import { IconProps } from "./type"

export const ICReload: React.FC<IconProps> = ({
    fill = "currentColor",
    stroke = "currentColor",
}) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5015 5.375H13.335H11.535C11.4687 5.375 11.4051 5.40134 11.3582 5.44822C11.3113 5.49511 11.285 5.5587 11.285 5.625C11.285 5.6913 11.3113 5.75489 11.3582 5.80178C11.4051 5.84866 11.4687 5.875 11.535 5.875H14.9325C14.9988 5.875 15.0624 5.84866 15.1093 5.80178C15.1562 5.75489 15.1825 5.69131 15.1825 5.625V2.25C15.1825 2.18369 15.1562 2.12011 15.1093 2.07322C15.0624 2.02634 14.9988 2 14.9325 2C14.8662 2 14.8026 2.02634 14.7557 2.07322C14.7088 2.12011 14.6825 2.18369 14.6825 2.25V3.5775V4.74717L13.837 3.93893C12.6713 2.82457 11.1565 2.14689 9.54881 2.02046C7.9411 1.89402 6.33904 2.32658 5.01345 3.24501C3.68785 4.16344 2.72003 5.51141 2.27358 7.06106C1.82714 8.61071 1.92945 10.267 2.56323 11.7499C3.19701 13.2328 4.32337 14.4514 5.75193 15.1997C7.18049 15.948 8.8236 16.1801 10.4035 15.8568C11.9835 15.5334 13.4033 14.6745 14.423 13.4251C15.4427 12.1758 15.9998 10.6127 16 9L14.5015 5.375ZM14.5015 5.375L13.6971 4.53021M14.5015 5.375L13.6971 4.53021M13.6971 4.53021C12.6481 3.42859 11.2479 2.72724 9.73746 2.54696C8.22704 2.36667 6.70103 2.71873 5.42225 3.5425C4.14348 4.36627 3.19199 5.61018 2.73166 7.06C2.27133 8.50981 2.33098 10.0748 2.90034 11.4853C3.4697 12.8959 4.51311 14.0638 5.85089 14.7878C7.18866 15.5119 8.73704 15.7467 10.2294 15.452C11.7217 15.1572 13.0645 14.3513 14.0265 13.1731C14.9883 11.9952 15.5093 10.519 15.5 8.99845C15.5004 8.9327 15.5267 8.86974 15.5732 8.82322C15.6201 8.77634 15.6837 8.75 15.75 8.75M13.6971 4.53021L15.75 8.75M15.75 8.75C15.8163 8.75 15.8799 8.77634 15.9268 8.82322C15.9736 8.87009 16 8.93365 16 8.99993L15.75 8.75Z" fill={fill} stroke={stroke} />
        </svg>
    )
}

export const ICReloadV2: React.FC<IconProps> = ({
    fill = "#46485C",
    width = 18,
    height = 18,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 8.25C15.5511 8.25 15.3603 8.32902 15.2197 8.46967C15.079 8.61032 15 8.80109 15 9C15.0089 10.4048 14.5277 11.7687 13.6392 12.8568C12.7508 13.945 11.5106 14.6893 10.1325 14.9615C8.7543 15.2337 7.32434 15.0167 6.08888 14.3481C4.85342 13.6794 3.8898 12.6009 3.36399 11.2982C2.83818 9.99551 2.7831 8.55024 3.20822 7.21131C3.63334 5.87238 4.51206 4.7236 5.69303 3.96283C6.874 3.20207 8.2833 2.87693 9.6782 3.04343C11.0731 3.20993 12.3663 3.85764 13.335 4.875H11.535C11.3361 4.875 11.1453 4.95402 11.0047 5.09467C10.864 5.23532 10.785 5.42609 10.785 5.625C10.785 5.82391 10.864 6.01468 11.0047 6.15533C11.1453 6.29598 11.3361 6.375 11.535 6.375H14.9325C15.1314 6.375 15.3222 6.29598 15.4628 6.15533C15.6035 6.01468 15.6825 5.82391 15.6825 5.625V2.25C15.6825 2.05109 15.6035 1.86032 15.4628 1.71967C15.3222 1.57902 15.1314 1.5 14.9325 1.5C14.7336 1.5 14.5428 1.57902 14.4022 1.71967C14.2615 1.86032 14.1825 2.05109 14.1825 2.25V3.5775C12.9335 2.38354 11.3106 1.65746 9.58801 1.522C7.86547 1.38653 6.14897 1.84998 4.72869 2.83402C3.30841 3.81805 2.27146 5.26231 1.79312 6.92264C1.31479 8.58298 1.42441 10.3576 2.10346 11.9464C2.78251 13.5352 3.98933 14.8409 5.51993 15.6426C7.05052 16.4444 8.811 16.6931 10.5038 16.3466C12.1966 16.0002 13.7178 15.0799 14.8104 13.7413C15.9029 12.4027 16.4998 10.7279 16.5 9C16.5 8.80109 16.421 8.61032 16.2803 8.46967C16.1397 8.32902 15.9489 8.25 15.75 8.25Z" fill={fill}/>
        </svg>
    )
}
