import { ETestStatus } from "../my_test/type";

export enum EType {
    PAY = "Trả phí",
    FREE = "Miễn phí",
}

export enum EStatusCard {
    NEW = "new",
    NORMAL = "normal",
    POPULAR = "popular",
    PAY = "pay",
    FREE = "free",
}

export interface ITags {
    name: string,
    icon?: React.ReactNode
}

export interface IRating {
    oneStar: number;
    twoStar: number;
    threeStar: number;
    fourStar: number;
    fiveStar: number;
}

export interface IConfiguration {
    questionUsage: number; 
    questionDuration: number
}

export interface ISkillSections {
    name: string; 
    description: string; 
    questions: any[];
}

export interface ICreatedBy {
    avatar: string; 
    email: string; 
    id: string;
    name: string;
    _id: string;
}

export type TPrice = {
    discount: number;
    price: number;
    unit: string;
    originalPrice: number;
}

export interface ILibrary {
  tags?: ITags[];

  _id?: string;
  id?: string;
  title: string;
  summary?:string;
  relevant?: string;
  description?: string;
  language?: string;
  industry?: string;
  roles?: string[];
  skills?: string[];
  type?: EType;
  level?: string;
  ratingLevel?: string;
  
  createdBy?: ICreatedBy;
  status?: EStatusCard;

  planTag: EStatusCard;
  statusTag: EStatusCard;

  avg_rate?: string;
  skillsRequired?: string[];

  approvalDate?: string;
  returnedDate?: string;

  configuration?: IConfiguration;
  previewSection?: any[];
  skillSections?: ISkillSections[];
  totalParticipate?: number;
  rating?: IRating;
  createdAt?: string;
  updatedAt?: string;
  price?: TPrice;

  uuid?: string; // FE only
  isPublic?: boolean;
}

export interface ITestLibrary extends Omit<ILibrary, "status"> {
    status: ETestStatus;
}

export interface ILibraryUI extends ILibrary {
    duration?: number; // FE only
}

export interface ISearchCriteria {
    page: number;
    limit: number;
    searchText?: string;
    language?: string;
    roles?: string[];
    type?: string;
    industry?: string[];
    statusTag?: string;
    planTag?: string;
    favorite?: boolean;
    rating?: number;
}

export interface IPagination {
    page: number; 
    limit: number;
    totalDocuments: number;
    totalPages: number;
}

export type TScoreLevels = {
    maxScore: number;
    minScore: number;
    name: string;
    score: number;
    symbol: string;
    _id: string;

    label: string;
    value?: string | number;
}

export type TRatingLevel = {
    id: string;
    label: string;
    scoreLevels: TScoreLevels[];
};

