import { ApiInstance } from "..";
import { convert2QueryString } from '../../utils/convert2QueryString';
import { ISearchCriteria } from '../../pages/library/models';
import { TConfirmCandidate, TInvitationCandidate } from "../../pages/my_assessment/type";
import { IInviteCandidate } from "../../pages/testing/type";

export const getMyCandidate = (payload: ISearchCriteria) => {
  const requestURL = "/api/candidate/my-candidate?" + convert2QueryString(payload);
  return ApiInstance.getInstance().get(requestURL);
}

export const getMyCandidateDetails = (id: string) => {
  return ApiInstance.getInstance().get(`/api/candidate/my-candidate/${id}`);
}

export const getTestsByCandidate = (id: string) => {
  return ApiInstance.getInstance().get(`/api/candidate/get-my-test-candidate-by-id/${id}`);
}

export const getInvitation = (assessmentId: string, payload: ISearchCriteria) => {
  const requestURL = `/api/candidate/get-invitation/${assessmentId}?` + convert2QueryString(payload);
  return ApiInstance.getInstance().get(requestURL);
}

export const inviteCandidateAPI = (assessmentId: string, payload: any) => {
  return ApiInstance.getInstance().post(`/api/candidate/invite/${assessmentId}`, payload);
};

export const inviteListCandidateAPI = (assessmentId: string, payload: any) => {
  return ApiInstance.getInstance().post(`/api/candidate/invite-list/${assessmentId}`, payload);
};

export const removeInvitation = (payload: TInvitationCandidate) => {
  return ApiInstance.getInstance().delete(`/api/candidate/remove-invitation`, { data: payload });
};

export const inviteWithoutLoginCandidateAPI = (assessmentId: string, payload: IInviteCandidate) => {
  return ApiInstance.getInstance().post(`/api/candidate/invite-without-login/${assessmentId}`, payload);
};

export const getAssessmentByURL = (url: string) => {
  return ApiInstance.getInstance().get(`/api/assessment/get-assessment-by-url/${url}`);
}

export const sendVerifyOTP = (testCandidateUrl: string) => {
  return ApiInstance.getInstance().post(`/api/candidate/send-verify-candidate-otp`, { testCandidateUrl });
}

export const verifyOTP = (testCandidateUrl: string, otp: string) => {
  return ApiInstance.getInstance().post(`/api/candidate/verify-candidate-otp`, { testCandidateUrl, otp });
}

export const confirmCandidate = (testCandidateUrl: string, payload: TConfirmCandidate) => {
  return ApiInstance.getInstance().post(`/api/test-execution/confirm-candidate/${testCandidateUrl}`, { ...payload });
}

export const getNotInvitedAssessment = (id: string) => {
  return ApiInstance.getInstance().get(`/api/candidate/get-not-invited-assessment/${id}`);
};

export const getMyCandidates = (payload: ISearchCriteria) => {
  const requestURL = "/api/candidate/get-my-candidates?" + convert2QueryString(payload);
  return ApiInstance.getInstance().get(requestURL);
}

export const getMyCandidatesFilterData = () => {
  return ApiInstance.getInstance().get(`/api/candidate/get-my-candidates-filter-data`);
};
