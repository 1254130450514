import React from 'react';
import { useParams } from 'react-router-dom';
import { usePreviewTesting } from './hooks/usePreviewTesting';
import { ILibrary } from '../library/models';
import { PreviewTestDetail } from './test/test';
import { SearchParamsStateType, useFilter } from '../../hooks/useFilter';

type TPreviewTestingPage = {
    id: string;
    testDetails: ILibrary;
    index: number;
    setTestDetails: (testDetails: ILibrary) => void;
}
export const PreviewTestingPageContext = React.createContext<TPreviewTestingPage>({} as TPreviewTestingPage);
export const usePreviewTestingPageContext = () => React.useContext(PreviewTestingPageContext);

export const PreviewTestingPage: React.FC = () => {
    const filtersDefaults: SearchParamsStateType = {
        index: { type: 'number', default: null },
    }
    const [indexSection, setIndexSection] = useFilter(filtersDefaults);
    const { index = null } = indexSection;
    
    const { id = '' } = useParams();
    const { loadTestDetails } = usePreviewTesting();
    const [testDetails, setTestDetails] = React.useState<ILibrary>({} as ILibrary);

    React.useEffect(() => {
        if (id) {
            loadTestDetails(id, setTestDetails);
        }
    }, [id, loadTestDetails]);

    return <PreviewTestingPageContext.Provider
        value={{
            id,
            index,
            testDetails,
            setTestDetails
        }}
    >
        <div className="lg-container mx-auto rounded-[12px] flex justify-center items-center">
            <div className="flex items-center justify-center">
                <PreviewTestDetail />
            </div>
        </div>
    </PreviewTestingPageContext.Provider>
}
