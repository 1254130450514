import { EPaymentMethod } from "../payment/type";

type TAssessment = {
    title: string;
    _id: string;
    activeDate: number;
    expireDate: number;
};

type TPricePayment = {
    price: number;
    discount: number;
    totalPrice: number;
    transactionFee: number;
    unit: string;
    originalPrice: number;
}

type TPaidTests = {
    titlte: string;
    _id: string;
}

export enum EOrderPaymentStatus {
    UNPAID = 'unpaid',
    SUCCESS = 'success',
    FAILURE = 'failure',
    WAITING_FOR_PAYMENT = 'waiting_for_payment'
}

export const OrderPaymentStatusMapping = {
    [EOrderPaymentStatus.UNPAID]: 'Chưa thanh toán',
    [EOrderPaymentStatus.SUCCESS]: 'Đã thanh toán',
    [EOrderPaymentStatus.FAILURE]: 'Thanh toán thất bại',
    [EOrderPaymentStatus.WAITING_FOR_PAYMENT]: 'Đang chờ thanh toán',
}

export enum EDifinition {
    CANDIDATE_CREDIT = 'candidate_credit',
    ASSESSMENT = 'assessment',
}

export type TPayer = {
    name: string;
    email: string;
    _id: string;
}

export type TDifinition = {
    type: EDifinition;
    assessment: TAssessment;
    paidTests: TPaidTests[];

    price: TPricePayment;
    creditPerQuantity: number;
    quantity: number;
    totalCandidateCredit: number;

}

type TInvoiceInfo = {
    buyerName: string;
    buyerLegalName: string;
    buyerTaxCode: string;
    buyerEmail: string;
    buyerAddress: string;
}

export type TGeneralInvoiceInfo = {
    currencyCode: string;
    invoiceIssuedDate: number;
    invoiceNo: string;
    invoiceSeries: string;
    invoiceType: string;
    supplierTaxCode: string;
    templateCode: string;
    transactionUuid: string;
}

export type TOrderDetails = {
    difinition: TDifinition;
    hasInvoice: boolean;
    invoiceInfo: TInvoiceInfo;
    orderCode: string;
    paidAt: number;
    paymentMethod: EPaymentMethod;
    paymentStatus: EOrderPaymentStatus;
    pricing: TPricePayment;
    payer: TPayer;
    _id: string;
    generalInvoiceInfo?: TGeneralInvoiceInfo;
};
