import React from "react"
import { Col, Row } from 'antd';
import { Comment } from './comment';
import { useCreateTestPageContext } from "..";
import { dateFormat } from '../../../utils/date';
import i18next from "../../../i18n";

type OverviewTabProps = {

}

export const OverviewTab: React.FC<OverviewTabProps> = () => {
    const { testDetails } = useCreateTestPageContext();

    const data: any[] = [
        {
            label: i18next.t('createTest.createdAt'),
            value: dateFormat(testDetails?.createdAt || '')
        },
        {
            label: i18next.t('createTest.updatedAt'),
            value: dateFormat(testDetails?.updatedAt || '')
        },
        {
            label: i18next.t('createTest.approvalDate'),
            value: dateFormat(testDetails?.approvalDate || '')
        },
        {
            label: i18next.t('createTest.createdBy'),
            value: testDetails?.createdBy?.name
        },
        {
            label: i18next.t('createTest.returnedDate'),
            value: dateFormat(testDetails?.returnedDate || '')
        },
    ];
    return <div className="bg-white p-4 lg:p-8 rounded-b-[10px] grid gap-[32px]">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
            {
                data.map((item, index) => {
                    return <div 
                    key={index}
                    className="grid grid-cols-[1fr] gap-[16px] rounded-[12px] border-[1.2px] border-[#E2E4EB] justify-items-left items-left py-[16px] px-[32px] min-h-[113px]">
                        <label className="text-[#46485C] font-bold text-[16px]">{item.label}</label>
                        <span className="text-[#A6A8B4] text-[24px] font-medium">{item.value}</span>
                    </div>
                })
            }
        </div>
        <div className="hidden grid gap-[32px] rounded-[12px] border-[1.2px] border-[#E2E4EB] py-[16px] px-[32px]">
            <label className="font-bold text-[16px] leading-[19px] text-[#46485C]">Bình luận trả về</label>
            <Comment />
        </div>
    </div>
}
