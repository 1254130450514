import { IconProps } from "./type"

export const ICUploadFile: React.FC<IconProps> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none" className="none-hover">
            <path d="M1.08691 26.0435H80.0434C84.4617 26.0435 88.0434 29.6252 88.0434 34.0435V84C88.0434 86.2091 86.2526 88 84.0434 88H5.08691C2.87778 88 1.08691 86.2091 1.08691 84V26.0435Z" fill="#E2E2E4" />
            <path d="M21.7393 35.5652C21.7393 33.9083 23.0824 32.5652 24.7393 32.5652H78.5219C80.1787 32.5652 81.5219 33.9083 81.5219 35.5652V68.4348H21.7393V35.5652Z" fill="white" />
            <path d="M10.2241 45.227C11.0847 41.6266 14.3031 39.0869 18.0049 39.0869L89.7712 39.0869C94.9703 39.0869 98.7882 43.9684 97.5356 49.0144L88.6114 84.9637C88.1685 86.7475 86.5671 88 84.7292 88H5.06881C2.48359 88 0.577394 85.5844 1.17842 83.07L10.2241 45.227Z" fill="#ECECED" />
            <path d="M1.08691 17C1.08691 14.7909 2.87778 13 5.08691 13H27.8695C32.2878 13 35.8695 16.5817 35.8695 21V26.0435H1.08691V17Z" fill="#E2E2E4" />
        </svg>
    )
}

export const ICUploadFileHover: React.FC<IconProps> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
            <g clipPath="url(#clip0_13791_4284)">
                <g filter="url(#filter0_d_13791_4284)">
                    <path d="M1.08691 26.1184H80.0434C84.4617 26.1184 88.0434 29.7001 88.0434 34.1184V84.0749C88.0434 86.2841 86.2526 88.0749 84.0434 88.0749H5.08691C2.87778 88.0749 1.08691 86.2841 1.08691 84.0749V26.1184Z" fill="#FFD600" />
                    <path d="M21.7392 35.6401C21.7392 33.9833 23.0823 32.6401 24.7392 32.6401H78.5218C80.1787 32.6401 81.5218 33.9833 81.5218 35.6401V68.5097H21.7392V35.6401Z" fill="white" />
                    <path d="M10.2241 45.302C11.0847 41.7016 14.3031 39.1619 18.0049 39.1619L89.7712 39.1619C94.9703 39.1619 98.7882 44.0434 97.5356 49.0893L88.6114 85.0386C88.1685 86.8224 86.5671 88.0749 84.7292 88.0749H5.06881C2.48359 88.0749 0.577394 85.6594 1.17842 83.145L10.2241 45.302Z" fill="#FFED47" />
                    <path d="M1.08691 17.0749C1.08691 14.8658 2.87778 13.075 5.08691 13.075H27.8695C32.2878 13.075 35.8695 16.6567 35.8695 21.075V26.1184H1.08691V17.0749Z" fill="#FFD600" />
                </g>
            </g>
            <defs>
                <filter id="filter0_d_13791_4284" x="-0.933044" y="13.075" width="100.708" height="79" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_13791_4284" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0666667 0 0 0 0 0.0470588 0 0 0 0 0.133333 0 0 0 0.12 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13791_4284" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13791_4284" result="shape" />
                </filter>
                <clipPath id="clip0_13791_4284">
                    <rect width="100" height="100" fill="white" transform="translate(0 0.0749512)" />
                </clipPath>
            </defs>
        </svg>
    )
}
