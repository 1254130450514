import { IconProps } from "./type"

export const ICEdit: React.FC<IconProps> = ({
    width = 24,
    height = 24,
    fill = "var(--icon-high-em)",
}) => {
    return (
        <svg width={width} height={height} fill={fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20 0.878906C19.1722 0.878906 18.3783 1.20776 17.7929 1.79312L8.29289 11.2931C8.16473 11.4213 8.07382 11.5819 8.02986 11.7577L7.02986 15.7577C6.94466 16.0985 7.04451 16.459 7.29289 16.7073C7.54127 16.9557 7.90176 17.0556 8.24254 16.9704L12.2425 15.9704C12.4184 15.9264 12.5789 15.8355 12.7071 15.7073L22.2071 6.20733C22.7925 5.62197 23.1213 4.82805 23.1213 4.00023C23.1213 3.1724 22.7925 2.37848 22.2071 1.79312C21.6217 1.20776 20.8278 0.878906 20 0.878906ZM19.2071 3.20733C19.4174 2.99705 19.7026 2.87891 20 2.87891C20.2974 2.87891 20.5826 2.99705 20.7929 3.20733C21.0032 3.41762 21.1213 3.70283 21.1213 4.00023C21.1213 4.29762 21.0032 4.58283 20.7929 4.79312L11.4888 14.0973L9.37437 14.6259L9.90296 12.5115L19.2071 3.20733Z" fill={fill} />
            <path d="M4 3.00024C3.20435 3.00024 2.44129 3.31631 1.87868 3.87892C1.31607 4.44153 1 5.20459 1 6.00024V20.0002C1 20.7959 1.31607 21.559 1.87868 22.1216C2.44129 22.6842 3.20435 23.0002 4 23.0002H18C18.7957 23.0002 19.5587 22.6842 20.1213 22.1216C20.6839 21.559 21 20.7959 21 20.0002V13.0002C21 12.448 20.5523 12.0002 20 12.0002C19.4477 12.0002 19 12.448 19 13.0002V20.0002C19 20.2655 18.8946 20.5198 18.7071 20.7073C18.5196 20.8949 18.2652 21.0002 18 21.0002H4C3.73478 21.0002 3.48043 20.8949 3.29289 20.7073C3.10536 20.5198 3 20.2655 3 20.0002V6.00024C3 5.73503 3.10536 5.48067 3.29289 5.29314C3.48043 5.1056 3.73478 5.00024 4 5.00024H11C11.5523 5.00024 12 4.55253 12 4.00024C12 3.44796 11.5523 3.00024 11 3.00024H4Z" fill={fill} />
        </svg>
    )
}
