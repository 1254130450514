import React from "react";
import * as rrweb from "rrweb";
import { Socket } from "socket.io-client";
import { EVENT_NAME_SOCKET } from "../utils/constants";
function calcJSONInByteSize(json: any) {
    return new Blob([JSON.stringify(json)]).size;
}
export type RecordData = {
    socket: Socket<any, any>;
    [key: string]: any;
}
const uploadToServer = (
    events: any[],
    data: RecordData,
) => {
    const { socket, ...rest } = data;
    const uploadedData = {
        events,
        ...rest
    }
    const byteSize = calcJSONInByteSize(uploadedData);
    socket.emitWithAck(EVENT_NAME_SOCKET.SEND.UPLOAD_EVENTS_TRACK, uploadedData).then(() => {
    }).catch((err: any) => {
        console.log("Error when upload data: ", byteSize, 'Error: ', err);
    })
}
type RecordScreenProviderProps = {
    children: React.ReactNode
}

type RecordScreenContextType = {
    eventsRef: React.MutableRefObject<any[]>;
    onStartRecording: (data: RecordData) => void;
    onStopRecording: () => void;
}
// Every 2 seconds, upload the events to the server
const INTVERAL_TIME = 2 * 1000;
const RecordScreenContext = React.createContext<RecordScreenContextType>({} as RecordScreenContextType);
export const useScreenRecord = () => React.useContext(RecordScreenContext);
export const RecordScreenProvider: React.FC<RecordScreenProviderProps> = ({
    children
}) => {
    const events = React.useRef<any[]>([]);
    const stopFnRef = React.useRef<() => void>();
    const intervelRef = React.useRef<any>();
    const dataRef = React.useRef<RecordData>();
    const onStartRecording = React.useCallback((data: RecordData) => {
        dataRef.current = data;
        stopFnRef.current = rrweb.record({
            emit(event) {
                events.current.push(event);
            },
        });
        intervelRef.current = setInterval(() => {
            if (events.current.length > 0) {
                const eventsToUpload = events.current;
                events.current = [];
                uploadToServer(eventsToUpload, data);
            }
        }, INTVERAL_TIME);
    }, []);
    const onStopRecording = React.useCallback(() => {
        stopFnRef.current?.();
        clearInterval(intervelRef.current);
        if (!stopFnRef.current) return;
        const eventsToUpload = events.current;
        events.current = [];
        uploadToServer(eventsToUpload, dataRef.current!);
    }, []);

    const contextData = React.useMemo(() => ({
        onStartRecording,
        onStopRecording,
        eventsRef: events,
    }), [onStartRecording, onStopRecording]);

    return <RecordScreenContext.Provider value={contextData}>
        {children}
    </RecordScreenContext.Provider>
}
