import React from "react";
import { EQuestionType, TQuestion, TSpeakingQuestion, createDefaultQuestion } from "./type";
import { useCreateTestPageContext } from "../..";
import { HRButton, IconButton } from "../../../../components/button";
import { ICClose, ICTick, ICWarning } from "../../../../icons";
import { Editor } from "react-draft-wysiwyg";
import { Modal } from "antd";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { useNotification } from "../../../../hooks/useNotification";
import htmlToDraft from "html-to-draftjs";
import { getInnerText } from "./helper";
import draftToHtml from "draftjs-to-html";
import { MAX_CONTENT_LENGTH } from "../../../../utils/constants";
import { isEmptyHTML, toolbar } from "./editor.config";
import { MAX_QUESTIONS_IN_GROUP } from "./contants";
import classNames from "classnames";
import i18next from "../../../../i18n"

type GroupQuestionProps = {
    selectedQuestion: TSpeakingQuestion | undefined
    questionType: EQuestionType.SPEAKING
    onCancel: () => void
}

export const SpeakingQuestionCreator: React.FC<GroupQuestionProps> = ({ selectedQuestion, questionType, onCancel }) => {
    const [question, setQuestion] = React.useState<TSpeakingQuestion>(() => (selectedQuestion ?? createDefaultQuestion(questionType)) as TSpeakingQuestion);
    const { form, selectedSection, setShowAddQuestion, onCreatedQuestion } = useCreateTestPageContext();
    const [needCheck, setNeedCheck] = React.useState<boolean>(false);

    const {
        showSuccess
    } = useNotification()
    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question?.content).contentBlocks))
    );

    React.useEffect(() => {
        const q = selectedQuestion ?? createDefaultQuestion(questionType) as TSpeakingQuestion
        setQuestion(q)
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(q.content).contentBlocks)))
    }, [selectedSection, questionType, selectedQuestion]);

    const isDirty = React.useMemo(() => {
        if (!selectedQuestion) return getInnerText(question.content).replace(/\n/g, '') !== ""
            || getInnerText(question.referenceContent as string).replace(/\n/g, '') !== ""
        return question.content !== selectedQuestion?.content
            || question.referenceContent !== selectedQuestion?.referenceContent
    }, [question, selectedQuestion]);

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        setQuestion({
            ...question,
            content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        })
    }

    const invalidMaxLengthEditor = React.useMemo(() => {
        const text = editorState?.getCurrentContent()?.getPlainText().length;
        if (text && text > MAX_CONTENT_LENGTH) {
            return true
        }
        return false;
    }, [editorState]);

    const isQuestionError = React.useMemo(() => {
        return needCheck && (isEmptyHTML(question.content) || invalidMaxLengthEditor);
    }, [needCheck, question.content, invalidMaxLengthEditor]);

    const validationValues = (): boolean => {
        setNeedCheck(true);
        return isEmptyHTML(question.content) ||
            isEmptyHTML(question.referenceContent as string)
    };

    // check data length editorState
    const editorStateDataLength = React.useMemo(() => {
        const numberFormat = new Intl.NumberFormat('en-US');
        const contentLength = editorState.getCurrentContent().getPlainText().length;
        return `${contentLength} / ${numberFormat.format(MAX_CONTENT_LENGTH)}`;
    }, [editorState]);

    const onSave = () => {
        if (!validationValues()) {
            onCreatedQuestion(question);
            setShowAddQuestion(false);
        }
    }

    const onSaveAndAddAnother = () => {
        if (!validationValues()) {
            onCreatedQuestion(question);
            showSuccess(i18next.t('createTest.success'), i18next.t('createTest.addQuestionSuccess'))
            setNeedCheck(false);
            const defaultQuestion = createDefaultQuestion(questionType) as TSpeakingQuestion
            setQuestion(defaultQuestion)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(defaultQuestion.content).contentBlocks)))
        }
    }
    const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false)
    const onCancelAction = React.useCallback(() => {
        if (isDirty) {
            setShowCancelModal(true)
        } else {
            onCancel()
        }
    }, [isDirty, setShowCancelModal, onCancel]);

    const inactiveAddQuestion = () => {
        if (!selectedSection) {
            return false;
        }
        return selectedSection?.questions.length >= MAX_QUESTIONS_IN_GROUP;
    };

    const title = React.useMemo(() => {
        return editorState.getCurrentContent().getPlainText();
    }, [editorState]);



    return <div className="pb-16 space-y-8 text-left lg-container mx-auto">
        <div className="lg:flex items-center justify-between">
            <div>
                <div className="flex space-x-2 items-center font-bold text-[24px]" >
                    <IconButton btnType="sub" btnSize="sm" icon={<ICClose width={18} height={18} />} onClick={onCancelAction} />
                    <span className="lg:max-w-[500px] line-clamp-1">{title || i18next.t('createTest.newQuestion')}</span>
                </div>
                <span className="text-xs font-medium text-n-600 ml-12">
                    {form.getFieldValue("title")} / {selectedSection?.name ?? ""}
                </span>
            </div>
            <div className="hidden lg:flex space-x-4">
                <span>
                    <HRButton
                        btnType="sub"
                        btnSize="md"
                        onClick={onCancelAction}
                    >
                        <div className="flex justify-center">
                            {i18next.t('createTest.cancel')}
                        </div>
                    </HRButton>
                </span>
                {
                    !selectedQuestion && <span><HRButton
                        onClick={onSaveAndAddAnother}
                        btnSize="md"
                        btnType="sub"
                        disabled={inactiveAddQuestion()}
                    >
                        <div className="flex justify-center">
                            {i18next.t('createTest.saveAndCreateNew')}
                        </div>
                    </HRButton></span>
                }
                <span>
                    <HRButton
                        btnSize="md"
                        onClick={onSave}
                        className="w-[107px] h-[44px]"
                        disabled={inactiveAddQuestion() && !selectedQuestion}
                    >
                        <div className="flex space-x-2 justify-center">
                            <ICTick />
                            <span>{selectedQuestion ? i18next.t('createTest.update') : i18next.t('createTest.save')}</span>
                        </div>
                    </HRButton>
                </span>
            </div>
        </div>
        <div className="p-2 grid grid-cols-1 grid-rows-[auto_1fr] justify-start items-start gap-[16px]">
            <div className="text-primary-bold text-standard-medium">{i18next.t('createTest.questionSection')}</div>
            <div className="space-y-3"
                onContextMenu={(e) => {
                    // @ts-ignore
                    document.querySelector(".questions-editor")?.focus?.()
                }}
            >
                <Editor
                    stripPastedStyles={true}
                    toolbar={toolbar}
                    editorState={editorState}
                    toolbarClassName={classNames(isQuestionError ? "error" : "", "editor-toolbar")}
                    wrapperClassName={classNames(isQuestionError ? "error" : "", "questions-wrapper", "editor-wrapper")}
                    editorClassName={classNames(isQuestionError ? "error" : "", "questions-editor", "editor-editor")}
                    onEditorStateChange={onEditorStateChange}
                    placeholder={i18next.t('createTest.enterQuestion') ?? ''}
                />
                <div className="noted text-med-em text-tiny-medium flex items-center justify-end">
                    <span>{editorStateDataLength}</span>
                </div>
                {
                    invalidMaxLengthEditor && <div className="flex items-center space-x-2">
                        <ICWarning fill="#F55858" width={18} height={18} />
                        <span className="text-body italic text-error-500">{i18next.t('createTest.enterMoreThanAllowedCharater')}</span>
                    </div>
                }
                {
                    isQuestionError && !invalidMaxLengthEditor && <div className="flex items-center space-x-2">
                        <ICWarning fill="#F55858" width={18} height={18} />
                        <span className="text-body italic text-error-500">{i18next.t('createTest.plsEnterQuestion')}</span>
                    </div>
                }
            </div>
        </div>
        <div className="block lg:hidden p-2">
            <HRButton
                btnSize="md"
                onClick={onSave}
                className="w-[107px] h-[44px]"
                disabled={inactiveAddQuestion() && !selectedQuestion}
            >
                <div className="flex space-x-2 justify-center">
                    <ICTick />
                    <span>{selectedQuestion ? i18next.t('createTest.update') : i18next.t('createTest.save')}</span>
                </div>
            </HRButton>
        </div>
        <Modal
            title={i18next.t('createTest.notification')}
            open={showCancelModal}
            centered={true}
            onCancel={setShowCancelModal.bind(null, false)}
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnSize="sm"
                        btnType="sub"
                        onClick={() => setShowCancelModal(false)}>
                        {i18next.t('createTest.no')}
                    </HRButton>
                    <HRButton btnSize="sm" onClick={onCancel}>
                        {i18next.t('createTest.yes')}
                    </HRButton>
                </div>
            }
        >
            <div className="space-y-2">
                <div>{i18next.t('createTest.infoWillBeLost')}</div>
                <div>{i18next.t('createTest.sureToCancel')}</div>
            </div>
        </Modal>
    </div>
};
