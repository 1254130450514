import React, { useCallback } from "react"
import { Upload, UploadProps, notification } from "antd";
import { useCompanyPageContext } from ".";
import { ICCheckV2, ICDelete, ICEye, ICReloadV2, ICUploadCompany, ICUploadCompanyHover, ICUploadV2 } from "../../icons";
import { HRButton } from "../../components/button";
import styled from "styled-components";
import { RcFile } from "antd/lib/upload";
import classNames from "classnames";
import { ChromePicker } from 'react-color';
import { useNotification } from "../../hooks/useNotification";
import { AVATAR_MAX_WIDTH, AVATAR_MAX_HEIGHT } from "../../utils/constants";
import { resizeImage } from "../home/resize-img";
import { setLoading } from "../../redux/slices/appInfo";
import { useDispatch } from "react-redux";
import { UploadAPI } from "../../api/upload";
import i18next from "../../i18n";

const { Dragger } = Upload;
const Wrapper = styled.div`
    &.has-image {
        .ant-upload.ant-upload-drag {
            border: none !important;
        }
        .ant-upload.ant-upload-drag .ant-upload {
            padding: 0px;
        }
    }
    .ant-upload.ant-upload-drag {
        border-radius: 12px;
        border: 1px dashed #C6C5CA;
        background-color: #F8F8F8;
        .none-hover {
            display: flex;
        }
        .hovering {
            display: none;
        }
        &:hover {
            border: 1px dashed #0084FF;
            background-color: #F5FAFF;
            .none-hover {
                display: none;
            }
            .hovering {
                display: flex;
            }
        }
    }
    .ant-upload-list-text-container {
        display: none;
    }
`;

const ColorWrapper = styled.div`
    .chrome-picker {
        border-radius: 12px !important;
        width: 100% !important;
        box-shadow: none !important;
        div:first-child {
            border-radius: 12px 12px 0 0 !important;
        }

    }
`;

export const CompanyLogo: React.FC = () => {
    const { 
        logoCompany,
        backgroundColor,
        textColor,
        setTextColor,
        setBackgroundColor,
        setLogoCompany,
    } = useCompanyPageContext();
    const { showError, showSuccess } = useNotification();
    const dispatch = useDispatch();
    const [previewImage, setPreviewImage] = React.useState<string | null>(null);
    const [fileUpload, setFileUpload] = React.useState<RcFile | null>(null);

    React.useEffect(() => {
        if (logoCompany) {
            setPreviewImage(`${process.env.REACT_APP_S3_URL}/${logoCompany}`);
        }
    }, [logoCompany]);

    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });

    const setImage = async (file: RcFile) => {
        const base64 = await getBase64(file);
        setPreviewImage(base64);
    };

    const setDefaultColor = useCallback(() => {
        setBackgroundColor('#7357FF');
        setTextColor('#FFFFFF');
    }, []);

    const onSetLogoPath = useCallback((value: string) => {
        setLogoCompany(value);
    }, [setLogoCompany]);

    const validationImage = useCallback((file: RcFile) => {
        return new Promise((resolve, reject) => {
            if (!file) {
                reject(i18next.t('companyProfile.fileNotExists'));
            }
            const isJpgOrPngOrJpeg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPngOrJpeg) {
                showError(i18next.t('companyProfile.error'), i18next.t('companyProfile.youCanUploadFileImage'));
                reject(false);
            }
            //   const isLt10M = file.size / 1024 / 1024 < 10;
            //   if (!isLt10M) {
            //     showError('Vượt quá kích thước tối đa', 'Kích thước tối đa: 10Mb');
            //     reject(false);
            //   }
            // check Dimensions file
            // let validDimensions = true;
            // const img = new Image();
            // img.src = window.URL.createObjectURL(file);
            // img.onload = function () {
            //     const width = img.naturalWidth,
            //         height = img.naturalHeight;
            //     window.URL.revokeObjectURL(img.src);
            //     if (width > 1080 && height > 720) {
            //         validDimensions = true;
            //         showError('Vượt quá độ phân giải tối đa', 'Độ phân giải tối đa: 1080x720');
            //         reject(false);
            //     } else {
            //         validDimensions = false;
            //         resolve(true);
            //     }
            // };
            return resolve(isJpgOrPngOrJpeg);
        })
    }, [showError]);

    const callUpload = async (file: File, fileName: string) => {
        try {
            dispatch(setLoading(true))
            const { data } = await UploadAPI(file);
            const filePath = data.fileUrl;
            notification.success({
                message: i18next.t('companyProfile.success'),
                description: `${fileName} file uploaded successfully.`,
                placement: "topRight"
            });
            setImage(file as RcFile);
            onSetLogoPath(filePath);
        } catch (e) {
            console.error(e)
            notification.error({
                message: "Something went wrong",
                placement: "bottom",
            });
        } finally {
            dispatch(setLoading(false))
        }
    };

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: false,
        accept: ".png, .jpg, .jpeg",
        // action: `${process.env.REACT_APP_API_URL}/api/uploader`,
        customRequest: (options) => {
            return {
                ...options,
                action: `${process.env.REACT_APP_API_URL}/api/uploader`,
                file: fileUpload,
            }
        },
        onDrop(e) {
            const file = e.dataTransfer?.files[0];
            const fileName = file?.name;
            return validationImage(file as RcFile).then((file) => {
                return resizeImage(file as RcFile, {
                    maxWidth: AVATAR_MAX_WIDTH,
                    maxHeight: AVATAR_MAX_HEIGHT,
                }).then((result) => {
                    setFileUpload(result as RcFile);
                    callUpload(result as File, fileName);
                }).catch((e) => {
                    console.error(e);
                })
            }).catch((e) => {
                console.error(e);
            })
        },
        beforeUpload: (file: RcFile) => {
            return new Promise((resolve, reject) => {
                if (!file) {
                    reject(i18next.t('companyProfile.fileNotExists'));
                }
                const isJpgOrPngOrJpeg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
                if (!isJpgOrPngOrJpeg) {
                    showError(i18next.t('companyProfile.error'), i18next.t('companyProfile.youCanUploadFileImage'));
                    reject(false);
                }

                let currentFile = file as RcFile;
                const fileName = file?.name;
                resolve(resizeImage(currentFile, {
                    maxWidth: AVATAR_MAX_WIDTH,
                    maxHeight: AVATAR_MAX_HEIGHT,
                }).then((result) => {
                    setFileUpload(result as RcFile);
                    callUpload(result as File, fileName);
                    resolve(true);
                }).catch((e) => {
                    resolve(true);
                }));
                return reject(false);
                // const newFile = reduceImageSize(currentFile, {
                //     maxWidth: AVATAR_MAX_WIDTH,
                //     maxHeight: AVATAR_MAX_HEIGHT,
                // })
                // const isLt10M = file.size / 1024 / 1024 < 10;
                // if (!isLt10M) {
                //     showError('Vượt quá kích thước tối đa', 'Kích thước tối đa: 10Mb');
                //     reject(false);
                // }
                // check Dimensions file
                // const img = new Image();
                // img.src = window.URL.createObjectURL(file);
                // img.onload = function () {
                //     const width = img.naturalWidth,
                //         height = img.naturalHeight;
                //     window.URL.revokeObjectURL(img.src);
                //     // 1080x720
                //     if (width > 1080 && height > 720) {
                //         showError('Vượt quá độ phân giải tối đa', 'Độ phân giải tối đa: 1080x720');
                //         reject(false);
                //     } else {
                //         resolve(true);
                //     }
                // };
                // return resolve(isJpgOrPngOrJpeg);
            });
        }
    };

    const onRemoveImage = useCallback(() => {
        setPreviewImage(null);
        setLogoCompany(null);
    }, []);

    return <div className="flex flex-col w-full gap-[24px] p-[32px] bg-white rounded-[12px] shadow-e-03 box-border overflow-hidden">
        <div className="flex text-heading-5-medium text-primary-bold">
            {i18next.t('companyProfile.avatar')}
        </div>
        <Wrapper className={classNames([{
            'has-image': previewImage,
        }])}>
            <Dragger {...uploadProps}>
                {previewImage && <div className="w-full max-h-[230px] relative rounded-[12px] box-border overflow-hidden group">
                    <img alt="logo" style={{ width: '100%', objectFit: 'cover', maxHeight: '230px' }} src={previewImage} />
                    <div className="absolute bottom-[8px] left-[24px] hidden flex-row gap-[16px] group-hover:flex">
                        <span>
                            <HRButton btnType="sub" btnSize="xs">
                                <div className="flex gap-[8px] items-center justify-center">
                                    <ICUploadV2 fill="#4F4B5C" />
                                    <span className="text-tiny-medium text-[#4F4B5C]">{i18next.t('companyProfile.changeAvatar')}</span>
                                </div>
                            </HRButton>
                        </span>
                        <span>
                            <HRButton btnType="sub_danger" btnSize="xs" onClick={(event) => {
                                event.stopPropagation();
                                onRemoveImage();
                            }}>
                                <div className="flex gap-[8px] items-center justify-center">
                                    <ICDelete width={12} height={12} fill="#F03D3D" />
                                    <span className="text-tiny-medium text-[#F03D3D]">{i18next.t('companyProfile.delete')}</span>
                                </div>
                            </HRButton>
                        </span>
                    </div>
                </div>}
                {!previewImage && <div className="flex flex-col justify-center items-center gap-[32px]">
                    <ICUploadCompany />
                    <div className="hovering shadow-e-02 box-border rounded-[12px] overflow-hidden">
                        <ICUploadCompanyHover />
                    </div>
                    <div className="flex">
                        <span>
                            <HRButton btnType="info" btnSize="sm" style={{
                                borderRadius: '144px',
                            }}>
                                <div className="flex gap-[8px] items-center justify-center">
                                    <ICUploadV2 />
                                    <span className="text-body-medium text-white">{i18next.t('companyProfile.uploadAvatar')}</span>
                                </div>
                            </HRButton>
                        </span>
                    </div>
                </div>}
            </Dragger>
        </Wrapper>
        <div className="text-body text-high-em italic">
            {i18next.t('companyProfile.avatarSizeError')}
        </div>

        <div className="flex justify-between items-center">
            <div className="text-heading-5-medium text-primary-bold">
                {i18next.t('companyProfile.color')}
            </div>
            <div>
                <HRButton btnType="sub" btnSize="sm"
                    onClick={setDefaultColor}
                    style={{
                        padding: '4px 8px',
                        borderRadius: '144px',
                    }}>
                    <div className="flex gap-[8px] items-center justify-center">
                        <ICReloadV2 width={12} height={12} fill="#4F4B5C" />
                        <span className="text-sub-medium text-[#4F4B5C]">{i18next.t('companyProfile.default')}</span>
                    </div>
                </HRButton>
            </div>
        </div>
        <div className="flex flex-col gap-[48px]">
            <div className="flex flex-row gap-[16px]">
                <div className="flex flex-col gap-[8px] w-1/2">
                    <div className="text-standard-medium text-high-em">{i18next.t('companyProfile.background')}</div>
                    <ColorWrapper>
                        <ChromePicker color={backgroundColor} disableAlpha={true} onChange={color => {
                            setBackgroundColor(color.hex);
                        }} />
                    </ColorWrapper>
                </div>
                <div className="flex flex-col gap-[8px] w-1/2">
                    <div className="text-standard-medium text-high-em">{i18next.t('companyProfile.textColor')}</div>
                    <ColorWrapper>
                        <ChromePicker color={textColor} disableAlpha={true} onChange={color => {
                            setTextColor(color.hex);
                        }} />
                    </ColorWrapper>
                </div>
            </div>


            <div className="flex flex-col gap-[16px]">
                <div className="flex flex-row gap-[8px] items-center justify-start">
                    <ICEye />
                    <div className="text-standard-medium text-high-em">
                        {i18next.t('companyProfile.preview')}
                    </div>
                </div>
                <div>
                    <span>
                        <HRButton btnType="primary" btnSize="lg" style={{
                            backgroundColor: backgroundColor,
                            color: textColor
                        }}>
                            <div className="flex gap-[8px] items-center justify-center">
                                <ICCheckV2 fill={textColor} width={24} height={24} />
                                <span className="text-body-bold">{i18next.t('companyProfile.previewButton')}</span>
                            </div>
                        </HRButton>
                    </span>
                </div>
            </div>
        </div>
        
    </div>
}
