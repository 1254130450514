export const ICTagFree: React.FC = () => {
  return (
    <svg
      width="101"
      height="25"
      viewBox="0 0 101 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M90.4478 0H0V25H90.4478L101 12.5L90.4478 0Z" fill="#A6A8B4" />
    </svg>
  );
};
