import i18next from "../../i18n";

export type TTLevelItem = {
    id: number;
    title: string;
    content: string;
    subContent: string[];
};

export type TLevelData = {
    title: string;
    subTitle?: string[];
    data: TTLevelItem[];
};

export const LevelATTeam: TLevelData = {
    title: i18next.t('candidateDetail.levelEvaluationSystem'),
    data: [
        {
            id: 1,
            title: i18next.t('candidateDetail.levels.M.title'),
            content: i18next.t('candidateDetail.levels.M.content'),
            subContent: [
                i18next.t('candidateDetail.levels.M.subContent1'),
                i18next.t('candidateDetail.levels.M.subContent2'),
                i18next.t('candidateDetail.levels.M.subContent3'),
            ],
        },
        {
            id: 2,
            title: i18next.t('candidateDetail.levels.A.title'),
            content: i18next.t('candidateDetail.levels.A.content'),
            subContent: [
                i18next.t('candidateDetail.levels.A.subContent1'),
                i18next.t('candidateDetail.levels.A.subContent2'),
                i18next.t('candidateDetail.levels.A.subContent3'),
            ],
        },
        {
            id: 3,
            title: i18next.t('candidateDetail.levels.S.title'),
            content: i18next.t('candidateDetail.levels.S.content'),
            subContent: [
                i18next.t('candidateDetail.levels.S.subContent1'),
                i18next.t('candidateDetail.levels.S.subContent2'),
                i18next.t('candidateDetail.levels.S.subContent3'),
            ],
        },
        {
            id: 4,
            title: i18next.t('candidateDetail.levels.E.title'),
            content: i18next.t('candidateDetail.levels.E.content'),
            subContent: [
                i18next.t('candidateDetail.levels.E.subContent1'),
                i18next.t('candidateDetail.levels.E.subContent2'),
                i18next.t('candidateDetail.levels.E.subContent3'),
            ],
        },
    ]
}

export const LevelTalentCompass: TLevelData = {
    title: i18next.t('candidateDetail.talentCompassEvaluationSystem'),
    subTitle: [
        'E (Emerging)',
        'S (Skilled)',
        'A (Advanced)',
        'M (Master)',
    ],
    data: [
        {
            id: 1,
            title: i18next.t('candidateDetail.levels.M.title'),
            content: i18next.t('candidateDetail.levels.M.content'),
            subContent: [
                i18next.t('candidateDetail.levels.M.subContent1'),
                i18next.t('candidateDetail.levels.M.subContent2'),
                i18next.t('candidateDetail.levels.M.subContent3'),
            ],
        },
        {
            id: 2,
            title: i18next.t('candidateDetail.levels.A.title'),
            content: i18next.t('candidateDetail.levels.A.content'),
            subContent: [
                i18next.t('candidateDetail.levels.A.subContent1'),
                i18next.t('candidateDetail.levels.A.subContent2'),
                i18next.t('candidateDetail.levels.A.subContent3'),
            ],
        },
        {
            id: 3,
            title: i18next.t('candidateDetail.levels.S.title'),
            content: i18next.t('candidateDetail.levels.S.content'),
            subContent: [
                i18next.t('candidateDetail.levels.S.subContent1'),
                i18next.t('candidateDetail.levels.S.subContent2'),
                i18next.t('candidateDetail.levels.S.subContent3'),
            ],
        },
        {
            id: 4,
            title: i18next.t('candidateDetail.levels.E.title'),
            content: i18next.t('candidateDetail.levels.E.content'),
            subContent: [
                i18next.t('candidateDetail.levels.E.subContent1'),
                i18next.t('candidateDetail.levels.E.subContent2'),
                i18next.t('candidateDetail.levels.E.subContent3'),
            ],
        },
    ]
};
