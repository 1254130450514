import React, { useCallback, useEffect } from "react"
import { LibraryHeader } from "./header"
import { HRFilter } from "../../components/filter"
import { LibraryList } from "./list"
import { useDispatch } from "react-redux"
import { ISearchCriteria, ILibrary, IPagination } from './models';
import { setLoading } from "../../redux/slices/appInfo";
import { getTestLibrary } from "../../api/library";
import { ICClockTime, ICStar, ICUserV2 } from "../../icons/index";
import { useFilter, SearchParamsStateType } from './../../hooks/useFilter';
import { firstValueCommonData } from "../../redux/selectors/commonData";
import { valueFormatter } from "../../utils"
import { useTranslation } from "react-i18next"

export const LibraryPage: React.FC = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const languageDefault = firstValueCommonData('language', i18n.language) || '';
    const filtersDefaults: SearchParamsStateType = {
        page: { type: 'number', default: 1 },
        limit: { type: 'number', default: 12 },
        language: { type: 'string', default: null },
        searchText: { type: 'string', default: null },
        roles: { type: 'string', default: [], multiple: true },
        industry: { type: 'string', default: [], multiple: true },
        type: { type: 'string', default: null },
        statusTag: { type: 'string', default: null },
        planTag: { type: 'string', default: null },
    };

    const filterValuesDefaults: ISearchCriteria = {
        page: 1,
        limit: 12,
        searchText: '',
        language: '',
        roles: [],
        type: undefined,
        industry: [],
        statusTag: '',
        planTag: ''
    };
    const [data, setData] = React.useState<ILibrary[]>([]);
    const [pagination, setPagination] = React.useState<IPagination>();
    const [filterParams, setFilterParams] = useFilter(filtersDefaults);
    const loadData = React.useCallback(async (value?: ISearchCriteria) => {
        const newParams = value || filterParams;
        setFilterParams(newParams);
        dispatch(setLoading(true));
        newParams.language = newParams.language || languageDefault;

        try {
            const results = await getTestLibrary(newParams);
            const { data } = results || {};
            const newData = data.data || [];
            newData.map((d: ILibrary) => {
                d.tags = [];
                const sumQuestionDuration = Array.isArray(d.configuration?.questionDuration) ? d.configuration?.questionDuration.reduce((a, b) => a + b, 0) : d.configuration?.questionDuration;
                d.tags.push(
                    {
                        name: `${sumQuestionDuration || 0}p`,
                        icon: <ICClockTime width={12} height={12} stroke="#4F4B5C" />
                    },
                );
                if (d?.totalParticipate) {
                    d.tags.push(
                        {
                            name: `${valueFormatter(d?.totalParticipate)}`,
                            icon: <ICUserV2 width={12} height={12} stroke="#4F4B5C" />
                        }
                    );
                }
                d.tags.push(
                    {
                        name: d?.avg_rate || '',
                        icon: <ICStar />
                    }
                );
                return { ...d };
            });
            setData(newData);
            setPagination(data.metaData);
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, filterParams, languageDefault, setFilterParams]);

    useEffect(() => {
        if (languageDefault) {
            loadData();
        }
    }, [languageDefault]);

    const onChangeData = useCallback((values: any) => {
        const newParams = { ...filterParams, ...values };
        loadData(newParams);
    }, [filterParams]);

    const onReloadData = useCallback(() => {
        loadData(filterValuesDefaults);
    }, [filterValuesDefaults]);

    return (
        <div className="text-center bg-surface-low">
            <div className="pt-8 space-y-[45px]">
                <LibraryHeader />
                <HRFilter onChange={onChangeData} filterParams={filterParams} />
                <LibraryList data={data} filterParams={filterParams} keyword={filterParams?.searchText} pagination={pagination} onChange={onChangeData} onReloadData={onReloadData} />
            </div>
        </div>
    )
}
