import { convert as convertHTML } from 'html-to-text';
export const htmlToText = (html: string) => {
    return convertHTML(html ?? '', {
        formatters: {
            linkFormatter: function (elem, walk, builder, formatOptions) {
                builder.openBlock({ leadingLineBreaks: formatOptions.leadingLineBreaks || 1 });
                walk(elem.children, builder);
                builder.closeBlock({ trailingLineBreaks: formatOptions.trailingLineBreaks || 1 });
            },
            imageFormatter: function (elem, walk, builder, formatOptions) {
                builder.openBlock({ leadingLineBreaks: formatOptions.leadingLineBreaks || 1 });
                builder.addInline("[Image]")
                builder.closeBlock({ trailingLineBreaks: formatOptions.trailingLineBreaks || 1 });
            }
        },
        selectors: [
            {
                selector: 'a',
                format: 'linkFormatter',
                options: { leadingLineBreaks: 1, trailingLineBreaks: 1 }
            },
            {
                selector: 'img',
                format: 'imageFormatter',
                options: { leadingLineBreaks: 1, trailingLineBreaks: 1 }
            }
        ]
    })
}