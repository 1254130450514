import { IconProps } from "./type"

export const ICEmptyCandidate: React.FC<IconProps> = () => {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Image Placeholder" clipPath="url(#clip0_11252_12693)">
                <g id="BACKGROUND">
                    <path id="Vector" d="M100 0H0V100H100V0Z" fill="#FFBE54" />
                </g>
                <g id="OBJECT">
                    <path id="Vector_2" d="M100 67.8V100H0V67.8H32.636H67.86H100Z" fill="#D8983D" />
                    <path id="Vector_3" d="M72.876 74.826C76.8104 74.826 80 74.0488 80 73.09C80 72.1312 76.8104 71.354 72.876 71.354C68.9415 71.354 65.752 72.1312 65.752 73.09C65.752 74.0488 68.9415 74.826 72.876 74.826Z" fill="#BC8037" />
                    <path id="Vector_4" d="M71.244 24.7661C71.244 24.7661 70.216 24.7201 69.426 26.2221C68.636 27.7241 69.188 31.5341 69.188 31.5341H70.326V30.9161L70.526 31.5341H74.016L74.096 30.9961L74.238 31.5341H74.52L74.602 31.1341L74.736 31.5341H75.518C75.518 31.5341 76.548 26.3481 74.27 24.8621C72.762 23.8821 71.244 24.7661 71.244 24.7661Z" fill="#965B4D" />
                    <path id="Vector_5" d="M71.622 72.0099C71.622 72.0099 71.548 72.7919 71.542 72.9999C71.536 73.2079 71.296 73.6999 70.464 73.8759C69.632 74.0519 68.792 73.9099 68.792 73.2979C68.792 72.6859 69.956 71.0259 69.956 71.0259L71.522 70.8159L71.622 72.0099Z" fill="#723D33" />
                    <path id="Vector_6" d="M72.22 58.872C72.4221 61.0746 72.363 63.2933 72.044 65.482C71.52 69.258 71.52 70.402 71.52 70.402L70.2 70.612C70.2 70.612 70 68.07 70 65.412V58.698L72.22 58.872Z" fill="#965B4D" />
                    <path id="Vector_7" d="M70.2001 70.3999C70.2001 70.3999 69.4841 71.6119 69.6241 72.1599C69.6521 72.2659 70.5021 72.3699 70.9221 72.2479C71.1481 72.1819 71.3361 71.9959 71.4301 71.5179C71.4845 71.1477 71.5119 70.7741 71.5121 70.3999H70.2001Z" fill="#965B4D" />
                    <path id="Vector_8" d="M73.5879 71.7641C73.5879 71.7641 73.6639 72.5461 73.6679 72.7541C73.6719 72.9621 73.9139 73.4541 74.7459 73.6301C75.5779 73.8061 76.4179 73.6641 76.4179 73.0521C76.4179 72.4401 75.2539 70.7801 75.2539 70.7801L73.6879 70.5801L73.5879 71.7641Z" fill="#723D33" />
                    <path id="Vector_9" d="M72.9998 58.6259C72.7989 60.8285 72.858 63.0471 73.1758 65.2359C73.6998 69.0139 73.6998 70.1579 73.6998 70.1579L75.0118 70.3579C75.0118 70.3579 75.2118 67.8159 75.2118 65.1579V58.4519L72.9998 58.6259Z" fill="#965B4D" />
                    <path id="Vector_10" d="M71.3641 31.7461L68.3901 32.6461L69.7381 41.8881H75.1241L76.4301 32.7941L73.4461 31.7461H71.3641Z" fill="#965B4D" />
                    <path id="Vector_11" d="M71.4238 30.2319C71.4238 30.2319 71.5898 31.5419 71.1758 32.2319C71.3372 32.4179 71.5394 32.5642 71.7667 32.6592C71.9939 32.7543 72.24 32.7956 72.4858 32.7799C73.3638 32.7459 73.5978 32.3159 73.5978 32.3159C73.5978 32.3159 73.2318 31.3159 73.3478 30.4899C73.4638 29.6639 71.4238 30.2319 71.4238 30.2319Z" fill="#EABE9D" />
                    <path id="Vector_12" d="M74.0178 26.7639C73.5978 26.0579 72.4018 26.0139 72.4018 26.0139C72.4018 26.0139 71.2018 26.0579 70.7878 26.7639C70.7878 26.7639 70.3878 27.0299 70.4998 28.2019C70.6118 29.3739 70.6778 29.9279 70.7878 30.0599C70.8978 30.1919 71.7618 31.0119 72.4018 30.9659C73.0438 31.0119 73.9058 30.1919 74.0178 30.0599C74.1298 29.9279 74.1938 29.3739 74.3038 28.2019C74.4138 27.0299 74.0178 26.7639 74.0178 26.7639Z" fill="#FFE1C8" />
                    <path id="Vector_13" d="M69.7382 41.8879C68.8974 43.9516 68.3637 46.1274 68.1542 48.3459C67.8042 52.0279 69.7382 58.8839 69.7382 58.8839H75.4982C75.4982 58.8839 76.8982 51.0499 76.4302 47.7859C76.1423 45.7902 75.7057 43.8187 75.1242 41.8879H69.7382Z" fill="#B2715D" />
                    <path id="Vector_14" d="M75 70.158C75 70.158 75.716 71.358 75.576 71.9159C75.55 72.0219 74.698 72.1259 74.278 72.0039C74.052 71.938 73.864 71.752 73.77 71.274C73.7156 70.9044 73.6882 70.5315 73.688 70.158H75Z" fill="#965B4D" />
                    <path id="Vector_15" d="M68.4001 32.646C68.4001 32.646 67.1561 33.73 67.4321 35.988C67.7081 38.246 69.1841 42.258 69.1841 42.258L71.442 48.112L73.1021 47.512L70.58 40.782C70.58 40.782 69.4961 33.6 68.4001 32.646Z" fill="#D8983D" />
                    <path id="Vector_16" d="M76.2938 32.646C76.2938 32.646 77.5378 33.73 77.2618 35.988C76.9858 38.246 75.5098 42.258 75.5098 42.258L73.2518 48.112L71.5918 47.512L74.1038 40.782C74.1038 40.782 75.1878 33.6 76.2938 32.646Z" fill="#D8983D" />
                    <path id="Vector_17" d="M71.632 47.3999C71.632 47.3999 71.2779 48.5999 71.1859 48.8919C71.0939 49.1839 71.1639 50.0219 71.3099 50.0919C71.4559 50.1619 71.494 49.8599 71.464 49.6679C71.4509 49.4907 71.461 49.3125 71.494 49.1379L71.5919 49.1699C71.5463 49.5137 71.5463 49.8621 71.5919 50.2059C71.6779 50.5359 71.894 50.3439 71.886 50.1219C71.886 50.1219 71.94 50.3899 72.124 50.2299C72.308 50.0699 72.438 49.3919 72.438 49.3919C72.4516 49.5249 72.4516 49.6589 72.438 49.7919C72.416 49.9459 72.238 50.0539 72.392 50.1379C72.546 50.2219 72.708 49.9159 72.746 49.8219C72.784 49.7279 72.868 49.1159 72.868 49.1159C72.868 49.1159 73.294 48.2559 73.286 48.0359C73.278 47.8159 72.234 47.2679 71.632 47.3999Z" fill="#FFE1C8" />
                    <path id="Vector_18" d="M72.8158 25.2159C72.3059 24.9655 71.7438 24.8401 71.1758 24.8499C70.2838 24.8839 68.6158 26.8339 70.3498 27.9659H71.0158L71.1758 27.2339L71.3338 27.9659H72.4998L72.6838 27.2499L72.8338 27.9659H73.1158L73.2498 27.2499L73.4998 27.9659H74.4838C74.4838 27.9659 74.9158 26.9339 74.4838 26.0659C74.0518 25.1979 72.8158 25.2159 72.8158 25.2159Z" fill="#965B4D" />
                    <path id="Vector_19" d="M71.414 31.4839L72.414 32.7859C72.414 32.7859 72.014 33.3859 71.454 33.2999C70.8939 33.2139 70.752 31.9319 70.752 31.9319L71.414 31.4839Z" fill="#D8983D" />
                    <path id="Vector_20" d="M73.3818 31.4839L72.3818 32.7859C72.3818 32.7859 72.7818 33.3859 73.3418 33.2999C73.9018 33.2139 74.0438 31.9319 74.0438 31.9319L73.3818 31.4839Z" fill="#D8983D" />
                    <path id="Vector_21" d="M26.2021 74.826C30.1366 74.826 33.3261 74.0488 33.3261 73.09C33.3261 72.1312 30.1366 71.354 26.2021 71.354C22.2676 71.354 19.0781 72.1312 19.0781 73.09C19.0781 74.0488 22.2676 74.826 26.2021 74.826Z" fill="#BC8037" />
                    <path id="Vector_22" d="M25.716 69.8979V71.24H23.894L23.688 69.8979H25.716Z" fill="#965B4D" />
                    <path id="Vector_23" d="M23.926 71.036C23.926 71.036 22.304 72.546 21.69 72.862C21.076 73.178 21.224 73.738 22.378 73.794C23.532 73.85 24.728 73.292 25.118 73.272C25.508 73.252 25.96 73.176 25.994 73.03C26.194 72.192 25.716 71.03 25.716 71.03L23.926 71.036Z" fill="#723D33" />
                    <path id="Vector_24" d="M27.4761 69.8979V71.24H29.2981L29.5021 69.8979H27.4761Z" fill="#965B4D" />
                    <path id="Vector_25" d="M29.2662 71.036C29.2662 71.036 30.8882 72.546 31.5022 72.862C32.1162 73.178 31.9682 73.738 30.8122 73.794C29.6562 73.85 28.4642 73.292 28.0722 73.272C27.6802 73.252 27.2322 73.176 27.1962 73.03C26.9962 72.192 27.4762 71.03 27.4762 71.03L29.2662 71.036Z" fill="#723D33" />
                    <path id="Vector_26" d="M22.0078 45.8379V52.2539L23.2938 70.4679H26.1538L25.9478 61.0879V50.0939L26.2658 49.9039L26.7738 70.4679H29.8878L29.9498 49.2679L29.8238 45.8379H22.0078Z" fill="#B2715D" />
                    <path id="Vector_27" d="M24.8442 32.1279L21.1182 32.9859L21.5182 45.2979C21.5259 45.5279 21.6226 45.7458 21.7879 45.9058C21.9532 46.0659 22.1741 46.1555 22.4042 46.1559H29.2282C29.4819 46.1533 29.7251 46.0543 29.9086 45.879C30.092 45.7037 30.202 45.4653 30.2162 45.2119L30.7682 32.8599L26.7182 32.1279H24.8442Z" fill="#965B4D" />
                    <path id="Vector_28" d="M24.754 31.0359C24.7574 31.4955 24.6995 31.9535 24.582 32.3979C24.9798 32.7579 25.4919 32.9661 26.028 32.9859C26.868 32.9859 27.028 32.3979 27.028 32.3979C26.9108 31.9044 26.873 31.3953 26.916 30.8899L24.754 31.0359Z" fill="#EABE9D" />
                    <path id="Vector_29" d="M23.2118 27.6841C23.0964 27.8494 23.0267 28.0423 23.0099 28.2432C22.993 28.4441 23.0295 28.6458 23.1158 28.8281C23.4258 29.4281 23.8298 29.4481 23.8298 29.4481L23.5998 27.8001L23.2118 27.6841Z" fill="#FFE1C8" />
                    <path id="Vector_30" d="M28.3358 27.6841C28.4507 27.8497 28.5199 28.0426 28.5364 28.2435C28.5529 28.4443 28.5161 28.646 28.4298 28.8281C28.1218 29.4281 27.7158 29.4481 27.7158 29.4481L27.9498 27.8041L28.3358 27.6841Z" fill="#FFE1C8" />
                    <path id="Vector_31" d="M23.6001 26.8461C23.6001 26.8461 23.6301 29.7061 23.8581 30.1361C24.0861 30.5661 25.0881 31.4801 25.9761 31.5101C26.8641 31.5401 27.8641 30.2221 27.8921 29.7921C27.9201 29.3621 28.3201 26.3921 27.8921 25.8441C27.4641 25.2961 23.8261 24.3861 23.6001 26.8461Z" fill="#FFE1C8" />
                    <path id="Vector_32" d="M27.552 25.2179C27.4253 24.9277 27.2355 24.6695 26.9965 24.4619C26.7574 24.2543 26.4751 24.1026 26.17 24.0179C25.154 23.7639 24.724 24.5419 24.2 24.4939C23.676 24.4459 22.962 24.8939 23 25.4159C23.038 25.9379 22.4 26.1139 22.62 26.9079C22.84 27.7019 23.62 28.1479 23.62 28.1479C23.62 28.1479 24.124 27.5479 24.092 27.0839C24.06 26.6199 24.188 26.4159 24.41 26.3039C24.632 26.1919 24.934 26.1619 25.522 26.3359C26.11 26.5099 27.286 26.3679 27.286 26.3679C27.286 26.3679 27.382 26.7679 27.686 26.8599C27.99 26.9519 27.796 27.7519 28.044 28.0059C28.044 28.0059 28.768 27.2259 28.768 26.8439C28.768 26.4619 28.8 25.3459 27.552 25.2179Z" fill="#965B4D" />
                    <path id="Vector_33" d="M24.7202 31.6841L25.9402 32.9861L25.2162 33.5021L24.3042 32.2501L24.7202 31.6841Z" fill="#D8983D" />
                    <path id="Vector_34" d="M31.9498 35.2C31.7158 33.556 30.6798 32.834 30.6798 32.834L30.4378 40.234H22.7998V42.044C22.7998 42.044 29.6358 43.244 31.8858 42.87C34.4198 42.45 32.1078 36.302 31.9498 35.2Z" fill="#D8983D" />
                    <path id="Vector_35" d="M26.9221 31.6841L25.7021 32.9861L26.4241 33.5021L27.3382 32.2501L26.9221 31.6841Z" fill="#D8983D" />
                    <path id="Vector_36" d="M28.2522 39.8001L21.4002 40.1261L21.1242 32.9861C21.1242 32.9861 20.0882 33.7101 19.8542 35.3541C19.6942 36.4661 17.7962 42.2541 20.3362 42.6761C22.5582 43.0441 28.6622 41.6221 28.6622 41.6221L28.2522 39.8001Z" fill="#D8983D" />
                    <path id="Vector_37" d="M28.622 41.3999C28.8303 41.405 29.0385 41.3849 29.242 41.3399C29.528 41.2679 30.488 40.7799 30.776 40.7679C31.064 40.7559 30.976 40.5679 30.91 40.4699C30.8693 40.4076 30.8246 40.3481 30.776 40.2919C30.7003 40.1678 30.6175 40.0483 30.528 39.9339C30.4177 39.7845 30.2988 39.6415 30.172 39.5059C30.0602 39.4247 29.9329 39.3675 29.798 39.3379C29.742 39.3379 28.252 39.8859 28.252 39.8859L28.622 41.3999Z" fill="#FFE1C8" />
                    <path id="Vector_38" d="M23.412 39.476C23.008 39.1188 22.584 38.7849 22.142 38.476C21.84 38.318 20.846 38.436 20.576 38.66C20.306 38.884 20.608 38.93 20.806 38.874C20.9308 38.8397 21.0588 38.8183 21.188 38.81H21.362L21.348 38.932C21.1374 38.9709 20.9302 39.0257 20.728 39.096C20.41 39.232 20.354 39.446 20.512 39.47C20.6422 39.4784 20.7729 39.4635 20.898 39.426C20.898 39.426 20.536 39.612 20.592 39.716C20.648 39.82 21.056 39.752 21.118 39.728C21.2194 39.6997 21.3247 39.6875 21.43 39.692C21.43 39.692 20.862 39.834 20.814 40.018C20.766 40.202 21.034 40.172 21.184 40.164C21.334 40.156 21.688 40.116 21.688 40.116L23.836 40.004C23.7057 39.8194 23.5641 39.6431 23.412 39.476Z" fill="#FFE1C8" />
                    <path id="Vector_39" d="M10.4341 74.826C14.3685 74.826 17.5581 74.0488 17.5581 73.09C17.5581 72.1312 14.3685 71.354 10.4341 71.354C6.49958 71.354 3.31006 72.1312 3.31006 73.09C3.31006 74.0488 6.49958 74.826 10.4341 74.826Z" fill="#BC8037" />
                    <path id="Vector_40" d="M8.37624 28.168C8.37624 28.168 7.20824 28.49 7.28624 29.244C7.36424 29.998 6.76224 29.49 6.71624 30.366C6.71624 30.366 5.99424 30.796 6.53224 31.256C7.07024 31.716 7.76224 31.286 7.94624 31.256C8.13024 31.226 8.56024 31.696 8.99024 31.468C9.42024 31.24 9.73024 31.102 10.1522 31.302C10.5742 31.502 11.4482 31.472 11.7102 31.302C11.9722 31.132 12.5862 31.502 13.1102 31.416C13.6342 31.33 13.6322 31.272 14.0162 31.286C14.4002 31.3 14.6402 31.226 14.6502 30.796C14.6602 30.366 14.3702 30.168 14.0502 30.044C14.0502 30.044 14.2642 28.998 13.7422 28.676C13.2202 28.354 12.7902 28.106 12.6522 27.922C12.5142 27.738 8.37624 28.168 8.37624 28.168Z" fill="#965B4D" />
                    <path id="Vector_41" d="M9.84221 72.0099C9.84221 72.0099 9.76621 72.7919 9.76221 72.9999C9.75821 73.2079 9.51621 73.6999 8.68421 73.8759C7.85221 74.0519 7.01221 73.9099 7.01221 73.2979C7.01221 72.6859 8.17421 71.0259 8.17421 71.0259L9.74221 70.8159L9.84221 72.0099Z" fill="#723D33" />
                    <path id="Vector_42" d="M10.4381 58.872C10.6403 61.0745 10.5819 63.2932 10.2641 65.482C9.74007 69.258 9.74007 70.4 9.74007 70.4L8.42607 70.61C8.42607 70.61 8.22607 68.068 8.22607 65.41V58.698L10.4381 58.872Z" fill="#C17B5F" />
                    <path id="Vector_43" d="M8.42618 70.3999C8.42618 70.3999 7.71018 71.6119 7.85018 72.1599C7.87818 72.2659 8.72818 72.3699 9.14818 72.2479C9.37618 72.1819 9.56418 71.9959 9.65818 71.5179C9.71148 71.1476 9.73888 70.774 9.74018 70.3999H8.42618Z" fill="#C17B5F" />
                    <path id="Vector_44" d="M11.7998 71.7641C11.7998 71.7641 11.8758 72.5461 11.8798 72.7541C11.8838 72.9621 12.1258 73.4541 12.9598 73.6301C13.7938 73.8061 14.6298 73.6641 14.6298 73.0521C14.6298 72.4401 13.4678 70.7801 13.4678 70.7801L11.9018 70.5801L11.7998 71.7641Z" fill="#723D33" />
                    <path id="Vector_45" d="M11.21 58.6259C11.0078 60.8284 11.0662 63.0471 11.384 65.2359C11.91 69.0139 11.91 70.1579 11.91 70.1579L13.222 70.3579C13.222 70.3579 13.422 67.8159 13.422 65.1579V58.4519L11.21 58.6259Z" fill="#C17B5F" />
                    <path id="Vector_46" d="M9.58186 31.7461L6.60986 32.6461L7.95786 41.8881H13.3439L14.6479 32.7941L11.6639 31.7461H9.58186Z" fill="#D8983D" />
                    <path id="Vector_47" d="M9.64404 30.2319C9.64404 30.2319 9.80804 31.5419 9.39404 32.2319C9.55549 32.4179 9.7577 32.5642 9.98492 32.6592C10.2121 32.7543 10.4582 32.7956 10.704 32.7799C11.584 32.7459 11.816 32.3159 11.816 32.3159C11.816 32.3159 11.452 31.3159 11.568 30.4899C11.684 29.6639 9.64404 30.2319 9.64404 30.2319Z" fill="#EABE9D" />
                    <path id="Vector_48" d="M12.236 26.7639C11.816 26.0579 10.622 26.0139 10.622 26.0139C10.622 26.0139 9.42198 26.0579 9.00798 26.7639C9.00798 26.7639 8.60798 27.0299 8.71998 28.2019C8.83198 29.3739 8.89598 29.9279 9.00798 30.0599C9.11998 30.1919 9.97998 31.0119 10.622 30.9659C11.264 31.0119 12.126 30.1919 12.236 30.0599C12.346 29.9279 12.414 29.3739 12.524 28.2019C12.634 27.0299 12.236 26.7639 12.236 26.7639Z" fill="#FFE1C8" />
                    <path id="Vector_49" d="M7.95791 41.8879C7.11699 43.9517 6.58266 46.1274 6.37191 48.3459C6.02191 52.0279 7.95791 58.8839 7.95791 58.8839H13.7159C13.7159 58.8839 15.1159 51.0499 14.6479 47.7859C14.36 45.7903 13.9242 43.8189 13.3439 41.8879H7.95791Z" fill="#965B4D" />
                    <path id="Vector_50" d="M13.2222 70.158C13.2222 70.158 13.9382 71.358 13.7982 71.9159C13.7702 72.0219 12.9202 72.1259 12.5002 72.0039C12.2722 71.938 12.0842 71.752 11.9902 71.274C11.9372 70.9043 11.9104 70.5314 11.9102 70.158H13.2222Z" fill="#C17B5F" />
                    <path id="Vector_51" d="M10.2358 24.818C9.77319 24.8081 9.31046 24.8342 8.85184 24.896C7.77584 25.002 6.30185 26.34 7.69984 27.384C7.69984 27.384 7.49985 28.398 8.74785 28.49C8.74785 28.49 11.7058 28.106 11.8158 26.724C11.8158 26.724 12.0158 28.152 12.5438 28.414C12.5438 28.414 13.5078 27.312 13.3078 26.364C13.2705 26.1083 13.1589 25.8692 12.9868 25.6765C12.8146 25.4838 12.5897 25.3459 12.3398 25.28C12.3398 25.28 11.4338 24.326 10.2358 24.818Z" fill="#965B4D" />
                    <path id="Vector_52" d="M6.61001 32.646C6.61001 32.646 5.36401 33.73 5.64201 35.988C5.92001 38.246 7.40001 42.258 7.40001 42.258L9.66001 48.112L11.318 47.512L8.80001 40.782C8.80001 40.782 7.71601 33.6 6.61001 32.646Z" fill="#E5CB83" />
                    <path id="Vector_53" d="M14.5121 32.646C14.5121 32.646 15.7561 33.73 15.4801 35.988C15.2041 38.246 13.7281 42.258 13.7281 42.258L11.4701 48.112L9.81006 47.512L12.3221 40.782C12.3221 40.782 13.4001 33.6 14.5121 32.646Z" fill="#E5CB83" />
                    <path id="Vector_54" d="M9.85214 47.3999C9.85214 47.3999 9.49814 48.5999 9.40614 48.8919C9.31414 49.1839 9.38214 50.0219 9.52814 50.0919C9.67414 50.1619 9.71214 49.8599 9.68214 49.6679C9.67016 49.4907 9.68024 49.3126 9.71214 49.1379L9.81014 49.1699C9.76454 49.5137 9.76454 49.8621 9.81014 50.2059C9.89814 50.5359 10.1121 50.3439 10.1041 50.1219C10.1041 50.1219 10.1581 50.3899 10.3421 50.2299C10.5261 50.0699 10.6581 49.3919 10.6581 49.3919C10.6707 49.5249 10.6707 49.6589 10.6581 49.7919C10.6341 49.9459 10.4581 50.0539 10.6121 50.1379C10.7661 50.2219 10.9261 49.9159 10.9641 49.8219C11.0021 49.7279 11.0881 49.1159 11.0881 49.1159C11.0881 49.1159 11.5141 48.2559 11.5041 48.0359C11.4941 47.8159 10.4521 47.2679 9.85214 47.3999Z" fill="#FFE1C8" />
                    <path id="Vector_55" d="M87.7261 74.826C91.6617 74.826 94.8521 74.0488 94.8521 73.09C94.8521 72.1312 91.6617 71.354 87.7261 71.354C83.7905 71.354 80.6001 72.1312 80.6001 73.09C80.6001 74.0488 83.7905 74.826 87.7261 74.826Z" fill="#BC8037" />
                    <path id="Vector_56" d="M86.8961 69.8979V71.24H85.0761L84.8701 69.8979H86.8961Z" fill="#965B4D" />
                    <path id="Vector_57" d="M85.106 71.0359C85.106 71.0359 83.486 72.5459 82.87 72.8619C82.254 73.1779 82.4 73.7379 83.56 73.7999C84.72 73.8619 85.908 73.2979 86.3 73.2779C86.692 73.2579 87.14 73.1819 87.176 73.0359C87.376 72.1979 86.896 71.0359 86.896 71.0359H85.106Z" fill="#723D33" />
                    <path id="Vector_58" d="M88.6582 69.8979V71.24H90.4782L90.6842 69.8979H88.6582Z" fill="#965B4D" />
                    <path id="Vector_59" d="M90.4459 71.036C90.4459 71.036 92.0679 72.546 92.6839 72.862C93.2999 73.178 93.1499 73.738 91.9939 73.794C90.8379 73.85 89.6458 73.292 89.2538 73.272C88.8618 73.252 88.4139 73.176 88.3779 73.03C88.1779 72.192 88.6579 71.03 88.6579 71.03L90.4459 71.036Z" fill="#723D33" />
                    <path id="Vector_60" d="M83.1899 45.8379V52.2539L84.4759 70.4679H87.3359L87.1279 61.0879V50.0939L87.4459 49.9039L87.9539 70.4679H91.0679L91.1319 49.2679L91.0039 45.8379H83.1899Z" fill="#965B4D" />
                    <path id="Vector_61" d="M86.0238 32.1279L82.2998 32.9859L82.6998 45.2979C82.7076 45.5279 82.8043 45.7458 82.9695 45.9058C83.1348 46.0659 83.3557 46.1555 83.5858 46.1559H90.4098C90.664 46.1557 90.9084 46.0574 91.092 45.8816C91.2757 45.7058 91.3845 45.4659 91.3958 45.2119L91.9498 32.8599L87.8998 32.1279H86.0238Z" fill="#D8983D" />
                    <path id="Vector_62" d="M85.9342 31.0359C85.9386 31.4956 85.8808 31.9537 85.7622 32.3979C86.16 32.7579 86.672 32.9661 87.2082 32.9859C88.0502 32.9859 88.2082 32.3979 88.2082 32.3979C88.0911 31.9043 88.054 31.3952 88.0982 30.8899L85.9342 31.0359Z" fill="#EABE9D" />
                    <path id="Vector_63" d="M84.4002 27.6841C84.2853 27.8497 84.2162 28.0426 84.1997 28.2435C84.1831 28.4443 84.2199 28.646 84.3062 28.8281C84.6142 29.4281 85.0202 29.4481 85.0202 29.4481L84.7782 27.8001L84.4002 27.6841Z" fill="#FFE1C8" />
                    <path id="Vector_64" d="M89.5159 27.6841C89.6313 27.8494 89.701 28.0423 89.7178 28.2432C89.7347 28.4441 89.6982 28.6458 89.612 28.8281C89.302 29.4281 88.8979 29.4481 88.8979 29.4481L89.132 27.8041L89.5159 27.6841Z" fill="#FFE1C8" />
                    <path id="Vector_65" d="M84.7778 26.8461C84.7778 26.8461 84.8058 29.7061 85.0358 30.1361C85.2658 30.5661 86.2658 31.4801 87.1518 31.5101C88.0378 31.5401 89.0398 30.2221 89.0678 29.7921C89.0958 29.3621 89.4978 26.3921 89.0678 25.8441C88.6378 25.2961 84.9998 24.3861 84.7778 26.8461Z" fill="#FFE1C8" />
                    <path id="Vector_66" d="M85.9018 31.6841L87.1198 32.9861L86.3978 33.5021L85.4858 32.2501L85.9018 31.6841Z" fill="#965B4D" />
                    <path id="Vector_67" d="M93.1301 35.2C92.8961 33.556 91.8601 32.834 91.8601 32.834L91.6201 40.234H83.9761V42.044C83.9761 42.044 90.8121 43.244 93.0621 42.87C95.6001 42.45 93.2901 36.302 93.1301 35.2Z" fill="#965B4D" />
                    <path id="Vector_68" d="M88.1018 31.6841L86.8838 32.9861L87.6058 33.5021L88.5178 32.2501L88.1018 31.6841Z" fill="#965B4D" />
                    <path id="Vector_69" d="M89.4341 39.7999L82.5761 40.1239L82.3001 32.9839C82.3001 32.9839 81.2641 33.7079 81.0281 35.3519C80.8701 36.4639 78.9721 42.2519 81.5121 42.6739C83.7321 43.0419 89.8381 41.6199 89.8381 41.6199L89.4341 39.7999Z" fill="#965B4D" />
                    <path id="Vector_70" d="M89.8002 41.3999C90.0078 41.405 90.2154 41.3848 90.4182 41.3399C90.7042 41.2679 91.6662 40.7799 91.9542 40.7679C92.2422 40.7559 92.1542 40.5679 92.0862 40.4699C92.0466 40.4074 92.0025 40.3479 91.9542 40.2919C91.8785 40.1678 91.7957 40.0483 91.7062 39.9339C91.5958 39.784 91.4762 39.641 91.3482 39.5059C91.237 39.4249 91.1104 39.3677 90.9762 39.3379C90.9202 39.3379 89.4302 39.8859 89.4302 39.8859L89.8002 41.3999Z" fill="#FFE1C8" />
                    <path id="Vector_71" d="M84.6 39.476C84.1958 39.1184 83.771 38.7845 83.3281 38.476C83.0261 38.318 82.0341 38.436 81.7641 38.66C81.4941 38.884 81.7961 38.93 81.9941 38.874C82.1188 38.8396 82.2469 38.8181 82.3761 38.81H82.5501L82.536 38.932C82.3249 38.971 82.117 39.0258 81.9141 39.096C81.5961 39.232 81.5421 39.446 81.7001 39.47C81.8303 39.4782 81.961 39.4633 82.0861 39.426C82.0861 39.426 81.7241 39.612 81.7801 39.716C81.8361 39.82 82.2441 39.752 82.3061 39.728C82.4075 39.6997 82.5128 39.6875 82.6181 39.692C82.6181 39.692 82.0501 39.834 82.0021 40.018C81.9541 40.202 82.22 40.172 82.372 40.164C82.524 40.156 82.8761 40.116 82.8761 40.116L85.024 40.004C84.8938 39.8194 84.7522 39.6431 84.6 39.476Z" fill="#FFE1C8" />
                    <path id="Vector_72" d="M84.7778 28.364C84.7778 28.364 83.8838 27.1639 83.9498 26.2159C84.0158 25.2679 84.5158 25.1339 84.5158 25.1339C84.5158 25.1339 84.5158 24.5999 85.4158 24.2659C86.3158 23.9319 88.5318 24.6159 89.2658 24.7499C89.9998 24.8839 90.4158 24.6839 90.5338 25.0159C90.6518 25.3479 90.2338 25.7999 90.2338 25.7999C90.2338 25.7999 90.4998 25.856 90.4498 26.27C90.3998 26.684 89.8498 26.8159 89.8498 26.8159C89.8205 27.35 89.5947 27.8544 89.2158 28.2319C89.0679 27.7533 88.9615 27.2628 88.8978 26.7659C88.8978 26.7659 86.3258 26.9839 85.7618 26.4499C85.7618 26.4499 85.5618 27.1159 85.0838 27.2339C85.0838 27.2339 85.0378 27.978 84.7778 28.364Z" fill="#965B4D" />
                    <path id="Vector_73" d="M70.0159 80.008L31.0439 79.306L32.6359 67.8H67.8599L70.0159 80.008Z" fill="#E0C0A9" />
                    <path id="Vector_74" d="M67.8602 67.8H32.6362L42.0202 0H56.8662L67.8602 67.8Z" fill="#FFE1C8" />
                    <path id="Vector_75" d="M50.4398 85.1899C61.2513 85.1899 70.0158 82.8699 70.0158 80.0079C70.0158 77.146 61.2513 74.8259 50.4398 74.8259C39.6282 74.8259 30.8638 77.146 30.8638 80.0079C30.8638 82.8699 39.6282 85.1899 50.4398 85.1899Z" fill="#C6A58F" />
                    <path id="Vector_76" d="M48.014 78.4619C48.014 78.4619 47.364 80.3119 47.648 80.6779C47.932 81.0439 48.482 81.1779 49.082 81.1459C49.4234 81.1256 49.7498 80.999 50.0156 80.7838C50.2814 80.5686 50.4731 80.2756 50.564 79.9459C50.732 79.2119 50.448 77.6619 50.564 77.1959C50.68 76.7299 50.35 75.8779 50.35 75.8779L48.448 76.4619L48.014 78.4619Z" fill="#723D33" />
                    <path id="Vector_77" d="M52.0518 80.812C52.0518 80.812 52.1578 81.928 52.1658 82.228C52.1738 82.528 52.5178 83.228 53.7078 83.478C54.8978 83.728 56.0978 83.528 56.0978 82.654C56.0978 81.78 54.4338 79.4 54.4338 79.4L52.1998 79.104L52.0518 80.812Z" fill="#965B4D" />
                    <path id="Vector_78" d="M51.1999 62.0281C51.1999 62.0281 50.6999 66.0781 51.4499 71.4781C52.1999 76.8781 52.1999 78.5141 52.1999 78.5141L54.0759 78.8121C54.0759 78.8121 54.3499 75.1781 54.3499 71.3781V61.7781L51.1999 62.0281Z" fill="#723D33" />
                    <path id="Vector_79" d="M46.5482 24.3279L44.2822 25.5959L46.5482 38.0959H54.2482L56.1142 25.0959L51.8482 23.5959L46.5482 24.3279Z" fill="#B2715D" />
                    <path id="Vector_80" d="M48.9581 21.4299C48.9581 21.4299 49.2001 23.3039 48.6001 24.2999C48.8306 24.5664 49.1199 24.7758 49.4451 24.9115C49.7703 25.0472 50.1225 25.1055 50.4741 25.0819C51.7301 25.0339 52.0621 24.4179 52.0621 24.4179C52.0621 24.4179 51.5401 22.9959 51.7061 21.8179C51.8721 20.6399 48.9581 21.4299 48.9581 21.4299Z" fill="#EABE9D" />
                    <path id="Vector_81" d="M52.6661 16.474C52.0661 15.462 50.3581 15.3979 50.3581 15.3979C50.3581 15.3979 48.6501 15.462 48.0481 16.474C48.0481 16.474 47.4801 16.8539 47.6381 18.5299C47.7961 20.2059 47.8901 20.9959 48.0481 21.1859C48.2061 21.3759 49.4481 22.5439 50.3581 22.4819C51.2741 22.5439 52.5081 21.3739 52.6661 21.1859C52.8241 20.9979 52.9181 20.2039 53.0761 18.5299C53.2341 16.8559 52.6661 16.474 52.6661 16.474Z" fill="#FFE1C8" />
                    <path id="Vector_82" d="M51.9861 16.8699C51.9861 16.8699 51.9221 17.5319 50.7861 17.5799C49.6501 17.6279 49.4481 18.0119 49.0621 18.1339C48.6582 18.2679 48.2205 18.2594 47.8221 18.1099C47.8221 18.1099 47.7441 20.8859 48.0501 21.1859C48.3561 21.4859 49.0001 21.9859 49.0001 21.9859C49.0001 21.9859 49.3121 23.4099 49.1101 24.0039C48.9081 24.5979 48.8301 24.5339 48.8301 24.5339C48.8301 24.5339 48.9361 26.7219 47.4501 26.5799C45.9641 26.4379 45.9161 25.3319 45.9161 25.3319C45.543 25.1923 45.2218 24.9416 44.9957 24.6137C44.7697 24.2857 44.6497 23.8962 44.6521 23.4979C44.5881 22.0259 46.3601 21.5839 46.5481 20.7459C46.7361 19.9079 46.7861 18.7699 46.7221 18.7219C46.5671 18.6829 46.4242 18.6063 46.306 18.4988C46.1877 18.3914 46.0978 18.2564 46.0441 18.1059C46.0441 18.1059 45.1581 17.6799 45.4101 16.5559C45.6621 15.4319 46.6101 14.9439 47.5301 14.6919C48.4501 14.4399 49.2361 13.2919 50.6761 13.2919C51.0732 13.2838 51.4641 13.3916 51.8009 13.6023C52.1376 13.813 52.4057 14.1173 52.5721 14.4779C52.5721 14.4779 53.6941 14.7459 54.0581 15.7739C54.4221 16.8019 53.6921 18.1999 53.6921 18.1999C53.6921 18.1999 53.6141 20.1999 54.0561 20.9179C54.4981 21.6359 55.6561 22.7359 55.4161 23.6379C55.1761 24.5399 54.8001 24.5539 54.8001 24.5539C54.8001 24.5539 54.8321 26.0239 53.8981 26.2619C52.9641 26.4999 51.8701 25.8619 51.6281 24.8019C51.3861 23.7419 51.6881 22.0019 51.6881 22.0019C51.6881 22.0019 52.3341 21.4559 52.4761 20.8019C52.6181 20.1479 52.5701 18.1139 52.5701 18.1139C52.5701 18.1139 51.8001 17.8879 51.9861 16.8699Z" fill="#723D33" />
                    <path id="Vector_83" d="M54.7821 62.4001H46.5481C46.5481 62.4001 43.7821 52.6001 44.2821 47.3321C44.5298 44.8298 45.0544 42.3627 45.8461 39.9761C46.1941 38.9301 46.4741 38.2701 46.5361 38.1281V38.1021H54.2381C54.2381 38.1021 54.3321 38.3941 54.4801 38.9181C55.1872 41.4185 55.7297 43.9626 56.1041 46.5341C56.7821 51.2001 54.7821 62.4001 54.7821 62.4001Z" fill="#D8983D" />
                    <path id="Vector_84" d="M54.0742 78.514C54.0742 78.514 55.3842 80.382 54.8982 81.028C54.6622 81.342 53.6422 81.33 53.0422 81.154C52.7182 81.06 52.4422 80.796 52.3142 80.112C52.2379 79.582 52.1991 79.0474 52.1982 78.512L54.0742 78.514Z" fill="#723D33" />
                    <path id="Vector_85" d="M54.4901 38.9121L54.3481 38.8761C54.3481 38.8761 47.3621 38.1341 46.0141 43.4761C44.6661 48.8181 48.0001 62.4001 48.0001 62.4001H46.5481C46.5481 62.4001 43.7821 52.6001 44.2821 47.3321C44.5298 44.8298 45.0544 42.3627 45.8461 39.9761L46.5361 38.1281V38.1021H54.2381C54.2381 38.1021 54.3421 38.3881 54.4901 38.9121Z" fill="#BC7D33" />
                    <path id="Vector_86" d="M46.9482 62.3999C46.9482 62.3999 48.3222 74.4579 48.3982 75.5319C48.4742 76.6059 48.0482 78.1819 48.2482 78.4319C48.4482 78.6819 49.8482 78.8159 50.0482 78.4659C50.2482 78.1159 50.2102 76.6659 50.3102 76.3999C50.4102 76.1339 50.4482 69.7079 50.2362 67.1579C50.0242 64.6079 49.9222 63.1999 49.9222 63.1999V62.3999H46.9482Z" fill="#723D33" />
                    <path id="Vector_87" d="M54.6142 38.0939L49.3742 38.8119L48.2822 38.9619L46.0142 30.0939L47.0022 29.9259L52.6482 28.9619L54.6142 38.0939Z" fill="#FFBE54" />
                    <path id="Vector_88" d="M49.3742 38.812L48.2822 38.962L46.0142 30.094L47.0022 29.926L49.3742 38.812Z" fill="#D8983D" />
                    <path id="Vector_89" d="M51.8478 34.0961C50.8478 34.3201 46.7078 34.3321 44.9378 34.5861L45.3478 30.8461L44.2818 25.6001C44.2818 25.6001 43.4478 26.3001 43.0818 27.8321C42.7158 29.3641 41.9818 35.3001 42.2818 36.1321C42.3999 36.4969 42.6354 36.8125 42.9516 37.0295C43.2678 37.2466 43.6468 37.353 44.0298 37.3321C45.0118 37.3321 52.2818 35.8321 52.2818 35.8321L51.8478 34.0961Z" fill="#C17B5F" />
                    <path id="Vector_90" d="M50.6138 37.2619C51.6858 36.6619 53.8958 35.6039 55.7818 34.7619L55.0098 30.2259L56.1138 25.0959C56.1138 25.0959 56.9138 25.6759 57.2778 27.2079C57.6418 28.7399 58.1138 34.6239 58.0778 35.5079C57.9718 38.0279 50.8038 38.8759 50.8038 38.8759L50.6138 37.2619Z" fill="#C17B5F" />
                    <path id="Vector_91" d="M51.7578 34.1141C51.7578 34.1141 52.3978 33.5661 52.7358 33.2921C53.0298 33.0831 53.3884 32.9852 53.7478 33.0161C53.8978 33.0421 53.8598 33.2281 53.5978 33.3781C53.5978 33.3781 54.7358 33.2881 54.9178 33.4341C55.0998 33.5801 54.8598 33.8341 54.8598 33.8341C54.8598 33.8341 55.0718 33.9541 55.0718 34.1281C55.0718 34.3021 54.8478 34.4921 54.8478 34.4921C54.8813 34.5566 54.8966 34.6291 54.8921 34.7017C54.8875 34.7743 54.8632 34.8442 54.8218 34.9041C54.7375 35.0983 54.6189 35.2758 54.4718 35.4281C54.2598 35.6281 52.2818 35.8281 52.2818 35.8281C52.2818 35.8281 51.9698 35.2881 51.7578 34.1141Z" fill="#FFE1C8" />
                    <path id="Vector_92" d="M47.5379 37.8641C47.5379 37.8641 48.1559 37.3641 48.3899 37.1801C48.6239 36.9961 50.6139 37.2621 50.6139 37.2621L50.9819 38.7961C50.9819 38.7961 49.1139 39.6621 48.7819 39.6461C48.5881 39.6463 48.3959 39.6124 48.2139 39.5461C48.1479 39.5121 48.1559 39.2381 48.1559 39.2381C48.1559 39.2381 47.9719 39.2281 47.9139 39.1201C47.8769 39.0336 47.8598 38.94 47.8639 38.8461C47.8234 38.8444 47.7839 38.8328 47.7491 38.8122C47.7142 38.7916 47.6849 38.7627 47.6639 38.7281C47.5399 38.4961 47.6639 38.3781 47.6639 38.3781C47.6639 38.3781 47.2719 38.3301 47.5379 37.8641Z" fill="#FFE1C8" />
                    <path id="Vector_93" d="M47.9721 78.5861C47.9721 78.5861 47.2501 80.8501 47.2001 81.3421C47.1501 81.8341 47.3401 82.3001 48.3661 82.2661C48.822 82.2546 49.2635 82.104 49.6313 81.8344C49.9991 81.5649 50.2758 81.1893 50.4241 80.7581C50.6088 80.2205 50.7427 79.6667 50.8241 79.1041C50.9401 78.1661 51.3401 78.3661 51.1401 77.5501C51.0418 77.0956 50.8148 76.679 50.4861 76.3501C50.4861 76.3501 50.6081 78.2041 49.5741 78.8181C47.6621 79.9501 47.9721 78.5861 47.9721 78.5861Z" fill="#965B4D" />
                    <path id="Vector_94" d="M44.7661 34.6659L43.1001 35.6459C43.1001 35.6459 43.5141 34.3659 44.7661 34.6659Z" fill="#965B4D" />
                    <path id="Vector_95" d="M44.3999 26.1541L45.3359 30.7541C45.3359 30.7541 45.5739 27.3261 44.3999 26.1541Z" fill="#965B4D" />
                    <path id="Vector_96" d="M55.9262 25.6001L55.0002 30.2001C55.0002 30.2001 54.7482 26.7661 55.9262 25.6001Z" fill="#965B4D" />
                    <path id="Vector_97" d="M55.9258 35.0621L57.1998 35.8281C57.1998 35.8281 57.0178 34.6621 55.9258 35.0621Z" fill="#965B4D" />
                    <path id="Vector_98" d="M47.6938 41.8879C47.6938 41.8879 50.3618 50.3999 50.6138 56.1339C50.6138 56.1339 52.3878 47.5739 47.6938 41.8879Z" fill="#BC7D33" />
                    <path id="Vector_99" d="M20.386 81.866C20.386 81.866 17.838 83.518 17.218 87.922C16.598 92.326 16.722 100.144 16.722 100.144H25.902L20.386 81.866Z" fill="#FFE1C8" />
                    <path id="Vector_100" d="M16.9502 70.476L46.9582 62.158L60.4342 100.144H25.9002L16.9502 70.476Z" fill="#837296" />
                    <path id="Vector_101" d="M20.2881 72.5701L29.3061 70.0581L32.6641 79.4301L23.5461 82.2781L20.2881 72.5701Z" fill="#FFE1C8" />
                    <path id="Vector_102" d="M30.1761 77.434L30.6741 80.062L30.4981 80.116L25.7021 81.604L24.4541 79.418L25.3401 78.434L27.8181 77.292L30.1441 77.434H30.1761Z" fill="#B2715D" />
                    <path id="Vector_103" d="M26.0161 76.6C26.0161 76.6 26.4621 77.528 26.3461 78.124C26.5004 78.2251 26.676 78.2892 26.8592 78.3114C27.0423 78.3336 27.2281 78.3133 27.4021 78.252C28.0121 78.052 28.0681 77.684 28.0681 77.684C27.7779 77.302 27.5646 76.8673 27.4401 76.404C27.3161 75.772 26.0161 76.6 26.0161 76.6Z" fill="#EABE9D" />
                    <path id="Vector_104" d="M27 73.5479C26.524 73.1139 25.662 73.3119 25.662 73.3119C25.662 73.3119 24.822 73.5759 24.702 74.1779C24.702 74.1779 24.484 74.4499 24.86 75.2919C25.236 76.1339 25.422 76.5239 25.534 76.5999C25.646 76.6759 26.46 77.0999 26.9 76.9359C27.364 76.8379 27.77 76.0659 27.814 75.9459C27.858 75.8259 27.77 75.4119 27.56 74.5459C27.35 73.6799 27 73.5479 27 73.5479Z" fill="#FFE1C8" />
                    <path id="Vector_105" d="M26.7199 73.8419C26.7199 73.8419 26.8039 74.1899 26.2459 74.3699C25.6879 74.5499 25.6559 74.7699 25.4859 74.8919C25.3047 75.0169 25.0848 75.073 24.8659 75.0499C24.8659 75.0499 25.3179 76.4899 25.5239 76.5999C25.7299 76.7099 26.1239 76.8739 26.1239 76.8739C26.1239 76.8739 26.5239 77.5599 26.5339 77.8939C26.5439 78.2279 26.4879 78.2059 26.4879 78.2059C26.4879 78.2059 26.9259 79.3119 26.1719 79.4579C25.4179 79.6039 25.1999 79.0399 25.1999 79.0399C24.9843 79.0192 24.7794 78.9358 24.6106 78.8C24.4418 78.6643 24.3164 78.4821 24.2499 78.2759C23.9559 77.5239 24.7499 77.0439 24.6939 76.5839C24.6533 76.217 24.5618 75.8576 24.4219 75.5159C24.335 75.517 24.2491 75.4974 24.1712 75.4588C24.0934 75.4202 24.0257 75.3637 23.9739 75.2939C23.8118 75.2526 23.6667 75.1615 23.5591 75.0334C23.4515 74.9053 23.3867 74.7467 23.3739 74.5799C23.2999 73.9639 23.6859 73.5799 24.0979 73.3299C24.5099 73.0799 24.6979 72.3779 25.4259 72.1939C25.6323 72.1405 25.8495 72.1451 26.0535 72.2072C26.2574 72.2693 26.4403 72.3865 26.5819 72.5459C26.7662 72.5476 26.948 72.5895 27.1144 72.6686C27.2809 72.7477 27.4282 72.8621 27.5459 73.0039C27.9039 73.4759 27.7819 74.2839 27.7819 74.2839C27.7819 74.2839 28.0879 75.3139 28.4299 75.6139C28.7719 75.9139 29.5299 76.3079 29.5659 76.7979C29.6019 77.2879 29.4199 77.3499 29.4199 77.3499C29.4199 77.3499 29.6879 78.0919 29.2699 78.3499C28.8519 78.6079 28.2059 78.4419 27.9039 77.9399C27.6019 77.4379 27.4479 76.5019 27.4479 76.5019C27.5761 76.2843 27.6393 76.0344 27.6299 75.7819C27.521 75.3126 27.3813 74.851 27.2119 74.3999C27.2119 74.3999 26.7999 74.3879 26.7199 73.8419Z" fill="#723D33" />
                    <path id="Vector_106" d="M25.7022 81.6001L25.6542 81.6141L24.4882 81.9781C24.3782 81.4521 24.2882 81.0001 24.2682 80.7501C24.1948 80.2962 24.2596 79.8307 24.4542 79.4141L25.7022 81.6001Z" fill="#B2715D" />
                    <path id="Vector_107" d="M31.786 79.7179L30.674 80.0619L30.498 80.1159L30.144 77.4339V77.3799C30.5498 77.599 30.8854 77.9283 31.112 78.3299C31.26 78.5859 31.516 79.1179 31.786 79.7179Z" fill="#B2715D" />
                    <path id="Vector_108" d="M31.0698 70.2159L46.0638 66.0879L46.4318 66.9379L31.3818 71.1119L31.0698 70.2159Z" fill="#E1E1FF" />
                    <path id="Vector_109" d="M31.8521 72.5899L46.8441 68.4619L47.212 69.3139L32.1641 73.4859L31.8521 72.5899Z" fill="#E1E1FF" />
                    <path id="Vector_110" d="M32.6318 74.9659L47.6258 70.8359L47.9938 71.6879L32.9458 75.8619L32.6318 74.9659Z" fill="#E1E1FF" />
                    <path id="Vector_111" d="M33.4141 77.3399L48.4081 73.2119L48.7761 74.0639L33.7261 78.2359L33.4141 77.3399Z" fill="#E1E1FF" />
                    <path id="Vector_112" d="M24.1641 83.9679L50.0201 76.5139L50.3921 77.3639L24.4801 84.8619L24.1641 83.9679Z" fill="#E1E1FF" />
                    <path id="Vector_113" d="M24.9438 86.2899L50.7998 78.8379L51.1718 79.6879L25.2618 87.1859L24.9438 86.2899Z" fill="#E1E1FF" />
                    <path id="Vector_114" d="M26.0361 88.6119L51.8921 81.1599L52.2641 82.0099L26.3521 89.5079L26.0361 88.6119Z" fill="#E1E1FF" />
                    <path id="Vector_115" d="M26.9619 90.9359L52.8179 83.4819L53.1879 84.3319L27.2779 91.8299L26.9619 90.9359Z" fill="#E1E1FF" />
                    <path id="Vector_116" d="M27.7021 93.184L53.5582 85.73L53.9301 86.58L28.0202 94.078L27.7021 93.184Z" fill="#E1E1FF" />
                    <path id="Vector_117" d="M22.9258 95.2421C22.9258 95.2421 27.9818 85.0421 30.2858 84.8001C32.5898 84.5581 30.6858 91.8301 29.4378 93.3821C29.4378 93.3821 28.8178 98.3441 28.2598 100.144H21.7998L22.9258 95.2421Z" fill="#FFE1C8" />
                    <path id="Vector_118" d="M48.3818 93.3399L51.2358 94.7399L53.4698 89.4699L52.3098 88.9199L50.5758 92.8299L49.0058 91.9999L48.3818 93.3399Z" fill="#D8983D" />
                    <path id="Vector_119" d="M33.5642 95.7179L33.9042 96.8979L35.2682 96.4979L34.9282 95.3179L35.2962 95.2119L36.1102 98.0319L35.7422 98.1379L35.3602 96.8159L33.9962 97.2159L34.3782 98.5379L34.0142 98.6419L33.2002 95.8219L33.5642 95.7179Z" fill="#FCEBBB" />
                    <path id="Vector_120" d="M36.2898 94.9319L37.1018 97.7519L36.7378 97.8559L35.9258 95.0359L36.2898 94.9319Z" fill="#FCEBBB" />
                    <path id="Vector_121" d="M36.938 94.7859C37.1567 94.6782 37.385 94.5911 37.62 94.5259C38.008 94.4139 38.28 94.4119 38.502 94.5259C38.5942 94.5733 38.6754 94.6397 38.7402 94.7207C38.805 94.8017 38.852 94.8955 38.878 94.9959C38.9263 95.157 38.9208 95.3295 38.8623 95.4872C38.8038 95.6449 38.6956 95.7792 38.554 95.8699V95.8819C38.792 95.8939 38.98 96.0579 39.132 96.3319C39.2569 96.582 39.4041 96.8204 39.572 97.0439L39.196 97.1519C39.0484 96.958 38.9198 96.7504 38.812 96.5319C38.612 96.1679 38.424 96.0639 38.09 96.1459L37.748 96.2459L38.1 97.4679L37.736 97.5719L36.938 94.7859ZM37.672 95.9659L38.044 95.8579C38.434 95.7459 38.618 95.4579 38.526 95.1399C38.42 94.7759 38.112 94.6919 37.726 94.7999C37.604 94.8302 37.4864 94.8759 37.376 94.9359L37.672 95.9659Z" fill="#FCEBBB" />
                    <path id="Vector_122" d="M41.068 95.1759L39.972 95.4919L40.266 96.5079L41.488 96.1579L41.576 96.4619L40 96.9199L39.188 94.0999L40.71 93.6599L40.798 93.9659L39.64 94.2999L39.896 95.1919L40.992 94.8759L41.068 95.1759Z" fill="#FCEBBB" />
                    <path id="Vector_123" d="M41.2441 93.5441C41.4889 93.4323 41.7416 93.3387 42.0001 93.2641C42.5241 93.1121 42.9301 93.1261 43.2441 93.2861C43.4171 93.3769 43.5683 93.5042 43.6871 93.6593C43.8059 93.8144 43.8895 93.9935 43.9321 94.1841C44.0003 94.3816 44.0274 94.591 44.0119 94.7994C43.9965 95.0078 43.9387 95.2108 43.8421 95.3961C43.6561 95.7361 43.2621 95.9961 42.7121 96.1621C42.4919 96.2266 42.2682 96.2786 42.0421 96.3181L41.2441 93.5441ZM42.3261 95.9341C42.453 95.9158 42.578 95.887 42.7001 95.8481C43.4781 95.6241 43.7761 95.0681 43.5561 94.3061C43.3681 93.6401 42.8701 93.3241 42.1001 93.5461C41.9563 93.5856 41.8169 93.6399 41.6841 93.7081L42.3261 95.9341Z" fill="#FCEBBB" />
                    <path id="Vector_124" d="M28.2681 87.328L29.7921 88.028C29.7921 88.028 31.0201 86.046 30.3201 85.494C29.6201 84.942 28.2681 87.328 28.2681 87.328Z" fill="#EDCAB0" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_11252_12693">
                    <rect width="100" height="100" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
