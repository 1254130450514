import { ApiInstance } from "..";
import { LANGUAGE } from "../../utils/constants";

export const loginApi = (
  email: string,
  password: string,
  rememberPassword = false
) => {
  return ApiInstance.getInstance().post("/api/auth/login", {
    email,
    password,
    rememberPassword,
  });
};

export const registerApi = (email: string, password: string) => {
  const lang = localStorage.getItem("language") || LANGUAGE.VN;

  return ApiInstance.getInstance().post(
    "/api/auth/signup",
    {
      email,
      password,
    },
    {
      params: {
        lang,
      },
    }
  );
};

export const registerVerifyApi = (email: string, otp: string) => {
  return ApiInstance.getInstance().post("/api/auth/signup/verify", {
    email,
    otp,
  });
};

export const forgotPasswordApi = (email: string) => {
  const lang = localStorage.getItem("language") || LANGUAGE.VN;

  return ApiInstance.getInstance().post(
    "/api/auth/forgot-password",
    {
      email,
    },
    {
      params: {
        lang,
      },
    }
  );
};

export const forgotPasswordVerifyApi = (email: string, otp: string) => {
  return ApiInstance.getInstance().post("/api/auth/forgot-password/verify", {
    email,
    otp,
  });
};

export const resetPasswordApi = (
  email: string,
  newPassword: string,
  token: string
) => {
  return ApiInstance.getInstance().put(
    "/api/auth/forgot-password/reset-password",
    { email, newPassword, secondOtp: token }
  );
};

export const isExistedEmailApi = async (email: string) => {
  try {
    const result = await ApiInstance.getInstance().post(
      `/api/auth/checking-existed-user`,
      {
        email,
      }
    );
    return result.data.isExisted;
  } catch (err) {
    throw err;
  }
};

export const logout = () => {
  return ApiInstance.getInstance().post("/api/auth/logout", {});
};
