import i18next from "../../i18n";
import React from "react";

type AssistantEmptyStateProps = {
    onGetStarted: () => void;
}
export const AssistantEmptyState: React.FC<AssistantEmptyStateProps> = ({ onGetStarted }) => {
    return <div className="py-12 px-4 flex flex-col items-center justify-center gap-4">
        <img src={'/images/chat.svg'} className="w-[200px] h-[200px]" />

        <span className="text-[24px] font-medium text-[#0D121C]">
            {i18next.t('assisstant.assistantSection.title')}
        </span>

        <span className="text-[16px] text-[#9AA4B2]">
            {i18next.t('assisstant.assistantSection.description')}
        </span>
        <span
            onClick={onGetStarted}
            className="bg-[#0D121C] text-white text-[14px] font-bold py-3 px-4 rounded-lg cursor-pointer hover:bg-[#1E2433]"
        >
            {i18next.t('assisstant.assistantSection.buttonText')}
        </span>
    </div>
};
