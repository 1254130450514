import React from 'react';
import { useCandidateCreditPayment } from './hooks/useCandidateCreditPayment';
import { HeaderPayment } from './header';
import { TCandidateCreditInfo } from './type';
import { ListPayment } from './list';
import { PaymentMethod } from './payment-method';
import { TotalPayment } from './total';
import { FormInstance } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { EPaymentMethod } from '../payment/type';
import { TCompanyProfile } from '../../api/company-profile';
import { useMe } from '../../hooks/useMe';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { TUserInfo } from '../../redux/slices/userInfo';

type TCandidateCreditPage = {
    language: string;
    candidatePayment?: TCandidateCreditInfo;
    setCandidatePayment: React.Dispatch<React.SetStateAction<TCandidateCreditInfo>>;
    companyProfile: TCompanyProfile;
    setCompanyProfile: React.Dispatch<React.SetStateAction<TCompanyProfile>>;
    userInfo: TUserInfo,
    invoiceForm: FormInstance<any>,
    activeInvoice: boolean;
    setActiveInvoice: React.Dispatch<React.SetStateAction<boolean>>;
    discountCode: string;
    setDiscountCode: React.Dispatch<React.SetStateAction<string>>;
    currentPaymentMethod: EPaymentMethod;
    setCurrentPaymentMethod: React.Dispatch<React.SetStateAction<EPaymentMethod>>;

    quantity: number;
    setQuantity: React.Dispatch<React.SetStateAction<number>>;
    totalMoneyNotPaid: number;
}
export const CandidateCreditPageContext = React.createContext<TCandidateCreditPage>({} as TCandidateCreditPage);
export const useCandidateCreditPageContext = () => React.useContext(CandidateCreditPageContext);

export const CandidateCreditPage: React.FC = () => {
    const { i18n } = useTranslation();
    const { loadCandidatePayment, loadCompanyProfile } = useCandidateCreditPayment();
    const [candidatePayment, setCandidatePayment] = React.useState<TCandidateCreditInfo>({} as TCandidateCreditInfo);
    const [companyProfile, setCompanyProfile] = React.useState<TCompanyProfile>({} as TCompanyProfile);
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const { getMe } = useMe();
    const [invoiceForm] = useForm();
    const [activeInvoice, setActiveInvoice] = React.useState<boolean>(false);
    const [discountCode, setDiscountCode] = React.useState<string>('');
    const [currentPaymentMethod, setCurrentPaymentMethod] = React.useState<EPaymentMethod>(EPaymentMethod.MOMO);

    const [quantity, setQuantity] = React.useState<number>(1);

    React.useEffect(() => {
        Promise.all([
            loadCandidatePayment(setCandidatePayment),
            loadCompanyProfile(setCompanyProfile),
            getMe()
        ]);
    }, [loadCandidatePayment, loadCompanyProfile, getMe]);

    const totalMoneyNotPaid = React.useMemo(() => {
        if (candidatePayment?.creditPerQuantity && candidatePayment?.price?.price) {
            return candidatePayment?.creditPerQuantity * quantity * candidatePayment?.price?.price;
        }
        return 0;
    }, [candidatePayment, quantity]);

    return <CandidateCreditPageContext.Provider
        value={{
            language: i18n.language,
            candidatePayment,
            setCandidatePayment,
            companyProfile,
            setCompanyProfile,
            userInfo,
            invoiceForm,
            activeInvoice,
            setActiveInvoice,
            discountCode,
            setDiscountCode,
            currentPaymentMethod,
            setCurrentPaymentMethod,
            quantity,
            setQuantity,
            totalMoneyNotPaid,
        }}
    >
        <div className="lg-container mx-auto rounded-[12px] flex justify-start items-start pt-4 lg:pt-[45px] pb-[45px] px-2 lg:px-0">
            <div className="flex flex-col w-full items-start justify-center gap-4 lg:gap-[45px]">
                <HeaderPayment />
                <ListPayment />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-[16px] w-full">
                    <PaymentMethod />
                    <TotalPayment />
                </div>
            </div>
        </div>
    </CandidateCreditPageContext.Provider>
}
