import { combineReducers } from "redux";
import userInfo from "./slices/userInfo";
import appInfo from "./slices/appInfo";
import commonData from "./slices/commonData";

const rootReducer = combineReducers({ userInfo, appInfo, commonData });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
