import React from "react";
import { HRButton, IconButton } from "../../components/button";
import { ICAdd, ICRemove } from "../../icons";

type QualityPaymentProps = {
    quantity: number;
    setQuantity: React.Dispatch<React.SetStateAction<number>>;
};

export const QuantityPayment: React.FC<QualityPaymentProps> = ({
    quantity,
    setQuantity,
}) => {
    
    return <div className="text-left">
    <div className="flex items-center justify-between">
        <div className="flex items-center gap-[16px]">
            <IconButton
                btnSize="sm"
                btnType="sub"
                icon={<ICRemove />}
                onClick={() => {
                    quantity > 1 && setQuantity(prev => Math.max(prev - 1, 0))
                }}
                // disabled={quantity === 1}
            />
            <span className="text-high-em leading-[16px] text-[12px] font-bold">
                x{quantity}
            </span>
            <IconButton
                btnSize="sm"
                btnType="sub"
                icon={<ICAdd stroke="#4F4B5C" width={18} height={18} />}
                onClick={() => {
                    setQuantity(prev => prev + 1);
                }}
            />
        </div>
    </div>
</div>
}
