import { Checkbox, Radio } from "antd";
import { ContentState, Editor, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import React from "react";
import { EQuestionType, TChoiceQuestion } from "../../create_test/tab/question/type";
type TestingAnswerWrapperProps = {
    question: TChoiceQuestion;
    onChangeCorrectAnswer: (answer: number | undefined | number[]) => void;
    correctAnswer?: number | number[];
}

// Use for single choice and multiple choice only
export const TestingAnswerWrapper: React.FC<TestingAnswerWrapperProps> = ({
    question,
    onChangeCorrectAnswer,
    correctAnswer,
}) => {
    const onChangeCorrectAnswerWrapper = (index: number, isChecked?: boolean) => {
        if (question.type === "single_choice") {
            onChangeCorrectAnswer(index);
        } else {
            if (isChecked) {
                onChangeCorrectAnswer([...(correctAnswer as number[] ?? []), index]);
            } else {
                onChangeCorrectAnswer((correctAnswer as number[] ?? []).filter(item => item !== index));
            }
        }
    }
    return <div className='grid grid-cols-1 gap-[48px] answer-editor-view-only'>
        {
            question?.options?.map((answer, index) => {
                const isChecked = (index: number) => {
                    if (question.type === "single_choice") {
                        return correctAnswer === index;
                    } else {
                        if (!correctAnswer) {
                            return false;
                        }
                        if (Array.isArray(correctAnswer)) {
                            return correctAnswer.includes(index);
                        }
                        return ([correctAnswer] as number[] ?? [])?.includes(index);
                    }
                };
                return <AnswerEditor
                    key={answer?.id || index}
                    index={index}
                    answer={answer.content}
                    onChangeCorrectAnswer={onChangeCorrectAnswerWrapper}
                    isChecked={isChecked(index)}
                    questionType={question.type}
                />
            })
        }
    </div >
}

type TestingAnswerEditorProps = {
    questionType: EQuestionType.SINGLE_CHOICE | EQuestionType.MULTIPLE_CHOICE | EQuestionType.ESSAY;
    isChecked: boolean;
    onChangeCorrectAnswer: (index: number, isChecked?: boolean) => void;
    answer: string;
    index: number;
}
const AnswerEditor: React.FC<TestingAnswerEditorProps> = ({
    questionType,
    isChecked,
    onChangeCorrectAnswer,
    answer,
    index,
}) => {
    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(answer).contentBlocks))
    )

    React.useEffect(() => {
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(answer).contentBlocks)))
    }, [answer])

    const onEditorStateChange = (editorState: EditorState) => {
        // Do nothing
    }

    const onChangeAnswer = React.useCallback(() => {
        if (questionType === "single_choice") {
            onChangeCorrectAnswer(index);
        } else {
            onChangeCorrectAnswer(index, !isChecked);
        }
    }, [isChecked, index, onChangeCorrectAnswer, questionType]);
    return <div className="">
        <div
            className="flex items-center px-4 border border-n-150 rounded-[8px] cursor-pointer"
            onClick={onChangeAnswer}
        >
            {
                questionType === "single_choice" ? <Radio
                    checked={isChecked}
                    onChange={() => onChangeCorrectAnswer(index)}
                /> : <span className="pr-2">
                    <Checkbox
                        className="purple-checkbox"
                        checked={isChecked}
                        onChange={(e) => {
                            onChangeCorrectAnswer(index, e.target.checked);
                        }}
                    />
                </span>
            }
            <div className="relative">
                <Editor
                    stripPastedStyles={true}
                    readOnly={true}
                    editorState={editorState}
                    onChange={onEditorStateChange}
                />
            </div>
        </div>
    </div>
}
