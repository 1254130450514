import React from 'react';
import { useParams } from 'react-router-dom';
import { usePayment } from './hooks/usePayment';
import { HeaderPayment } from './header';
import { EPaymentMethod, TPaymentDetails } from './type';
import { ListPayment } from './list';
import { PaymentMethod } from './payment-method';
import { TotalPayment } from './total';
import { FormInstance } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { TCompanyProfile } from '../../api/company-profile';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useMe } from '../../hooks/useMe';
import { TUserInfo } from '../../redux/slices/userInfo';

type TPaymentPage = {
    id: string;
    language: string;
    paymentDetails?: TPaymentDetails;
    setPaymentDetails: React.Dispatch<React.SetStateAction<TPaymentDetails>>;
    companyProfile: TCompanyProfile;
    setCompanyProfile: React.Dispatch<React.SetStateAction<TCompanyProfile>>;
    userInfo: TUserInfo,
    invoiceForm: FormInstance<any>,
    activeInvoice: boolean;
    setActiveInvoice: React.Dispatch<React.SetStateAction<boolean>>;
    discountCode: string;
    setDiscountCode: React.Dispatch<React.SetStateAction<string>>;
    currentPaymentMethod: EPaymentMethod;
    setCurrentPaymentMethod: React.Dispatch<React.SetStateAction<EPaymentMethod>>;
}
export const PaymentPageContext = React.createContext<TPaymentPage>({} as TPaymentPage);
export const usePaymentPageContext = () => React.useContext(PaymentPageContext);

export const PaymentPage: React.FC = () => {
    const { id = '' } = useParams();
    const { i18n } = useTranslation();
    const { loadPaymentDetails, loadCompanyProfile } = usePayment();
    const [paymentDetails, setPaymentDetails] = React.useState<TPaymentDetails>({} as TPaymentDetails);
    const [companyProfile, setCompanyProfile] = React.useState<TCompanyProfile>({} as TCompanyProfile);
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const { getMe } = useMe();
    const [invoiceForm] = useForm();
    const [activeInvoice, setActiveInvoice] = React.useState<boolean>(false);
    const [discountCode, setDiscountCode] = React.useState<string>('');
    const [currentPaymentMethod, setCurrentPaymentMethod] = React.useState<EPaymentMethod>(EPaymentMethod.MOMO);

    React.useEffect(() => {
        if (id) {
            Promise.all([
                loadPaymentDetails(id, setPaymentDetails),
                loadCompanyProfile(setCompanyProfile),
                getMe()
            ]);
        }
    }, [id, loadPaymentDetails, loadCompanyProfile]);

    return <PaymentPageContext.Provider
        value={{
            id,
            language: i18n.language,
            paymentDetails,
            setPaymentDetails,
            companyProfile,
            setCompanyProfile,
            userInfo,
            invoiceForm,
            activeInvoice,
            setActiveInvoice,
            discountCode,
            setDiscountCode,
            currentPaymentMethod,
            setCurrentPaymentMethod,
        }}
    >
        <div className="lg-container mx-auto rounded-[12px] flex justify-start items-start pt-4 lg:pt-[45px] pb-[45px] px-2 lg:px-0">
            <div className="flex flex-col w-full items-start justify-center gap-4 lg:gap-[45px] ">
                <HeaderPayment />
                <ListPayment />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-[16px] w-full">
                    <PaymentMethod />
                    <TotalPayment />
                </div>
            </div>
        </div>
    </PaymentPageContext.Provider>
}
