import { ILibraryUI } from "../library/models";
import { TAdditionalQuestion } from "../my_assessment/create-assessment/type";

type TAssessment = {
    title: string;
    _id: string;
    activeDate: number;
    expireDate: number;
};

type TPricePayment = {
    price: number;
    tax: number;
    totalPrice: number;
    transactionFee: number;
    voucherDiscount?: number;
}

export type TPaymentDetails = {
    assessment: TAssessment;
    pricing: TPricePayment;
    paidTests: (TAdditionalQuestion | ILibraryUI)[];
};

export enum EPaymentMethod {
    VNPAY = "vnpay",
    MOMO = "momo",
};

export const PaymentMethosMapping = {
    [EPaymentMethod.VNPAY]: "VNPAY",
    [EPaymentMethod.MOMO]: "Ví MoMo",
};

type TInvoiceInfo = {
    buyerName: string;
    buyerLegalName: string;
    buyerTaxCode: string;
    buyerEmail: string;
    buyerAddress: string;
}

export type TPaymentRequest = {
    paymentMethod: EPaymentMethod;
    assessmentId: string;
    language: string;
    hasInvoice: boolean;
    invoiceInfo?: TInvoiceInfo;
};
