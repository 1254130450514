export const DEFAULT_QUESTIONS_TIMING = 10;
export const MAX_QUESTIONS_IN_GROUP = 100;

export const STATE_QUESTIONS: any[] = [
    {
        label: 'Active',
        value: true,
    },
    {
        label: 'Inactive',
        value: false,
    }
];

export const TYPE_QUESTIONS: any[] = [
    {
        label: 'Single choice',
        value: 'single_choice',
    },
    {
        label: 'Multi choice',
        value: 'multiple_choice',
    }
];
