import React from "react";
import { Form, Switch } from "antd";
import { HRInput } from "../../../../../components/form/input";
import { HRSelect } from "../../../../../components/form/select";
import { HRTextArea } from "../../../../../components/form/textarea";
import { HRForm } from "../../../../../components/form/form";
import { useAssementData } from "../../hooks/useCreateAssessment";
import { firstValueCommonData } from "../../../../../redux/selectors/commonData";
import { useTranslation } from "react-i18next";
import i18next from "../../../../../i18n";

export const GeneralStep: React.FC = () => {
    const {
        generalForm,
        setAssessment
    } = useAssementData();
    const { i18n } = useTranslation();
    const languageDefault = firstValueCommonData('language', i18n.language) || '';
    const onValuesChangeForm = React.useCallback((_: any, allValues: any) => {
        setAssessment((prev) => ({
            ...prev,
            ...allValues,
            language: allValues.language || '',
        }));
    }, [setAssessment]);

    React.useEffect(() => {
        generalForm.setFieldsValue({
            language: languageDefault,
        });
    }, [languageDefault, generalForm]);

    const childForm = (
        <div className="grid gap-[20px] grid-cols-[1fr]">
            <Form.Item
                name="title"
                rules={[
                    {
                        required: true,
                        message: i18next.t('createAssessment.haveNotEnterTitle') ?? '',
                    },
                ]}
            >
                <HRInput label={i18next.t('createAssessment.title') ?? ''} isRequired={true} maxLength={255} />
            </Form.Item>
            <div className="grid grid-cols-[1fr] lg:grid-cols-[1fr_1fr] lg:gap-8">
                <div className="grid grid-rows-[auto_1fr] gap-[21px]">
                    <Form.Item
                        name="role"
                        rules={[
                            {
                                required: true,
                                message: i18next.t('createAssessment.haveNotSelectPosition') ?? '',
                            },
                        ]}
                    >
                        <HRSelect
                            label={i18next.t('createAssessment.position') ?? ''}
                            name="position"
                            placeholder={i18next.t('createAssessment.select')}
                            isRequired={true}
                        />
                    </Form.Item>
                </div>

                <div className="grid grid-cols-[1fr] gap-[24px]">
                    <Form.Item name="language"
                        rules={[
                            {
                                required: true,
                                message: i18next.t('createAssessment.haveNotSelectLanguage') ?? '',
                            },
                        ]}
                    >
                        <HRSelect
                            label={i18next.t('createAssessment.language') ?? ''}
                            name="language"
                            placeholder={i18next.t('createAssessment.select')}
                            isRequired={true}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-[32px]">
                <Form.Item name="introduce">
                    <HRTextArea
                        label={i18next.t('createAssessment.introduce') ?? ''}
                        maxLength={3000}
                        style={{
                            height: "105px",
                        }}
                    />
                </Form.Item>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-[32px]">
                <Form.Item name="note">
                    <HRTextArea
                        label="Note"
                        maxLength={3000}
                        style={{
                            height: "105px",
                        }}
                    />
                </Form.Item>
                <div>
                    <div className="space-x-1 text-body-medium text-med-em">
                        {i18next.t('createAssessment.configure')}
                    </div>
                    <div className="flex gap-[8px] items-center">
                        <Form.Item
                            name="forceTurnOnCamera"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                        <span className="text-body text-high-em">
                            {i18next.t('createAssessment.forceTurnOnCamera')}
                        </span>
                    </div>
                </div>

            </div>
        </div>
    );

    return (
        <div className="bg-white p-2 lg:p-8 rounded-[8px]">
            <div className="mb-[24px] flex flex-col gap-[8px]">
                <div className="text-heading-6-bold text-high-em">{i18next.t('createAssessment.generalInfomation')}</div>
                <div className="text-med-em text-body">{i18next.t('createAssessment.refillInfomationToCreateAssessment')}</div>
            </div>
            <HRForm
                form={generalForm}
                name="createAssessment"
                onValuesChange={onValuesChangeForm}
                childNode={childForm}
            />
        </div>
    );
};
