import { IconProps } from "./type"

export const ICUploadCompany: React.FC<IconProps> = () => {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" className="none-hover">
            <g id="Vector">
                <path d="M12.7908 99.9999H76.6976C76.7005 97.6631 76.5138 95.3299 76.1395 93.0232C71.8604 61.5814 42.9767 37.2093 8.13953 37.2093C7.75135 37.2015 7.36306 37.2171 6.97674 37.2558C4.6449 37.2562 2.31516 37.396 0 37.6744L6.77218e-05 87.2092C6.77218e-05 87.6016 0.0177347 87.9898 0.0523166 88.3732C0.168505 89.6543 0.477613 90.9128 0.971142 92.1055C1.0325 92.2538 1.09659 92.4007 1.16337 92.5464C2.43448 95.3111 4.66263 97.5446 7.42344 98.8227C7.57891 98.8945 7.73594 98.9632 7.89446 99.0288C9.09282 99.5247 10.3576 99.8344 11.645 99.9493C12.0224 99.9828 12.4046 99.9999 12.7908 99.9999Z" fill="#E2E2E4" />
                <path d="M86.0464 26.7442C86.0464 33.8083 80.3199 39.5349 73.2558 39.5349C66.1917 39.5349 60.4651 33.8083 60.4651 26.7442C60.4651 19.6801 66.1917 13.9535 73.2558 13.9535C80.3199 13.9535 86.0464 19.6801 86.0464 26.7442Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M6.77218e-05 87.2092C6.77218e-05 87.6016 0.0177347 87.9898 0.0523166 88.3732C0.0172783 87.9869 -0.000215682 87.5985 6.77218e-05 87.2092ZM1.16337 92.5464C1.09659 92.4007 1.0325 92.2538 0.971142 92.1055C0.477613 90.9128 0.168505 89.6543 0.0523166 88.3732C0.185636 89.8511 0.570352 91.2566 1.16337 92.5464ZM7.42344 98.8227C4.66263 97.5446 2.43448 95.3111 1.16337 92.5464C1.79572 93.9253 2.66908 95.1819 3.74358 96.2564C4.81024 97.323 6.05633 98.1915 7.42344 98.8227ZM7.42344 98.8227C7.57891 98.8945 7.73594 98.9632 7.89446 99.0288C9.09282 99.5247 10.3576 99.8344 11.645 99.9493C10.1489 99.8165 8.7268 99.4261 7.42344 98.8227Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M87.2093 0H12.7908C5.72665 0 6.77218e-05 5.72659 6.77218e-05 12.7907L0 37.6744C2.31516 37.396 4.6449 37.2562 6.97674 37.2558C7.36306 37.2171 7.75135 37.2015 8.13953 37.2093C42.9767 37.2093 71.8604 61.5814 76.1395 93.0232C76.5138 95.3299 76.7005 97.6631 76.6976 99.9999H87.2093C94.2734 99.9999 100 94.2734 100 87.2092V12.7907C100 5.72659 94.2734 0 87.2093 0ZM73.2558 39.5349C80.3199 39.5349 86.0464 33.8083 86.0464 26.7442C86.0464 19.6801 80.3199 13.9535 73.2558 13.9535C66.1917 13.9535 60.4651 19.6801 60.4651 26.7442C60.4651 33.8083 66.1917 39.5349 73.2558 39.5349Z" fill="#ECECED" />
            </g>
        </svg>
    )
}

export const ICUploadCompanyHover: React.FC<IconProps> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
            <path d="M12.7908 100.075H76.6976C76.7005 97.7383 76.5138 95.4051 76.1395 93.0984C71.8604 61.6565 42.9767 37.2845 8.13953 37.2845C7.75135 37.2767 7.36306 37.2923 6.97674 37.331C4.6449 37.3314 2.31516 37.4711 0 37.7496L6.77218e-05 87.2844C6.77218e-05 87.6768 0.0177347 88.065 0.0523166 88.4484C0.168505 89.7295 0.477613 90.988 0.971142 92.1807C1.0325 92.3289 1.09659 92.4759 1.16337 92.6216C2.43448 95.3862 4.66263 97.6198 7.42344 98.8979C7.57891 98.9697 7.73594 99.0384 7.89446 99.104C9.09282 99.5999 10.3576 99.9096 11.645 100.025C12.0224 100.058 12.4046 100.075 12.7908 100.075Z" fill="#A0D2FF" />
            <path d="M86.0464 26.8194C86.0464 33.8835 80.3199 39.6101 73.2558 39.6101C66.1917 39.6101 60.4651 33.8835 60.4651 26.8194C60.4651 19.7553 66.1917 14.0287 73.2558 14.0287C80.3199 14.0287 86.0464 19.7553 86.0464 26.8194Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.77218e-05 87.2844C6.77218e-05 87.6768 0.0177347 88.065 0.0523166 88.4484C0.0172783 88.0621 -0.000215682 87.6737 6.77218e-05 87.2844ZM1.16337 92.6216C1.09659 92.4759 1.0325 92.3289 0.971142 92.1807C0.477613 90.988 0.168505 89.7295 0.0523166 88.4484C0.185636 89.9263 0.570352 91.3317 1.16337 92.6216ZM7.42344 98.8979C4.66263 97.6198 2.43448 95.3862 1.16337 92.6216C1.79572 94.0005 2.66908 95.257 3.74358 96.3316C4.81024 97.3982 6.05633 98.2667 7.42344 98.8979ZM7.42344 98.8979C7.57891 98.9697 7.73594 99.0384 7.89446 99.104C9.09282 99.5999 10.3576 99.9096 11.645 100.025C10.1489 99.8917 8.7268 99.5013 7.42344 98.8979Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M87.2093 0.0751953H12.7908C5.72665 0.0751953 6.77218e-05 5.80178 6.77218e-05 12.8659L0 37.7496C2.31516 37.4711 4.6449 37.3314 6.97674 37.331C7.36306 37.2923 7.75135 37.2767 8.13953 37.2845C42.9767 37.2845 71.8604 61.6565 76.1395 93.0984C76.5138 95.4051 76.7005 97.7383 76.6976 100.075H87.2093C94.2734 100.075 100 94.3485 100 87.2844V12.8659C100 5.80178 94.2734 0.0751953 87.2093 0.0751953ZM73.2558 39.6101C80.3199 39.6101 86.0464 33.8835 86.0464 26.8194C86.0464 19.7553 80.3199 14.0287 73.2558 14.0287C66.1917 14.0287 60.4651 19.7553 60.4651 26.8194C60.4651 33.8835 66.1917 39.6101 73.2558 39.6101Z" fill="#C2E2FF" />
        </svg>
    )
}
