import React from "react";
import { usePaymentPageContext } from ".";
import { HRForm } from "../../components/form/form";
import Form from "antd/lib/form";
import { HRInput } from "../../components/form/input";
import { HRTextArea } from "../../components/form/textarea";
import { EMAIL_REGEX } from "../../utils/constants";
import { HRTooltip } from "../../components/tooltip";
import i18next from "../../i18n";

export const InvoicePayment: React.FC = () => {
    const { invoiceForm, companyProfile, userInfo } = usePaymentPageContext();
    const childForm = (
        <div className="grid gap-[16px] grid-cols-[1fr]">
            <div className="grid grid-cols-[1fr_1fr] gap-[16px]">
                <Form.Item
                    name="buyerLegalName"
                    rules={[
                        {
                            required: true,
                            message: i18next.t('payment.enterCompanyName') ?? '',
                        },
                    ]}
                >
                    <HRInput label={i18next.t('payment.companyName') ?? ''} isRequired={true} maxLength={100} />
                </Form.Item>
                <HRTooltip 
                    content={i18next.t('payment.useForIssueInvoice') ?? ''}
                    childNode={
                        <Form.Item
                            name="buyerTaxCode"
                            rules={[
                                {
                                    required: true,
                                    message: i18next.t('payment.enterTaxCode') ?? '',
                                },
                            ]}
                        >
                            <HRInput label={i18next.t('payment.taxCode') ?? ''} isRequired={true} maxLength={14}/>
                        </Form.Item>
                    }
                />
            </div>
            <div className="grid grid-cols-[1fr_1fr] gap-[16px]">
                <Form.Item
                    name="buyerName"
                    rules={[
                        {
                            required: true,
                            message: i18next.t('payment.enterBuyerName') ?? '',
                        },
                    ]}
                >
                    <HRInput label={i18next.t('payment.buyerName') ?? ''} isRequired={true} maxLength={100} />
                </Form.Item>
                <Form.Item
                    name="buyerEmail"
                    rules={[
                        {
                            required: true,
                            message: i18next.t('payment.enterBuyerEmail') ?? '',
                        },
                        {
                            pattern: EMAIL_REGEX,
                            message: i18next.t('payment.invalidBuyerEmail') ?? '',
                        },
                    ]}
                >
                    <HRInput label="Email" isRequired={true} maxLength={100} />
                </Form.Item>
            </div>
            <Form.Item
                name="buyerAddress"
                rules={[
                    {
                        required: true,
                        message: i18next.t('payment.enterCompanyAddress') ?? '',
                    },
                ]}
            >
                <HRTextArea
                    label={i18next.t('payment.companyAddress') ?? ''}
                    maxLength={500}
                    style={{
                        height: "120px",
                    }}
                />
            </Form.Item>
        </div>
    );

    return <div className="flex flex-col w-full">
        <HRForm 
            form={invoiceForm} 
            name="createTest" 
            childNode={childForm} 
            fields={[
                {
                    name: ["buyerLegalName"],
                    value: companyProfile?.name,
                },
                {
                    name: ["buyerTaxCode"],
                    value: companyProfile?.taxCode,
                },
                {
                    name: ["buyerName"],
                    value: userInfo?.name,
                },
                {
                    name: ["buyerEmail"],
                    value: companyProfile?.email,
                },
                {
                    name: ["buyerAddress"],
                    value: companyProfile?.address,
                }
            ]}/>
    </div>
}
