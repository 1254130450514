import _ from "lodash"
import React from "react"

export const useSticky = () => {
    const [isSticky, setSticky] = React.useState(false);
    const element = React.useRef<HTMLDivElement>(null);

    const handleScroll = React.useCallback(_.debounce(() => {
        if (element.current) {
            window.scrollY > element.current.getBoundingClientRect().bottom
                ? setSticky(true)
                : setSticky(false)
        }
    }, 20, { trailing: true }), [element]);

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [handleScroll])

    return { isSticky, element }
}
