import { EQuestionType } from "../pages/create_test/tab/question/type";
import i18next from "../i18n";

export const WHITE_LIST_IMAGE_TYPES = ["image/png", "image/jpeg", "image/jpg"];
export const BACKGROUND_MAX_WIDTH = 1920;
export const BACKGROUND_MAX_HEIGHT = 1080;
export const AVATAR_MAX_WIDTH = 1080;
export const AVATAR_MAX_HEIGHT = 720;
export const EMAIL_REGEX = new RegExp(/^[A-Za-z0-9_!#$%&'*+\\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
export const NAME_REGEX = new RegExp(`^[a-z0-9A-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ .);('-]+$`);
export const EVENT_NAME_SOCKET = {
    SEND: {
        JOIN_TEST: "join-testing",
        ANSWER_QUESTION: "answer-question",
        SUBMIT_SECTION: "submit-section",
        SUBMIT_TEST: "submit-test",
        CHEATING_TEST: "cheating-test",
        UPLOAD_CAMERA_BUFFER: 'upload-camera-buffer',
        TURN_OFF_CAMERA: 'turn-off-camera',
        UPLOAD_EVENTS_TRACK: 'upload-events-track',
    },
    RECEIVE: {
        FINISH_SECTION: "finish-section",
        SYNC_ANSWER: "sync-answer",
    }
};

export enum LANGUAGE {
    VN = "vn",
    EN = "en",
};

export enum ERROR_CODE {
    EXPIRED = 1000,
    LOCKED = 1001,
    MAX_INVITE_CANDIDATE_PER_HOUR = 1002,
    ASSESSMENT_WAITING_FOR_PAYMENT = 1003,
    TEST_HAS_USED_IN_ASSESSMENT = 1101,
    PAYMENT_NOT_SUPPORT = 1200,
    TAX_CODE_INVALID = 1201,
    PAYMENT_FAILED = 1004,
};

export const ERROR_CODE_TITLE_MESSAGE = {
    [ERROR_CODE.LOCKED]: i18next.t('constant.positionJobClosed'),
};

export const ERROR_CODE_DESC_MESSAGE = {
    [ERROR_CODE.LOCKED]: i18next.t('constant.jobCloseDescriptionMessage'),
};

export const QuestionTypeMapping: Record<EQuestionType, string> = {
    [EQuestionType.SINGLE_CHOICE]: "Single Choice",
    [EQuestionType.MULTIPLE_CHOICE]: "Multiple Choice",
    [EQuestionType.CODING]: "Coding",
    [EQuestionType.ESSAY]: "Essay",
    [EQuestionType.GROUP]: "Group",
    [EQuestionType.SPEAKING]: "Speaking"
};

export const MAX_CONTENT_LENGTH = 10000;

export const QUESTIONS_TIMING = [
    {
        value: 5,
        label: `5 ${i18next.t('constant.minutes')}`
    },
    {
        value: 10,
        label: `10 ${i18next.t('constant.minutes')}`
    },
    {
        value: 12,
        label: `12 ${i18next.t('constant.minutes')}`
    },
    {
        value: 15,
        label: `15 ${i18next.t('constant.minutes')}`
    },
    {
        value: 20,
        label: `20 ${i18next.t('constant.minutes')}`
    },
    {
        value: 25,
        label: `25 ${i18next.t('constant.minutes')}`
    },
    {
        value: 30,
        label: `30 ${i18next.t('constant.minutes')}`
    },
    {
        value: 40,
        label: `40 ${i18next.t('constant.minutes')}`
    },
    {
        value: 50,
        label: `50 ${i18next.t('constant.minutes')}`
    },
    {
        value: 60,
        label: `60 ${i18next.t('constant.minutes')}`
    },
    {
        value: 90,
        label: `90 ${i18next.t('constant.minutes')}`
    },
    { 
        label: `120 ${i18next.t('constant.minutes')}`, 
        value: 120 
    },
    { 
        label: `180 ${i18next.t('constant.minutes')}`, 
        value: 180 
    },
    {
        label: `300 ${i18next.t('constant.minutes')}`,
        value: 300,
    },
];
