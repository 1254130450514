import React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getMyAssessment } from '../../api/assessment';
import { HRButton } from "../../components/button"
import { Filter } from './filter';
import { SearchParamsStateType, useFilter } from '../../hooks/useFilter';
import { ICAdd } from "../../icons"
import { setLoading } from '../../redux/slices/appInfo';
import { IPagination } from '../library/models';
import { AssessmentPageList } from "./list"
import { EStatusAssessment, ISearchMyAssessment, TAssessmentData, TWaitingPaymentOrder } from './type';
import { WaitingPayment } from './waitingPayment';
import { getMyWaitingForPaymentOrder } from '../../api/order';
import i18next from "../../i18n";

type TSortData = {
    field?: string;
    order?: 'asc' | 'desc';
}
type TSortDataContext = {
    sortData: TSortData;
    setSortData: (data: TSortData) => void;
}
const SortDataContext = React.createContext<TSortDataContext>({} as TSortDataContext);
export const useSortData = () => React.useContext(SortDataContext);

export const MyAssessmentPage: React.FC = () => {
    const dispatch = useDispatch();
    const [sortData, setSortData] = React.useState<TSortData>({ field: 'createdAt', order: 'desc' });
    const [pagination, setPagination] = React.useState<IPagination>();
    const [status, setStatus] = React.useState<EStatusAssessment>();
    const [waitingPaymentList, setWaitingPaymentList] = React.useState<TWaitingPaymentOrder[]>([]);

    const filtersDefaults: SearchParamsStateType = {
        page: { type: 'number', default: 1 },
        limit: { type: 'number', default: 5 },
        title: { type: 'string', default: null },
        orderBy: { type: 'string', default: "createdAt::desc" },
        status: { type: 'string', default: null },
    }
    const [filterParams, setFilterParams] = useFilter(filtersDefaults);
    const [assessmentList, setAssessmentList] = React.useState<TAssessmentData[]>([]);
    const onChangeSort = useCallback((data: TSortData) => {
        setSortData(data);
        const formatSortData = `${data.field}::${data.order}`;
        onChangeFilter({
            ...filterParams,
            orderBy: formatSortData,
            page: 1
        })
    }, [filterParams]);

    const loadWaitingPayment = async () => {
        try {
            const results = await getMyWaitingForPaymentOrder();
            const newData = (results.data || []) as TWaitingPaymentOrder[];
            setWaitingPaymentList(newData);
        } catch (e) {
            console.error(e)
        }
    }

    React.useEffect(() => {
        loadWaitingPayment();
        loadData(filterParams);
    }, []);

    const onChangeFilter = useCallback((values: any) => {
        const newParams = { ...filterParams, ...values };
        loadData(newParams);
    }, [filterParams]);

    const loadData = async (value?: ISearchMyAssessment) => {
        const newParams = value || filterParams;
        const currentStatus = newParams.status; // || EStatusAssessment.ACTIVE;
        const payload = {
            ...newParams,
            status: currentStatus
        }
        setStatus(currentStatus);
        setFilterParams(payload);
        dispatch(setLoading(true));
        try {
            const results = await getMyAssessment(payload);
            const { data } = results || {};
            const newData = data.data || [];
            setAssessmentList(newData);
            setPagination(data.metaData);
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    };

    const reloadData = useCallback(() => {
        loadData(filterParams);
    }, [filterParams]);

    return (
        <div className="text-center">
            <div className="px-2 lg:px-0 pt-2 lg:pt-8 space-y-6 min-h-screen">
                <div className="pb-16 space-y-8 text-left lg-container mx-auto">
                    <SortDataContext.Provider value={{ sortData, setSortData: onChangeSort }}>
                        <TestHeader />
                        <div className="flex flex-col-reverse lg:flex-row justify-between items-end relative gap-2">
                            <Filter
                                status={status}
                                filterParams={filterParams}
                                setStatus={setStatus}
                                onChangeFilter={onChangeFilter}
                            />
                            {waitingPaymentList?.length > 0 && <WaitingPayment waitingPaymentList={waitingPaymentList} />}
                        </div>
                        <AssessmentPageList
                            assessmentList={assessmentList}
                            status={status}
                            setAssessmentList={setAssessmentList}
                            reloadData={reloadData}
                            setStatus={setStatus}
                            keyword={filterParams?.title}
                            filterParams={filterParams}
                            onChangeFilter={onChangeFilter}
                            pagination={pagination}
                            onChangePaging={onChangeFilter}
                        />
                    </SortDataContext.Provider>
                </div>
            </div>
        </div>
    )
}

const TestHeader: React.FC = () => {
    const navigate = useNavigate();
    const goCreateTest = useCallback(() => {
        navigate('/create-assessment');
    }, [navigate]);
    return (
        <div className="lg:flex items-center justify-between space-y-4 lg:space-y-2">
            <div className="flex space-x-2 items-center text-heading-5-semi-bold text-high-em" >
                <span>{i18next.t('assessmentDetail.assessments')}</span>
            </div>
            <div>
                <HRButton btnType="primary" btnSize="md" onClick={goCreateTest}>
                    <div className="flex space-x-2 justify-center">
                        <ICAdd />
                        <span>{i18next.t('assessmentDetail.createAssessment')}</span>
                    </div>
                </HRButton>
            </div>
        </div>
    )
}
