import { IconProps } from "./type"

export const ICCheck: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="11" fill="#4D87FA" />
            <path d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4852L15.8995 7.82837L17.3137 9.24258L10.2426 16.3137Z" fill="white" />
        </svg>
    )
}

export const ICCircleCheck: React.FC = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.2" y="1.2" width="37.6" height="37.6" rx="18.8" fill="#F1E8FD" />
            <path d="M28 14L17 25L12 20" stroke="#AF7DE7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="1.2" y="1.2" width="37.6" height="37.6" rx="18.8" stroke="#AF7DE7" strokeWidth="2.4" />
        </svg>
    )
}

export const ICCheckV2: React.FC<IconProps> = ({
    fill = "white",
    width = 24,
    height = 24,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.7071 5.29289C21.0976 5.68342 21.0976 6.31658 20.7071 6.70711L9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929C3.68342 10.9024 4.31658 10.9024 4.70711 11.2929L9 15.5858L19.2929 5.29289C19.6834 4.90237 20.3166 4.90237 20.7071 5.29289Z" fill={fill} />
        </svg>
    )
}

export const ICCheckFill: React.FC<IconProps> = ({
    fill = "#4DCC8F",
    width = 24,
    height = 24,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 23C18.0751 23 23 18.0751 23 12C23 9.61495 22.2409 7.40719 20.9512 5.60512L10.2529 16.3035C10.2472 16.3091 10.2381 16.3091 10.2324 16.3035L6.29289 12.3639C6.10536 12.1764 6 11.9221 6 11.6568C6 11.1046 6.44772 10.6568 7 10.6568C7.26522 10.6568 7.51957 10.7622 7.70711 10.9497L10.2426 13.4853L19.6409 4.08696C17.6621 2.17576 14.9684 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" fill={fill} />
        </svg>

    )
}

export const ICCheckAll: React.FC<IconProps> = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.2159 3.76594C8.41205 3.96031 8.41349 4.27689 8.21912 4.47304L6.60516 6.10174C6.41079 6.29789 6.09421 6.29933 5.89806 6.10496C5.70191 5.91058 5.70047 5.59401 5.89484 5.39786L7.5088 3.76915C7.70318 3.573 8.01975 3.57156 8.2159 3.76594ZM10.717 3.76752C10.9123 3.96277 10.9123 4.27935 10.7171 4.47463L6.47489 8.71729C6.38113 8.81106 6.25395 8.86375 6.12133 8.86375C5.98872 8.86376 5.86154 8.81108 5.76777 8.71731L3.64645 6.59599C3.45118 6.40072 3.45118 6.08414 3.64645 5.88888C3.84171 5.69362 4.15829 5.69362 4.35355 5.88888L6.1213 7.65663L10.0099 3.76756C10.2052 3.57229 10.5218 3.57227 10.717 3.76752ZM1.14645 5.88888C1.34171 5.69362 1.65829 5.69362 1.85355 5.88888L3.97487 8.0102C4.17014 8.20546 4.17014 8.52204 3.97487 8.71731C3.77961 8.91257 3.46303 8.91257 3.26777 8.71731L1.14645 6.59599C0.951184 6.40072 0.951184 6.08414 1.14645 5.88888Z" fill="#676472" />
        </svg>
    )
}

export const ICCheckMultiComplete: React.FC<IconProps> = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6667 2H5.33333C4.44928 2 3.60143 2.35119 2.97631 2.97631C2.35119 3.60143 2 4.44928 2 5.33333V18.6667C2 19.5507 2.35119 20.3986 2.97631 21.0237C3.60143 21.6488 4.44928 22 5.33333 22H18.6667C19.5507 22 20.3986 21.6488 21.0237 21.0237C21.6488 20.3986 22 19.5507 22 18.6667V5.33333C22 4.44928 21.6488 3.60143 21.0237 2.97631C20.3986 2.35119 19.5507 2 18.6667 2ZM16.7778 9.34444L11.7 16.0111C11.5965 16.1456 11.4636 16.2545 11.3114 16.3297C11.1592 16.4048 10.9919 16.444 10.8222 16.4444C10.6535 16.4454 10.4867 16.4078 10.3346 16.3346C10.1825 16.2615 10.0491 16.1546 9.94444 16.0222L7.23333 12.5667C7.1436 12.4514 7.07744 12.3196 7.03865 12.1787C6.99986 12.0379 6.98918 11.8908 7.00724 11.7458C7.02529 11.6009 7.07173 11.4609 7.14388 11.3339C7.21604 11.2069 7.31251 11.0953 7.42778 11.0056C7.66058 10.8243 7.95584 10.743 8.2486 10.7795C8.39357 10.7975 8.53355 10.8439 8.66057 10.9161C8.78759 10.9883 8.89915 11.0847 8.98889 11.2L10.8 13.5111L15 7.95556C15.089 7.83882 15.2001 7.74077 15.327 7.66699C15.4539 7.5932 15.5941 7.54514 15.7396 7.52553C15.8851 7.50593 16.033 7.51517 16.1749 7.55273C16.3168 7.59029 16.4499 7.65544 16.5667 7.74444C16.6834 7.83345 16.7815 7.94458 16.8552 8.07148C16.929 8.19838 16.9771 8.33858 16.9967 8.48406C17.0163 8.62953 17.0071 8.77745 16.9695 8.91936C16.9319 9.06126 16.8668 9.19438 16.7778 9.31111V9.34444Z" fill="#0BAA60" />
        </svg>
    )
}

export const ICCheckSingleComplete: React.FC<IconProps> = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13272_3439)">
                <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693604 16.6688 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C24 10.4241 23.6896 8.8637 23.0866 7.4078C22.4835 5.95189 21.5996 4.62902 20.4853 3.51472C19.371 2.40041 18.0481 1.5165 16.5922 0.913445C15.1363 0.310389 13.5759 0 12 0ZM17.16 9.132L11.676 16.332C11.5642 16.4772 11.4206 16.5949 11.2563 16.676C11.092 16.7571 10.9113 16.7996 10.728 16.8C10.5457 16.801 10.3656 16.7604 10.2014 16.6814C10.0371 16.6024 9.89301 16.487 9.78 16.344L6.852 12.612C6.75509 12.4875 6.68364 12.3451 6.64174 12.193C6.59985 12.0409 6.58832 11.8821 6.60782 11.7255C6.62732 11.5689 6.67747 11.4178 6.7554 11.2806C6.83332 11.1434 6.93751 11.0229 7.062 10.926C7.31343 10.7303 7.63231 10.6424 7.94849 10.6818C8.10505 10.7013 8.25624 10.7515 8.39342 10.8294C8.5306 10.9073 8.65109 11.0115 8.748 11.136L10.704 13.632L15.24 7.632C15.3361 7.50593 15.4561 7.40003 15.5932 7.32034C15.7303 7.24065 15.8817 7.18874 16.0388 7.16757C16.1959 7.1464 16.3556 7.15638 16.5089 7.19695C16.6622 7.23751 16.8059 7.30787 16.932 7.404C17.0581 7.50012 17.164 7.62014 17.2437 7.7572C17.3233 7.89425 17.3753 8.04566 17.3964 8.20278C17.4176 8.35989 17.4076 8.51964 17.367 8.6729C17.3265 8.82616 17.2561 8.96993 17.16 9.096V9.132Z" fill="#0BAA60" />
            </g>
            <defs>
                <clipPath id="clip0_13272_3439">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export const ICCheckBoard: React.FC<IconProps> = ({
    fill = "var(--text-primary)",
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M7 3.875C7 2.77043 7.89543 1.875 9 1.875H15C16.1046 1.875 17 2.77043 17 3.875V5.875C17 6.97957 16.1046 7.875 15 7.875H9C7.89543 7.875 7 6.97957 7 5.875V3.875ZM15 3.875H9V5.875H15V3.875Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M6 5.875C5.73478 5.875 5.48043 5.98036 5.29289 6.16789C5.10536 6.35543 5 6.60978 5 6.875V20.875C5 21.1402 5.10536 21.3946 5.29289 21.5821C5.48043 21.7696 5.73478 21.875 6 21.875H18C18.2652 21.875 18.5196 21.7696 18.7071 21.5821C18.8946 21.3946 19 21.1402 19 20.875V6.875C19 6.60978 18.8946 6.35543 18.7071 6.16789C18.5196 5.98036 18.2652 5.875 18 5.875H16C15.4477 5.875 15 5.42728 15 4.875C15 4.32272 15.4477 3.875 16 3.875H18C18.7956 3.875 19.5587 4.19107 20.1213 4.75368C20.6839 5.31629 21 6.07935 21 6.875V20.875C21 21.6707 20.6839 22.4337 20.1213 22.9963C19.5587 23.5589 18.7957 23.875 18 23.875H6C5.20435 23.875 4.44129 23.5589 3.87868 22.9963C3.31607 22.4337 3 21.6707 3 20.875V6.875C3 6.07935 3.31607 5.31629 3.87868 4.75368C4.44129 4.19107 5.20435 3.875 6 3.875H8C8.55228 3.875 9 4.32272 9 4.875C9 5.42728 8.55228 5.875 8 5.875H6Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.7071 12.1679C16.0976 12.5584 16.0976 13.1916 15.7071 13.5821L11.7071 17.5821C11.3166 17.9726 10.6834 17.9726 10.2929 17.5821L8.29289 15.5821C7.90237 15.1916 7.90237 14.5584 8.29289 14.1679C8.68342 13.7774 9.31658 13.7774 9.70711 14.1679L11 15.4608L14.2929 12.1679C14.6834 11.7774 15.3166 11.7774 15.7071 12.1679Z" fill={fill} />
        </svg>
    )
}

