import { IconProps } from "./type"

export const ICMilkTea: React.FC<IconProps> = () => {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2301_65094)">
                <path d="M50 4.29688C23.5723 4.29688 2.14844 25.7207 2.14844 52.1484C2.14844 64.5621 6.87598 75.8711 14.6281 84.375L15.8072 84.5703H84.193L85.3721 84.375C93.124 75.8711 97.8516 64.5621 97.8516 52.1484C97.8516 25.7207 76.4277 4.29688 50 4.29688Z" fill="#FFBD86" />
                <path d="M79.6521 13.3447H55.5589C53.928 13.3447 52.5939 14.5457 52.5939 16.0135C52.5939 17.4811 53.9282 18.6816 55.5589 18.6816H56.8046C58.4355 18.6816 59.7696 19.8826 59.7696 21.3502C59.7696 22.8176 58.4353 24.018 56.8046 24.018H54.9124C53.2815 24.018 51.9478 25.2193 51.9478 26.6865C51.9478 28.1541 53.2815 29.3547 54.9124 29.3547H68.9784L71.7038 24.0205C71.6845 24.0201 71.6655 24.018 71.646 24.018H69.8845C68.2542 24.018 66.9198 22.8176 66.9198 21.3502C66.9198 19.8826 68.2542 18.6816 69.8845 18.6816H79.6521C81.2829 18.6816 82.6171 17.4811 82.6171 16.0135C82.6171 14.5457 81.2827 13.3447 79.6521 13.3447Z" fill="#FFE5C2" />
                <path d="M81.5393 40.8965H79.3209C79.2451 42.5432 79.1119 44.1723 78.9258 45.7793H81.5393C85.4064 45.7793 88.5525 48.9254 88.5525 52.7926V56.1342C88.5525 60.0014 85.4064 63.1475 81.5393 63.1475H74.3945C73.6576 64.8479 72.8445 66.4795 71.957 68.0303H81.5394C88.099 68.0303 93.4355 62.6938 93.4355 56.1342V52.7926C93.4355 46.233 88.0988 40.8965 81.5393 40.8965Z" fill="#DFF6FD" />
                <path d="M66.3062 75.9791C74.1467 67.91 79.3738 54.1893 79.5871 38.5484C79.5945 38.0018 79.1572 37.5527 78.6105 37.5527H21.3893C20.8424 37.5527 20.4051 38.0018 20.4127 38.5484C20.626 54.1891 25.8531 67.9098 33.6936 75.9791H66.3062Z" fill="#F2FBFF" />
                <path d="M39.5503 14.7461H37.7974L40.9593 17.908C42.7015 18.4973 43.9597 20.1467 43.9597 22.0852V41.6539H46.8894V22.0852C46.8894 18.0383 43.5972 14.7461 39.5503 14.7461Z" fill="white" />
                <path d="M22.2326 0.285937L4.38765 18.1309C4.0062 18.5123 4.0062 19.1305 4.38765 19.5119L19.1103 34.2346C19.4917 34.616 20.1099 34.616 20.4914 34.2346L33.7355 20.9904C34.2292 20.4967 34.8662 20.1715 35.5556 20.0609L41.0244 19.1846C41.5589 19.0988 41.7712 18.4436 41.3884 18.0607L23.6136 0.285937C23.2322 -0.0953125 22.6138 -0.0953125 22.2326 0.285937Z" fill="#D6694B" />
                <path d="M9.6146 24.2103L28.2804 5.54448C28.3861 5.43882 28.5095 5.36323 28.6408 5.31343L23.6134 0.286084C23.232 -0.0953613 22.6138 -0.0953613 22.2324 0.286084L4.38765 18.131C4.0062 18.5125 4.0062 19.1306 4.38765 19.5121L9.40171 24.5263C9.45151 24.4119 9.52105 24.3039 9.6146 24.2103Z" fill="#C4573A" />
                <path d="M77.3635 21.4435C75.4475 20.3373 72.9957 21.0621 71.9891 23.0322L62.4746 41.6539H69.0402L78.6678 26.9033C79.8797 25.0462 79.284 22.5523 77.3635 21.4435Z" fill="#CBC4CC" />
                <path d="M74.5533 25.1286C75.3904 23.4901 77.2268 22.7172 78.9236 23.1385C78.6002 22.4528 78.0728 21.8536 77.3635 21.444C75.4475 20.3378 72.9957 21.0626 71.9891 23.0327L62.4746 41.6544H66.11L74.5533 25.1286Z" fill="#B5ADB6" />
                <path d="M39.5699 75.9791H60.4303C63.0465 74.0332 65.484 71.3512 67.6402 67.9967C70.834 63.0279 73.1445 57.0219 74.4496 50.4434L73.8422 50.248H26.1582L25.5508 50.4434C26.8557 57.0219 29.1664 63.0279 32.3602 67.9967C34.5162 71.3512 36.9537 74.033 39.5699 75.9791Z" fill="#EA9B58" />
                <path d="M39.311 75.7838H49.504C46.9851 73.8557 44.6366 71.241 42.5513 67.9967C39.3575 63.0279 37.047 57.0219 35.7419 50.4434H25.5503C26.8552 57.0219 29.1659 63.0279 32.3597 67.9967C34.4446 71.2404 36.7927 73.8551 39.311 75.7838Z" fill="#D88A55" />
                <path d="M25.5507 50.4434H74.4495C74.9585 47.8773 75.3145 45.224 75.5102 42.5113C75.5513 41.9438 75.105 41.459 74.536 41.459H25.4641C24.895 41.459 24.4489 41.9436 24.4899 42.5113C24.6856 45.224 25.0417 47.8773 25.5507 50.4434Z" fill="#FFE5C2" />
                <path d="M35.6934 41.459H25.4641C24.895 41.459 24.4489 41.9436 24.4899 42.5113C24.6856 45.224 25.0417 47.8773 25.5509 50.4434H35.7802C35.2712 47.8773 34.9151 45.224 34.7194 42.5113C34.6782 41.9436 35.1245 41.459 35.6934 41.459Z" fill="#FED2A4" />
                <path d="M26.5098 84.5704H73.4903C76.6088 83.1771 78.8549 80.0608 79.0825 76.3987C79.1032 76.0644 78.8317 75.7837 78.4967 75.7837H21.5034C21.1684 75.7837 20.8969 76.0644 20.9176 76.3987C21.1452 80.0608 23.3912 83.1771 26.5098 84.5704Z" fill="#DFF6FD" />
                <path d="M26.1004 84.3751H36.2746C33.3705 82.8995 31.3092 79.8985 31.0918 76.3987C31.0711 76.0644 31.3426 75.7837 31.6776 75.7837H21.5034C21.1684 75.7837 20.8969 76.0644 20.9176 76.3987C21.135 79.8985 23.1963 82.8995 26.1004 84.3751Z" fill="#C8EFFE" />
                <path d="M22.0049 90.0391L21.0303 90.2344C29.0717 96.3607 39.1104 100 50 100C60.8897 100 70.9281 96.3607 78.9697 90.2344L78.0129 90.0391H22.0049Z" fill="#D6694B" />
                <path d="M21.0301 90.2344H78.9693C81.2766 88.4766 83.4205 86.5154 85.3717 84.375H14.6279C16.5789 86.5154 18.7229 88.4766 21.0301 90.2344Z" fill="#C4573A" />
                <path d="M23.4498 84.375H14.6279C16.5791 86.5154 18.723 88.4766 21.0303 90.2344H78.9695C79.9469 89.4896 80.8932 88.7064 81.8096 87.8906H30.1799C28.5984 87.8906 27.066 87.3424 25.8436 86.3393L23.4498 84.375Z" fill="#A74B30" />
                <path d="M62.5945 3.32031H52.7693C51.1389 3.32031 49.8047 4.52109 49.8047 5.98887C49.8047 7.45625 51.1389 8.65684 52.7693 8.65684H62.5945C64.2254 8.65684 65.5596 7.45625 65.5596 5.98887C65.5596 4.52109 64.2254 3.32031 62.5945 3.32031Z" fill="#FFE5C2" />
            </g>
            <defs>
                <clipPath id="clip0_2301_65094">
                    <rect width="100" height="100" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
