import React from "react";
import { useDispatch } from "react-redux";
import { useNotification } from "../../../hooks/useNotification";
import { setLoading } from "../../../redux/slices/appInfo";
import { getCandidateCreditInfo } from "../../../api/order";
import { TCandidateCreditInfo } from "../type";
import { getCompanyProfile, TCompanyProfile } from "../../../api/company-profile";

export const useCandidateCreditPayment = () => {
    const dispatch = useDispatch();
    const { showError } = useNotification();

    const loadCandidatePayment = React.useCallback(async (setPaymentDetails: React.Dispatch<React.SetStateAction<TCandidateCreditInfo>>) => {
        dispatch(setLoading(true));
        try {
            const result = await getCandidateCreditInfo();
            if (setPaymentDetails && result?.data) {
                setPaymentDetails((prev: any) => ({
                    ...prev,
                    ...result.data,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const loadCompanyProfile = React.useCallback(async (setCompanyProfile: React.Dispatch<React.SetStateAction<TCompanyProfile>>) => {
        dispatch(setLoading(true));
        try {
            const result = await getCompanyProfile();
            if (setCompanyProfile && result?.data) {
                setCompanyProfile((prev: any) => ({
                    ...prev,
                    ...result.data,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    return {
        loadCandidatePayment,
        loadCompanyProfile
    }
}
