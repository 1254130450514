import i18next from "../../i18n";

export type TTestData = {
    _id: string;
    name: string;
    status: ETestStatus;
    createdAt: number;
    totalQuestions: number;
    skills: string[]
    position: string;
    title?: string;
}

export enum ETestStatus {
    DRAFT = 'draft',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    SUBMITTED = 'submitted',
    ACTIVE = 'active',
    CANCELLED = 'cancelled'
}

export const testStatusMapping: Record<ETestStatus, string> = {
    [ETestStatus.DRAFT]: i18next.t('myTest.draft'),
    [ETestStatus.APPROVED]: i18next.t('myTest.approved'),
    [ETestStatus.REJECTED]: i18next.t('myTest.rejected'),
    [ETestStatus.SUBMITTED]: i18next.t('myTest.submitted'),
    [ETestStatus.ACTIVE]: i18next.t('myTest.active'),
    [ETestStatus.CANCELLED]: i18next.t('myTest.cancelled')
}

export const StatusTestMappingColor = {
    [ETestStatus.ACTIVE]: '#1C92FF',
    [ETestStatus.DRAFT]: '#B3B1B8',
    [ETestStatus.APPROVED]: '#0BAA60',
    [ETestStatus.REJECTED]: '#E09400',
    [ETestStatus.SUBMITTED]: '#0BAA60',
    [ETestStatus.CANCELLED]: '#F95E5E'
}
