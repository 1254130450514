import React from "react";
import { notification } from "antd"
import { ICInfo, ICWarning, ICClose, ICCheckFill } from "../icons/index";
import { CloseButton } from "../components/button";

export const useNotification = () => {

    const showSuccess = React.useCallback((title: React.ReactNode = "Thành công!", description?: React.ReactNode) => {
        notification.destroy();
        notification.success({
            icon: <ICCheckFill />,
            message: (
                <span className="text-high-em text-body-bold">{title}</span>
            ),
            description: description,
            placement: "bottom",
            className: "middle-notification",
            closeIcon: <CloseButton />
        });
    }, []);

    const showError = React.useCallback((title: React.ReactNode = "Thất bại!", description: React.ReactNode) => {
        notification.destroy();
        notification.error({
            icon: <ICWarning fill={'#F03D3D'} />,
            message: (
                <span className="text-body-bold text-high-em">{title}</span>
            ),
            description: description,
            placement: "bottom",
            // duration: 10000000,
            className: "middle-notification",
            closeIcon: <CloseButton fill="#4F4B5C" />,
        });
    }, []);

    const showReload = React.useCallback((data: {title: React.ReactNode, description?: React.ReactNode}, onClose: () => void) => {
        notification.destroy();
        notification.success({
            icon: <ICInfo />,
            message: (
                <span className="text-[#3FB483]">{data?.title}</span>
            ),
            description: data?.description,
            placement: "bottom",
            duration: 100000,
            onClose: onClose,
            className: "middle-notification",
            closeIcon: (
                <>
                    <CloseButton text={'Hoàn tác'}/>
                    <ICClose height={18} width={18} />
                </>
            )
        });
    }, []);

    return {
        showSuccess,
        showError,
        showReload
    }
}
