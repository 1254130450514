export const isValidEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|([a-zA-Z0-9]([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

/**
 * Strong password rules:
 * 1. Has a number
 * 2. Has a lowercase letter
 * 3. Has an uppercase letter
 * @param password 
 */
export const isStrongPassword = (password: string) => {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{3,}$/;
    return re.test(password);
}

export const haveAtLeastOneNumber = (password: string) => {
    const re = /^(?=.*\d).{1,}$/;
    return re.test(password);
}

export const haveAtLeastOneLowerCase = (password: string) => {
    const re = /^(?=.*[a-z]).{1,}$/;
    return re.test(password);
}

export const haveAtLeastOneUpperCase = (password: string) => {
    const re = /^(?=.*[A-Z]).{1,}$/;
    return re.test(password);
}
export const haveUnallowedCharacters = (password: string) => {
    const re = /[\(\)'\,\| ]/
    return re.test(password);
}

export const isNetworkError = (error: any) => {
    return error.code === 'ERR_NETWORK';
}

export const getURL = (url: string) => {
    return url ? `${process.env.REACT_APP_S3_URL}/${url}` : '/images/avatar_holder.jpg';
}

export const goodFileSize = (file: File, allowSize: number = 10): boolean => {
    const fileSize = Math.round((file.size / 1024));
    return fileSize <= allowSize * 1024;
}

export const validateImageDimensions = (file: File, width: number, height: number, callback: (result: boolean) => void) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
        if (img.width > width || img.height > height) {
            callback(false);
        } else {
            callback(true);
        }
    };
}

export const removeUndefinedAttribute = (values: any = {}) => {
    Object.keys(values).forEach(key => {
        if (values[key] === null || values[key] === undefined || values[key] === 'undefined' || values[key] === '') {
            delete values[key];
        }
    });
    return values;
}

export const compareObjects = (currentObject: any, needCompareObject: any) => {
    const isObject = (object: any) => {
        return object != null && typeof object === "object";
    };
    const isDeepEqual = (object1: any, object2: any) => {
        const objKeys1 = Object.keys(object1);
        const objKeys2 = Object.keys(object2);

        if (objKeys1.length !== objKeys2.length) return false;

        for (var key of objKeys1) {
            const value1 = object1[key];
            const value2 = object2[key];

            const isObjects = isObject(value1) && isObject(value2);

            if ((isObjects && !isDeepEqual(value1, value2)) ||
                (!isObjects && value1 !== value2)
            ) {
                return false;
            }
        }
        return true;
    };
    return isDeepEqual(removeUndefinedAttribute(currentObject), removeUndefinedAttribute(needCompareObject));
}


export const valueFormatter = (value: number): string => {
    let valueStr = value.toString();
    // k, m, b
    const shortten = [{
        value: 1,
        symbol: ""
    }, {
        value: 1000,
        symbol: "k"
    }, {
        value: 1000000,
        symbol: "m"
    }, {
        value: 1000000000,
        symbol: "b"
    }];
    let shorttenIndex = 0;
    const absValue = Math.abs(value);
    const idx = shortten.findIndex(e => absValue < e.value);
    if (idx === -1) {
        shorttenIndex = shortten.length - 1;
    } else if (idx > 0) {
        shorttenIndex = idx - 1;
    }

    const shorttenValue = value / shortten[shorttenIndex].value;
    let fixed = shorttenValue.toFixed(1);
    const afterDot = fixed.split(".")[1];
    if (afterDot === "0") {
        fixed = shorttenValue.toFixed(0);
        if (fixed === "1000" && shorttenIndex < shortten.length - 1) {
            shorttenIndex++;
            fixed = (value / shortten[shorttenIndex].value).toFixed(0);
        }
    }
    valueStr = fixed + (shortten[shorttenIndex].symbol ? ("" + shortten[shorttenIndex].symbol) : "");
    valueStr = valueStr.split(".").map((val, index) => {
        if (index === 0) return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return val;
    }).join(".")
    return valueStr;
}

export const containsEmptyElements = (arr: any[]): boolean => {
    return Object.values(arr).length !== arr.length;
};

export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const createLink = (url: string) => {
    if (window.location.hash) {
        // Hash mode
        return `${window.location.origin}/#/testing/${url}/invitation`;
    }
    return `${window.location.origin}/testing/${url}/invitation`;
}

export const getRandomCharsFromString = (charset: string, length: number) => {
    return new Array(length).fill(0).map(() => charset[Math.floor(Math.random() * charset.length)]).join("");
}

export const generateRandomPassword = (): string =>
    [
        getRandomCharsFromString("abcdefghijklmnopqrstuvwxyz0123456789", 6),
        getRandomCharsFromString("@#%/", 1),
        getRandomCharsFromString("ABCDEFGHIJKLMNOPQRSTUVWXYZ", 1),
    ].sort(() => Math.random() - 0.5).join("");

export const formatMoney = (money: number, currency = 'VND'): string => {
    if (money === 0) return '0 ' + currency;
    if (!money) return '';
    return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' ' + currency;
}


export function detectMobileDevice() {
    const userAgent = navigator.userAgent;
    const isMobile = /Mobile/.test(userAgent);

    if (isMobile && window.location.pathname !== '/not-supported') {
        window.location.href = '/not-supported';
        // const isIOS = /iPhone|iPad|iPod/.test(userAgent);
        // const isAndroid = /Android/.test(userAgent);

        // if (isIOS) {
        //     window.location.href = 'https://apps.apple.com/vn/app/husa-assessment/id6467098866';
        // } else if (isAndroid) {
        //     window.location.href = 'https://play.google.com/store/apps/details?id=com.jt.hr_mobile';
        // }
    }
}