import React from "react";
import styled from "styled-components";
import { Conversation, sendUserMessage, Topics, transformQuestion } from "../../api/gpt/generate";
import { AssistantMessage, ImportOptions, Message, UserMessage } from "./type";
import { GeneratingMessage, MessageRenderer } from "./MessageRenderer";
import { MessageInput } from "./MessageInput";
import { formatQuestion, TQuestion } from "../create_test/tab/question/type";
import { AssistantEmptyState } from "./AssistantEmptyState";
import { Conversations } from "./Conversations";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slices/appInfo";
import { getTestLibraryById, getTestRatingLevel, saveTestLibrary, updateTestLibrary } from "../../api/library";
import { ITestLibrary } from "../library/models";
import { useNotification } from "../../hooks/useNotification";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { useMobile } from "../../hooks/useDimensions";
import { GET_STARTED_MESSAGE, GET_STARTED_MESSAGE_VI } from "./constants";
import { RootState } from "../../redux/reducers";
import { Button } from "antd";
import { useNavigation } from "../../hooks/useNavigate";
import i18next from "../../i18n";
import { LANGUAGE } from "../../utils/constants";

export const CustomScrollBar = styled.div`
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #4b5563;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: #2f2f2f;
        border-radius: 10px;
    }
`;


export const AssistantPage: React.FC = () => {
    const isMobile = useMobile();
    const [inputValue, setInputValue] = React.useState('');
    const dispatch = useDispatch();
    const { showSuccess, showError } = useNotification();
    const [messages, setMessages] = React.useState<Message[]>([]);
    const [isGenerating, setIsGenerating] = React.useState(false);
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const [conversationId, setConversationId] = React.useState<string | undefined>(undefined);
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const [isOpenSidebar, setIsOpenSidebar] = React.useState(!isMobile);
    const { navigate } = useNavigation();
    const commonData = useSelector((state: RootState) => state.commonData);
    const conversationsRef = React.useRef<any>();
    const scrollBottom = () => {
        setTimeout(() => {
            if (scrollRef.current) scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }, 10);
    }
    const focusInput = () => {
        setTimeout(() => {
            if (textAreaRef.current) textAreaRef.current.focus();
        }, 10);
    }
    React.useEffect(() => {
        // Clear input value when conversationId changed
        setInputValue('');
        focusInput();
    }, [conversationId]);
    const onSend = async (message: string) => {
        setMessages([...messages, {
            role: 'user',
            message,
            deliveredAt: new Date().toISOString()
        }]);
        scrollBottom();
        try {
            setIsGenerating(true);
            const response = await sendUserMessage({
                topic: Topics.GENERATE_ASSESMENT,
                userContent: message,
                conversationId
            });
            const newMessages = [
                { role: 'user', content: message, generatedAt: Date.now() },
                { role: 'assistant', content: JSON.stringify(response), generatedAt: Date.now() }
            ] as Conversation["messages"];
            if (!conversationId && conversationsRef.current) {
                const newConversation: Conversation = {
                    _id: response.conversationId,
                    messages: newMessages,
                    createdAt: Date.now(),
                }
                conversationsRef.current.addConversation(newConversation);
            } else if (conversationsRef.current) {
                conversationsRef.current.updateConversationMessage(conversationId, newMessages);
            }
            setConversationId(response.conversationId);
            setMessages(prev => prev.concat({
                deliveredAt: new Date().toISOString(),
                role: 'assistant',
                questions: response.questions,
                message: response.clarification,
                title: response.title,
                assessmentTime: response.assessmentTime,
                language: response.language ?? 'en'
            }));
            scrollBottom();
        } finally {
            setIsGenerating(false);
        }

    }
    const onLoad = React.useCallback((conversation: Conversation) => {
        setConversationId(conversation._id);
        const messages = conversation.messages.map(message => {
            if (message.role !== 'assistant') return {
                role: 'user',
                message: message.content,
                deliveredAt: new Date(message.generatedAt).toISOString()
            } as UserMessage;
            const data = JSON.parse(message.content);
            const assistantMessage: AssistantMessage = {
                deliveredAt: new Date(message.generatedAt).toISOString(),
                role: 'assistant',
                questions: (data.questions ?? []).map(transformQuestion).filter(Boolean) as TQuestion[],
                title: data.title ?? '',
                message: data.clarification ?? '',
                assessmentTime: data.assessmentTime,
                language: data.language ?? 'en'
            }
            return assistantMessage;
        });
        if (isMobile) {
            setIsOpenSidebar(false);
        }
        setMessages(messages);
        // Scroll to bottom
        scrollBottom();
    }, [isMobile]);

    const onCreateNewConversation = () => {
        setConversationId(undefined);
        setMessages([]);
    }
    const onImport = async (options: ImportOptions) => {
        dispatch(setLoading(true));
        try {
            const { libraryId, questions, title, assessmentTime, language } = options;
            let libraryTitle = '';
            let libObjId = '';
            if (libraryId) {
                const library = (await getTestLibraryById(libraryId)).data;
                libraryTitle = library.title;
                const skillSections = library.skillSections ?? [];
                skillSections.push({
                    type: "standard",
                    name: title,
                    description: i18next.t('assisstant.descriptions.generatedByAssistant'),
                    questions: questions.map(formatQuestion)
                });
                const lib = await updateTestLibrary(libraryId, {
                    skillSections
                });
                libObjId = lib.data._id;
            } else {
                const languagueCode = options.language === 'en' ? 'en' : 'vn';
                const language = commonData.language.find(d => d.code === languagueCode);
                if (!language) throw new Error('Language not found');
                // TODO
                const otherRole = commonData.position.find(d => d.label === 'Khác' || d.label === 'Others');
                if (!otherRole) throw new Error('Role not found');
                const testType = commonData.test_type.find(d => d.label === 'Chuyên môn' || d.label === 'Expertise');
                if (!testType) throw new Error('Test type not found');
                const industry = commonData.test_field.find(d => d.label === 'Khác' || d.label === 'Others');
                if (!industry) throw new Error('Industry not found');
                const res = await getTestRatingLevel();
                const ratingLevels = res.data as any[];
                const ratingLevel = ratingLevels.find(d => d.label === 'Specialist' || d.label === 'Chuyên viên');
                if (!ratingLevel) throw new Error('Rating level not found');
                const createTestData = {
                    title,
                    language: language._id,
                    industry: [industry._id],
                    roles: [otherRole._id],
                    type: testType._id,
                    previewSection: [],
                    ratingLevel: ratingLevel._id,
                    skillSections: [{
                        type: "standard",
                        name: `Skill area 1`,
                        description: 'Generated by assistant',
                        questions: questions.map(formatQuestion)
                    }],
                    configuration: {
                        questionDuration: assessmentTime || 15,
                    }
                }
                libraryTitle = title;
                const lib = await saveTestLibrary(createTestData);
                libObjId = lib.data._id;
            }
            showSuccess(i18next.t('assisstant.messages.success'), <div className="flex items-center space-x-4">
                <p>{i18next.t('assisstant.messages.testAdded', 'Successful', { libraryTitle })}</p>
                <Button type="link" onClick={() => { navigate(`/edit-test/${libObjId}`) }}>
                    <p className="font-semibold text-[12px]">{i18next.t('assisstant.messages.viewDetail')}</p>
                </Button>
            </div>)
        } catch (e) {
            showError(i18next.t('assisstant.messages.error'), i18next.t('assisstant.messages.errorOccurred'));
            console.error(e);
        } finally {
            dispatch(setLoading(false));
        }
    }
    const getStartedMessage = () => {
        const lang = localStorage.getItem('language') ?? LANGUAGE.VN;
        switch (lang) {
            case LANGUAGE.EN:
                return GET_STARTED_MESSAGE;
            default:
                return GET_STARTED_MESSAGE_VI;
        }
    }
    const onGetStarted = () => {
        setInputValue(getStartedMessage());
        focusInput();
    }
    return <div
        style={{
            height: 'calc(100svh - 70px)',
        }}
        className={classNames(
            "bg-[#F6F8FB] grid gap-4",
            isOpenSidebar ? "grid-cols-[300px,1fr]" : "grid-cols-[0px_1fr]",
        )}
    >
        <div className="bg-white relative">
            <div className="h-full overflow-hidden">
                <Conversations
                    onCreate={onCreateNewConversation}
                    onLoad={onLoad}
                    currentConversationId={conversationId}
                    onCreateNewConversation={onCreateNewConversation}
                    ref={conversationsRef}
                />
            </div>
            <div
                className="absolute flex items-center justify-center cursor-pointer shadow-md rounded-md opacity-50 hover:opacity-100"
                style={{
                    top: '14px',
                    right: '-30px',
                    backgroundColor: 'white',
                    width: '30px',
                    height: '30px',
                }}
                onClick={() => setIsOpenSidebar(prev => !prev)}
            >
                {
                    isOpenSidebar ? <DoubleLeftOutlined /> : <DoubleRightOutlined />
                }
            </div>
        </div>
        <div
            onClick={e => {
                if (!isMobile || !isOpenSidebar) return;
                setIsOpenSidebar(false);
            }}
            className="flex flex-col justify-between py-2 h-[calc(100svh-70px)]"
        >
            {messages.length
                ? <CustomScrollBar
                    ref={scrollRef}
                    className="overflow-y-auto p-2 px-2 lg:px-4 space-y-3"
                    style={{
                        maxHeight: 'calc(100svh - 150px)'
                    }}
                >
                    {
                        messages.map((message, index) => {
                            return <MessageRenderer
                                key={index}
                                message={message}
                                onImport={onImport}
                            />
                        })
                    }
                    {
                        isGenerating && <GeneratingMessage />
                    }
                </CustomScrollBar>
                : <div className="w-full h-full flex items-center justify-center">
                    <AssistantEmptyState onGetStarted={onGetStarted} />
                </div>
            }


            <div className="py-2 w-full lg:w-2/3 mx-auto">
                <MessageInput
                    isGenerating={isGenerating}
                    onSend={onSend}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    ref={textAreaRef}
                />
            </div>
        </div>
    </div>
}