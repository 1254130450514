import React from "react"

type CopiedValue = string | null
type CopyFn = (text: string) => Promise<boolean>;

export const useCopyToClipboard = () => {
  const [copiedText, setCopiedText] = React.useState<CopiedValue>(null)

  const copy: CopyFn = async text => {
    if (!navigator?.clipboard) {
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy"); // Working around for dev env without https
      document.body.removeChild(el);
      return true;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn('Copy failed', error)
      setCopiedText(null);
      return false
    }
  }

  return { copiedText, copy }
}
