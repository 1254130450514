import React from "react";
import { HRButton, IconButton } from "../../../components/button";
import { ICCheckV2, ICClose, ICNext } from "../../../icons";
import { useAssementData } from "./hooks/useCreateAssessment"
import { ICBack } from '../../../icons/back';
import { useGeneralAssessment } from "./hooks/useAssessment";
import { EStatusAssessment } from "../type";
import { serializeAdditionalQuestion } from "./type";
import { Modal } from "antd";
import { useNavigation } from "../../../hooks/useNavigate";
import { useNotification } from "../../../hooks/useNotification";
import i18next from "../../../i18n";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { UserRoles } from "../../../redux/slices/userInfo";

type TCreateAssessmentHeaderProps = {
    mode: 'create' | 'edit';
    id: string;
}

export const CreateAssessmentHeader: React.FC<TCreateAssessmentHeaderProps> = ({
    mode,
    id
}) => {
    const {
        assessment,
        tests,
        additionalQuestions,
        step,
        setStep,
        generalForm,
        setAssessment,
        reviewQuestion,
        setIsPrompt,
    } = useAssementData();
    const { navigate, getBackUrl } = useNavigation();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const { showError } = useNotification();
    const {
        saveGeneralInformation,
        updatedAssessment,
    } = useGeneralAssessment(generalForm, setStep, setAssessment);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const onCancelAction = React.useCallback(() => {
        const referrer = getBackUrl();
        let searchParams = new URLSearchParams();
        if (referrer) {
            const url = new URL(referrer);
            if (url.pathname === '/my-assessment') {
                searchParams = new URLSearchParams(url.search);
            }
        }
        searchParams.append('status', assessment?.status || EStatusAssessment.ACTIVE);
        navigate({
            pathname: "/my-assessment",
            search: searchParams.toString()
        });
    }, [navigate, assessment, getBackUrl]);

    const onNextStep = React.useCallback(() => {
        generalForm.validateFields().then((results: any) => {
            if (mode === 'create') {
                saveGeneralInformation(results);
            } else {
                setStep(prev => prev + 1);
            }
        }).catch((err) => {
            showError(i18next.t("createAssessment.missRequiredInfomation"), i18next.t("createAssessment.refillRequiredInfomation"));
        });
    }, [generalForm, mode, saveGeneralInformation, setStep]);

    const previousStep = React.useCallback(() => {
        setStep(prev => prev - 1);
    }, [setStep]);

    const hasPriceTest = React.useMemo(() => {
        return tests.filter(d => typeof d._id === 'string').some(d => d.price !== null);
    }, [tests]);

    const doSubmitAssessment = React.useCallback(() => {
        const testsIds = tests.filter(d => d._id).map((test) => test._id);
        if (id) {
            const additionalIndex = (reviewQuestion ?? []).findIndex(d => d.type === 'additional_question') || 0;
            const payload = {
                ...assessment,
                tests: testsIds,
                additionalIndex: additionalIndex,
                additionalQuestions: additionalQuestions.map(serializeAdditionalQuestion),
                forceTurnOnCamera: assessment?.forceTurnOnCamera || false,
            };
            setIsPrompt(false);
            updatedAssessment(id, payload).then((res: any) => {
                if (res.status === 200) {
                    const data = res.data;
                    if (data.needToPay && !data?.canSkipPayment) {
                        navigate(`/payment/${id}`);
                    } else {
                        navigate(`/assessment/${id}/details`);
                    }
                }
            }).catch((err) => console.warn(err)).finally(() => setIsPrompt(true));
        }

    }, [id, hasPriceTest, assessment, setIsPrompt, navigate, tests, additionalQuestions, reviewQuestion, updatedAssessment]);

    const onSubmit = React.useCallback(() => {
        if (hasPriceTest && assessment?.canSkipPayment === false && userInfo.role !== UserRoles.PRO) {
            setShowConfirmModal(true);

        } else {
            doSubmitAssessment();
        }
    }, [hasPriceTest, assessment, doSubmitAssessment]);


    const disableCompleted = React.useMemo(() => {
        return additionalQuestions.length === 0 && tests.filter(d => typeof d._id === 'string').length === 0;
    }, [tests, additionalQuestions]);

    const totalQuestion = React.useMemo(() => {
        return tests.filter(d => typeof d._id === 'string').length;
    }, [tests]);

    const totalAdditionalQuestions = React.useMemo(() => {
        if (additionalQuestions?.length) {
            return additionalQuestions.length;
        }
        return 0;
    }, [additionalQuestions]);

    const totalTime = React.useMemo(() => {
        const durations = tests.filter(d => typeof d._id === 'string').map(t => {
            const questionDuration = t.configuration?.questionDuration ?? 0;
            if (Array.isArray(questionDuration)) {
                return questionDuration.reduce((acc, cur) => acc + cur ?? 0, 0);
            }
            return questionDuration
        })
        const timeQuestion = durations.reduce((acc, cur) => {
            return acc + cur
        }, 0);
        const timeAdditional = additionalQuestions.map(d => d.duration).reduce((partialSum, a) => (partialSum || 0) + (a || 0), 0) || 0;
        return timeQuestion + timeAdditional;
    }, [tests, additionalQuestions]);

    return <div className="text-left">
        <div className="flex items-center justify-between">
            <div className="flex gap-[16px] justify-start items-start">
                <div className="flex">
                    <IconButton btnType="sub" btnSize="sm" icon={<ICClose width={18} height={18} />} onClick={onCancelAction} />
                </div>
                <div className="flex flex-col justify-start items-start gap-[8px]">
                    <div className="max-w-[500px] line-clamp-1 text-heading-5-bold text-high-em">
                        {mode === 'edit' ? i18next.t('createAssessment.editAssessment') : i18next.t('createAssessment.createAssessment')}
                    </div>
                    <div className="grid grid-cols-[auto_auto_auto_auto] gap-[16px] text-sub-medium text-med-em">
                        <span className="max-w-[500px] line-clamp-1">{assessment?.title || i18next.t('createAssessment.newAssessment')}</span>
                        <span>{`${totalQuestion} ${i18next.t('createAssessment.totalTests')}`}</span>
                        <span>{`${totalAdditionalQuestions} ${i18next.t('createAssessment.totalQuestions')}`}</span>
                        <span>{`${totalTime} ${i18next.t('createAssessment.minutes')}`}</span>
                    </div>

                </div>
            </div>
            <div className="hidden lg:grid grid-cols-[auto_1fr] gap-[16px]">
                <HRButton
                    onClick={previousStep}
                    btnType="sub"
                    btnSize="md"
                    style={{
                        display: step === 0 ? 'none' : 'block',
                    }}
                >
                    <div className="flex space-x-2 justify-center">
                        <ICBack />
                    </div>
                </HRButton>
                {
                    step !== 3
                        ? <HRButton
                            btnSize="md"
                            onClick={onNextStep}
                        >
                            <div className="flex space-x-2 justify-center">
                                <span>{ i18next.t('createAssessment.continue') }</span>
                                <ICNext />
                            </div>
                        </HRButton>
                        : <HRButton
                            onClick={onSubmit}
                            btnSize="md"
                            disabled={disableCompleted}
                        >
                            <div className="flex space-x-2 justify-center">
                                <span>{hasPriceTest && assessment?.canSkipPayment !== true ? i18next.t('createAssessment.completeAndCheckout') : i18next.t('createAssessment.complete')}</span>
                                <ICCheckV2 fill={disableCompleted ? '#B2B4BF' : 'white'} />
                            </div>
                        </HRButton>
                }
            </div>
        </div>
        <CannotModifyModal 
            isOpen={showConfirmModal}
            onCancel={() => setShowConfirmModal(false)}
            onConfirm={() => {
                doSubmitAssessment();
                setShowConfirmModal(false);
            }}
        />
    </div>
}

type CannotModifyModalProps = {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}
export const CannotModifyModal: React.FC<CannotModifyModalProps> = ({
    isOpen,
    onConfirm,
    onCancel
}) => {
    return <Modal
        centered
        onCancel={onCancel}
        open={isOpen}
        title={
            <div className="font-bold text-[18px]">{ i18next.t('createAssessment.note') }</div>
        }
        footer={
            <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                <HRButton
                    btnType="sub"
                    btnSize="sm"
                    onClick={onCancel}>
                    { i18next.t('createAssessment.back') }
                </HRButton>
                <HRButton
                    btnType="primary"
                    btnSize="sm"
                    onClick={onConfirm}>
                    { i18next.t('createAssessment.gotIt') }
                </HRButton>
            </div>
        }>
        <p className="mb-0 text-[16px] font-medium leading-[24px]">{ i18next.t('createAssessment.cannotEditAfterPaid') }</p>
    </Modal>
}
