import { ApiInstance } from "..";
import { uuid } from "../../pages/create_test/tab/question/helper";
import { EQuestionType, generateOption, TMultipleChoiceQuestion, TQuestion, TSingleChoiceQuestion } from "../../pages/create_test/tab/question/type";
import { IPagination } from "../../pages/library/models";
export enum Topics {
    GENERATE_ASSESMENT = "GENERATE_ASSESMENT",
}
export type GenerateWithConversationDto = {
    conversationId?: string;

    topic: Topics;

    userContent: string;
}

export type Message = {
    role: "user" | "system" | "assistant";
    content: string;
    generatedAt: number;
}

type BaseQuestion = {
    question: string,
    content?: string,
    options: string[],
}
type SingleChoiceQuestion = BaseQuestion & {
    type: EQuestionType.SINGLE_CHOICE,
    answer: string,
    correctAnswer?: number,
}
type MultipleChoiceQuestion = BaseQuestion & {
    type: EQuestionType.MULTIPLE_CHOICE,
    answer: string[],
    correctAnswer?: number[],
}
export type Question = SingleChoiceQuestion | MultipleChoiceQuestion;

type GenerateAssessmentResponse = {
    questions: Question[],
    clarification: string,
    conversationId: string,
    title?: string,
    assessmentTime?: number,
    language?: "en" | "vi",
}


export function transformQuestion(question: Question): TQuestion | undefined {
    if (question.type === EQuestionType.SINGLE_CHOICE) {
        const correctAnswer = question.correctAnswer ?? question.options.indexOf(question.answer);
        if (correctAnswer === -1 || correctAnswer >= question.options.length) return undefined;
        return {
            id: uuid(),
            type: EQuestionType.SINGLE_CHOICE,
            content: question.content ?? question.question,
            options: question.options.map(o => typeof o === 'object' ? o : generateOption(o)),
            correctAnswer,
            isMixed: false,
            isActive: true,
        } as TSingleChoiceQuestion
    } else {
        const correctAnswer = question.correctAnswer ?? question.answer.map((a) => {
            if (typeof a === 'number') return a;
            return question.options.indexOf(a);
        });
        if (correctAnswer.some((a) => a === -1 || a >= question.options.length)) return undefined;
        return {
            id: uuid(),
            type: EQuestionType.MULTIPLE_CHOICE,
            content: question.content ?? question.question,
            options: question.options.map(o => typeof o === 'object' ? o : generateOption(o)),
            correctAnswer,
            isMixed: false,
            isActive: true,
            scoreCombination: {},
        } as TMultipleChoiceQuestion;
    }
}
export const sendUserMessage = async (body: GenerateWithConversationDto) => {
    const result = await ApiInstance.getInstance().post("/api/gpt/conversation", body);
    const data = result.data as GenerateAssessmentResponse;
    return {
        questions: data.questions.map(transformQuestion).filter(q => q !== undefined) as TQuestion[],
        clarification: data.clarification,
        conversationId: data.conversationId,
        title: data.title,
        assessmentTime: data.assessmentTime,
        language: data.language,
    };
};

export type Conversation = {
    _id: string,
    messages: Message[],
    title?: string
    createdAt: number;
}
export async function getConversations(limit: number, page: number) {
    const { data } = await ApiInstance.getInstance().get(`/api/gpt/conversation?limit=${limit}&page=${page}`);
    return data as {
        data: Conversation[],
        metaData: IPagination
    };
}

export async function getConversation(conversationId: string) {
    const { data } = await ApiInstance.getInstance().get(`/api/gpt/conversation/${conversationId}`);
    return data as Conversation;
}