import { IconProps } from "./type"

export const ICClockTime: React.FC<IconProps> = ({
    width = 25,
    height = 25,
    fill = "#46485C",
    stroke = "#7357FF",
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 12.086V12.2931L13.1464 12.4396L15.8499 15.143C15.9394 15.237 15.9888 15.3622 15.9877 15.4921C15.9866 15.6232 15.934 15.7486 15.8413 15.8413C15.7486 15.934 15.6232 15.9866 15.4921 15.9877C15.3622 15.9888 15.237 15.9394 15.143 15.8499L12.1466 12.8534L12.1465 12.8534C12.0527 12.7597 12.0001 12.6326 12 12.5C12 12.5 12 12.4999 12 12.4999V7.5C12 7.36739 12.0527 7.24022 12.1464 7.14645C12.2402 7.05268 12.3674 7 12.5 7C12.6326 7 12.7598 7.05268 12.8536 7.14645C12.9473 7.24022 13 7.36739 13 7.5V12.086ZM12.5 4C10.2457 4 8.08365 4.89553 6.48959 6.48959C4.89553 8.08365 4 10.2457 4 12.5C4 14.7543 4.89553 16.9163 6.48959 18.5104C8.08365 20.1045 10.2457 21 12.5 21C14.7543 21 16.9163 20.1045 18.5104 18.5104C20.1045 16.9163 21 14.7543 21 12.5C21 10.2457 20.1045 8.08365 18.5104 6.48959C16.9163 4.89553 14.7543 4 12.5 4ZM3 12.5C3 7.25314 7.25314 3 12.5 3C17.7469 3 22 7.25314 22 12.5C22 17.7469 17.7469 22 12.5 22C7.25314 22 3 17.7469 3 12.5Z"
                fill={fill} stroke={stroke}
            />
        </svg>
    )
}

export const ICClock: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.575 7.48571C5.575 3.90942 8.4165 1 11.975 1C14.1886 1 16.1307 2.12987 17.2768 3.84421C17.5838 4.30334 17.4604 4.92437 17.0013 5.23132C16.5421 5.53828 15.9211 5.41492 15.6142 4.95579C14.8207 3.769 13.4883 3 11.975 3C9.5784 3 7.6203 4.93901 7.57577 7.4H17.6C19.1571 7.4 20.4 8.68548 20.4 10.2286V19.3714C20.4 20.9145 19.1571 22.2 17.6 22.2H6.8C5.24293 22.2 4 20.9145 4 19.3714V10.2286C4 9.11486 4.64745 8.13534 5.59317 7.67645C5.58125 7.61471 5.575 7.55094 5.575 7.48571ZM6.8 9.4C6.37707 9.4 6 9.76024 6 10.2286V19.3714C6 19.8398 6.37707 20.2 6.8 20.2H17.6C18.0229 20.2 18.4 19.8398 18.4 19.3714V10.2286C18.4 9.76024 18.0229 9.4 17.6 9.4H6.8Z" fill="#BEC0CA" />
        </svg>
    )
}

