import { createGlobalStyle } from 'styled-components';

import Font_Black_OTF from '../assets/fonts/SVN-Poppins-Black.otf';
import Font_Black_TTF from '../assets/fonts/SVN-Poppins-Black.ttf';
import Font_Black_Italic_TTF from '../assets/fonts/SVN-Poppins-BlackItalic.otf';
import Font_Black_Italic_OTF from '../assets/fonts/SVN-Poppins-BlackItalic.ttf';

import Font_Bold_OTF from '../assets/fonts/SVN-Poppins-Bold.otf';
import Font_Bold_TTF from '../assets/fonts/SVN-Poppins-Bold.ttf';
import Font_Bold_Italic_OTF from '../assets/fonts/SVN-Poppins-BoldItalic.otf';
import Font_Bold_Italic_TTF from '../assets/fonts/SVN-Poppins-BoldItalic.ttf';

import Font_Extra_Bold_OTF from '../assets/fonts/SVN-Poppins-ExtraBold.otf';
import Font_Extra_Bold_TTF from '../assets/fonts/SVN-Poppins-ExtraBold.ttf';
import Font_Extra_Bold_Italic_OTF from '../assets/fonts/SVN-Poppins-ExtraBoldItalic.otf';
import Font_Extra_Bold_Italic_TTF from '../assets/fonts/SVN-Poppins-ExtraBoldItalic.ttf';

import Font_Extra_Light_OTF from '../assets/fonts/SVN-Poppins-ExtraLight.otf';
import Font_Extra_Light_TTF from '../assets/fonts/SVN-Poppins-ExtraLight.ttf';
import Font_Extra_Light_Italic_OTF from '../assets/fonts/SVN-Poppins-ExtraLightItalic.otf';
import Font_Extra_Light_Italic_TTF from '../assets/fonts/SVN-Poppins-ExtraLightItalic.ttf';

import Font_Italic_OTF from '../assets/fonts/SVN-Poppins-Italic.otf';
import Font_Italic_TTF from '../assets/fonts/SVN-Poppins-Italic.ttf';

import Font_Light_OTF from '../assets/fonts/SVN-Poppins-Light.otf';
import Font_Light_TTF from '../assets/fonts/SVN-Poppins-Light.ttf';
import Font_LightItalic_OTF from '../assets/fonts/SVN-Poppins-LightItalic.otf';
import Font_LightItalic_TTF from '../assets/fonts/SVN-Poppins-LightItalic.ttf';

import Font_Medium_OTF from '../assets/fonts/SVN-Poppins-Medium.otf';
import Font_Medium_TTF from '../assets/fonts/SVN-Poppins-Medium.ttf';
import Font_MediumItalic_OTF from '../assets/fonts/SVN-Poppins-MediumItalic.otf';
import Font_MediumItalic_TTF from '../assets/fonts/SVN-Poppins-MediumItalic.ttf';

import Font_Regular_OTF from '../assets/fonts/SVN-Poppins-Regular.otf';
import Font_Regular_TTF from '../assets/fonts/SVN-Poppins-Regular.ttf';

import Font_SemiBold_OTF from '../assets/fonts/SVN-Poppins-SemiBold.otf';
import Font_SemiBold_TTF from '../assets/fonts/SVN-Poppins-SemiBold.ttf';
import Font_SemiBoldItalic_OTF from '../assets/fonts/SVN-Poppins-SemiBoldItalic.otf';
import Font_SemiBoldItalic_TTF from '../assets/fonts/SVN-Poppins-SemiBoldItalic.ttf';

import Font_Thin_OTF from '../assets/fonts/SVN-Poppins-Thin.otf';
import Font_Thin_TTF from '../assets/fonts/SVN-Poppins-Thin.ttf';
import Font_ThinItalic_OTF from '../assets/fonts/SVN-Poppins-ThinItalic.otf';
import Font_ThinItalic_TTF from '../assets/fonts/SVN-Poppins-ThinItalic.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Black_Italic_OTF}) format('otf');
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Black_Italic_TTF}) format('truetype');
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Black_OTF}) format('otf'); 
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Black_TTF}) format('truetype');
    }
    
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Bold_Italic_OTF}) format('otf');
        font-weight: bold;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Bold_Italic_TTF}) format('truetype');
        font-weight: bold;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Bold_OTF}) format('otf');
        font-weight: bold;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Bold_TTF}) format('truetype');
        font-weight: bold;
    }
    
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Extra_Bold_Italic_OTF}) format('otf');
        font-weight: 800;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Extra_Bold_Italic_TTF}) format('truetype');
        font-weight: 800;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Extra_Bold_OTF}) format('otf');
        font-weight: 800;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Extra_Bold_TTF}) format('truetype');
        font-weight: 800;
    }
   
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Extra_Light_Italic_OTF}) format('otf');
        font-weight: 200;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Extra_Light_Italic_TTF}) format('truetype');
        font-weight: 200;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Extra_Light_OTF}) format('otf');
        font-weight: 200;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Extra_Light_TTF}) format('truetype');
        font-weight: 200;
    }
    
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Italic_OTF}) format('otf');
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Italic_TTF}) format('truetype');
        text-decoration: italic;
    }

    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_LightItalic_OTF}) format('otf');
        font-weight: 300;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_LightItalic_TTF}) format('truetype');
        font-weight: 300;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Light_OTF}) format('otf');
        font-weight: 300;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Light_TTF}) format('truetype');
        font-weight: 300;
    }
    
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_MediumItalic_OTF}) format('otf');
        font-weight: 500;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_MediumItalic_TTF}) format('truetype');
        font-weight: 500;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Medium_OTF}) format('otf');
        font-weight: 500;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Medium_TTF}) format('truetype');
        font-weight: 500;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Regular_OTF}) format('otf');
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Regular_TTF}) format('truetype');
    }

    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_SemiBoldItalic_OTF}) format('otf');
        font-weight: 600;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_SemiBoldItalic_TTF}) format('truetype');
        font-weight: 600;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_SemiBold_OTF}) format('otf');
        font-weight: 600;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_SemiBold_TTF}) format('truetype');
        font-weight: 600;
    }
   
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_ThinItalic_OTF}) format('otf');
        font-weight: 100;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_ThinItalic_TTF}) format('truetype');
        font-weight: 100;
        text-decoration: italic;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Thin_OTF}) format('otf');
        font-weight: 100;
    }
    @font-face {
        font-family: "SVN-Poppins";
        src: local("SVN-Poppins"), url(${Font_Thin_TTF}) format('truetype');
        font-weight: 100;
    }
    
`;
