import { ApiInstance } from "..";
import { TCandidateCreditRequest } from "../../pages/candidate_credit/type";
import { TPaymentRequest } from "../../pages/payment/type";

export const getPaymentUrl = (payload: TPaymentRequest) => {
  return ApiInstance.getInstance().post(`/api/payment/gateway/create-payment-url`, payload);
}

export const getCandidateCreditPaymentUrl = (payload: TCandidateCreditRequest) => {
  return ApiInstance.getInstance().post(`/api/payment/gateway/create-candidate-credit-payment-url`, payload);
}

export const downloadInvoice = (orderId: string) => {
  return ApiInstance.getInstance().get(`/api/payment/get-invoice-file-by-order/${orderId}`);
}

export const retrievePaymentUrl = (orderId: string) => {
  return ApiInstance.getInstance().get(`/api/payment/retrieve-payment-url/${orderId}`);
}
