import { Modal, notification } from "antd"
import React from "react";
import Cropper, { Area } from 'react-easy-crop'
import { useDispatch } from "react-redux";
import { UploadAPI } from "../../api/upload";
import { updateUserInfo } from "../../api/user";
import { HRButton } from "../../components/button";
import { setUserInfo } from "../../redux/actions";
import { setLoading } from "../../redux/slices/appInfo";
import getCroppedImg from "../../utils/crop";
import { useNotification } from "../../hooks/useNotification";
import i18next from "../../i18n";

type UploadAvatarProps = {
    file?: File;
    onClose: () => void;
}
const fileToBase64 = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
})
const BASE_STYLE = {
    height: "52px",
    borderRadius: "10px"
}
export const UploadAvatar: React.FC<UploadAvatarProps> = ({
    file,
    onClose,
}) => {
    const dispatch = useDispatch();
    const { showSuccess } = useNotification();
    const [base64, setBase64] = React.useState<string>("");
    const [crop, setCrop] = React.useState({ x: 0, y: 0 })
    const [zoom, setZoom] = React.useState(1)
    React.useEffect(() => {
        if (file) fileToBase64(file).then(setBase64);
        else setBase64("");
    }, [file]);

    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area | null>(null)
    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }
    const onAccept = async () => {
        try {
            dispatch(setLoading(true));
            const croppedImage: any = await getCroppedImg(
                base64,
                croppedAreaPixels,
            )
            const res = await fetch(croppedImage);
            const blob = await res.blob();
            const myFile = new File([blob], 'image.jpeg', {
                type: blob.type,
            });
            const { data } = await UploadAPI(myFile);
            const url = data.fileUrl;
            await updateUserInfo({ avatar: url });
            dispatch(setUserInfo({
                avatar: url,
            }))
            showSuccess(i18next.t('myProfile.success'));
            onClose();
        } catch (e) {
            console.error(e)
            notification.error({
                message: "Something went wrong",
                placement: "bottom",
            });
        } finally {
            dispatch(setLoading(false));
        }
    }
    return (
        <Modal
            centered
            onOk={onAccept}
            onCancel={onClose}
            open={!!file}
            title={
                <div className="font-bold text-[18px]">{i18next.t('myProfile.alignYourAvatar')}</div>
            }
            footer={
                <div className="flex justify-end gap-[16px]">
                    <HRButton onClick={onClose} btnSize="sm" btnType="sub">
                        {i18next.t('myProfile.cancel')}
                    </HRButton>
                    <HRButton
                        onClick={onAccept}
                        btnSize="sm">
                        {i18next.t('myProfile.confirm')}
                    </HRButton>
                </div>
            }
        >
            <div className="w-full h-[300px] relative">
                <Cropper
                    image={base64}
                    crop={crop}
                    zoom={zoom}
                    aspect={3/3}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    objectFit="vertical-cover"
                />
            </div>
        </Modal>
    )
}
