import { IconProps } from "./type"

export const ICFee: React.FC<IconProps> = ({
    fill = "#FFCB46",
}) => {
    return (
        <svg width="81" height="25" viewBox="0 0 81 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M80.0522 12.5L69.5 0H0L10.5522 12.5L0 25H69.5L80.0522 12.5Z" fill={fill}/>
        </svg>
    )
}