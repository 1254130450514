import { IconProps } from "./type"

export const ICComingSoonTop: React.FC<IconProps> = () => {
    return (
        <svg width="104" height="33" viewBox="0 0 104 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.2">
                <path d="M5.07547 28.6647C5.07547 26.5169 3.26772 24.7758 1.03774 24.7758C-1.19224 24.7758 -3 26.5169 -3 28.6647C-3 30.8125 -1.19224 32.5536 1.03774 32.5536C3.26772 32.5536 5.07547 30.8125 5.07547 28.6647Z" fill="#D9D9D9" />
                <path d="M19.2075 28.6647C19.2075 26.5169 17.3998 24.7758 15.1698 24.7758C12.9398 24.7758 11.1321 26.5169 11.1321 28.6647C11.1321 30.8125 12.9398 32.5536 15.1698 32.5536C17.3998 32.5536 19.2075 30.8125 19.2075 28.6647Z" fill="#D9D9D9" />
                <path d="M33.3396 28.6647C33.3396 26.5169 31.5319 24.7758 29.3019 24.7758C27.0719 24.7758 25.2642 26.5169 25.2642 28.6647C25.2642 30.8125 27.0719 32.5536 29.3019 32.5536C31.5319 32.5536 33.3396 30.8125 33.3396 28.6647Z" fill="#D9D9D9" />
                <path d="M47.4717 28.6647C47.4717 26.5169 45.6639 24.7758 43.434 24.7758C41.204 24.7758 39.3962 26.5169 39.3962 28.6647C39.3962 30.8125 41.204 32.5536 43.434 32.5536C45.6639 32.5536 47.4717 30.8125 47.4717 28.6647Z" fill="#D9D9D9" />
                <path d="M61.6038 28.6647C61.6038 26.5169 59.796 24.7758 57.566 24.7758C55.3361 24.7758 53.5283 26.5169 53.5283 28.6647C53.5283 30.8125 55.3361 32.5536 57.566 32.5536C59.796 32.5536 61.6038 30.8125 61.6038 28.6647Z" fill="#D9D9D9" />
                <path d="M75.7358 28.6647C75.7358 26.5169 73.9281 24.7758 71.6981 24.7758C69.4681 24.7758 67.6604 26.5169 67.6604 28.6647C67.6604 30.8125 69.4681 32.5536 71.6981 32.5536C73.9281 32.5536 75.7358 30.8125 75.7358 28.6647Z" fill="#D9D9D9" />
                <path d="M89.8679 28.6647C89.8679 26.5169 88.0602 24.7758 85.8302 24.7758C83.6002 24.7758 81.7924 26.5169 81.7924 28.6647C81.7924 30.8125 83.6002 32.5536 85.8302 32.5536C88.0602 32.5536 89.8679 30.8125 89.8679 28.6647Z" fill="#D9D9D9" />
                <path d="M104 28.6647C104 26.5169 102.192 24.7758 99.9623 24.7758C97.7323 24.7758 95.9245 26.5169 95.9245 28.6647C95.9245 30.8125 97.7323 32.5536 99.9623 32.5536C102.192 32.5536 104 30.8125 104 28.6647Z" fill="#D9D9D9" />
                <path d="M5.07547 15.0536C5.07547 12.9058 3.26772 11.1647 1.03774 11.1647C-1.19224 11.1647 -3 12.9058 -3 15.0536C-3 17.2014 -1.19224 18.9425 1.03774 18.9425C3.26772 18.9425 5.07547 17.2014 5.07547 15.0536Z" fill="#D9D9D9" />
                <path d="M19.2075 15.0536C19.2075 12.9058 17.3998 11.1647 15.1698 11.1647C12.9398 11.1647 11.1321 12.9058 11.1321 15.0536C11.1321 17.2014 12.9398 18.9425 15.1698 18.9425C17.3998 18.9425 19.2075 17.2014 19.2075 15.0536Z" fill="#D9D9D9" />
                <path d="M33.3396 15.0536C33.3396 12.9058 31.5319 11.1647 29.3019 11.1647C27.0719 11.1647 25.2642 12.9058 25.2642 15.0536C25.2642 17.2014 27.0719 18.9425 29.3019 18.9425C31.5319 18.9425 33.3396 17.2014 33.3396 15.0536Z" fill="#D9D9D9" />
                <path d="M47.4717 15.0536C47.4717 12.9058 45.6639 11.1647 43.434 11.1647C41.204 11.1647 39.3962 12.9058 39.3962 15.0536C39.3962 17.2014 41.204 18.9425 43.434 18.9425C45.6639 18.9425 47.4717 17.2014 47.4717 15.0536Z" fill="#D9D9D9" />
                <path d="M61.6038 15.0536C61.6038 12.9058 59.796 11.1647 57.566 11.1647C55.3361 11.1647 53.5283 12.9058 53.5283 15.0536C53.5283 17.2014 55.3361 18.9425 57.566 18.9425C59.796 18.9425 61.6038 17.2014 61.6038 15.0536Z" fill="#D9D9D9" />
                <path d="M75.7358 15.0536C75.7358 12.9058 73.9281 11.1647 71.6981 11.1647C69.4681 11.1647 67.6604 12.9058 67.6604 15.0536C67.6604 17.2014 69.4681 18.9425 71.6981 18.9425C73.9281 18.9425 75.7358 17.2014 75.7358 15.0536Z" fill="#D9D9D9" />
                <path d="M5.07547 1.44248C5.07547 -0.705299 3.26772 -2.44641 1.03774 -2.44641C-1.19224 -2.44641 -3 -0.705299 -3 1.44248C-3 3.59025 -1.19224 5.33136 1.03774 5.33136C3.26772 5.33136 5.07547 3.59025 5.07547 1.44248Z" fill="#D9D9D9" />
                <path d="M19.2075 1.44248C19.2075 -0.705299 17.3998 -2.44641 15.1698 -2.44641C12.9398 -2.44641 11.1321 -0.705299 11.1321 1.44248C11.1321 3.59025 12.9398 5.33136 15.1698 5.33136C17.3998 5.33136 19.2075 3.59025 19.2075 1.44248Z" fill="#D9D9D9" />
                <path d="M33.3396 1.44248C33.3396 -0.705299 31.5319 -2.44641 29.3019 -2.44641C27.0719 -2.44641 25.2642 -0.705299 25.2642 1.44248C25.2642 3.59025 27.0719 5.33136 29.3019 5.33136C31.5319 5.33136 33.3396 3.59025 33.3396 1.44248Z" fill="#D9D9D9" />
                <path d="M47.4717 1.44248C47.4717 -0.705299 45.6639 -2.44641 43.434 -2.44641C41.204 -2.44641 39.3962 -0.705299 39.3962 1.44248C39.3962 3.59025 41.204 5.33136 43.434 5.33136C45.6639 5.33136 47.4717 3.59025 47.4717 1.44248Z" fill="#D9D9D9" />
            </g>
        </svg>

    )
}
