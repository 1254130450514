import React from "react"
import { HRButton } from "../../components/button"
import { ICAccount, ICHistory, ICStar } from "../../icons/index"
import { HomeHeader } from "../home/header"
import { Transaction } from "./transaction"
import { getMyCandidateCreditInfo } from "../../api/user"
import { TMyCandidateCreditInfo } from "../my_assessment/type"
import { useNavigate } from "react-router-dom"
import { useGeneralPageData } from "../general"
import i18next from "../../i18n"

export const MyAccountPage: React.FC = () => {
    const { setShowComingSoonModal } = useGeneralPageData();
    const navigate = useNavigate();
    const [myCandidateCreditInfo, setMyCandidateCreditInfo] = React.useState<TMyCandidateCreditInfo>({} as TMyCandidateCreditInfo);

    const onLoadCandidateCreditInfo = React.useCallback(async () => {
        try {
            const results = await getMyCandidateCreditInfo();
            const { data } = results || {};
            setMyCandidateCreditInfo(data);
        } catch (e) {
            console.error(e)
        }
    }, []);

    React.useEffect(() => {
        onLoadCandidateCreditInfo();
    }, [onLoadCandidateCreditInfo]);

    return (
        <div className="px-2 lg:px-0 lg:pt-8 space-y-6 pb-16 bg-white">
            <HomeHeader activedIndex={1} />
            <div className="text-left lg-container mx-auto lg:pt-4 space-y-16">
                <div>
                    <div className="flex relative rounded-[24px] min-h-[304px] w-full text-white p-4 lg:p-8" style={{
                        background: "linear-gradient(90deg, #F09819 0%, #EDDE5D 100%)",
                        boxShadow: "0px 6px 16px -6px rgba(17, 12, 34, 0.1)",
                    }}>
                        <span className="hidden lg:block absolute right-0 top-0">
                            <ICAccount />
                        </span>
                        <div className="flex flex-col gap-[32px]">
                            <div className="flex flex-col gap-[8px]">
                                <h2 className="mb-0 text-white text-[20px] lg:text-[28px] leading-[40px] font-bold">{i18next.t('account.yourCandidateCredit')}</h2>
                                <span className="flex gap-[8px] items-center">
                                    <ICStar fill="white" />
                                    <p className="mb-0 text-[14px] font-medium leading-[24px]">{myCandidateCreditInfo?.remainingCandidateCredit} {i18next.t('account.candidateCredit')} </p>
                                </span>
                            </div>
                            <p className="mb-0 max-w-[555px] text-[14px] font-normal leading-[16px]">
                                {i18next.t('account.candidateCreditDescription')}
                            </p>
                            <HRButton
                                onClick={() => {
                                    navigate("/candidate-credit/payment");
                                }}
                                style={{
                                    color: "#E09400",
                                    background: "white",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "208px",
                                    height: "56px",
                                    borderRadius: "8px",
                                    lineHeight: "24px",
                                    boxShadow: "0px 1px 2px -1px rgba(17, 12, 34, 0.08)",
                                }}>
                                <span>{i18next.t('account.addCandidateCredit')}</span>
                            </HRButton>
                        </div>
                    </div>
                </div>

                <div className="lg:flex justify-between items-start gap-[32px]">
                    <div className="lg:flex flex-col gap-[8px] lg:max-w-[554px]">
                        <h2 className="mb-0 text-high-em text-title-bold">
                            {i18next.t('account.overview')}
                        </h2>
                        <p className="mb-0 text-med-em text-body">
                            {i18next.t('account.overviewDescription')}
                        </p>
                    </div>
                    <div className="flex flex-col justify-center items-start gap-[16px]">
                        <ul
                            className="p-[24px] text-[14px] leading-[24px] font-normal w-full lg:min-w-[554px]"
                            style={{
                                border: "1px solid #D9D8DC",
                                borderRadius: "12px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                            }}
                        >
                            <li className="flex items-center justify-between pb-[8px]" style={{
                                borderBottom: "1px solid #D9D8DC",
                            }}>
                                <span>
                                    {i18next.t('account.totalPurchasedCandidateCredit')}
                                </span>
                                <span className="font-bold">
                                    {myCandidateCreditInfo?.totalCandidateCreditPurchased}
                                </span>
                            </li>
                            <li className="flex items-center justify-between pb-[8px]" style={{
                                borderBottom: "1px solid #D9D8DC",
                            }}>
                                <span>
                                    {i18next.t('account.totalUsedCandidateCredit')}
                                </span>
                                <span className="font-bold">
                                    {myCandidateCreditInfo?.totalUsedCount}
                                </span>
                            </li>
                            <li className="flex items-center justify-between pb-[8px]" style={{
                                borderBottom: "1px solid #D9D8DC",
                            }}>
                                <span>
                                    {i18next.t('account.remainingCandidateCredit')}
                                </span>
                                <span className="font-bold">
                                    {myCandidateCreditInfo?.remainingCandidateCredit}
                                </span>
                            </li>
                            <li className="flex items-center justify-between">
                                <span>
                                    {i18next.t('account.candidateUsedInLast7Days')}
                                </span>
                                <span className="font-bold">
                                    {myCandidateCreditInfo?.lastSevenDaysUsedCount}
                                </span>
                            </li>
                        </ul>
                        <div className="w-full">
                            <HRButton btnType="sub"
                                btnSize="sm"
                                onClick={() => setShowComingSoonModal(true)}
                            >
                                <div className="flex items-center justify-center gap-[8px]">
                                    <span>
                                        {i18next.t('account.viewHistory')}
                                    </span>
                                    <ICHistory />
                                </div>
                            </HRButton>
                        </div>

                    </div>
                </div>

                {/* <div className="flex space-x-8">
                    <div className="w-2/3">
                        <div className="text-[24px] font-medium text-n-800 mb-4">Gói</div>
                        <div
                            className="p-8 rounded-[20px]"
                            style={{
                                background: "linear-gradient(90deg, #7357FF 0%, #6E4CF8 100.31%)",
                            }}
                        >
                            <div className="flex justify-between">
                                <div>
                                    <div className="font-bold text-light-purple">Gói hiện tại của bạn</div>
                                    <div className="font-bold text-[24px] text-white">Miễn phí</div>
                                    <div className="text-support-purple text-xs">Với gói miễn phí, bạn có thể sử dụng miễn phí một số bài kiểm tra đã chọn và mười câu hỏi tùy chỉnh cho mỗi bài đánh giá cho số lượng ứng viên và bài đánh giá không giới hạn.</div>
                                    <div></div>
                                </div>
                                <div>
                                    <HRButton style={{
                                        color: "#7357FF",
                                        background: "white",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "48px",
                                        padding: "14px 22px"
                                    }}>Thay đổi</HRButton>
                                </div>
                            </div>
                            <div className="space-y-2 mt-6">
                                <div className="space-x-2 flex items-center text-white">
                                    <ICVerified />
                                    <span>Số lượng bài thẩm định không giới hạn </span>
                                </div>
                                <div className="space-x-2 flex items-center text-white">
                                    <ICCancel />
                                    <span>Không giới hạn chia sẻ </span>
                                </div>
                                <div className="space-x-2 flex items-center text-white">
                                    <ICCancel />
                                    <span>Lựa chọn các bài kiểm tra chất lượng </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/3">
                        <div className="text-[24px] font-medium text-n-800 mb-4">Thanh toán</div>
                        <div className="p-8 border-[2px] border-n-300 rounded-[20px] space-y-4">
                            <div className="text-[18px] font-medium">Phương thức thanh toán</div>
                            <div className="space-y-1">
                                <div className="text-base font-bold text-n-800">Visa: 5342</div>
                                <div className="font-medium text-n-500 text-base">Ngày hết hạn: 15/06/2022</div>
                            </div>
                            <div className="space-y-1">
                                <div className="text-base font-bold text-n-800">Master card: 5342</div>
                                <div className="font-medium text-n-500 text-base">Ngày hết hạn: 15/06/2022</div>
                            </div>
                            <div className="pt-6">
                                <HRButton style={{
                                    background: "white",
                                    color: "#7357FF",
                                    padding: "14px 22px",
                                    border: "1px solid #7357FF",
                                    borderRadius: "6px",
                                    fontSize: "16px",
                                    fontWeight: 700,
                                }}>Cập nhật</HRButton>
                            </div>

                        </div>
                    </div>
                </div> */}


                <div className="h-[2px] bg-n-200"></div>
                <Transaction />
            </div>
        </div>
    )
}
