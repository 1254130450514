import styled from 'styled-components';
import Webcam from 'react-webcam';
import React from 'react';
import { getURL } from '../utils';
import { ICWarning } from '../icons';
import { TAssessmentTesting } from '../pages/testing/type';
import { useTestingPageContext } from '../pages/testing';
import { EVENT_NAME_SOCKET } from '../utils/constants';

const Wrapper = styled.div` 
    &.custom-camera {
        max-width: 288px;
        height: 216px;
        background: #D9D8DC;
        border-radius: 12px;
        width: 100%;
    }
    &.camera-testing {
        max-width: 288px;
        min-width: 288px;
        height: 216px;
        width: 100%;
        video {
            max-width: 288px;
            height: 216px;
            width: 100%;
        }
    }
    &.camera-testing-mobile {
        max-width: 165px;
        min-width: 165px;
        height: 116px;
        width: 100%;
        video {
            max-width: 165px;
            height: 116px;
            width: 100%;
        }
    }
    .none-camera {
        max-width: 288px;
        min-width: 288px;
        height: 216px;
        width: 100%;
        background: var(--tc-surface-high);
        border-radius: 12px;
    }
`;

type HRCameraProps = {
    assessmentDetails?: TAssessmentTesting;
    deviceId?: string;
    isCameraEnabled?: boolean;
    setIsCameraEnabled?: (isCameraEnabled: boolean) => void;
    classess?: string;
    height?: number;
    width?: number;
    setIsNoneCamera?: React.Dispatch<React.SetStateAction<boolean>>;
    setVideoStream?: React.Dispatch<React.SetStateAction<MediaStream | undefined>>;
}

export const HRCamera: React.FC<HRCameraProps> = ({
    assessmentDetails,
    deviceId,
    isCameraEnabled = true,
    setIsCameraEnabled,
    setVideoStream,
    classess,
    setIsNoneCamera,
    height = 216,
    width = 288,
}) => {
    const webcamRef = React.useRef<Webcam>(null);
    const videoConstraints = {
        width: { min: 288 },
        facingMode: "user",
        deviceId
    };
    const [isNotSupportCamera, setIsNotSupportCamera] = React.useState<boolean>(false);

    const stopCamera = () => {
        console.warn('stop camera', webcamRef);
        if (webcamRef?.current) {
            let streamVideo = webcamRef?.current?.video?.srcObject as MediaStream;
            if (streamVideo) {
                streamVideo.getTracks()?.forEach((track: any) => track?.stop());
            }
        }
    };

    React.useEffect(() => {
        return () => {
            stopCamera();
        }
    }, []);

    const onUserMediaError = React.useCallback((error: any) => {
        console.warn('error', error);
        setIsNotSupportCamera(true);
        setIsNoneCamera && setIsNoneCamera(true);
        setIsCameraEnabled && setIsCameraEnabled(false);
    }, [setIsCameraEnabled, setIsNoneCamera]);
    const onUserMedia = useRecordCamera();
    return <Wrapper className={classess}>
        {isCameraEnabled ?
            <div className="relative w-full">
                <Webcam
                    ref={webcamRef}
                    audio={false}
                    height={height}
                    width={width}
                    videoConstraints={videoConstraints}
                    onUserMediaError={onUserMediaError}
                    forceScreenshotSourceSize={true}
                    onUserMedia={(stream) => {
                        onUserMedia(stream);
                        setIsNotSupportCamera(false);
                        setIsNoneCamera && setIsNoneCamera(false);
                        setIsCameraEnabled && setIsCameraEnabled(true);
                        setVideoStream && setVideoStream(stream);
                    }}
                    style={{
                        borderRadius: '12px',
                        width: '100%',
                    }} />
                {isNotSupportCamera &&
                    <div className="flex flex-col items-center justify-center h-full absolute top-[0] w-full gap-[8px] bg-surface-high rounded-[12px] not-support-camera">
                        <span>
                            <ICWarning fill="var(--icon-low-em)" />
                        </span>
                        <span className="text-body-medium text-high-em">Không hiển thị được camera</span>
                    </div>
                }
            </div>
            :
            <div className="none-camera">
                <div className="flex items-center justify-center h-full">
                    <img alt="" src={getURL('')} className="w-[60px] h-[60px] rounded-full bg-[#D9D9D9]" />
                </div>
            </div>
        }
    </Wrapper>
}


export const useRecordCamera = () => {
    const { socket } = useTestingPageContext();
    const onUserMedia = React.useCallback((stream: MediaStream) => {
        if (!socket || socket.disconnected) return;
        const recorder = new MediaRecorder(stream);
        recorder.ondataavailable = (e) => {
            const blob = e.data;
            socket.emit(EVENT_NAME_SOCKET.SEND.UPLOAD_CAMERA_BUFFER, {
                blob
            })
        }
        recorder.onstop = () => {
            socket.emit(EVENT_NAME_SOCKET.SEND.TURN_OFF_CAMERA)
        }
        recorder.start(2000);
        return () => {
            recorder.stop();
        }
    }, [socket]);
    return onUserMedia;
}