import React from "react";
import { HRButton } from "../../../components/button";
import { HRTextArea } from "../../../components/form/textarea";
import { TAssessment } from "../create-assessment/type"
import i18next from "../../../i18n";

type AssessmentNoteProps = {
    assessmentDetails: TAssessment;
    onUpdateNote: (note: string) => void;
}
export const AssessmentNote: React.FC<AssessmentNoteProps> = ({
    assessmentDetails,
    onUpdateNote
}) => {
    const [note, setNote] = React.useState<string>(assessmentDetails.note ?? "");
    React.useEffect(() => {
        setNote(assessmentDetails.note ?? "");
    }, [assessmentDetails.note]);
    return <div className="min-h-[250px] flex flex-col w-full items-start justify-start rounded-[12px] bg-white shadow-e-03 z-[3] p-4">
        <span className="text-standard-bold text-g-800">{i18next.t('assessmentDetail.note')}</span>
        <HRTextArea
            parentClassName="w-full mt-2"
            rows={5}
            value={note}
            onChange={(e) => setNote(e.target.value)}
        />
        <div className="flex justify-end w-full mt-4">
            <div className="align-right w-[120px]">
                <HRButton
                    btnSize="sm"
                    disabled={note === (assessmentDetails.note ?? "")}
                    onClick={() => onUpdateNote(note)}
                >
                    {i18next.t('assessmentDetail.update')}
                </HRButton>
            </div>
        </div>
    </div>
}