import React, { useState } from "react"
import { IViewV2 } from "../../icons/index";
import { HRButton, IconButton } from "../../components/button"
import { Card, Badge, Typography, Divider, Modal, Tooltip } from 'antd';
import { ILibrary, TRatingLevel } from './models';
import { LibraryTags } from './tags';
import { LibraryDetails } from "./details"
import Highlighter from "react-highlight-words";
import classNames from 'classnames'
import i18next from "../../i18n"

const { Title } = Typography;
const statusClassMapping = {
    new: "tag-new",
    popular: "tag-popular",
    normal: "tag-normal",
    pay: "tag-paid",
    free: "tag-free",
}
type LibraryCardProps = {
    data: ILibrary;
    ratingLevel?: TRatingLevel[] | undefined;
    keyword?: string,
}
export const LibraryCard: React.FC<LibraryCardProps> = ({
    data = {} as ILibrary,
    keyword,
    ratingLevel = []
}) => {
    const [open, setOpen] = useState(false);
    const openDetails = () => {
        setOpen(true);
    };
    const onPreviewTesting = React.useCallback(() => {
        window.open(`/testing/${data.id}/preview`, '_blank');
    }, [data]);

    return <>
        <Modal
            open={open}
            centered={true}
            closable={false}
            footer={null}
            width={1140}
        >
            <LibraryDetails data={data} ratingLevel={ratingLevel} setOpen={setOpen} />
        </Modal>
        <div className="card-wrapper">
            <Badge.Ribbon className={
                classNames(
                    data.isPublic === false ? "tag-private" : `${statusClassMapping[data?.statusTag]} ${statusClassMapping[data?.planTag]}`
                )
            }
                text={<LibraryTags data={data} />} placement={'start'}>
                <Card size="small" className="rounded-[8px]" style={{
                    boxShadow: '0px 6px 16px -6px rgba(17, 12, 34, 0.10)',
                }} bodyStyle={{
                    padding: '16px'
                }}>
                    <div className="grid pt-[47px] grid-cols-1 gap-[16px] text-left">
                        <div className="">
                            <Tooltip title={data?.title} placement="top">
                                <Title level={5}
                                    style={{
                                        minHeight: '48px',
                                        color: '#110C22',
                                        fontWeight: 700,
                                        lineHeight: '24px',
                                        fontSize: '16px'
                                    }}>
                                    <span className="line-clamp-2">
                                        <Highlighter
                                            highlightClassName="text-highlight"
                                            searchWords={[`${keyword || ''}`]}
                                            autoEscape={true}
                                            textToHighlight={data?.title || ''}
                                        />
                                    </span>
                                </Title>
                            </Tooltip>
                        </div>
                        <div>
                            <p className="line-clamp-7 mb-0 text-med-em text-sub"
                                style={{
                                    minHeight: '111px'
                                }}
                            >
                                <Highlighter
                                    highlightClassName="text-highlight"
                                    searchWords={[`${keyword || ''}`]}
                                    autoEscape={true}
                                    textToHighlight={data?.summary || ''}
                                />
                            </p>
                        </div>
                        <div className="flex flex-row gap-[8px] items-center justify-start">
                            {
                                data?.tags?.map((item, index) => {
                                    return <div key={index}
                                        className="grid items-center justify-center text-center gap-[8px] rounded-[50px] border-[1px] border-outline-high text-high-em text-sub-medium px-[8px] py-[4px]"
                                        style={{
                                            gridTemplateColumns: item?.icon ? '12px auto' : 'auto'
                                        }}
                                    >
                                        {item?.icon && <span>{item?.icon}</span>}
                                        <span className="m-0 w-full">{item.name}</span>
                                    </div>
                                })
                            }
                        </div>
                        <div><Divider style={{ margin: '0', borderColor: '#E2E4EB' }} /></div>
                        <div
                            className={classNames([
                                'grid items-center gap-[16px]',
                                {
                                    'justify-center grid-cols-1': data?.previewSection?.length === 0,
                                },
                                {
                                    'grid-cols-[auto_1fr]': data?.previewSection && data?.previewSection?.length > 0,
                                }
                            ])}
                        >
                            {data?.previewSection && data?.previewSection?.length > 0 && <div>
                                <IconButton btnSize="sm" btnType="sub" icon={<IViewV2 height={18} width={18}  />}
                                    onClick={onPreviewTesting} 
                                    hoverText={i18next.t('library.tryTheTest') ?? ''}
                                />

                            </div>}
                            <div>
                                <HRButton
                                    onClick={openDetails}
                                    btnSize="sm"
                                    btnType="secondary"
                                    className="flex items-center justify-center rounded-[4px] h-[40px]">
                                    {i18next.t('library.detail')}
                                </HRButton>
                            </div>
                        </div>
                    </div>
                </Card>
            </Badge.Ribbon>
        </div>
    </>
}
