import { ApiInstance } from "..";

export type TColorCompanyProfile = {
  backgroundColor: string;
  fontColor: string;
};

export type TCompanyProfileRequest = {
  name: string;
  logo: string;
  taxCode: string;
  address: string;
  email: string;
  country: string;
  color: TColorCompanyProfile;
};

export type TCompanyProfileEntity = {
  address: string | null;
  country: string | null;
  email: string | null;
  logo: string | null;
  name: string;
  taxCode: string | null;
  color: TColorCompanyProfile;
};

export type TCompanyProfile = TCompanyProfileEntity & {
  _id: string;
  userId: string;
  isActive: boolean;
};

export const getCompanyProfile = () => {
  const requestURL = `/api/company-profile`;
  return ApiInstance.getInstance().get(requestURL);
};

export const updateCompanyProfile = (request: TCompanyProfileRequest) => {
  const requestURL = `/api/company-profile`;
  return ApiInstance.getInstance().patch(requestURL, request);
};

export const getCompanyProfileByAssessmentId = (assessmentId: string) => {
  const requestURL = `/api/company-profile/get-by-assessment/${assessmentId}`;
  return ApiInstance.getInstance().get(requestURL);
};

export const getCompanyProfileByTestCandidate = (testCandidateUrl: string) => {
  const requestURL = `/api/company-profile/get-by-test-candidate/${testCandidateUrl}`;
  return ApiInstance.getInstance().get(requestURL);
};

export const getCompanyProfileByAssessmentUrl = (assessmentUrl: string) => {
  const requestURL = `/api/company-profile/get-by-assessment-url/${assessmentUrl}`;
  return ApiInstance.getInstance().get(requestURL);
};
