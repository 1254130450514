import { IconProps } from "./type"

export const ICSingleChoice: React.FC<IconProps> = ({
    width = 49,
    height = 49,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Single choice" clipPath="url(#clip0_10979_85824)">
                <g id="Group">
                    <path id="Vector" d="M24.2502 2.5625C11.5648 2.5625 1.28125 12.846 1.28125 25.5314C1.28125 38.0611 11.3143 48.2464 23.7846 48.4945L34.8134 45.9313C42.1833 42.1073 47.2191 34.4079 47.2191 25.5314C47.2191 12.846 36.9356 2.5625 24.2502 2.5625Z" fill="#4F4B5C" />
                    <path id="Vector_2" d="M38.9849 13.4492H36.7733C36.5857 13.6689 36.1741 14.2317 36.1719 14.9479C36.1703 15.4893 36.403 15.9973 36.8637 16.4579C38.0981 17.6923 38.4532 18.7281 38.5244 18.9759C38.383 19.1879 37.9351 19.9488 38.1625 20.7853C38.2797 21.2167 38.5567 21.5935 38.9878 21.9124C40.594 21.9108 41.8957 20.6083 41.8957 19.0018V16.3601C41.8956 14.7523 40.5924 13.4492 38.9849 13.4492Z" fill="#F9A0B1" />
                    <path id="Vector_3" d="M36.3443 13.4492C34.7368 13.4492 33.4336 14.7524 33.4336 16.36V19.0017C33.4336 20.6092 34.7368 21.9124 36.3443 21.9124H38.986C39.0648 21.9124 39.1426 21.9084 39.2198 21.9023C37.3992 20.7089 38.6781 19.0031 38.6781 19.0031C38.6781 19.0031 38.4279 17.8222 36.9642 16.3586C35.5005 14.8948 36.9642 13.4493 36.9642 13.4493H36.3443V13.4492Z" fill="#FF7186" />
                    <path id="Vector_4" d="M38.9864 18.6936V16.6678C38.9864 16.497 38.8479 16.3584 38.677 16.3584H36.6512C36.4804 16.3584 36.3418 16.4969 36.3418 16.6678V18.6936C36.3418 18.8644 36.4803 19.003 36.6512 19.003H38.677C38.8479 19.003 38.9864 18.8644 38.9864 18.6936Z" fill="#FF4155" />
                    <path id="Vector_5" d="M22.8302 16.7752C24.103 18.0481 24.8735 18.6038 24.9057 18.6268L24.9744 18.676L25.4169 18.4567C25.4377 18.8608 25.5607 19.6879 26.1941 20.152C26.6696 20.5002 27.3264 20.5657 28.151 20.3497C28.3621 19.9466 28.4821 19.4882 28.4821 19.0017V16.36C28.4821 14.7524 27.1789 13.4492 25.5714 13.4492H22.9297C22.8735 13.4492 22.8178 13.4512 22.7624 13.4544C22.5338 13.8481 21.6384 15.5834 22.8302 16.7752Z" fill="#3ECE9A" />
                    <path id="Vector_6" d="M20.0195 16.36V19.0017C20.0195 20.6092 21.3227 21.9124 22.9303 21.9124H25.572C26.7592 21.9124 27.7798 21.2012 28.2326 20.182C25.4021 21.0015 25.556 18.2312 25.556 18.2312L24.9882 18.5125C24.9882 18.5125 24.2096 17.9551 22.9303 16.6757C21.651 15.3964 22.9303 13.4492 22.9303 13.4492C21.3227 13.4492 20.0195 14.7523 20.0195 16.36Z" fill="#2BBC85" />
                    <path id="Vector_7" d="M27.2564 43.3622C29.3141 43.7434 32.2939 43.7267 34.9497 41.5558C35.342 40.5247 36.3796 39.248 36.3796 36.5856C36.3797 35.8584 36.3797 30.7208 36.3797 28.8311C36.3797 27.9262 35.6394 27.186 34.7345 27.1859H34.578C33.6731 27.1859 32.9328 27.9262 32.9328 28.8311V27.7241C32.9328 26.8448 32.2926 26.0726 31.4196 25.9678C30.3789 25.8428 29.486 26.6615 29.486 27.6785V27.3309C29.486 26.4516 28.8458 25.6795 27.9728 25.5746C26.9321 25.4495 26.0392 26.2682 26.0392 27.2853V21.8051C26.0392 20.9258 25.3989 20.1536 24.5259 20.0488C23.4851 19.9238 22.5923 20.7425 22.5923 21.7595V30.9065C22.5923 31.0173 22.5355 31.1144 22.4502 31.1713L22.4919 32.7641C22.5044 33.2414 22.1775 33.5245 21.8936 33.622C21.6014 33.7225 21.1897 33.6955 20.9117 33.3186C20.6336 32.9417 20.4457 32.5119 20.3469 32.0356C19.2196 33.4561 19.0089 35.4095 19.828 37.0444C20.635 38.6048 19.5523 36.8891 23.3604 41.8075C23.4277 41.8921 23.4864 41.9823 23.5373 42.0765C24.0843 42.3676 25.4664 43.0306 27.2564 43.3622Z" fill="#FFC7AB" />
                    <path id="Vector_8" d="M23.9131 20.0361H24.7164C25.1779 20.0361 25.5553 20.4137 25.5553 20.8749V22.3838C25.5553 22.8453 25.1779 23.2227 24.7164 23.2227H23.9131C23.4517 23.2227 23.0742 22.8453 23.0742 22.3838V20.8749C23.0742 20.4136 23.4517 20.0361 23.9131 20.0361Z" fill="#FCE4D9" />
                    <path id="Vector_9" d="M22.6413 32.7598L22.5926 30.9062C22.5926 31.0864 22.4436 31.2308 22.2635 31.2242C21.7988 31.2072 21.0832 31.191 20.5055 31.845C20.4068 31.9567 20.315 32.0726 20.2285 32.1915C20.3474 32.6792 20.5511 33.0798 20.7922 33.4067C21.3861 34.2117 22.6675 33.7599 22.6413 32.7598Z" fill="#FFAA7B" />
                    <path id="Vector_10" d="M23.3613 41.8071C23.6183 42.1306 23.7581 42.5316 23.7581 42.9446L23.7849 48.4942C23.9398 48.4974 24.0948 48.5002 24.2505 48.5002C28.0594 48.5002 31.6512 47.5721 34.8137 45.9311L34.8028 42.2508C34.8028 41.9296 34.9151 41.6101 35.0792 41.2529C29.9169 45.7473 23.3613 41.8071 23.3613 41.8071Z" fill="#FFAA7B" />
                    <g id="Group_2">
                        <path id="Vector_11" d="M24.1326 19.6193C23.9192 19.6193 23.7145 19.5346 23.5636 19.3836L22.2162 18.0362C21.9019 17.7218 21.9019 17.2125 22.2162 16.8981C22.5304 16.584 23.0398 16.584 23.3541 16.8981L24.1326 17.6766L25.2717 16.5375C25.586 16.2231 26.0954 16.2232 26.4097 16.5374C26.7239 16.8516 26.7239 17.3611 26.4097 17.6753L24.7016 19.3836C24.5506 19.5346 24.346 19.6193 24.1326 19.6193Z" fill="#DEEEFF" />
                    </g>
                    <path id="Vector_12" d="M47.2182 25.531C47.2182 24.9628 47.1967 24.3996 47.1561 23.8418C44.196 23.9284 39.9082 24.9297 39.9082 30.0045V37.7546C39.9082 37.7546 39.9813 39.4565 41.3404 40.8738C44.9943 36.8066 47.2182 31.4289 47.2182 25.531Z" fill="#3C58A0" />
                    <path id="Vector_13" d="M1.28516 25.7716C1.39597 36.5481 8.92719 45.5445 19.0135 47.897C20.0097 44.9895 19.746 41.7936 17.8423 40.4739C13.6262 37.5509 14.414 35.0171 13.8383 28.3859C13.4041 23.3864 5.26484 24.782 1.28516 25.7716Z" fill="#3C58A0" />
                    <g id="Group_3">
                        <path id="Vector_14" d="M25.5727 0.5H23.3612C23.1736 0.719656 22.762 1.28244 22.7598 1.99869C22.7581 2.54 22.9909 3.04803 23.4516 3.50872C24.686 4.74312 25.0411 5.77888 25.1123 6.02666C24.9709 6.23872 24.523 6.99959 24.7504 7.83613C24.8676 8.26747 25.1446 8.64425 25.5756 8.96319C27.1819 8.96159 28.4836 7.65913 28.4836 6.05253V3.41075C28.4835 1.80322 27.1803 0.5 25.5727 0.5Z" fill="#F9A0B1" />
                        <path id="Vector_15" d="M22.9303 0.5C21.3227 0.5 20.0195 1.80322 20.0195 3.41075V6.05244C20.0195 7.65997 21.3227 8.96319 22.9303 8.96319H25.572C25.6507 8.96319 25.7285 8.95916 25.8057 8.95306C23.9852 7.75972 25.264 6.05384 25.264 6.05384C25.264 6.05384 25.0139 4.87297 23.5502 3.40934C22.0864 1.94563 23.5502 0.500094 23.5502 0.500094H22.9303V0.5Z" fill="#FF7186" />
                        <path id="Vector_16" d="M25.5743 5.7444V3.71855C25.5743 3.54774 25.4358 3.40918 25.2649 3.40918H23.2391C23.0683 3.40918 22.9297 3.54765 22.9297 3.71855V5.7444C22.9297 5.91521 23.0682 6.05377 23.2391 6.05377H25.2649C25.4358 6.05377 25.5743 5.91531 25.5743 5.7444Z" fill="#FF4155" />
                    </g>
                    <g id="Group_4">
                        <path id="Vector_17" d="M12.1567 13.4492H9.94517C9.75757 13.6689 9.34601 14.2317 9.34376 14.9479C9.34207 15.4892 9.57485 15.9972 10.0355 16.4578C11.2699 17.6923 11.6251 18.728 11.6963 18.9758C11.5549 19.1878 11.107 19.9487 11.3344 20.7853C11.4515 21.2166 11.7286 21.5934 12.1596 21.9123C13.7659 21.9107 15.0676 20.6083 15.0676 19.0017V16.36C15.0675 14.7524 13.7644 13.4492 12.1567 13.4492Z" fill="#F9A0B1" />
                        <path id="Vector_18" d="M9.51427 13.4492C7.90673 13.4492 6.60352 14.7524 6.60352 16.36V19.0017C6.60352 20.6092 7.90673 21.9124 9.51427 21.9124H12.156C12.2347 21.9124 12.3125 21.9084 12.3897 21.9023C10.5691 20.7089 11.848 19.0031 11.848 19.0031C11.848 19.0031 11.5979 17.8222 10.1341 16.3585C8.67042 14.8947 10.1341 13.4492 10.1341 13.4492H9.51427Z" fill="#FF7186" />
                        <path id="Vector_19" d="M12.1583 18.6936V16.6678C12.1583 16.497 12.0198 16.3584 11.8489 16.3584H9.82305C9.65223 16.3584 9.51367 16.4969 9.51367 16.6678V18.6936C9.51367 18.8644 9.65214 19.003 9.82305 19.003H11.8489C12.0198 19.0029 12.1583 18.8644 12.1583 18.6936Z" fill="#FF4155" />
                    </g>
                    <g id="Group_5">
                        <path id="Vector_20" d="M38.9868 0.5H36.7752C36.5877 0.719656 36.1761 1.28244 36.1738 1.99869C36.1721 2.54 36.4049 3.04803 36.8656 3.50872C38.1 4.74312 38.4551 5.77888 38.5264 6.02666C38.3849 6.23872 37.9371 6.99959 38.1644 7.83613C38.2816 8.26747 38.5587 8.64425 38.9897 8.96319C40.596 8.96159 41.8976 7.65913 41.8976 6.05253V3.41075C41.8976 1.80322 40.5943 0.5 38.9868 0.5Z" fill="#FFDD40" />
                        <path id="Vector_21" d="M36.3443 0.5C34.7368 0.5 33.4336 1.80322 33.4336 3.41075V6.05244C33.4336 7.65997 34.7368 8.96319 36.3443 8.96319H38.986C39.0648 8.96319 39.1426 8.95916 39.2198 8.95306C37.3992 7.75972 38.6781 6.05384 38.6781 6.05384C38.6781 6.05384 38.4279 4.87297 36.9642 3.40934C35.5005 1.94563 36.9642 0.500094 36.9642 0.500094H36.3443V0.5Z" fill="#FFB332" />
                        <path id="Vector_22" d="M38.9883 5.7444V3.71855C38.9883 3.54774 38.8499 3.40918 38.679 3.40918H36.6531C36.4823 3.40918 36.3438 3.54765 36.3438 3.71855V5.7444C36.3438 5.91521 36.4822 6.05377 36.6531 6.05377H38.679C38.8499 6.05377 38.9883 5.91531 38.9883 5.7444Z" fill="#FF8F33" />
                    </g>
                    <g id="Group_6">
                        <path id="Vector_23" d="M12.1567 0.5H9.94517C9.75757 0.719656 9.34601 1.28244 9.34376 1.99869C9.34207 2.54 9.57485 3.04803 10.0355 3.50872C11.2699 4.74312 11.6251 5.77888 11.6963 6.02666C11.5549 6.23872 11.107 6.99959 11.3344 7.83613C11.4515 8.26747 11.7286 8.64425 12.1596 8.96319C13.7659 8.96159 15.0676 7.65913 15.0676 6.05253V3.41075C15.0675 1.80322 13.7644 0.5 12.1567 0.5Z" fill="#FFDD40" />
                        <path id="Vector_24" d="M9.51427 0.5C7.90673 0.5 6.60352 1.80322 6.60352 3.41075V6.05244C6.60352 7.65997 7.90673 8.96319 9.51427 8.96319H12.156C12.2347 8.96319 12.3125 8.95916 12.3897 8.95306C10.5691 7.75972 11.848 6.05384 11.848 6.05384C11.848 6.05384 11.5979 4.87297 10.1341 3.40934C8.67042 1.94563 10.1341 0.500094 10.1341 0.500094H9.51427V0.5Z" fill="#FFB332" />
                        <path id="Vector_25" d="M12.1583 5.7444V3.71855C12.1583 3.54774 12.0198 3.40918 11.8489 3.40918H9.82305C9.65223 3.40918 9.51367 3.54765 9.51367 3.71855V5.7444C9.51367 5.91521 9.65214 6.05377 9.82305 6.05377H11.8489C12.0198 6.05377 12.1583 5.91531 12.1583 5.7444Z" fill="#FF8F33" />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_10979_85824">
                    <rect width="48" height="48" fill="white" transform="translate(0.25 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}
