import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ICCheckFill } from '../../icons';
import { HRButton } from '../../components/button';
import { PaymentMethosMapping } from '../payment/type';
import { formatMoney } from '../../utils';
import { formatLocaleDateString } from '../../utils/date';
import { usePaymentStatusPageContext } from '.';
import i18next from "../../i18n";


export const PaymentSuccessAssessmentPage: React.FC = () => {
    const navigate = useNavigate();
    const { orderDetails } = usePaymentStatusPageContext();

    return <div className="rounded-[12px] bg-white shadow-l-large flex w-full lg:max-w-[562px] flex-col items-center justify-center gap-[32px] p-4 lg:p-8">
        <ICCheckFill fill='#0BAA60' width={100} height={100} />
        <div className="flex flex-col gap-[24px]">
            <h1 className="mb-[0] text-center text-high-em text-heading-5-bold">{i18next.t('paymentStatus.paymentSuccess')}</h1>
            <div className="flex flex-col gap-[4px]">
                <h4 className="text-body mb-[0] text-center text-high-em">
                    {i18next.t('paymentStatus.assessmentExpireAt', { assessment: orderDetails?.difinition?.assessment?.title, expireDate: formatLocaleDateString(orderDetails?.difinition?.assessment?.expireDate as number) })}
                </h4>
                {orderDetails?.hasInvoice && <h4 className="text-body mb-[0] text-center text-high-em">
                    {i18next.t('paymentStatus.invoiceWillBeSendedToEmail')} <strong>{orderDetails?.invoiceInfo?.buyerEmail}</strong>
                </h4>}
            </div>
            <div className="flex flex-col gap-[24px] rounded-[12px] border border-[#ECECED] p-[24px]">
                <div className="flex justify-between items-center gap-[24px]">
                    <span className="text-body text-high-em">{i18next.t('paymentStatus.assessment')}</span>
                    <span className="text-body-bold text-high-em text-right">
                        {orderDetails?.difinition?.assessment?.title}
                    </span>
                </div>
                <div className="flex justify-between items-center gap-[24px]">
                    <span className="text-body text-high-em">{i18next.t('paymentStatus.date')}</span>
                    <span className="text-body-bold text-high-em text-right">
                        {formatLocaleDateString(orderDetails?.difinition?.assessment?.activeDate as number)}
                    </span>
                </div>
                <div className="flex justify-between items-center gap-[24px]">
                    <span className="text-body text-high-em">{i18next.t('paymentStatus.assessmentExpireDate')}</span>
                    <span className="text-body-bold text-high-em text-right">
                        {formatLocaleDateString(orderDetails?.difinition?.assessment?.expireDate as number)}
                    </span>
                </div>
                <div className="flex justify-between items-center gap-[24px]">
                    <span className="text-body text-high-em">{i18next.t('paymentStatus.orderCode')}</span>
                    <span className="text-body-bold text-high-em text-right">
                        {orderDetails?.orderCode}
                    </span>
                </div>
                <div className="flex justify-between items-center gap-[24px]">
                    <span className="text-body text-high-em">{i18next.t('paymentStatus.method')}</span>
                    {orderDetails?.paymentMethod && <span className="text-body-bold text-high-em text-right">
                        {PaymentMethosMapping[orderDetails?.paymentMethod]}
                    </span>}
                </div>
                <div className="flex justify-between items-center gap-[24px]">
                    <span className="text-body text-high-em">{i18next.t('paymentStatus.total')}</span>
                    {orderDetails?.pricing?.totalPrice && <span className="text-body-bold text-high-em text-right">
                        {formatMoney(orderDetails?.pricing?.totalPrice as number)}
                    </span>}
                </div>
            </div>
            <div className="flex items-center justify-center">
                <span>
                    <HRButton btnType="secondary"
                        onClick={() => {
                            navigate(`/assessment/${orderDetails?.difinition?.assessment?._id}/details`)
                        }}
                    >
                        {i18next.t('paymentStatus.backToAssessment')}
                    </HRButton>
                </span>
            </div>
        </div>
    </div>
}
