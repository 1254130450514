import React from "react";
import { HRCamera } from "../../../../components/camera";
import { ICCamera, ICCameraError, ICCameraOff, ICEyeTurnOff, ICEyeTurnOn, ICMicroPhoneOff } from "../../../../icons";
import { ICMicroPhoneOnV2, ICMicroPhoneV2 } from '../../../../icons/microphone';
import styled from "styled-components";
import { IconButton } from "../../../../components/button";
import classNames from "classnames";
import { TAssessmentTesting } from "../../type";
import { useMobile } from "../../../../hooks/useDimensions";

const Wrapper = styled.div`
    .camera-wrapper {
        border: 4px solid transparent;
        .camera-testing {
            position: fixed;
            display: flex;
            align-items: flex-end;
            bottom: 96px;
            height: 216px;
            right: 32px;
            z-index: 1;
            border: 4px solid transparent;
        }
        .camera-testing-mobile {
            position: fixed;
            display: flex;
            align-items: flex-end;
            top: 10px;
            right: 10px;
            height: 130px;
            z-index: 100;
            border: 4px solid transparent;
        }
        .micro-phone-icon {
            bottom: 110px;
            right: 262px;
        }
    }
    .camera-actions {
        position: fixed;
        display: flex;
        align-items: flex-end;
        bottom: 32px;
        right: 32px;
        z-index: 1;
        width: 100%;
        justify-content: flex-end;
        padding-right: 55px;
    }
    .enable-microphone {
        .camera-testing {
            overflow: hidden;
            box-sizing: border-box;
            border-radius: 12px;
            border: 4px solid var(--text-info);
            video {
                border-radius: 0 !important;
            }
        }
        .none-camera {
            border-radius: 0 !important;
        }
    }
    .in-visible-camera {
        &.enable-microphone {
            border-color: transparent;
        }
        .camera-testing,
        .none-camera,
        .not-support-camera,
        video {
            display: none;
        }
    }
`;

type CameraViewerProps = {
    setTurnOffCamera?: React.Dispatch<React.SetStateAction<boolean>>;
    setVideoStream?: React.Dispatch<React.SetStateAction<MediaStream | undefined>>;
    assessmentDetails?: TAssessmentTesting;
    allowTurnOffCamera?: boolean;
}

export const CameraViewer: React.FC<CameraViewerProps> = ({
    setTurnOffCamera,
    setVideoStream,
    assessmentDetails,
    allowTurnOffCamera = true,
}) => {
    const isMobile = useMobile();
    const [isCameraEnabled, setIsCameraEnabled] = React.useState<boolean>(true);
    const [isMicrophoneEnabled, setIsMicrophoneEnabled] = React.useState<boolean>(false);
    const [isNoneCamera, setIsNoneCamera] = React.useState<boolean>(false);
    const [inVisibleCamera, setInVisibleCamera] = React.useState<boolean>(isMobile);

    const onToggleCamera = React.useCallback(() => {
        if (isNoneCamera || !allowTurnOffCamera) return;
        setIsCameraEnabled(prev => !prev);
    }, [setIsCameraEnabled, isNoneCamera, allowTurnOffCamera]);
    const onToggleMicrophone = React.useCallback(() => {
        setIsMicrophoneEnabled(prev => !prev);
        navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((mediaStream) => {
            mediaStream.getAudioTracks()[0].enabled = !mediaStream.getAudioTracks()[0].enabled;
        });
    }, []);
    const onToggleInVisibleCamera = React.useCallback(() => {
        setInVisibleCamera(prev => !prev);
    }, []);

    React.useEffect(() => {
        if (isNoneCamera || isCameraEnabled === false) {
            setTurnOffCamera && setTurnOffCamera(true);
        }
    }, [isCameraEnabled, isNoneCamera, setTurnOffCamera]);

    return <Wrapper className={`flex justify-center items-end ${isNoneCamera ? 'none-camera' : ''}`}>
        <div className="grid grid-cols-1 gap-[32px] items-start w-full h-full">
            <div
                className={classNames([
                    "flex flex-col items-center w-full relative h-[216px] camera-wrapper",
                    {
                        'enable-microphone': isMicrophoneEnabled,
                        'in-visible-camera': inVisibleCamera,
                    }
                ])}
            >
                <HRCamera
                    assessmentDetails={assessmentDetails}
                    height={isMobile ? 130 : 216}
                    isCameraEnabled={isCameraEnabled}
                    setVideoStream={setVideoStream}
                    setIsNoneCamera={setIsNoneCamera}
                    classess={isMobile ? "camera-testing-mobile" : "camera-testing"}
                />
                {isMicrophoneEnabled && !inVisibleCamera && <span className="fixed z-[1] micro-phone-icon">
                    <ICMicroPhoneOnV2 />
                </span>}
            </div>
            <div className="flex items-center justify-center gap-[16px] camera-actions">
                <IconButton
                    disabled={!allowTurnOffCamera}
                    btnSize="sm"
                    btnType="sub_danger"
                    style={{
                        borderRadius: '144px',
                        height: '40px',
                        width: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: !isCameraEnabled ? '#F55858' : 'white',
                        border: '1px solid #ECECED',
                    }}
                    icon={<div>{isCameraEnabled ? (isNoneCamera ? <ICCameraError /> : <ICCamera />) : <ICCameraOff />}</div>}
                    onClick={onToggleCamera} />
                <IconButton
                    btnSize="sm"
                    btnType="sub_danger"
                    style={{
                        borderRadius: '144px',
                        height: '40px',
                        width: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: !isMicrophoneEnabled ? '#F55858' : 'white',
                        border: '1px solid #ECECED',
                    }}
                    icon={<div>{isMicrophoneEnabled ? <ICMicroPhoneV2 /> : <ICMicroPhoneOff fill="white" />}</div>}
                    onClick={onToggleMicrophone} />
                <IconButton
                    btnSize="sm"
                    btnType="sub"
                    style={{
                        borderRadius: '144px',
                        height: '40px',
                        width: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        border: '1px solid #ECECED',
                    }}
                    icon={<div>{inVisibleCamera ? <ICEyeTurnOff /> : <ICEyeTurnOn />}</div>}
                    onClick={onToggleInVisibleCamera} />
            </div>
        </div>
    </Wrapper>
}
