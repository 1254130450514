import Bowser from 'bowser';
import React from "react";
import { useDispatch } from "react-redux";
import { IInviteCandidate, IReportIssueCandidate, IStartSection, STEPS, TAssessmentTesting, TTesting } from "../type";
import { useNotification } from "../../../hooks/useNotification";
import { setLoading } from "../../../redux/slices/appInfo";
import { confirmCandidate, getAssessmentByURL, inviteWithoutLoginCandidateAPI } from "../../../api/candidate";
import { TAssessment } from "../../my_assessment/create-assessment/type";
import { checkStatusAssessment, getCandidateAssessmentByURL, getCurrentSection, reportIssueCandidate, startSection } from "../../../api/test-candidate";
import { TConfirmCandidate } from "../../my_assessment/type";
import { checkingConnectionGateway } from "../../../api/gateway";
import { ApiInstance } from "../../../api";
import { ERROR_CODE } from "../../../utils/constants";


export enum DeviceType {
    MOBILE = 'mobile',
    TABLET = 'tablet',
    DESKTOP = 'desktop',
};
export const DeviceInfoMapping = {
    [DeviceType.MOBILE]: 'Mobile',
    [DeviceType.TABLET]: 'Tablet',
    [DeviceType.DESKTOP]: 'Desktop',
};

export const useTesting = () => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useNotification();

    const device: string = React.useMemo(() => {
        const platform = Bowser.getParser(navigator.userAgent).getPlatform();
        if (!platform) return DeviceType.DESKTOP;
        return DeviceInfoMapping[platform.type as DeviceType] || DeviceType.DESKTOP;
    }, []);

    const inviteWithoutLoginCandidate = React.useCallback(async (assessmentId: string, values: IInviteCandidate) => {
        dispatch(setLoading(true));
        try {
            const result = await inviteWithoutLoginCandidateAPI(assessmentId, values);
            showSuccess(`Đã mời ${values.email} thành công`);
            return result;
        } catch (error: any) {
            console.error(error);
            const errorCode = error?.response?.data?.errorCode || '';
            if (errorCode === ERROR_CODE.MAX_INVITE_CANDIDATE_PER_HOUR) {
                showError("", "Bạn thực hiện thao tác quá nhanh. Vui lòng thử lại sau 1 giờ.");
            } else {
                showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
            }
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, showSuccess]);

    const loadAssessmentByURL = React.useCallback(async (url: string, setAssessmentDetails?: React.Dispatch<React.SetStateAction<TAssessment>>) => {
        dispatch(setLoading(true));
        try {
            const result = await getAssessmentByURL(url);
            if (setAssessmentDetails) {
                setAssessmentDetails((prev: any) => ({
                    ...prev,
                    ...result.data,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const loadCandidateAssessmentByURL = React.useCallback(async (url: string, 
            setAssessmentDetails: React.Dispatch<React.SetStateAction<TAssessmentTesting | any>>,
            setErrorCodeCandidate: React.Dispatch<React.SetStateAction<number | any>>) => {
        dispatch(setLoading(true));
        try {
            await checkStatusAssessment(url);
            const result = await getCandidateAssessmentByURL(url);
            if (setAssessmentDetails) {
                setAssessmentDetails((prev: any) => ({
                    ...prev,
                    ...result.data,
                }));
            }
        } catch (error: any) {
            console.error(error);
            const errorCode = error?.response?.data?.errorCode || '';
            setErrorCodeCandidate(errorCode);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch]);

    const reportIssueTestingCandidate = React.useCallback(async (request: IReportIssueCandidate) => {
        dispatch(setLoading(true));
        try {
            return await reportIssueCandidate(request);
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const startSectionTesting = React.useCallback(async (payload: IStartSection, 
        setSectionTesting: React.Dispatch<React.SetStateAction<TTesting | any>>,
        setErrorCodeCandidate: React.Dispatch<React.SetStateAction<number | any>>) => {
        dispatch(setLoading(true));
        try {
            const newPayload: IStartSection = {
                ...payload,
                device,
                lat: payload?.lat || 0,
                long: payload?.long || 0
            };
            const result = await startSection(newPayload);
            setSectionTesting(result.data || {});
        } catch (error: any) {
            console.error(error);
            const errorCode = error?.response?.data?.errorCode || '';
            if ((errorCode === ERROR_CODE.EXPIRED || errorCode === ERROR_CODE.LOCKED) && setErrorCodeCandidate) {
                setErrorCodeCandidate(errorCode);
            } else {
                showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
            }
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, device]);

    const getCurrentSectionTesting = React.useCallback(async (
        url: string, 
        handleFullScreen: any, 
        setSectionTesting: React.Dispatch<React.SetStateAction<TTesting | any>>, 
        setStep: React.Dispatch<React.SetStateAction<STEPS | any>>,
        setErrorCodeCandidate: React.Dispatch<React.SetStateAction<number | any>>
    ) => {
        dispatch(setLoading(true));
        try {
            const result = await getCurrentSection(url);
            const newData = (result.data || {}) as TTesting;
            setSectionTesting(newData);
            if (setStep) {
                if (!newData?.isStarted) {
                    setStep(STEPS.PREPARE);
                } else {
                    setStep(STEPS.TEST);
                }
            }
            if (handleFullScreen) {
                handleFullScreen.enter();
            }
        } catch (error: any) {
            console.error(error);
            const errorCode = error?.response?.data?.errorCode || '';
            if ((errorCode === ERROR_CODE.EXPIRED || errorCode === ERROR_CODE.LOCKED) && setErrorCodeCandidate) {
                setErrorCodeCandidate(errorCode);
            } else {
                showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
            }
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const confirmInviteCadidate = React.useCallback(async (
        testCandidateUrl: string, 
        values: TConfirmCandidate, 
        setAssessmentDetails: React.Dispatch<React.SetStateAction<TAssessmentTesting | any>>,
        setHasErrorAssessment: React.Dispatch<React.SetStateAction<boolean | any>>,
    ) => {
        dispatch(setLoading(true));
        try {
            const lastName = (values.lastName || '').trim();
            const firstName = (values.firstName || '').trim();
            const newValues = { lastName, firstName };
            setAssessmentDetails((prev: any) => ({
                ...prev,
                candidate: {...(prev.candidate || {}), ...newValues},
            }));
            return await confirmCandidate(testCandidateUrl, values);
        } catch (error: any) {
            console.error(error);
            setHasErrorAssessment(true);
            // showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch]);

    const checkingConnection = React.useCallback(async (
            testCandidateUrl: string, 
            setToken: React.Dispatch<React.SetStateAction<TTesting | any>>,
            setStep: React.Dispatch<React.SetStateAction<STEPS | any>>,
        ) => {
        dispatch(setLoading(true));
        try {
            const result = await checkingConnectionGateway(testCandidateUrl);
            const testCandidateToken = result.data?.testCandidateToken || '';
            setToken(testCandidateToken);
            if (testCandidateToken) {
                // Add bearer token to socket
                ApiInstance.setToken(testCandidateToken);
                setStep(STEPS.ABILITIES_CHECK);
            } else {
                setStep(STEPS.CONFIRM_USER_INVITED);
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    return {
        inviteWithoutLoginCandidate,
        loadAssessmentByURL,
        loadCandidateAssessmentByURL,
        reportIssueTestingCandidate,
        startSectionTesting,
        getCurrentSectionTesting,
        confirmInviteCadidate,
        checkingConnection,
    }
}
