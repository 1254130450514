import { OTP } from "../../components/otp"
import React from "react"
import { Register } from "./register"
import { UserInfomation } from "./information"
import { useDispatch } from "react-redux"
import { setLoading } from "../../redux/slices/appInfo"
import { isExistedEmailApi, registerApi, registerVerifyApi } from "../../api/auth"
import { setUserInfo } from "../../redux/actions"
import { updateUserInfo } from "../../api/user"
import { EnterPassword } from "./password"
import { notification } from "antd"
import { useNavigate } from "react-router-dom";
import i18next from "../../i18n"


export const RegisterPage: React.FC = () => {
    const dispatch = useDispatch();
    const [registerStep, setRegisterStep] = React.useState<number>(1);
    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [emailError, setEmailError] = React.useState<string>("");
    const [otpErrorMessage, setOtpErrorMessage] = React.useState<string>("");
    const navigate = useNavigate();

    const onResendOTP = async () => {
        dispatch(setLoading(true));
        try {
            await registerApi(email, password);
        }catch(e){
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    }
    const onBack = () => {
        setRegisterStep(prev => prev - 1)
    }
    const onVerify = async (otp: string) => {
        dispatch(setLoading(true));
        try {
            const result = await registerVerifyApi(email, otp);
            if (result.data.accessToken) {
                dispatch(setUserInfo({
                    token: result.data.accessToken,
                }));
            }
            setRegisterStep(4)
        } catch (e: any) {
            const status = e.response.status;
            const message = e.response.data.errorMessage;
            if (status === 400) { // Invalid OTP
                if (message === "Otp has been expired") setOtpErrorMessage(i18next.t('register.otpHasExpired') ?? '');
                else setOtpErrorMessage(i18next.t('register.incorrectOTP') ?? '');
            }
        }
        finally {
            dispatch(setLoading(false));
        }
    }
    const onRegister = async (password: string) => {
        dispatch(setLoading(true));
        try {
            await registerApi(email, password);
            setRegisterStep(3)
        } catch (e: any) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    }

    const onUpdateUserInfo = async (
        name: string,
        dateOfBirth: number,
        phoneNumber: string,
    ) => {
        dispatch(setLoading(true));
        try {
            await updateUserInfo({
                name,
                dateOfBirth,
                phoneNumber,
            });
            notification.success({
                message: i18next.t('register.success'),
                description: i18next.t('register.registerSuccessful'),
                placement: "topRight"
            });
            navigate("/");
        } catch (e) {
            console.error(e)
        } finally {
            dispatch(setLoading(false));
        }
    }
    const onEnterEmail = async (email: string) => {
        setEmailError("");
        setEmail(email);
        dispatch(setLoading(true));
        try {
            const isExisted = await isExistedEmailApi(email);
            if (isExisted) {
                setEmailError(i18next.t('register.emailExisted') ?? '');
            } else {
                setRegisterStep(2)
            }
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    }
    const onEnterPassword = (password: string) => {
        setPassword(password);
        onRegister(password)
    }
    return (
        <div className="flex w-screen h-screen items-center justify-center overflow-x-hidden rounded-[12px] bg-surface-low ">
            <div className="shadow-e-03 bg-white rounded-[12px] overflow-hidden box-border">
                {registerStep === 1 && <Register
                    errorMessage={
                        emailError
                    }
                    onSubmit={onEnterEmail}
                />}
                {registerStep === 2 && <EnterPassword
                    onSubmit={onEnterPassword}
                />}
                {registerStep === 3 && <OTP
                    errorMessage={otpErrorMessage}
                    onVerify={onVerify}
                    onResend={onResendOTP}
                    onBack={onBack}
                />}
                {registerStep === 4 && <UserInfomation
                    onSubmit={onUpdateUserInfo}
                />}
            </div>
        </div>
    )
}
