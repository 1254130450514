import React from "react";
import { useDispatch } from "react-redux";
import { useNotification } from "../../../hooks/useNotification";
import { setLoading } from "../../../redux/slices/appInfo";
import { TCompanyProfile, TCompanyProfileEntity, TCompanyProfileRequest, getCompanyProfile, updateCompanyProfile } from "../../../api/company-profile";
import { useWatch } from "antd/lib/form/Form";
import { FormInstance } from "antd";
import { compareObjects } from "../../../utils";
import { ERROR_CODE } from "../../../utils/constants";

export const useCompanyProfile = (form: FormInstance, initValuesForm: any, fontColor: string, backgroundColor: string, logo: string | null) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useNotification();

    const name = useWatch('name', form);
    const address = useWatch('address', form);
    const country = useWatch('country', form);
    const email = useWatch('email', form);
    const taxCode = useWatch('taxCode', form);

    const hasChangedValues = React.useMemo(() => {
        const values = {
            name,
            address,
            country,
            email,
            taxCode,
            logo,
            color: {
                fontColor,
                backgroundColor,
            }
        };
        return !compareObjects(values, initValuesForm);
    }, [
        name,
        address,
        country,
        email,
        taxCode,
        initValuesForm,
        fontColor,
        backgroundColor,
        logo,
    ]);

    const loadCompanyProfile = React.useCallback(async (setCompanyProfile: React.Dispatch<React.SetStateAction<TCompanyProfile>>) => {
        dispatch(setLoading(true));
        try {
            const result = await getCompanyProfile();
            if (setCompanyProfile && result?.data) {
                setCompanyProfile((prev: TCompanyProfile) => ({
                    ...prev,
                    ...result.data,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const onSaveCompanyProfile = React.useCallback(async (request: TCompanyProfileRequest, setCompanyProfileDataAPI: React.Dispatch<React.SetStateAction<TCompanyProfileEntity>>) => {
        try {
            dispatch(setLoading(true));
            const result = await updateCompanyProfile(request);
            const companyProfile = result?.data;
            setCompanyProfileDataAPI(prev => ({
                address: companyProfile?.address,
                country: companyProfile?.country,
                email: companyProfile?.email,
                logo: companyProfile?.logo,
                name: companyProfile?.name,
                taxCode: companyProfile?.taxCode,
                color: {
                    backgroundColor: companyProfile?.color?.backgroundColor,
                    fontColor: companyProfile?.color?.fontColor,
                }
            }));
            dispatch(setLoading(false));
            showSuccess('Đã lưu', 'Cập nhật thông tin công ty thành công');
        } catch (error: any) {
            dispatch(setLoading(false));
            const errorCode = error?.response?.data?.errorCode || '';
            if(errorCode === ERROR_CODE.TAX_CODE_INVALID) {
                showError("", "Mã số thuế không hợp lệ");
            } else {
                const errorMessage = error?.response?.data?.errorMessage || error?.response?.statusText;
                errorMessage && showError("", errorMessage);
            }
        }
    }, [dispatch, showSuccess, showError]);

    return {
        loadCompanyProfile,
        onSaveCompanyProfile,
        hasChangedValues,
    }
}
