import { IconProps } from "./type"

export const ICMultiChoice: React.FC<IconProps> = ({
    width = 49,
    height = 49,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Multiple Choice" clipPath="url(#clip0_10979_85813)">
                <g id="Group">
                    <g id="Group_2">
                        <g id="Group_3">
                            <path id="Vector" d="M48.5792 17.4496L43.5551 26.6784C43.2898 27.1678 42.7601 27.4528 42.2051 27.4078C41.6501 27.3618 41.1748 26.9934 40.992 26.4675L40.2176 24.24C39.177 24.9037 37.8711 25.8815 36.5923 27.2353C34.5476 29.4009 30.8069 34.5234 29.4747 39.0525C29.062 40.5883 26.6736 40.2317 26.7194 38.6512L26.7663 24.4959C26.7672 24.2015 26.86 23.9156 27.0325 23.6775C27.9222 22.4446 28.9047 21.2784 29.951 20.2115C32.5638 17.5453 35.1851 15.87 37.1961 14.85L36.3692 12.2371C36.1976 11.6953 36.3692 11.1037 36.8032 10.7371C37.2373 10.3715 37.8486 10.3021 38.3539 10.5628L47.9886 15.5278C48.3252 15.7012 48.5774 16.0021 48.689 16.364C48.7995 16.7259 48.7601 17.1178 48.5792 17.4496Z" fill="#6680FF" />
                        </g>
                    </g>
                    <g id="Group_4">
                        <path id="Vector_2" d="M24.7502 0.5L28.575 20.3933L24.7502 40.2866L19.922 33.4784V11H17.6251C17.0663 11 16.5601 10.6691 16.336 10.1563C16.1129 9.64438 16.2132 9.04813 16.5938 8.6375L23.7189 0.95C23.9852 0.663125 24.3583 0.5 24.7502 0.5Z" fill="#FF4D4D" />
                    </g>
                    <path id="Vector_3" d="M33.1642 10.1563C32.9401 10.6691 32.4338 11 31.8751 11H29.5782L29.5482 47.0516L24.75 40.2866V0.5C25.1419 0.5 25.515 0.663125 25.7813 0.95L32.9063 8.6375C33.287 9.04813 33.3873 9.64438 33.1642 10.1563Z" fill="#4F4B5C" />
                    <path id="Vector_4" d="M24.75 27.3281L27.3061 43.9129L24.75 48.4996H21.3282C20.5519 48.4996 19.9219 47.8696 19.9219 47.0934C19.9219 46.1587 19.8835 45.3056 19.8028 44.4853C19.47 41.0587 18.7463 37.8646 17.6522 34.9912C16.4981 31.9556 14.9016 29.3465 12.9074 27.2353C11.6287 25.8815 10.3237 24.9037 9.28306 24.24L8.50868 26.4675C8.32587 26.9934 7.85056 27.3618 7.29556 27.4078C6.74149 27.4528 6.21087 27.1678 5.94556 26.6784L0.921307 17.4496C0.740369 17.1178 0.700994 16.7259 0.811619 16.364C0.923182 16.0021 1.17537 15.7012 1.51193 15.5278L11.1467 10.5628C11.652 10.3021 12.2633 10.3715 12.6973 10.7371C13.1314 11.1037 13.303 11.6953 13.1314 12.2371L12.3045 14.85C14.3155 15.87 16.9367 17.5453 19.5496 20.2115C20.5959 21.2784 21.5784 22.4446 22.4681 23.6775C23.2978 24.824 24.06 26.0437 24.75 27.3281Z" fill="#FFC34D" />
                    <path id="Vector_5" d="M29.5481 47.0512C29.5603 47.4309 29.4169 47.7994 29.1525 48.0722C28.8872 48.3459 28.5234 48.4997 28.1428 48.4997H24.75V27.3281C25.4156 28.5647 26.0156 29.8603 26.5434 31.2075C28.3594 35.8434 29.3701 41.1731 29.5481 47.0512Z" fill="#FF7733" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_10979_85813">
                    <rect width="48" height="48" fill="white" transform="translate(0.75 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}
