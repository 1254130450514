import React from 'react';
import { useApproveRejectTesting } from './hooks/usePreviewTesting';
import { TTesting } from '../testing/type';
import { SearchParamsStateType, useFilter } from '../../hooks/useFilter';
import { TApproveTesting } from '../my_assessment/type';
import Typography from 'antd/lib/typography';

type TApproveRejectTesting = {
    id: string;
    token: string;
    isApproved: string;
    testDetails: TTesting | undefined;
    setTestDetails: (testDetails: TTesting | undefined) => void;
}
export const ApproveRejectTestingContext = React.createContext<TApproveRejectTesting>({} as TApproveRejectTesting);
export const useApproveRejectTestingContext = () => React.useContext(ApproveRejectTestingContext);

export const ApproveRejectTestingPage: React.FC = () => {
    const filtersDefaults: SearchParamsStateType = {
        id: { type: 'string', default: null },
        token: { type: 'string', default: null },
        isApproved: { type: 'boolean', default: false },
    }
    const [params, setParams] = useFilter(filtersDefaults);
    const { onAcceptedTesting } = useApproveRejectTesting();
    const [testDetails, setTestDetails] = React.useState<TTesting | undefined>(undefined);
    const [linkExpired, setLinkExpired] = React.useState<boolean | undefined>(undefined);

    React.useEffect(() => {
        if (params.id && params.token) {
            const payloadRequest: TApproveTesting = {...params};
            onAcceptedTesting(payloadRequest, setTestDetails, setLinkExpired);
        }
    }, []);

    return <ApproveRejectTestingContext.Provider
        value={{
            id: params.id,
            token: params.token,
            isApproved: params.isApproved,
            testDetails,
            setTestDetails
        }}
    >
        <div className="lg-container mx-auto rounded-[12px] flex justify-center items-center">
            <div className="flex items-center justify-center bg-white rounded-[12px] max-w-[466px] p-[32px] shadow-e-03 box-border overflow-hidden">
                {linkExpired && <div className="flex flex-col gap-[24px]">
                    <h2 className="text-heading-5-bold text-high-em mb-0">Liên kết đã hết hạn</h2>
                    <p className="text-standard text-high-em mb-0">
                        Xin lỗi vì sự bất tiện này, nhưng link bạn nhấn vào đã hết hạn và không còn sử dụng được. Nếu bạn gặp bất kỳ vấn đề gì, xin vui lòng liên hệ với đội ngũ hỗ trợ của HUSA Support Team.
                    </p>
                    <p className="text-standard text-high-em mb-0">
                        Chi tiết xin vui lòng liên hệ: <a href="mailto:contact@husa.io" className="text-standard text-primary-bold mb-0">contact@husa.io</a>
                    </p>
                </div>}

                {testDetails && <div className="grid grid-cols-1 gap-[24px] items-start justify-start w-full">
                    <div className="text-left">
                        <Typography.Title level={3} style={{
                            color: '#110C22',
                            fontWeight: 700,
                            marginBottom: 0,
                            fontSize: '28px',
                            lineHeight: '40px'
                        }}>Hoàn tất phê duyệt</Typography.Title>
                    </div>
                    <div className="grid grid-cols-1 gap-[4px] text-left">
                        {params.isApproved && <span className="text-body text-high-em">
                            Chúng tôi đã gửi thông báo cho phép ứng viên làm bài đánh giá đến email của họ.
                        </span>}
                        {!params.isApproved && <span className="text-body text-high-em">
                            Chúng tôi đã gửi thông báo từ chối ứng viên làm bài đánh giá đến email của họ.
                        </span>}
                    </div>
                </div>}
            </div>
        </div>
    </ApproveRejectTestingContext.Provider>
}
