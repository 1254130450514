import { IconProps } from "./type"

export const ICSave: React.FC<IconProps> = ({
    fill = 'white'
}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.60004 1.40002H4.45753C2.76911 1.40002 1.40039 2.76874 1.40039 4.45717V19.5429C1.40039 21.2313 2.76911 22.6 4.45753 22.6H19.5432C21.2317 22.6 22.6004 21.2313 22.6004 19.5429V8.0521C22.6004 7.2413 22.2783 6.46371 21.705 5.89039L18.11 2.29545M6.60004 1.40002H15.9483C16.7591 1.40003 17.5367 1.72212 18.11 2.29545M5.60039 3.40002H4.45753C3.87368 3.40002 3.40039 3.87331 3.40039 4.45717V19.5429C3.40039 20.1267 3.87367 20.6 4.45753 20.6H5.62039C5.60728 20.5354 5.60039 20.4685 5.60039 20.4V15C5.60039 13.785 6.58536 12.8 7.80039 12.8H16.2004C17.4154 12.8 18.4004 13.785 18.4004 15V20.6H19.5432C20.1271 20.6 20.6004 20.1267 20.6004 19.5429V8.05211C20.6004 7.77174 20.489 7.50285 20.2908 7.3046L18.4004 5.41424V6.00002C18.4004 7.21505 17.4154 8.20002 16.2004 8.20002L7.80039 8.20002C6.58536 8.20002 5.60039 7.21505 5.60039 6.00002V3.40002ZM16.405 3.50375C16.4019 3.53543 16.4004 3.56755 16.4004 3.60002V6.00002C16.4004 6.11048 16.3108 6.20002 16.2004 6.20002L7.80039 6.20002C7.68993 6.20002 7.60039 6.11048 7.60039 6.00002V3.40002H15.9483C16.1079 3.40003 16.2637 3.43611 16.405 3.50375ZM16.4004 20.6V15C16.4004 14.8896 16.3108 14.8 16.2004 14.8H7.80039C7.68993 14.8 7.60039 14.8896 7.60039 15V20.4C7.60039 20.4685 7.5935 20.5354 7.58039 20.6H16.4004Z" fill={fill}/>
        </svg>
    )
}
