import { IconProps } from "./type"

export const ICUpload: React.FC = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M15.9144 14.5166C16.3049 14.9071 16.9381 14.9071 17.3286 14.5166L19.562 12.2832C20.5807 11.2645 21.1707 9.87405 21.16 8.4068C21.1493 6.95655 20.5783 5.55838 19.5098 4.52002C18.4751 3.4882 17.0828 2.91893 15.6344 2.90828C14.1829 2.89762 12.7877 3.44918 11.7579 4.47899L9.52442 6.71245C9.1339 7.10297 9.1339 7.73614 9.52442 8.12666C9.91494 8.51718 10.5481 8.51718 10.9386 8.12666L13.1721 5.89322C13.8106 5.25471 14.6856 4.90136 15.6197 4.90823C16.5544 4.9151 17.4436 5.28221 18.1012 5.93982L18.1124 5.95082C18.7878 6.60539 19.1532 7.48864 19.16 8.4215C19.1668 9.33987 18.7974 10.2194 18.1478 10.869L15.9144 13.1024C15.5239 13.4929 15.5239 14.1261 15.9144 14.5166ZM13.0616 15.9007L10.8281 18.1341C10.8281 18.1341 10.8281 18.1341 10.8281 18.1341C10.1873 18.7749 9.33832 19.1261 8.38045 19.1191C7.44774 19.1122 6.56427 18.7469 5.90981 18.0715C5.90252 18.064 5.89512 18.0566 5.8876 18.0493C5.21219 17.3947 4.84684 16.5115 4.83998 15.5786C4.83323 14.6602 5.20259 13.7807 5.85216 13.1312L8.08562 10.8977C8.47614 10.5072 8.47614 9.87402 8.08562 9.48349C7.69509 9.09297 7.06193 9.09297 6.6714 9.48349L4.43795 11.7169C3.41925 12.7356 2.82925 14.1261 2.84003 15.5933C2.85067 17.041 3.41972 18.4368 4.48448 19.4746C5.52227 20.5394 6.91832 21.1084 8.36576 21.119C9.84753 21.1299 11.2147 20.5759 12.2423 19.5483L14.4758 17.3149C14.8663 16.9243 14.8663 16.2912 14.4758 15.9007C14.0853 15.5101 13.4521 15.5101 13.0616 15.9007ZM7.906 16.0343C8.29652 16.4249 8.92969 16.4249 9.32021 16.0343L16.0206 9.33396C16.4111 8.94344 16.4111 8.31028 16.0206 7.91975C15.63 7.52923 14.9969 7.52923 14.6064 7.91975L7.906 14.6201C7.51547 15.0106 7.51547 15.6438 7.906 16.0343Z" fill="#4F4B5C" />
    </svg>
}

export const ICUploadV2: React.FC<IconProps> = ({
    fill = "white",
}) => {
    return <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon / upload-01">
            <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M8.9123 2.77539C6.72965 2.77539 4.96026 4.54478 4.96026 6.72743C4.96026 6.89538 4.97069 7.06055 4.99087 7.22238C5.01749 7.43581 4.95119 7.6504 4.80882 7.81162C4.73214 7.89846 4.63737 7.96531 4.53263 8.00852C4.40791 8.06788 4.26789 8.09227 4.12785 8.07679C4.05866 8.06914 3.98812 8.06519 3.91638 8.06519C2.86986 8.06519 2.02148 8.91356 2.02148 9.96008C2.02148 10.9356 2.79509 11.6671 3.91638 11.6671C4.3306 11.6671 4.66638 12.0028 4.66638 12.4171C4.66638 12.8313 4.3306 13.1671 3.91638 13.1671C2.11621 13.1671 0.521484 11.906 0.521484 9.96008C0.521484 8.23927 1.80181 6.81749 3.46183 6.59536C3.53196 3.6453 5.94537 1.27539 8.9123 1.27539C10.7743 1.27539 12.4174 2.20907 13.4002 3.63105C13.4713 3.62797 13.5426 3.62641 13.6143 3.62641C16.3008 3.62641 18.4786 5.80423 18.4786 8.4907C18.4786 10.7352 16.9683 12.4308 14.9704 12.9803C14.571 13.0901 14.1582 12.8554 14.0483 12.456C13.9385 12.0566 14.1732 11.6438 14.5726 11.534C15.9925 11.1434 16.9786 9.98713 16.9786 8.4907C16.9786 6.63265 15.4724 5.12641 13.6143 5.12641C13.4458 5.12641 13.2807 5.13874 13.1196 5.16244C12.8127 5.20758 12.5096 5.05912 12.3572 4.78894C11.6782 3.58535 10.3894 2.77539 8.9123 2.77539ZM9.37002 8.47539C9.57291 8.47534 9.76716 8.55749 9.90845 8.7031L12.6097 11.4869C12.8982 11.7841 12.891 12.259 12.5938 12.5474C12.2965 12.8359 11.8217 12.8287 11.5332 12.5315L10.1202 11.0753V15.9754C10.1202 16.3896 9.78441 16.7254 9.3702 16.7254C8.95599 16.7254 8.6202 16.3896 8.6202 15.9754V11.0765L7.20998 12.5312C6.92166 12.8286 6.44685 12.836 6.14945 12.5477C5.85204 12.2594 5.84468 11.7845 6.13299 11.4871L8.83171 8.70335C8.97293 8.55768 9.16714 8.47544 9.37002 8.47539Z" fill={fill} />
        </g>
    </svg>
}
