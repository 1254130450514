import React from "react"
import { Avatar } from 'antd';

type CommentProps = {

}

export const Comment: React.FC<CommentProps> = () => {
    return <div className="grid grid-cols-[auto_1fr] gap-[30px]">
        <Avatar src={'/images/people.png'}
            style={{
                width: '87px',
                height: '60px'
            }}
         />
        <div className="grid grid-cols-[1fr] grid-rows-[auto_1fr] gap-[8px]">
            <span className="grid grid-cols-[auto_1fr] gap-[8px]">
                <span className="text-[16px] font-bold leading-[19px] text-[#46485C]">Maria</span>
                <span className="font-medium text-[12px] text-[#A6A8B4]">19/07/2022</span>
            </span>
            <span className="p-[16px] rounded-[12px] bg-[#EEF0F6]">
                <span className="font-normal text-[#46485C] text-[14px] leading-[17px] italic">
                    “Ask CDCR San Quintin State Prison 2008. We installed Purex dispensers throughout the prison to combat diseases…and it was a Roaring Success (as in Roaring Drunk) I mean we had Long lines of prisoners fist fighting to use them.”
                </span>
            </span>
        </div>
    </div>

}
