import React from "react";
import { Form } from "antd";
import { HRInput } from "../../../components/form/input";
import { HRSelect } from "../../../components/form/select";
import { HRTextArea } from "../../../components/form/textarea";
import { HRForm } from "../../../components/form/form";
import { HRTags } from "../../../components/tags/tags";
import { useCreateTestPageContext } from "..";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import _ from "lodash";
import i18next from "../../../i18n";
import { useMobile } from "../../../hooks/useDimensions";

export const HomeTab: React.FC = () => {
    const { form, tags, setTags, ratingLevel, setScoreLevelsQuestion } = useCreateTestPageContext();
    const commonData = useSelector((state: RootState) => state.commonData);
    const isMobile = useMobile();
    const onSelectAllIndustries = React.useCallback((isCheck: any) => {
        const industries = _.uniqBy(commonData?.['test_field'] || [], '_id');
        if (isCheck) {
            form.setFieldsValue({
                industry: industries?.map((item) => item?._id),
            });
        } else {
            form.setFieldsValue({
                industry: [],
            });
        }
    }, [form, commonData]);

    const onSelectAllRoles = React.useCallback((isCheck: any) => {
        const position =  _.uniqBy(commonData?.['position'] || [], '_id');
        if (isCheck) {
            form.setFieldsValue({
                roles: position?.map((item) => item?._id),
            });
        } else {
            form.setFieldsValue({
                roles: [],
            });
        }
    }, [form, commonData]);

    const childForm = (
        <div className="grid gap-[20px] grid-cols-[1fr]">
            <Form.Item
                name="title"
                rules={[
                    {
                        required: true,
                        message: i18next.t('createTest.enterTitle') ?? '',
                    },
                    // {
                    //     pattern: new RegExp(`^${REGEX_UNICODE}+$`),
                    //     message: "Ký tự không hợp lệ",
                    // },
                ]}
            >
                <HRInput label={i18next.t('createTest.title') ?? ''} isRequired={true} maxLength={100} />
            </Form.Item>
            <div className="grid grit-cols-1 lg:grid-cols-[1fr_1fr] gap-4 lg:gap-8">
                <div className="grid grid-rows-[auto_1fr] gap-[21px]">
                    <Form.Item
                        name="summary"
                    >
                        <HRInput label={i18next.t('createTest.summary') ?? ''} maxLength={5000} />
                    </Form.Item>
                    <Form.Item name="description">
                        <HRTextArea
                            label={i18next.t('createTest.description') ?? ''}
                            maxLength={5000}
                            style={{
                                height: isMobile ? "250px" : "566px",
                            }}
                        />
                    </Form.Item>
                </div>

                <div className="grid grid-cols-[1fr] gap-[24px]">
                    <Form.Item name="language"
                        rules={[
                            {
                                required: true,
                                message: i18next.t('createTest.selectLanguage') ?? '',
                            },
                        ]}>
                        <HRSelect
                            label={i18next.t('createTest.language') ?? ''}
                            name="language"
                            placeholder={i18next.t('createTest.select') ?? ''}
                            isRequired={true}
                        />
                    </Form.Item>
                    <Form.Item
                        name="industry"
                        rules={[
                            {
                                required: true,
                                message: i18next.t('createTest.enterRequireInfo') ?? '',
                            },
                        ]}
                    >
                        <HRSelect
                            mode="multiple"
                            name="test_field"
                            placeholder={i18next.t('createTest.select') ?? ''}
                            label={i18next.t('createTest.industry') ?? ''}
                            isRequired={true}
                            hasAllOption={true}
                            onSelectedAll={onSelectAllIndustries}
                        />
                    </Form.Item>
                    <Form.Item
                        name="roles"
                        rules={[
                            {
                                required: true,
                                message: i18next.t('createTest.enterRequireInfo') ?? '',
                            },
                        ]}
                    >
                        <HRSelect
                            label={i18next.t('createTest.position') ?? ''}
                            name="position"
                            mode="multiple"
                            placeholder={i18next.t('createTest.select') ?? ''}
                            isRequired={true}
                            hasAllOption={true}
                            onSelectedAll={onSelectAllRoles}
                        />
                    </Form.Item>
                    <Form.Item
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: i18next.t('createTest.enterRequireInfo') ?? '',
                            },
                        ]}
                    >
                        <HRSelect
                            label={i18next.t('createTest.type') ?? ''}
                            name="test_type"
                            placeholder={i18next.t('createTest.select') ?? ''}
                            isRequired={true}
                        />
                    </Form.Item>
                    <Form.Item
                        name="ratingLevel"
                        rules={[
                            {
                                required: true,
                                message: i18next.t('createTest.enterRequireInfo') ?? '',
                            },
                        ]}
                    >
                        <HRSelect
                            label={i18next.t('createTest.ratingLevel') ?? ''}
                            options={ratingLevel}
                            placeholder={i18next.t('createTest.select') ?? ''}
                            isRequired={true}
                            hasFilterSort={false}
                            onChange={(value) => {
                                const ratingLevelSelected = ratingLevel?.find((item) => item?.id === value);
                                setScoreLevelsQuestion(ratingLevelSelected?.scoreLevels || []);
                            }}
                        />
                    </Form.Item>
                    <div className="grid grid-cols-[1fr] gap-[8px] text-n-800 text-left">
                        <label className="text-med-em text-body-bold">{i18next.t('createTest.skillRequired') ?? ''}</label>
                        <span className="grid gap-[16px]">
                            <HRTags tags={tags} setTags={setTags} />
                        </span>
                    </div>
                    <Form.Item name="relevant"
                        // rules={[
                        //     {
                        //         pattern: new RegExp(`^${REGEX_UNICODE}+$`),
                        //         message: "Ký tự không hợp lệ",
                        //     },
                        // ]}
                    >
                        <HRTextArea
                            label={i18next.t('createTest.relevant') ?? ''}
                            maxLength={255}
                            style={{
                                height: "105px",
                            }}
                        />
                    </Form.Item>
                </div>
            </div>
        </div>
    );

    return (
        <div className="bg-white p-2 lg:p-8 rounded-b-[10px]">
            <HRForm form={form} name="createTest" childNode={childForm} />
        </div>
    );
};
