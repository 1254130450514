import { Radio, RadioChangeEvent, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTesting } from '../../hooks/useTesting';
import { IReportIssueCandidate, STEPS } from '../../type';
import { HRTextArea } from '../../../../components/form/textarea';
import { HRButton } from '../../../../components/button';
import { ICNext } from '../../../../icons';
import { useTestingPageContext } from '../..';
import { getNameCommonData } from '../../../../redux/selectors/commonData';
import i18next from '../../../../i18n';

const RadioWrapper = styled.div`
    .ant-radio-wrapper {
        border: 1px solid #ECECED;
        border-radius: 8px;
        padding: 16px;
        margin-right: 16px;
        box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
        &.ant-radio-wrapper-checked {
            background-color: #7357FF;
            color: white;
            .ant-radio-checked .ant-radio-inner:after {
                background-color: white;
            }
            .ant-radio-checked .ant-radio-inner {
                background-color: #7357FF !important;
                border: 2px solid white!important;
            }
        }
        span.ant-radio+* {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }
        .ant-radio {
            font-size: 14px;
            font-weight: 500;
            color: #46485C;
            .ant-radio-inner {
                width: 20px;
                height: 20px;
                border: 2px solid #4F4B5C !important;
                &:after {
                    width: 20px;
                    height: 20px;
                    margin-top: -10px;
                    margin-left: -10px;
                }
            }
        }
    }
`;
const InputWrapper = styled.div`
    label {
        color: #46485C;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
`;

enum TRadioValueProps {
    YES = 'YES',
    NO = 'NO'
}

export const AbilitiesCheckAssessment: React.FC = () => {
    const { reportIssueTestingCandidate } = useTesting();
    const { companyProfile, url, assessmentDetails, setStep } = useTestingPageContext();
    const [reason, setReason] = React.useState<string>('');
    const [hasHealthIssue, setHasHealthIssue] = React.useState<TRadioValueProps>();
    const [hasLanguageIssue, setHasLanguageIssue] = React.useState<TRadioValueProps>(TRadioValueProps.NO);
    const languageName = getNameCommonData('language', assessmentDetails?.assessment?.language)

    const activeNextButton = React.useMemo(() => {
        if (hasHealthIssue === TRadioValueProps.NO && hasLanguageIssue === TRadioValueProps.NO) return true;
        if (hasHealthIssue === TRadioValueProps.YES || hasLanguageIssue === TRadioValueProps.YES) {
            if (reason) return true;
        }
        return false;
    }, [hasHealthIssue, hasLanguageIssue, reason]);

    const hasNeedValue = React.useMemo(() => {
        if (hasHealthIssue === TRadioValueProps.YES || hasLanguageIssue === TRadioValueProps.YES) return true;
        return false;
    }, [hasHealthIssue, hasLanguageIssue]);

    const doReportIssue = React.useCallback(() => {
        const payload: IReportIssueCandidate = {
            id: assessmentDetails._id || '',
            url: url,
            issueDetail: reason || '',
            hasHealthIssue: hasHealthIssue === TRadioValueProps.YES ? true : false,
            hasLanguageIssue: hasLanguageIssue === TRadioValueProps.YES ? true : false
        };
        reportIssueTestingCandidate(payload).then((res) => {
            if (!hasNeedValue) {
                setStep(STEPS.CAMERA_CHECK);
            } else {
                setStep(STEPS.ABILITIES_CHECK_CONFIRMATION);
            }
        });
    }, [assessmentDetails, setStep, url, hasNeedValue, reason, hasHealthIssue, hasLanguageIssue, reportIssueTestingCandidate]);
    
    return <div className="flex bg-white lg:max-w-[562px] rounded-[12px] px-4 lg:px-8 py-[54px] shadow-e-03">
        <div className="grid grid-cols-1 gap-[32px] items-start justify-start">
            <div className="text-center">
                <Typography.Title level={3} style={{
                    color: '#110C22',
                    fontWeight: 700,
                    marginBottom: 0,
                    fontSize: '28px',
                    lineHeight: '40px'
                }}>{i18next.t('testing.checkHealthStatus')}</Typography.Title>

            </div>
            <div className="grid grid-cols-1 gap-[8px]">
                <span className="text-high-em text-body">
                    {i18next.t('testing.toEnsureObjectivity')}
                </span>
            </div>
            <div className="grid grid-cols-1 gap-[32px]">
                <RadioWrapper className="grid grid-cols-1 gap-[8px]">
                    <label className="text-body text-high-em">{i18next.t('testing.doYouHaveAnyHealthIssue')}</label>
                    <Radio.Group value={hasHealthIssue} onChange={(e: RadioChangeEvent) => setHasHealthIssue(e.target.value)}>
                        <Radio value={TRadioValueProps.YES}>{i18next.t('testing.yes')}</Radio>
                        <Radio value={TRadioValueProps.NO}>{i18next.t('testing.no')}</Radio>
                    </Radio.Group>
                </RadioWrapper>
                {/* <RadioWrapper className="grid grid-cols-1 gap-[8px]">
                    <label className="text-body text-high-em">Bài đánh giá sau sẽ được thực hiện bằng ngôn ngữ <strong>{languageName}</strong>, bạn có gặp bất kỳ khó khăn hay trở ngại nào đối với loại ngôn ngữ này hay không?</label>
                    <Radio.Group value={hasLanguageIssue} onChange={(e: RadioChangeEvent) => setHasLanguageIssue(e.target.value)}>
                        <Radio value={TRadioValueProps.YES}>Có</Radio>
                        <Radio value={TRadioValueProps.NO}>Không</Radio>
                    </Radio.Group>
                </RadioWrapper> */}
                {hasNeedValue && <InputWrapper>
                    <HRTextArea
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        label={i18next.t('testing.plsProvideDetailInfomation') ?? ''}
                        maxLength={500}
                        style={{
                            height: "141px",
                        }}
                    />
                </InputWrapper>}
            </div>
            <div>
                {!activeNextButton && <HRButton 
                    btnType="primary"
                    disabled={true}
                >
                    <div className="flex space-x-2 items-center justify-center">
                        <span>{i18next.t('testing.continue')}</span>
                        <ICNext fill="#B2B4BF" />
                    </div>
                </HRButton>}
                {activeNextButton && <HRButton 
                    onClick={doReportIssue}
                    btnType="primary"
                    style={{
                        backgroundColor: companyProfile?.color?.backgroundColor,
                        borderColor: companyProfile?.color?.backgroundColor,
                        color: companyProfile?.color?.fontColor || "#fff",
                    }}
                >
                    <div className="flex space-x-2 items-center justify-center">
                        <span>{i18next.t('testing.continue')}</span>
                        <ICNext fill={(companyProfile?.color?.fontColor || "#fff")} />
                    </div>
                </HRButton>}
            </div>
        </div>
    </div>
}
