import { IconProps } from "./type"

export const ICComingSoonBottom: React.FC<IconProps> = () => {
    return (
        <svg width="306" height="98" viewBox="0 0 306 98" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.2">
                <path d="M288.453 92.998C288.453 86.6161 293.724 81.4425 300.226 81.4425C306.729 81.4425 312 86.6161 312 92.998C312 99.38 306.729 104.554 300.226 104.554C293.724 104.554 288.453 99.38 288.453 92.998Z" fill="#D9D9D9" />
                <path d="M247.245 92.998C247.245 86.6161 252.516 81.4425 259.019 81.4425C265.521 81.4425 270.792 86.6161 270.792 92.998C270.792 99.38 265.521 104.554 259.019 104.554C252.516 104.554 247.245 99.38 247.245 92.998Z" fill="#D9D9D9" />
                <path d="M206.038 92.998C206.038 86.6161 211.309 81.4425 217.811 81.4425C224.314 81.4425 229.585 86.6161 229.585 92.998C229.585 99.38 224.314 104.554 217.811 104.554C211.309 104.554 206.038 99.38 206.038 92.998Z" fill="#D9D9D9" />
                <path d="M164.83 92.998C164.83 86.6161 170.101 81.4425 176.604 81.4425C183.106 81.4425 188.377 86.6161 188.377 92.998C188.377 99.38 183.106 104.554 176.604 104.554C170.101 104.554 164.83 99.38 164.83 92.998Z" fill="#D9D9D9" />
                <path d="M123.623 92.998C123.623 86.6161 128.894 81.4425 135.396 81.4425C141.899 81.4425 147.17 86.6161 147.17 92.998C147.17 99.38 141.899 104.554 135.396 104.554C128.894 104.554 123.623 99.38 123.623 92.998Z" fill="#D9D9D9" />
                <path d="M82.4151 92.998C82.4151 86.6161 87.6863 81.4425 94.1887 81.4425C100.691 81.4425 105.962 86.6161 105.962 92.998C105.962 99.38 100.691 104.554 94.1887 104.554C87.6863 104.554 82.4151 99.38 82.4151 92.998Z" fill="#D9D9D9" />
                <path d="M41.2076 92.998C41.2076 86.6161 46.4788 81.4425 52.9811 81.4425C59.4835 81.4425 64.7547 86.6161 64.7547 92.998C64.7547 99.38 59.4835 104.554 52.9811 104.554C46.4788 104.554 41.2076 99.38 41.2076 92.998Z" fill="#D9D9D9" />
                <path d="M0 92.998C0 86.6161 5.27121 81.4425 11.7736 81.4425C18.2759 81.4425 23.5471 86.6161 23.5471 92.998C23.5471 99.38 18.2759 104.554 11.7736 104.554C5.27121 104.554 0 99.38 0 92.998Z" fill="#D9D9D9" />
                <path d="M288.453 52.5536C288.453 46.1716 293.724 40.998 300.226 40.998C306.729 40.998 312 46.1716 312 52.5536C312 58.9355 306.729 64.1091 300.226 64.1091C293.724 64.1091 288.453 58.9355 288.453 52.5536Z" fill="#D9D9D9" />
                <path d="M247.245 52.5536C247.245 46.1716 252.516 40.998 259.019 40.998C265.521 40.998 270.792 46.1716 270.792 52.5536C270.792 58.9355 265.521 64.1091 259.019 64.1091C252.516 64.1091 247.245 58.9355 247.245 52.5536Z" fill="#D9D9D9" />
                <path d="M206.038 52.5536C206.038 46.1716 211.309 40.998 217.811 40.998C224.314 40.998 229.585 46.1716 229.585 52.5536C229.585 58.9355 224.314 64.1091 217.811 64.1091C211.309 64.1091 206.038 58.9355 206.038 52.5536Z" fill="#D9D9D9" />
                <path d="M164.83 52.5536C164.83 46.1716 170.101 40.998 176.604 40.998C183.106 40.998 188.377 46.1716 188.377 52.5536C188.377 58.9355 183.106 64.1091 176.604 64.1091C170.101 64.1091 164.83 58.9355 164.83 52.5536Z" fill="#D9D9D9" />
                <path d="M123.623 52.5536C123.623 46.1716 128.894 40.998 135.396 40.998C141.899 40.998 147.17 46.1716 147.17 52.5536C147.17 58.9355 141.899 64.1091 135.396 64.1091C128.894 64.1091 123.623 58.9355 123.623 52.5536Z" fill="#D9D9D9" />
                <path d="M82.4151 52.5536C82.4151 46.1716 87.6863 40.998 94.1887 40.998C100.691 40.998 105.962 46.1716 105.962 52.5536C105.962 58.9355 100.691 64.1091 94.1887 64.1091C87.6863 64.1091 82.4151 58.9355 82.4151 52.5536Z" fill="#D9D9D9" />
                <path d="M288.453 12.1091C288.453 5.72719 293.724 0.553589 300.226 0.553589C306.729 0.553589 312 5.72719 312 12.1091C312 18.4911 306.729 23.6647 300.226 23.6647C293.724 23.6647 288.453 18.4911 288.453 12.1091Z" fill="#D9D9D9" />
                <path d="M247.245 12.1091C247.245 5.72719 252.516 0.553589 259.019 0.553589C265.521 0.553589 270.792 5.72719 270.792 12.1091C270.792 18.4911 265.521 23.6647 259.019 23.6647C252.516 23.6647 247.245 18.4911 247.245 12.1091Z" fill="#D9D9D9" />
                <path d="M206.038 12.1091C206.038 5.72719 211.309 0.553589 217.811 0.553589C224.314 0.553589 229.585 5.72719 229.585 12.1091C229.585 18.4911 224.314 23.6647 217.811 23.6647C211.309 23.6647 206.038 18.4911 206.038 12.1091Z" fill="#D9D9D9" />
                <path d="M164.83 12.1091C164.83 5.72719 170.101 0.553589 176.604 0.553589C183.106 0.553589 188.377 5.72719 188.377 12.1091C188.377 18.4911 183.106 23.6647 176.604 23.6647C170.101 23.6647 164.83 18.4911 164.83 12.1091Z" fill="#D9D9D9" />
            </g>
        </svg>

    )
}
