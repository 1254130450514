import React from "react";
import { ICSendV4 } from "../../../icons";
import { Switch } from "antd";
import { useAssementDetailsData } from ".";
import { useAssessment } from "../create-assessment/hooks/useAssessment";
import { EStatusAssessment } from "../type";
import i18next from "../../../i18n";

export const ConfigCVAssessment: React.FC = () => {
    const { saveAutoInviteAssessment } = useAssessment();
    const { assessmentId, assessmentDetails, setAssessmentDetails, myCandidateCreditInfo } = useAssementDetailsData();
    const [active, setActive] = React.useState(false);

    React.useEffect(() => {
        setActive(assessmentDetails?.isAutoInvite || false);
    }, [assessmentDetails]);

    const handleActiveChanged = React.useCallback(() => {
        setActive(prev => !prev);
        saveAutoInviteAssessment(assessmentId, !active, setAssessmentDetails);
    }, [assessmentId, saveAutoInviteAssessment, active, setAssessmentDetails]);

    const disableActive = React.useMemo(() => {
        return myCandidateCreditInfo?.remainingCandidateCredit === 0 || assessmentDetails?.status !== EStatusAssessment.ACTIVE;
    }, [assessmentDetails, myCandidateCreditInfo]);
   
    return <div className="grid grid-cols-1 gap-[32px]">
        <div>
            <div className="grid grid-cols-1 gap-[16px]">
                <div className="grid grid-cols-[56px_1fr_50px] gap-[16px] items-center">
                    <ICSendV4 />
                    <span className="flex flex-col gap-[4px]">
                        <span className="text-[#46485C] font-bold leading-[22px] text-[18px]">{i18next.t('assessmentDetail.sendLink')}</span>
                        <span className="text-[#767888] text-[14px] font-normal leading-[17px]">
                            {i18next.t('assessmentDetail.autoSendResultToCandidate')}
                        </span>
                    </span>
                    <Switch checked={active} disabled={disableActive} style={{
                        width: '36px',
                    }} onChange={handleActiveChanged} />
                </div>
            </div>
        </div>
    </div>
}
