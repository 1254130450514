import React from "react";
import classNames from 'classnames';
import { HRTextArea } from "../../../components/form/textarea";
import { ICSelectEssay, ICInfoV2, ICSave, ICArrowBack, ICClose, ICCheckAll, ICStarOutline } from "../../../icons";
import { EQuestionType, ESkillAreaType } from "../../create_test/tab/question/type";
import { TCandidateTestingData, TSectionTesting, TTQuestionTesting, TTestingCandidate } from "../../testing/type";
import { HRButton } from "../../../components/button";
import { ICBack } from "../../../icons/back";
import { InputNumber, Slider, Tag } from "antd";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/appInfo";
import { evaluateMultiEssayQuestion, setViewEssayQuestion } from "../../../api/test-candidate";
import { TEvaluateMultiEssayQuestion, TViewEssayQuestion } from "../../my_assessment/type";
import { useNotification } from "../../../hooks/useNotification";
import { Iframe } from "../../../components/iframe";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import i18next from "../../../i18n";

export enum STEPS {
    INTRO,
    REVIEW,
    RESULT,
}

type TViewEssayWrapperProps = {
    testQuestions: TTestingCandidate[];
    setTestQuestions: React.Dispatch<React.SetStateAction<TTestingCandidate[]>>;
    candidateDetails: TCandidateTestingData;
    selectSkillArea: TSectionTesting | undefined;
    setSelectSkillArea: React.Dispatch<React.SetStateAction<TSectionTesting | undefined>>;
    setShowEssayModal: React.Dispatch<React.SetStateAction<boolean>>;
};
export const ViewEssayWrapper: React.FC<TViewEssayWrapperProps> = ({
    testQuestions,
    setTestQuestions,
    candidateDetails,
    selectSkillArea,
    setSelectSkillArea,
    setShowEssayModal,
}) => {
    const { showError } = useNotification();
    const [step, setStep] = React.useState(STEPS.INTRO);
    const [questions, setQuestions] = React.useState<TTQuestionTesting[]>([]);
    const [showSuggestions, setShowSuggestions] = React.useState<boolean>(false);
    const [showReferenceContent, setShowReferenceContent] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (selectSkillArea) {
            const essayQuestions = selectSkillArea.questions.filter((question) => question.type === EQuestionType.ESSAY);
            setQuestions(essayQuestions as any);
        }
    }, [selectSkillArea]);

    const onSubmitViewContent = React.useCallback(async (question: TTQuestionTesting) => {
        try {
            const payload: TViewEssayQuestion = {
                testCandidateId: candidateDetails?._id || '',
                questionId: question._id,
            };
            const result = await setViewEssayQuestion(payload);
            const tests: TTestingCandidate[] = (result.data?.tests || []) as TTestingCandidate[];
            setTestQuestions(prev => {
                return prev.map((item) => {
                    const newItem = tests.find((newItem) => newItem._id === item._id);
                    if (newItem) {
                        return {
                            ...item,
                            score: newItem.score,
                            atteamRating: newItem.atteamRating,
                            standardRating: newItem.standardRating,
                            sections: newItem.sections,
                        }
                    }
                    return item;
                })
            });
        } catch (error) {
            showError('Error', i18next.t('candidate.errorMessage'));
        }
    }, [candidateDetails?._id, setTestQuestions, showError]);

    const onQuestionClick = React.useCallback((question: TTQuestionTesting) => {
        if (!question.hasViewed) {
            onSubmitViewContent(question);
        }
        setQuestions(prev => {
            return prev.map((q) => {
                if (q._id === question._id) {
                    return {
                        ...q,
                        isFocused: true,
                        hasViewed: true,
                    }
                } else {
                    return {
                        ...q,
                        isFocused: false,
                    }
                }
            })
        });
        setStep(STEPS.REVIEW);
    }, []);

    const questionSelected = React.useMemo(() => {
        return questions.find((question) => question.isFocused);
    }, [questions]);

    React.useEffect(() => {
        if (questionSelected) {
            setStep(STEPS.REVIEW);
        }
    }, [questionSelected]);

    const resetValues = React.useCallback(() => {
        setQuestions(prev => {
            return prev.map((q) => {
                return {
                    ...q,
                    isFocused: false,
                }
            })
        });
        setStep(STEPS.INTRO);
        setShowReferenceContent(false);
        setShowSuggestions(false);
        setShowEssayModal(false);
    }, []);

    return <div className="lg:grid lg:grid-cols-[370px_1fr] rounded-[12px] h-full min-h-[764px] max-h-[764px] overflow-auto">
        <div className="lg:flex flex-col h-full items-start">
            <div className="grid grid-cols-1 shadow-l-small lg:h-full w-full">
                {!showReferenceContent && !showSuggestions && <div className="flex flex-col items-start justify-start gap-[16px] p-[16px]">
                    <div className="text-standard-semi-bold text-high-em line-clamp-2">{selectSkillArea?.name}</div>
                    <div className="flex flex-col w-full">
                        {
                            questions.map((question, index) => {
                                return <div key={index}
                                    className={
                                        classNames("flex flex-col gap-[8px] border-l-[2px] pl-[16px] cursor-pointer py-[8px] w-full",
                                            {
                                                "border-outline-primary text-primary bg-surface-primary-accent-base": question?.isFocused,
                                            },
                                            {
                                                "border-outline-med text-high-em": !question?.isFocused,
                                            },
                                            {
                                                "text-low-em": question?.hasViewed
                                            }
                                        )}
                                    onClick={() => onQuestionClick(question)}
                                >
                                    <div className="text-body">
                                        <QuestionItem content={question?.content} />
                                    </div>
                                    <div className="flex gap-[8px] text-med-em">
                                        {question?.hasViewed && <div className="flex gap-[8px]">
                                            <ICCheckAll />
                                            <span className="text-tiny-medium">{i18next.t('candidateDetail.viewed')}</span>
                                        </div>}
                                        {question?.hasEvaluated && <div className="flex gap-[8px]">
                                            <ICStarOutline width={12} height={12} stroke="#676472" />
                                            <span className="text-tiny-medium">{i18next.t('candidateDetail.evaluated')}</span>
                                        </div>}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>}

                {showReferenceContent && <div className="flex flex-col items-start justify-start gap-[16px] p-[16px]">
                    <div className="flex gap-[8px] items-center">
                        <span className="cursor-pointer" onClick={() => setShowReferenceContent(false)}>
                            <ICClose width={18} height={18} fill="#4F4B5C" />
                        </span>
                        <span className="text-standard-semi-bold text-high-em">{i18next.t('candidateDetail.suggestionsTitle')}</span>
                    </div>
                    <div>
                        <EditorViewQuestionWrapper content={questionSelected?.referenceContent as string} />
                    </div>
                </div>}

                {
                    showSuggestions && <div className="flex flex-col items-start justify-start gap-[16px] p-[16px]">
                        <div className="flex gap-[8px] items-center">
                            <span className="cursor-pointer" onClick={() => {
                                setShowSuggestions(false);
                            }}>
                                <ICClose width={18} height={18} fill="#4F4B5C" />
                            </span>
                            <span className="text-standard-semi-bold text-high-em">{i18next.t('candidateDetail.rankEvaluation')}</span>
                        </div>
                        <div>
                            <QuestionSuggestion />
                        </div>
                    </div>
                }

            </div>
        </div>

        {step === STEPS.INTRO && <div className="flex flex-col bg-surface-low p-[24px] h-full">
            <div className="flex rounded-[12px] bg-white items-center justify-center text-center h-full">
                <ViewEssayIntro resetValues={resetValues} />
            </div>
        </div>}
        {step === STEPS.REVIEW && <div className="flex flex-col bg-surface-low h-full">
            <ViewEssayReview
                setShowEssayModal={setShowEssayModal}
                questions={questions}
                setQuestions={setQuestions}
                setStep={setStep}
                setShowReferenceContent={setShowReferenceContent}
                setShowSuggestions={setShowSuggestions}
                selectSkillArea={selectSkillArea}
                candidateDetails={candidateDetails}
                resetValues={resetValues}
                setSelectSkillArea={setSelectSkillArea}
                testQuestions={testQuestions}
                setTestQuestions={setTestQuestions}
            />
        </div>}

    </div>
}

type ViewEssayIntroProps = {
    resetValues: () => void;
};
const ViewEssayIntro: React.FC<ViewEssayIntroProps> = ({
    resetValues,
}) => {
    return <div className="flex flex-col gap-[32px] p-[32px]">
        <div className="flex items-center justify-center rounded-full">
            <ICSelectEssay />
        </div>
        <div className="flex flex-col gap-[8px]">
            <div className="text-heading-6-bold text-high-em">{i18next.t('candidateDetail.selectEssayQuestion')}</div>
            <div className="text-body text-high-em">{i18next.t('candidateDetail.essayQuestionDescription')}</div>
        </div>
        <div className="flex justify-center items-center">
            <div className="flex max-w-[242px] w-full">
                <HRButton btnSize="md" btnType="primary" onClick={() => {
                    resetValues();
                }}>{i18next.t('candidateDetail.goBack')}</HRButton>
            </div>
        </div>
    </div>
}


const QuestionSuggestion: React.FC = () => {
    const sampleData = [
        {
            id: 1,
            level: i18next.t('candidateDetail.masterLevel'),
            content: i18next.t('candidateDetail.masterDescription'),
        },
        {
            id: 2,
            level: i18next.t('candidateDetail.advancedLevel'),
            content: i18next.t('candidateDetail.advancedDescription'),
        },
        {
            id: 3,
            level: i18next.t('candidateDetail.skilledLevel'),
            content: i18next.t('candidateDetail.skilledDescription'),
        },
        {
            id: 4,
            level: i18next.t('candidateDetail.emergingLevel'),
            content: i18next.t('candidateDetail.emergingDescription'),
        },
        {
            id: 5,
            level: i18next.t('candidateDetail.noviceLevel'),
            content: i18next.t('candidateDetail.noviceDescription'),
        }
    ];

    return <div className="flex flex-col gap-[8px] p-[16px]">
        <div className="flex flex-col gap-[8px]">
            {
                sampleData.map((data, index) => {
                    return <div key={index} className="flex flex-col gap-[8px] bg-surface-low rounded-[8px] p-[16px]">
                        <div>
                            <Tag style={{
                                borderRadius: '4px',
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '16px',
                                padding: '4px 8px',
                                margin: 0,
                                color: '#FFFFFF',
                                boxSizing: 'border-box',
                                backgroundColor: "#8B75FF",
                                borderColor: "#8B75FF"
                            }}>
                                {data.level}
                            </Tag>
                        </div>
                        <div className="text-body text-high-em">{data.content}</div>
                    </div>
                })
            }
        </div>
    </div>
}

type ViewEssayReviewProps = {
    candidateDetails: TCandidateTestingData;
    selectSkillArea: TSectionTesting | undefined;
    questions: TTQuestionTesting[];
    setQuestions: React.Dispatch<React.SetStateAction<TTQuestionTesting[]>>;
    setShowEssayModal: React.Dispatch<React.SetStateAction<boolean>>;
    setStep: React.Dispatch<React.SetStateAction<STEPS>>;
    setShowReferenceContent: React.Dispatch<React.SetStateAction<boolean>>;
    setShowSuggestions: React.Dispatch<React.SetStateAction<boolean>>;
    resetValues: () => void;
    setSelectSkillArea: React.Dispatch<React.SetStateAction<TSectionTesting | undefined>>;
    testQuestions: TTestingCandidate[];
    setTestQuestions: React.Dispatch<React.SetStateAction<TTestingCandidate[]>>;
};
const ViewEssayReview: React.FC<ViewEssayReviewProps> = ({
    candidateDetails,
    selectSkillArea,
    questions,
    setQuestions,
    setShowEssayModal,
    setStep,
    setShowReferenceContent,
    setShowSuggestions,
    resetValues,
    setSelectSkillArea,
    setTestQuestions,
    testQuestions,
}) => {
    const { showSuccess, showError } = useNotification();
    const dispatch = useDispatch();
    const [score, setScore] = React.useState<number | undefined>(undefined);
    const currentQuestion = questions.find((question) => question.isFocused);
    const [comment, setComment] = React.useState<string>('');

    const onSubmit = React.useCallback(async () => {
        try {
            dispatch(setLoading(true));
            const evaluateData = questions.map((question) => {
                return {
                    questionId: question._id,
                    score: question.score,
                    comment: question.comment,
                }
            });
            const payload: TEvaluateMultiEssayQuestion = {
                testCandidateId: candidateDetails?._id || '',
                evaluateData: evaluateData
            };
            const result = await evaluateMultiEssayQuestion(payload);
            const newData: TTestingCandidate[] = (result.data?.tests || []) as TTestingCandidate[];
            const allSectionQuestions: TSectionTesting[] = (result.data?.tests?.map((item: any) => item.sections).flat() || []) as TSectionTesting[];
            const newSelectSkillArea = allSectionQuestions.find((item) => item._id === selectSkillArea?._id);
            setSelectSkillArea(prev => {
                if (prev) {
                    return {
                        ...prev,
                        ...newSelectSkillArea,
                    }
                }
                return prev;
            });
            setTestQuestions(prev => {
                return prev.map((item) => {
                    const newItem = newData.find((newItem) => newItem._id === item._id);
                    if (newItem) {
                        return {
                            ...item,
                            score: newItem.score,
                            atteamRating: newItem.atteamRating,
                            standardRating: newItem.standardRating,
                            sections: newItem.sections,
                        }
                    }
                    return item;
                })
            });

            dispatch(setLoading(false));
            setStep(STEPS.INTRO);
            setQuestions(prev => {
                return prev.map((q) => {
                    if (q.isFocused) {
                        return {
                            ...q,
                            isFocused: false,
                        }
                    }
                    return {
                        ...q,
                    };
                });
            });
            showSuccess(i18next.t('candidateDetail.successMessage'));
        } catch (error) {
            dispatch(setLoading(false));
            showError('Error', i18next.t('candidateDetail.errorMessage'));
        }
    }, [dispatch, questions, candidateDetails?._id, setSelectSkillArea, setTestQuestions, setStep, setQuestions, showSuccess, selectSkillArea?._id, showError]);

    const onChangeScore = React.useCallback((value: number | null) => {
        if (value !== null && isNaN(value)) {
            return;
        }
        setScore(value || 0);
        setQuestions(prev => {
            return prev.map((question) => {
                if (question._id !== currentQuestion?._id) {
                    return question;
                }
                return {
                    ...question,
                    score: question?.isFocused ? value || 0 : question.score || 0,
                }

            });
        });
    }, [setQuestions, currentQuestion]);

    React.useEffect(() => {
        if (currentQuestion) {
            setScore(currentQuestion.score);
            setComment(currentQuestion.comment || '');
        }
    }, [currentQuestion]);

    const onSetCommentData = React.useCallback((value: string) => {
        setComment(value);
        setQuestions(prev => {
            return prev.map((question) => {
                return {
                    ...question,
                    comment: question?.isFocused ? value : question.comment,
                }
            });
        });
    }, []);

    return <div className="flex flex-col justify-start gap-[32px] relative min-h-[600px] lg:h-full">
        <div className="flex items-center justify-between bg-white border-b border-outline-high p-[16px]">
            <div className="text-heading-6-semi-bold text-high-em">{i18next.t('candidateDetail.scoringArea')}</div>
            {selectSkillArea?.type !== ESkillAreaType.STANDARD && <div className="flex gap-[8px] items-center cursor-pointer" onClick={() => {
                setShowSuggestions(true);
                setShowReferenceContent(false);
            }}>
                <span><ICInfoV2 /></span>
                <span className="text-body-medium text-high-em">{i18next.t('candidateDetail.rankEvaluation')}</span>
            </div>}
        </div>

        <div className="h-full">
            <div className="flex flex-col items-start justify-start text-left gap-[16px] px-[24px] max-h-[400px] overflow-auto">
                <div className="shadow-e-03 bg-white rounded-[12px] flex flex-col gap-[16px] px-[16px] py-[24px] w-full">
                    <div className="text-standard-medium text-primary-bold">{i18next.t('candidateDetail.question')}</div>
                    <div className="text-body text-high-em">
                        <EditorViewQuestionWrapper content={currentQuestion?.content as string} />
                    </div>
                </div>
                <div className="shadow-e-03 bg-white rounded-[12px] flex flex-col gap-[16px] px-[16px] py-[24px] w-full">
                    <div className="text-standard-medium text-primary-bold">{i18next.t('candidateDetail.candidateAnswer')}</div>
                    <div className="text-body text-high-em">
                        <EditorViewQuestionWrapper content={currentQuestion?.answer as string} />
                    </div>
                </div>
            </div>
        </div>

        <div className="actions absolute bottom-0 flex flex-col gap-[16px] w-full left-0 right-0 shadow-lg bg-white p-[16px]">
            <div className="flex justify-between items-center w-full gap-[24px]">
                <div className="flex flex-col gap-[8px] w-1/2">
                    <div className="text-sub-medium text-high-em">{i18next.t('candidateDetail.scoreLabel')}</div>
                    <div className="flex gap-[5px]">
                        <Slider min={0} max={100} step={1}
                            value={typeof score === 'number' ? score : 0}
                            style={{
                                width: "100%",
                            }}
                            onChange={val => onChangeScore(val)} />
                        <InputNumber
                            max={100}
                            step={1}
                            value={score === null ? undefined : score}
                            onChange={val => {
                                onChangeScore(val);
                            }}
                        />
                    </div>
                </div>
                <div className={classNames([
                    "flex flex-col w-1/2",
                ])}>
                    <HRTextArea value={comment}
                        parentClassName="w-full"
                        onChange={(e) => onSetCommentData(e.target.value)}
                        placeholder={i18next.t('candidateDetail.commentPlaceholder') ?? ''} />
                </div>
            </div>
            <div className="lg:flex justify-between items-center">
                <div className="max-w-[180px]">
                    <HRButton btnSize="sm" btnType="tertiary"
                        onClick={() => {
                            setShowReferenceContent(true);
                            setShowSuggestions(false);
                        }}
                        style={{
                            backgroundColor: "#F3F3F4",
                            borderRadius: "8px",
                        }}>
                        <div className="flex gap-[8px] items-center">
                            <span><ICArrowBack width={18} height={18} /></span>
                            <span className="text-body-medium text-high-em">{i18next.t('candidateDetail.suggestionsTitle')}</span>
                        </div>
                    </HRButton>
                </div>
                <div className="pt-4 lg:pt-0 flex gap-[16px] justify-between">
                    <span>
                        <HRButton btnSize="md" btnType="sub" onClick={() => {
                            resetValues();
                        }}>
                            <div className="flex gap-[8px] items-center">
                                <span><ICBack /></span>
                                <span className="text-body-bold text-high-em">{i18next.t('candidateDetail.goBack')}</span>
                            </div>
                        </HRButton>
                    </span>
                    <span>
                        <HRButton btnSize="md" btnType="success" onClick={onSubmit}>
                            <div className="flex gap-[8px] items-center">
                                <span><ICSave /></span>
                                <span className="text-body-bold">{i18next.t('candidateDetail.saveChanges')}</span>
                            </div>
                        </HRButton>
                    </span>
                </div>
            </div>
        </div>

    </div>
}

type EditorViewQuestionWrapperProps = {
    content: string;
    id?: string;
    onClick?: () => void;
}
export const EditorViewQuestionWrapper: React.FC<EditorViewQuestionWrapperProps> = ({
    content,
    id,
    onClick,
}) => {
    const onClickIFrame = React.useCallback(() => {
        onClick && onClick();
    }, []);

    return <Iframe key={id} content={content as string || ''} onInferredClick={() => onClickIFrame()} />;
}

type QuestionItemProps = {
    content: string;
}
export const QuestionItem: React.FC<QuestionItemProps> = ({
    content,
}) => {
    const [editorContentState, setEditorContentState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(content as string).contentBlocks))
    );

    return <div className={classNames([
        "flex flex-col gap-[8px] line-clamp-2"
    ])}>
        <span dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorContentState.getCurrentContent())) }}>
        </span>
    </div>
}
