import { TQuestion } from "../create_test/tab/question/type";

export type UserMessage = {
    role: 'user';
    message: string;
    deliveredAt: string;
}
export type AssistantMessage = {
    role: 'assistant';
    message: string;
    questions: TQuestion[];
    deliveredAt: string;
    title?: string;
    language?: "en" | "vi";
    assessmentTime?: number;
}
export type Message = UserMessage | AssistantMessage;

export const DEFAULT_ASSESSTMENT_TIME = 15; // 15 minutes

export type ImportOptions = {
    libraryId?: string;
    questions: TQuestion[];
    title: string;
    language: "en" | "vi";
    assessmentTime: number;
}