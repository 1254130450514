import React from 'react';
import OtpInput from 'react-otp-input';
import { ICReloadV2 } from '../icons/reload';
import { ICTick } from '../icons/tick';
import { HRButton } from './button';
import { Navigator } from './navigator';
import i18next from '../i18n';
import parse from 'html-react-parser';

type OTPProps = {
    isBlockResend?: boolean;
    onResend: () => void;
    onVerify: (otp: string) => void;
    onBack?: () => void;
    errorMessage?: string;
    isShowNavigator?: boolean;
    isShowBackButton?: boolean;
}
const DEFAULT_RESEND_TIME = 180;
export const OTP: React.FC<OTPProps> = ({
    errorMessage = "",
    onResend,
    onBack,
    onVerify,
    isBlockResend = false,
    isShowNavigator = true,
    isShowBackButton = true,
}) => {
    const [otp, setOtp] = React.useState<string>("");
    const otpRef = React.useRef<any>(null);
    const [countDown, setCountDown] = React.useState<number>(0);
    React.useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(prev => prev - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [])
    const onResendOTP = () => {
        onResend();
        setCountDown(DEFAULT_RESEND_TIME);
        setOtp("");
    }
    return (
        <div className="flex flex-col gap-[48px] p-[48px] lg:min-w-[478px] text-center">
            {isShowNavigator && <Navigator current={2} total={3} />}
            <div className="flex flex-col gap-[16px]">
                <h2 className="text-heading-5-bold text-high-em mb-0">{i18next.t("otp.enterOtp")}</h2>
                <div className="text-sub text-med-em">
                    {parse(i18next.t('otp.sendVerificationCodeToYourEmail'))}
                </div>
            </div>
            <div>
                <OtpInput
                    ref={otpRef}
                    containerStyle={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                    shouldAutoFocus={true}
                    value={otp}
                    onChange={(event: any) => {
                        if (!/^[0-9]*$/.test(event)) {
                            return;
                        }
                        setOtp(event);
                    }}
                    numInputs={4}
                    isInputNum={true}
                    separator={<div className='w-[24px]' />}
                    inputStyle={{
                        width: "48px",
                        height: "48px",
                        borderRadius: "8px",
                        backgroundColor: errorMessage ? "#FFF1F1" : "#F8F8F8",
                        fontSize: "28px",
                        fontWeight: 500,
                        lineHeight: "40px",
                        border: errorMessage ? "1px solid #FFE0E0" : "1px solid #ECECED",
                        color: errorMessage ? "#F03D3D" : "#110C22",
                    }}
                    focusStyle={{
                        border: "1px solid #ECECED",
                        outlineWidth: "0px",
                        backgroundColor: "#F8F8F8",
                        boxShadow: "0px 0px 0px 4px #ECECED, 0px 2px 4px 0px rgba(17, 12, 34, 0.12)",
                    }}
                />
                <div className="pt-[16px] text-sub-medium text-error-500">
                    {errorMessage}
                </div>
            </div>
            <div className="flex flex-col gap-[24px]">
                <HRButton disabled={!otp || otp?.length < 4} onClick={onVerify.bind(this, otp)}>
                    <div className="flex space-x-2 justify-center">
                        <ICTick fill='white' />
                        <span>{i18next.t('otp.confirm')}</span>
                    </div>
                </HRButton>
                {
                    countDown <= 0 ? (
                        !isBlockResend && (
                            <div className="flex flex-col gap-[4px]">
                                <div className='text-body text-med-em'>
                                    {i18next.t('otp.didNotReceiveCode')}
                                </div>
                                <div
                                    onClick={onResendOTP}
                                    className="justify-center space-x-2 flex text-info-500 text-body-medium items-center px-3 cursor-pointer"
                                >
                                    <ICReloadV2 fill="#4D87FA" />
                                    <span>{i18next.t('otp.resendCode')}</span>
                                </div>
                            </div>
                        )
                    ) : (
                        <div className='flex justify-center space-x-1 text-body-medium'>
                            <div className="text-gray-400">
                                {i18next.t('otp.resendIn')}
                            </div>
                            <div className="text-high-em">{countDown}s</div>
                        </div>
                    )
                }
            </div>
            {isShowBackButton && <div
                className="cursor-pointer text-info-500 underline text-body font-medium"
                onClick={onBack}
            >
                {i18next.t('otp.goBack')}
            </div>}
        </div>
    )
}
