import React from "react";
import { EyeFilled } from "@ant-design/icons";
import { Button } from "antd";
import { TQuestion } from "../create_test/tab/question/type";
import { ICLogo } from "../../icons";
import { AssistantMessage, DEFAULT_ASSESSTMENT_TIME, ImportOptions, Message, UserMessage } from "./type";
import styled from "styled-components";
import { ReviewQuestionModal } from "./ReviewAndSaveModal";
import i18next from "../../i18n";
type MessageRendererProps = {
    message: Message;
    onImport: (options: ImportOptions) => void;
}
export const MessageRenderer: React.FC<MessageRendererProps> = ({
    message,
    onImport
}) => {
    switch (message.role) {
        case 'assistant':
            return <AssistantMessageRenderer
                message={message}
                onImport={onImport}
            />
        case 'user':
            return <UserMessageRenderer message={message} />
        default:
            return null;
    }
}
type UserMessageRendererProps = {
    message: UserMessage;
}
const UserMessageRenderer: React.FC<UserMessageRendererProps> = ({ message }) => {
    return <div className="flex justify-end">
        <div className="flex px-3 py-1 bg-[#eaeaea] rounded-xl max-w-[400px]">
            <div className="flex flex-col text-[#364152] font-medium text-[14px]">
                <div>
                    {message.message.split('\n').map((line, index) => <div key={index}>{line}</div>)}
                </div>
            </div>
        </div>
    </div>
}
type AssistantMessageRendererProps = {
    message: AssistantMessage;
    onImport: (
        options: ImportOptions
    ) => void;
}
const AssistantMessageRenderer: React.FC<AssistantMessageRendererProps> = ({ message, onImport }) => {
    const hasQuestions = message.questions.length > 0;
    const [isOpen, setIsOpen] = React.useState(false);
    return <div className="flex max-w-[80%]">
        <div className="grid grid-cols-[48px_1fr] gap-3">
            <div className="w-[48px] h-[48px] rounded-full bg-white flex items-center justify-center">
                <ICLogo
                    width={48}
                    height={48}
                />
            </div>
            <div className="bg-white px-3 py-2 rounded-xl shadow-sm">
                {
                    !hasQuestions && <div className="flex flex-col text-[#364152] font-medium text-[14px]">
                        <div>{message.message}</div>
                    </div>
                }
                {
                    hasQuestions && <div className="flex flex-col text-[#364152] font-medium text-[14px] space-y-2">
                        <span className="font-semibold text-[14px]">
                            {message.title}
                        </span>
                        <div className="text-sm font-normal">
                            <div>
                                {message.questions.length} {i18next.t('assisstant.message.questions.questions')} - {message.assessmentTime || DEFAULT_ASSESSTMENT_TIME} {i18next.t('assisstant.message.questions.assessmentTime')}
                            </div>
                            <div>
                                {i18next.t('assisstant.message.language')}: {message.language === 'en' ? i18next.t('assisstant.message.questions.language.en') : i18next.t('assisstant.message.questions.language.vi')}
                            </div>
                        </div>
                        <Button
                            onClick={() => setIsOpen(true)}
                            type="primary"
                            style={{
                                borderRadius: 4,
                                width: "144px",
                                backgroundColor: '#096dd9',
                            }}
                        >
                            <div className="flex items-center space-x-2 font-semibold">
                                <span className="text-[12px]">{i18next.t('assisstant.message.viewQuestions')}</span>
                                <EyeFilled />
                            </div>
                        </Button>
                    </div>
                }
                {
                    hasQuestions && <ReviewQuestionModal
                        title={message.title}
                        isOpen={isOpen}
                        onClose={() => setIsOpen(false)}
                        questions={message.questions}
                        onImport={(libraryId) => {
                            onImport({
                                libraryId,
                                questions: message.questions,
                                title: message.title || 'Generated by AI',
                                assessmentTime: message.assessmentTime || DEFAULT_ASSESSTMENT_TIME,
                                language: message.language || 'en',
                            });
                            setIsOpen(false);
                        }}
                    />
                }
            </div>
        </div>
    </div>
}
type GrayCircleProps = {
    delay: number;
}
const GrayCircle = styled.div<GrayCircleProps>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #cdd5df;

    @keyframes bounce {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-50%);
        }
    }
    animation: bounce 0.8s ease-in-out infinite;
    animation-delay: ${props => props.delay}s;
`
export const GeneratingMessage: React.FC = () => {
    return <div className="flex w-4/5">
        <div className="grid grid-cols-[48px_1fr] gap-3 items-center">
            <div className="w-[48px] h-[48px] rounded-full bg-white flex items-center justify-center">
                <ICLogo
                    width={48}
                    height={48}
                />
            </div>
            <div className="flex flex-col bg-white p-3 rounded-xl shadow-sm">
                <div className="space-x-2 items-center flex py-1 px-2">
                    <GrayCircle
                        delay={0}
                    />
                    <GrayCircle
                        delay={0.1}
                    />
                    <GrayCircle
                        delay={0.2}
                    />
                </div>
            </div>
        </div>
    </div>
}