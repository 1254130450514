import { LANGUAGE } from "../../utils/constants";
import { PrivacyPolicyEnPage } from "./privacy-policy-en";
import { PrivacyPolicyViPage } from "./privacy-policy-vi";

const PrivacyPolicyContent = ({ lang }: { lang: string }) => {
    switch(lang) {
        case LANGUAGE.EN:
            return <PrivacyPolicyEnPage />;
        default:
            return <PrivacyPolicyViPage />;
    }
}

export const PrivacyPolicyPage: React.FC = () => {
    const lang = localStorage.getItem('language') ?? LANGUAGE.VN;

    return <PrivacyPolicyContent lang={lang}/>
}
