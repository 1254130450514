import React from 'react';
import classNames from 'classnames'
import { useSticky } from '../hooks/useSticky';
import { ICLogoTesting } from '../icons';
import { useCandidateLayoutContext } from './candidate';
import i18next from '../i18n';

export const CandidateHeader: React.FC = () => {
    const { isSticky, element } = useSticky();
    {/* isSticky ? "header-sticky" : "" */}

    const { companyProfile } = useCandidateLayoutContext();

    const logoCompany = React.useMemo(() => {
        return `${process.env.REACT_APP_S3_URL}/${companyProfile?.logo}`;
    }, [companyProfile]);

    return (
        <div ref={element} className={classNames("h-[70px] flex justify-center mx-auto shadow-e-02 overflow-hidden box-border header-sticky")}>
            <div className="lg-container flex justify-between items-center w-full h-full">
                <div className="flex">
                    {(companyProfile?.logo || companyProfile?.name) && <div className="flex items-center gap-[8px]">
                        {companyProfile?.logo && 
                            <div className="flex w-[40px] h-[40px]">
                                <img alt="logo" style={{ width: '100%', objectFit: 'contain' }} src={logoCompany} />
                            </div>
                        }
                        {companyProfile?.name && <span className="text-body-semi-bold text-high-em">{companyProfile?.name}</span>}
                    </div>}
                </div>
                <div className="flex gap-[12px] items-center justify-end">
                    <span className="text-tiny-medium text-med-em">{i18next.t('layout.providedBy')}</span>
                    <ICLogoTesting />
                </div>
            </div>
        </div>
    )
}
