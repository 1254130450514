import { MutilQuestionAtTeam } from "./at-team-multi";
import { QuestionEditor } from "./editor"
import { EssayEditor } from "./essay"
import { GroupQuestionCreator } from "./GroupQuestion";
import { SpeakingQuestionCreator } from "./speaking";
import { EQuestionType, ESkillAreaType, TChoiceQuestion, TQuestion, TSpeakingQuestion } from "./type"

type QuestionCreatorProps = {
    selectedQuestion: TQuestion | undefined;
    questionType: EQuestionType;
    skillAreaTypeSelected: ESkillAreaType | undefined;
    onCancel: () => void;
}
export const QuestionCreator: React.FC<QuestionCreatorProps> = ({
    questionType,
    selectedQuestion,
    skillAreaTypeSelected,
    onCancel,
}) => {
    switch (questionType) {
        case EQuestionType.SINGLE_CHOICE:
            return <QuestionEditor
                questionType={questionType}
                onCancel={onCancel}
                selectedQuestion={selectedQuestion as TChoiceQuestion}
            />
        case EQuestionType.MULTIPLE_CHOICE:
            return (skillAreaTypeSelected === ESkillAreaType.ATTEAM ? <MutilQuestionAtTeam
                questionType={questionType}
                onCancel={onCancel}
                selectedQuestion={selectedQuestion as TChoiceQuestion}
            /> :
                <QuestionEditor
                    questionType={questionType}
                    onCancel={onCancel}
                    selectedQuestion={selectedQuestion as TChoiceQuestion}
                />)
        case EQuestionType.ESSAY:
            return <EssayEditor
                questionType={questionType}
                onCancel={onCancel}
                selectedQuestion={selectedQuestion as TChoiceQuestion}
            />
        case EQuestionType.CODING:
            return <div>coding</div>
        case EQuestionType.GROUP:
            return <GroupQuestionCreator
                questionType={questionType}
                onCancel={onCancel}
                selectedQuestion={selectedQuestion as TChoiceQuestion}
            />
        case EQuestionType.SPEAKING:
            return <SpeakingQuestionCreator 
                questionType={questionType}
                onCancel={onCancel}
                selectedQuestion={selectedQuestion as TSpeakingQuestion}
            />
        default:
            return <div>not found</div>
    }
}
