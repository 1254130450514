import { IconProps } from "./type"

export const ICSmallClock: React.FC<IconProps> = ({
    fill = "#46485C",
}) => {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 2C5.43913 2 4.42172 2.42143 3.67157 3.17157C2.92143 3.92172 2.5 4.93913 2.5 6C2.5 7.06087 2.92143 8.07828 3.67157 8.82843C4.42172 9.57857 5.43913 10 6.5 10C7.56087 10 8.57828 9.57857 9.32843 8.82843C10.0786 8.07828 10.5 7.06087 10.5 6C10.5 4.93913 10.0786 3.92172 9.32843 3.17157C8.57828 2.42143 7.56087 2 6.5 2ZM1.5 6C1.5 3.2385 3.7385 1 6.5 1C9.2615 1 11.5 3.2385 11.5 6C11.5 8.7615 9.2615 11 6.5 11C3.7385 11 1.5 8.7615 1.5 6ZM6.5 3C6.63261 3 6.75979 3.05268 6.85355 3.14645C6.94732 3.24021 7 3.36739 7 3.5V5.793L8.3535 7.1465C8.44458 7.2408 8.49498 7.3671 8.49384 7.4982C8.4927 7.6293 8.44011 7.75471 8.34741 7.84741C8.25471 7.94011 8.1293 7.9927 7.9982 7.99384C7.8671 7.99498 7.7408 7.94458 7.6465 7.8535L6.1465 6.3535C6.05273 6.25975 6.00003 6.1326 6 6V3.5C6 3.36739 6.05268 3.24021 6.14645 3.14645C6.24021 3.05268 6.36739 3 6.5 3Z" fill={fill}/>
        </svg>
    )
}
