import i18next from "i18next";
import { Conversation } from "../../api/gpt/generate";

export const groupByDay = (data: Conversation[]): { title: string, items: Conversation[] }[] => {
    const groups: { [key: string]: Conversation[] } = {};
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    data.forEach(item => {
        const createdDate = new Date(item.createdAt ?? new Date());
        createdDate.setHours(0, 0, 0, 0);

        const dateKey = createdDate.toISOString().split('T')[0];
        if (!groups[dateKey]) {
            groups[dateKey] = [];
        }
        groups[dateKey].push(item);
    });

    const result: any[] = Object.keys(groups).map(dateKey => {
        const createdDate = new Date(dateKey);
        const dayDiff = Math.floor((today.getTime() - createdDate.getTime()) / (1000 * 60 * 60 * 24)); // Chênh lệch ngày

        let title: string = '';
        if (dayDiff === 0) {
            title = i18next.t('assisstant.dateTitles.today');
        }

        if (dayDiff === 1) {
            title = i18next.t('assisstant.dateTitles.yesterday');
        }

        if (dayDiff > 1 && dayDiff <= 30) {
            title = i18next.t('assisstant.dateTitles.createdDaysAgo', { days: dayDiff });
        }

        if (dayDiff > 30) {
            title = i18next.t('assisstant.dateTitles.previous30Days');
        }

        return {
            title: title,
            createdDate: dateKey,
            items: groups[dateKey]
        };
    });

    return result;
}