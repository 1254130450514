import styled from 'styled-components';
import { ICChevronDown, ICChevronUp, ICSetting } from "../../../../../icons";
import React from 'react';
import i18next from '../../../../../i18n';

const Wrapper = styled.div`
    border: 1px solid #E2E4EB;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(79, 117, 140, 0.24);
`;

const Header = styled.div`
    background: #EEF0F6;
    border-radius: 4px 4px 0px 0px;
    height: 76px;
    padding: 0 16px;
`;

const Body = styled.div`
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

const Collapse = styled.a`

`;

type HRPanelProps = {
    header: React.ReactNode,
    collapseText?: React.ReactNode,
    body: React.ReactNode,
    onChangeCollapse?: (value: any) => void,
    open?: boolean
}
export const HRPanel: React.FC<HRPanelProps> = ({ header, body, collapseText, onChangeCollapse, open = false, ...props }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(open);
    const onChanges = React.useCallback(() => {
        const newOpen = !isOpen;
        setIsOpen(newOpen);
        if (onChangeCollapse) {
            onChangeCollapse(newOpen);
        }
    }, [isOpen, onChangeCollapse]);

    return <Wrapper>
        <Header className="grid grid-cols-[auto_1fr_auto] gap-[16px] justify-between items-center text-[14px] font-medium leading-[21px]">
            <ICSetting />
            {header}
            {collapseText && <Collapse className="text-[#4D87FA] underline" onClick={onChanges}>{isOpen ? i18next.t('createTest.returnToDefault') : collapseText}</Collapse>}
        </Header>
        {isOpen && <Body>
            {body}
        </Body>}
    </Wrapper>
}

type HRMobilePanelProps = HRPanelProps & {
    subTitle: React.ReactNode,
    canExpand?: boolean
}
export const HRMobilePanel: React.FC<HRMobilePanelProps> = ({
    header,
    subTitle,
    canExpand,
    body,
    collapseText,
    onChangeCollapse,
    open = false,
    ...props
}) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(open);
    const onChanges = React.useCallback(() => {
        const newOpen = !isOpen;
        setIsOpen(newOpen);
        if (onChangeCollapse) {
            onChangeCollapse(newOpen);
        }
    }, [isOpen, onChangeCollapse]);
    return <Wrapper>
        <div className="p-2 bg-[#EEF0F6] text-[14px] font-medium leading-[21px] space-y-4">
            <div className="flex items-center justify-between">
                <div className="flex space-x-2 items-center">
                    <ICSetting />
                    {header}
                </div>
                {canExpand && <Collapse
                    onClick={onChanges}
                >
                    {isOpen ? <ICChevronUp /> : <ICChevronDown />}
                </Collapse>}
            </div>
            <div>
                {subTitle}
            </div>
        </div>
        {isOpen && <Body>
            {body}
        </Body>}
    </Wrapper>
}
