import React from "react";
import { useNotification } from "../../../../hooks/useNotification";
import { useDispatch } from "react-redux";
import { Button, notification } from "antd";
import { setLoading } from "../../../../redux/slices/appInfo";
import { UploadAPI } from "../../../../api/upload";
import { getURL } from "../../../../utils";
import { TQuestion } from "./type";
import { HRInput } from "../../../../components/form/input";
import { HRTextArea } from "../../../../components/form/textarea";
import i18next from "../../../../i18n";

type AudioUploadProps = {
    url?: string
    onUploaded: (url: string) => void
}

export const AudioUpload: React.FC<AudioUploadProps> = ({
    url,
    onUploaded
}) => {
    const inputAudioRef = React.useRef<HTMLInputElement>(null);
    const { showSuccess } = useNotification();
    const dispatch = useDispatch();
    const onUploadedAudio = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            if (file) {
                const WHITE_LIST_AUDIO_TYPES = ["audio/mpeg", "audio/wav", "audio/ogg", "audio/mp3"];
                // Validate file
                if (!WHITE_LIST_AUDIO_TYPES.includes(file.type)) {
                    notification.error({
                        message: "Error",
                        description: i18next.t('createTest.invalidFile')
                    })
                    return;
                }
                callUpload(file);
            }
        }
    }
    const callUpload = async (file: File) => {
        try {
            dispatch(setLoading(true))
            const { data } = await UploadAPI(file);
            onUploaded(data.fileUrl);
            showSuccess(i18next.t('createTest.success'));
        } catch (e) {
            console.error(e)
            notification.error({
                message: "Something went wrong",
                placement: "bottom",
            });
        } finally {
            dispatch(setLoading(false))
        }
    };
    return <div>
        <div className="grid grid-cols-1 gap-[8px]">
            <span className="text-primary-bold text-standard-medium">Audio</span>
            <span className="text-body-medium text-med-em">{i18next.t('createTest.success')}</span>
        </div>
        <Button onClick={() => inputAudioRef.current?.click()}>{i18next.t('createTest.clickToUploadAudio')}</Button>
        <input
            onChange={onUploadedAudio}
            accept="audio/*"
            type="file"
            className="hidden"
            ref={inputAudioRef}
        />
        {
            url && <div className="mt-2 flex items-center space-x-4">
                <audio controls>
                    <source src={getURL(url)} type="audio/mpeg" />
                </audio>
                <Button
                    danger
                    onClick={() => {
                        if (inputAudioRef.current) inputAudioRef.current.value = '';
                        onUploaded('');
                    }}
                >
                    {i18next.t('createTest.remove')}
                </Button>
            </div>
        }
    </div>
}

type AudioQuestionProps<T> = {
    question: T;
    setQuestion: React.Dispatch<React.SetStateAction<T>>
}
export function AudioQuestion<T extends TQuestion>({ question, setQuestion }: AudioQuestionProps<T>) {
    return <div>
        <AudioUpload
            url={question.audioUrl}
            onUploaded={(url) => {
                setQuestion(prev => ({
                    ...prev,
                    audioUrl: url ? url : undefined,
                    maximumPlayAudio: url ? prev.maximumPlayAudio ?? 1 : undefined
                }))
            }}
        />
        {
            question.audioUrl && <div>
                <div>
                    <span className="text-body-medium text-med-em">{i18next.t('createTest.maxNumberOfListenAudio')}</span>
                    <div className="w-[100px]">
                        <HRInput
                            size="small"
                            type="number"
                            min={1}
                            value={question.maximumPlayAudio}
                            onChange={e => {
                                let value = Math.max(1, parseInt(e.target.value));
                                if (isNaN(value)) value = 1;
                                setQuestion(prev => ({ ...prev, maximumPlayAudio: value }))
                            }}
                        />
                    </div>
                </div>
                <div>
                    <span className="text-body-medium text-med-em">Transcription</span>
                    <HRTextArea
                        placeholder={i18next.t('createTest.transcription') ?? ''}
                        value={question.audioTranscription}
                        onChange={e => setQuestion(prev => ({ ...prev, audioTranscription: e.target.value }))}
                        rows={5}
                    />
                </div>
            </div>
        }
    </div>
}