import { customAlphabet } from "nanoid";
import { IndexedOption } from "./type";

export const getInnerText = (html: string) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
}

export const isDuplicateAnswer = (answers: IndexedOption[]) => {
    const textAnswers = answers.map((answer) => getInnerText(answer.content));
    const isDuplicate = textAnswers.some((answer, index) => {
        return textAnswers.indexOf(answer) !== index;
    });
    return isDuplicate;
}

export const quoteString = function (str: string) {
    return str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
};

// Generate a random UUID
const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";
export const uuid = () => {
    return customAlphabet(alphabet, 21)(21);
}