import { Pagination, PaginationProps } from 'antd'
import styled from 'styled-components';

const PaginationWrapper = styled.div`
    li {
        width: 34px;
        height: 34px;
        border: 0;
        color: #8D8A95;
        border-radius: 4px;
        background-color: transparent;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin-right: 30px;
        &:hover {
            a {
                color: #3C28A4;
            }
        }
        &.ant-pagination-item-active {
            background-color: #F1EEFF;
            a {
                color: #3C28A4;
            }
        }
        &.ant-pagination-prev,
        &.ant-pagination-next {
            button {
                background-color: transparent;
                border: 1px solid transparent;
                &:hover {
                    border-color: #7357FF;
                    color: #7357FF;
                }
            }
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }
    li:last-child {
        margin-right: 0;
    }
`;

interface HRPaginationProps extends PaginationProps {
    currentPage?: number;
    total?: number;
    showSizeChanger?: boolean;
}

export const HRPagination: React.FC<HRPaginationProps> = ({
    currentPage = 1,
    total = 20,
    showSizeChanger = false,
    ...props
}) => {

    return <PaginationWrapper>
        <Pagination current={currentPage} total={total} showSizeChanger={showSizeChanger} {...props} ></Pagination>
    </PaginationWrapper>
}
