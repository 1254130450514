import { uploadCallback } from '../../../../../create_test/tab/question/editor.config';
import { EQuestionType } from '../../../../../create_test/tab/question/type';
import { TAdditionalQuestion } from '../../../type';

// TODO use in create_test/tab/question/editor.config.ts
export const toolbar = {
    options: ['inline', 'list', 'link', "image"],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline', 'superscript', 'subscript']
    },
    list: {
        options: ['unordered', 'ordered'],
    },
    image: {
        className: 'editor-image',
        uploadCallback: uploadCallback,
        popupClassName: 'editor-image-popup',
        previewImage: true,
        // Remove LCR option
        alignmentEnabled: false,
        // Default to full width
        defaultSize: {
            height: 'auto',
            width: '100%',
        },
    }
}

export const answerToolbar = {
    options: ['inline', 'list', 'link'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],

        bold: { icon: '/images/B.svg', className: undefined },
        italic: { icon: '/images/I.svg', className: undefined },
        underline: { icon: '/images/U.svg', className: undefined },
        // strikethrough: { icon: strikethrough, className: undefined },
        // monospace: { icon: monospace, className: undefined },
        superscript: { icon: '/images/x2-up.svg', className: undefined },
        subscript: { icon: '/images/x2.svg', className: undefined },

    },
    link: {
        options: ['link'],
        link: { icon: '/images/link-toolbar.svg', className: undefined },
    },
    list: {
        options: ['unordered', 'ordered'],
        unordered: { icon: '/images/unordered.svg', className: undefined },
        ordered: { icon: '/images/ordered.svg', className: undefined },
    }
}

export const isEmptyHTML = (html: string) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.innerText.replace(/\n/g, "").length === 0;
}

export const isAnswerError = (question: TAdditionalQuestion) => {
    return (question.type === EQuestionType.SINGLE_CHOICE && question.correctAnswer === undefined)
        || (question.type === EQuestionType.MULTIPLE_CHOICE && question.correctAnswer.length === 0)
}
