import { IconProps } from "./type"

export const ICSelectEssay: React.FC<IconProps> = () => {
    return (
        <svg width="201" height="201" viewBox="0 0 201 201" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42.3962 177.3C74.6242 194.288 126.876 194.288 159.104 177.3C191.332 160.312 191.336 132.776 159.104 115.792C126.872 98.8082 74.6242 98.8042 42.3922 115.792C10.1602 132.78 10.1642 160.304 42.3962 177.3Z" fill="#F5F5F5" />
            <path d="M28.5761 156.04L30.0721 156.9C30.3405 157.036 30.6372 157.107 30.9381 157.107C31.239 157.107 31.5357 157.036 31.8041 156.9L100.932 116.992C101.037 116.957 101.129 116.891 101.193 116.801C101.258 116.712 101.293 116.604 101.293 116.494C101.293 116.383 101.258 116.276 101.193 116.186C101.129 116.097 101.037 116.03 100.932 115.996L99.4481 115.148C99.1797 115.012 98.883 114.941 98.5821 114.941C98.2812 114.941 97.9845 115.012 97.7161 115.148L28.5761 155.04C28.4716 155.075 28.3809 155.143 28.3166 155.232C28.2523 155.322 28.2178 155.429 28.2178 155.54C28.2178 155.65 28.2523 155.758 28.3166 155.847C28.3809 155.937 28.4716 156.004 28.5761 156.04Z" fill="#E0E0E0" />
            <path d="M128.372 170.416C140.023 170.416 149.468 165.238 149.468 158.852C149.468 152.465 140.023 147.288 128.372 147.288C116.721 147.288 107.276 152.465 107.276 158.852C107.276 165.238 116.721 170.416 128.372 170.416Z" fill="#E0E0E0" />
            <path d="M152.744 138.948C161.733 138.948 169.02 134.952 169.02 130.024C169.02 125.095 161.733 121.1 152.744 121.1C143.755 121.1 136.468 125.095 136.468 130.024C136.468 134.952 143.755 138.948 152.744 138.948Z" fill="#E0E0E0" />
            <path d="M160.704 121.056C160.68 119.012 162.108 114.764 164.196 112.084C166.284 109.404 169.592 107.424 171.972 108.208C174.188 108.936 173.972 111.832 171.328 113.78C168.684 115.728 164.684 118.18 163.544 121.516L161.124 125.612L160.704 121.056Z" fill="#27DEBF" />
            <path opacity="0.1" d="M160.704 121.056C160.68 119.012 162.108 114.764 164.196 112.084C166.284 109.404 169.592 107.424 171.972 108.208C174.188 108.936 173.972 111.832 171.328 113.78C168.684 115.728 164.684 118.18 163.544 121.516L161.124 125.612L160.704 121.056Z" fill="black" />
            <path d="M160.588 123.856C160.62 123.852 160.651 123.838 160.674 123.815C160.698 123.793 160.714 123.764 160.72 123.732C162.004 116.74 167.58 111.004 170.94 109.712C170.978 109.697 171.008 109.669 171.025 109.632C171.041 109.595 171.042 109.553 171.028 109.516C171.021 109.498 171.01 109.481 170.997 109.467C170.983 109.453 170.967 109.442 170.949 109.434C170.932 109.426 170.912 109.422 170.893 109.421C170.873 109.421 170.854 109.425 170.836 109.432C167.408 110.748 161.728 116.58 160.436 123.68C160.432 123.699 160.433 123.718 160.437 123.738C160.441 123.757 160.449 123.775 160.46 123.791C160.471 123.807 160.486 123.82 160.502 123.831C160.518 123.841 160.537 123.848 160.556 123.852L160.588 123.856Z" fill="white" />
            <path d="M161.7 128.308C162.016 127.156 163.396 125.244 165.644 123.648C168.136 121.888 171.304 121.052 172.02 119.696C172.88 118.064 171.496 116.552 168.92 116.896C166.344 117.24 161.856 120.82 161.192 126.124L161.7 128.308Z" fill="#27DEBF" />
            <path d="M161.168 127.108C161.197 127.105 161.224 127.094 161.246 127.076C161.269 127.059 161.286 127.035 161.296 127.008C163.36 121.008 167.896 118.856 170.004 118.556C170.043 118.55 170.078 118.529 170.102 118.498C170.126 118.466 170.136 118.427 170.132 118.388C170.129 118.368 170.123 118.349 170.113 118.332C170.104 118.315 170.09 118.3 170.075 118.289C170.059 118.277 170.041 118.268 170.022 118.263C170.003 118.258 169.983 118.257 169.964 118.26C167.632 118.592 163.124 120.784 161.012 126.912C161.006 126.93 161.003 126.95 161.004 126.969C161.005 126.988 161.01 127.007 161.019 127.025C161.028 127.042 161.039 127.058 161.054 127.071C161.069 127.084 161.086 127.094 161.104 127.1C161.124 127.108 161.146 127.111 161.168 127.108Z" fill="white" />
            <path d="M30.4399 155.032L30.1359 154.856C29.8802 154.689 29.6673 154.465 29.5144 154.201C29.3615 153.937 29.273 153.64 29.2559 153.336V50.8318C29.2733 50.5267 29.362 50.2299 29.5148 49.9653C29.6676 49.7006 29.8803 49.4754 30.1359 49.3078L96.1839 11.1798C96.4566 11.0417 96.7581 10.9697 97.0639 10.9697C97.3696 10.9697 97.6711 11.0417 97.9439 11.1798L98.2439 11.3558C98.5003 11.5225 98.7137 11.7474 98.8666 12.0123C99.0195 12.2772 99.1077 12.5744 99.1239 12.8798V115.38C99.1077 115.685 99.0195 115.983 98.8666 116.247C98.7137 116.512 98.5003 116.737 98.2439 116.904L32.1999 155.032C31.9271 155.17 31.6256 155.242 31.3199 155.242C31.0141 155.242 30.7126 155.17 30.4399 155.032Z" fill="#27DEBF" />
            <path opacity="0.4" d="M99.1156 12.7398C99.0556 12.2838 98.6876 12.1158 98.2476 12.3678L32.1956 50.4998C31.937 50.6628 31.7238 50.8885 31.5756 51.1558L29.5156 49.9558C29.6657 49.6886 29.8802 49.4631 30.1396 49.2998L96.1836 11.1798C96.4564 11.0417 96.7579 10.9697 97.0636 10.9697C97.3694 10.9697 97.6709 11.0417 97.9436 11.1798L98.2476 11.3558C98.4816 11.5124 98.6798 11.7167 98.8294 11.9552C98.979 12.1937 99.0765 12.461 99.1156 12.7398Z" fill="white" />
            <path opacity="0.1" d="M32.0521 155.1C31.7957 155.202 31.5205 155.249 31.2448 155.237C30.969 155.225 30.6989 155.155 30.4521 155.032L30.1441 154.856C29.8884 154.689 29.6758 154.464 29.5236 154.199C29.3713 153.934 29.2838 153.637 29.2681 153.332V50.8318C29.2773 50.5265 29.3655 50.2288 29.5241 49.9678L31.5841 51.1678C31.4206 51.424 31.3231 51.7167 31.3001 52.0198V154.524C31.3001 155.028 31.6361 155.264 32.0521 155.1Z" fill="black" />
            <path d="M39.1078 117.96L38.8038 117.784C38.5482 117.618 38.3353 117.393 38.1824 117.129C38.0295 116.865 37.9409 116.569 37.9238 116.264V97.8402C37.9409 97.535 38.0295 97.2381 38.1823 96.9734C38.3352 96.7086 38.5481 96.4835 38.8038 96.3161L98.7678 61.6642C99.0399 61.5261 99.3407 61.4541 99.6458 61.4541C99.9509 61.4541 100.252 61.5261 100.524 61.6642L100.828 61.8402C101.084 62.0068 101.298 62.2318 101.451 62.4966C101.604 62.7615 101.692 63.0587 101.708 63.3642V81.7882C101.692 82.093 101.603 82.3897 101.451 82.6539C101.298 82.9181 101.084 83.1423 100.828 83.3082L40.8678 117.96C40.595 118.098 40.2936 118.17 39.9878 118.17C39.6821 118.17 39.3806 118.098 39.1078 117.96Z" fill="#EBEBEB" />
            <path d="M101.7 63.2239C101.64 62.7679 101.272 62.5999 100.828 62.8519L40.8636 97.5079C40.605 97.6709 40.3918 97.8966 40.2436 98.1639L38.1836 96.9639C38.3319 96.6943 38.5467 96.4671 38.8076 96.3039L98.7676 61.6519C99.0397 61.5139 99.3405 61.4419 99.6456 61.4419C99.9507 61.4419 100.252 61.5139 100.524 61.6519L100.828 61.8279C101.065 61.9841 101.266 62.1897 101.416 62.4306C101.567 62.6716 101.663 62.9422 101.7 63.2239Z" fill="#FAFAFA" />
            <path d="M40.7156 118.032C40.4591 118.134 40.1837 118.181 39.9079 118.168C39.6321 118.156 39.362 118.085 39.1156 117.96L38.8036 117.788C38.5498 117.619 38.3388 117.394 38.1874 117.129C38.0361 116.865 37.9485 116.568 37.9316 116.264V97.8401C37.9409 97.5348 38.0291 97.2371 38.1876 96.9761L40.2476 98.1761C40.0908 98.4381 40.0014 98.735 39.9876 99.0401V117.468C39.9836 117.956 40.3036 118.192 40.7156 118.032Z" fill="#E0E0E0" />
            <path d="M49.3436 96.2717C52.7636 94.2957 55.5356 95.8317 55.5356 99.7037C55.5356 103.576 52.7596 108.28 49.3356 110.212C45.9116 112.144 43.1396 110.612 43.1396 106.78C43.1396 102.948 45.9236 98.2477 49.3436 96.2717Z" fill="#27DEBF" />
            <path d="M39.1078 142.928L38.8038 142.752C38.5482 142.585 38.3353 142.361 38.1824 142.097C38.0295 141.833 37.9409 141.537 37.9238 141.232V122.808C37.9409 122.503 38.0295 122.206 38.1823 121.941C38.3352 121.676 38.5481 121.451 38.8038 121.284L98.7678 86.6319C99.0399 86.4938 99.3407 86.4219 99.6458 86.4219C99.9509 86.4219 100.252 86.4938 100.524 86.6319L100.828 86.8079C101.084 86.9744 101.298 87.1993 101.451 87.4642C101.604 87.7291 101.692 88.0264 101.708 88.3319V106.756C101.692 107.061 101.603 107.357 101.451 107.622C101.298 107.886 101.084 108.11 100.828 108.276L40.8678 142.928C40.595 143.066 40.2936 143.138 39.9878 143.138C39.6821 143.138 39.3806 143.066 39.1078 142.928Z" fill="#EBEBEB" />
            <path d="M101.7 88.188C101.64 87.732 101.272 87.568 100.828 87.82L40.8636 122.476C40.6061 122.639 40.393 122.863 40.2436 123.128L38.1836 121.928C38.3352 121.669 38.5499 121.453 38.8076 121.3L98.7676 86.648C99.0397 86.51 99.3405 86.438 99.6456 86.438C99.9507 86.438 100.252 86.51 100.524 86.648L100.828 86.824C101.062 86.9764 101.26 87.1768 101.411 87.412C101.561 87.6472 101.66 87.9118 101.7 88.188Z" fill="#FAFAFA" />
            <path d="M40.7156 142.996C40.4594 143.099 40.1842 143.146 39.9083 143.134C39.6325 143.122 39.3623 143.052 39.1156 142.928L38.8036 142.752C38.5491 142.584 38.3377 142.358 38.1862 142.094C38.0347 141.829 37.9475 141.532 37.9316 141.228V122.808C37.9404 122.502 38.0286 122.205 38.1876 121.944L40.2476 123.144C40.0912 123.408 40.0019 123.706 39.9876 124.012V142.44C39.9836 142.924 40.3036 143.16 40.7156 142.996Z" fill="#E0E0E0" />
            <path d="M49.3436 121.24C52.7636 119.264 55.5356 120.8 55.5356 124.672C55.5356 128.544 52.7596 133.248 49.3356 135.18C45.9116 137.112 43.1396 135.58 43.1396 131.748C43.1396 127.916 45.9236 123.212 49.3436 121.24Z" fill="#27DEBF" />
            <path d="M39.1078 76.2082L38.8038 76.0322C38.5481 75.8648 38.3352 75.6397 38.1823 75.375C38.0295 75.1103 37.9409 74.8134 37.9238 74.5082V56.1002C37.9409 55.7956 38.0295 55.4992 38.1824 55.2352C38.3353 54.9711 38.5482 54.7467 38.8038 54.5802L98.7678 19.9122C99.0399 19.7741 99.3407 19.7021 99.6458 19.7021C99.9509 19.7021 100.252 19.7741 100.524 19.9122L100.828 20.0882C101.084 20.254 101.298 20.4782 101.451 20.7424C101.603 21.0066 101.692 21.3033 101.708 21.6082V40.0322C101.692 40.3376 101.604 40.6349 101.451 40.8997C101.298 41.1646 101.084 41.3895 100.828 41.5562L40.8678 76.2082C40.595 76.3463 40.2936 76.4183 39.9878 76.4183C39.6821 76.4183 39.3806 76.3463 39.1078 76.2082Z" fill="#EBEBEB" />
            <path d="M101.7 21.468C101.64 21.012 101.272 20.848 100.828 21.1L40.8636 55.756C40.6043 55.9164 40.3907 56.1409 40.2436 56.408L38.1836 55.208C38.331 54.9388 38.5461 54.7127 38.8076 54.552L98.7676 19.9C99.0397 19.7619 99.3405 19.6899 99.6456 19.6899C99.9507 19.6899 100.252 19.7619 100.524 19.9L100.828 20.076C101.065 20.2313 101.266 20.4362 101.416 20.6765C101.567 20.9167 101.663 21.1868 101.7 21.468Z" fill="#FAFAFA" />
            <path d="M40.7157 76.2759C40.4595 76.3786 40.1843 76.4257 39.9084 76.414C39.6326 76.4022 39.3624 76.332 39.1157 76.2079L38.8037 76.0319C38.5474 75.8636 38.3345 75.637 38.1823 75.3708C38.0301 75.1046 37.9429 74.8062 37.9277 74.4999V56.0999C37.9363 55.7933 38.0245 55.4942 38.1837 55.2319L40.2437 56.4319C40.0867 56.6953 39.9974 56.9936 39.9837 57.2999V75.6999C39.9837 76.2039 40.3037 76.4359 40.7157 76.2759Z" fill="#E0E0E0" />
            <path d="M49.3436 54.5199C52.7636 52.5439 55.5356 54.0799 55.5356 57.9479C55.5356 61.8159 52.7596 66.5239 49.3356 68.4599C45.9116 70.3959 43.1396 68.8599 43.1396 65.0279C43.1396 61.1959 45.9236 56.4999 49.3436 54.5199Z" fill="#27DEBF" />
            <path d="M43.3236 99.5999L43.0197 99.4239C42.7632 99.2573 42.5498 99.0323 42.3969 98.7675C42.244 98.5026 42.1558 98.2054 42.1396 97.8999V79.4759C42.1565 79.1707 42.2449 78.8737 42.3977 78.6089C42.5506 78.3442 42.7637 78.1191 43.0197 77.9519L102.98 43.2999C103.252 43.1618 103.554 43.0898 103.86 43.0898C104.165 43.0898 104.467 43.1618 104.74 43.2999L105.044 43.4719C105.299 43.6396 105.512 43.8647 105.665 44.1294C105.818 44.394 105.906 44.6908 105.924 44.9959V63.4199C105.907 63.7251 105.818 64.022 105.665 64.2867C105.512 64.5514 105.299 64.7766 105.044 64.9439L45.0796 99.5999C44.8076 99.738 44.5068 99.81 44.2016 99.81C43.8965 99.81 43.5957 99.738 43.3236 99.5999Z" fill="#37474F" />
            <path d="M105.915 44.8599C105.855 44.4039 105.487 44.2359 105.043 44.4919L45.0794 79.1479C44.8199 79.3093 44.6052 79.5336 44.4554 79.7999L42.3994 78.5999C42.5463 78.3306 42.7598 78.1034 43.0194 77.9399L102.979 43.2999C103.252 43.1618 103.554 43.0898 103.859 43.0898C104.165 43.0898 104.467 43.1618 104.739 43.2999L105.043 43.4719C105.28 43.6272 105.48 43.8316 105.63 44.071C105.781 44.3105 105.878 44.5796 105.915 44.8599Z" fill="#455A64" />
            <path d="M44.9315 99.6678C44.6751 99.77 44.3999 99.8168 44.1242 99.8051C43.8484 99.7933 43.5783 99.7234 43.3315 99.5998L43.0235 99.4238C42.7682 99.2562 42.556 99.031 42.4038 98.7662C42.2516 98.5015 42.1638 98.2047 42.1475 97.8998V79.4758C42.1581 79.1702 42.2477 78.8726 42.4075 78.6118L44.4635 79.8118C44.3077 80.0741 44.2197 80.371 44.2075 80.6758V99.1038C44.1995 99.5918 44.4995 99.8278 44.9315 99.6678Z" fill="#263238" />
            <path d="M53.5595 77.9079C56.9795 75.9359 59.7515 77.4719 59.7515 81.3399C59.7515 85.2079 56.9755 89.9159 53.5515 91.8519C50.1275 93.7879 47.3515 92.2519 47.3555 88.4199C47.3595 84.5879 50.1395 79.8839 53.5595 77.9079Z" fill="#F5F5F5" />
            <path d="M53.6996 89.04C53.5914 89.1221 53.4609 89.1695 53.3252 89.1759C53.1895 89.1824 53.0551 89.1475 52.9396 89.076L50.2196 87.02C49.8556 86.744 49.8996 85.988 50.3196 85.324C50.7396 84.66 51.3756 84.348 51.7356 84.624L53.6316 86.06L58.8996 74.744C59.2276 74.036 59.8556 73.544 60.3036 73.684C60.7516 73.824 60.8476 74.484 60.5156 75.18L54.4996 88.1C54.3332 88.4717 54.0657 88.7891 53.7276 89.016L53.6996 89.04Z" fill="#27DEBF" />
            <path d="M105.908 72.2199L104.168 71.2159C103.412 70.7839 102.488 71.2159 102 71.5119L100.9 70.8999C99.5842 70.0999 97.7522 71.7679 97.3442 72.1639L96.8722 71.8959C95.4762 71.0559 92.9362 73.3079 92.9362 73.3079V64.8999L94.0402 64.3679L92.2842 63.3519C92.0326 63.2403 91.756 63.1966 91.4823 63.2254C91.2085 63.2542 90.9471 63.3544 90.7242 63.5159C90.0744 63.939 89.5336 64.5094 89.1458 65.1808C88.758 65.8522 88.534 66.6056 88.4922 67.3799V86.8079C86.8922 85.6079 85.1802 84.8879 83.6922 85.1559C82.5068 85.433 81.4697 86.1472 80.7882 87.1559C80.0522 88.4599 81.2682 89.2759 82.8362 91.0679C84.4042 92.8599 85.7642 94.9879 87.1802 96.3079C87.8791 97.016 88.7092 97.5812 89.6242 97.9719V100.5L91.3442 101.496L102.736 92.9319V90.3999C102.736 90.3999 104.36 83.9119 104.88 81.1999C105.38 78.5919 105.84 74.4279 104.98 72.2679L105.912 72.2039L105.908 72.2199Z" fill="#27DEBF" />
            <path opacity="0.4" d="M105.908 72.2199L104.168 71.2159C103.412 70.7839 102.488 71.2159 102 71.5119L100.9 70.8999C99.5842 70.0999 97.7522 71.7679 97.3442 72.1639L96.8722 71.8959C95.4762 71.0559 92.9362 73.3079 92.9362 73.3079V64.8999L94.0402 64.3679L92.2842 63.3519C92.0326 63.2403 91.756 63.1966 91.4823 63.2254C91.2085 63.2542 90.9471 63.3544 90.7242 63.5159C90.0744 63.939 89.5336 64.5094 89.1458 65.1808C88.758 65.8522 88.534 66.6056 88.4922 67.3799V86.8079C86.8922 85.6079 85.1802 84.8879 83.6922 85.1559C82.5068 85.433 81.4697 86.1472 80.7882 87.1559C80.0522 88.4599 81.2682 89.2759 82.8362 91.0679C84.4042 92.8599 85.7642 94.9879 87.1802 96.3079C87.8791 97.016 88.7092 97.5812 89.6242 97.9719V100.5L91.3442 101.496L102.736 92.9319V90.3999C102.736 90.3999 104.36 83.9119 104.88 81.1999C105.38 78.5919 105.84 74.4279 104.98 72.2679L105.912 72.2039L105.908 72.2199Z" fill="white" />
            <path d="M93.0641 94.548L84.4481 89.576L80.6761 87.396C80.1961 88.572 81.3601 89.396 82.8321 91.08C84.3881 92.86 85.7601 95 87.1761 96.32C87.875 97.0281 88.7051 97.5933 89.6201 97.984V100.5L91.3401 101.496L93.8561 99.604C93.5321 97.468 93.0641 94.548 93.0641 94.548Z" fill="#27DEBF" />
            <path d="M90.2161 68.3837L88.4961 67.3877V87.7677L90.2161 87.8117V68.3837Z" fill="#27DEBF" />
            <path d="M103.732 73.5278L105.068 72.5438C105.036 72.4558 105.012 72.3678 104.976 72.2838L105.464 72.2518L104.392 72.2038L102.604 71.2158C102.395 71.2996 102.193 71.4 102 71.5158L102.56 71.8398L103.732 73.5278Z" fill="#27DEBF" />
            <path d="M98.9999 73.2358L100.252 72.2838L98.5239 71.2358C98.1068 71.5038 97.7169 71.8119 97.3599 72.1558L98.5599 72.8518L98.9999 73.2358Z" fill="#27DEBF" />
            <path d="M96.3839 73.1439L94.6719 72.1439L94.6799 72.1279V74.2919L96.6719 73.5639L96.3839 73.1439Z" fill="#27DEBF" />
            <g opacity="0.15">
                <path d="M93.0641 94.5475L84.4481 89.5755L80.6761 87.3955C80.1961 88.5715 81.3601 89.3955 82.8321 91.0795C84.3881 92.8595 85.7601 94.9995 87.1761 96.3195C87.875 97.0276 88.7051 97.5928 89.6201 97.9835V100.5L91.3401 101.496L93.8561 99.6035C93.5321 97.4675 93.0641 94.5475 93.0641 94.5475Z" fill="black" />
                <path d="M90.2161 68.3837L88.4961 67.3877V87.7677L90.2161 87.8117V68.3837Z" fill="black" />
                <path d="M103.732 73.5278L105.068 72.5438C105.036 72.4558 105.012 72.3678 104.976 72.2838L105.464 72.2518L104.392 72.2038L102.604 71.2158C102.395 71.2996 102.193 71.4 102 71.5158L102.56 71.8398L103.732 73.5278Z" fill="black" />
                <path d="M98.9999 73.2358L100.252 72.2838L98.5239 71.2358C98.1068 71.5038 97.7169 71.8119 97.3599 72.1558L98.5599 72.8518L98.9999 73.2358Z" fill="black" />
                <path d="M96.3839 73.1439L94.6719 72.1439L94.6799 72.1279V74.2919L96.6719 73.5639L96.3839 73.1439Z" fill="black" />
            </g>
            <path d="M91.3403 101.512V98.9801C90.4256 98.5838 89.5968 98.0132 88.9003 97.3001C87.4843 95.9801 86.1003 93.8361 84.5563 92.0601C83.0123 90.2841 81.7563 89.4481 82.5083 88.1481C83.1898 87.1394 84.2269 86.4251 85.4123 86.1481C86.9083 85.8801 88.6123 86.6121 90.2123 87.8001V68.3841C90.2537 67.6102 90.477 66.8569 90.8641 66.1856C91.2513 65.5142 91.7913 64.9436 92.4403 64.5201C93.6723 63.8081 94.6723 64.3841 94.6723 65.8081V74.2921C94.6723 74.2921 97.7483 71.5641 98.9883 73.2361C98.9883 73.2361 102.012 70.0721 103.388 72.7441C103.388 72.7441 105.604 70.9561 106.588 73.0321C107.572 75.1081 107.108 79.5001 106.588 82.2121C106.068 84.9241 104.44 91.4121 104.44 91.4121V93.9441L91.3403 101.512Z" fill="#27DEBF" />
            <path d="M94.68 77.9357C94.68 77.9357 94.96 74.0637 96.384 73.1357H96.332C95.7427 73.4534 95.189 73.8329 94.68 74.2677V74.1957C94.6746 74.207 94.6719 74.2193 94.6719 74.2317C94.6719 74.2442 94.6746 74.2565 94.68 74.2677V77.9357Z" fill="#455A64" />
            <path d="M99 73.2359V76.7799C99 76.7799 99.4 73.1359 100.2 72.2559C99.7653 72.5375 99.3629 72.8662 99 73.2359Z" fill="#455A64" />
            <path d="M103.384 72.7439V76.2999C103.384 76.2999 103.6 72.7199 104.372 72.1919C104.018 72.3267 103.685 72.5126 103.384 72.7439Z" fill="#455A64" />
            <path d="M90.7159 61.076C90.4359 61.236 90.2119 61.144 90.2119 60.864V58.064C90.2267 57.9003 90.2801 57.7425 90.3677 57.6034C90.4554 57.4644 90.5747 57.3481 90.7159 57.264C90.9919 57.104 91.2159 57.2 91.2159 57.476V60.276C91.2018 60.4394 91.1492 60.5972 91.0623 60.7362C90.9753 60.8753 90.8566 60.9918 90.7159 61.076Z" fill="#EBEBEB" />
            <path d="M86.5001 65.5117C86.3041 65.8477 85.9881 66.0317 85.7921 65.9117L84.1641 64.9717C83.9681 64.8597 83.9681 64.4917 84.1641 64.1717C84.3601 63.8317 84.6761 63.6477 84.8721 63.7717L86.5001 64.7117C86.7121 64.8037 86.7121 65.1717 86.5001 65.5117Z" fill="#EBEBEB" />
            <path d="M84.7762 71.3237V71.4037C84.7591 71.5775 84.7013 71.7449 84.6075 71.8922C84.5137 72.0396 84.3864 72.1627 84.2362 72.2517L82.0122 73.5357C81.7162 73.7077 81.4722 73.6077 81.4722 73.3077V73.2277C81.4902 73.0542 81.5484 72.8872 81.6421 72.74C81.7358 72.5928 81.8625 72.4695 82.0122 72.3797L84.2362 71.0957C84.5362 70.9237 84.7762 71.0237 84.7762 71.3237Z" fill="#EBEBEB" />
            <path d="M96.6519 64.5477V64.4677C96.6683 64.2931 96.7258 64.125 96.8197 63.9769C96.9135 63.8288 97.0411 63.7051 97.1919 63.6157L99.4159 62.3317C99.7119 62.1597 99.9559 62.2637 99.9559 62.5597V62.6397C99.9378 62.8132 99.8797 62.9802 99.7859 63.1274C99.6922 63.2746 99.5655 63.3979 99.4159 63.4877L97.1919 64.7717C96.8999 64.9437 96.6519 64.8437 96.6519 64.5477Z" fill="#EBEBEB" />
            <path d="M94.8999 60.6599C94.7039 60.5479 94.7039 60.1839 94.8999 59.8599L96.5279 57.0599C96.7239 56.7199 97.0399 56.5359 97.2359 56.6599C97.4319 56.7759 97.4319 57.1399 97.2359 57.4799L95.6079 60.2799C95.4279 60.5919 95.1079 60.7759 94.8999 60.6599Z" fill="#EBEBEB" />
            <path d="M158.188 126.588V126.132C158.008 126.664 157.176 128.964 155.916 130.132C154.912 131.048 153.54 132.104 153.192 133.124C152.844 134.144 155.1 134.544 156.036 134.38C157.128 134.184 158.536 133.58 159.304 132.624C159.844 131.96 160.188 131.176 160.572 130.652C160.956 130.128 161.844 129.552 162.056 128.948C162.168 128.616 162.032 127.888 161.872 127.276C161.712 126.664 161.564 126.096 161.372 126.148V126.5C160.916 126.856 160.351 127.046 159.772 127.036C159.3 127.036 158.184 126.94 158.188 126.588Z" fill="#263238" />
            <path d="M155.004 130.92C155.316 130.644 155.632 130.372 155.916 130.12C156.796 129.32 157.468 127.952 157.856 127.02C158.075 126.488 158.188 125.919 158.188 125.344V123.532H161.388V126.484V126.564C161.236 127.224 159.788 128.964 158.944 130.132C158.388 130.9 157.344 131.876 156.068 132.048C154.84 132.208 154.228 131.828 155.004 130.92Z" fill="#FFA8A7" />
            <path d="M145.776 123.448V122.992C145.596 123.528 144.76 125.828 143.5 126.992C142.496 127.908 141.124 128.968 140.776 129.988C140.428 131.008 142.684 131.408 143.62 131.24C144.712 131.048 146.12 130.44 146.888 129.488C147.428 128.824 147.772 128.036 148.156 127.516C148.54 126.996 149.432 126.412 149.64 125.808C149.752 125.476 149.616 124.752 149.456 124.14C149.296 123.528 149.148 122.94 148.956 123.008V123.356C148.501 123.715 147.936 123.905 147.356 123.896C146.9 123.896 145.772 123.804 145.776 123.448Z" fill="#263238" />
            <path d="M142.592 127.784C142.9 127.504 143.216 127.236 143.5 126.984C144.38 126.184 145.052 124.812 145.44 123.88C145.661 123.35 145.775 122.782 145.776 122.208V120.392H148.956V123.344V123.424C148.804 124.088 147.356 125.844 146.512 126.992C145.956 127.76 144.912 128.74 143.636 128.908C142.424 129.068 141.812 128.692 142.592 127.784Z" fill="#FFA8A7" />
            <path d="M160.74 77.1198C163.208 82.5078 160.648 101.496 160.648 101.496C160.724 102.368 161.724 104.092 162.096 107.94C162.536 112.488 161.404 123.908 161.404 123.908C160.921 124.187 160.377 124.345 159.82 124.367C159.262 124.389 158.708 124.276 158.204 124.036C158.204 124.036 155.172 106.836 154.604 102.316C154.1 98.3678 153.132 89.6998 153.132 89.6998L150.416 102.3C150.812 103.572 151.1 104.875 151.276 106.196C151.536 108.856 148.956 121.24 148.956 121.24C148.956 121.24 147.86 121.64 145.776 121.336C145.776 121.336 144.864 104.9 144.624 101.696C144.224 96.3158 145.648 79.5238 146.624 76.3398L160.74 77.1198Z" fill="#37474F" />
            <path d="M153.132 89.6999L152.332 84.6919C152.332 84.6919 148.94 83.9919 147.404 82.5439C148.462 83.7878 149.848 84.7106 151.404 85.2079L152.304 89.0639L150.732 100.732L153.132 89.6999Z" fill="#263238" />
            <path d="M151.12 58.2477C150.346 58.1812 149.568 58.313 148.859 58.6308C148.15 58.9485 147.533 59.4417 147.068 60.0637C145.796 61.7277 140.92 68.0637 140.92 68.0637C140.92 68.0637 133.72 64.4637 133.38 64.0397C133.04 63.6157 133.228 62.7517 133.324 62.2797C133.42 61.8077 133.584 60.7477 133.688 60.4517C133.872 59.9357 133.108 60.0757 132.656 60.5597C132.204 61.0437 132.008 61.9877 131.78 62.1597C131.552 62.3317 129.848 60.3557 129.016 59.4077C128.184 58.4597 127.816 57.8077 127.244 58.1877C126.844 58.4677 127.1 59.0317 127.696 59.8437C127.973 60.1879 128.23 60.5486 128.464 60.9237C128.532 61.0677 128.316 61.1837 128.228 61.2637C128.068 61.4077 127.78 61.6637 127.76 61.8677C127.764 61.9909 127.756 62.1141 127.736 62.2357C127.66 62.5077 127.336 62.6357 127.232 62.8997C127.164 63.0877 127.232 63.2997 127.2 63.5037C127.176 63.6317 127.104 63.7477 127.08 63.8797C127.072 64.0082 127.091 64.1369 127.138 64.2571C127.184 64.3772 127.256 64.4859 127.348 64.5757C127.928 65.2717 128.724 65.5357 129.488 65.9597C130.124 66.3117 130.78 66.4637 131.512 66.7877C132.244 67.1117 140.732 72.9997 142.076 72.2557C143.42 71.5117 150.356 63.4557 150.356 63.4557C152.356 60.1317 151.12 58.2477 151.12 58.2477Z" fill="#FFA8A7" />
            <path d="M156.388 58.3958C159.548 58.6158 159.772 60.1438 160.564 61.8118C161.144 63.1004 161.197 64.5646 160.712 65.8918L158.5 72.2678C158.5 72.2678 159.532 74.7638 160.732 77.1198C155.2 80.2078 148.292 78.5838 146.628 76.3198C146.556 75.8398 146.984 72.1038 147.248 69.6638C146.272 67.2198 146.016 65.6638 146.428 64.0638C147.192 61.0638 150.152 58.1438 151.464 58.2478L156.388 58.3958Z" fill="#F5F5F5" />
            <path d="M153.344 55.7758H154.572C155.422 55.7758 156.264 55.6082 157.049 55.2827C157.834 54.9571 158.548 54.48 159.148 53.8785C159.749 53.2771 160.225 52.5631 160.55 51.7775C160.874 50.9918 161.041 50.1499 161.04 49.2998C161.039 47.5755 160.353 45.9222 159.134 44.7034C157.914 43.4845 156.26 42.7998 154.536 42.7998H153.38C151.659 42.8051 150.011 43.4923 148.797 44.7107C147.582 45.9291 146.9 47.5794 146.9 49.2998C146.897 51.0128 147.574 52.657 148.782 53.8712C149.99 55.0855 151.631 55.7705 153.344 55.7758Z" fill="#263238" />
            <path d="M164.5 59.1837C163.487 59.1826 162.516 58.7798 161.8 58.0637C161.084 57.3475 160.681 56.3765 160.68 55.3637V47.1797C161.182 47.1797 161.679 47.2786 162.142 47.4708C162.606 47.6631 163.027 47.9448 163.382 48.3C163.737 48.6551 164.018 49.0767 164.21 49.5406C164.402 50.0045 164.5 50.5017 164.5 51.0037V59.1837Z" fill="#263238" />
            <path d="M161.38 45.7319L160.424 47.2679C160.521 47.3361 160.632 47.3837 160.748 47.4079C160.865 47.4322 160.985 47.4325 161.102 47.409C161.218 47.3854 161.329 47.3384 161.427 47.2709C161.525 47.2033 161.608 47.1166 161.672 47.0159C161.797 46.8053 161.836 46.5546 161.782 46.3156C161.728 46.0767 161.584 45.8678 161.38 45.7319Z" fill="#263238" />
            <path d="M149.204 45.6719C148.456 46.1119 147.532 47.9919 147.624 51.9359C147.7 55.2799 148.784 56.1039 149.328 56.3359C149.872 56.5679 150.928 56.4279 151.94 56.2559V58.2559C151.94 58.2559 150.532 59.8919 150.62 60.8119C150.708 61.7319 152.656 61.9479 153.976 61.0519C154.83 60.4348 155.564 59.666 156.14 58.7839V53.9519C156.247 54.0834 156.379 54.1923 156.528 54.2723C156.678 54.3523 156.841 54.4018 157.01 54.4178C157.179 54.4338 157.349 54.416 157.511 54.3656C157.672 54.3151 157.822 54.233 157.952 54.1239C159.08 53.3759 159.124 51.5239 158.468 50.8599C157.812 50.1959 156.4 50.4079 156.14 51.3239C156.14 51.3239 154.512 51.3839 152.348 50.1959C151.505 49.7421 150.781 49.0959 150.235 48.3099C149.689 47.5238 149.336 46.62 149.204 45.6719Z" fill="#FFA8A7" />
            <path d="M151.728 51.0959C151.724 51.1787 151.745 51.2607 151.788 51.3317C151.83 51.4027 151.893 51.4595 151.968 51.4949C152.043 51.5303 152.127 51.5428 152.209 51.5307C152.291 51.5187 152.367 51.4827 152.429 51.4272C152.49 51.3718 152.534 51.2994 152.554 51.2191C152.575 51.1388 152.571 51.0543 152.544 50.9761C152.516 50.8979 152.466 50.8296 152.4 50.7798C152.334 50.7299 152.255 50.7007 152.172 50.6959C152.061 50.6935 151.953 50.7335 151.871 50.8079C151.788 50.8822 151.737 50.9852 151.728 51.0959Z" fill="#263238" />
            <path d="M151.244 53.9316L150.268 54.2396C150.286 54.3048 150.316 54.3658 150.357 54.419C150.399 54.4722 150.451 54.5165 150.51 54.5493C150.569 54.5822 150.633 54.6028 150.701 54.6101C150.768 54.6174 150.835 54.6111 150.9 54.5916C151.032 54.5476 151.141 54.4542 151.205 54.3311C151.269 54.208 151.283 54.0648 151.244 53.9316Z" fill="#F28F8F" />
            <path d="M148.284 49.8079L149.132 49.2159C149.096 49.1589 149.049 49.1098 148.994 49.0715C148.939 49.0331 148.877 49.0064 148.811 48.993C148.745 48.9795 148.677 48.9796 148.611 48.9931C148.545 49.0067 148.483 49.0335 148.428 49.0719C148.314 49.1527 148.236 49.2741 148.209 49.4111C148.182 49.5481 148.209 49.6902 148.284 49.8079Z" fill="#263238" />
            <path d="M148.5 50.792C148.495 50.8483 148.502 50.905 148.52 50.9586C148.538 51.0122 148.567 51.0617 148.604 51.1042C148.641 51.1467 148.686 51.1813 148.737 51.206C148.788 51.2307 148.843 51.245 148.9 51.248C148.986 51.2551 149.073 51.2361 149.149 51.1936C149.224 51.1511 149.286 51.0869 149.325 51.0094C149.364 50.9318 149.379 50.8444 149.368 50.7582C149.357 50.6721 149.32 50.5912 149.263 50.5259C149.206 50.4607 149.13 50.414 149.046 50.3918C148.962 50.3697 148.874 50.3732 148.792 50.4017C148.71 50.4303 148.638 50.4827 148.586 50.5523C148.534 50.6218 148.504 50.7053 148.5 50.792Z" fill="#263238" />
            <path d="M150.532 50.5518L150.464 53.0838L149.132 52.6718L150.532 50.5518Z" fill="#F28F8F" />
            <path d="M151.94 56.2637C153.016 56.1237 155.228 55.4877 155.572 54.6157C155.433 55.058 155.162 55.4473 154.796 55.7317C154.136 56.2957 151.94 56.9317 151.94 56.9317V56.2637Z" fill="#F28F8F" />
            <path opacity="0.1" d="M154.972 77.7198C155.405 77.6199 155.814 77.4346 156.175 77.1745C156.536 76.9144 156.841 76.5848 157.073 76.2051C157.305 75.8254 157.458 75.4033 157.525 74.9634C157.591 74.5236 157.569 74.0749 157.46 73.6438C157.396 73.3809 157.245 73.1473 157.031 72.981C156.818 72.8147 156.554 72.7255 156.284 72.7278C156.639 72.4925 156.944 72.1894 157.181 71.8359C157.419 71.4824 157.584 71.0854 157.668 70.6678L158.34 66.6678C158.421 66.0162 158.362 65.3548 158.168 64.7278L160.392 62.1558L160.792 62.4398C161.129 63.5695 161.097 64.7777 160.7 65.8878L158.5 72.2678C158.5 72.2678 159.532 74.7638 160.732 77.1198C158.933 78.09 156.947 78.6634 154.908 78.8014C152.869 78.9394 150.825 78.6388 148.912 77.9198C150.926 78.2356 152.982 78.1677 154.972 77.7198Z" fill="black" />
            <path d="M166.4 74.272C163.528 62.532 161.404 58.616 157.916 58.672C156.896 59.872 157.18 63.568 158.788 65.8C158.788 65.8 162.388 73.372 162.952 76.064C163.452 78.428 164.024 81.404 163.988 83.436C163.977 83.7965 163.835 84.1406 163.588 84.404C162.932 85.116 162.932 85.688 162.504 86.472C162.172 87.088 161.808 87.32 161.912 87.456C162.049 87.6023 162.236 87.6917 162.436 87.7065C162.636 87.7213 162.835 87.6605 162.992 87.536C163.294 87.3076 163.564 87.0383 163.792 86.736C163.679 87.4893 163.461 88.2231 163.144 88.916C162.408 90.576 162.588 91.424 164.828 90.832C166.232 90.46 167.116 89.292 167.328 85.912C167.429 84.6691 167.442 83.4206 167.364 82.176C167.08 78.976 166.864 76.16 166.4 74.272Z" fill="#FFA8A7" />
            <path d="M137.904 107.836C138.212 96.4081 137.296 93.6721 134.256 91.5801L133.076 98.2001C133.076 98.2001 134.46 106 134.312 108.6C134.184 110.884 133.96 113.74 133.42 115.584C133.325 115.916 133.11 116.202 132.816 116.384C132.04 116.868 131.728 117.364 131.144 117.984C130.688 118.464 130.3 118.58 130.344 118.732C130.433 118.9 130.581 119.028 130.759 119.092C130.938 119.156 131.134 119.15 131.308 119.076C131.695 118.92 132.063 118.72 132.404 118.48C132.136 119.18 131.757 119.833 131.28 120.412C130.192 121.744 130.144 122.564 132.34 122.584C133.712 122.584 134.808 121.748 135.844 118.72C136.246 117.61 136.568 116.472 136.808 115.316C137.352 112.296 137.856 109.676 137.904 107.836Z" fill="#B16668" />
            <path d="M131.7 90.712C133.152 90.5 135.124 90.804 136.4 93.084C137.676 95.364 138 99.288 138.132 103.188C138.132 103.188 135.6 105.304 132.56 104.264L131.7 90.712Z" fill="#27DEBF" />
            <path opacity="0.1" d="M131.7 90.712C133.152 90.5 135.124 90.804 136.4 93.084C137.676 95.364 138 99.288 138.132 103.188C138.132 103.188 135.6 105.304 132.56 104.264L131.7 90.712Z" fill="black" />
            <path d="M135.968 153.556C136.196 153.644 136.256 154.084 136.292 154.956C136.316 155.616 136.46 156.772 135.684 157C134.908 157.228 133.776 157.112 133.284 156.492C132.664 155.692 132.248 155.028 131.364 154.092C130.596 153.268 129.764 152.492 129.46 151.348C129.18 150.304 129.412 149.852 130.124 149.548C131.1 149.148 132.64 150.496 133.404 150.708C134.06 150.9 135.736 153.468 135.968 153.556Z" fill="#263238" />
            <path d="M135.968 152.748V153.82C135.565 154.077 135.1 154.22 134.622 154.233C134.144 154.246 133.672 154.128 133.256 153.892C133.212 153.788 132.976 152.36 132.976 152.36L135.968 152.748Z" fill="#B16668" />
            <path d="M127.3 159.736C127.487 160.31 127.645 160.894 127.772 161.484C127.904 162.132 128.092 163.216 127.868 163.704C127.644 164.192 126.756 164.504 125.38 164.504C124.492 164.504 123.108 165.1 122.16 165.628C121.212 166.156 119.14 166.392 118.104 166.272C116.884 166.128 115.62 165.42 115.42 164.912C115.22 164.404 115.484 163.884 118.364 162.656C118.392 162.656 122.364 161.352 123.756 159.816L127.3 159.736Z" fill="#263238" />
            <path d="M127.3 158.576C127.3 158.576 127.3 159.376 127.3 159.828C127.3 160.28 126.768 160.96 125.376 160.908C124.4 160.876 123.72 160.612 123.776 159.808V158.648L127.3 158.576Z" fill="#B16668" />
            <path d="M136.664 138.52C136.427 136.617 136.004 134.741 135.4 132.92C135.4 132.92 135.78 128.12 135.964 123.532C136.164 118.6 135.964 112.4 136.048 111.168C136.048 111.168 120.128 107.968 119.844 113.648C119.56 119.328 120.472 133.872 120.644 136.376C120.816 138.88 121.264 141.1 121.784 145.176C122.304 149.252 123.724 158.648 123.724 158.648C124.276 158.929 124.887 159.069 125.505 159.056C126.124 159.043 126.729 158.879 127.268 158.576C127.268 158.576 128.388 147.172 128.272 144.28C128.182 142.139 127.814 140.018 127.176 137.972C127.176 137.972 127.476 133.268 127.744 129.172C128.012 125.076 128.292 123.116 128.352 121.34L128.696 121.168C128.696 121.168 129.044 128.224 129.232 130.732C129.42 133.24 129.82 135.64 130.544 140.292C131.176 144.332 133.048 152.872 133.048 152.872C134.864 153.856 135.952 152.744 135.952 152.744C135.952 152.744 137.156 142.024 136.664 138.52Z" fill="#37474F" />
            <path d="M128.368 121.344C128.368 121.344 132.232 119.44 134.164 117.724C134.164 117.724 132.368 120.252 130.012 121.372C130.012 121.372 129.176 130.14 129.18 129.964L128.696 121.328L128.368 121.344Z" fill="#263238" />
            <path d="M124.932 88.9042L125.876 94.0882L130.556 93.7482L129.576 87.8682L124.932 88.9042Z" fill="#B16668" />
            <path d="M135.504 93.3682C135.04 91.9602 133.252 90.4242 131.464 90.7562C128.805 91.208 126.184 91.8619 123.624 92.7122C121.984 93.3002 120.492 94.7122 120.356 100.104C120.356 100.104 119.808 112.672 119.8 115.1C123.912 116.728 128.2 116.68 130.556 116.164C135.484 115.088 136.076 113.044 136.076 113.044C136.076 113.044 136.14 104.008 135.992 100.104C135.868 96.7882 135.824 94.3362 135.504 93.3682Z" fill="#27DEBF" />
            <path d="M121.32 82.5962C121.32 82.5962 120.12 84.4922 120.276 84.6722C120.432 84.8522 121.328 84.9962 121.328 84.9962L121.32 82.5962Z" fill="#B16668" />
            <path d="M124.616 77.3599C122.14 77.9399 121.448 79.0479 121.3 83.0079C121.148 87.144 121.6 88.4319 122.156 88.9759C122.528 89.3439 124.556 89.3759 125.588 89.0599C126.864 88.6599 129.748 87.5799 131.06 85.4599C132.604 82.9799 132.952 79.6599 131.208 78.3359C128.752 76.4999 125.624 77.1199 124.616 77.3599Z" fill="#B16668" />
            <path d="M124.984 89.1882C125.824 88.5122 125.748 88.0762 125.748 88.0762C125.148 88.6402 122.916 89.1202 122.148 88.9762C122.464 89.3002 123.916 89.3482 124.984 89.1882Z" fill="#9A4A4D" />
            <path d="M121.328 78.388C122.056 79.632 123.632 80.228 123.632 80.228C124.108 80.768 123.844 82.88 124.68 83.212C124.68 83.212 125.04 81.676 126.332 81.84C127.624 82.004 127.844 83.652 127.048 84.672C126.252 85.692 125.488 85.328 125.488 85.328C125.488 85.328 125.576 87.508 126.572 88.724C126.572 88.724 127.68 89.056 129.692 88.576C130.932 88.284 132.656 86.688 133.356 85.028C134.844 81.508 134.88 78.372 132.636 77.484C132.36 75.884 131.08 75.192 129.632 74.892C126.516 74.252 124.508 76.196 122.108 76.028C120.732 75.932 120.476 76.928 121.328 78.388Z" fill="#263238" />
            <path d="M132.688 78.0198L134.156 77.1078C134.035 76.9132 133.842 76.7745 133.619 76.7224C133.396 76.6702 133.161 76.7089 132.966 76.8298C132.772 76.9508 132.633 77.1441 132.581 77.3673C132.529 77.5904 132.567 77.8252 132.688 78.0198Z" fill="#263238" />
            <path d="M123.752 92.6919C123.752 92.6919 121.424 93.1239 120.208 94.9639C118.992 96.8039 113.728 103.404 113.728 103.404C113.728 103.404 106.16 99.6159 105.788 99.1639C105.416 98.7119 105.624 97.8079 105.728 97.3079C105.832 96.8079 106 95.7079 106.128 95.3879C106.32 94.8399 105.516 94.9879 105.04 95.4959C104.564 96.0039 104.356 96.9999 104.12 97.1719C103.884 97.3439 102.08 95.2719 101.208 94.2719C100.336 93.2719 99.9242 92.5719 99.3362 92.9839C98.9162 93.2839 99.1882 93.8759 99.8122 94.7319C100.106 95.0909 100.373 95.4705 100.612 95.8679C100.688 96.0199 100.456 96.1439 100.368 96.2239C100.196 96.3759 99.8962 96.6239 99.8722 96.8639C99.8849 96.9969 99.8849 97.1309 99.8722 97.2639C99.7882 97.5479 99.4362 97.6639 99.3362 97.9599C99.2642 98.1639 99.3362 98.3879 99.3042 98.5999C99.2762 98.7359 99.2042 98.8559 99.1762 98.9999C99.1685 99.1357 99.1898 99.2716 99.2388 99.3986C99.2877 99.5255 99.3632 99.6405 99.4602 99.7359C100.072 100.468 100.912 100.748 101.716 101.192C102.384 101.564 103.076 101.724 103.848 102.068C104.62 102.412 113.692 109.116 115.108 108.336C116.524 107.556 123.78 99.0759 123.78 99.0759C124.393 98.123 124.717 97.0128 124.712 95.8797C124.707 94.7467 124.374 93.6394 123.752 92.6919Z" fill="#B16668" />
            <path d="M124.564 92.4199C123.564 92.5319 122.164 92.3479 120.2 94.4519C118.624 96.2576 117.155 98.1541 115.8 100.132C116.058 101.171 116.611 102.114 117.391 102.846C118.172 103.579 119.147 104.072 120.2 104.264C120.2 104.264 123.632 100.748 124.6 98.8079C125.568 96.8679 125.74 93.7239 124.564 92.4199Z" fill="#27DEBF" />
            <path opacity="0.1" d="M124.564 92.4199C123.564 92.5319 122.164 92.3479 120.2 94.4519C118.624 96.2576 117.155 98.1541 115.8 100.132C116.058 101.171 116.611 102.114 117.391 102.846C118.172 103.579 119.147 104.072 120.2 104.264C120.2 104.264 123.632 100.748 124.6 98.8079C125.568 96.8679 125.74 93.7239 124.564 92.4199Z" fill="black" />
        </svg>
    )
}
