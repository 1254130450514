import React from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AssessmentQuestionRow } from "./row-question";
import { TAdditionalQuestion } from '../../../type';
import { useAssementData } from "../../../hooks/useCreateAssessment";
import { reorder } from "../../../../../../utils";
import { TChoiceQuestionType } from "../../../../../create_test/tab/question/type";

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    width: '100%'
});
const getMoveItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    background: isDragging ? "#F1E8FD" : "white",
    color: isDragging ? "#46485C" : "inherit",
    borderRadius: isDragging ? "8px" : "inherit",
    border: isDragging ? "1px solid #7357FF" : "inherit",
    boxShadow: isDragging ? "0px 4px 16px rgba(79, 117, 140, 0.24)" : "inherit",
    ...draggableStyle
});

type AssessmentQuestionsProps = {
    data: TAdditionalQuestion[],
    onChange: React.Dispatch<React.SetStateAction<TAdditionalQuestion[]>>
}
export const AssessmentQuestions: React.FC<AssessmentQuestionsProps> = ({
    data,
    onChange
}) => {
    const { setShowAddQuestion } = useAssementData();
    const [idSelectedForUpdate, setIdSelectedForUpdate] = React.useState<[string, TChoiceQuestionType]>();
    // Some trick for memorize onUpdateQuestion
    React.useEffect(() => {
        if(!idSelectedForUpdate) return;
        const [id, type] = idSelectedForUpdate;
        const idx = data.findIndex((d) => d.id === id);
        if(idx === -1) return;
        setShowAddQuestion(true, type, idx);
        setIdSelectedForUpdate(undefined);
    }, [idSelectedForUpdate]);
    // Drop && Drag
    const onDragEnd = React.useCallback((result: any) => {
        const { source, destination } = result;
        if (!result.destination) {
            return;
        }
        onChange(prev => {
            return reorder(
                prev,
                source.index,
                destination.index
            );
        })
    }, [onChange]);

    const onUpdateQuestion = React.useCallback((id: string, type: TChoiceQuestionType) => {
        setIdSelectedForUpdate([id, type]);
    }, []);

    const onDeleteQuestion = React.useCallback((id: string) => {
        onChange(prev => {
            return prev.filter((d) => d.id !== id);
        });
    }, [onChange]);

    const onCollapsed = React.useCallback((id: string) => {
        onChange(prev => {
            return prev.map((q) => {
                return q.id === id ? {
                    ...q,
                    isExpand: !q.isExpand
                }: q
            })
        });
    }, [onChange]);
    if(!data) return <div className="w-full"></div>;
    return <div className="w-full">
        <div className="bg-white rounded-[8px] mb-4">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {data.filter(d => d?.id).map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getMoveItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <AssessmentQuestionRow
                                                question={item}
                                                id={item.id}
                                                onDelete={onDeleteQuestion}
                                                onUpdate={onUpdateQuestion}
                                                onCollapsed={onCollapsed}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    </div>
}
