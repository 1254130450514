import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type TData = {
    _id: string,
    label: string,
    category: string,
    isActive: true,
    code?: string,
    order?: number,
    createdAt?: string,
    value?: string,
}
type CommonData = Record<string, TData[]>;

const initialState: CommonData = {};

const commonDataSlice = createSlice({
    name: "commonData",
    initialState,
    reducers: {
        setCommonData: (state: CommonData, action: PayloadAction<TData[]>) => {
            action.payload.forEach((data) => {
                if(!state[data.category]) {
                    state[data.category] = [];
                }
                state[data.category].push(data);
            })
        },
        removeCommonData: () => {
            return {};
        },
    },
});

export const { setCommonData, removeCommonData } = commonDataSlice.actions;

export default commonDataSlice.reducer;
