import React from "react";
import { useLocation } from "react-router-dom";

export const ScrollTop = () => {
    const { pathname } = useLocation();

    React.useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }
    , [pathname]);
    return null;
};
