import { Input, Space } from "antd";
import React from "react";
import { ICOutlineSendV2, ICSend } from "../../icons";
import TextArea from "antd/lib/input/TextArea";
import classNames from "classnames";
import i18next from "../../i18n";

type MessageInputProps = {
    isGenerating: boolean,
    onSend: (message: string) => void;
    inputValue: string;
    setInputValue: (value: string) => void;
}
export const MessageInput = React.forwardRef<HTMLTextAreaElement, MessageInputProps>(({
    inputValue,
    setInputValue,
    isGenerating,
    onSend
}, ref) => {
    const onSendWrapper = () => {
        if (!inputValue || isGenerating) return;
        onSend(inputValue);
        setInputValue('');
    }
    return <Space.Compact
        style={{ width: '100%' }}
        className="space-x-3 rounded-[30px] bg-white px-6 py-2 shadow-lg flex flex-row items-center"
    >
        <TextArea
            ref={ref}
            placeholder={`${i18next.t('assisstant.inputArea.placeholder')}`}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={e => {
                if (e.key === 'Enter' && !e.shiftKey && inputValue) {
                    onSendWrapper();
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
            autoSize={{ minRows: 1, maxRows: 6 }}
            className="border-none focus:ring-0 text-[#333] bg-transparent text-[14px]"
        />

        <div
            className={
                classNames(
                    "flex items-center justify-center rounded-[100px] bg-[#0d121c] text-white px-4 space-x-2 py-2",
                    isGenerating ? "cursor-not-allowed" : "cursor-pointer"
                )
            }
            onClick={onSendWrapper}
        >
            <ICOutlineSendV2 />
            <span className="text-[14px] font-bold">{i18next.t('assisstant.sendButton.label')}</span>
        </div>
    </Space.Compact>
})