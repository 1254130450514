import React from "react"
import { useCreateTestPageContext } from "../.."
import { SkillSection } from "./section"
import { ESkillAreaType, TQuestion, TSkillArea } from "./type"
import { SectionHeader } from './section-header';
import { ConfigQuestion } from './config-question';
import { FormInstance } from "antd/lib/form/Form";
import { FilterQuestions } from './filter-questions';
import { Modal, notification } from "antd"
import { HRSelect } from "../../../../components/form/select";
import { HRButton } from "../../../../components/button"
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { OpenAIOutlined } from "@ant-design/icons";
import i18next from "../../../../i18n";

type TConfigQuestionPage = {
    skillAreas: TSkillArea[],
    questionUsageForm: FormInstance<any>,
    questionUsage: number | number[] | undefined,
    setQuestionUsage: (value: number | number[] | undefined) => void,
    questionDuration: number | undefined,
    setQuestionDuration: (value: number | undefined) => void,
}
const ConfigQuestionPageContext = React.createContext<TConfigQuestionPage>({} as TConfigQuestionPage);
export const useConfigQuestionContextContext = () => React.useContext(ConfigQuestionPageContext);

export const QuestionTab: React.FC = () => {
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const {
        sampleSection,
        setSampleSection,
        skillAreas,
        setSkillAreas,
        onAddSkillArea,
        questionUsageForm,
        questionUsage,
        setQuestionUsage,
        questionDuration,
        setQuestionDuration,
    } = useCreateTestPageContext();
    const dispatch = useDispatch();
    const [showMoveModal, setShowMoveModal] = React.useState(false);
    const [groupQuestionSelected, setGroupQuestionSelected] = React.useState<any>(undefined);

    const onChangeSkillArea = (index: number, section: TSkillArea) => {
        setSkillAreas(skillAreas.map((skillArea, i) => i === index ? section : skillArea))
    };

    const [selectedMovedQuestions, setSelectedMovedQuestions] = React.useState<{
        indexSection: number,
        indexQuestion: number,
    } | undefined>();
    const sourceMove = React.useMemo(() => {
        if (!selectedMovedQuestions) return {};
        const { indexSection, indexQuestion } = selectedMovedQuestions;
        if (indexSection === 0) { // Sample
            return {
                fromGroupName: i18next.t('createTest.sampleQuestion'),
                questionNo: "No." + (indexQuestion + 1),
            }
        } else {
            return {
                fromGroupName: skillAreas[indexSection - 1]?.name,
                questionNo: "No." + (indexQuestion + 1),
            }
        }
    }, [selectedMovedQuestions, skillAreas]);

    const groupQuestions = React.useMemo(() => {
        if (!selectedMovedQuestions) return [];
        const { indexSection } = selectedMovedQuestions;
        if (indexSection === 0) {
            return skillAreas.map((d, index) => {
                return {
                    ...d,
                    label: d.name,
                    value: index + 1,
                }
            }).filter(d => d.type !== ESkillAreaType.ATTEAM);
        } else {
            const res = [];
            const skillAreasSelected = skillAreas[indexSection - 1];
            if (skillAreasSelected?.type !== ESkillAreaType.ATTEAM) {
                res.push({
                    label: i18next.t('createTest.sampleQuestion'),
                    value: 0
                });
            }
            skillAreas.forEach((d, index) => {
                if (index !== indexSection - 1 && d.type === skillAreasSelected?.type) {
                    res.push({
                        label: d.name,
                        value: index + 1
                    });
                }
            });
            return res;
        }
    }, [selectedMovedQuestions, skillAreas]);

    const doMoveQuestion = () => {
        if (!selectedMovedQuestions || groupQuestionSelected === undefined) return;
        const { indexSection, indexQuestion } = selectedMovedQuestions;
        const newGroupped = [
            sampleSection,
            ...skillAreas
        ];
        const questionMoved = newGroupped[indexSection].questions[indexQuestion];
        const newGrouppedAfterMove = newGroupped.map((d, i) => {
            if (i === indexSection) { // from
                return {
                    ...d,
                    questions: d.questions.filter((q, i) => i !== indexQuestion)
                }
            } else if (i === groupQuestionSelected) { // to
                return {
                    ...d,
                    questions: [
                        ...d.questions,
                        questionMoved
                    ]
                }
            } else {
                return d;
            }
        });
        setSampleSection(newGrouppedAfterMove[0]);
        setSkillAreas(newGrouppedAfterMove.slice(1));
    }
    const onMove = React.useCallback((
        indexSection: number,
        indexQuestion: number,
    ) => {
        setSelectedMovedQuestions({
            indexSection,
            indexQuestion,
        });
        setGroupQuestionSelected(undefined);
        setShowMoveModal(true);
    }, []);
    return <div className="bg-white px-2 lg:px-9 py-8 space-y-3">
        <FilterQuestions />
        <div className="pt-[30px]">
            <div className="flex flex-col gap-[16px]">
                <SectionHeader hasDescription={false} title={i18next.t('createTest.sampleQuestion')} hint={i18next.t('createTest.addAtLeastSampleQuestion', { t: '4' })} />
                <SkillSection data={sampleSection}
                    questionType="sample"
                    onChange={setSampleSection}
                    index={0}
                    onMove={onMove.bind(this, 0)} // Hard code that sample section is index 0
                />
            </div>
            <div className="flex flex-col gap-[16px] pt-[50px]">
                <SectionHeader title={i18next.t('createTest.skillArea')} hint={i18next.t('createTest.classifyQuestion')} />
                <div className="space-y-6">
                    {
                        skillAreas.map((skillArea, index) => {
                            const checkActiveWeight = skillArea?.questions?.some(q => (q?.weight as number) >= 0);
                            return <SkillSection
                                onChange={onChangeSkillArea.bind(this, index)}
                                data={skillArea}
                                questionType="group"
                                key={index}
                                index={index + 1}
                                checkActiveWeight={checkActiveWeight}
                                onMove={onMove.bind(this, index + 1)} // Hard code that sample section is index 0
                            />
                        })
                    }
                </div>
                {
                    skillAreas.length < 15 && <div
                        onClick={onAddSkillArea}
                        className="text-center px-[24px] py-[16px] border-[1px] border-dashed border-outline-info-med rounded-[4px] cursor-pointer text-body-bold text-info"
                    >
                        {i18next.t('createTest.addSkillArea')}
                    </div>
                }
            </div>
            <ConfigQuestionPageContext.Provider value={{
                skillAreas,
                questionUsageForm,
                questionUsage,
                setQuestionUsage,
                questionDuration,
                setQuestionDuration,
            }}>
                <ConfigQuestion />
            </ConfigQuestionPageContext.Provider>
        </div>
        <Modal
            width={375}
            centered
            onCancel={() => setShowMoveModal(false)}
            open={showMoveModal}
            title={
                <div className="grid grid-cols-1">
                    <span className="font-bold text-[18px]">{i18next.t('createTest.moveQuestion')}</span>
                    <span className="grid grid-cols-[auto_1fr] gap-[5px] text-[10px]">
                        <span className="text-[10px]">{sourceMove?.fromGroupName} {'>'}</span>
                        <span>{sourceMove?.questionNo}</span>
                    </span>
                </div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] gap-[16px] justify-end">
                    <span>
                        <HRButton
                            btnSize="sm"
                            btnType="secondary"
                            onClick={() => setShowMoveModal(false)}>
                            {i18next.t('createTest.cancel')}
                        </HRButton>
                    </span>
                    <span>
                        <HRButton
                            // disabled={!groupQuestionSelected}
                            btnSize="sm"
                            onClick={() => {
                                doMoveQuestion();
                                setShowMoveModal(false);
                            }}>
                            {i18next.t('createTest.move')}
                        </HRButton>
                    </span>
                </div>
            }>
            <div className="space-y-2">
                <HRSelect
                    className="w-full"
                    hasSearch={false}
                    value={groupQuestionSelected}
                    hasFilterSort={false}
                    allowClear={false}
                    options={groupQuestions}
                    onChange={(value) => setGroupQuestionSelected(value)}
                />
            </div>
        </Modal>
    </div>
}
