import React from "react";
import { TAssessment } from "../create-assessment/type";
import { HRForm } from "../../../components/form/form";
import { FormProps, useForm } from "antd/lib/form/Form";
import Form from "antd/lib/form";
import { HRInput } from "../../../components/form/input";
import { HRButton, IconButton } from "../../../components/button";
import { ICEdit } from "../../../icons";
import { MinusCircleOutlined } from "@ant-design/icons";
import i18next from "../../../i18n";

type EmailRecipients = {
  assessmentDetails: TAssessment;
  onUpdate: (emails: string[]) => void;
};

export const EmailRecipients: React.FC<EmailRecipients> = ({
  assessmentDetails,
  onUpdate,
}) => {
  const [isEdit, setIsEdit] = React.useState(false);

  const changeMode = () => {
    setIsEdit(!isEdit);
  };

  const [form] = useForm();

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    onUpdate(values.emails);
    changeMode();
  };

  const childForm = (
    <div className="flex flex-col gap-4">
      <Form.List name="emails">
        {(fields, { add, remove }, { errors }) => (
          <div className="flex flex-col gap-4">
            {fields.map((field, index) => (
              <Form.Item required={false} key={field.key}>
                <div className="flex items-center gap-2 w-full">
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input email or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <HRInput
                      placeholder={i18next.t('assessmentDetail.enterEmail') ?? ''}
                      wrapperClassName="w-[60%]"
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                </div>
              </Form.Item>
            ))}
            <Form.Item>
              <HRButton
                type="dashed"
                onClick={() => add()}
                style={{
                  width: "60%",
                }}
              >
                {i18next.t('assessmentDetail.addEmail')}
              </HRButton>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </div>
        )}
      </Form.List>
      <Form.Item>
        <HRButton
          type="primary"
          htmlType="submit"
          className="w-fit"
          width="fit-content"
        >
          {i18next.t('assessmentDetail.save')}
        </HRButton>
      </Form.Item>
    </div>
  );

  return (
    <div className="min-h-[250px] flex flex-col w-full items-start justify-start rounded-[12px] bg-white shadow-e-03 z-[3] p-4 space-y-2">
      <div className="flex justify-between items-center w-full">
        <span className="text-standard-bold text-g-800">
          {i18next.t('assessmentDetail.emailRecipients')}
        </span>

        <IconButton
          btnSize="sm"
          btnType="sub"
          onClick={changeMode}
          icon={<ICEdit />}
          hoverText={i18next.t('assessmentDetail.updateEmailRecipients') ?? ''}
          className="flex items-center"
        />
      </div>

      {!isEdit && (
        <div className="flex flex-col items-center justify-between gap-x-2">
          {assessmentDetails.emailRecipients?.map((email) => {
            return <span className="text-[14px] py-2">{email}</span>;
          })}
        </div>
      )}

      {isEdit && (
        <HRForm
          form={form}
          name="createCompanyProfile"
          childNode={childForm}
          fields={[
            {
              name: ["emails"],
              value: assessmentDetails?.emailRecipients ?? [],
            },
          ]}
          className="w-full"
          onFinish={onFinish}
        />
      )}
    </div>
  );
};
