import { IconProps } from "./type"

export const ICEssay: React.FC<IconProps> = ({
    width = 150,
    height = 150,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5454_37422)">
                <path d="M12.7057 7.37368C9.82541 8.47081 8.55707 9.73915 7.45994 12.6194C7.21037 13.2717 6.29288 13.2717 6.04331 12.6194C4.94497 9.73915 3.67784 8.47081 0.797558 7.37368C0.1441 7.12411 0.1441 6.20661 0.797558 5.95825C3.67784 4.85871 4.94497 3.59158 6.04331 0.711297C6.29288 0.0590437 7.21037 0.0590437 7.45994 0.711297C8.55707 3.59158 9.82541 4.85871 12.7057 5.95825C13.3567 6.20541 13.3567 7.12411 12.7057 7.37368Z" fill="#3FC4C1" />
                <path d="M150.28 3.20098V123.799C150.28 125.564 148.85 127.001 147.079 127.001H50.4266C48.6688 127.001 47.2244 125.564 47.2244 123.799V3.20098C47.2244 1.4311 48.6688 0 50.4266 0H147.079C148.85 0 150.28 1.4311 150.28 3.20098Z" fill="#FFA800" />
                <path d="M128.815 46.7765V146.798C128.815 148.563 127.378 150 125.614 150H28.9661C27.1962 150 25.7651 148.563 25.7651 146.798V26.201C25.7651 24.4299 27.1962 22.9988 28.9661 22.9988H103.654L128.815 46.7765Z" fill="#FFCD00" />
                <path d="M128.815 46.7789H106.864C106.197 46.7789 105.58 46.5776 105.072 46.2304L105.023 46.1954L104.571 45.8132C104.003 45.2369 103.656 44.4448 103.656 43.5767V23L128.815 46.7789Z" fill="white" />
                <path d="M113.392 107.717H39.1032C38.1447 107.717 37.3671 106.939 37.3671 105.981C37.3671 105.022 38.1447 104.245 39.1032 104.245H113.392C114.351 104.245 115.129 105.022 115.129 105.981C115.129 106.939 114.352 107.717 113.392 107.717Z" fill="white" />
                <path d="M113.392 117.359H39.1032C38.1447 117.359 37.3671 116.581 37.3671 115.622C37.3671 114.664 38.1447 113.886 39.1032 113.886H113.392C114.351 113.886 115.129 114.664 115.129 115.622C115.129 116.582 114.352 117.359 113.392 117.359Z" fill="white" />
                <path d="M113.392 127.001H39.1032C38.1447 127.001 37.3671 126.224 37.3671 125.265C37.3671 124.305 38.1447 123.529 39.1032 123.529H113.392C114.351 123.529 115.129 124.305 115.129 125.265C115.129 126.224 114.352 127.001 113.392 127.001Z" fill="white" />
                <path d="M128.815 46.7789V69.7596L105.072 46.2303C105.58 46.5775 106.197 46.7789 106.864 46.7789H128.815Z" fill="#FFA800" />
                <path d="M150.28 66.9807V123.8C150.28 125.564 148.85 127.001 147.079 127.001H128.815V46.779L150.28 66.9807Z" fill="#FF8400" />
                <path d="M44.9168 85.3993L59.7366 87.6707C60.6095 87.8045 61.3618 87.0534 61.228 86.1793L58.9553 71.3595L44.9168 85.3993Z" fill="white" />
                <path d="M17.2798 29.6854C13.4025 25.808 7.11746 25.808 3.24132 29.6854C-0.636024 33.5615 -0.636024 39.8477 3.24132 43.7239L44.9168 85.3993L58.9553 71.3596L17.2798 29.6854Z" fill="#3FC4C1" />
                <path d="M64.6616 85.6513L62.3914 70.8315L62.2177 69.7115L61.4124 68.9073L25.7651 33.2541V38.1707L58.9529 71.3584L61.2243 86.1782C61.3485 86.9835 60.724 87.6852 59.9391 87.6852C59.8704 87.6852 59.8004 87.6792 59.7377 87.6719L44.918 85.4005L25.7651 66.2477V71.1571L42.4597 87.8528L43.2662 88.657L44.3899 88.8318L59.2097 91.102C59.4532 91.1442 59.6967 91.1575 59.9391 91.1575C61.328 91.1575 62.6542 90.5534 63.5644 89.4913C64.4735 88.4351 64.8702 87.0317 64.6616 85.6513Z" fill="#FFA800" />
            </g>
            <defs>
                <clipPath id="clip0_5454_37422">
                    <rect width="150" height="150" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
