import i18next from "../i18n";
import { TPrice } from "../pages/library/models";

type HRPriceProps = {
    data: TPrice;
    custommUI?: boolean;
};

export const HRPrice: React.FC<HRPriceProps> = ({ data, custommUI }) => {

    if (!data) {
        return <div className="flex gap-[5px] font-medium text-[14px] leading-[24px] text-high-em">
            <span>{i18next.t('price.free')}</span>
        </div>
    };

    const hasDiscount = data?.discount !== null && data?.discount !== undefined;

    if (custommUI) {
        const originalPrice = data?.originalPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return <div className="flex gap-[5px] font-medium text-[14px] leading-[24px] text-high-em">
            {hasDiscount && <span className="text-[#0BAA60]">{i18next.t('price.discount')} {data?.discount}%</span>}
            <div>
                {originalPrice && <div className="line-through text-[#B3B1B8]">
                    <span>{data?.originalPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                    <span>{' '}{data?.unit}</span>
                </div>}
                <div>
                    <span>{data?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                    <span>{' '}{data?.unit}</span>
                </div>
            </div>
        </div>
    }

    return <div className="flex gap-[5px] font-medium text-[14px] leading-[24px] text-high-em">
        <span>{data?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
        <span>{data?.unit}</span>
        {hasDiscount && <span className="text-[#0BAA60]">-{data?.discount}%</span>}
    </div>
}
