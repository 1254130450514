import React from "react"
import { Slider } from "antd";
import { SliderMarks } from "antd/lib/slider";

type TScoreCandidateProps = {
    value: [number, number];
    setValue: (value: [number, number]) => void;
}
export const ScoreCandidate: React.FC<TScoreCandidateProps> = ({
    value,
    setValue
}) => {
    const scoreList: SliderMarks = {
        0: {
            label: <span className="text-[10px] leading-[16px] text-[#A6A8B4]">0%</span>,
        },
        10: {
            label: <span className="text-[10px] leading-[16px] text-[#A6A8B4]">10%</span>,
        },
        20: {
            label: <span className="text-[10px] leading-[16px] text-[#A6A8B4]">20%</span>,
        },
        30: {
            label: <span className="text-[10px] leading-[16px] text-[#A6A8B4]">30%</span>,
        },
        40: {
            label: <span className="text-[10px] leading-[16px] text-[#A6A8B4]">40%</span>,
        },
        50: {
            label: <span className="text-[10px] leading-[16px] text-[#A6A8B4]">50%</span>,
        },
        60: {
            label: <span className="text-[10px] leading-[16px] text-[#A6A8B4]">60%</span>,
        },
        70: {
            label: <span className="text-[10px] leading-[16px] text-[#A6A8B4]">70%</span>,
        },
        80: {
            label: <span className="text-[10px] leading-[16px] text-[#A6A8B4]">80%</span>,
        },
        90: {
            label: <span className="text-[10px] leading-[16px] text-[#A6A8B4]">90%</span>,
        },
        100: {
          label: <span className="text-[10px] leading-[16px] text-[#A6A8B4]">100%</span>,
        },
    };

    return <div className="flex flex-col items-start">
        <div className="grid grid-cols-1 gap-[16px] w-full text-left">
            <Slider range marks={scoreList} defaultValue={[0, 100]} value={value} onChange={setValue} />
        </div>
    </div>
}
