import React from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { getCommonAPI } from "../api/common";
import { useMe } from "../hooks/useMe";
import { CandidateLayout } from "../layout/candidate";
import { setCommonData } from "../redux/actions";
import { RootState } from "../redux/reducers";
import { LayoutType } from '../utils/enums';

type CandidatePageProps = {
    page: React.ReactElement
    isPrivate?: boolean,
    layout?: LayoutType
}
export const CandidatePage: React.FC<CandidatePageProps> = ({
    page,
    isPrivate = false,
    layout = LayoutType.CANDIDATE,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const commonData = useSelector((state: RootState) => state.commonData);
    const { getMe } = useMe();

    const [pageContent, setPageContent] = React.useState<React.ReactElement>(<></>);
    React.useEffect(() => {
        if ((isPrivate || typeof isPrivate === "undefined") && !userInfo.token) {
            navigate("/login");
        } else {
            if (layout === LayoutType.CANDIDATE) {
                return setPageContent(<CandidateLayout>{page}</CandidateLayout>)
            } else {
                setPageContent(page)
            }
        }
    }, [page, userInfo, isPrivate, navigate, layout]);

    React.useEffect(() => {
        if (isPrivate) {
            getMe()
        }
        // Fetching common data if not exist
        if (!commonData || Object.keys(commonData).length === 0) {
            getCommonAPI().then(({ data }) => {
                dispatch(setCommonData(data));
            }).catch((err) => {
                console.log(err)
            })
        }
    }, []);

    return <>{pageContent}</>
}
