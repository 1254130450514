import { Button } from "antd";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { ICRecord, ICStopRecording } from "../icons/record";
import React, { useEffect } from "react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { getURL } from "../utils";
import { ICUploadV2 } from "../icons";

type AudioRecorderProps = {
    onFinishRecord: (file: File) => void;
    recordFileUrl?: string;
}
export const HRAudioRecorder: React.FC<AudioRecorderProps> = ({
    onFinishRecord,
    recordFileUrl
}) => {
    const {
        recordingBlob,
        startRecording,
        stopRecording,
        isRecording,
        mediaRecorder,
        recordingTime,
        togglePauseResume,
        isPaused,
    } = useAudioRecorder();
    const minutes = Math.floor(recordingTime / 60).toString().padStart(2, "0");
    const seconds = Math.floor(recordingTime % 60).toString().padStart(2, "0");
    const [audioUrl, setAudioUrl] = React.useState<string | null>(null);
    const addAudioElement = (blob: Blob) => {
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
        const audiofile = new File([blob], "speaking.mp3", { type: "audio/mpeg" });
        onFinishRecord(audiofile);
    };
    const onRecordingStop = () => {
        stopRecording();
    }
    useEffect(() => {
        if (!recordingBlob) return;
        const audioBlob = recordingBlob;
        addAudioElement(audioBlob);
    }, [recordingBlob])
    return (
        <div className="w-[380px] space-y-3">
            <div className="flex justify-start">
                <Button onClick={isRecording ? togglePauseResume : startRecording}>
                    <div className="flex items-center space-x-2">
                        {
                            isRecording ? <>
                                <ICStopRecording />
                                <span>{isPaused ? "Resume" : "Pause"}</span>
                            </> : <>
                                <ICRecord />
                                <span>Start Recording</span>
                            </>
                        }
                    </div>
                </Button>
            </div>
            {
                isRecording && <div className="text-[#c22058]">Recording: {[minutes, seconds].join(":")}</div>
            }
            {mediaRecorder && (
                <div className="rounded-sm border p-2 w-[380px]">
                    <LiveAudioVisualizer
                        mediaRecorder={mediaRecorder}
                        width={350}
                        height={50}
                        barWidth={2}
                        gap={2}
                        smoothingTimeConstant={0.5}
                        fftSize={2048}
                        minDecibels={-100}
                    />
                </div>
            )}
            {
                isRecording && <div className="flex justify-start">
                    <Button onClick={onRecordingStop} disabled={!isRecording} type="primary" className="text-[#000] flex items-center space-x-2">
                        <ICUploadV2 fill="#000"/>
                        Stop and Save
                    </Button>
                </div>
            }

            <div className="flex justify-start">
                {recordFileUrl && !audioUrl && (
                    <audio controls>
                        <source src={getURL(recordFileUrl)} type="audio/mpeg" />
                    </audio>
                )}
                {
                    audioUrl && <audio controls autoPlay={true} controlsList="nodownload">
                        <source src={audioUrl} type="audio/mpeg" />
                    </audio>
                }
            </div>
        </div>
    );
}