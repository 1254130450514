import { ApiInstance } from "..";
import { convert2QueryString } from "../../utils/convert2QueryString";

export const getPaymentDetails = (assessmentId: string) => {
  const requestURL = `/api/order/preview-order/${assessmentId}`;
  return ApiInstance.getInstance().get(requestURL);
};

export const getOrderDetails = (orderCode: string) => {
  const requestURL = `/api/order/get-by-order-code/${orderCode}`;
  return ApiInstance.getInstance().get(requestURL);
};

export const getCandidateCreditInfo = () => {
  return ApiInstance.getInstance().get('/api/order/candidate-credit-info');
};

export const getMyOrder = (data: Record<string, any>) => {
  const requestURL = `/api/order/my-orders?` + convert2QueryString(data);
  return ApiInstance.getInstance().get(requestURL);
};

export const getMyWaitingForPaymentOrder = () => {
  return ApiInstance.getInstance().get(`/api/order/my-waiting-for-payment-orders`);
}

export const getOrderByAssessment = (assessmentId: string) => {
  return ApiInstance.getInstance().get(`/api/order/get-order-by-assessment/${assessmentId}`);
}
