import { ContentState, Editor, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import React from "react";
import { EQuestionType, TChoiceQuestion, TQuestion } from "../../create_test/tab/question/type"
import { TestingAnswerWrapper } from "./answer";
import classNames from 'classnames';
import { Editor as EditorControl } from "react-draft-wysiwyg";
import { MAX_CONTENT_LENGTH } from "../../../utils/constants";
import { toolbarNoLink } from "../../create_test/tab/question/editor.config";
import i18next from "../../../i18n";

export const toolbar = {
    options: ['inline', 'list', 'link'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],
    },
    list: {
        options: ['unordered', 'ordered'],
    }
}

type QuestionRenderProps = {
    question: TQuestion;
    onChangeCorrectAnswer: (answer: undefined | number | number[]) => void;
    correctAnswer?: number | number[];
}
export const QuestionRender: React.FC<QuestionRenderProps> = ({
    question,
    onChangeCorrectAnswer,
    correctAnswer,
}) => {
    switch (question?.type) {
        case EQuestionType.SINGLE_CHOICE:
        case EQuestionType.MULTIPLE_CHOICE:
        case EQuestionType.ESSAY:
            return <ChoiceQuestionRender
                question={question}
                onChangeCorrectAnswer={onChangeCorrectAnswer}
                correctAnswer={correctAnswer}
            />
        case EQuestionType.CODING:
            // TODO: Coding question render
            return <>Coding Question Render</>
        case EQuestionType.GROUP:
            return <>Group Question Render</>
        case EQuestionType.SPEAKING:
            return <div></div>
    }
}

type ChoiceQuestionRenderProps = {
    question: TChoiceQuestion;
    onChangeCorrectAnswer: (answer: undefined | number | number[]) => void;
    correctAnswer?: number | number[];
}
const ChoiceQuestionRender: React.FC<ChoiceQuestionRenderProps> = ({
    question,
    onChangeCorrectAnswer,
    correctAnswer,
}) => {
    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question?.content || '').contentBlocks))
    )
    const [editorInputState, setEditorInputState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question?.referenceContent || '').contentBlocks))
    )
    const [editorAnswerState, setEditorAnswerState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft('').contentBlocks))
    );

    React.useEffect(() => {
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question?.content || '').contentBlocks)));
        setEditorInputState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question?.referenceContent || '').contentBlocks)));
    }, [question]);

    const onEditorStateChange = (valueEditorState: EditorState) => {
        const text = valueEditorState.getCurrentContent().getPlainText()
        const oldContentState = editorAnswerState.getCurrentContent().getPlainText();
        // const lines = text.split(/\n/);
        if (text.length > MAX_CONTENT_LENGTH) {
            const oldState = ContentState.createFromBlockArray(htmlToDraft(oldContentState).contentBlocks);
            setEditorState(EditorState.moveFocusToEnd(EditorState.push(editorAnswerState, oldState, 'insert-characters')));
        } else {
            setEditorAnswerState(valueEditorState);
        }
        // else if (lines.length > 50) {
        //     const undo = EditorState.undo(valueEditorState)
        //     setEditorAnswerState(undo)
        // }
    }

    return <div className="px-[32px] py-[54px] rounded-[12px] bg-white shadow-l-large space-x-4 flex">
        <div className="w-1/2">
            <Editor
                stripPastedStyles={true}
                readOnly={true}
                editorState={editorState}
                onChange={setEditorState}
            />
        </div>
        {[EQuestionType.SINGLE_CHOICE, EQuestionType.MULTIPLE_CHOICE].includes(question.type) && <div className="w-1/2">
            <div className="text-standard-medium text-low-em mb-3">{i18next.t('previewTesting.selectAnswer')}</div>
            <TestingAnswerWrapper
                question={question}
                onChangeCorrectAnswer={onChangeCorrectAnswer}
                correctAnswer={correctAnswer}
            />
        </div>}
        {question.type === EQuestionType.ESSAY && <div className="w-1/2">
            <div className="text-standard-medium text-low-em mb-3">{i18next.t('previewTesting.answerSection')}</div>
            <EditorControl
                stripPastedStyles={true}
                toolbar={toolbarNoLink}
                editorStyle={{ 
                    height: '250px',
                    minHeight: '250px',
                    overflow: 'auto',
                    resize: 'vertical',
                    color: '#110C22',
                }}
                editorState={editorAnswerState}
                toolbarClassName={'editor-toolbar'}
                wrapperClassName={classNames("questions-wrapper", "editor-wrapper", "answer-essay-wrapper")}
                editorClassName={classNames("questions-editor", "editor-editor")}
                placeholder={i18next.t('previewTesting.enterAnswer') ?? ''}
                onEditorStateChange={onEditorStateChange}
            />
        </div>}
    </div>
}
