import { IconProps } from "./type";

export const IView: React.FC<IconProps> = ({
  fill = "#4F4B5C",
}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon / info 2" clipPath="url(#clip0_11876_53450)">
        <g id="Vector">
          <path fillRule="evenodd" clipRule="evenodd" d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z" fill="#4F4B5C" />
          <path fillRule="evenodd" clipRule="evenodd" d="M9 8.25C9.41421 8.25 9.75 8.58579 9.75 9V12C9.75 12.4142 9.41421 12.75 9 12.75C8.58579 12.75 8.25 12.4142 8.25 12V9C8.25 8.58579 8.58579 8.25 9 8.25Z" fill={fill} />
          <path fillRule="evenodd" clipRule="evenodd" d="M8.25 6C8.25 5.58579 8.58579 5.25 9 5.25H9.0075C9.42171 5.25 9.7575 5.58579 9.7575 6C9.7575 6.41421 9.42171 6.75 9.0075 6.75H9C8.58579 6.75 8.25 6.41421 8.25 6Z" fill={fill} />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_11876_53450">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export const ICViewWhite: React.FC<IconProps> = ({
  fill = "white",
}) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4004 12C15.4004 12.7956 15.0843 13.5587 14.5217 14.1213C13.9591 14.6839 13.196 15 12.4004 15C11.6047 15 10.8417 14.6839 10.2791 14.1213C9.71646 13.5587 9.40039 12.7956 9.40039 12C9.40039 11.2044 9.71646 10.4413 10.2791 9.87868C10.8417 9.31607 11.6047 9 12.4004 9C13.196 9 13.9591 9.31607 14.5217 9.87868C15.0843 10.4413 15.4004 11.2044 15.4004 12Z" fill={fill} />
      <path d="M22.2948 11.553C20.1368 7.236 16.3048 5 12.4008 5C8.49783 5 4.66483 7.236 2.50683 11.553C2.43747 11.6918 2.40137 11.8448 2.40137 12C2.40137 12.1552 2.43747 12.3082 2.50683 12.447C4.66483 16.764 8.49683 19 12.4008 19C16.3038 19 20.1368 16.764 22.2948 12.447C22.3642 12.3082 22.4003 12.1552 22.4003 12C22.4003 11.8448 22.3642 11.6918 22.2948 11.553ZM12.4008 17C9.43183 17 6.39883 15.38 4.53083 12C6.39883 8.62 9.43083 7 12.4008 7C15.3698 7 18.4028 8.62 20.2708 12C18.4028 15.38 15.3698 17 12.4008 17Z" fill={fill} />
    </svg>
  );
};




export const IViewV2: React.FC<IconProps> = ({
  width = 24,
  height = 24,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12Z" fill="currentColor" />
          <path d="M21.894 11.553C19.736 7.236 15.904 5 12 5C8.09702 5 4.26402 7.236 2.10602 11.553C2.03666 11.6918 2.00055 11.8448 2.00055 12C2.00055 12.1552 2.03666 12.3082 2.10602 12.447C4.26402 16.764 8.09602 19 12 19C15.903 19 19.736 16.764 21.894 12.447C21.9634 12.3082 21.9995 12.1552 21.9995 12C21.9995 11.8448 21.9634 11.6918 21.894 11.553V11.553ZM12 17C9.03102 17 5.99802 15.38 4.13002 12C5.99802 8.62 9.03002 7 12 7C14.969 7 18.002 8.62 19.87 12C18.002 15.38 14.969 17 12 17Z" fill="currentColor" />
      </svg>
  );
};
