import { IconProps } from "./type"

export const ICMoMo: React.FC<IconProps> = ({
    width = 40,
    height = 40,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_15102_36044)">
                <path fillRule="evenodd" clipRule="evenodd" d="M37.2973 0H2.7027C1.21622 0 0 1.21622 0 2.7027V37.2973C0 38.7838 1.21622 40 2.7027 40H37.2973C38.7838 40 40 38.7838 40 37.2973V2.7027C40 1.21622 38.7838 0 37.2973 0Z" fill="#A50064" />
                <path d="M27.6756 18.7834C30.8513 18.7834 33.4189 16.2158 33.4189 13.0401C33.4189 9.86444 30.8513 7.29688 27.6756 7.29688C24.4999 7.29688 21.9324 9.86444 21.9324 13.0401C21.9324 16.2158 24.4999 18.7834 27.6756 18.7834ZM27.6756 10.5942C29.027 10.5942 30.1216 11.6888 30.1216 13.0401C30.1216 14.3915 29.027 15.4861 27.6756 15.4861C26.3243 15.4861 25.2297 14.3915 25.2297 13.0401C25.2297 11.6888 26.3243 10.5942 27.6756 10.5942Z" fill="white" />
                <path d="M27.6756 21.2695C24.4999 21.2695 21.9324 23.8371 21.9324 27.0128C21.9324 30.1884 24.4999 32.756 27.6756 32.756C30.8513 32.756 33.4189 30.1884 33.4189 27.0128C33.4189 23.8371 30.8513 21.2695 27.6756 21.2695ZM27.6756 29.4587C26.3243 29.4587 25.2297 28.3641 25.2297 27.0128C25.2297 25.6614 26.3243 24.5668 27.6756 24.5668C29.027 24.5668 30.1216 25.6614 30.1216 27.0128C30.1216 28.3641 29.027 29.4587 27.6756 29.4587Z" fill="white" />
                <path d="M15.973 21.2695C15 21.2695 14.1081 21.5939 13.3919 22.1344C12.6757 21.5939 11.7703 21.2695 10.8108 21.2695C8.43243 21.2695 6.5 23.202 6.5 25.5803V32.7695H9.7973V25.5398C9.7973 24.9993 10.2297 24.5668 10.7703 24.5668C11.3108 24.5668 11.7432 24.9993 11.7432 25.5398V32.756H15.0405V25.5398C15.0405 24.9993 15.473 24.5668 16.0135 24.5668C16.5541 24.5668 16.9865 24.9993 16.9865 25.5398V32.756H20.2703V25.5668C20.2703 23.202 18.3514 21.2695 15.973 21.2695Z" fill="white" />
                <path d="M15.9729 7.29688C15 7.29688 14.1081 7.6212 13.3919 8.16174C12.6756 7.6212 11.7702 7.29688 10.8108 7.29688C8.41888 7.29688 6.48645 9.22931 6.48645 11.6077V18.7834H9.78375V11.5671C9.78375 11.0266 10.2162 10.5942 10.7567 10.5942C11.2973 10.5942 11.7297 11.0266 11.7297 11.5671V18.7834H15.027V11.5671C15.027 11.0266 15.4594 10.5942 16 10.5942C16.5405 10.5942 16.9729 11.0266 16.9729 11.5671V18.7834H20.2702V11.6077C20.2702 9.22931 18.3513 7.29688 15.9729 7.29688Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_15102_36044">
                    <rect width="40" height="40" rx="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
