import React from "react";

type SectionAssessmentProps = {
  title: string;
  subTitle: string;
  content?: React.ReactNode;
  classess?: string;
};
export const SectionAssessment: React.FC<SectionAssessmentProps> = ({
  title,
  subTitle,
  content,
  classess,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-[8px]">
        <div className="text-g-800 text-heading-6-bold">
          {title}
        </div>
        <div className="text-g-400 text-body-medium">
          {subTitle}
        </div>
      </div>
      {content && <div className={`mt-[24px] ${classess}`}>
        {content}
      </div>}
    </div>
  );
};
