import React from "react"
import { QuestionEditor } from "./editor"
import { useAssementData } from "../../../hooks/useCreateAssessment";
import { EQuestionType, TEssayChoiceQuestion } from "../../../../../create_test/tab/question/type";
import { EssayEditor } from "./essay";

type AddQuestionProps = {
}
export const AddQuestionAssessment: React.FC<AddQuestionProps> = () => {
    const { setShowAddQuestion, selectedQuestion, selectedQuestionType, onCreatedQuestion } = useAssementData();

    const onBack = React.useCallback(() => {
        setShowAddQuestion(false);
    }, [setShowAddQuestion]);

    if (selectedQuestionType === EQuestionType.ESSAY) return <EssayEditor
        onCancel={onBack}
        selectedQuestion={selectedQuestion as TEssayChoiceQuestion}
        questionType={selectedQuestionType}
        onCreateQuestion={(q) => {
            onCreatedQuestion(q);
            setShowAddQuestion(false)
        }}
    />
    return <QuestionEditor
        questionType={selectedQuestionType}
        onCancel={onBack}
        selectedQuestion={selectedQuestion}
    />;

}
