import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    .ant-table-thead th.ant-table-cell {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        padding: 16px 0 8px 0 !important
    }
    .ant-table-tbody>tr>td {
        padding: 16px 0 0 0 !important;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
    }
    .white-header.no-border .ant-table-tbody>tr>td {
        border-bottom: 1px solid #E2E4EB !important
    }
    .white-header.no-border .ant-table-tbody>tr>td {
        padding: 16px 16px 16px 0 !important;
    }
`;

type PanelAssessmentProps = {
    title?: string,
    childNode: React.ReactNode | React.ReactElement,
    actionHeader?: React.ReactNode | React.ReactElement,
    header?: React.ReactNode,
    rightContentHeader?: React.ReactNode | React.ReactElement,
    classBody?: string,
}

export const PanelAssessment: React.FC<PanelAssessmentProps> = ({
    title,
    childNode,
    actionHeader,
    header,
    rightContentHeader,
    classBody
}) => {

    return <div className="flex flex-col w-full items-start justify-start rounded-[12px] bg-white shadow-e-03 z-[3]">
        {!header && <div className="grid grid-cols-[auto_auto] w-full items-center justify-between p-[16px] border-solid border-b border-[#ECECED]">
           <span className="flex items-center min-h-[40px] text-high-em text-standard-bold">{title}</span>
            {actionHeader && <span>{actionHeader}</span>}
            {rightContentHeader}
        </div>}
        {header && <div className="grid grid-cols-1 w-full items-center justify-between p-[16px] border-solid border-b border-outline-med">
            {header}
        </div>}
        <Wrapper className={`p-[16px] pt-0 w-full ${classBody}`}>{childNode}</Wrapper>
    </div>
}
