import React from "react";
import { useDispatch } from "react-redux";
import { Card, List, Modal, Rate, Tabs, Tag } from "antd";
import { htmlToText } from "html-to-text";
import { QuestionCandidateDetails } from "./questions";
import debouce from 'lodash/debounce';
import { saveNoteTestCandidate, downloadTestResult, updateEvaluateStatus } from "../../../api/test-candidate";
import { HRButton, IconButton } from "../../../components/button";
import { HRSelect } from "../../../components/form/select";
import { HRTextArea } from "../../../components/form/textarea";
import { useNotification } from "../../../hooks/useNotification";
import { ICUserV3, ICSendV3, ICDownload, IView } from "../../../icons";
import { setLoading } from "../../../redux/slices/appInfo";
import { formatLocaleDateTimeString } from "../../../utils/date";
import { PanelAssessment } from "../../my_assessment/details/panel";
import { EStatusAssessment, TSaveNote } from "../../my_assessment/type";
import { TCandidateTestingData, TQuestionTesting, ECandidateAssessmenStatus, candidateStatusMapping, EEvaluateStatusOptions, TTestingCandidate, TSectionTesting, candidateStatusColorMapping, EEvaluateStatus } from "../../testing/type";
import { useGeneralPageData } from "../../general";
import { QuestionTypeMapping } from "../../../utils/constants";
import { ViewEssayWrapper } from "./view-essay";
import classNames from "classnames";
import { AntiCheatTab } from "./anti-cheat-tab";
import i18next from "../../../i18n";
import { useMobile } from "../../../hooks/useDimensions";

type CandidateDetailsProps = {
    candidateDetails: TCandidateTestingData;
    candidateId: string;
    tests: TTestingCandidate[];
    setShowConfirmSendResult: React.Dispatch<React.SetStateAction<boolean>>
    setShowRejectModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowQuestionModal: React.Dispatch<React.SetStateAction<boolean>>;
    setQuestion: React.Dispatch<React.SetStateAction<TQuestionTesting | null>>;
    setCandidateSelected: React.Dispatch<React.SetStateAction<TCandidateTestingData | null>>;
    setCandidateList: React.Dispatch<React.SetStateAction<TCandidateTestingData[]>>;
}

export const CandidateDetails: React.FC<CandidateDetailsProps> = ({
    candidateId,
    candidateDetails,
    tests,
    setShowConfirmSendResult,
    setShowRejectModal,
    setShowQuestionModal,
    setQuestion,
    setCandidateSelected,
    setCandidateList
}) => {
    const dispatch = useDispatch();
    const { setShowComingSoonModal } = useGeneralPageData();
    const { showSuccess, showError } = useNotification();
    const [questionType, setQuestionType] = React.useState<'question' | 'additional_question' | 'test' | null>(null);
    const [additionalQuestions, setAdditionalQuestions] = React.useState<TQuestionTesting[]>([]);
    const [candidateNote, setCandidateNote] = React.useState<string>('');
    const [rateNote, setRateNote] = React.useState<number>(0);
    const [testQuestions, setTestQuestions] = React.useState<TTestingCandidate[]>([]);
    const [showEssayModal, setShowEssayModal] = React.useState<boolean>(false);
    const [selectSkillArea, setSelectSkillArea] = React.useState<TSectionTesting | undefined>(undefined);
    const isMobile = useMobile();
    React.useEffect(() => {
        if (candidateDetails) {
            setCandidateNote(candidateDetails?.note || '');
            setRateNote(candidateDetails?.rating || 0);
        }
    }, [candidateDetails]);

    const downloadResultTesting = React.useCallback(async () => {
        if (candidateDetails?._id) {
            try {
                dispatch(setLoading(true));
                const result = await downloadTestResult(candidateDetails?._id);
                const linkDownload = document.createElement('a');
                linkDownload.href = `${process.env.REACT_APP_S3_URL}/${result.data.url}`;
                linkDownload.target = '_blank';
                linkDownload.rel = 'noopener noreferrer';
                document.body.appendChild(linkDownload);
                linkDownload.click();
                document.body.removeChild(linkDownload);
                showSuccess(i18next.t('candidateDetail.downloadSuccess'));
            } catch (error: any) {
                console.error(error);
                showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
            }
            finally {
                dispatch(setLoading(false));
            }
        }
    }, [dispatch, candidateDetails, showError, showSuccess]);

    const onUpdateEvaluateStatus = React.useCallback(async (evaluateStatus: EEvaluateStatus) => {
        if (candidateDetails?._id) {
            try {
                dispatch(setLoading(true));
                await updateEvaluateStatus(candidateDetails?._id, evaluateStatus);
                setCandidateList([{ ...candidateDetails, evaluateStatus }]);
                showSuccess(i18next.t('candidateDetail.updateSuccess'));
            } catch (error: any) {
                console.error(error);
                showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
            }
            finally {
                dispatch(setLoading(false));
            }
        }
    }, []);

    const activeDownloadButton = React.useMemo(() => {
        if (candidateDetails?.status === ECandidateAssessmenStatus.DONE) {
            return false;
        }
        return true;
    }, [candidateDetails?.status]);

    const headerPanel = (
        <div className="flex flex-row justify-between gap-[32px] w-full">
            <div className="flex flex-col">
                <span className="text-[22px] font-bold leading-[32px]">{candidateDetails?.assessment?.title}</span>
                <Rate value={rateNote || 0} disabled />
            </div>
            <div className="flex gap-[16px]">
                <div>
                    <IconButton
                        btnType="sub"
                        btnSize="md"
                        icon={<ICUserV3 fill="#F55858" height={18} width={18} />}
                        onClick={() => {
                            setShowComingSoonModal(true);
                            // setShowRejectModal(true);
                        }}
                    />
                </div>
                <div>
                    <IconButton
                        btnType="sub"
                        btnSize="md"
                        icon={<ICSendV3 height={18} width={18} />}
                        onClick={() => {
                            setCandidateSelected(candidateDetails);
                            setShowConfirmSendResult(true);
                        }}
                        disabled={
                            candidateDetails?.status !== ECandidateAssessmenStatus.DONE ||
                            candidateDetails?.assessment?.status === EStatusAssessment.ARCHIVED
                        }
                        hoverText={i18next.t('candidateDetail.sendResultsToCandidate') ?? ''}
                    />
                </div>
                <div>
                    <IconButton
                        btnType="sub"
                        btnSize="md"
                        icon={<ICDownload height={18} width={18} />}
                        onClick={downloadResultTesting}
                        disabled={activeDownloadButton}
                        hoverText={i18next.t('candidateDetail.downloadResults') ?? ''}
                    />
                </div>
            </div>
        </div>
    );

    React.useEffect(() => {
        if (candidateDetails) {
            const testQuestionList: TTestingCandidate[] = ((candidateDetails?.tests || []) as TTestingCandidate[])?.filter((item: any) => item?.type === 'test') || [];
            setTestQuestions(testQuestionList);
        }
    }, [candidateDetails]);

    const renderDuration = React.useMemo(() => {
        const questionList: TTestingCandidate[] = ((candidateDetails?.tests || []) as TTestingCandidate[]) || [];
        const duration = questionList?.reduce((acc, item) => {
            return acc + item.duration;
        }, 0);
        return duration;
    }, [candidateDetails?.tests]);

    const onViewQuestion = React.useCallback((question: TQuestionTesting, typeOfSection: 'question' | 'additional_question' | 'test') => {
        setQuestion(question);
        setQuestionType(typeOfSection);
        setCandidateSelected(candidateDetails);
        setShowQuestionModal(true);
    }, [candidateDetails, setCandidateSelected, setQuestion, setShowQuestionModal]);

    const onSaveNote = React.useCallback(async (newValue: string, candidateId: string, rateNote: number) => {
        try {
            dispatch(setLoading(true));
            const payload: TSaveNote = {
                note: newValue,
                rating: rateNote
            }
            await saveNoteTestCandidate(candidateId, payload);
            dispatch(setLoading(false));
            showSuccess(i18next.t('candidateDetail.noteSaved'));
        } catch (error) {
            dispatch(setLoading(false));
            showError('Error', i18next.t('candidateDetail.errorMessage'));
        }
    }, [dispatch, showSuccess, showError]);
    const onSearchKeyword = React.useMemo(() => {
        return debouce(onSaveNote, 600);
    }, [onSaveNote]);

    const onRateChange = React.useCallback((value: number) => {
        setRateNote(value);
        onSearchKeyword(candidateNote, candidateId, value);
    }, [setRateNote, candidateNote, candidateId, onSearchKeyword]);

    React.useEffect(() => {
        return () => {
            onSearchKeyword.cancel();
        }
    }, [onSearchKeyword]);

    const handleChangeNote = React.useCallback(($event: any) => {
        let value = $event.target.value;
        if (value.length > 500) {
            return;
        }
        setCandidateNote(value);
        onSearchKeyword(value, candidateId, rateNote);
    }, [setCandidateNote, candidateId, rateNote]);

    React.useEffect(() => {
        if (candidateDetails) {
            const additionalQuestionList: TTestingCandidate[] = ((candidateDetails?.tests || []) as TTestingCandidate[])?.filter((item: any) => item?.type === 'additional_question') || [];
            if (additionalQuestionList?.length) {
                const newData = additionalQuestionList.map((item) => {
                    const questions = item?.sections?.map((section) => section?.questions)?.flat() || [];
                    return [...questions];
                }).flat() || [];
                setAdditionalQuestions(newData);
            }
        }
    }, [candidateDetails]);
    
    const content = (
        <div className="flex flex-col gap-[24px]">
            <div className="flex w-full">
                <Card
                    bordered={false}
                    bodyStyle={{
                        position: 'relative',
                        padding: isMobile ? '12px 16px 0 16px': '24px 32px 0 32px',
                    }}
                    style={{
                        width: '100%',
                    }}>
                    <div className="lg:grid grid-cols-[1fr_auto_200px] gap-[24px]">
                        <div className="grid grid-cols-2 lg:grid-cols-5 gap-[24px]">
                            <div className="flex flex-col gap-[8px]">
                                <span className="text-sub text-low-em">{i18next.t('candidateDetail.inviteOnDate')}</span>
                                <span className="text-high-em text-body">{candidateDetails?.invitedAt ? formatLocaleDateTimeString(candidateDetails?.invitedAt || 0) : '-'}</span>
                            </div>
                            <div className="flex flex-col gap-[8px]">
                                <span className="text-sub text-low-em">{i18next.t('candidateDetail.start')}</span>
                                <span className="text-high-em text-body">
                                    {candidateDetails?.startedAt ? formatLocaleDateTimeString(candidateDetails?.startedAt || 0) : '-'}
                                </span>
                            </div>
                            <div className="flex flex-col gap-[8px]">
                                <span className="text-sub text-low-em">{i18next.t('candidateDetail.end')}</span>
                                <span className="text-high-em text-body">
                                    {candidateDetails?.endedAt ? formatLocaleDateTimeString(candidateDetails?.endedAt || 0) : '-'}
                                </span>
                            </div>
                            <div className="flex flex-col gap-[8px]">
                                <span className="text-sub text-low-em">{i18next.t('candidateDetail.testDuration')}</span>
                                <span className="text-high-em text-body">{renderDuration} {i18next.t('candidateDetail.minute')}</span>
                            </div>
                            <div className="flex flex-col gap-[8px]">
                                <span className="text-sub text-low-em">{i18next.t('candidateDetail.status')}</span>
                                <span
                                    className={classNames([
                                        'text-body-medium',
                                        `${candidateStatusColorMapping[candidateDetails?.status]}`
                                    ])}>
                                    {candidateDetails?.status ? candidateStatusMapping[candidateDetails.status] : '-'}
                                </span>
                            </div>
                        </div>
                        <div className="h-full w-[1px] bg-outlines-med"></div>
                        <div className="flex flex-col gap-[8px] pt-4 lg:pt-0">
                            <span className="text-sub text-low-em">{i18next.t('candidateDetail.evaluationRound')}</span>
                            <span className="text-high-em text-body">
                                <HRSelect
                                    options={EEvaluateStatusOptions}
                                    placeholder={i18next.t('candidateDetail.selectLanguage')}
                                    value={candidateDetails?.evaluateStatus}
                                    className="w-full"
                                    allowClear={false}
                                    onChange={(value) => onUpdateEvaluateStatus(value)}
                                />
                            </span>
                        </div>

                    </div>
                </Card>
            </div>
            <div className="line h-[1px] w-full bg-outlines-med"></div>
            <div className="flex">
                <div className="flex gap-[24px] w-full">
                    <Card
                        bodyStyle={{
                            padding: isMobile ? '0 12px 12px 12px' : '0 24px 24px 24px',
                        }}
                        style={{
                            border: 0,
                            borderRight: '1px solid #ECECED',
                            width: '100%',
                            boxShadow: '0px 1px 2px -1px rgba(17, 12, 34, 0.08)',
                        }}>
                        <div className="flex flex-col gap-[24px]">
                            <div className="text-center">
                                <QuestionCandidateDetails
                                    testQuestions={testQuestions}
                                    setShowEssayModal={setShowEssayModal}
                                    setSelectSkillArea={setSelectSkillArea}
                                    setTestQuestions={setTestQuestions}
                                    candidateId={candidateId}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <Card
                bodyStyle={{
                    padding: '16px',
                }}
                style={{
                    border: 0,
                }}>
                <div className="flex flex-col gap-[24px]">
                    <div className="flex flex-row">
                        <span className="text-body-bold text-high-em">{i18next.t('candidateDetail.antiFraud')}</span>
                    </div>
                    <AntiCheatTab
                        candidateDetails={candidateDetails}
                    />
                </div>
            </Card>
            <div>
            </div>
            <div className="grid grid-cols-1 gap-[24px] ">
                <Card
                    bodyStyle={{
                        padding: '16px',
                    }}
                    style={{
                        border: 0,
                    }}>
                    <div className="flex flex-col gap-[12px]">
                        <div className="flex flex-row">
                            <span className="text-title-bold text-high-em">{i18next.t('candidateDetail.additionalQuestions')}</span>
                        </div>
                        <div className="flex flex-row overflow-x-auto">
                            <List
                                className="min-w-[700px]"
                                header={<div className="grid grid-cols-[1fr_150px_auto_180px] gap-[24px]">
                                    <span className="text-high-em text-standard-bold">{i18next.t('candidateDetail.question')}</span>
                                    <span className="text-high-em text-standard-bold">{i18next.t('candidateDetail.type')}</span>
                                    <span className="text-high-em text-standard-bold">{i18next.t('candidateDetail.viewAnswer')}</span>
                                    <span className="text-high-em text-standard-bold">{i18next.t('candidateDetail.evaluate')}</span>
                                </div>}
                                bordered
                                dataSource={additionalQuestions}
                                style={{
                                    width: '100%',
                                    borderRadius: '8px',
                                }}
                                rowKey={(item) => item?._id}
                                renderItem={item => (
                                    <List.Item style={{
                                        width: '100%',
                                    }}>
                                        <div className="grid grid-cols-[1fr_150px_auto_180px] gap-[24px] items-center w-full">
                                            <span className="text-high-em text-body">
                                                {htmlToText(item?.content)}
                                            </span>
                                            <span className="text-high-em text-body">
                                                {QuestionTypeMapping[item?.type]}
                                            </span>
                                            <span>
                                                <HRButton
                                                    btnType="sub_info"
                                                    btnSize="sm"
                                                    onClick={() => {
                                                        onViewQuestion(item, 'additional_question');
                                                    }}
                                                >
                                                    <div className="flex flex-row items-center gap-[10px]">
                                                        <IView fill="#0084FF" stroke="#0084FF" />
                                                        <span className="text-body text-info">{i18next.t('candidateDetail.view')}</span>
                                                    </div>
                                                </HRButton>
                                            </span>
                                            <span>
                                                <Rate disabled value={item?.rating || 0} />
                                            </span>
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </div>
                </Card>
            </div>

            <div className="grid grid-cols-1">
                <Card
                    bodyStyle={{
                        padding: '16px',
                    }}
                    style={{
                        border: 0,
                    }}>
                    <div className="lg:flex flex-row gap-[10px] justify-between">
                        <div className="flex flex-col">
                            <div className="flex flex-col gap-[8px]">
                                <span className="text-high-em text-title-bold">{i18next.t('candidateDetail.yourEvaluation')}</span>
                                <span className="text-body text-high-em">{i18next.t('candidateDetail.provideOverallEvaluation')}</span>
                            </div>
                            <div className="flex flex-row">
                                <Rate defaultValue={candidateDetails?.rating || 0} value={rateNote} onChange={(value) => onRateChange(value)} />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <HRTextArea
                                value={candidateNote}
                                onChange={handleChangeNote}
                                placeholder={i18next.t('candidateDetail.addPrivateNotesHere') ?? ''}
                                maxLength={500}
                                className="h-[109px] w-full lg:w-[538px]"
                            />
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );

    return <div className="flex flex-row w-full">
        <div className="grid grid-cols-1 gap-[40px] w-full">
            <PanelAssessment header={headerPanel} childNode={content} classBody="p-0" />
        </div>

        <Modal
            width={1000}
            centered
            onCancel={() => setShowEssayModal(false)}
            open={showEssayModal}
            style={{
                borderRadius: '12px',
                overflow: 'hidden',
                boxSizing: 'border-box',
            }}
            bodyStyle={{ padding: 0, borderRadius: '12px', minHeight: '764px' }}
            closable={false}
            maskClosable={false}
            destroyOnClose={true}
            title={null}
            footer={null}>
            <ViewEssayWrapper
                selectSkillArea={selectSkillArea}
                setSelectSkillArea={setSelectSkillArea}
                setShowEssayModal={setShowEssayModal}
                testQuestions={testQuestions}
                setTestQuestions={setTestQuestions}
                candidateDetails={candidateDetails} />
        </Modal>

    </div>
}
