import { Layout } from 'antd';
import { MainHeader } from "./header"
type MainLayoutProps = {
    children: React.ReactNode
}
export const MainLayout: React.FC<MainLayoutProps> = ({
    children
}) => {
    const { Header, Content } = Layout;
    return (
        <Layout>
            <Header
                style={{
                    height: '70px',
                    backgroundColor: 'white',
                    zIndex: 999,
                }}
                className='px-1 lg:px-12'
            >
                <MainHeader />
            </Header>
            <Content style={{ minHeight: 'calc(100vh - 70px)', backgroundColor: '#F8F8F8' }}>{children}</Content>
        </Layout>
    )
}
