import { ApiInstance } from "."

export const UploadAPI = (
    file: File,
) => {
    return ApiInstance.getInstance().post("/api/uploader", { file }, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}