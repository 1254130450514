import { ApiInstance } from "..";
import { convert2QueryString } from '../../utils/convert2QueryString';

export const updateReceiveCV = (id: string, receiveCV: boolean) => {
  return ApiInstance.getInstance().post(`/api/assessment-cv/update-receive-cv/${id}`, { receiveCV });
}

export const applyCV = (id: string, data: Record<string, any>) => {
  return ApiInstance.getInstance().post(`/api/assessment-cv/apply-assessment/${id}`, data);
}

export const checkReceiveCvStatus = (id: string) => {
  return ApiInstance.getInstance().get(`/api/assessment-cv/receive-cv-status/${id}`);
}

export const getCVinAssessment = (id: string, data: Record<string, any>) => {
  const requestURL = `/api/assessment-cv/cv/${id}?` + convert2QueryString(data);
  return ApiInstance.getInstance().get(requestURL);
}
