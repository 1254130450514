import { ApiInstance } from "..";

export async function deleteConversation(conversationId: string) {
    const { data } = await ApiInstance.getInstance().delete(`/api/gpt/conversation/${conversationId}`);
    return data;    
}

export async function deleteAllConversation() {
    const { data } = await ApiInstance.getInstance().delete('/api/gpt/conversation/all');
    return data;
}