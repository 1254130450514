import { Form } from "antd"
import React from "react"
import { HRButton } from "../../components/button"
import { HRInputPassword } from "../../components/form/input"
import { Navigator } from "../../components/navigator"
import { ICVerify } from "../../icons/verify"
import { haveAtLeastOneLowerCase, haveAtLeastOneNumber, haveAtLeastOneUpperCase, haveUnallowedCharacters, isStrongPassword } from "../../utils"
import { HRForm } from "../../components/form/form"
import { useForm } from "antd/lib/form/Form"
import { ICTick } from "../../icons"
import i18next from "../../i18n"

type EnterPasswordProps = {
    onSubmit: (password: string) => void;
}
export const EnterPassword: React.FC<EnterPasswordProps> = ({
    onSubmit
}) => {
    const [passwordForm] = useForm();
    const [password, setPassword] = React.useState<string>("");
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");
    const errorSamePassword = React.useMemo(() => {
        if ((password.length > 0 && confirmPassword.length > 0) && password !== confirmPassword) {
            return i18next.t("password.enterValidPassword");
        }
        return ""
    }, [password, confirmPassword]);

    const isValidPassword = React.useMemo(() => {
        return !password.length || isStrongPassword(password);
    }, [password]);

    const onNext = () => {
        passwordForm.validateFields().then((res) => {
            onSubmit(password);
        }).catch((error) => {
            console.log('error', error);
        });
    };

    const rules = React.useMemo(() => {
        return [
        {
            name: i18next.t('password.haveUnallowedCharacters'),
            rule: !haveUnallowedCharacters(password)
        },{
            name: i18next.t('password.passwordLength'),
            rule: password.length >= 6 && password.length <= 20
        }, {
            name: i18next.t('password.haveAtLeastOneUpperCase'),
            rule: haveAtLeastOneUpperCase(password)
        }, {
            name: i18next.t('password.haveAtLeastOneLowerCase'),
            rule: haveAtLeastOneLowerCase(password)
        }, {
            name: i18next.t('password.haveAtLeastOneNumber'),
            rule: haveAtLeastOneNumber(password)
        }]
    }, [password]);
    return (
        <div className="flex flex-col gap-[48px] p-4 lg:p-[48px] lg:min-w-[478px]">
            <Navigator
                current={1}
                total={3}
            />
            <div className="flex flex-col gap-[16px]">
                <h3 className="text-center mb-0 text-heading-5-bold text-high-em">
                    <div>{i18next.t('register.createPassword')}</div>
                </h3>
                <div className="text-center text-sub text-med-em">
                    {i18next.t('register.doNotShareInfomation')}
                </div>
            </div>

            <div className="flex flex-col gap-[24px]">
                <HRForm form={passwordForm}
                    name="passwordForm"
                    childNode={
                    <div className="flex flex-col gap-[16px]">
                        <Form.Item required 
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: i18next.t('register.passwordShouldNotBeEmpty') ?? '',
                                },
                            ]}>
                            <div className="space-y-1">
                                <HRInputPassword 
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    size="large"
                                    maxLength={20}
                                    placeholder={i18next.t('register.newPassword') ?? ''}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item name="confirmPassword" required 
                            rules={[
                                {
                                    required: true,
                                    message: i18next.t('register.passwordShouldNotBeEmpty') ?? '',
                                },
                            ]}>
                            <div className="space-y-1">
                                <HRInputPassword isError={!!errorSamePassword}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    size="large"
                                    maxLength={20}
                                    placeholder={i18next.t('register.reenterNewPassword') ?? ''}
                                />
                                <div className="pt-2 font-medium text-xs text-error-500">{errorSamePassword}</div>
                            </div>
                        </Form.Item>
                </div>} />

                <div className="flex flex-col gap-[4px]">
                    {
                        rules.map((rule, index) => (
                            <div key={index} className="text-body text-high-em flex gap-[8px]">
                                <ICVerify isValid={rule.rule} />
                                <span>{rule.name}</span>
                            </div>
                        ))
                    }
                </div>
                <HRButton
                    disabled={!!errorSamePassword || !isValidPassword}
                    onClick={() => onNext() }>
                    <div className="space-x-2 flex items-center justify-center">
                        <ICTick />
                        <span>{i18next.t('register.confirm')}</span>
                    </div>
                </HRButton>
            </div>

        </div>
    )
}
