import React, { useEffect } from "react"
import { HomeHeader } from "./header"
import { HomeBackground } from "./background"
import { UserInfo } from "./info"
import { RootState } from "../../redux/reducers"
import { useDispatch, useSelector } from "react-redux"
import { setLoading } from "../../redux/slices/appInfo"
import { updateUserInfo } from "../../api/user"
import { setUserInfo } from "../../redux/actions"
import { useNotification } from '../../hooks/useNotification';
import { isValidPhoneNumber } from "react-phone-number-input";
import i18next from "../../i18n"

export const HomePage: React.FC = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const [user, setUser] = React.useState(userInfo);
    const [errors, setErrors] = React.useState<{
        isEmptyName: boolean;
        isExistsFeatureName: boolean;
        inValidPhone: boolean;
    }>({
        isEmptyName: false,
        isExistsFeatureName: false,
        inValidPhone: false,
    })
    useEffect(() => {
        if (userInfo) {
            setUser(userInfo);
        }
    }, [userInfo]);

    const isChange = React.useMemo(() => {
        return Object.keys(user).some((key: string) => (user as any)[key] !== (userInfo as any)[key]);
    }, [user, userInfo]);

    const onChange = (key: string, value: any) => {
        setUser({
            ...user,
            [key]: value === undefined ? null : value,
        });
        if (key === "name" && value?.length > 0) {
            setErrors(prev => ({
                ...prev,
                isEmptyName: false,
            }))
        }
    }
    const {
        showSuccess,
        showError,
    } = useNotification()
    const onSave = async () => {
        if(user.name?.length === 0) {
            setErrors(prev => ({
                ...prev,
                isEmptyName: true,
            }))
            return;
        }
        if(user.phoneNumber) {
            const inValidPhone = !isValidPhoneNumber(user.phoneNumber, "VN");
            setErrors(prev => ({
                ...prev,
                inValidPhone: inValidPhone,
            }));
            if (inValidPhone) return;
        }

        dispatch(setLoading(true));
        try {
            await updateUserInfo(user);
            dispatch(setUserInfo(user));
            showSuccess(i18next.t('home.success'));
            setErrors(prev => ({
                ...prev,
                isEmptyName: false,
                isExistsFeatureName: false,
                inValidPhone: false,
            }));
        } catch (e: any) {
            console.error(e)
            const { errorCode, errorMessage } = e?.response?.data ?? {};
            if (errorCode === 400 && errorMessage === "featuredName is already in use") {
                showError(i18next.t('home.fail'), i18next.t('home.uniqueNameAlreadyInUse'))
                setErrors(prev => ({
                    ...prev,
                    isExistsFeatureName: true,
                }));

            } else {
                setErrors(prev => ({
                    ...prev,
                    isExistsFeatureName: false,
                }));
            }
            // Handle other errors
        } finally {
            dispatch(setLoading(false));
        }

    }
    return (
        <div className="text-center">
            <div className="pt:0 lg:pt-8 lg:space-y-6">
                <HomeHeader />
                <div className="lg:pb-16 space-y-8 bg-surface-low">
                    <HomeBackground
                        user={user}
                        onCancel={() => {
                            setUser(userInfo);
                            setErrors({
                                isEmptyName: false,
                                isExistsFeatureName: false,
                                inValidPhone: false,
                            })
                        }}
                        onSave={onSave}
                        showSaveButton={isChange}
                    />
                    <UserInfo
                        userInfo={user}
                        onChange={onChange}
                        errors={errors}
                    />
                </div>
            </div>
        </div>
    )
}
