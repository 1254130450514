import React from "react";
import { Link } from "react-router-dom";
import { HRButton } from "../../components/button";
import { HRInput } from "../../components/form/input";
import { Navigator } from "../../components/navigator";
import { ICUserV2, ICNext } from "../../icons/index";
import { isValidEmail } from "../../utils";
import parse from 'html-react-parser';
import i18next from "../../i18n";

type EnterEmailProps = {
    isEmailNotFound?: boolean;
    onSubmit: (email: string) => void;
}
export const EnterEmail: React.FC<EnterEmailProps> = ({
    isEmailNotFound = false,
    onSubmit
}) => {
    const [email, setEmail] = React.useState<string>("");
    const isValid = React.useMemo(() => {
        return email.length > 0 && isValidEmail(email) && email.length <= 50;
    }, [email]);
    
    return (
        <div className="flex flex-col gap-[48px] p-6 lg:p-[48px] lg:min-w-[478px]">
            <Navigator
                current={1}
                total={3}
            />
            <div className="flex flex-col gap-[8px]">
                <h3 className="text-center text-heading-5-bold text-high-em">
                    {parse(i18next.t('forgotPassword.enterYourEmailOrUsername'))}
                </h3>
                <div className="text-center text-med-em text-sub">
                    {parse(i18next.t('forgotPassword.sendItToYourRegisteredEmail'))}
                </div>
            </div>
            <div className="flex flex-col gap-[24px]">
                <div className="space-y-1">
                    <HRInput
                        isError={isEmailNotFound}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        size="large"
                        maxLength={50}
                        placeholder={i18next.t("forgotPassword.accountEmail") ?? ""}
                        prefix={<ICUserV2 width={24} height={24} fill="var(--icon-low-em)" />}
                    />
                    <div className="pt-2 font-medium text-xs text-error-500">{isEmailNotFound ? i18next.t("forgotPassword.emailNotExist") : ""}</div>
                </div>
                <HRButton
                    disabled={!isValid}
                    onClick={onSubmit.bind(this, email)}
                >
                    <div className="space-x-2 flex items-center justify-center">
                        <span>{i18next.t('forgotPassword.continue')}</span>
                        <ICNext fill={!isValid ? '#D9D8DC' : 'white'} />
                    </div>
                </HRButton>
            </div>

            <div className="text-body text-center">
                <Link to="/login">
                    <a href="#" className="text-info-500 underline text-body">{i18next.t('forgotPassword.returnToLogin')}</a>
                </Link>
            </div>
        </div>
    )
}
