import { Button, Modal, Popover, Rate, Row, Table, Tag } from "antd"
import { ColumnType, ColumnsType } from "antd/lib/table";
import React from "react";
import classNames from "classnames";
import Highlighter from "react-highlight-words";
import { SortOrder, TableRowSelection } from "antd/lib/table/interface";
import { useSortData } from ".";
import styled from 'styled-components';
import { IPagination } from "../../../library/models";
import { ICCreateLinkV2, ICDelete, ICEmptyCandidate, ICForward, ICMoreVertical, ICNotificationV2, ICSendV3, ICSort, ICUserV3 } from "../../../../icons";
import { useNotification } from "../../../../hooks/useNotification";
import { HRButton, IconButton } from "../../../../components/button";
import { HRPagination } from "../../../../components/pagination";
import { ECandidateAssessmenStatus, EEvaluateStatus, EEvaluateStatusOptions, TAssessmentTesting, candidateStatusMapping } from "../../../testing/type";
import { getStatusStyleCandidate } from "../../../my_test/helper";
import { formatLocaleDateString } from "../../../../utils/date";
import { TCandidate, TInvitationCandidate } from "../../type";
import { useAssessment } from "../../create-assessment/hooks/useAssessment";
import { useAssementDetailsData } from "..";
import { useCopyToClipboard } from "../../../../hooks/useCopyToClipboard";
import { useDispatch } from 'react-redux';
import { setLoading } from "../../../../redux/slices/appInfo";
import { HRTooltip } from "../../../../components/tooltip";
import { HRSelect } from "../../../../components/form/select";
import { rejectCandidate, rejectMultiCandidate, sendResultCandidate, updateEvaluateStatus } from "../../../../api/test-candidate";
import { useGeneralPageData } from "../../../general";
import { useNavigation } from "../../../../hooks/useNavigate";
import i18next from "../../../../i18n";
import { useMobile } from "../../../../hooks/useDimensions";

const WrapperTable = styled.div`
    .ant-table-thead>tr>th {
        border: 0;
        .ant-checkbox-inner {
            width: 20px;
            height: 20px;
        }
        
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #393F52 !important;
            border-color: #393F52 !important;
        }
        
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #7357FF !important;
            border-color: #7357FF !important;
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner:after {
            background-color: #7357FF !important;
        }
        
        .ant-checkbox-checked .ant-checkbox-inner::after {
            left: 30.5%;
        }
    }
    .ant-table-tbody>tr.ant-table-row>td {
        border-top: 1px solid transparent !important;
        border-bottom: 1px solid transparent !important;
        padding: 16px 16px 16px 0 !important;

        .ant-checkbox-inner {
            width: 20px;
            height: 20px;
        }
        
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #393F52 !important;
            border-color: #393F52 !important;
        }
        
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #7357FF !important;
            border-color: #7357FF !important;
        }
        
        .ant-checkbox-checked .ant-checkbox-inner::after {
            left: 30.5%;
        }
    }
    .ant-table-tbody>tr.ant-table-row >td:first-child {
        border-left: 1px solid transparent !important;
        padding-left: 16px !important;
    }
    .ant-table-tbody>tr.ant-table-row >td:last-child {
        border-right: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row:hover>td:first-child, .ant-table-tbody>tr>td.ant-table-cell-row-hover:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 12px;
    }
    .ant-table-tbody>tr.ant-table-row:hover>td:last-child, .ant-table-tbody>tr>td.ant-table-cell-row-hover:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 12px;
    }
`;

const WrapperActions = styled.div`
    &:after {
        border-top: 10px solid #F1EEFF;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: "";
        display: block;
        height: 0;
        position: absolute;
        bottom: -10px;
        left: 16px;
        width: 0;
        z-index: 1;
    }
`;

enum CONFIRM_TYPE_DIALOG {
    SEND_RESULT = 'SEND_RESULT',
    REJECT = 'REJECT',
    DELETE = 'DELETE',
};

type PopoverContentProps = {
    onClose: () => void;
    onDeleteCandidate: () => void;
    onCopyLink: () => void;
    onRejectCandidate: (value: TAssessmentTesting) => void;
    onSendResultCandidate: (value: TAssessmentTesting) => void;
    reminderCandidate: () => void;
    id: string;
    data: TAssessmentTesting;
}
const PopoverContent: React.FC<PopoverContentProps> = ({ id, data, onClose, onDeleteCandidate, onCopyLink, onRejectCandidate, onSendResultCandidate, reminderCandidate }) => {
    const onCloseWrapper = React.useCallback((event: any) => {
        if (event) {
            event.stopPropagation();
        }
        onClose();
    }, [onClose]);

    const reminderCandidateWrapper = React.useCallback((event: any) => {
        if (event) {
            event.stopPropagation();
        }
        onClose();
        reminderCandidate();
    }, [onClose, reminderCandidate]);

    const deleteWrapper = React.useCallback((event: any) => {
        if (event) {
            event.stopPropagation();
        }
        onDeleteCandidate();
    }, [onDeleteCandidate]);

    const onCopyLinkWrapper = React.useCallback((event: any) => {
        if (event) {
            event.stopPropagation();
        }
        onClose();
        onCopyLink();
    }, [onCopyLink, onClose]);

    const onRejectCandidateWrapper = React.useCallback((event: any) => {
        if (event) {
            event.stopPropagation();
        }
        onClose();
        onRejectCandidate(data);
    }, [onRejectCandidate, data, onClose]);
    const onSendResultCandidateWrapper = React.useCallback((event: any) => {
        if (event) {
            event.stopPropagation();
        }
        onClose();
        onSendResultCandidate(data);
    }, [onSendResultCandidate, data, onClose]);

    const inactiveDeleteButton = React.useMemo(() => {
        return data?.status === ECandidateAssessmenStatus.IN_PROGRESS && !data?.isResting;
    }, [data]);

    const renderDoneStatus = React.useMemo(() => {
        return (
            <div className="flex flex-col gap-0 w-[206px] bg-transparent">
                <div className="rounded-[12px] my-[8px] bg-white" style={{
                    filter: 'drop-shadow(0px 4px 16px rgba(98, 112, 140, 0.24))'
                }}>
                    <div onClick={onSendResultCandidateWrapper}>
                        <div className="flex space-x-3 items-center text-[14px] leading-[24px] font-medium px-4 py-3 hover:bg-n-200 cursor-pointer">
                            <ICSendV3 />
                            <span>{i18next.t('assessmentDetail.sendResult')}</span>
                        </div>
                    </div>
                    <div onClick={onCopyLinkWrapper} className="flex space-x-3 items-center text-[14px] leading-[24px] font-medium bg-white px-4 py-3 hover:bg-n-200 cursor-pointer">
                        <ICCreateLinkV2 stroke="#E2E4EB" />
                        <span className="text-[#E2E4EB]">{i18next.t('assessmentDetail.copyTestUrl')}</span>
                    </div>
                    <div onClick={onRejectCandidateWrapper} className="flex space-x-3 items-center text-[14px] leading-[24px] font-medium bg-white px-4 py-3 hover:bg-n-200 cursor-pointer">
                        <ICUserV3 />
                        <span>{i18next.t('assessmentDetail.rejectCandidate')}</span>
                    </div>
                </div>
                <div onClick={deleteWrapper}
                    style={{
                        boxShadow: '0px 4px 16px rgba(98, 112, 140, 0.24)'
                    }}
                    className="flex space-x-3 items-center bg-white leading-[24px] rounded-[12px] px-4 py-3 text-[14px] font-medium text-[#F55858] hover:bg-n-200 cursor-pointer">
                    <ICDelete fill="#F55858" />
                    <span>{i18next.t('assessmentDetail.deleteCandidate')}</span>
                </div>
            </div>
        )
    }, [onSendResultCandidateWrapper, deleteWrapper, onCopyLinkWrapper, onRejectCandidateWrapper]);

    return (
        <>
            {data.status === 'done' && renderDoneStatus}
            {data.status !== 'done' && <div className="flex flex-col gap-0 w-[206px] bg-transparent">
                <div onClick={reminderCandidateWrapper}
                    style={{
                        boxShadow: '0px 4px 16px rgba(98, 112, 140, 0.24)'
                    }}
                    className="bg-white rounded-[12px]">
                    <div className="flex space-x-3 items-center text-[14px] leading-[24px] font-medium px-4 py-3 hover:bg-n-200 cursor-pointer">
                        <ICNotificationV2 />
                        <span className="text-[#7357FF]">{i18next.t('assessmentDetail.remindCandidate')}</span>
                    </div>
                </div>
                <div className="rounded-[12px] my-[8px] bg-white" style={{
                    filter: 'drop-shadow(0px 4px 16px rgba(98, 112, 140, 0.24))'
                }}>
                    <div onClick={onCloseWrapper}>
                        <div className="flex space-x-3 items-center text-[14px] leading-[24px] font-medium px-4 py-3 hover:bg-n-200 cursor-pointer">
                            <ICSendV3 fill="#E2E4EB" />
                            <span className="text-[#E2E4EB]">{i18next.t('assessmentDetail.sendResult')}</span>
                        </div>
                    </div>
                    <div onClick={onCopyLinkWrapper} className="flex space-x-3 items-center text-[14px] leading-[24px] font-medium bg-white px-4 py-3 hover:bg-n-200 cursor-pointer">
                        <ICCreateLinkV2 />
                        <span>{i18next.t('assessmentDetail.copyTestUrl')}</span>
                    </div>
                    <div onClick={onRejectCandidateWrapper} className="flex space-x-3 items-center text-[14px] leading-[24px] font-medium bg-white px-4 py-3 hover:bg-n-200 cursor-pointer">
                        <ICUserV3 />
                        <span>{i18next.t('assessmentDetail.rejectCandidate')}</span>
                    </div>
                </div>
                {!inactiveDeleteButton && <div onClick={deleteWrapper}
                    style={{
                        boxShadow: '0px 4px 16px rgba(98, 112, 140, 0.24)'
                    }}
                    className="flex space-x-3 items-center bg-white leading-[24px] rounded-[12px] px-4 py-3 text-[14px] font-medium text-[#F55858] hover:bg-n-200 cursor-pointer">
                    <ICDelete fill="#F55858" />
                    <span>{i18next.t('assessmentDetail.deleteCandidate')}</span>
                </div>}
            </div>}
        </>
    )
}

const TitleRenderer: React.FC<{ title: string }> = ({ title }) => {
    const keyword = React.useContext(KeywordContext);

    return <Highlighter
        highlightClassName="text-highlight"
        searchWords={[`${keyword || ''}`]}
        autoEscape={true}
        className="text-body text-high-em"
        textToHighlight={title || ''}
    />
}
type SortableColumnProps = {
    title: string;
    order?: SortOrder;
    name: string;
}
const SortableColumn: React.FC<SortableColumnProps> = ({
    title,
    order,
    name
}) => {
    const { setSortData } = useSortData();
    return <div className="flex space-x-3 items-center select-none min-w-[100px] group" onClick={() => {
        setSortData({
            field: order === 'descend' ? 'invitedAt' : name,
            order: order === 'descend' ? 'desc' : !order ? 'asc' : 'desc',
        });
    }}>
        <span className="text-body-bold text-high-em">{title as string}</span>
        <ICSort order={order} />
    </div>
}

type CandidateTableProps = {
    candidateList: TAssessmentTesting[];
    setCandidateList: (assessmentList: TAssessmentTesting[]) => void;
    status: ECandidateAssessmenStatus | undefined;
    keyword?: string;
    pagination?: IPagination;
    onChange: ({ page }: { page: number }) => void;
    reloadData: () => void;
    setCandidateFullList: React.Dispatch<React.SetStateAction<TCandidate[]>>;
    filterParams?: any;
    selectedCandidateRowKeys: React.Key[];
    setSelectedCandidateRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
}
const KeywordContext = React.createContext<string>('');

export const CandidateTable: React.FC<CandidateTableProps> = ({
    candidateList,
    setCandidateList,
    keyword,
    pagination,
    onChange,
    reloadData,
    setCandidateFullList,
    selectedCandidateRowKeys,
    setSelectedCandidateRowKeys,
}) => {
    const { navigate } = useNavigation();
    const dispatch = useDispatch();
    const { setShowComingSoonModal } = useGeneralPageData();
    const { removeInvitationCandidate, removeInvitationMultiCandidate } = useAssessment();
    const { assessmentId, setCvs } = useAssementDetailsData();
    const isMobile = useMobile();
    const { showSuccess, showError } = useNotification();
    const onChangePaging = (page: number) => {
        onChange({ page })
    };

    const [showConfirmSendResult, setShowConfirmSendResult] = React.useState(false);
    const [showRejectModal, setShowRejectModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    const [candidateSelection, setCandidateSelection] = React.useState<TAssessmentTesting>();
    const [candidateId, setCandidateId] = React.useState('');
    const [candidateMultiSelection, setCandidateMultiSelection] = React.useState<TAssessmentTesting[]>([]);
    const [actionMode, setActionMode] = React.useState<'single' | 'multi'>('single');

    const onUpdateEvaluateStatus = React.useCallback(async (testCandidateId: string, evaluateStatus: EEvaluateStatus) => {
        try {
            setShowRejectModal(false);
            dispatch(setLoading(true));
            await updateEvaluateStatus(testCandidateId, evaluateStatus);
            dispatch(setLoading(false));
            showSuccess(i18next.t('assessmentDetail.updatedEvaluateStatus'));
        } catch (error) {
            showError('Error', i18next.t('assessmentDetail.errorOccurred'));
        }
        finally {
            dispatch(setLoading(false));
            reloadData();
        }
    }, [dispatch, showError, showSuccess, reloadData]);

    const onRejectCandidate = React.useCallback(async () => {
        try {
            setShowRejectModal(false);
            if (actionMode === 'single') {
                dispatch(setLoading(true));
                await rejectCandidate(candidateSelection?._id || '');
                dispatch(setLoading(false));
                showSuccess(i18next.t('assessmentDetail.candidateRejected'));
            } else {
                // multi actions
                dispatch(setLoading(true));
                await rejectMultiCandidate(assessmentId, selectedCandidateRowKeys as string[]);
                dispatch(setLoading(false));
                showSuccess(i18next.t('assessmentDetail.candidateRejected'));
            }
        } catch (error) {
            showError('Error', i18next.t('assessmentDetail.errorOccurred'));
        }
        finally {
            dispatch(setLoading(false));
            reloadData();
        }
    }, [dispatch, assessmentId, actionMode, candidateSelection, selectedCandidateRowKeys, reloadData, showSuccess, showError]);

    const onSendResultCandidate = React.useCallback(async () => {
        try {
            dispatch(setLoading(true));
            await sendResultCandidate(candidateSelection?._id || '');
            dispatch(setLoading(false));
            showSuccess(i18next.t('assessmentDetail.resultSended'));
        } catch (error) {
            dispatch(setLoading(false));
            showError('Error', i18next.t('assessmentDetail.errorOccurred'));
        }
    }, [dispatch, candidateSelection, showSuccess, showError]);

    const onDeleteCandidate = React.useCallback(async () => {
        try {
            const reloadData = () => {
                setCandidateList(candidateList.filter(d => d?.candidate?._id !== candidateId));
                setCandidateFullList(prev => prev.filter(d => d._id !== candidateId));
                setCvs(prev => {
                    const indexCVSelected = prev.findIndex((item) => item._id !== candidateId);
                    if (prev[indexCVSelected]?.hasInvited) {
                        prev[indexCVSelected].hasInvited = false;
                    }
                    return prev;
                });
            };
            if (actionMode === 'single') {
                dispatch(setLoading(true));
                const candidate = candidateList.find(d => d?.candidate?._id === candidateId);
                const payload: TInvitationCandidate = {
                    assessmentId,
                    candidateId
                };
                const fullName = i18next.t('assessmentDetail.deleted', { t: `${candidate?.candidate?.firstName || ''} ${candidate?.candidate?.lastName || ''}` });
                await removeInvitationCandidate(payload, reloadData, fullName);
            } else {
                // multi actions
                await removeInvitationMultiCandidate(assessmentId, selectedCandidateRowKeys as string[], reloadData);
            }
        } catch (error) {
            showError('Error', i18next.t('assessmentDetail.errorOccurred'));
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [actionMode, dispatch, candidateList, assessmentId, candidateId, removeInvitationCandidate, setCandidateList, setCandidateFullList, setCvs, removeInvitationMultiCandidate, selectedCandidateRowKeys, showError]);

    const showConfirmModal = React.useCallback((type: CONFIRM_TYPE_DIALOG, data: TAssessmentTesting) => {
        setCandidateSelection(data);
        setActionMode('single');
        if (type === CONFIRM_TYPE_DIALOG.REJECT) {
            setShowRejectModal(true);
        }
        if (type === CONFIRM_TYPE_DIALOG.SEND_RESULT) {
            setShowConfirmSendResult(true);
        }
    }, [setShowRejectModal]);

    const onChangeData = React.useCallback((id: string, value: any) => {
        setCandidateList(candidateList.map(assessment => {
            if (assessment._id === id) {
                return {
                    ...assessment,
                    status: value
                }
            }
            return assessment;
        }));
    }, [setCandidateList, candidateList]);

    // show delete candidate modal
    const onShowDeleteModal = React.useCallback((id: string) => {
        setCandidateId(id);
        setActionMode('single');
        setShowDeleteModal(true);
    }, [setShowDeleteModal]);

    const columns: ColumnsType<TAssessmentTesting> = [
        {
            title: i18next.t('assessmentDetail.name'),
            dataIndex: 'candidate',
            key: 'candidate',
            sorter: true,
            render: (value: any) => {
                const fullName = `${value?.firstName || ''} ${value?.lastName || ''}`;
                return <div className="min-w-[150px]">
                    <TitleRenderer title={fullName} />
                </div>
            },
        },
        {
            title: i18next.t('assessmentDetail.invitedAt'),
            dataIndex: 'invitedAt',
            key: 'invitedAt',
            sorter: true,
            width: '162px',
            render: (invitedAt: number) => {
                if (invitedAt) {
                    return <div className="pr-2 text-body text-high-em w-[162px]">
                        <span>{formatLocaleDateString(invitedAt)}</span>
                    </div>
                }
                return null;
            }
        },
        {
            title: i18next.t('assessmentDetail.score'),
            dataIndex: 'score',
            key: 'score',
            width: '60px',
            sorter: true,
            align: 'center' as const,
            render: (score: number) => {
                return <div className="text-body text-high-em text-center w-[60px]">
                    <span>{score === -1 || score === null ? '-' : score}</span>
                </div>
            }
        },
        {
            title: i18next.t('assessmentDetail.rating'),
            dataIndex: 'rating',
            key: 'rating',
            sorter: true,
            width: '175px',
            render: (rating: number) => {
                return <div className="w-[175px]">
                    <Rate value={rating ?? 0} />
                </div>
            }
        },
        {
            title: i18next.t('assessmentDetail.status'),
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: '135px',
            render: (status: ECandidateAssessmenStatus) => {
                return <Tag style={{
                    borderRadius: '144px',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '16px',
                    padding: '4px 8px',
                    width: '135px',
                    border: 0,
                    ...getStatusStyleCandidate(status)
                }}>
                    {candidateStatusMapping[status] ?? status}
                </Tag>
            },
        },
        {
            title: i18next.t('assessmentDetail.evaluateStatus'),
            dataIndex: 'evaluateStatus',
            key: 'evaluateStatus',
            sorter: true,
            width: '20%',
            render: (status: EEvaluateStatus, data: TAssessmentTesting) => {
                return <div className="text-left pr-2 w-full min-w-[160px]" onClick={(event) => event.stopPropagation()}>
                    <HRSelect
                        options={EEvaluateStatusOptions}
                        placeholder={i18next.t('assessmentDetail.selectEvaluateStatus')}
                        value={status}
                        className="w-full"
                        allowClear={false}
                        onChange={(value: EEvaluateStatus) => onUpdateEvaluateStatus(data._id ?? '', value)}
                    />
                </div>
            },
        },
        {
            title: '',
            dataIndex: '_id',
            key: '_id',
            width: '40px',
            render: (_id: string, data: TAssessmentTesting) => {
                return <ItemActions
                    id={_id}
                    data={data}
                    reloadData={reloadData}
                    onShowDeleteModal={onShowDeleteModal}
                    onChangeData={onChangeData}
                    onRejectCandidate={() => {
                        setShowComingSoonModal(true);
                    }}
                    onSendResultCandidate={() => showConfirmModal(CONFIRM_TYPE_DIALOG.SEND_RESULT, data)}
                />
            }
        },
        {
            title: '',
            dataIndex: 'url',
            key: 'url',
            width: '40px',
            render: () => {
                return <span className="flex w-[40px]">
                    <ICForward />
                </span>;
            }
        }
    ].map((col: ColumnType<TAssessmentTesting>) => {
        if (col.sorter) {
            const oldTitle = col.title;
            col.title = ({ sortColumns }) => {
                const sortedColumn = sortColumns?.find(({ column }) => column.key === col.key);
                const columnSort = (col.dataIndex as string) === 'candidate' ? 'candidate.firstName' : col.dataIndex as string;
                return <SortableColumn
                    title={oldTitle as string}
                    order={sortedColumn?.order}
                    name={columnSort}
                />
            }
        } else {
            col.title = <div className="font-bold text-base">{col.title as string}</div>;
        }
        col.showSorterTooltip = false;
        return col
    });

    const selectedRowOnCurrentPage = React.useMemo(() => {
        return candidateList.filter(candidate => candidate._id && selectedCandidateRowKeys.includes(candidate._id));
    }, [candidateList, selectedCandidateRowKeys]);

    const selectedRowOtherPage = React.useMemo(() => {
        return selectedCandidateRowKeys?.length - selectedRowOnCurrentPage?.length;
    }, [selectedRowOnCurrentPage, selectedCandidateRowKeys]);

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
        // TODO: will work after
        // if (newSelectedRowKeys.length === 0) {
        //     setSelectedCandidateRowKeys([]);
        //     return;
        // }
        // const hasItemExist = newSelectedRowKeys.some(item => selectedCandidateRowKeys.includes(item));
        // if (hasItemExist) {
        //     // remove newSelectedRowKeys from selectedCandidateRowKeys
        //     setSelectedCandidateRowKeys(prev => {
        //         return _.filter(prev, (item) => {
        //             return !newSelectedRowKeys.includes(item);
        //         }
        //         );
        //     });
        // } else {
        //     setSelectedCandidateRowKeys(prev => {
        //         return _.union([...prev, ...newSelectedRowKeys]);
        //     });
        // }
        setSelectedCandidateRowKeys(newSelectedRowKeys);
        setCandidateMultiSelection(selectedRows);
    };

    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys: selectedCandidateRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: 'odd',
                text: 'Select Odd Row',
                onSelect: changableRowKeys => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return false;
                        }
                        return true;
                    });
                    setSelectedCandidateRowKeys(newSelectedRowKeys);
                },
            },
            {
                key: 'even',
                text: 'Select Even Row',
                onSelect: changableRowKeys => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return true;
                        }
                        return false;
                    });
                    setSelectedCandidateRowKeys(newSelectedRowKeys);
                },
            },
        ],
    };

    const candidateInprocessAndInvited = React.useMemo(() => {
        return candidateMultiSelection.some(candidate => candidate.status === ECandidateAssessmenStatus.INVITED || candidate.status === ECandidateAssessmenStatus.IN_PROGRESS);
    }, [candidateMultiSelection]);
    const candidateDone = React.useMemo(() => {
        return candidateMultiSelection.some(candidate => candidate.status === ECandidateAssessmenStatus.DONE);
    }, [candidateMultiSelection]);

    const onShowRejectModal = React.useCallback(() => {
        setActionMode('multi');
        setShowRejectModal(true);
    }, [setShowRejectModal]);

    const onShowDeleteCandidateModal = React.useCallback(() => {
        setActionMode('multi');
        setShowDeleteModal(true);
    }, [setShowDeleteModal]);

    return <div className="flex flex-col" id="candidate-table">
        {candidateMultiSelection?.length > 0 && <WrapperActions className="relative flex justify-start text-left item-start bg-primary-p-50">
            <div className="flex justify-between items-center w-full p-[16px]">
                <span className="text-left text-body-medium text-high-em">
                    {i18next.t('assessmentDetail.invitedAt', { 
                        candidateMultiSelection: candidateMultiSelection?.length, 
                        selectedRowOnCurrentPage: selectedRowOnCurrentPage?.length, 
                        selectedCandidateRowKeys: selectedRowOnCurrentPage?.length === 0 ? selectedCandidateRowKeys?.length : selectedRowOtherPage 
                    })}
                </span>
                <span className="flex flex-row gap-[16px]">
                    <HRTooltip
                        placement="bottom"
                        content={i18next.t('assessmentDetail.remindCandidate')}
                        childNode={(
                            <IconButton
                                onClick={() => setShowComingSoonModal(true)}
                                btnType="secondary" btnSize="sm"
                                icon={<ICNotificationV2 height={18} width={18} />}
                            />
                        )}
                    >
                    </HRTooltip>
                    <HRTooltip
                        placement="bottom"
                        content={i18next.t('assessmentDetail.rejectCandidate')}
                        childNode={(
                            <IconButton
                                btnType="secondary" btnSize="sm"
                                onClick={() => {
                                    setShowComingSoonModal(true);
                                }}
                                icon={<ICUserV3 fill="#7357FF" height={18} width={18} />}
                            />
                        )}
                    >
                    </HRTooltip>
                    <HRTooltip
                        placement="bottom"
                        content={i18next.t('assessmentDetail.deleteCandidate')}
                        childNode={(
                            <IconButton
                                btnType="secondary"
                                btnSize="sm"
                                icon={<ICDelete fill="#F55858" height={18} width={18} />}
                                onClick={onShowDeleteCandidateModal} />
                        )}
                    >
                    </HRTooltip>
                </span>
            </div>
        </WrapperActions>}
        <WrapperTable className="grid grid-cols-1">
            <KeywordContext.Provider value={keyword ?? ''}>
                <Table
                    className="white-header overflow-x-auto"
                    columns={columns}
                    dataSource={candidateList}
                    rowClassName="cursor-pointer data-row"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                navigate(`/assessment/${assessmentId}/test/${record?._id}/details`, {
                                    state: {
                                        search: window.location.search,
                                        hash: "candidate-table"
                                    }
                                });
                            }
                        }
                    }}
                    rowKey="_id"
                    locale={{
                        emptyText: <EmptyTable keyword={keyword} />
                    }}
                    pagination={false}
                />
            </KeywordContext.Provider>
            {pagination && pagination.totalPages > 1 && <Row gutter={16} className="grid items-center justify-center lg:justify-end w-full py-[12px] px-[16px] border-t-[1px] border border-[#ECECED]" style={{
                margin: 0
            }}>
                <HRPagination
                    onChange={onChangePaging}
                    defaultCurrent={pagination?.page}
                    defaultPageSize={pagination?.limit}
                    total={pagination?.totalDocuments}
                    currentPage={pagination?.page}
                    showSizeChanger={false}
                    size={isMobile ? 'small' : 'default'}
                />
            </Row>}

            <Modal
                centered
                onCancel={() => setShowConfirmSendResult(false)}
                open={showConfirmSendResult}
                title={
                    <div className="font-bold text-[18px]">
                        {i18next.t('assessmentDetail.sendResultToCandidate')}
                    </div>
                }
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <HRButton
                            btnSize="sm"
                            btnType="secondary"
                            onClick={() => setShowConfirmSendResult(false)}>
                            {i18next.t('assessmentDetail.cancel')}
                        </HRButton>
                        <HRButton
                            btnSize="sm"
                            onClick={() => {
                                onSendResultCandidate();
                                setShowConfirmSendResult(false);
                            }}>
                            {i18next.t('assessmentDetail.send')}
                        </HRButton>
                    </div>
                }>
                <p>{i18next.t('assessmentDetail.sendResultToCandidateByEmail')}</p>
            </Modal>

            <Modal
                centered
                onCancel={() => setShowDeleteModal(false)}
                open={showDeleteModal}
                title={
                    <div className="text-title-bold text-high-em">{i18next.t('assessmentDetail.deleteCandidate')}</div>
                }
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <HRButton
                            btnType="secondary"
                            btnSize="sm"
                            onClick={() => setShowDeleteModal(false)}>
                            {i18next.t('assessmentDetail.cancel')}
                        </HRButton>
                        <HRButton
                            btnSize="sm"
                            onClick={() => {
                                onDeleteCandidate();
                                setShowDeleteModal(false);
                            }}>
                            {i18next.t('assessmentDetail.confirm')}
                        </HRButton>
                    </div>
                }>
                <p className="mb-0 text-standard-medium text-med-em">{i18next.t('assessmentDetail.sureToDeleteCandidate')}</p>
            </Modal>

            <Modal
                centered
                onCancel={() => setShowRejectModal(false)}
                open={showRejectModal}
                title={
                    <div className="font-bold text-[18px]">{i18next.t('assessmentDetail.rejectCandidate')}</div>
                }
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <HRButton
                            btnType="secondary"
                            btnSize="sm"
                            onClick={() => setShowRejectModal(false)}>
                            {i18next.t('assessmentDetail.cancel')}
                        </HRButton>
                        <HRButton
                            btnSize="sm"
                            onClick={onRejectCandidate}>
                            {i18next.t('assessmentDetail.confirm')}
                        </HRButton>
                    </div>
                }>
                <p>{i18next.t('assessmentDetail.weWillRemindCandidate')}</p>
            </Modal>

        </WrapperTable>
    </div>
}

type IEmptyTableProps = {
    keyword: string | undefined;
}

const EmptyTable: React.FC<IEmptyTableProps> = ({ keyword }) => {
    return (
        <div className="my-6 flex flex-col gap-[32px]">
            <div className="flex justify-center">
                <span className="flex rounded-[144px] w-[100px] h-[100px] overflow-hidden">
                    <ICEmptyCandidate />
                </span>
            </div>
            <div className="flex flex-col justify-center items-center gap-[8px]">
                <span className="text-heading-6-bold text-high-em">
                    {keyword ? `${i18next.t('assessmentDetail.noResultFor')} “${keyword}”` : i18next.t('assessmentDetail.noCandidatesYet')}
                </span>
                {
                    !keyword && <span className="text-body text-high-em max-w-[500px]">
                        {i18next.t('assessmentDetail.noCandidate')}
                    </span>
                }
            </div>
        </div>
    )
}

type ItemActionsProps = {
    id: string;
    data: TAssessmentTesting;
    onChangeData: (id: string, value: string) => void;
    reloadData: () => void;
    onShowDeleteModal: (id: string) => void;
    onRejectCandidate: (value: TAssessmentTesting) => void;
    onSendResultCandidate: (value: TAssessmentTesting) => void;
}
export const ItemActions: React.FC<ItemActionsProps> = ({
    id,
    onChangeData,
    reloadData,
    onShowDeleteModal,
    data,
    onRejectCandidate,
    onSendResultCandidate,
}) => {
    const { setShowComingSoonModal } = useGeneralPageData();
    const { showSuccess } = useNotification();
    const { copy } = useCopyToClipboard();
    const [open, setOpen] = React.useState(false);

    const onClose = React.useCallback(() => {
        setOpen(false);
    }, [])
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    const onShowConfirmDeleteModal = React.useCallback(() => {
        onShowDeleteModal(data?.candidate?._id);
        onClose();
    }, [data, onClose, onShowDeleteModal]);

    const onCopyLink = React.useCallback(() => {
        if (data?.url) {
            const baseUrl = `${window.location.origin}/testing/${data.url}`;
            copy(baseUrl);
            showSuccess(i18next.t('assessmentDetail.copiedTestUrl'));
        }
    }, [data, copy, showSuccess]);

    return (
        <div className="text-center">
            <Popover
                open={open}
                placement="bottomRight"
                content={<PopoverContent
                    id={id}
                    data={data}
                    onClose={onClose}
                    onDeleteCandidate={onShowConfirmDeleteModal}
                    onCopyLink={onCopyLink}
                    onRejectCandidate={() => onRejectCandidate(data)}
                    onSendResultCandidate={() => onSendResultCandidate(data)}
                    reminderCandidate={() => setShowComingSoonModal(true)}
                />}
                showArrow={false}
                className="popover-more"
                prefixCls="hr-popover ant-popover"
                trigger="click"
                style={{
                    backgroundColor: "transparent",
                }}
                onOpenChange={handleOpenChange}
            >
                <Button onClick={(event) => event.stopPropagation()} ghost style={{
                    width: "32px",
                    height: "32px",
                    padding: "0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                >
                    <ICMoreVertical />
                </Button>
            </Popover>
        </div>
    )
}
