import React from "react";
import { TAdditionalQuestion, TAssessment, TReviewQuestion } from "../type";
import { FormInstance } from 'antd';
import { TStepItemProps } from '../config';
import { ILibrary, TRatingLevel } from "../../../library/models";
import { TAssessmentData } from "../../type";
import { EQuestionType, TChoiceQuestionType } from "../../../create_test/tab/question/type";

export type TAssementData = {
    step: number; 
    setStep: React.Dispatch<React.SetStateAction<number>>;
    onChangeStep: (step: number) => void;

    tests: ILibrary[];
    setTests: React.Dispatch<React.SetStateAction<ILibrary[]>>;

    generalForm: FormInstance<any>;
    stepAssessment: TStepItemProps[];
    
    myAssessment: TAssessmentData[];
    setMyAssessment: React.Dispatch<React.SetStateAction<TAssessmentData[]>>;

    assessment: TAssessment;
    setAssessment: React.Dispatch<React.SetStateAction<TAssessment>>;

    showAddQuestion: boolean;
    setShowAddQuestion: (
        show: boolean,
        type?: TChoiceQuestionType,
        indexQuestionSelected?: number
    ) => void;
    selectedQuestion: TAdditionalQuestion | undefined;
    // TODO: Need to check with the team for the type of this function
    selectedQuestionType: EQuestionType.SINGLE_CHOICE | EQuestionType.MULTIPLE_CHOICE | EQuestionType.ESSAY;
    setSelectedQuestionType: React.Dispatch<React.SetStateAction<EQuestionType.SINGLE_CHOICE | EQuestionType.MULTIPLE_CHOICE | EQuestionType.ESSAY>>;
    onCreatedQuestion: (question: TAdditionalQuestion) => void;

    // step three
    additionalQuestions: TAdditionalQuestion[];
    setAdditionalQuestions: React.Dispatch<React.SetStateAction<TAdditionalQuestion[]>>;
    onChangeAdditionalQuestion?: (value: TAdditionalQuestion) => void;

    // step four
    reviewQuestion: TReviewQuestion[];
    setReviewQuestion: React.Dispatch<React.SetStateAction<TReviewQuestion[]>>;

    isPrompt: boolean;
    setIsPrompt: React.Dispatch<React.SetStateAction<boolean>>;

    ratingLevel: TRatingLevel[] | undefined;

}
export const AssementDataContext = React.createContext<TAssementData>({} as TAssementData);
export const useAssementData = () => React.useContext(AssementDataContext);
