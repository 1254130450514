import { HRButton } from "../../components/button"
import { ICNext } from "../../icons"
import React from "react";
import { useTesting } from "./hooks/useTesting";
import { useTestingPageContext } from ".";
import { useNotification } from "../../hooks/useNotification";
import { ERROR_CODE, ERROR_CODE_DESC_MESSAGE, ERROR_CODE_TITLE_MESSAGE, EVENT_NAME_SOCKET } from "../../utils/constants";
import { ErrorLink } from "../../components/errorLink";
import { setLoading } from "../../redux/slices/appInfo";
import { useDispatch } from "react-redux";
import { useScreenRecord } from "../../hooks/useScreenRecord";
import i18next from "../../i18n";
import parse from 'html-react-parser';

export const TestingIntro: React.FC = () => {
    const dispatch = useDispatch();
    const { getCurrentSectionTesting } = useTesting();
    const { assessmentDetails, setSectionTesting, handleFullScreen, socket, setStep, companyProfile } = useTestingPageContext();
    const isForceTurnOnCamera = assessmentDetails.assessment.forceTurnOnCamera || false;
    const { showError } = useNotification();
    const [errorCodeCandidate, setErrorCodeCandidate] = React.useState<number | undefined>(undefined);
    const {
        onStartRecording
    } = useScreenRecord();
    const onBeginTesting = React.useCallback(async () => {
        if (!socket) {
            showError(i18next.t('testing.error'), i18next.t('testing.cannotConnectToServer'));
            return;
        }
        dispatch(setLoading(true));
        await socket.emitWithAck(EVENT_NAME_SOCKET.SEND.JOIN_TEST);
        if (assessmentDetails?._id) {
            getCurrentSectionTesting(assessmentDetails?._id, handleFullScreen, setSectionTesting, setStep, setErrorCodeCandidate);
            onStartRecording({
                socket,
                assessmentId: assessmentDetails.assessment?._id!,
            })
        }
    }, [dispatch, assessmentDetails, getCurrentSectionTesting, handleFullScreen, setSectionTesting, socket, setStep, showError]);

    const candidateName = React.useMemo(() => {
        return (assessmentDetails?.candidate?.lastName || '') + " " + assessmentDetails?.candidate?.firstName;
    }, [assessmentDetails]);

    const titleError = React.useMemo(() => {
        if (errorCodeCandidate === ERROR_CODE.LOCKED) {
            return ERROR_CODE_TITLE_MESSAGE[errorCodeCandidate];
        }
        return undefined;
    }, [errorCodeCandidate]);
    const descriptionError = React.useMemo(() => {
        if (errorCodeCandidate === ERROR_CODE.LOCKED) {
            return ERROR_CODE_DESC_MESSAGE[errorCodeCandidate];
        }
        return undefined;
    }, [errorCodeCandidate]);

    return errorCodeCandidate ?
        <ErrorLink title={titleError} description={descriptionError} />
        :
        <div className="lg-container mx-auto bg-white px-8 py-[54px] rounded-[12px]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="space-y-6">
                    <div className="text-heading-5-bold text-high-em">{i18next.t('testing.hi')} {candidateName}</div>
                    <div className="text-body text-high-em">
                        <div>{i18next.t('testing.welcome')} <strong>{assessmentDetails?.assessment?.title}</strong> {companyProfile?.name ? `của công ty ${companyProfile?.name}.` : ''}</div>
                        <div>{parse(i18next.t('testing.pressStart'))}</div>
                    </div>
                    <div className="text-body text-high-em">
                        <div>{i18next.t('testing.opportunityForShowcaseYourSelf')}</div>
                        <div>{i18next.t('testing.goodLuckToYou')}</div>
                    </div>
                    {
                        isForceTurnOnCamera && <div className="text-body font-bold text-red-400">
                            {i18next.t('testing.noteTurnOnCamera')}
                        </div>
                    }
                </div>
                <div className="space-y-4">
                    <div className="text-standard-bold text-high-em">{i18next.t('testing.beforeStart')}</div>
                    <div>
                        <ul className="list-disc text-body text-high-em space-y-3">
                            {parse(i18next.t('testing.beforeStartContent', { 
                                totalTests: assessmentDetails?.overview?.totalTests || 0,
                                totalQuestions: assessmentDetails?.overview?.totalQuestions || 0,
                                totalDurations: assessmentDetails?.overview?.totalDuration || 0
                            }))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex justify-center mt-6">
                <span>
                    <HRButton
                        btnType="primary"
                        onClick={onBeginTesting}
                        style={{
                            backgroundColor: companyProfile?.color?.backgroundColor,
                            borderColor: companyProfile?.color?.backgroundColor,
                            color: companyProfile?.color?.fontColor || "#fff",
                        }}
                    >
                        <div className="flex space-x-2 items-center justify-center">
                            <span>{i18next.t('testing.start')}</span>
                            <ICNext fill={(companyProfile?.color?.fontColor || "#fff")} />
                        </div>
                    </HRButton>
                </span>
            </div>
        </div>
}
