import { IconProps } from "./type"

export const ICChevronDown: React.FC<IconProps> = ({
    stroke = "#46485C",
    width = 24,
    height = 24
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9L12 15L18 9" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
