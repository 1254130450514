import { IconProps } from "./type"

export const ICDownload: React.FC<IconProps> = ({
    width = 18,
    height = 18,
    fill = "var(--icon-high-em)"
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1.5C9.19891 1.5 9.38968 1.57902 9.53033 1.71967C9.67098 1.86032 9.75 2.05109 9.75 2.25V10.1895L11.4698 8.46975C11.6112 8.33313 11.8007 8.25754 11.9973 8.25924C12.1939 8.26095 12.3821 8.33983 12.5211 8.47889C12.6602 8.61794 12.739 8.80605 12.7408 9.0027C12.7425 9.19935 12.6669 9.3888 12.5303 9.53025L9.53025 12.5303C9.3896 12.6709 9.19887 12.7498 9 12.7498C8.80113 12.7498 8.6104 12.6709 8.46975 12.5303L5.46975 9.53025C5.39812 9.46106 5.34098 9.37831 5.30167 9.2868C5.26237 9.1953 5.24168 9.09688 5.24081 8.9973C5.23995 8.89772 5.25892 8.79896 5.29663 8.70678C5.33434 8.61461 5.39003 8.53087 5.46045 8.46045C5.53087 8.39003 5.61461 8.33434 5.70678 8.29663C5.79896 8.25892 5.89772 8.23995 5.9973 8.24081C6.09689 8.24168 6.1953 8.26237 6.2868 8.30167C6.37831 8.34098 6.46106 8.39812 6.53025 8.46975L8.25 10.1895V2.25C8.25 2.05109 8.32902 1.86032 8.46967 1.71967C8.61032 1.57902 8.80109 1.5 9 1.5ZM3.75 12.75C3.94891 12.75 4.13968 12.829 4.28033 12.9697C4.42098 13.1103 4.5 13.3011 4.5 13.5V15H13.5V13.5C13.5 13.3011 13.579 13.1103 13.7197 12.9697C13.8603 12.829 14.0511 12.75 14.25 12.75C14.4489 12.75 14.6397 12.829 14.7803 12.9697C14.921 13.1103 15 13.3011 15 13.5V15C15 15.3978 14.842 15.7794 14.5607 16.0607C14.2794 16.342 13.8978 16.5 13.5 16.5H4.5C4.10218 16.5 3.72064 16.342 3.43934 16.0607C3.15804 15.7794 3 15.3978 3 15V13.5C3 13.3011 3.07902 13.1103 3.21967 12.9697C3.36032 12.829 3.55109 12.75 3.75 12.75Z" fill={fill} />
        </svg>
    )
}
