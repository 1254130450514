import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
import translationEn from "./translation/en/en.json";
import translationVN from "./translation/vn/vn.json";
import { LANGUAGE } from "./utils/constants";

const resources = {
  en: {
    translation: translationEn,
  },
  vn: {
    translation: translationVN,
  }
}

i18next.use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('language') || 'vn', //default language
    interpolation: {
        escapeValue: false 
    }
  });

export default i18next;
