import { IconProps } from "./type"

export const ICHistory: React.FC<IconProps> = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.50005 2.5498C5.93781 2.5498 3.05005 5.43757 3.05005 8.9998C3.05005 12.562 5.93781 15.4498 9.50005 15.4498C13.0623 15.4498 15.95 12.562 15.95 8.9998C15.95 5.43757 13.0623 2.5498 9.50005 2.5498ZM1.55005 8.9998C1.55005 4.60914 5.10938 1.0498 9.50005 1.0498C13.8907 1.0498 17.45 4.60914 17.45 8.9998C17.45 13.3905 13.8907 16.9498 9.50005 16.9498C5.10938 16.9498 1.55005 13.3905 1.55005 8.9998ZM6.87812 6.30685C6.91562 5.89434 7.28042 5.59033 7.69294 5.62783L11.818 6.00284C12.1785 6.03562 12.4642 6.32129 12.497 6.68186L12.872 10.8069C12.9095 11.2194 12.6055 11.5842 12.193 11.6217C11.7805 11.6592 11.4156 11.3552 11.3781 10.9427L11.1481 8.41236L7.78036 11.7801C7.48747 12.073 7.0126 12.073 6.7197 11.7801C6.42681 11.4872 6.42681 11.0124 6.7197 10.7195L10.0875 7.3517L7.55713 7.12167C7.14462 7.08417 6.84061 6.71937 6.87812 6.30685Z" fill="#4F4B5C" />
        </svg>

    )
}
