import { ApiInstance } from "..";
import { convert2QueryString } from '../../utils/convert2QueryString';

import { ISearchCriteria } from '../../pages/library/models';
import { TFormFilter } from "../../pages/my_assessment/create-assessment/components/add-additional-test";
import { TUpdatePasswordAssessment } from "../../pages/my_assessment/type";

export const getAssessment = (payload: ISearchCriteria) => {
  const requestURL = "/api/assessment?" + convert2QueryString(payload);
  return ApiInstance.getInstance().get(requestURL);
};

export const getCustomQuestion = (payload: TFormFilter) => {
  const requestURL = "/api/question/custom-question?" + convert2QueryString(payload);
  return ApiInstance.getInstance().get(requestURL);
};

export const saveAssessment = (payload: any) => {
  return ApiInstance.getInstance().post("/api/assessment", payload);
};

export const updateAssessment = (id: string, payload: any) => {
  return ApiInstance.getInstance().put(`/api/assessment/${id}`, payload);
};

export const updateAssessmentAdditionalInfo = (id: string, payload: any) => {
  return ApiInstance.getInstance().patch(`/api/assessment/${id}`, payload);
};

export const modifyStatusAssessment = (id: string, payload: any) => {
  return ApiInstance.getInstance().put(`/api/assessment/update-status/${id}`, payload);
};

export const updateActiveURL = (id: string, payload: any) => {
  return ApiInstance.getInstance().put(`/api/assessment/update-active-url/${id}`, payload);
};

export const updatePasswordAssessment = (id: string, payload: TUpdatePasswordAssessment) => {
  return ApiInstance.getInstance().put(`/api/assessment/update-password/${id}`, payload);
};

export const getMyAssessment = (payload: ISearchCriteria) => {
  const requestURL = "/api/assessment/my-assessment?" + convert2QueryString(payload);
  return ApiInstance.getInstance().get(requestURL);
}

export const getAssessmentDetails = (id: string) => {
  return ApiInstance.getInstance().get(`/api/assessment/my-assessment/${id}`);
}

export const updateReceiveCV = (id: string, receiveCV: boolean) => {
  return ApiInstance.getInstance().post(`/api/assessment/update-receive-cv/${id}`, { receiveCV });
}

export const applyCV = (id: string, data: Record<string, any>) => {
  return ApiInstance.getInstance().post(`/api/assessment/apply-assessment/${id}`, data);
}

export const checkReceiveCvStatus = (id: string) => {
  return ApiInstance.getInstance().get(`/api/assessment/receive-cv-status/${id}`);
}

export const getCVinAssessment = (id: string, data: Record<string, any>) => {
  const requestURL = `/api/assessment/cv/${id}?` + convert2QueryString(data);
  return ApiInstance.getInstance().get(requestURL);
}

export const deleteAssessment = (id: string) => {
  return ApiInstance.getInstance().delete(`/api/assessment/${id}`);
}

export const cloneAssessment = (id: string) => {
  return ApiInstance.getInstance().post(`/api/assessment/clone/${id}`);
}

export const generateURLAssessment = (id: string) => {
  return ApiInstance.getInstance().put(`/api/assessment/generate-url/${id}`);
}

export const updateAutoInvite = (id: string, isAutoInvite: boolean) => {
  return ApiInstance.getInstance().put(`/api/assessment/update-auto-invite/${id}`, { isAutoInvite });
};

export const getRoleOnAssessment = (id: string) => {
  return ApiInstance.getInstance().get(`/api/assessment/assessment-role-by-id/${id}`);
}

export const updateAutoSendResult = (id: string, isAutoSendResult: boolean) => {
  return ApiInstance.getInstance().put(`/api/assessment/update-auto-send-result/${id}`, { isAutoSendResult });
};

export const updateEmailRepicipents = (id: string, emails: string[]) => {
  return ApiInstance.getInstance().put(`/api/assessment/update-email-recipients/${id}`, { emails });
}