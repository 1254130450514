import { IconProps } from "./type"

export const ICVNPay: React.FC<IconProps> = ({
    width = 40,
    height = 40,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M22.4252 13.6493L19.459 16.6594L19.3112 16.8072L16.1668 20.0009L16.0207 20.1507H16.0191L14.6289 21.5595V21.5615L14.483 21.7094C14.0663 22.1324 13.6057 22.4791 13.1162 22.7537C12.8684 22.8922 12.6151 23.0108 12.3559 23.112C11.778 23.3382 11.1715 23.4707 10.561 23.5098C10.1867 23.5353 9.80659 23.5253 9.43411 23.4787C8.62787 23.3808 7.83675 23.12 7.11495 22.6956C6.80589 22.5144 6.48916 22.3253 6.22585 22.0603C6.22408 22.0603 6.22408 22.0603 6.22212 22.0583L0.533695 16.2859C0.43964 16.1803 0.355208 16.0662 0.284323 15.9432C0.103676 15.6332 0 15.2739 0 14.8889C0 14.8283 0.00215992 14.7679 0.00765788 14.7094C0.0153158 14.6201 0.0288643 14.5336 0.0461436 14.4501L0.0805059 14.3118C0.157281 14.0546 0.282163 13.8115 0.455153 13.5927C0.5011 13.5371 0.549011 13.4823 0.600848 13.4275L0.696866 13.3314L6.45048 7.49053H6.45264L9.93894 3.95315C10.7165 3.16386 11.9549 3.12839 12.7706 3.85133L22.4252 13.6493Z" fill="#005AAA"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.16793 22.0049C6.18599 22.023 6.20406 22.041 6.22192 22.0585C6.48484 22.3231 6.80373 22.5142 7.11456 22.6956C7.83636 23.1202 8.62748 23.3812 9.43371 23.4789C9.806 23.5255 10.1861 23.5353 10.5606 23.5098C11.1711 23.4709 11.7778 23.3382 12.3555 23.1124C12.6147 23.0112 12.8682 22.8922 13.1156 22.7541C13.6053 22.4791 14.0657 22.1324 14.4826 21.7098L14.6285 21.5617V21.5599L16.0185 20.1513H16.0203L16.723 19.436L18.3685 17.7667L19.311 16.8074L19.4589 16.6596L25.4351 10.5951L27.9002 7.88448L28.0482 7.73662C28.8007 6.97324 30.0218 6.97324 30.774 7.73662L32.1238 9.10578H32.1258L32.1697 9.15082C31.5728 9.12192 30.97 9.33992 30.5188 9.78847L25.9515 14.3475L25.9054 14.4041L17.9575 22.3409L17.9459 22.3311L15.0187 25.2498L14.8229 25.46C14.5924 25.6879 14.1105 26.119 13.4653 26.4284C13.1735 26.5687 12.876 26.6699 12.5825 26.7325C12.4077 26.7674 12.229 26.7909 12.0545 26.7986H11.9217C11.4574 26.7986 11.0004 26.6934 10.5628 26.4908L9.18611 25.0981L8.62551 24.527L6.04913 21.8831L6.16793 22.0049ZM39.4349 19.2935L26.0173 33.1166C25.8004 33.3368 25.5738 33.5352 25.334 33.7146C25.3262 33.7226 25.3165 33.7301 25.3069 33.7379C25.2743 33.7614 25.2417 33.7867 25.2072 33.81C25.2032 33.814 25.1995 33.8156 25.1958 33.8198C24.1704 34.5643 22.9129 35.0006 21.5575 35.0006C19.8834 35.0006 18.3628 34.3343 17.2381 33.2491L17.0826 33.1088L11.3786 27.3187C11.5571 27.3478 11.7376 27.3614 11.918 27.3614L12.0698 27.3596C12.2791 27.3516 12.4884 27.3265 12.6936 27.2837C13.0296 27.2133 13.3693 27.0963 13.7033 26.9369C14.4213 26.5918 14.957 26.1148 15.2123 25.8613C15.3122 25.7617 15.387 25.6782 15.4179 25.6411L17.9444 23.1222L17.954 23.1337L26.641 14.4605L26.6871 14.4039L30.907 10.1896C31.4752 9.62587 32.3624 9.55194 33.0072 10.0023L34.0074 11.0171C34.0096 11.0171 34.0096 11.0191 34.0096 11.0191L39.4349 16.5249C39.4828 16.5739 39.529 16.6247 39.5712 16.6765C40.1856 17.4469 40.1394 18.5809 39.4349 19.2935Z" fill="#ED1C24"/>
            <path d="M21.5306 14.5577L20.9737 15.1222L19.9967 14.1245C19.8429 13.9671 19.8449 13.7136 19.9984 13.56C20.1541 13.4044 20.4018 13.4044 20.5553 13.562L21.5306 14.5577ZM19.8834 16.2294L19.3265 16.7921L16.2549 13.6513C15.1145 12.4865 13.45 12.0774 11.9084 12.5836C11.703 12.6517 11.4805 12.5375 11.413 12.3291C11.3458 12.1179 11.4591 11.8923 11.6663 11.824C13.49 11.2238 15.4638 11.7088 16.8135 13.0885L19.8834 16.2294ZM16.5909 19.5705L16.034 20.1356L12.9489 16.9812C12.7951 16.8256 12.7975 16.5721 12.9506 16.4157C13.1064 16.26 13.3557 16.262 13.5075 16.4185L16.5909 19.5705ZM18.2361 17.901L17.6793 18.4655L14.6018 15.3171C13.8513 14.5479 12.6223 14.5432 11.866 15.3056C11.1096 16.069 11.1039 17.3144 11.8564 18.0815L13.8393 20.1121C13.9931 20.2679 13.9931 20.521 13.8376 20.6772C13.6838 20.8328 13.4343 20.8308 13.2807 20.675L11.2961 18.6451C10.2401 17.5638 10.2458 15.8115 11.3115 14.738C12.3769 13.6656 14.1048 13.6732 15.1606 14.7544L18.2361 17.901ZM13.1333 22.7442C13.1278 22.7482 13.1219 22.7498 13.116 22.7539C12.8741 22.8882 12.6282 23.0054 12.3749 23.1048L9.6448 20.3111C7.93631 18.5638 7.69243 15.8262 9.06319 13.7993C9.18591 13.6188 9.43155 13.5717 9.61024 13.6965C9.79069 13.8212 9.83487 14.0709 9.71195 14.2534C8.55443 15.9625 8.76178 18.2728 10.2036 19.7478L13.1333 22.7442ZM18.4667 11.9939C18.3131 12.1476 18.0616 12.1438 17.908 11.9882C15.3468 9.36981 11.1656 9.35347 8.58546 11.9507C6.00711 14.5499 5.98983 18.7931 8.5509 21.4113L10.5551 23.4629C10.5687 23.4765 10.582 23.492 10.5897 23.508C10.58 23.508 10.5706 23.5098 10.561 23.5098C10.1962 23.5353 9.82741 23.5251 9.46454 23.4823L7.99207 21.9744C5.12587 19.0444 5.14315 14.2925 8.03056 11.3832C10.9199 8.4747 15.6005 8.49403 18.4667 11.4244L18.4722 11.4302C18.6261 11.5858 18.622 11.8373 18.4667 11.9939Z" fill="#009EDB"/>
        </svg>
    )
}
