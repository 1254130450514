import { EQuestionType, generateOption, TChoiceQuestion, TQuestion, TSerializedQuestion } from "../../create_test/tab/question/type";
import { uuid } from "../../create_test/tab/question/helper";
import { EStatusCard, IConfiguration, ILibraryUI, TPrice } from "../../library/models";
import { EStatusAssessment, TCandidate } from "../type";

export type TAdditionalQuestion = TChoiceQuestion & {
    id?: string;
    _id?: string;
    duration?: number; // in minutes
    title?: string;
    classification?: string;
    expectedAnswer?: string;
    why?: string; // why exist this question

    content?: string;
    where?: string;
    planTag?: EStatusCard;
    statusTag?: EStatusCard;
    configuration?: IConfiguration;

    desiredAnswer?: string;
    questionType?: EQuestionType.SINGLE_CHOICE | EQuestionType.MULTIPLE_CHOICE | EQuestionType.ESSAY;
    relatedReason?: string;
    price?: TPrice;
}

export type TReviewQuestion = {
    id: string;
    type: 'question' | 'additional_question';
    data: TAdditionalQuestion | ILibraryUI;
};
export type TEmailTemplate = {
    subject: string;
    content: string;
}
export type TAssessment = {
    title: string;
    role: string;
    language: string;
    introduce: string;
    additionalIndex?: number;
    tests?: ILibraryUI[];
    testIds: string[]; // Consider using Object to store and retrieve data
    additionalQuestions: TAdditionalQuestion[];
    totalCandidates?: number;
    totalQuestions?: number;
    url?: string;
    id?: string;
    _id?: string;
    password?: string;
    activeUrl?: boolean;
    activePassword?: boolean;
    status?: EStatusAssessment;

    isAutoSendMail?: boolean;
    
    candidate?: TCandidate;
    hasHealthIssue?: boolean;
    hasIssue?: boolean;
    hasLanguageIssue?: boolean;
    hasSubmitIssue?: boolean;
    testCandidateToken?: string;
    isApproved?: boolean;
    isSubmitted?: boolean;
    issueDetail?: string;
    rating?: number;
    score?: number;

    needToPay?: boolean;
    isPaid?: boolean;
    enableReceiveCV?: boolean;
    countGenerateUrl?: number;
    canSkipPayment?: boolean;
    isAutoInvite?: boolean;
    isResting?: boolean;
    hasInvitedCandidate?: boolean;
    emailTemplate?: TEmailTemplate;
    note?: string;
    forceTurnOnCamera?: boolean;
    emailRecipients?: string[];
}

export const BlankAssessment: TAssessment = {
    title: "",
    role: "",
    language: "",
    introduce: "",
    tests: [],
    testIds: [],
    additionalQuestions: [],
}
export type TStepItem = {
    title: string;
    component: React.ReactElement;
}
// Duplcate from create_test/tab/question/type.ts
export const DEFAULT_DURATION = 10;
export const createDefaultQuestion = (type: TAdditionalQuestion['type']): TAdditionalQuestion => {
    switch (type) {
        case EQuestionType.SINGLE_CHOICE:
            return {
                id: uuid(),
                type,
                content: '',
                isMixed: false,
                isActive: true,
                options: new Array(4).fill('').map(() => generateOption('')),
                correctAnswer: 0,
                duration: DEFAULT_DURATION,
                scoreCombination: {},
            };
        case EQuestionType.MULTIPLE_CHOICE:
            return {
                id: uuid(),
                type,
                content: '',
                isMixed: false,
                isActive: true,
                options: new Array(4).fill('').map(() => generateOption('')),
                correctAnswer: [0],
                duration: DEFAULT_DURATION,
                scoreCombination: {},
            };
        case EQuestionType.ESSAY:
            return {
                id: uuid(),
                type,
                content: '',
                isMixed: false,
                isActive: true,
                options: new Array(4).fill('').map(() => generateOption('')),
                correctAnswer: [0],
                duration: DEFAULT_DURATION,
                referenceContent: '',
                scoreCombination: {},
            };
        // case EQuestionType.CODING:
        //     return {
        //         id: uuid(),
        //         type,
        //         content: '',
        //         testCases: new Array(2).fill('').map(() => ''),
        //         expectedOutputs: new Array(2).fill('').map(() => ''),
        //         score: 0,
        //         isActive: true,
        //     };
    }
}

export const serializeAdditionalQuestion = (question: TAdditionalQuestion): TSerializedQuestion => {
    switch (question.type) {
        case EQuestionType.SINGLE_CHOICE:
        case EQuestionType.MULTIPLE_CHOICE:
            return {
                ...question,
                options: question.options ? question.options.map((option) => option.content) : [],
            }
        case EQuestionType.ESSAY:
            return {
                ...question,
                options: question.options ? question.options.map((option) => option.content) : [],
            }
        // case EQuestionType.CODING:
        //     return question;
    }
}
