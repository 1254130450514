import React from "react";
import { useAssementData } from "./hooks/useCreateAssessment"
import { Steps } from 'antd';
import styled from "styled-components";
import { ICNext } from "../../../icons";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";

const StepWrapper = styled.div`
    .ant-steps {
        padding: 0 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-steps .ant-steps-item-container {
        &:hover {
            .ant-steps-item-icon {
                border: 2.4px solid #7357FF;
                border-color: #7357FF !important;
                box-shadow: 0px 2px 4px -2px rgba(17, 12, 34, 0.12);
                .ant-steps-icon {
                    background: #7357FF;
                }
            } 
            .ant-steps-icon {
                color: white !important;
            }
            .ant-steps-item-title {
                font-weight: 700;
                font-size: 16px;
                color: #676472 !important;
            }
        }
        .ant-steps-item-content {
            margin-left: -32px;
            margin-top: 17px;
            width: 200px;
            text-align: left;
        }
        .ant-steps-item-title {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #676472;
        }
    }
    .ant-steps-label-vertical .ant-steps-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .ant-steps-item-active {
        .ant-steps-item-icon {
            border: 2.4px solid #7357FF;
            .ant-steps-icon {
                background: #7357FF;
            }
        }    
    }
    .ant-steps-item-finish {
        .ant-steps-item-icon {
            .ant-steps-icon {
                background: #F1E8FD;
                border: 2.4px solid #AF7DE7;
                border-radius: 55px;
                color: #7357FF;
            }
        }
        .ant-steps-item-tail:after {
            background-color: #7357FF !important;
        }
    }
    .ant-steps-item-tail {
        top: 25px;
        padding: 0;
        margin-left: 94px;
        width: calc(100% - 134px);
        margin-right: 40px;
    }
    .ant-steps-item-tail:after {
        background-color: #E2E4EB !important;
        height: 4px;
        border-radius: 55px;
    }
    .ant-steps-item-icon {
        width: 54px;
        height: 54px;
        border: 0;
        margin-left: 0;
        background: #F3F3F4 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-steps-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: #F3F3F4;
            color: #110C22;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            font-weight: 700;
            font-size: 22px;
            line-height: 24px;
        }
    }
`;

export const CreateAssessmentStep: React.FC = () => {
    const {
        step,
        onChangeStep,
        stepAssessment
    } = useAssementData();

    return <div>
        <StepWrapper className="hidden lg:flex justify-center pt-[66px]">
            {
                <Steps
                    current={step}
                    items={stepAssessment}
                    onChange={(value: number) => onChangeStep(value)}
                    labelPlacement={'vertical'}
                />
            }
        </StepWrapper>
        <div className="block lg:hidden text-center p-4 font-bold">
            <hr />
            <div className="flex pt-4 items-center justify-between">
                <span
                    className="cursor-pointer"
                    onClick={() => {
                        if (step > 0) {
                            onChangeStep(step - 1)
                        }
                    }}
                >
                    <LeftCircleOutlined style={{ fontSize: "20px" }} />
                </span>
                <div className="pt-1">
                    Step {step + 1} / {stepAssessment.length}: {stepAssessment[step].title}
                </div>
                <span
                    className="cursor-pointer"
                    onClick={() => {
                        if (step < stepAssessment.length - 1) {
                            onChangeStep(step + 1)
                        }
                    }}
                >
                    <RightCircleOutlined style={{ fontSize: "20px" }}/>
                </span>
            </div>
        </div>
    </div>
}
