import { IconProps } from "./type"

export const ICMicroPhone: React.FC<IconProps> = ({
    fill = "#BEC0CA",
}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.96654 2.65683C9.77134 1.85203 10.8629 1.3999 12.001 1.3999C13.1392 1.3999 14.2307 1.85203 15.0355 2.65683C15.8403 3.46163 16.2925 4.55317 16.2925 5.69133V10.0799C16.2925 11.2181 15.8403 12.3096 15.0355 13.1144C14.2307 13.9192 13.1392 14.3713 12.001 14.3713C10.8629 14.3713 9.77134 13.9192 8.96654 13.1144C8.16174 12.3096 7.70961 11.2181 7.70961 10.0799V5.69133C7.70961 4.55317 8.16174 3.46163 8.96654 2.65683ZM12.001 3.3999C11.3933 3.3999 10.8105 3.64132 10.3808 4.07105C9.95102 4.50077 9.70961 5.08361 9.70961 5.69133V10.0799C9.70961 10.6876 9.95102 11.2705 10.3808 11.7002C10.8105 12.1299 11.3933 12.3713 12.001 12.3713C12.6088 12.3713 13.1916 12.1299 13.6213 11.7002C14.051 11.2705 14.2925 10.6876 14.2925 10.0799V5.69133C14.2925 5.08361 14.051 4.50077 13.6213 4.07105C13.1916 3.64132 12.6088 3.3999 12.001 3.3999ZM4.25381 10.1875C4.80034 10.1079 5.30787 10.4865 5.38741 11.033C5.61864 12.6218 6.41414 14.0742 7.62839 15.1245C8.84264 16.1748 10.3945 16.7528 11.9999 16.7528C13.6054 16.7528 15.1572 16.1748 16.3715 15.1245C17.5857 14.0742 18.3812 12.6218 18.6125 11.033C18.692 10.4865 19.1995 10.1079 19.7461 10.1875C20.2926 10.267 20.6712 10.7745 20.5916 11.3211C20.2912 13.3853 19.2576 15.2724 17.6799 16.6371C16.3561 17.7822 14.7235 18.4952 12.9999 18.6951V21.5999C12.9999 22.1522 12.5522 22.5999 11.9999 22.5999C11.4477 22.5999 10.9999 22.1522 10.9999 21.5999V18.6951C9.27642 18.4952 7.64379 17.7822 6.31997 16.6371C4.7423 15.2724 3.70869 13.3853 3.40826 11.3211C3.32872 10.7745 3.70729 10.267 4.25381 10.1875Z" fill={fill}/>
        </svg>
    )
}

export const ICMicroPhoneV2: React.FC<IconProps> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.72496 1.9925C7.32856 1.3889 8.14722 1.0498 9.00084 1.0498C9.85446 1.0498 10.6731 1.3889 11.2767 1.9925C11.8803 2.5961 12.2194 3.41476 12.2194 4.26838V7.5598C12.2194 8.41342 11.8803 9.23208 11.2767 9.83568C10.6731 10.4393 9.85446 10.7784 9.00084 10.7784C8.14722 10.7784 7.32856 10.4393 6.72496 9.83568C6.12136 9.23208 5.78227 8.41342 5.78227 7.5598V4.26838C5.78227 3.41476 6.12136 2.5961 6.72496 1.9925ZM9.00084 2.5498C8.54504 2.5498 8.10792 2.73087 7.78562 3.05316C7.46333 3.37546 7.28227 3.81258 7.28227 4.26838V7.5598C7.28227 8.0156 7.46333 8.45272 7.78562 8.77502C8.10792 9.09731 8.54504 9.27838 9.00084 9.27838C9.45663 9.27838 9.89376 9.09731 10.2161 8.77502C10.5383 8.45272 10.7194 8.0156 10.7194 7.5598V4.26838C10.7194 3.81258 10.5383 3.37546 10.2161 3.05316C9.89376 2.73087 9.45663 2.5498 9.00084 2.5498ZM3.19042 7.64048C3.60032 7.58082 3.98096 7.86475 4.04062 8.27464C4.21404 9.4662 4.81067 10.5555 5.72135 11.3432C6.63204 12.131 7.7959 12.5645 9.00001 12.5645C10.2041 12.5645 11.368 12.131 12.2787 11.3432C13.1894 10.5555 13.786 9.4662 13.9594 8.27464C14.0191 7.86475 14.3997 7.58082 14.8096 7.64048C15.2195 7.70014 15.5034 8.08078 15.4438 8.49068C15.2184 10.0389 14.4432 11.4542 13.26 12.4777C12.2671 13.3365 11.0427 13.8713 9.75001 14.0212V16.1998C9.75001 16.614 9.41423 16.9498 9.00001 16.9498C8.5858 16.9498 8.25001 16.614 8.25001 16.1998V14.0212C6.95738 13.8713 5.73291 13.3365 4.74004 12.4777C3.55678 11.4542 2.78158 10.0389 2.55626 8.49068C2.4966 8.08078 2.78053 7.70014 3.19042 7.64048Z" fill="var(--icon-high-em)"/>
        </svg>
    )
}

export const ICMicroPhoneOff: React.FC<IconProps> = ({
    fill = "white",
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.00086 1.0498C8.14724 1.0498 7.32859 1.3889 6.72499 1.9925C6.43298 2.28451 6.20288 2.62684 6.04313 2.99911L7.28255 4.23853C7.29027 3.79354 7.47039 3.36842 7.78565 3.05316C8.10794 2.73087 8.54507 2.5498 9.00086 2.5498C9.45666 2.5498 9.89378 2.73087 10.2161 3.05316C10.5384 3.37546 10.7194 3.81258 10.7194 4.26838V7.5598C10.7194 7.59727 10.7182 7.63461 10.7158 7.67177L11.9324 8.8884C12.1198 8.47493 12.2194 8.0225 12.2194 7.5598V4.26838C12.2194 3.41476 11.8803 2.5961 11.2767 1.9925C10.6731 1.3889 9.85448 1.0498 9.00086 1.0498ZM10.7561 10.2577L9.64964 9.15121C9.44559 9.2344 9.22549 9.27838 9.00086 9.27838C8.54507 9.27838 8.10794 9.09731 7.78565 8.77502C7.46335 8.45272 7.28229 8.0156 7.28229 7.5598V6.78386L5.78229 5.28386V7.5598C5.78229 8.41342 6.12139 9.23208 6.72499 9.83568C7.32859 10.4393 8.14724 10.7784 9.00086 10.7784C9.62875 10.7784 10.2377 10.5949 10.7561 10.2577ZM12.0375 11.5391L13.1056 12.6072C12.1415 13.3903 10.9764 13.879 9.75004 14.0212V16.1998C9.75004 16.614 9.41425 16.9498 9.00004 16.9498C8.58583 16.9498 8.25004 16.614 8.25004 16.1998V14.0212C6.9574 13.8713 5.73293 13.3365 4.74007 12.4777C3.55681 11.4542 2.78161 10.0389 2.55628 8.49068C2.49662 8.08078 2.78055 7.70014 3.19044 7.64048C3.60034 7.58082 3.98099 7.86475 4.04064 8.27464C4.21406 9.4662 4.81069 10.5555 5.72138 11.3432C6.63207 12.131 7.79593 12.5645 9.00004 12.5645C10.1003 12.5645 11.1669 12.2025 12.0375 11.5391ZM14.3331 11.2891L13.2511 10.2071C13.6156 9.62337 13.8588 8.96623 13.9594 8.27464C14.0191 7.86475 14.3997 7.58082 14.8096 7.64048C15.2195 7.70014 15.5035 8.08078 15.4438 8.49068C15.2962 9.50476 14.9127 10.4618 14.3331 11.2891Z" fill="white"/>
            <path d="M2.46967 2.78012C2.76256 2.48722 3.23744 2.48722 3.53033 2.78012L15.1976 14.4474C15.4905 14.7403 15.4905 15.2151 15.1976 15.508C14.9047 15.8009 14.4298 15.8009 14.1369 15.508L2.46967 3.84078C2.17678 3.54788 2.17678 3.07301 2.46967 2.78012Z" fill="white"/>
        </svg>      
    )
}

export const ICMicroPhoneOnV2: React.FC<IconProps> = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#0084FF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.064 11.1836C16.3406 11.6616 16.1772 12.2734 15.6992 12.5499C13.126 14.0384 11.3984 16.818 11.3984 20C11.3984 23.182 13.126 25.9616 15.6992 27.4501C16.1772 27.7266 16.3406 28.3384 16.064 28.8164C15.7875 29.2945 15.1758 29.4578 14.6977 29.1813C11.532 27.35 9.39844 23.9248 9.39844 20C9.39844 16.0752 11.532 12.65 14.6977 10.8187C15.1758 10.5422 15.7875 10.7055 16.064 11.1836ZM23.9328 11.1836C24.2094 10.7055 24.8211 10.5422 25.2992 10.8187C28.4649 12.65 30.5984 16.0752 30.5984 20C30.5984 23.9248 28.4649 27.35 25.2992 29.1813C24.8211 29.4578 24.2094 29.2945 23.9328 28.8164C23.6563 28.3384 23.8197 27.7266 24.2977 27.4501C26.8709 25.9616 28.5984 23.182 28.5984 20C28.5984 16.818 26.8709 14.0384 24.2977 12.5499C23.8196 12.2734 23.6563 11.6616 23.9328 11.1836ZM18.5473 15.3148C18.8153 15.7977 18.641 16.4064 18.1581 16.6744C16.9872 17.3241 16.1984 18.5706 16.1984 20C16.1984 21.4294 16.9872 22.6759 18.1581 23.3256C18.641 23.5936 18.8153 24.2023 18.5473 24.6852C18.2793 25.1681 17.6706 25.3424 17.1877 25.0744C15.4072 24.0865 14.1984 22.1851 14.1984 20C14.1984 17.8149 15.4072 15.9135 17.1877 14.9256C17.6706 14.6576 18.2793 14.8319 18.5473 15.3148ZM21.4496 15.3148C21.7175 14.8319 22.3263 14.6576 22.8092 14.9256C24.5897 15.9135 25.7984 17.8149 25.7984 20C25.7984 22.1851 24.5897 24.0865 22.8092 25.0744C22.3263 25.3424 21.7175 25.1681 21.4496 24.6852C21.1816 24.2023 21.3559 23.5936 21.8388 23.3256C23.0096 22.6759 23.7984 21.4294 23.7984 20C23.7984 18.5706 23.0096 17.3241 21.8388 16.6744C21.3559 16.4064 21.1816 15.7977 21.4496 15.3148ZM18.9422 20C18.9422 19.4477 19.3899 19 19.9422 19H19.9984C20.5507 19 20.9984 19.4477 20.9984 20C20.9984 20.5523 20.5507 21 19.9984 21H19.9422C19.3899 21 18.9422 20.5523 18.9422 20Z" fill="white"/>
        </svg>
    )
}
