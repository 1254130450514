import { IconProps } from "./type"

export const ICStar: React.FC<IconProps> = ({
    fill = "#FFCB46",
}) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1701 15.75C13.0501 15.7505 12.9318 15.7222 12.8251 15.6675L9.00007 13.665L5.17507 15.6675C5.05086 15.7328 4.91082 15.762 4.77087 15.7517C4.63092 15.7414 4.49667 15.692 4.38339 15.6092C4.2701 15.5264 4.18232 15.4134 4.13003 15.2832C4.07773 15.153 4.06302 15.0107 4.08757 14.8725L4.83757 10.65L1.74757 7.65003C1.65116 7.55382 1.58277 7.43318 1.54974 7.30105C1.5167 7.16892 1.52027 7.03029 1.56007 6.90003C1.60354 6.76674 1.6835 6.6483 1.79088 6.55815C1.89826 6.468 2.02876 6.40976 2.16757 6.39003L6.44257 5.76753L8.32507 1.92003C8.38648 1.79323 8.48237 1.68629 8.60175 1.61146C8.72113 1.53663 8.85917 1.49695 9.00007 1.49695C9.14096 1.49695 9.279 1.53663 9.39838 1.61146C9.51776 1.68629 9.61365 1.79323 9.67507 1.92003L11.5801 5.76003L15.8551 6.38253C15.9939 6.40226 16.1244 6.4605 16.2318 6.55065C16.3391 6.6408 16.4191 6.75924 16.4626 6.89253C16.5024 7.02279 16.5059 7.16142 16.4729 7.29355C16.4399 7.42568 16.3715 7.54632 16.2751 7.64253L13.1851 10.6425L13.9351 14.865C13.9618 15.0056 13.9478 15.151 13.8947 15.2839C13.8415 15.4168 13.7514 15.5317 13.6351 15.615C13.4993 15.7102 13.3357 15.7577 13.1701 15.75Z" fill={fill}/>
        </svg>
    )
}
