import { IconProps } from "./type"

export const ICFolder: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 2C3.20435 2 2.44129 2.31607 1.87868 2.87868C1.31607 3.44129 1 4.20435 1 5V19C1 19.7957 1.31607 20.5587 1.87868 21.1213C2.44129 21.6839 3.20435 22 4 22H20C20.7957 22 21.5587 21.6839 22.1213 21.1213C22.6839 20.5587 23 19.7957 23 19V8C23 7.20435 22.6839 6.44129 22.1213 5.87868C21.5587 5.31607 20.7957 5 20 5H11.5352L9.83205 2.4453C9.64658 2.1671 9.33435 2 9 2H4ZM3.29289 4.29289C3.48043 4.10536 3.73478 4 4 4H8.46482L10.1679 6.5547C10.3534 6.8329 10.6656 7 11 7H20C20.2652 7 20.5196 7.10536 20.7071 7.29289C20.8946 7.48043 21 7.73478 21 8V19C21 19.2652 20.8946 19.5196 20.7071 19.7071C20.5196 19.8946 20.2652 20 20 20H4C3.73478 20 3.48043 19.8946 3.29289 19.7071C3.10536 19.5196 3 19.2652 3 19V5C3 4.73478 3.10536 4.48043 3.29289 4.29289Z" fill="#A6A8B4" />
        </svg>
    )
}

export const ICFolderMove: React.FC<IconProps> = ({
    width = "24",
    height = "24",
    fill = "var(--icon-high-em)",
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
            <g clipPath="url(#clip0_14489_24499)">
                <path d="M19 3.00031H12.472C12.3173 3.00133 12.1645 2.96713 12.025 2.90031L8.869 1.31631C8.45239 1.10885 7.9934 1.00069 7.528 1.00031H5C3.67441 1.00189 2.40356 1.52919 1.46622 2.46653C0.528882 3.40386 0.00158786 4.67471 0 6.00031L0 18.0003C0.00158786 19.3259 0.528882 20.5967 1.46622 21.5341C2.40356 22.4714 3.67441 22.9987 5 23.0003H19C20.3256 22.9987 21.5964 22.4714 22.5338 21.5341C23.4711 20.5967 23.9984 19.3259 24 18.0003V8.00031C23.9984 6.67471 23.4711 5.40386 22.5338 4.46653C21.5964 3.52919 20.3256 3.00189 19 3.00031ZM5 3.00031H7.528C7.68266 2.99928 7.83552 3.03348 7.975 3.10031L11.131 4.67931C11.5472 4.88849 12.0062 4.99837 12.472 5.00031H19C19.598 5.00128 20.1821 5.18097 20.6773 5.51632C21.1725 5.85166 21.5561 6.32734 21.779 6.88231L2 6.99431V6.00031C2 5.20466 2.31607 4.44159 2.87868 3.87898C3.44129 3.31638 4.20435 3.00031 5 3.00031ZM19 21.0003H5C4.20435 21.0003 3.44129 20.6842 2.87868 20.1216C2.31607 19.559 2 18.796 2 18.0003V8.99431L22 8.8813V18.0003C22 18.796 21.6839 19.559 21.1213 20.1216C20.5587 20.6842 19.7956 21.0003 19 21.0003Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_14489_24499">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
