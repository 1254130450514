import { ECandidateAssessmenStatus } from "../testing/type";
import { ETestStatus } from "./type";

type StatusStyle = {
    backgroundColor: string;
    color: string;
}
export const getStatusStyle = (status: ETestStatus): StatusStyle => {
    switch (status) {
        case ETestStatus.SUBMITTED:
            return {
                backgroundColor: "#D4F0F1",
                color: "#46485C",
            }
        case ETestStatus.DRAFT:
            return {
                backgroundColor: "#EEF0F6",
                color: "#46485C",
            }
        case ETestStatus.REJECTED:
            return {
                backgroundColor: "#F6EAC2",
                color: "#46485C",
            }
        case ETestStatus.APPROVED:
            return {
                backgroundColor: "rgb(213 236 178)",
                color: "#46485C",
            }
        case ETestStatus.CANCELLED:
            return {
                backgroundColor: "#FCB9A9",
                color: "#46485C",
            }
        case ETestStatus.ACTIVE:
            return {
                backgroundColor: "#BFECBF",
                color: "#46485C",
            }
        default:
            return {
                backgroundColor: "#F5F5F5",
                color: "#46485C",
            }
    }
}

export const getStatusStyleCandidate = (status: ECandidateAssessmenStatus): StatusStyle => {
    switch (status) {
        case ECandidateAssessmenStatus.DONE:
            return {
                backgroundColor: "#E2FCF0",
                color: "#0BAA60",
            }
        case ECandidateAssessmenStatus.IN_PROGRESS:
            return {
                backgroundColor: "#FFF5DB",
                color: "#E09400",
            }
        case ECandidateAssessmenStatus.INVITED:
            return {
                backgroundColor: "#E9F4FF",
                color: "#0084FF",
            }
        case ECandidateAssessmenStatus.EXPIRED:
            return {
                backgroundColor: "#ECECED",
                color: "#B3B1B8",
            }
        default:
            return {
                backgroundColor: "#F5F5F5",
                color: "#46485C",
            }
    }
}
