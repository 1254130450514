import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { createStateSyncMiddleware } from 'redux-state-sync';
import rootReducer from "./reducers";

const config = {
    // All actions will be triggered in other tabs except 'appInfo/setLoading'
    predicate: (action: any) => action?.type !== 'appInfo/setLoading',
};
const middlewares = [createStateSyncMiddleware(config)];
const store = configureStore(
    { 
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
    },
);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
