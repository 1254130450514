export type TExceptions = {
    errorCode: number;
    errorMessage: string;
};
/* eslint-disable @typescript-eslint/no-empty-function */
export class Errors {
    private constructor() { }

    // Auth module
    static readonly Unauthorized: TExceptions = { errorCode: 401, errorMessage: 'Unauthorized' };

    static readonly InvalidToken: TExceptions = { errorCode: 401, errorMessage: 'Invalid token' };

    static readonly InvalidOtp: TExceptions = { errorCode: 400, errorMessage: 'Invalid Otp' };

    static readonly ForgotPasswordBlocked: TExceptions = {
        errorCode: 400,
        errorMessage: 'Forgot password otp has been blocked'
    };

    static readonly OtpExpired: TExceptions = { errorCode: 400, errorMessage: 'Otp has been expired' };

    static readonly EmailAlreadyExisted: TExceptions = { errorCode: 400, errorMessage: 'This email already existed' };

    static readonly EmailNotFound: TExceptions = { errorCode: 404, errorMessage: 'This email can not be found' };

    static readonly NewPasswordNotMatchOldPassword = {
        errorCode: 404,
        errorMessage: 'New password does not match old password'
    };

    // Assessment module
    static readonly AssessmentNotFound: TExceptions = { errorCode: 404, errorMessage: 'Assessment not found!' };

    static readonly GenerateUrlFiveTimesADay: TExceptions = {
        errorCode: 400,
        errorMessage: 'Can only generate url 5 times a day'
    };

    static readonly AssessmentHasBeenArchived: TExceptions = {
        errorCode: 400,
        errorMessage: 'Assessment has been archived'
    };

    static readonly AssessmentHasBeenLocked: TExceptions = {
        errorCode: 1001,
        errorMessage: 'Assessment has been locked'
    };

    static readonly InvalidAssessmentPassword: TExceptions = { errorCode: 400, errorMessage: 'Invalid Otp' };

    static readonly AssessmentIsWaitingForPayment: TExceptions = {
        errorCode: 400,
        errorMessage: 'Assessment is waiting for payment'
    };

    static readonly AssessmentIsPaid: TExceptions = {
        errorCode: 400,
        errorMessage: 'Assessment is paid, can not update'
    };

    static readonly CannotEditAssessment: TExceptions = {
        errorCode: 400,
        errorMessage: 'Cannot edit assessment'
    };

    static readonly CannotUpdateStatus: TExceptions = {
        errorCode: 400,
        errorMessage: 'Cannot update assessment status'
    };

    static readonly CandidateHasBeenExpired: TExceptions = {
        errorCode: 400,
        errorMessage: 'This asssesment has been expried, please buy more to be able to continue'
    };

    static readonly AssessmentNeedToPay: TExceptions = {
        errorCode: 400,
        errorMessage: 'You need to pay for the assessment before inviting candidates'
    };

    static readonly InactivePublicLink: TExceptions = {
        errorCode: 400,
        errorMessage: 'This public link has been inactive'
    };

    // Candidate module
    static readonly CandidateNotFound: TExceptions = { errorCode: 404, errorMessage: 'Candidate not found!' };

    static readonly CandidateInvited: TExceptions = { errorCode: 400, errorMessage: 'This candidate has been invited' };

    static readonly WaitFiveMinutesToSendInvitaion: TExceptions = {
        errorCode: 400,
        errorMessage: 'you need to wait 5 minutes to continue sending invitations'
    };

    // Common module
    static readonly DataNotFound: TExceptions = { errorCode: 404, errorMessage: 'Data not found!' };

    // Device module
    static readonly DeviceNotFound: TExceptions = { errorCode: 404, errorMessage: 'Device not found!' };

    // Feature module
    static readonly FeatureNotFound: TExceptions = { errorCode: 404, errorMessage: 'Feature not found!' };

    // Order module
    static readonly OrderNotFound: TExceptions = { errorCode: 404, errorMessage: 'Order not found!' };

    // Page module
    static readonly PageNotFound: TExceptions = { errorCode: 404, errorMessage: 'Page not found!' };

    // Payment module
    static readonly PaymentNotSupported: TExceptions = { errorCode: 404, errorMessage: 'Payment method not supported' };

    // Permission module
    static readonly PermissionNotFound: TExceptions = { errorCode: 404, errorMessage: 'Permission not found!' };

    static readonly PositionNotFound: TExceptions = { errorCode: 404, errorMessage: 'Position not found!' };

    // Question module
    static readonly QuestionNotFound: TExceptions = { errorCode: 404, errorMessage: 'Question not found!' };

    // Role module
    static readonly RoleNotFound: TExceptions = { errorCode: 404, errorMessage: 'Role not found!' };

    // Test module
    static readonly TestNotFound: TExceptions = { errorCode: 404, errorMessage: 'Test not found!' };

    // Test Candidate module
    static readonly TestCandidateNotFound: TExceptions = { errorCode: 404, errorMessage: 'Test Candidate not found!' };

    static readonly TestHasBeenFinished: TExceptions = { errorCode: 400, errorMessage: 'This test has been finished' };

    static readonly NoSectionFound: TExceptions = { errorCode: 404, errorMessage: 'No section found' };

    static readonly SendResultTwoTimesADay: TExceptions = {
        errorCode: 400,
        errorMessage: 'Can only send result 2 times a day'
    };

    static readonly TestCandidateHasBeenExpired: TExceptions = {
        errorCode: 400,
        errorMessage: 'This test candidate has been expired'
    };

    static readonly TestNeedToBeFinished: TExceptions = {
        errorCode: 400,
        errorMessage: 'Test need to be finished'
    };

    static readonly ApproveLinkHasBeenExpired: TExceptions = {
        errorCode: 400,
        errorMessage: 'Approve link has been expired'
    };

    static readonly CannotRemoveCandidateWhileTesting: TExceptions = {
        errorCode: 400,
        errorMessage: 'Candidate cannot be removed while taking the test'
    };

    // Uploadr
    static readonly FileSizeUploaded: TExceptions = {
        errorCode: 400,
        errorMessage: 'File size should not more than 10MB'
    };

    // User module
    static readonly UserNotFound: TExceptions = { errorCode: 404, errorMessage: 'User not found!' };

    static readonly InvalidPassword: TExceptions = { errorCode: 400, errorMessage: 'Invalid password' };

    static readonly AssessmentIsNotActiveReceiveCV: TExceptions = {
        errorCode: 400,
        errorMessage: 'Assessment is not active receive CV'
    };

    static readonly OutOfCandidateCredit: TExceptions = {
        errorCode: 1000,
        errorMessage: 'You have run out of candidate credit, please buy more to be able to continue'
    };

    // AssessmentCV module
    static readonly AssessmentCVNotFound: TExceptions = { errorCode: 404, errorMessage: 'CV not found!' };

    static readonly MaxInviteCandidatePerHour: TExceptions = { errorCode: 400, errorMessage: 'Max invite candidate per hour' };
}

export const isEqualError = (error: any, exeption: TExceptions): boolean => {
    return error?.response?.data?.errorCode === exeption.errorCode && error?.response?.data?.errorMessage === exeption.errorMessage
}