import React from "react";
import { TQuestion } from "../create_test/tab/question/type";
import { htmlToText } from "../../utils/htmlToText";
import { HRTooltip } from "../../components/tooltip";
import { ICChevronDown, ICChevronUp } from "../../icons";
import { QuestionExplanation } from "../create_test/tab/question/row-question";
import { Button, Modal } from "antd";
import { getMyTestLibrary } from "../../api/library";
import { HRSelect } from "../../components/form/select";
import { ITestLibrary } from "../library/models";
import { HRButton } from "../../components/button";
import i18next from "../../i18n";

type ReviewQuestionModalProps = {
    title?: string;
    isOpen: boolean;
    onClose: () => void;
    questions: TQuestion[];
    onImport: (libraryId?: string) => void;
}
enum EImportType {
    NEW = "new",
    EXISTED = "existed"
}
export const ReviewQuestionModal: React.FC<ReviewQuestionModalProps> = ({
    title,
    isOpen,
    onClose,
    questions,
    onImport
}) => {
    const [step, setStep] = React.useState(0);
    const [importType, setImportType] = React.useState<EImportType>(EImportType.NEW);
    const [selectedLibrary, setSelectedLibrary] = React.useState<string | undefined>(undefined);
    React.useEffect(() => {
        if (!isOpen) {
            setStep(0);
        }
    }, [isOpen]);

    return <Modal
        centered
        onCancel={onClose}
        open={isOpen}
        zIndex={1001}
        width={1000}
        title={title ?? "Questions"}
        footer={
            <div className="flex justify-end p-4">
                <div className="flex justify-end">
                    <HRButton
                        btnType="sub_danger"
                        onClick={onClose}
                        btnSize="sm"
                    >
                        <div className="flex items-center justify-center text-[14px] font-semibold">
                            {i18next.t('assisstant.modal.footer.closeButton')}
                        </div>
                    </HRButton>
                    <Button onClick={onClose}></Button>
                </div>
                <div className="flex justify-end">
                    <HRButton
                        type="primary"
                        onClick={() => {
                            if (step === 0) {
                                setStep(1);
                            } else {
                                onImport(
                                    importType === EImportType.NEW ? undefined : selectedLibrary
                                );
                            }
                        }}
                        disabled={step === 1 && (importType === EImportType.EXISTED && !selectedLibrary)}
                        btnSize="sm"
                    >
                        <div className="flex items-center justify-center text-[14px] font-semibold">
                            {
                                step === 0 ? i18next.t('assisstant.modal.footer.primaryButton.continueToSave') : i18next.t('assisstant.modal.footer.primaryButton.save')
                            }
                        </div>
                    </HRButton>
                </div>
            </div>
        }
        style={{
            padding: 0,
        }}
        bodyStyle={{
            padding: 0,
        }}
    >
        <div
            className="max-h-[500px] overflow-y-auto p-2 px-2 lg:px-4 space-y-3"
        >
            {
                step === 0 && <ReviewQuestions
                    questions={questions}
                />
            }
            {
                step === 1 && <LibrarySelection
                    selectedLibrary={selectedLibrary}
                    onChoose={setSelectedLibrary}
                    importType={importType}
                    setImportType={setImportType}
                />
            }
        </div>
    </Modal>
}
type ReviewQuestionsProps = {
    questions: TQuestion[];
}
const ReviewQuestions: React.FC<ReviewQuestionsProps> = ({
    questions
}) => {
    return <div className="space-y-2">
        {
            questions.map((question, index) => {
                return <QuestionRowRenderer
                    key={index}
                    question={question}
                    index={index}
                    defaultOpen={index === 0}
                />
            })
        }
    </div>
}

type LibrarySelectionProps = {
    importType: EImportType | undefined;
    setImportType: (type: EImportType) => void;
    selectedLibrary: string | undefined;
    onChoose: (
        libraryId?: string
    ) => void;
}
const LibrarySelection: React.FC<LibrarySelectionProps> = ({
    importType,
    setImportType,
    selectedLibrary,
    onChoose
}) => {
    const [tests, setTests] = React.useState<ITestLibrary[]>([]);
    React.useEffect(() => {
        getMyTestLibrary({
            // TODO: Add pagination or lazy loading
            limit: 100,
            page: 1
        }).then((res) => {
            setTests(res.data.data);
        });
    }, []);
    return <div className="min-h-[300px] px-4 space-y-4">
        <HRSelect
            allowClear={false}
            label={`${i18next.t('assisstant.select.label1')}`}
            showSearch={false}
            value={importType}
            onChange={(value) => {
                setImportType(value);
            }}
            options={[
                {
                    label: i18next.t('assisstant.select.option1.label'),
                    value: EImportType.NEW
                },
                {
                    label: i18next.t('assisstant.select.option2.label'),
                    value: EImportType.EXISTED
                }
            ]}
        />
        {
            importType === EImportType.EXISTED && <HRSelect
                label={`${i18next.t('assisstant.select.label2')}`}
                showSearch
                value={selectedLibrary}
                onChange={(value) => {
                    onChoose(value);
                }}
                options={tests.map(test => ({
                    label: test.title,
                    value: test._id
                }))}
            />
        }
    </div>
}

type QuestionRowRendererProps = {
    index: number;
    question: TQuestion;
    defaultOpen?: boolean;
}

export const QuestionRowRenderer: React.FC<QuestionRowRendererProps> = ({
    defaultOpen = false,
    question,
    index
}) => {
    const [isCollapsed, setIsCollapsed] = React.useState(!defaultOpen);
    return <div className="py-3 px-5 border-[1px] border border-purple text-standard rounded-lg cursor-pointer select-none">
        <div className="flex justify-between cursor-pointer" onClick={() => setIsCollapsed(prev => !prev)}>
            <div className="grid grid-cols-[auto_1fr] gap-[8px] items-center">
                <span className="text-standard-bold text-high-em">
                    {i18next.t('assisstant.questionRowRenderer.noLabel')} {(index + 1).toString().padStart(2, '0')}
                </span>
                <HRTooltip
                    content={question.content}
                    childNode={(
                        <span className="line-clamp-1 w-full text-high-em text-standard">
                            {htmlToText(question.content)}
                        </span>
                    )}
                >
                </HRTooltip>
            </div>
            <div className="p-1">
                {
                    isCollapsed ? <ICChevronDown stroke="#A6A8B4" /> : <ICChevronUp stroke="#A6A8B4" />
                }
            </div>
        </div>
        {
            !isCollapsed && <QuestionExplanation question={question} />
        }
    </div>
}