export const ICTagPopular: React.FC = () => {
  return (
    <svg
      width="101"
      height="25"
      viewBox="0 0 101 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.4478 0H0V25H90.4478L101 12.5L90.4478 0Z"
        fill="url(#paint0_linear_1109_24784)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1109_24784"
          x1="0"
          y1="12.5"
          x2="101.316"
          y2="12.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7357FF" />
          <stop offset="1" stopColor="#6E4CF8" />
        </linearGradient>
      </defs>
    </svg>
  );
};
