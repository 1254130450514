import { IconProps } from "./type"

export const ICGlobal: React.FC<IconProps> = ({
    fill = "var(--icon-high-em)"
}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="globe-alt">
                <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M3.05752 10.6H6.42154C6.52405 8.23271 6.98778 6.07086 7.71399 4.41096C7.80882 4.19421 7.90916 3.98365 8.01505 3.78053C5.34382 5.00721 3.40927 7.5624 3.05752 10.6ZM11.6 1C5.74578 1 1 5.74578 1 11.6C1 17.4542 5.74578 22.2 11.6 22.2C17.4542 22.2 22.2 17.4542 22.2 11.6C22.2 5.74578 17.4542 1 11.6 1ZM11.6 3C11.351 3 11.0349 3.11115 10.6602 3.47336C10.2808 3.84023 9.89334 4.41936 9.54631 5.21259C8.94569 6.58543 8.5249 8.46489 8.42357 10.6H14.7764C14.6751 8.46489 14.2543 6.58543 13.6537 5.21259C13.3067 4.41936 12.9192 3.84023 12.5398 3.47336C12.1651 3.11115 11.849 3 11.6 3ZM16.7785 10.6C16.6759 8.23271 16.2122 6.07086 15.486 4.41096C15.3912 4.19421 15.2908 3.98365 15.1849 3.78053C17.8562 5.00721 19.7907 7.5624 20.1425 10.6H16.7785ZM14.7764 12.6H8.42357C8.5249 14.7351 8.94569 16.6146 9.54631 17.9874C9.89334 18.7806 10.2808 19.3598 10.6602 19.7266C11.0349 20.0888 11.351 20.2 11.6 20.2C11.849 20.2 12.1651 20.0888 12.5398 19.7266C12.9192 19.3598 13.3067 18.7806 13.6537 17.9874C14.2543 16.6146 14.6751 14.7351 14.7764 12.6ZM15.1849 19.4195C15.2908 19.2164 15.3912 19.0058 15.486 18.789C16.2122 17.1291 16.6759 14.9673 16.7785 12.6H20.1425C19.7907 15.6376 17.8562 18.1928 15.1849 19.4195ZM8.01505 19.4195C7.90916 19.2164 7.80882 19.0058 7.71399 18.789C6.98778 17.1291 6.52405 14.9673 6.42154 12.6H3.05752C3.40927 15.6376 5.34382 18.1928 8.01505 19.4195Z" fill={fill}/>
            </g>
        </svg>
    )
}
