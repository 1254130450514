import i18next from "../../i18n";
import { ESkillAreaType, TChoiceQuestion, TCodingQuestion, TGroupQuestion, TSpeakingQuestion } from "../create_test/tab/question/type";
import { TAssessment } from "../my_assessment/create-assessment/type";
import { EStatusAssessment } from "../my_assessment/type";

export enum STEPS {
    HOLD = 'hold',
    INTRO = 'intro',
    PREPARE = 'prepare',
    TEST = 'test',
    GENERAL = 'general',
    PASSWORD = 'password',
    CONFIRM_USER_INVITED = 'confirm_user_invited',
    OTP = 'otp',
    ABILITIES_CHECK = 'abilities_check',
    ABILITIES_CHECK_CONFIRMATION = 'abilities_check_confirmation',
    CAMERA_CHECK = 'camera_check',
    AUDIO_MIC_CHECK = 'audio_mic_check',
    NONE = 'none',
    FINISH_TEST = 'finish_test',
    TAKE_BREAK = 'take_break',
    SUBMIT_TEST = 'submit_test',
    END_TEST = 'end_test',
}

export type IInviteCandidate = {
    email: string;
    firstName: string;
    lastName: string;
    mailTemplate?: string;
}

export type IReportIssueCandidate = {
    hasHealthIssue: boolean;
    hasLanguageIssue: boolean;
    issueDetail: string;
    id: string;
    url: string;
}

export type IStartSection = {
    id: string | undefined;
    url: string;
    device?: string;
    lat?: number;
    long?: number;
}

export type TCodingQuestionTesting = TCodingQuestion & {
    selectedAnswer: number | number[] | undefined | string;
    answer?: string;
    _id: string;
    note?: string;
    rating?: number;
}



export type TChoiceQuestionTesting = TChoiceQuestion & {
    selectedAnswer: number | number[] | undefined | string;
    answer?: string;
    _id: string;
    note?: string;
    rating?: number;
    score?: number;
}
export type TGroupQuestionTesting = Omit<TGroupQuestion, "questions"> & {
    _id: string;
    answer: string[];
    score?: number;
    questions: TChoiceQuestionTesting[];
    rating?: number;
    selectedAnswer: undefined;
    isAutoEvaluate?: boolean;
};
export type TSpeakingQuestionTesting = TSpeakingQuestion & {
    _id: string;
    score?: number;
    rating?: number;
    selectedAnswer?: undefined;
    isAutoEvaluate?: boolean;
    answer?: string[];
}
export type TQuestionTesting = (TCodingQuestionTesting | TChoiceQuestionTesting | TGroupQuestionTesting | TSpeakingQuestionTesting);
export type TSectionTesting = {
    _id: string;
    name: string;
    score?: number;
    questions: TQuestionTesting[];
    type: ESkillAreaType;
    standardRating?: string;
    atteamRating?: string;
    description?: string;
}

export type TTQuestionTesting = TQuestionTesting & {
    hasViewed?: boolean;
    answer?: string;
    comment?: string;

    // ui
    isFocused?: boolean;
    hasEvaluated?: boolean;
};

export type TTesting = {
    type: string;
    _id: string;
    title: string;
    introduce: string;
    duration: number;
    isSubmitted: boolean;
    isLost: boolean;
    nextSection: string;
    remainingTime: number;
    sections: TSectionTesting[];
    startAt?: number;
    isStarted?: boolean;
}

export interface TTestingCandidate {
    duration: number;
    isLost: boolean;
    isSubmitted: boolean;
    score: number;
    sections: TSectionTesting[];
    title: string;
    startAt: number;
    type: 'question' | 'additional_question' | 'test' | 'atteam' | 'standard';
    _id: string;

    ratingLevel?: string;
    atteamRating?: string;
    standardRating?: string;

    // ui
    isCollapsed?: boolean;
}

export type TCandidate = {
    email: string;
    firstName: string;
    lastName: string;
    fullName?: string;
    _id: string;
    id?: string;
}

export type TAssessmentData = {
    activePassword: boolean;
    id: string;
    role: string;
    title: string;
    language: string;
    _id: string;
    status: EStatusAssessment;
    forceTurnOnCamera?: boolean;
}
export enum ECandidateAssessmenStatus {
    IN_PROGRESS = 'in_progress',
    DONE = 'done',
    ALL = 'all',
    INVITED = 'invited',
    EXPIRED = 'expired',
}

export const candidateStatusMapping: Record<ECandidateAssessmenStatus, string> = {
    [ECandidateAssessmenStatus.INVITED]: i18next.t('assessmentDetail.invited'),
    [ECandidateAssessmenStatus.IN_PROGRESS]: i18next.t('assessmentDetail.inProgress'),
    [ECandidateAssessmenStatus.DONE]: i18next.t('assessmentDetail.done'),
    [ECandidateAssessmenStatus.ALL]: i18next.t('assessmentDetail.all'),
    [ECandidateAssessmenStatus.EXPIRED]: i18next.t('assessmentDetail.expired'),
}

export const candidateStatusColorMapping: Record<ECandidateAssessmenStatus, string> = {
    [ECandidateAssessmenStatus.INVITED]: 'text-primary',
    [ECandidateAssessmenStatus.IN_PROGRESS]: 'text-info',
    [ECandidateAssessmenStatus.DONE]: 'text-success',
    [ECandidateAssessmenStatus.ALL]: 'text-high-em',
    [ECandidateAssessmenStatus.EXPIRED]: 'text-high-em',
}

export type TOverview = {
    totalDuration: number;
    totalQuestions: number;
    totalTests: number;
}

export type TAssessmentTesting = Omit<TAssessment, "status"> & {
    candidate: TCandidate;
    currentSection: string;
    hasHealthIssue: boolean;
    hasIssue: boolean;
    hasLanguageIssue: boolean;
    hasSubmitIssue: boolean;
    isApproved: boolean;
    isStarted: boolean;
    isSubmitted: boolean;
    nextSection: string;
    owner: string;
    accepted: boolean;
    assessment: TAssessmentData;
    status: ECandidateAssessmenStatus;
    remainingTime: number;
    testCandidateToken: string;
    overview: TOverview;
    endedAt: number;
    score: number | null;
    rating: number | null;
    evaluateStatus: EEvaluateStatus;
}

type BaseCheatingLog = {
    at: number;
    type: string;
}

type ChangeTabLog = BaseCheatingLog & {
    type: 'change_tab';
}

type BackToTabLog = BaseCheatingLog & {
    type: 'back_to_tab';
}


export type TCheatingLog = ChangeTabLog | BackToTabLog;

export type TCandidateTestingData = Omit<TAssessmentTesting, "tests"> & {
    invitedAt: number;
    startedAt: number;
    turnOnCamera: boolean;
    exitFullScreenMode: boolean;
    changeTab: boolean;
    location: string | string[];
    device: string | string[];
    tests: TTestingCandidate[];
    note?: string;
    cheatingLogs: TCheatingLog[];

    ggDriveFolderId?: string;
    ggDriveShareLink?: string;

    // only ui
    uuid?: string;
}

export type TAssessmentResponse = {
    _id: string;
    title: string;
    id: string;
    isSendEmail?: boolean;
}

export enum EEvaluateStatus {
    NOT_EVALUATED = 'not_yet_evaluated',
    EVALUATED = 'evaluated',
    INVITED_FOR_INTERVIEW = 'invited_for_interview',
    INVITED_FOR_TAKE_HOME_TEST = 'invited_for_take_home_test',
    TAKE_HOME_TEST_COMPLETED = 'take_home_test_completed',
    REFERENCES_CHECKED = 'references_checked',
    OFFER_SENT = 'offer_sent',
    OFFER_DECLINED = 'offer_declined',
    CANDIDATE_WITHDREW = 'candidate_withdrew',
    CANDIDATE_UNRESPONSIVE = 'candidate_unresponsive',
    REJECTED = 'rejected',
    HIRED = 'hired'
};

export const evaluateStatusMapping: Record<EEvaluateStatus, string> = {
    [EEvaluateStatus.NOT_EVALUATED]: i18next.t('testing.notEvaluated'),
    [EEvaluateStatus.EVALUATED]: i18next.t('testing.evaluated'),
    [EEvaluateStatus.INVITED_FOR_INTERVIEW]: i18next.t('testing.invitedForInterview'),
    [EEvaluateStatus.INVITED_FOR_TAKE_HOME_TEST]: i18next.t('testing.invitedForTakeHomeTest'),
    [EEvaluateStatus.TAKE_HOME_TEST_COMPLETED]: i18next.t('testing.takeHomeTestCompleted'),
    [EEvaluateStatus.REFERENCES_CHECKED]: i18next.t('testing.referencesChecked'),
    [EEvaluateStatus.OFFER_SENT]: i18next.t('testing.offerSent'),
    [EEvaluateStatus.OFFER_DECLINED]: i18next.t('testing.offerDeclined'),
    [EEvaluateStatus.CANDIDATE_WITHDREW]: i18next.t('testing.candidateWithdrew'),
    [EEvaluateStatus.CANDIDATE_UNRESPONSIVE]: i18next.t('testing.candidateUnresponsive'),
    [EEvaluateStatus.REJECTED]: i18next.t('testing.rejected'),
    [EEvaluateStatus.HIRED]: i18next.t('testing.hired'),
};

export const EEvaluateStatusOptions = [
    { value: EEvaluateStatus.NOT_EVALUATED, label: evaluateStatusMapping[EEvaluateStatus.NOT_EVALUATED] },
    { value: EEvaluateStatus.EVALUATED, label: evaluateStatusMapping[EEvaluateStatus.EVALUATED] },
    { value: EEvaluateStatus.INVITED_FOR_INTERVIEW, label: evaluateStatusMapping[EEvaluateStatus.INVITED_FOR_INTERVIEW] },
    { value: EEvaluateStatus.INVITED_FOR_TAKE_HOME_TEST, label: evaluateStatusMapping[EEvaluateStatus.INVITED_FOR_TAKE_HOME_TEST] },
    { value: EEvaluateStatus.TAKE_HOME_TEST_COMPLETED, label: evaluateStatusMapping[EEvaluateStatus.TAKE_HOME_TEST_COMPLETED] },
    { value: EEvaluateStatus.REFERENCES_CHECKED, label: evaluateStatusMapping[EEvaluateStatus.REFERENCES_CHECKED] },
    { value: EEvaluateStatus.OFFER_SENT, label: evaluateStatusMapping[EEvaluateStatus.OFFER_SENT] },
    { value: EEvaluateStatus.OFFER_DECLINED, label: evaluateStatusMapping[EEvaluateStatus.OFFER_DECLINED] },
    { value: EEvaluateStatus.CANDIDATE_WITHDREW, label: evaluateStatusMapping[EEvaluateStatus.CANDIDATE_WITHDREW] },
    { value: EEvaluateStatus.CANDIDATE_UNRESPONSIVE, label: evaluateStatusMapping[EEvaluateStatus.CANDIDATE_UNRESPONSIVE] },
    { value: EEvaluateStatus.REJECTED, label: evaluateStatusMapping[EEvaluateStatus.REJECTED] },
    { value: EEvaluateStatus.HIRED, label: evaluateStatusMapping[EEvaluateStatus.HIRED] },
];
