import React, { useMemo } from "react";
import { BlankAssessment, TAdditionalQuestion, TAssessment } from "../create-assessment/type";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/slices/appInfo";
import { getAssessmentDetails, updateAssessment, updateAssessmentAdditionalInfo, updateAutoSendResult, updateEmailRepicipents } from "../../../api/assessment";
import { useNavigate, useParams } from "react-router-dom";
import { HRCollapse } from "../../../components/collapse";
import { Card, Drawer, Switch, Table } from "antd";
import { CreateLinkAssessment } from "./createLink";
import { CreatePasswordAssessment } from "./createPassword";
import { InviteEmailAssessment } from "./inviteEmail";
import { PanelAssessment } from "./panel";
import { HRButton, IconButton } from "../../../components/button";
import classNames from "classnames";
import { ColumnsType } from "antd/lib/table";
import { HeaderAssessmentDetails } from "./header";
import { ILibraryUI, IPagination } from "../../library/models";
import { HRTooltip } from "../../../components/tooltip";
import { htmlToText } from "../../../utils/htmlToText";
import { CandidateList } from "./candidate-list";
import { EStatusAssessment, TCandidate, TMyCandidateCreditInfo, TSearchCandidateList, TWaitingPaymentOrder } from "../type";
import { ICAdd, ICCheckBoard, ICCheckV2, ICDownload, ICHasFee, ICInfoV3, ICNext, ICNoneData, ICSettingV2 } from "../../../icons";
import { ECandidateAssessmenStatus, TAssessmentTesting } from "../../testing/type";
import { SearchParamsStateType, useFilter } from "../../../hooks/useFilter";
import { useNotification } from "../../../hooks/useNotification";
import { PurchaseAssessment } from "./purchase-info";
import { AssessmentCV, TCVItem } from "./cv";
import { CandidateCreditInfo } from "./candidate-credit-info";
import { getMyCandidateCreditInfo } from "../../../api/user";
import styled from "styled-components";
import { RootState } from "../../../redux/reducers";
import { exportResultAssessment, getCandidateList, isEnableExportResultAssessment } from "../../../api/test-candidate";
import { updateReceiveCV } from "../../../api/assessment-cv";
import { QuestionTypeMapping } from "../../../utils/constants";
import { EQuestionType } from "../../create_test/tab/question/type";
import { getOrderByAssessment } from "../../../api/order";
import { ICBack } from "../../../icons/back";
import { AssessmentNote } from "./note";
import { AssessmentConfiguration } from "./configuration";
import { EmailRecipients } from "./email-recipients";
import i18next from "../../../i18n";

const Wrapper = styled.div`
    .no-assessment {
        transition: all 0.3s ease-in;
        background: rgba(255, 255, 255, 0.7);
        background-blend-mode: screen;
        backdrop-filter: blur(16px);
    }
`;

const DrawerContent = styled(Drawer)`
    .ant-drawer-header {
        padding: 0;
        border-bottom: 1px solid var(--tc-outlines-med);
        .ant-drawer-header-title {
            flex-direction: column;
            align-items: flex-start;
            .ant-drawer-close {
                padding: 8px 8px 8px 19px;
                margin: 0;
            }
            .ant-drawer-title {
                padding: 16px 16px 16px 24px;
                color: var(--tc-text-primary-bold);
                font-family: SVN-Poppins;
                font-size: 22px;
                font-weight: 600;
                line-height: 32px;
            }
        }
    }
`;

type TAssementDetailsData = {
    assessmentId: string;
    assessmentDetails: TAssessment;
    setAssessmentDetails: React.Dispatch<React.SetStateAction<TAssessment>>;
    candidateList: TAssessmentTesting[];
    setCandidateList: React.Dispatch<React.SetStateAction<TAssessmentTesting[]>>;
    candidateFullList: TCandidate[];
    setCandidateFullList: React.Dispatch<React.SetStateAction<TCandidate[]>>;
    myCandidateCreditInfo: TMyCandidateCreditInfo;
    cvs: TCVItem[];
    setCvs: React.Dispatch<React.SetStateAction<TCVItem[]>>;
    activeDownloadResult?: boolean;
    waitingOrderPayment?: TWaitingPaymentOrder;
}
export const AssementDetailsDataContext = React.createContext<TAssementDetailsData>({} as TAssementDetailsData);
export const useAssementDetailsData = () => React.useContext(AssementDetailsDataContext);

export const AssessmentDetailsPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const userId = useMemo(() => userInfo?._id, [userInfo]);
    const { showError, showSuccess } = useNotification();

    const { id = '' } = useParams();
    const [assessmentDetails, setAssessmentDetails] = React.useState<TAssessment>(BlankAssessment);
    const [assessmentId, setAssessmentId] = React.useState<string>(id);
    const [pagination, setPagination] = React.useState<IPagination>();
    const [candidateList, setCandidateList] = React.useState<TAssessmentTesting[]>([]);
    const filtersDefaults: SearchParamsStateType = {
        page: { type: 'number', default: 1 },
        limit: { type: 'number', default: 5 },
        searchString: { type: 'string', default: null },
        orderBy: { type: 'string', default: "invitedAt::desc" },
        status: { type: 'string', default: ECandidateAssessmenStatus.ALL },
        fromScore: { type: 'number', default: null },
        toScore: { type: 'number', default: null },
    }
    const [filterParams, setFilterParams] = useFilter(filtersDefaults);
    const [candidateFullList, setCandidateFullList] = React.useState<TCandidate[]>([]);
    const [showWarning, setShowWarning] = React.useState<boolean>();
    const [myCandidateCreditInfo, setMyCandidateCreditInfo] = React.useState<TMyCandidateCreditInfo>({} as TMyCandidateCreditInfo);
    const [cvs, setCvs] = React.useState<TCVItem[]>([]);
    const [activeDownloadResult, setActiveDownloadResult] = React.useState<boolean>(false);
    const [waitingOrderPayment, setWaitingOrderPayment] = React.useState<TWaitingPaymentOrder>();

    const [selectedCandidateRowKeys, setSelectedCandidateRowKeys] = React.useState<React.Key[]>([]);

    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
    const [isAutoSendResult, setIsAutoSendResult] = React.useState<boolean>(false);

    const loadAssessmentDetails = React.useCallback(async () => {
        if (!id) return;
        try {
            dispatch(setLoading(true));
            getAssessmentDetails(id).then((res) => {
                setAssessmentDetails(res.data);
                setIsAutoSendResult(res.data?.isAutoSendResult || false);
            }).catch(error => {
                console.error(error);
                showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
            });
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, id, showError]);

    const loadOrderIdByAssessmentId = React.useCallback(async () => {
        if (!id) return;
        try {
            dispatch(setLoading(true));
            getOrderByAssessment(id).then((res) => {
                setWaitingOrderPayment(res.data);
            }).catch(error => {
                console.error(error);
                showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
            });
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, id, showError]);

    const onCheckDownloadResultCandidate = React.useCallback(async () => {
        dispatch(setLoading(true));
        try {
            const result = await isEnableExportResultAssessment(assessmentId);
            setActiveDownloadResult(result?.data?.isEnableExport);
        }
        catch (error: any) {
            const errorMessage = error?.response?.data?.errorMessage || error?.response?.statusText;
            errorMessage && showError("Error", errorMessage);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, assessmentId]);

    React.useEffect(() => {
        if (!id) return;
        const keyValue = `user_${userId}_${id}`;
        const data = localStorage.getItem('assessment') as string;
        if (data) {
            const newData = JSON.parse(data);
            const index = newData.findIndex((item: any) => item === keyValue);
            if (index === -1) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        } else {
            setShowWarning(true);
        }
    }, []);

    React.useEffect(() => {
        loadAssessmentDetails();
        onCheckDownloadResultCandidate();
    }, []);

    const onLoadCandidateCreditInfo = React.useCallback(async () => {
        try {
            const results = await getMyCandidateCreditInfo();
            const { data } = results || {};
            setMyCandidateCreditInfo(data);
        } catch (e) {
            console.error(e)
        }
    }, []);

    React.useEffect(() => {
        onLoadCandidateCreditInfo();
    }, [onLoadCandidateCreditInfo]);

    const activeEditButton = React.useMemo(() => {
        if (assessmentDetails?.needToPay) {
            if (assessmentDetails?.canSkipPayment) {
                return true;
            } else {
                return assessmentDetails?.isPaid as boolean;
            }
        } else {
            return assessmentDetails?.status !== EStatusAssessment.ARCHIVED && assessmentDetails?.totalCandidates === 0;
        }
    }, [assessmentDetails]);

    const isArchivedAssessment = React.useMemo(() => {
        return assessmentDetails?.status === EStatusAssessment.ARCHIVED;
    }, [assessmentDetails]);

    const activePreviewTesting = React.useMemo(() => {
        if (assessmentDetails?.tests && assessmentDetails?.tests?.length > 0) {
            return assessmentDetails?.tests?.some((item) => item?.previewSection && item?.previewSection?.length > 0);
        }
        return false;
    }, [assessmentDetails]);

    React.useEffect(() => {
        if (assessmentDetails?._id && !assessmentDetails?.isPaid && assessmentDetails?.needToPay && assessmentDetails?.status === EStatusAssessment.PAIDING) {
            loadOrderIdByAssessmentId();
        }
    }, [assessmentDetails]);

    const onReloadCandiateList = React.useCallback(async () => {
        try {
            loadAssessmentDetails();
            const payload: TSearchCandidateList = {
                ...filterParams,
                status: filterParams?.status === ECandidateAssessmenStatus.ALL ? undefined : filterParams?.status,
            }
            const results = await getCandidateList(assessmentId, payload);
            const { data } = results || {};
            const newData = data.data || [];
            setCandidateList(newData);
            setPagination(data.metaData);
            onLoadCandidateCreditInfo();
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [assessmentId, filterParams, dispatch, onLoadCandidateCreditInfo, loadAssessmentDetails]);

    const onPreviewTesting = React.useCallback(() => {
        window.open(`/testing/${assessmentDetails?._id}/checking`, '_blank');
    }, [assessmentDetails]);

    const content = (
        <div className="lg:flex flex-col gap-[16px]">
            {showWarning && <div className="lg:flex gap-[16px] items-center justify-center bg-[#F5FAFF] lg:rounded-[144px] py-[16px] px-[24px]">
                <span className="hidden lg:inline">
                    <ICInfoV3 />
                </span>
                <span className="text-high-em text-body">
                    {i18next.t('assessmentDetail.useOneCandidateCreditToTest')}
                </span>
                <span>
                    <HRButton
                        style={{
                            borderRadius: '144px',
                        }}
                        onClick={() => {
                            setShowWarning(false);
                            const keyValue = `user_${userId}_${id}`;
                            const data = localStorage.getItem('assessment') as string;
                            if (data) {
                                const newData = JSON.parse(data);
                                newData.push(keyValue);
                                localStorage.setItem('assessment', JSON.stringify(newData));
                            } else {
                                localStorage.setItem('assessment', JSON.stringify([keyValue]));
                            }
                        }}
                        btnType="sub_info"
                        btnSize="sm"
                    >
                        <div className="flex gap-[8px] justify-center">
                            <ICCheckV2 fill="#0084FF" />
                            <span>{i18next.t('assessmentDetail.gotIt')}</span>
                        </div>
                    </HRButton>
                </span>
            </div>}

            <Wrapper className="grid grid-cols-1 lg:grid-cols-2 gap-[16px] items-start relative">
                {myCandidateCreditInfo?.remainingCandidateCredit === 0 && <div className="flex flex-col items-center justify-center bg-white z-[3] absolute left-0 bottom-0 h-full w-full no-assessment">
                    <div className="flex gap-[32px] p-[24px] rounded-[12px] items-center bg-white justify-between max-w-[800px] min-h-[348px] z-[5] overflow-hidden box-border shadow-lg">
                        <img width="300px" src="/images/no-assessment.png" alt="none-assessment" />
                        <div className="flex flex-col gap-[16px] z-[2] items-start">
                            <span className="text-heading-6-bold text-high-em">{i18next.t('assessmentDetail.outOfCandidateCredit')}</span>
                            <span className="text-body text-med-em">{i18next.t('assessmentDetail.outOfCandidateCreditDescription')}</span>
                            <span>
                                <HRButton
                                    onClick={() => {
                                        navigate("/candidate-credit/payment");
                                    }}
                                    btnType="primary"
                                >
                                    <div className="flex gap-[8px]">
                                        <span className="text-white font-bold leading-[24px] text-[14]">
                                            {i18next.t('assessmentDetail.buyMoreCandidateCredit')}
                                        </span>
                                        <ICNext fill="white" />
                                    </div>
                                </HRButton>
                            </span>
                        </div>
                    </div>
                </div>}

                {/* <div className="flex flex-col">
                    <CreatePasswordAssessment />
                </div> */}

                <div className="grid grid-cols-1 gap-[16px]">
                    <Card
                        bodyStyle={{
                            padding: '16px',
                        }}
                        style={{
                            borderRadius: '12px',
                            border: '1px solid #ECECED',
                            boxShadow: '0px 1px 2px -1px rgba(17, 12, 34, 0.08)',
                        }}>
                        <CreateLinkAssessment />
                    </Card>
                </div>
                <div className="grid grid-cols-1 gap-[32px]">
                    <Card
                        bodyStyle={{
                            padding: '16px',
                        }}
                        style={{
                            borderRadius: '12px',
                            border: '1px solid #ECECED',
                            boxShadow: '0px 1px 2px -1px rgba(17, 12, 34, 0.08)',
                        }}>
                        <InviteEmailAssessment assessmentId={id} candidateList={candidateFullList} setCandidateList={setCandidateFullList} onReloadCandiateList={onReloadCandiateList} />
                    </Card>
                </div>
            </Wrapper>

        </div>
    );

    const onUpdateAssessmentAdditionalInfo = React.useCallback(async (updatedData: Record<string, any>) => {
        try {
            dispatch(setLoading(true));
            const results = await updateAssessmentAdditionalInfo(assessmentId, updatedData);
            const { data } = results || {};
            setAssessmentDetails(prev => {
                const cloned: any = { ...prev };
                Object.keys(updatedData).forEach((key) => {
                    cloned[key] = data[key];
                });
                return cloned;
            });
            showSuccess(i18next.t('assessmentDetail.updateSuccess'));
        } finally {
            dispatch(setLoading(false));
        }
    }, []);

    const onUpdateEmailRecipients = React.useCallback(async (emails: string[]) => {
        try {
            dispatch(setLoading(true));
            const data = emails.filter((email, index) => emails.indexOf(email) === index);
            await updateEmailRepicipents(assessmentId, data);
            setAssessmentDetails(prev => {
                const cloned: any = { ...prev, emailRecipients: data };
                return cloned;
            });
            showSuccess(i18next.t('assessmentDetail.updateSuccess'));
        } finally {
            dispatch(setLoading(false));
        }
    }, []);

    const onDownloadAssessment = React.useCallback(async () => {
        dispatch(setLoading(true));
        try {
            const testCandidateIds = selectedCandidateRowKeys?.map((item) => item.toString()) || [];
            const result = await exportResultAssessment(assessmentId, testCandidateIds);
            const newValues = decodeURIComponent(result.data?.fileToBytes);
            const link = document.createElement('a');
            link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${newValues}`;
            link.download = `${assessmentId}.xlsx`;
            link.click();
            showSuccess(i18next.t('assessmentDetail.downloadSuccess'));
        }
        catch (error: any) {
            const errorMessage = error?.response?.data?.errorMessage || error?.response?.statusText;
            errorMessage && showError("Error", errorMessage);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, showSuccess, assessmentId, selectedCandidateRowKeys]);

    const onChangeAutoSendResult = React.useCallback(async (checked: boolean) => {
        dispatch(setLoading(true));
        try {
            await updateAutoSendResult(assessmentId, checked);
            setIsAutoSendResult(checked);
        }
        catch (error: any) {
            const errorMessage = error?.response?.data?.errorMessage || error?.response?.statusText;
            errorMessage && showError("Error", errorMessage);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, assessmentId]);

    return <AssementDetailsDataContext.Provider
        value={{
            assessmentId,
            assessmentDetails,
            setAssessmentDetails,
            candidateList,
            setCandidateList,
            candidateFullList,
            setCandidateFullList,
            myCandidateCreditInfo,
            cvs,
            setCvs,
            activeDownloadResult,
            waitingOrderPayment,
        }}
    >
        <div className="lg-container mx-auto lg:pt-[40px] pb-[40px]">
            <HeaderAssessmentDetails id={id} onDownload={onDownloadAssessment} />
            <div className="grid grid-cols-1 gap-[40px] pt-6 lg:pt-[130px]">
                {assessmentDetails?._id && assessmentDetails?.status !== EStatusAssessment.LOCKED && (
                    (assessmentDetails?.isPaid && assessmentDetails?.needToPay) ||
                    (!assessmentDetails?.isPaid && !assessmentDetails?.needToPay) ||
                    assessmentDetails?.status === EStatusAssessment.ACTIVE
                ) && !isArchivedAssessment &&
                    (
                        <div className="flex flex-col w-full z-[2] shadow-e-03 overflow-hidden box-border rounded-[12px]">
                            <HRCollapse
                                childNode={content}
                                classess="no-border-radius"
                                title={<div className="lg:flex justify-between items-center">
                                    <span className="text-standard-bold text-g-800">{i18next.t('assessmentDetail.invite')}</span>
                                    <div className="pr-[64px]">
                                        <CandidateCreditInfo />
                                    </div>
                                </div>}
                            />
                            <Card
                                bodyStyle={{
                                    padding: '16px',
                                }}
                                style={{
                                    border: 0,
                                    borderTop: '1px solid #ECECED',
                                    borderRadius: '12px',
                                    borderTopLeftRadius: '0px',
                                    borderTopRightRadius: '0px',
                                    boxShadow: 'none',
                                    overflow: 'hidden',
                                }}>
                                <CreatePasswordAssessment />
                            </Card>
                        </div>
                    )
                }
                {assessmentDetails?._id && !assessmentDetails?.isPaid && assessmentDetails?.needToPay && assessmentDetails?.status !== EStatusAssessment.ACTIVE
                    && <PanelAssessment title={i18next.t('assessmentDetail.invite') ?? ''} childNode={<PurchaseAssessment />} />}

                {assessmentDetails?.status !== EStatusAssessment.DRAFT && assessmentDetails?.status !== EStatusAssessment.PAIDING && <div className="grid grid-cols-1 z-[5] gap-[40px]">
                    <PanelAssessment title={i18next.t('assessmentDetail.listCandidates') ?? ''}
                        actionHeader={
                            <div>
                                <IconButton
                                    btnSize="sm"
                                    btnType="sub"
                                    onClick={() => setOpenDrawer(true)}
                                    icon={<ICSettingV2 />}
                                    hoverText={i18next.t('assessmentDetail.configAssessment') ?? ''}
                                />
                                {/* {activeDownloadResult && assessmentDetails?.status !== EStatusAssessment.LOCKED &&  <IconButton
                                    btnSize="sm"
                                    btnType="sub"
                                    onClick={() => onDownloadAssessment()}
                                    icon={<ICDownload width={18} height={18} />}
                                />}
                                {!activeDownloadResult && 
                                    <HRTooltip content="Chưa có ứng viên nào hoàn thành"
                                        placement={'left'}
                                        mouseLeaveDelay={0}
                                        childNode={
                                            <span style={{ cursor: !activeDownloadResult ? 'not-allowed' : 'pointer' }}>
                                                <IconButton
                                                    btnSize="sm"
                                                    disabled={true}
                                                    style={{ 
                                                        pointerEvents: 'none',
                                                        height: '40px',
                                                        width: '40px',
                                                        display: 'grid',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius: '8px',
                                                        border: 0
                                                    }}
                                                    btnType="sub"
                                                    icon={<ICDownload width={18} height={18} fill="var(--icon-disabled)" />}
                                                />
                                            </span>
                                        }>
                                    </HRTooltip>
                                } */}
                            </div>
                        }
                        classBody="p-0 rounded-b-[12px] overflow-hidden"
                        childNode={
                            <CandidateList
                                filterParams={filterParams}
                                setFilterParams={setFilterParams}
                                pagination={pagination}
                                setPagination={setPagination}
                                selectedCandidateRowKeys={selectedCandidateRowKeys}
                                setSelectedCandidateRowKeys={setSelectedCandidateRowKeys}
                            />
                        } />
                </div>}
                {assessmentDetails?.status !== EStatusAssessment.DRAFT && assessmentDetails?.status !== EStatusAssessment.PAIDING && <HRCollapse title={i18next.t('myAssessments.cvsList')} childNode={<AssessmentCV
                    isActive={assessmentDetails.enableReceiveCV}
                    onToggleActive={(enableReceiveCV) => onUpdateAssessmentAdditionalInfo({ enableReceiveCV })}
                    assessmentId={id}
                    onReloadCandiateList={onReloadCandiateList}
                    setCandidateList={setCandidateFullList}
                    assessmentDetails={assessmentDetails}

                    isActiveReceiveEmail={assessmentDetails.isAutoSendMail}
                    onToggleActiveReceiveEmail={active => onUpdateAssessmentAdditionalInfo({ isAutoSendMail: active })}
                />} />}

                { assessmentDetails?.status !== EStatusAssessment.DRAFT && assessmentDetails?.status !== EStatusAssessment.PAIDING && <EmailRecipients assessmentDetails={assessmentDetails} onUpdate={onUpdateEmailRecipients}/>}

                <div className="grid grid-cols-1 lg:grid-cols-2 items-start gap-[16px]">
                    <AssessmentNote
                        assessmentDetails={assessmentDetails}
                        onUpdateNote={(note) => onUpdateAssessmentAdditionalInfo({ note })}
                    />
                    <AssessmentConfiguration
                        assessmentDetails={assessmentDetails}
                        onUpdate={onUpdateAssessmentAdditionalInfo}
                    />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 items-start gap-[16px]">
                    <PanelAssessment title={i18next.t('assessmentDetail.tests') ?? ''}
                        actionHeader={
                            <>{activePreviewTesting && <HRButton
                                btnSize="sm"
                                btnType="sub"
                                onClick={onPreviewTesting}
                            >
                                <div className="flex items-center space-x-2 justify-center">
                                    <span>{i18next.t('assessmentDetail.try')}</span>
                                </div>
                            </HRButton>}</>
                        }
                        childNode={
                            <TestsTable id={id}
                                data={assessmentDetails?.tests || []}
                                mode="test"
                                activeEditButton={activeEditButton} />} />
                    <PanelAssessment title={i18next.t('assessmentDetail.customQuestion') ?? ''}
                        childNode={<AdditionalQuestionTable id={id} activeEditButton={activeEditButton} data={assessmentDetails?.additionalQuestions}></AdditionalQuestionTable>} />
                </div>
            </div>
            {/* Add Setting config ASM - Draw */}
            <DrawerContent
                title={
                    <div className="text-title-medium text-primary-bold">
                        {i18next.t('assessmentDetail.setting')}
                    </div>
                }
                width={360}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                closable={true}
                closeIcon={<ICBack fill="var(--icon-med-em)" />}
                bodyStyle={{ padding: '0', gap: '24px' }}
            >
                <div className="flex flex-col gap-[32px]">
                    <div className="flex flex-col gap-[16px] p-[16px]">
                        <div className="flex">
                            <div className="flex items-center justify-center h-[56px] w-[56px] rounded-[44px] bg-surface-primary-accent-1">
                                <ICCheckBoard />
                            </div>
                        </div>
                        <div className="flex flex-col gap-[4px]">
                            <div className="flex items-center justify-between">
                                <div className="text-standard-bold text-high-em">{i18next.t('assessmentDetail.autoSendResult')}</div>
                                <div>
                                    <Switch checked={isAutoSendResult} style={{
                                        width: '36px',
                                    }} onChange={onChangeAutoSendResult} />
                                </div>
                            </div>
                            <div className="text-body text-med-em">
                                {i18next.t('assessmentDetail.autoSendResultDescription')}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[16px] p-[16px]">
                        <div className="flex">
                            <div className="flex items-center justify-center h-[56px] w-[56px] rounded-[44px] bg-surface-primary-accent-1">
                                <ICDownload width={24} height={24} fill="var(--text-primary)" />
                            </div>
                        </div>
                        <div className="flex flex-col gap-[4px]">
                            <div className="flex items-center justify-start">
                                <div className="text-standard-bold text-high-em">{i18next.t('assessmentDetail.downloadResult')}</div>
                            </div>
                            <div className="text-body text-med-em">
                                {i18next.t('assessmentDetail.downloadResultDescription')}
                            </div>
                        </div>
                        <div className="flex">
                            {activeDownloadResult && assessmentDetails?.status !== EStatusAssessment.LOCKED &&
                                <HRButton btnSize="md" btnType="primary" onClick={() => onDownloadAssessment()}>
                                    <div className="flex items-center justify-center gap-[8px] text-body-medium text-white">
                                        <span>{i18next.t('assessmentDetail.download')}</span>
                                        <ICDownload width={24} height={24} fill="white" />
                                    </div>
                                </HRButton>}

                            {!activeDownloadResult &&
                                <HRTooltip content={i18next.t('assessmentDetail.noCandidates')}
                                    placement={'left'}
                                    mouseLeaveDelay={0}
                                    childNode={
                                        <span className="w-full" style={{ cursor: !activeDownloadResult ? 'not-allowed' : 'pointer' }}>
                                            <HRButton btnSize="md" btnType="primary" disabled={true} onClick={() => onDownloadAssessment()}>
                                                <div className="flex items-center justify-center gap-[8px] text-body-medium text-[#D9D8DC]">
                                                    <span>{i18next.t('assessmentDetail.download')}</span>
                                                    <ICDownload width={18} height={18} fill="var(--icon-disabled)" />
                                                </div>
                                            </HRButton>
                                        </span>
                                    }>
                                </HRTooltip>
                            }
                        </div>
                    </div>
                </div>
            </DrawerContent>
        </div>
    </AssementDetailsDataContext.Provider>
}

type TTestsTableProps = {
    data: ILibraryUI[];
    id: string;
    activeEditButton: boolean;
    mode: 'test' | 'additionalQuestion';
}

const TestsTable: React.FC<TTestsTableProps> = ({
    data = [],
    id = '',
    activeEditButton,
    mode,
}) => {
    const columns: ColumnsType<any> = [
        {
            title: <span className="text-body-bold text-high-em">{i18next.t('assessmentDetail.testTitle')}</span>,
            dataIndex: 'title',
            key: 'title',
            sorter: false,
            width: '80%',
            render: (title: string, recordData: any) => {
                return <HRTooltip content={title} placement="bottom"
                    childNode={
                        <div className="flex gap-[8px] items-center">
                            {recordData?.price && <ICHasFee />}
                            <span className="line-clamp-2">{title}</span>
                        </div>
                    }>
                </HRTooltip>
            },
        },
        {
            title: <span className="text-body-bold text-high-em">{i18next.t('assessmentDetail.testDuration')}</span>,
            dataIndex: '_id',
            width: '20%',
            key: '_id',
            sorter: false,
            render: (_id: string, data: ILibraryUI) => {
                let timeQuestion = 0;
                const questionDuration = data.configuration?.questionDuration ?? 0;
                if (Array.isArray(questionDuration)) {
                    timeQuestion = questionDuration.reduce((acc, cur) => acc + cur ?? 0, 0);
                } else {
                    timeQuestion = questionDuration;
                }
                return i18next.t('assessmentDetail.timeQuestion', { t: timeQuestion });
            },
        },
    ];

    return (<Table
        className={classNames("white-header", "no-border")}
        columns={columns}
        dataSource={data}
        locale={{
            emptyText: <EmptyTable id={id} activeEditButton={activeEditButton} mode={mode} text={i18next.t('assessmentDetail.noTest')} />
        }}
        rowKey="_id"
        pagination={false}
    />
    )
};

type TAdditionalQuestionTableProps = {
    data: TAdditionalQuestion[];
    id: string;
    activeEditButton: boolean;
}

const AdditionalQuestionTable: React.FC<TAdditionalQuestionTableProps> = ({
    data = [],
    id,
    activeEditButton,
}) => {
    const columns: ColumnsType<any> = [
        {
            title: <span className="text-body-bold text-high-em">{i18next.t('assessmentDetail.customQuestionTitle')}</span>,
            dataIndex: 'content',
            key: 'content',
            width: '50%',
            sorter: false,
            render: (content: string) => {
                const newValue = htmlToText(content);
                return <HRTooltip content={newValue}
                    placement="bottom"
                    childNode={<span className="line-clamp-2">{newValue}</span>}>
                </HRTooltip>
            },
        },
        {
            title: <span className="text-body-bold text-high-em">{i18next.t('assessmentDetail.customQuestionType')}</span>,
            dataIndex: 'type',
            key: 'type',
            width: '30%',
            sorter: false,
            render: (type: EQuestionType) => {
                return QuestionTypeMapping[type];
            },
        },
        {
            title: <span className="text-body-bold text-high-em">{i18next.t('assessmentDetail.customQuestionDuration')}</span>,
            dataIndex: 'duration',
            key: 'duration',
            width: '20%',
            sorter: false,
            render: (duration: number) => {
                return `${duration} ${i18next.t('myAssessments.minutes')}`;
            },
        },
    ];

    return (<Table
        className="white-header no-border overflow-x-auto"
        columns={columns}
        dataSource={data}
        locale={{
            emptyText: <EmptyTable id={id} activeEditButton={activeEditButton} mode="additionalQuestion" text={i18next.t('assessmentDetail.noCustomQuestions')} />
        }}
        rowKey="_id"
        pagination={false}
    />
    )
}


type IEmptyTableProps = {
    id: string;
    text: string;
    activeEditButton: boolean;
    mode: 'test' | 'additionalQuestion';
}

const EmptyTable: React.FC<IEmptyTableProps> = ({
    id,
    text,
    mode = 'test',
    activeEditButton,
}) => {
    const navigate = useNavigate();
    const goEditAssessment = React.useCallback(() => {
        navigate(`/edit-assessment/${id}`, { replace: true });
    }, [navigate, id]);

    return (
        <div className="my-6 flex flex-col gap-[32px]">
            <div className="flex justify-center">
                <ICNoneData />
            </div>
            <div className="text-standard-bold text-high-em">
                {text}
            </div>
            {activeEditButton && <div className="flex justify-center">
                <span>
                    <HRButton btnType="secondary" btnSize="sm"
                        style={{
                            borderRadius: '144px'
                        }}
                        onClick={goEditAssessment}>
                        <div className="flex space-x-2 items-center px-4">
                            <ICAdd stroke="#7357FF" width={18} height={18} />
                            <span>{mode === 'test' ? i18next.t('assessmentDetail.addTest') : i18next.t('assessmentDetail.addCustomQuestion')}</span>
                        </div>
                    </HRButton>
                </span>
            </div>}
        </div>
    )
}
