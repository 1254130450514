import { TStepItem } from "./type";
import { StepProps } from 'antd';
import { GeneralStep } from './components/general/general';
import { AssessmentAddTest } from "./components/add-test";
import { AssessmentAdditionalTest } from "./components/add-additional-test";
import { ReviewConfigureAssessment } from "./components/review-configure";
import { EStatusCard, ILibrary } from "../../library/models";
import { uuid } from "../../create_test/tab/question/helper";
import i18next from "../../../i18n";

export type TStepItemProps = StepProps & TStepItem & {
}

export const STEP: TStepItemProps[] = [{
    title: i18next.t('createAssessment.generalInfomation'),
    component: <GeneralStep />
}, {
    title: i18next.t('createAssessment.addTest'),
    component: <AssessmentAddTest />
}, {
    title: i18next.t('createAssessment.customQuestions'),
    component: <AssessmentAdditionalTest />
}, {
    title: i18next.t('createAssessment.confirmInfomation'),
    component: <ReviewConfigureAssessment />
}];

export const DEFAULT_ADD_TESTS: ILibrary[] = [
    {
        uuid: uuid(),
        title: i18next.t('test', {t: 1}),
        description: i18next.t('test', {t: 1}) ?? '',
        planTag: EStatusCard.FREE,
        statusTag: EStatusCard.POPULAR,
    },
    {
        uuid: uuid(),
        title: i18next.t('test', {t: 2}),
        description: i18next.t('test', {t: 2}) ?? '',
        planTag: EStatusCard.FREE,
        statusTag: EStatusCard.POPULAR,
    },
    {
        uuid: uuid(),
        title: i18next.t('test', {t: 3}),
        description: i18next.t('test', {t: 3}) ?? '',
        planTag: EStatusCard.FREE,
        statusTag: EStatusCard.POPULAR,
    },
    {
        uuid: uuid(),
        title: i18next.t('test', {t: 4}),
        description: i18next.t('test', {t: 4}) ?? '',
        planTag: EStatusCard.FREE,
        statusTag: EStatusCard.POPULAR,
    },
    {
        uuid: uuid(),
        title: i18next.t('test', {t: 5}),
        description: i18next.t('test', {t: 5}) ?? '',
        planTag: EStatusCard.FREE,
        statusTag: EStatusCard.POPULAR,
    },
];
