import React from "react";
import { HRButton, IconButton } from "../../../../../../components/button"
import { ICAdd, ICClose, ICSave, ICTick, ICWarning } from "../../../../../../icons"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.style.css";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { answerToolbar, isAnswerError, isEmptyHTML, toolbar } from "./editor.config";
import { Checkbox, Form, Modal, Radio } from "antd";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classNames from 'classnames'
import { getInnerText, isDuplicateAnswer } from "../../../../../create_test/tab/question/helper";
import styled from 'styled-components';
import _ from "lodash";
import { TAdditionalQuestion, createDefaultQuestion, DEFAULT_DURATION } from '../../../type';
import { HRSelect } from "../../../../../../components/form/select";
import { HRForm } from "../../../../../../components/form/form";
import { useForm } from "antd/lib/form/Form";
import { HRInput } from "../../../../../../components/form/input";
import { HRTextArea } from "../../../../../../components/form/textarea";
import { useAssementData } from '../../../hooks/useCreateAssessment';
import { EQuestionType, generateOption } from "../../../../../create_test/tab/question/type";
import { ICBack } from "../../../../../../icons/back";
import { QUESTIONS_TIMING } from "../../../../../../utils/constants";
import i18next from "../../../../../../i18n";

const WrapperEditor = styled.div`
    &.read-only-mode {
        background: transparent;
        border-radius: 8px;
        padding: 0 16px;
        .anwser-questions {
            background: transparent;
            display: grid;
        }
    }
    &.read-only-mode.has-value {
        border: 1px solid #0BAA60;
    }
`;

type QuestionEditorProps = {
    selectedQuestion: TAdditionalQuestion | undefined
    questionType: EQuestionType.SINGLE_CHOICE | EQuestionType.MULTIPLE_CHOICE | EQuestionType.ESSAY
    onCancel: () => void
}
export const QuestionEditor: React.FC<QuestionEditorProps> = ({ selectedQuestion, questionType, onCancel }) => {
    const [question, setQuestion] = React.useState<TAdditionalQuestion>(() => selectedQuestion ?? createDefaultQuestion(questionType))
    const { setShowAddQuestion, onCreatedQuestion } = useAssementData();
    const [needCheck, setNeedCheck] = React.useState<boolean>(false);
    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question.content).contentBlocks))
    );
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [questionForm] = useForm();
    const QuestionsTimes = [
        {
            value: 1,
            label: i18next.t('createAssessment.questionTimes', { t: 1 })
        },
        {
            value: 2,
            label: i18next.t('createAssessment.questionTimes', { t: 2 })
        },
        ...QUESTIONS_TIMING,
    ];

    React.useEffect(() => {
        const q = selectedQuestion ?? createDefaultQuestion(questionType)
        setQuestion(q)
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(q.content).contentBlocks)))
    }, [questionType, selectedQuestion]);

    const isDirty = React.useMemo(() => {
        const formValues = questionForm.getFieldsValue();
        const isFormDirty = _.isEmpty(formValues) ? false : !_.isEqual({
            desiredAnswer: formValues.desiredAnswer ?? "",
            relatedReason: formValues.relatedReason ?? "",
            title: formValues.title ?? "",
            questionType: formValues.questionType ?? "",
        }, {
            desiredAnswer: selectedQuestion?.desiredAnswer ?? "",
            relatedReason: selectedQuestion?.relatedReason ?? "",
            title: selectedQuestion?.title ?? "",
            questionType: selectedQuestion?.questionType ?? "",
        })
        if (!selectedQuestion) return getInnerText(question.content).replace(/\n/g, '') !== ""
            || question.options.some(o => getInnerText(o.content).replace(/\n/g, '') !== "")
            || (question.type === EQuestionType.SINGLE_CHOICE && question.correctAnswer !== 0)
            || (question.type === EQuestionType.MULTIPLE_CHOICE && !_.isEqual(question.correctAnswer, [0]))
            || question.duration !== DEFAULT_DURATION
            || isFormDirty
        return question.content !== selectedQuestion?.content
            || question.options.some((o, i) => o !== selectedQuestion?.options[i])
            || question.correctAnswer !== selectedQuestion?.correctAnswer
            || question.duration !== selectedQuestion?.duration
            || isFormDirty
    }, [question, selectedQuestion, questionForm])

    const onEditorStateChange = (valueEditorState: EditorState) => {
        const text = valueEditorState.getCurrentContent().getPlainText()
        const oldContent = editorState.getCurrentContent().getPlainText()
        // const lines = text.split(/\n/)
        if (text.length > 5000) {
            const oldState = ContentState.createFromBlockArray(htmlToDraft(oldContent).contentBlocks);
            setEditorState(EditorState.moveFocusToEnd(EditorState.push(editorState, oldState, 'insert-characters')));
        } else {
            setEditorState(valueEditorState);
            setQuestion({
                ...question,
                content: draftToHtml(convertToRaw(valueEditorState.getCurrentContent()))
            })
        }
        // else if (lines.length > 50) {
        //     const undo = EditorState.undo(valueEditorState)
        //     setEditorState(undo)
        // }
    }

    const isQuestionError = React.useMemo(() => {
        return needCheck && isEmptyHTML(question.content)
    }, [question.content, needCheck]);

    const validationValues = (): boolean => {
        setNeedCheck(true);
        return isEmptyHTML(question.content)
            || question.options.some(o => isEmptyHTML(o.content))
            || !question.duration
            || isDuplicateAnswer(question.options)
            || isAnswerError(question)
    };

    const onSave = () => {
        if (!validationValues()) {
            onCreatedQuestion(question);
            setShowAddQuestion(false)
        }
    }

    const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false)
    const onCancelAction = React.useCallback(() => {
        if (isDirty) {
            setShowCancelModal(true)
        } else {
            onCancel()
        }
    }, [isDirty, setShowCancelModal, onCancel]);

    const title = React.useMemo(() => {
        return editorState.getCurrentContent().getPlainText();
    }, [editorState]);

    const childForm = (
        <div className="grid gap-[57px] grid-cols-[1fr]">
            <div className="grid lg:grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
                <Form.Item
                    name="title"
                >
                    <HRInput label={i18next.t('createAssessment.questionTitle') ?? ''} maxLength={50} />
                </Form.Item>

                <Form.Item name="questionType">
                    <HRSelect
                        name="questionType"
                        label={i18next.t('createAssessment.title') ?? ''}
                        placeholder={i18next.t('createAssessment.select')}
                    />
                </Form.Item>
            </div>

            <div className="grid lg:grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
                <Form.Item name="desiredAnswer">
                    <HRTextArea
                        label={i18next.t('createAssessment.desiredAnswer') ?? ''}
                        maxLength={1000}
                        style={{
                            height: "105px",
                        }}
                    />
                </Form.Item>
                <Form.Item name="relatedReason">
                    <HRTextArea
                        label={i18next.t('createAssessment.relatedReason') ?? ''}
                        maxLength={1000}
                        style={{
                            height: "105px",
                        }}
                    />
                </Form.Item>
            </div>
        </div>
    );

    const onValuesChangeForm = React.useCallback((_: any, allValues: any) => {
        setQuestion((prev) => ({
            ...prev,
            ...allValues
        }));
    }, [setQuestion]);

    return <div className="pb-16 space-y-4 lg:space-y-8 text-left lg-container mx-auto">
        <div className="flex items-center justify-between">
            <div>
                <div className="flex space-x-2 items-center">
                    <IconButton btnType="sub" btnSize="sm" icon={<ICBack width={18} height={18} />} onClick={onCancelAction} />
                    <span className="max-w-[500px] line-clamp-1 text-heading-5-bold text-high-em">{title || i18next.t('createAssessment.createCustomQuestion')}</span>
                </div>
            </div>
            <div className="hidden lg:flex space-x-4">
                <HRButton
                    btnType="sub"
                    btnSize="md"
                    onClick={onCancelAction}
                >
                    <div className="flex space-x-2 justify-center">
                        <span className="font-bold">{i18next.t('createAssessment.cancel')}</span>
                    </div>
                </HRButton>

                <HRButton
                    onClick={onSave}
                    btnSize="md"
                >
                    <div className="flex space-x-2 justify-center">
                        <ICSave />
                        <span>{selectedQuestion ? i18next.t('createAssessment.update') : i18next.t('createAssessment.save')}</span>
                    </div>
                </HRButton>
            </div>
        </div>

        <div className="mt-8 p-2 lg:p-8 bg-white rounded-[12px] grid grid-cols-1 gap-[36px] shadow-e-03">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-[38px]">
                <div className=" grid grid-cols-1 grid-rows-[auto_auto_1fr] justify-start items-start gap-[16px]">
                    <div className="text-primary-bold text-standard-medium">{i18next.t('createAssessment.questionSection')}</div>
                    <div className="grid grid-cols-1">
                        <span>
                            <HRSelect
                                className="w-full"
                                hasSearch={false}
                                isRequired={true}
                                label={i18next.t('createAssessment.questionDuration') ?? ''}
                                hasFilterSort={false}
                                allowClear={false}
                                placeholder={i18next.t('createAssessment.select') ?? ''}
                                value={question?.duration}
                                onChange={(value) => {
                                    setQuestion((prev) => ({
                                        ...prev,
                                        duration: value
                                    }));
                                }}
                                options={QuestionsTimes}
                            />
                            {needCheck && !question?.duration && <div className="flex items-start">
                                <span className="text-body italic text-error-500">{i18next.t('createAssessment.shouldNotEmpty')}</span>
                            </div>}
                        </span>
                    </div>
                    <div className="space-y-3"
                        onContextMenu={(e) => {
                            // @ts-ignore
                            document.querySelector(".questions-editor")?.focus?.()
                        }}>
                        <Editor
                            toolbar={toolbar}
                            editorState={editorState}
                            toolbarClassName={classNames(isQuestionError ? "error" : "", "editor-toolbar")}
                            wrapperClassName={classNames(isQuestionError ? "error" : "", "questions-wrapper", "editor-wrapper")}
                            editorClassName={classNames(isQuestionError ? "error" : "", "questions-editor", "editor-editor")}
                            onEditorStateChange={onEditorStateChange}
                            stripPastedStyles={true}
                            placeholder={i18next.t('createAssessment.enterQuestion') ?? ''}
                        />
                        {
                            isQuestionError && <div className="flex items-center space-x-2">
                                <ICWarning fill="#F55858" width={18} height={18} />
                                <span className="text-body italic text-error-500">{i18next.t('createAssessment.plsEnterQuestion')}</span>
                            </div>
                        }
                    </div>
                </div>
                <div className="grid grid-cols-1 grid-rows-[auto_1fr] justify-start items-start gap-[32px]">
                    <div className="text-primary-bold text-standard-medium">{i18next.t('createAssessment.answerSection')}</div>
                    <AnswerWrapper
                        needCheck={needCheck}
                        question={question}
                        onChanges={setQuestion}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 h-[1.4px] w-full bg-[#E2E4EB]"></div>
            <div className="grid grid-cols-1">
                <div className="grid grid-cols-1 gap-[8px]">
                    <span className="text-primary-bold text-standard-medium">{i18next.t('createAssessment.infoForYourTeam')}</span>
                    <span className="text-body-medium text-med-em">{i18next.t('createAssessment.notShowingToCandidate')}</span>
                </div>
            </div>
            <div className="grid grid-cols-1">
                <HRForm
                    form={questionForm}
                    name="questionForm"
                    initialValues={selectedQuestion}
                    onValuesChange={onValuesChangeForm}
                    childNode={childForm}
                />
            </div>
        </div>
        <div className="px-2 block lg:hidden">
            <HRButton
                onClick={onSave}
                btnSize="md"
            >
                <div className="flex space-x-2 justify-center">
                    <ICSave />
                    <span>{selectedQuestion ? i18next.t('createAssessment.update') : i18next.t('createAssessment.save')}</span>
                </div>
            </HRButton>
        </div>
        <Modal
            title={i18next.t('createAssessment.notification')}
            open={showCancelModal}
            centered={true}
            onOk={onCancel}
            onCancel={setShowCancelModal.bind(null, false)}
            okText={i18next.t('createAssessment.yes')}
            cancelText={i18next.t('createAssessment.no')}
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end">
                    <HRButton
                        btnSize="sm"
                        btnType="sub"
                        onClick={setShowCancelModal.bind(null, false)}>
                        {i18next.t('createAssessment.no')}
                    </HRButton>
                    <HRButton btnSize="sm" onClick={onCancel}>
                        {i18next.t('createAssessment.yes')}
                    </HRButton>
                </div>
            }
        >
            <div className="space-y-2">
                <div>{i18next.t('createAssessment.inputWillBeMissed')}</div>
                <div>{i18next.t('createAssessment.sureToCancel')}</div>
            </div>
        </Modal>
    </div>
}
type AnswerWrapperProps = {
    question: TAdditionalQuestion,
    onChanges?: (question: TAdditionalQuestion) => void,
    viewOnly?: boolean
    needCheck?: boolean
}
// Use for single choice and multiple choice only
export const AnswerWrapper: React.FC<AnswerWrapperProps> = ({
    question,
    onChanges,
    viewOnly = false,
    needCheck = false
}) => {
    const duplicates = React.useMemo(() => {
        const questionOptionsText = question.options.map(item => getInnerText(item.content))
        return questionOptionsText.map((item, index, arr) => {
            if (!item || item.length === 0) return false;
            return arr.some((item2, index2) => item && item2 && item === item2 && index !== index2)
        })
    }, [question.options])
    const onChangeAnswer = React.useMemo(() => {
        if (viewOnly) return () => { };
        if (!onChanges) return () => { };
        return onChanges
    }, [onChanges, viewOnly])

    const onChangeAnswerContent = (index: number, value: string) => {
        const newQuestion = { ...question };
        // Important: careful with immutable
        newQuestion.options = newQuestion.options.map((item, i) => {
            if (i === index) return {
                ...item,
                content: value
            }
            return item;
        });
        onChangeAnswer(newQuestion);
    }

    const onChangeCorrectAnswer = (index: number, isChecked?: boolean) => {
        switch (question.type) {
            case EQuestionType.SINGLE_CHOICE:
                const newSingleQuestion = _.cloneDeep(question);
                newSingleQuestion.correctAnswer = index;
                onChangeAnswer(newSingleQuestion);
                break;
            case EQuestionType.MULTIPLE_CHOICE:
                const newMultiQuestion = _.cloneDeep(question);
                if (isChecked) {
                    newMultiQuestion.correctAnswer.push(index);
                } else {
                    newMultiQuestion.correctAnswer = newMultiQuestion.correctAnswer.filter(item => item !== index);
                }
                onChangeAnswer(newMultiQuestion);
                break;
        }
    }

    const onMixAnswer = (isMixed: boolean) => {
        const newQuestion = { ...question };
        newQuestion.isMixed = isMixed;
        onChangeAnswer(newQuestion);
    }

    const onAddNewAnswer = () => {
        const newQuestion = { ...question };
        newQuestion.options = [
            ...newQuestion.options,
            generateOption("")
        ];
        onChangeAnswer(newQuestion);
    }

    const onRemoveAnswer = (index: number) => {
        if (index < 2) return;
        const newQuestion = { ...question };
        let correctAnswer = newQuestion.correctAnswer;
        if (newQuestion.type === EQuestionType.SINGLE_CHOICE) {
            if (newQuestion.correctAnswer === index) {
                correctAnswer = 0;
            }
        } else if (newQuestion.type === EQuestionType.MULTIPLE_CHOICE) {
            let newCorrectAnswer = [] as number[]
            newQuestion.correctAnswer.forEach((item, i) => {
                if (item === index) return;
                if (item > index) {
                    newCorrectAnswer.push(item - 1);
                } else {
                    newCorrectAnswer.push(item);
                }
            });
            correctAnswer = newCorrectAnswer;
        }
        // Important: careful with immutable
        const newOptions = [...newQuestion.options];
        newOptions.splice(index, 1);
        newQuestion.options = newOptions;
        newQuestion.correctAnswer = correctAnswer;
        onChangeAnswer(newQuestion);
    }

    return <div className={classNames('grid grid-cols-1 gap-[48px]', viewOnly ? 'answer-editor-view-only' : '')}>
        {
            question.options.map((answer, index) => {
                return <AnswerEditor
                    key={answer.id}
                    index={index} // id for what ???
                    answer={answer.content}
                    onChange={onChangeAnswerContent}
                    onChangeCorrectAnswer={onChangeCorrectAnswer}
                    onRemoveAnswer={onRemoveAnswer}
                    viewOnly={viewOnly}
                    isChecked={
                        question.type === EQuestionType.SINGLE_CHOICE ?
                            question.correctAnswer === index :
                            question.type === EQuestionType.MULTIPLE_CHOICE && Array.isArray(question.correctAnswer) ? question.correctAnswer.includes(index) : false
                    }
                    needCheck={needCheck}
                    questionType={question.type}
                    isDuplicate={duplicates[index]}
                />
            })
        }
        {
            needCheck && isAnswerError(question) && <div className="flex items-center space-x-2" style={{
                marginTop: "24px"
            }}>
                <ICWarning fill="#F55858" width={18} height={18} />
                <span className="text-body italic text-error-500">{i18next.t('createAssessment.selectCorrectAnswer')}</span>
            </div>
        }
        {
            !viewOnly && <div className="flex justify-between">
                <span>
                    <HRButton
                        onClick={onAddNewAnswer}
                        btnType="sub"
                        btnSize="md"
                    >
                        <div className="flex space-x-3 items-center">
                            <ICAdd stroke="#4F4B5C" />
                            <span className="text-high-em text-body-bold">{i18next.t('createAssessment.addAnswer')}</span>
                        </div>
                    </HRButton>
                </span>
                <div className="mt-2">
                    <Checkbox
                        checked={question.isMixed}
                        className="purple-checkbox"
                        onChange={(e) => {
                            onMixAnswer(e.target.checked)
                        }}
                    >
                        <span className="text-high-em text-body">{i18next.t('createAssessment.mixAnswer')}</span>
                    </Checkbox>
                </div>
            </div>
        }
    </div>
}

type AnswerEditorProps = {
    questionType: EQuestionType,
    answer: string
    onChange: (index: number, value: string) => void
    needCheck: boolean,
    isChecked: boolean,
    index: number,
    onChangeCorrectAnswer: (index: number, isChecked?: boolean) => void
    onRemoveAnswer: (index: number) => void
    viewOnly: boolean
    isDuplicate?: boolean
}
const AnswerEditor: React.FC<AnswerEditorProps> = ({
    questionType,
    answer,
    onChange,
    needCheck,
    isChecked,
    index,
    onChangeCorrectAnswer,
    viewOnly,
    onRemoveAnswer,
    isDuplicate
}) => {
    const [isFocus, setIsFocus] = React.useState<boolean>(false)

    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(answer).contentBlocks))
    )

    React.useEffect(() => {
        if (!answer) {
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(answer).contentBlocks)))
        }
    }, [answer])

    const onEditorStateChange = (valueEditorState: EditorState) => {
        if (viewOnly) return;
        let text = valueEditorState.getCurrentContent().getPlainText();
        const oldContent = editorState.getCurrentContent().getPlainText()
        // Prevent user enter new line
        if (text.length > 5000) {
            const oldState = ContentState.createFromBlockArray(htmlToDraft(oldContent).contentBlocks);
            setEditorState(EditorState.moveFocusToEnd(EditorState.push(editorState, oldState, 'insert-characters')));
        } else {
            setEditorState(valueEditorState);
            onChange(index, draftToHtml(convertToRaw(valueEditorState.getCurrentContent())))
        }
    }
    const isAnswerError = React.useMemo(() => {
        return needCheck && isEmptyHTML(answer)
    }, [answer, needCheck]);

    return <WrapperEditor className={classNames(viewOnly ? "read-only-mode" : "", isChecked ? 'has-value' : '', "grid grid-cols-1")}>
        <div className="grid grid-cols-[auto_1fr_auto] gap-[16px] items-center">
            {
                questionType === "single_choice" ? <Radio
                    checked={isChecked}
                    onChange={() => onChangeCorrectAnswer(index)}
                /> : <span className="pr-2">
                    <Checkbox
                        className="purple-checkbox"
                        checked={isChecked}
                        onChange={(e) => {
                            onChangeCorrectAnswer(index, e.target.checked);
                        }}
                    />
                </span>
            }
            <div className="relative">
                <Editor
                    readOnly={viewOnly}
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => setIsFocus(false)}
                    toolbar={answerToolbar}
                    editorState={editorState}
                    toolbarClassName={classNames(isFocus ? "" : "hidden", "answer-toolbar")}
                    wrapperClassName={classNames(isAnswerError || (isDuplicate && !isEmptyHTML(answer)) ? "error" : "", "anwser-questions", "editor-wrapper", viewOnly ? 'view-only' : '')}
                    editorClassName={classNames("anwser-questions anwser-editor", viewOnly ? 'view-only' : '')}
                    onEditorStateChange={onEditorStateChange}
                    handleReturn={() => {
                        return true;
                    }}
                    placeholder={i18next.t('createAssessment.answer') ?? ''}
                    stripPastedStyles={true}
                />
                {
                    isDuplicate && !isEmptyHTML(answer) && <div className="absolute bottom-[-25px] left-0 flex items-center space-x-2">
                        <ICWarning fill="#F55858" width={18} height={18} />
                        <span className="text-body italic text-error-500">{i18next.t('createAssessment.answerExisted')}</span>
                    </div>
                }
            </div>

            {!viewOnly && <span className="p-1 cursor-pointer" onClick={onRemoveAnswer.bind(this, index)}>
                <ICClose
                    fill={index > 1 ? "#46485C" : "#46485c2e"}
                    width={24}
                    height={24}
                />
            </span>}
        </div>
        {
            isAnswerError && <div className="flex items-center space-x-2 ml-8">
                <span className="text-body italic text-error-500">{i18next.t('createAssessment.shouldNotEmpty')}</span>
            </div>
        }
    </WrapperEditor>
}
