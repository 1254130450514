import { IconProps } from "./type"

export const ICInfo: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_564_1559)">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 24C13.5762 24.0021 15.1372 23.6926 16.5934 23.0894C18.0496 22.4862 19.3722 21.6012 20.4852 20.4852C21.6012 19.3722 22.4862 18.0496 23.0894 16.5934C23.6926 15.1372 24.0021 13.5762 24 12C24.002 10.4239 23.6925 8.86284 23.0894 7.40667C22.4862 5.95049 21.6012 4.62787 20.4852 3.51481C19.3722 2.39883 18.0496 1.51381 16.5934 0.910621C15.1372 0.307433 13.5762 -0.00203216 12 1.00419e-05C10.4239 -0.00199929 8.86284 0.307481 7.40667 0.910667C5.95049 1.51385 4.62787 2.39885 3.51481 3.51481C2.39885 4.62787 1.51385 5.95049 0.910667 7.40667C0.307481 8.86284 -0.00199929 10.4239 1.00419e-05 12C-0.00203216 13.5762 0.307433 15.1372 0.910621 16.5934C1.51381 18.0496 2.39883 19.3722 3.51481 20.4852C4.62787 21.6012 5.95049 22.4862 7.40667 23.0894C8.86284 23.6925 10.4239 24.002 12 24ZM11.9998 4.20005C12.3976 4.20005 12.7792 4.35808 13.0605 4.63939C13.3418 4.92069 13.4998 5.30222 13.4998 5.70005C13.4998 6.09787 13.3418 6.4794 13.0605 6.76071C12.7792 7.04201 12.3976 7.20005 11.9998 7.20005C11.602 7.20005 11.2204 7.04201 10.9391 6.76071C10.6578 6.4794 10.4998 6.09787 10.4998 5.70005C10.4998 5.30222 10.6578 4.92069 10.9391 4.63939C11.2204 4.35808 11.602 4.20005 11.9998 4.20005ZM11.0998 8.40005C10.4371 8.40005 9.8998 8.93731 9.8998 9.60005C9.8998 10.2628 10.4371 10.8 11.0998 10.8L11.0998 16.8H10.1998C9.53706 16.8 8.9998 17.3373 8.9998 18C8.9998 18.6628 9.53706 19.2 10.1998 19.2H12.2998H14.3998C15.0625 19.2 15.5998 18.6628 15.5998 18C15.5998 17.3373 15.0625 16.8 14.3998 16.8H13.4998V9.60005C13.4998 8.93731 12.9625 8.40005 12.2998 8.40005H11.0998Z" fill="#3FB483" />
            </g>
            <defs>
                <clipPath id="clip0_564_1559">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const ICInfoV2: React.FC<IconProps> = ({
    fill = "#46485C",
}) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3003_13833)">
                <path fillRule="evenodd" clipRule="evenodd" d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z" fill={fill}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M9 8.25C9.41421 8.25 9.75 8.58579 9.75 9V12C9.75 12.4142 9.41421 12.75 9 12.75C8.58579 12.75 8.25 12.4142 8.25 12V9C8.25 8.58579 8.58579 8.25 9 8.25Z" fill={fill} />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.25 6C8.25 5.58579 8.58579 5.25 9 5.25H9.0075C9.42171 5.25 9.7575 5.58579 9.7575 6C9.7575 6.41421 9.42171 6.75 9.0075 6.75H9C8.58579 6.75 8.25 6.41421 8.25 6Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_3003_13833">
                <rect width="18" height="18" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export const ICInfoV3: React.FC = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_8546_29922)">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 24C15.5762 24.0021 17.1372 23.6926 18.5934 23.0894C20.0496 22.4862 21.3722 21.6012 22.4852 20.4852C23.6012 19.3722 24.4862 18.0496 25.0894 16.5934C25.6926 15.1372 26.0021 13.5762 26 12C26.002 10.4239 25.6925 8.86284 25.0894 7.40667C24.4862 5.95049 23.6012 4.62787 22.4852 3.51481C21.3722 2.39883 20.0496 1.51381 18.5934 0.910621C17.1372 0.307433 15.5762 -0.00203216 14 1.00419e-05C12.4239 -0.00199929 10.8628 0.307481 9.40667 0.910667C7.95049 1.51385 6.62787 2.39885 5.51481 3.51481C4.39885 4.62787 3.51385 5.95049 2.91067 7.40667C2.30748 8.86284 1.998 10.4239 2.00001 12C1.99797 13.5762 2.30743 15.1372 2.91062 16.5934C3.51381 18.0496 4.39883 19.3722 5.51481 20.4852C6.62787 21.6012 7.95049 22.4862 9.40667 23.0894C10.8628 23.6925 12.4239 24.002 14 24ZM13.9998 4.20005C14.3976 4.20005 14.7792 4.35808 15.0605 4.63939C15.3418 4.92069 15.4998 5.30222 15.4998 5.70005C15.4998 6.09787 15.3418 6.4794 15.0605 6.76071C14.7792 7.04201 14.3976 7.20005 13.9998 7.20005C13.602 7.20005 13.2204 7.04201 12.9391 6.76071C12.6578 6.4794 12.4998 6.09787 12.4998 5.70005C12.4998 5.30222 12.6578 4.92069 12.9391 4.63939C13.2204 4.35808 13.602 4.20005 13.9998 4.20005ZM13.0998 8.40005C12.4371 8.40005 11.8998 8.93731 11.8998 9.60005C11.8998 10.2628 12.4371 10.8 13.0998 10.8L13.0998 16.8H12.1998C11.5371 16.8 10.9998 17.3373 10.9998 18C10.9998 18.6628 11.5371 19.2 12.1998 19.2H14.2998H16.3998C17.0625 19.2 17.5998 18.6628 17.5998 18C17.5998 17.3373 17.0625 16.8 16.3998 16.8H15.4998V9.60005C15.4998 8.93731 14.9625 8.40005 14.2998 8.40005H13.0998Z" fill="#0084FF"/>
            </g>
            <defs>
            <filter id="filter0_d_8546_29922" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_8546_29922"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.0666667 0 0 0 0 0.0470588 0 0 0 0 0.133333 0 0 0 0.12 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8546_29922"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8546_29922" result="shape"/>
            </filter>
            </defs>
        </svg>

    )
}

export const ICInfoBlack: React.FC<IconProps> = ({
    fill = "var(--icon-high-em)",
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12C11 11.4477 11.4477 11 12 11Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M11 8C11 7.44772 11.4477 7 12 7H12.01C12.5623 7 13.01 7.44772 13.01 8C13.01 8.55228 12.5623 9 12.01 9H12C11.4477 9 11 8.55228 11 8Z" fill={fill} />
        </svg>
    )
}

export const ICCircleInfo: React.FC = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1716_3487)">
            <path d="M7.99935 4.6665C8.36754 4.6665 8.66602 4.96498 8.66602 5.33317V7.99984C8.66602 8.36803 8.36754 8.6665 7.99935 8.6665C7.63116 8.6665 7.33268 8.36803 7.33268 7.99984V5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665Z" fill="#BEC0CA" />
            <path d="M7.99935 9.99984C7.63116 9.99984 7.33268 10.2983 7.33268 10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332H8.00602C8.37421 11.3332 8.67268 11.0347 8.67268 10.6665C8.67268 10.2983 8.37421 9.99984 8.00602 9.99984H7.99935Z" fill="#BEC0CA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.666016 7.99984C0.666016 3.94975 3.94926 0.666504 7.99935 0.666504C12.0494 0.666504 15.3327 3.94975 15.3327 7.99984C15.3327 12.0499 12.0494 15.3332 7.99935 15.3332C3.94926 15.3332 0.666016 12.0499 0.666016 7.99984ZM7.99935 1.99984C4.68564 1.99984 1.99935 4.68613 1.99935 7.99984C1.99935 11.3135 4.68564 13.9998 7.99935 13.9998C11.3131 13.9998 13.9993 11.3135 13.9993 7.99984C13.9993 4.68613 11.3131 1.99984 7.99935 1.99984Z" fill="#BEC0CA" />
        </g>
        <defs>
            <clipPath id="clip0_1716_3487">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
}
