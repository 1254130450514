import { Layout } from 'antd';
import { CandidateHeader } from './candidate-header';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import React from 'react';
import { Loading } from '../components/loading';
import { useParams } from 'react-router-dom';
import { setLoading } from '../redux/slices/appInfo';
import { useDispatch } from 'react-redux';
import { TColorCompanyProfile, TCompanyProfile, getCompanyProfileByAssessmentId, getCompanyProfileByAssessmentUrl, getCompanyProfileByTestCandidate } from '../api/company-profile';

type TCandidateLayout = {
    handleFullScreen: any;
    companyProfile?: TCompanyProfile;
    setCompanyProfile?: React.Dispatch<React.SetStateAction<TCompanyProfile>>;
}
export const CandidateLayoutContext = React.createContext<TCandidateLayout>({} as TCandidateLayout);
export const useCandidateLayoutContext = () => React.useContext(CandidateLayoutContext);

export const DEFAULT_COLOR_COMPANY_PROFILE: TColorCompanyProfile = {
    backgroundColor: '#7357FF',
    fontColor: '#FFFFFF',
};

type CandidateLayoutProps = {
    children: React.ReactNode
}
export const CandidateLayout: React.FC<CandidateLayoutProps> = ({
    children
}) => {
    const dispatch = useDispatch();
    const { id, url, assessmentUrl } = useParams();
    
    const { Header, Content } = Layout;
    const handleFullScreen = useFullScreenHandle();
    const [companyProfile, setCompanyProfile] = React.useState<TCompanyProfile>({} as TCompanyProfile);
    const [activeFullScreen, setActiveFullScreen] = React.useState<boolean>(false);

    const onChangeFullScreen = (isFull: boolean) => {
        setActiveFullScreen(isFull);
    };

    const setDefaultColor = () => {
        setCompanyProfile((prev) => ({
            ...prev,
            color: DEFAULT_COLOR_COMPANY_PROFILE,
        }));
    };

    const loadCompanyProfileByAssessment = React.useCallback(async () => {
        if (!id) return;
        dispatch(setLoading(true));
        try {
            const { data } = await getCompanyProfileByAssessmentId(id);
            if (!data?.color) {
                setDefaultColor();
            } else {
                setCompanyProfile(data);
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setLoading(false));
        }
    }, [id]);

    const loadCompanyProfileByAssessmentUrl = React.useCallback(async () => {
        if (!assessmentUrl) return;
        dispatch(setLoading(true));
        try {
            const { data } = await getCompanyProfileByAssessmentUrl(assessmentUrl);
            if (!data?.color) {
                setDefaultColor();
            } else {
                setCompanyProfile(data);
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setLoading(false));
        }
    }, [assessmentUrl]);

    const loadCompanyProfileByTesting = React.useCallback(async () => {
        if (!url) return;
        dispatch(setLoading(true));
        try {
            const { data } = await getCompanyProfileByTestCandidate(url);
            if (!data?.color) {
                setDefaultColor();
            } else {
                setCompanyProfile(data);
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setLoading(false));
        }
    }, [url]);

    React.useEffect(() => {
        loadCompanyProfileByAssessment();
        loadCompanyProfileByTesting();
        loadCompanyProfileByAssessmentUrl();
    }, []);

    return (
        <FullScreen handle={handleFullScreen} onChange={onChangeFullScreen}>
            <Layout style={{
                backgroundColor: "var(--hr-surface-low)"
            }}>
                <CandidateLayoutContext.Provider
                    value={{
                        handleFullScreen,
                        companyProfile,
                        setCompanyProfile,
                    }}
                >
                    <Header className='shadow-e-02' style={{
                        height: '70px',
                        backgroundColor: 'white',
                        zIndex: 99,
                        padding: 0,
                    }}>
                        <CandidateHeader />
                    </Header>
                    <Content style={{
                        minHeight: 'calc(100vh - 70px)',
                        padding: '32px 0',
                    }}>{children}</Content>

                </CandidateLayoutContext.Provider>
            </Layout>
            {activeFullScreen && <Loading />}
        </FullScreen>
    )
}

