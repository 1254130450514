import { IconProps } from "./type";

export const ICFavored: React.FC<IconProps> = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7666 4.31099L14.7668 4.31115C15.1573 4.70142 15.4671 5.16483 15.6785 5.67487L16.1404 5.48346L15.6785 5.67487C15.8898 6.18492 15.9986 6.73162 15.9986 7.28373C15.9986 7.83584 15.8898 8.38254 15.6785 8.89259C15.4671 9.40264 15.1573 9.86604 14.7668 10.2563L14.7667 10.2564L9.17915 15.8439L9.17771 15.8454C9.15447 15.8688 9.12682 15.8874 9.09635 15.9001C9.06589 15.9128 9.03321 15.9193 9.00021 15.9193C8.9672 15.9193 8.93453 15.9128 8.90406 15.9001C8.8736 15.8874 8.84595 15.8688 8.82271 15.8454L8.82126 15.8439L3.23376 10.2564L3.23252 10.2552C2.47836 9.5063 2.03597 8.49946 1.99443 7.43745C1.95288 6.37544 2.31528 5.33712 3.00864 4.53161C3.70199 3.72609 4.67481 3.2132 5.73118 3.09622C6.78755 2.97925 7.84901 3.26687 8.7018 3.90117L8.99921 4.12238L9.29732 3.90213C10.1068 3.30404 11.1041 3.01635 12.1079 3.09138C13.1116 3.16641 14.055 3.59918 14.7666 4.31099ZM14.416 9.9063L15.2711 9.05248H14.9306C15.1893 8.51964 15.3186 7.92794 15.3008 7.32615C15.2732 6.38764 14.89 5.4947 14.2289 4.82804C13.5677 4.16138 12.678 3.7708 11.7397 3.73536C10.8015 3.69991 9.88483 4.02224 9.17527 4.63712L9.161 4.64948L9.14771 4.66288C9.12446 4.68631 9.09681 4.70491 9.06635 4.7176L9.25864 5.17915L9.06635 4.7176C9.03589 4.7303 9.00321 4.73683 8.97021 4.73683C8.9372 4.73683 8.90453 4.7303 8.87406 4.7176L8.68177 5.17915L8.87406 4.7176C8.8436 4.70491 8.81595 4.68631 8.79271 4.66288L8.79187 4.66204C8.10218 3.96995 7.166 3.57988 6.18893 3.57748V3.57746L6.18389 3.5775C5.45321 3.58308 4.74045 3.8043 4.13502 4.21342C3.52959 4.62253 3.05845 5.20132 2.7807 5.87717C2.50295 6.55302 2.43097 7.29585 2.57377 8.01245C2.71657 8.72906 3.06781 9.38755 3.58342 9.9053L3.58441 9.9063L8.64691 14.9613L9.00021 15.3141L9.3535 14.9613L14.416 9.9063Z"
        fill="#46485C"
        stroke="#F55858"
      />
    </svg>
  );
};
