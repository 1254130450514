import React from "react";
import { useDispatch } from "react-redux";
import { useNotification } from "../../../hooks/useNotification";
import { setLoading } from "../../../redux/slices/appInfo";
import { TTesting } from "../../testing/type";
import { TApproveTesting } from "../../my_assessment/type";
import { approveTesting } from "../../../api/test-candidate";

export const useApproveRejectTesting = () => {
    const dispatch = useDispatch();
    const { showError } = useNotification();

    const onAcceptedTesting = React.useCallback(async (payload: TApproveTesting, setTestDetails: React.Dispatch<React.SetStateAction<TTesting | undefined>>,
        setLinkExpired: React.Dispatch<React.SetStateAction<boolean | undefined>>) => {
        dispatch(setLoading(true));
        try {
            const result = await approveTesting(payload);
            if (setTestDetails && result?.data) {
                setTestDetails((prev: any) => ({
                    ...prev,
                    ...result.data,
                }));
            }
        } catch (error: any) {
            console.error(error);
            if (setLinkExpired) {
                setLinkExpired(true);
            } else {
                showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
            }
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    return {
        onAcceptedTesting
    }
}
