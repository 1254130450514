import React from "react";
import { useDispatch } from "react-redux";
import { useNotification } from "../../../hooks/useNotification";
import { setLoading } from "../../../redux/slices/appInfo";
import { getOrderDetails, getPaymentDetails } from "../../../api/order";
import { TPaymentDetails } from "../type";
import { TOrderDetails } from "../../payment_status/type";
import { getCompanyProfile, TCompanyProfile } from "../../../api/company-profile";

export const usePayment = () => {
    const dispatch = useDispatch();
    const { showError } = useNotification();

    const loadPaymentDetails = React.useCallback(async (id: string, setPaymentDetails?: React.Dispatch<React.SetStateAction<TPaymentDetails>>) => {
        dispatch(setLoading(true));
        try {
            const result = await getPaymentDetails(id);
            if (setPaymentDetails && result?.data) {
                setPaymentDetails((prev: any) => ({
                    ...prev,
                    ...result.data,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const loadOrderDetails = React.useCallback(async (id: string, setOrderDetails?: React.Dispatch<React.SetStateAction<TOrderDetails>>) => {
        dispatch(setLoading(true));
        try {
            const result = await getOrderDetails(id);
            if (setOrderDetails && result?.data) {
                setOrderDetails((prev: any) => ({
                    ...prev,
                    ...result.data,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const loadCompanyProfile = React.useCallback(async (setCompanyProfile: React.Dispatch<React.SetStateAction<TCompanyProfile>>) => {
        dispatch(setLoading(true));
        try {
            const result = await getCompanyProfile();
            if (setCompanyProfile && result?.data) {
                setCompanyProfile((prev: any) => ({
                    ...prev,
                    ...result.data,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    return {
        loadPaymentDetails,
        loadOrderDetails,
        loadCompanyProfile
    }
}
