import React from "react"
import classNames from 'classnames'
import { HRSelect } from "../../../../components/form/select"
import { HRForm } from "../../../../components/form/form"
import { HRMobilePanel, HRPanel } from "./components/panel"
import { SectionHeader } from './section-header';
import { useConfigQuestionContextContext } from './index';
import { InputNumber, Form, Input } from 'antd';
import { removeUndefinedAttribute } from "../../../../utils";
import styled from 'styled-components';
import { DEFAULT_QUESTIONS_TIMING } from './contants';
import { uuid } from "./helper"
import { QUESTIONS_TIMING } from "../../../../utils/constants"
import i18next from "../../../../i18n"

const FormItem = styled.span`
    .ant-form-item-explain-error {
        position: absolute;
        font-size: 10px;
        bottom: -15px;
        left: 0;
    }
`;

export const ConfigQuestion: React.FC = () => {
    const {
        skillAreas,
        questionUsageForm,
        questionUsage,
        setQuestionUsage,
        questionDuration,
        setQuestionDuration
    } = useConfigQuestionContextContext();
    const [disableQuestionUsageForm, setDisableQuestionUsageForm] = React.useState<boolean>(false);
    const onValuesChangeUsageForm = React.useCallback((values: any, allValues: any) => {
        const newValues = Object.keys(removeUndefinedAttribute(allValues)).map((k) => allValues[k]);
        setQuestionUsage(newValues);
    }, [setQuestionUsage]);

    const onCollapseQuestionUsage = React.useCallback((values: any) => {
        if (!values) {
            setQuestionUsage(undefined);
            setDisableQuestionUsageForm(false);
            questionUsageForm?.resetFields();
        } else {
            setQuestionUsage([]);
            setDisableQuestionUsageForm(true);
        }
    }, [questionUsageForm, setQuestionUsage]);

    const totalActiveQuestions = React.useMemo(() => {
        return skillAreas.map(d => d.questions).reduce((a, b) => [...a, ...b], [])?.filter(d => d.isActive === true);
    }, [skillAreas]);

    const activeQuestions = React.useMemo(() => {
        return skillAreas.map((d, index) => {
            const totalActive = d.questions?.filter(q => q.isActive === true).length;
            const value = Array.isArray(questionUsage) ? (questionUsage[index] > totalActive ? totalActive : questionUsage[index]) : null;
            const field = `question_${index + 1}`;
            return {
                ...d,
                numQuestions: totalActive,
                [`${field}`]: value,
                uuid: uuid()
            }
        });
    }, [skillAreas, questionUsage]);

    const renderNumberQuestions = React.useMemo(() => {
        return activeQuestions.map((item, index) => {
            if (item.numQuestions < 1) {
                return null;
            }
            const value = Array.isArray(questionUsage) ? (questionUsage[index] > item.numQuestions ? item.numQuestions : questionUsage[index]) : undefined;

            return <div key={index}
                className={classNames(activeQuestions.length !== (index + 1) ? "border-y-[1px] border-t-0 border-[#BEC0CA]" : "", "grid grid-cols-[1fr] gap-[16px] p-[16px]")}>
                <span className="text-left text-[#46485C] font-bold text-[16px] leading-[20px]">{item.name}</span>
                <div className="grid grid-cols-[auto_120px_auto] gap-[16px] items-center justify-start text-[#46485C]">
                    <span>{i18next.t('createTest.use')}</span>
                    <FormItem className="relative w-full" key={`formItem_${item.uuid}`}>
                        <Form.Item name={`question_${index + 1}`}
                            required
                            initialValue={value}
                            rules={[
                                {
                                    required: true,
                                    message: i18next.t('createTest.enterQuestionTitle') ?? '',
                                },
                                {
                                    message: i18next.t('createTest.totalQuestionsGreaterThan0') ?? '',
                                    validator: (_, value) => {
                                        if (value === null || value > 0 || value === undefined) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject();
                                    }
                                }
                            ]}>
                            <InputNumber
                                type="number"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                min={0}
                                value={value}
                                style={{
                                    width: "100%"
                                }}
                                max={item.numQuestions}
                            />
                        </Form.Item>
                    </FormItem>
                    <span>{i18next.t('createTest.numberQuestionsIn', { t: item.numQuestions })}</span>
                </div>
            </div>
        });
    }, [questionUsage, activeQuestions]);

    return <div className="bg-white pt-[30px]">
        <div className="grid grid-cols-[1fr] gap-[16px]">
            <SectionHeader title={i18next.t('createTest.configure')} hint={i18next.t('createTest.limitNumberQuestion')} />
            <div className="hidden lg:block">
                <HRPanel
                    collapseText={i18next.t('createTest.editGroupQuestion')}
                    open={Array.isArray(questionUsage) ? true : false}
                    header={(
                        <div className="grid grid-cols-1 lg:grid-cols-[auto_auto] gap-[16px] items-center justify-start text-[#46485C]">
                            <span className="text-[#46485C] font-bold text-[16px] leading-[20px]">{i18next.t('createTest.totalQuestions')}</span>
                            <div className="flex space-x-2 items-center">
                                <span>{i18next.t('createTest.use')}</span>
                                <span>
                                    <Input
                                        className="hr-input-number"
                                        onKeyPress={(event: any) => {
                                            if (event.key === '0' && !event.target.value) {
                                                event.preventDefault();
                                            }
                                            else if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            const valueNumber = parseInt(value);
                                            if (!value || valueNumber === 0) {
                                                setQuestionUsage(undefined);
                                                return;
                                            }
                                            if (valueNumber > totalActiveQuestions?.length) {
                                                setQuestionUsage(totalActiveQuestions?.length ?? 0);
                                                return;
                                            }
                                            else if (isNaN(valueNumber)) {
                                                return;
                                            }
                                            setQuestionUsage(value ? valueNumber : undefined);
                                        }}
                                        onPaste={e => e.preventDefault()}
                                        disabled={disableQuestionUsageForm || Array.isArray(questionUsage)}
                                        status={!Array.isArray(questionUsage) && questionUsage && (questionUsage > totalActiveQuestions?.length) ? 'error' : ''}
                                        value={!Array.isArray(questionUsage) ? questionUsage : undefined}
                                        style={{
                                            width: "120px"
                                        }}
                                        max={totalActiveQuestions?.length}
                                        allowClear={true}
                                    />
                                </span>
                                <span>{i18next.t('createTest.numberQuestionsIn', { t: totalActiveQuestions?.length })}</span>
                            </div>
                        </div>)}
                    body={
                        <HRForm form={questionUsageForm}
                            name="usageForm"
                            initialValues={activeQuestions}
                            onValuesChange={onValuesChangeUsageForm}
                            childNode={renderNumberQuestions} />
                    }
                    onChangeCollapse={onCollapseQuestionUsage}
                />
            </div>
            <div className="block lg:hidden">
                <HRMobilePanel
                    canExpand={totalActiveQuestions?.length > 0}
                    open={Array.isArray(questionUsage) ? true : false}
                    header={<span className="text-[#46485C] font-bold text-[16px] leading-[20px]">{i18next.t('createTest.totalQuestions')}</span>}
                    subTitle={<div className="flex space-x-2 items-center">
                        <span>{i18next.t('createTest.use')}</span>
                        <span>
                            <Input
                                className="hr-input-number"
                                onKeyPress={(event: any) => {
                                    if (event.key === '0' && !event.target.value) {
                                        event.preventDefault();
                                    }
                                    else if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    const valueNumber = parseInt(value);
                                    if (!value || valueNumber === 0) {
                                        setQuestionUsage(undefined);
                                        return;
                                    }
                                    if (valueNumber > totalActiveQuestions?.length) {
                                        setQuestionUsage(totalActiveQuestions?.length ?? 0);
                                        return;
                                    }
                                    else if (isNaN(valueNumber)) {
                                        return;
                                    }
                                    setQuestionUsage(value ? valueNumber : undefined);
                                }}
                                onPaste={e => e.preventDefault()}
                                disabled={disableQuestionUsageForm || Array.isArray(questionUsage)}
                                status={!Array.isArray(questionUsage) && questionUsage && (questionUsage > totalActiveQuestions?.length) ? 'error' : ''}
                                value={!Array.isArray(questionUsage) ? questionUsage : undefined}
                                style={{
                                    width: "120px"
                                }}
                                max={totalActiveQuestions?.length}
                                allowClear={true}
                            />
                        </span>
                        <span>{i18next.t('createTest.numberQuestionsIn', { t: totalActiveQuestions?.length })}</span>
                    </div>}
                    body={<HRForm form={questionUsageForm}
                        name="usageForm"
                        initialValues={activeQuestions}
                        onValuesChange={onValuesChangeUsageForm}
                        childNode={renderNumberQuestions}
                    />
                    }
                    onChangeCollapse={onCollapseQuestionUsage}
                />
            </div>
            <div className="hidden lg:block">
                <HRPanel
                    header={(
                        <div className="grid grid-cols-[auto_230px] gap-[16px] items-center justify-start text-[#46485C]">
                            <span className="text-[#46485C] font-bold text-[16px] leading-[20px]">{i18next.t('createTest.questionDuration')}</span>
                            <span>
                                <HRSelect
                                    className="w-full"
                                    value={!Array.isArray(questionDuration) ? questionDuration : null}
                                    hasSearch={false}
                                    hasFilterSort={false}
                                    allowClear={false}
                                    onChange={(value) => setQuestionDuration(value)}
                                    options={QUESTIONS_TIMING}
                                />
                            </span>
                        </div>)}
                    body={<></>}
                />
            </div>
            <div className="block lg:hidden">
                <HRMobilePanel
                    canExpand={false}
                    header={<span className="text-[#46485C] font-bold text-[16px] leading-[20px]">{i18next.t('createTest.questionDuration')}</span>}
                    subTitle={
                        <span>
                            <HRSelect
                                className="w-full"
                                value={!Array.isArray(questionDuration) ? questionDuration : null}
                                hasSearch={false}
                                hasFilterSort={false}
                                allowClear={false}
                                onChange={(value) => setQuestionDuration(value)}
                                options={QUESTIONS_TIMING}
                            />
                        </span>
                    }
                    body={<></>}
                />
            </div>
        </div>

    </div>
}
