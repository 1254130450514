
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { UserRoles } from "../../slices/userInfo";

export function UseSelectorTyped<T>(fn: (state: RootState) => T): T {
  return useSelector(fn);
}

export const firstValueCommonData = (name: string, language: string, fieldName: string = '_id') => {
    const commonData = UseSelectorTyped(state => state.commonData);
    if (commonData[name] && Array.isArray(commonData[name])) {
      const data = commonData[name].find(d => d.code === language) as any;
      return data[fieldName] || null;
    }
    return null;
}

export const getNameCommonData = (name: string, value: string) => {
  const commonData = UseSelectorTyped(state => state.commonData);
  if (commonData[name] && Array.isArray(commonData[name])) {
    return commonData[name]?.filter(d => d._id === value)[0]?.label || '';
  }
  return '';
}

export const isSuponsor = () => {
  const user = UseSelectorTyped(state => state.userInfo);
  return user?.role === UserRoles.SPONSOR;
};
