import { Badge } from "antd";
import React from "react";
import { EStatusCard } from "../../../../../library/models";

const statusTextMapping = {
  new: "Mới",
  popular: "Phổ biến",
  normal: "Phổ biến",
  pay: "Trả phí",
  free: "Miễn phí",
};

const statusColorMapping = {
  new: "badge-new",
  popular: "badge-popular",
  normal: "badge-normal",
  pay: "badge-paid",
  free: "badge-free",
}

type StatusQuestionProps = {
  text: EStatusCard;
};
export const StatusQuestion: React.FC<StatusQuestionProps> = ({
  text,
}) => {
  return (
    <>
     {text && <Badge count={statusTextMapping[text]} 
        className={statusColorMapping[text]}
        style={{
            fontSize: "11px",
            fontWeight: 700,
            lineHeight: "17px",
            padding: "4px 8px",
            height: "25px",
            borderRadius: "44px"
        }} />}
    </>
  );
};
