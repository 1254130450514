import { Input, Modal, Switch } from "antd"
import React from "react"
import { HRButton, IconButton } from "../../../../components/button"
import { ICAdd, ICChevronDown, ICChevronUp, ICDelete, ICEdit, ICEye } from "../../../../icons"
import { ESkillAreaType, SkillAreaTypeOptions, TSkillArea } from "./type"
import classNames from 'classnames'
import { useCreateTestPageContext } from "../.."
import { QuestionType } from "./type"

import { GroupQuestion } from "./group-question";
import { HRInput } from "../../../../components/form/input";
import { quoteString } from "./helper"
import { HRSelect } from "../../../../components/form/select"
import { isSuponsor } from "../../../../redux/selectors/commonData"
import { HRTextArea } from "../../../../components/form/textarea"
import i18next from "../../../../i18n"
import parse from 'html-react-parser'
import { useMobile } from "../../../../hooks/useDimensions"

type SkillSectionProps = {
    data: TSkillArea,
    questionType?: QuestionType,
    onChange: (data: TSkillArea) => void
    index: number,
    checkActiveWeight?: boolean,
    onMove: (
        indexQuestion: number,
    ) => void
}
export const SkillSection: React.FC<SkillSectionProps> = ({
    data,
    questionType = 'group',
    onChange,
    checkActiveWeight,
    index,
    onMove
}) => {
    const {
        id,
        setShowAddQuestion,
        onDeletedSkillArea,
        skillAreas,
        formFilter,
        testDetails,
        pageIsDirty,
        setSkillAreaTypeSelected,
    } = useCreateTestPageContext();

    const onAddQuestion = React.useCallback(() => {
        setSkillAreaTypeSelected(data.type);
        setShowAddQuestion(true, index);
    }, [setShowAddQuestion, index, data.type, setSkillAreaTypeSelected]);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const hasSuponsor = isSuponsor();
    const isMobile = useMobile();
    const totalActiveQuestion = React.useMemo(() => {
        return data.questions.filter(q => q.isActive).length
    }, [data.questions])
    const [isExpand, setIsExpand] = React.useState(true);
    const onToggleExpand = React.useCallback(() => {
        setIsExpand(prev => !prev);
    }, []);

    const [confirmNameDelete, setConfirmNameDelete] = React.useState('');

    const existsNames = React.useMemo(() => {
        return skillAreas.map(s => s.name);
    }, [skillAreas]);
    const onChangeSectionName = React.useCallback((name: string) => {
        onChange({
            ...data,
            name
        })
    }, [data, onChange]);

    const visibleQuestions = React.useMemo(() => {
        return data.questions.map(q => {
            let isMatch = true;
            if (formFilter.title) {
                const regex = new RegExp(quoteString(formFilter.title), 'i');
                isMatch = regex.test(q.content);
            }
            if (formFilter.questionType && formFilter.questionType !== q.type) isMatch = false;
            if (formFilter.isActive !== undefined && formFilter.isActive !== q.isActive) isMatch = false;
            return isMatch;
        })
    }, [data.questions, formFilter]);

    React.useEffect(() => {
        const hasVisibleQuestion = visibleQuestions.some(v => v);
        if (hasVisibleQuestion) {
            setIsExpand(true);
        } else {
            setIsExpand(false);
        }
    }, [formFilter, visibleQuestions]);

    const goPreviewTest = React.useCallback(() => {
        setShowConfirm(false);
        if (questionType === 'sample') {
            window.open(`/testing/${id}/preview`, '_blank');
        } else {
            window.open(`/testing/${id}/preview?index=${index}`, '_blank');
        }
    }, [id, index, questionType]);

    const activePreviewQuestion = React.useMemo(() => {
        if (questionType === "sample") {
            return (testDetails?.previewSection?.length || 0) > 0;
        }
        return (testDetails?.skillSections?.[index > 0 ? index - 1 : index]?.questions?.length || 0) > 0;
    }, [testDetails, index, questionType]);

    const onPreviewTesting = React.useCallback(() => {
        if (!pageIsDirty) {
            goPreviewTest();
        } else {
            setShowConfirm(true);
        }
    }, [pageIsDirty]);

    const handleActiveChanged = React.useCallback((checked: boolean) => {
        onChange({
            ...data,
            hasQuestionWeight: checked,
            questions: data?.questions?.map(q => ({
                ...q,
                weight: checked ? (q.weight || 1) : undefined
            }))
        })
    }, [data, onChange]);

    const hasAddedQuestion = React.useMemo(() => {
        if (!hasSuponsor) {
            return false;
        }

        return questionType !== "sample" ? !data?.type : false;
    }, [data?.type, questionType, hasSuponsor]);

    return <div className="flex flex-col gap-[16px] bg-surface-low border-[1px] border-outline-med rounded-[8px] shadow-e-01 p-[16px]">
        <div className="grid grid-cols-1 lg:grid-cols-2 justify-between items-start gap-2">
            <div className="flex flex-col gap-[8px]">
                <EditableHeader
                    content={data.name}
                    onChange={onChangeSectionName}
                    canEdit={questionType === 'group'}
                    existsNames={existsNames}
                />
                {questionType === 'group' && <div className="flex min-w-[250px]">
                    <HRTextArea maxLength={5000} autoSize
                        style={{
                            width: '100%',
                            minWidth: isMobile ? '300px' : '500px',
                            borderRadius: "4px",
                            border: 0,
                            fontStyle: "italic",
                            fontSize: "14px",
                            lineHeight: "24px",
                            color: "#110C22",
                            backgroundColor: "#F8F8F8"
                        }}
                        placeholder={`${i18next.t('createTest.description')}...`} 
                        value={data?.description as string}
                        onChange={(e) => {
                            onChange({
                                ...data,
                                description: e.target.value
                            })
                        }} />
                </div>}
            </div>
            <div className="flex justify-end gap-[16px] items-center">
                <div className="flex items-center gap-[16px]">
                    {hasSuponsor && questionType !== 'sample' && <HRSelect
                        placeholder={i18next.t('createTest.select')}
                        options={SkillAreaTypeOptions}
                        className="w-full min-w-[200px] lg:min-w-[250px]"
                        value={data.type}
                        disabled={data?.questions?.length > 0}
                        onChange={(value) => {
                            onChange({
                                ...data,
                                type: value
                            })
                        }}
                    />}
                    {questionType !== 'sample' && <IconButton btnSize="sm" btnType="tertiary"
                        onClick={setShowDeleteModal.bind(this, true)}
                        icon={<ICDelete fill="#4F4B5C" width={18} height={18} />} />}
                    <IconButton btnSize="sm" btnType="tertiary"
                        onClick={onToggleExpand}
                        icon={isExpand ? <ICChevronUp /> : <ICChevronDown />} />
                </div>
            </div>
        </div>
        <div className="h-[1px] w-full bg-outlines-med"></div>
        <div className="flex justify-between">
            <div>
                <span
                    className={classNames(
                        data.questions.length < 10 ? 'text-n-800' :
                            data.questions.length < 80 ? 'text-dark-action' :
                                data.questions.length < 100 ? 'text-dark-warning' :
                                    "text-sy-dark-alert"
                    )}
                >{totalActiveQuestion} / {data.questions.length} {i18next.t('createTest.numberQuestions')}</span>
            </div>
            {questionType === "group" && <div className="flex gap-[8px] items-center">
                <Switch checked={data?.hasQuestionWeight} onChange={handleActiveChanged} />
                <span className="text-body text-high-em">{i18next.t('createTest.scoreForEachQuestion')}</span>
            </div>}
        </div>
        {
            isExpand && data.questions.length > 0 && <div>
                <GroupQuestion
                    data={data}
                    index={index}
                    setShowDeleteModal={setShowDeleteModal}
                    questionType={questionType}
                    activeWeight={data?.hasQuestionWeight}
                    onChange={onChange}
                    visibleQuestions={visibleQuestions}
                    onMove={onMove}
                />
            </div>
        }
        <div className="line bg-outlines-med w-full h-[1px]"></div>
        <div className="flex items-center justify-end gap-[16px]">
            <span className="hidden lg:inline">
                {activePreviewQuestion && <HRButton
                    btnType="sub"
                    btnSize="md"
                    onClick={onPreviewTesting}>
                    <div className="grid grid-cols-[auto_auto] space-x-2 justify-center">
                        <ICEye />
                        <span>{i18next.t('createTest.preview')}</span>
                    </div>
                </HRButton>}
            </span>
            <span>
                <HRButton btnType="secondary" btnSize="md" disabled={hasAddedQuestion} onClick={onAddQuestion}>
                    <div className="grid grid-cols-[auto_auto] space-x-2 items-center justify-center cursor-pointer">
                        <ICAdd stroke="#7357FF" width={24} height={24} />
                        <span className="text-body-bold text-primary">{i18next.t('createTest.question')}</span>
                    </div>
                </HRButton>
            </span>
        </div>
        <Modal
            width={375}
            centered
            onCancel={() => setShowDeleteModal(false)}
            open={showDeleteModal}
            afterClose={() => setConfirmNameDelete('')}
            title={
                <div className="font-bold text-[18px]">{i18next.t('createTest.removeGroupQuestion')}</div>
            }
            footer={
                <div className="flex justify-center gap-[16px]">
                    <HRButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={() => setShowDeleteModal(false)}>
                        {i18next.t('createTest.cancel')}
                    </HRButton>
                    <HRButton
                        btnSize="sm"
                        disabled={confirmNameDelete !== data.name}
                        onClick={() => {
                            onDeletedSkillArea(index)
                            setShowDeleteModal(false)
                        }}>
                        {i18next.t('createTest.confirm')}
                    </HRButton>
                </div>
            }
        >
            <div className="space-y-2">
                <div className="text-base font-medium">{i18next.t('createTest.sureToRemoveGroupQuestion')}: <span className="font-bold text-primary">{data.name}</span> ({data.questions.length} {i18next.t('createTest.numberQuestions')})</div>
                <div className="space-y-1">
                    <div>
                        {parse(i18next.t('createTest.enterToConfirm', { t: `<span className="font-bold text-sy-dark-alert"> ${data.name} </span>` }))}
                    </div>
                    <HRInput
                        value={confirmNameDelete}
                        onChange={(e) => setConfirmNameDelete(e.target.value)}
                    />
                </div>
            </div>
        </Modal>
        <Modal
            open={showConfirm}
            centered={true}
            closable={false}
            title={
                <div className="font-bold text-[18px] leading-[22px]">{i18next.t('createTest.previewTest')}</div>
            }
            footer={
                <div className="flex justify-end space-x-6">
                    <span>
                        <HRButton
                            btnSize="sm"
                            btnType="outline"
                            onClick={goPreviewTest}>
                            {i18next.t('createTest.continuePreview')}
                        </HRButton>
                    </span>
                    <span>
                        <HRButton
                            btnSize="sm"
                            btnType="primary"
                            onClick={() => setShowConfirm(false)}>
                            {i18next.t('createTest.gotIt')}
                        </HRButton>
                    </span>
                </div>
            }
        >
            <p className="font-medium text-[16px] leading-[24px]">{i18next.t('createTest.changeNotSaved')}</p>
        </Modal>
    </div>
}

type EmptySkillHeaderProps = {
    showCloseButton?: boolean,
    onClose?: () => void,
    onAddQuestion: () => void
}
const EmptySkillHeader: React.FC<EmptySkillHeaderProps> = ({
    showCloseButton = false,
    onClose,
    onAddQuestion
}) => {
    return <div className="flex gap-[16px] items-center">
        <HRSelect
            placeholder={i18next.t('createTest.select')}
            options={SkillAreaTypeOptions}
            className="w-full min-w-[250px]"
        />
        {/* {
            showCloseButton && <span className="p-2 cursor-pointer" onClick={onClose}>
                <ICDelete fill="#4F4B5C" width={18} height={18} />
            </span>
        } */}
    </div>
}

type EditableHeaderProps = {
    content: string,
    onChange: (content: string) => void
    canEdit?: boolean,
    existsNames?: string[]
}
const EditableHeader: React.FC<EditableHeaderProps> = ({
    content,
    onChange,
    canEdit = true,
    existsNames = []
}) => {
    const [newContent, setNewContent] = React.useState(content);
    const [errorMess, setErrorMess] = React.useState("");
    React.useEffect(() => {
        setNewContent(content);
    }, [content]);
    const [isEditing, setIsEditing] = React.useState(false);

    const onSubmit = React.useCallback(() => {
        if (!newContent) {
            setErrorMess(i18next.t('createTest.groupNameShouldNotEmpty') ?? '');
        } else {
            const isExist = existsNames.some(name => name === newContent && name !== content);
            if (isExist) {
                setErrorMess(i18next.t('createTest.groupNameExisted') ?? '');
            } else {
                onChange(newContent);
                setIsEditing(false);
                setErrorMess("");
            }
        }
    }, [newContent, onChange, existsNames, content]);

    const onCancel = React.useCallback(() => {
        setNewContent(content);
        setIsEditing(false);
    }, [content]);
    return <div className="text-base font-bold text-n-800 flex space-x-2 items-center">
        {
            isEditing ? <div className="space-y-1">
                <Input
                    style={{
                        borderColor: errorMess.length ? "#F55858" : "#D6D7DD",
                    }}
                    value={newContent}
                    onChange={(e) => setNewContent(e.target.value)}
                    onBlur={onSubmit}
                    autoFocus
                    onFocus={(event) => event.target.select()}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onSubmit();
                        }
                        if (e.key === "Escape") {
                            onCancel();
                        }
                    }}
                />
                {
                    errorMess.length > 0 && <div className="text-[12px] text-[#F55858]">{errorMess}</div>
                }
            </div> : <>
                <span className="text-gray-800 text-standard-bold">
                    {content}
                </span>
                {
                    canEdit && <span className="p-1 cursor-pointer" onClick={() => setIsEditing(true)}>
                        <ICEdit width={18} height={18} />
                    </span>
                }
            </>
        }
    </div>
}
