import { IconProps } from "./type"

export const ICClose: React.FC<IconProps> = ({
    height = 40,
    width = 40,
    fill = "var(--text-high-em)",
    className = "svgFillColor"
}) => {
    return (
        <svg width={width} height={height} className={className} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.82196 8.82168C9.13451 8.50923 9.55835 8.3337 10.0003 8.3337C10.4422 8.3337 10.8661 8.50923 11.1786 8.82168L20.0003 17.6433L28.822 8.82168C28.9757 8.6625 29.1596 8.53553 29.363 8.44818C29.5663 8.36083 29.785 8.31485 30.0063 8.31293C30.2276 8.31101 30.4471 8.35318 30.6519 8.43698C30.8567 8.52078 31.0428 8.64453 31.1993 8.80102C31.3558 8.95751 31.4795 9.1436 31.5633 9.34842C31.6471 9.55325 31.6893 9.77272 31.6874 9.99402C31.6855 10.2153 31.6395 10.434 31.5521 10.6374C31.4648 10.8407 31.3378 11.0246 31.1786 11.1783L22.357 20L31.1786 28.8217C31.4822 29.136 31.6502 29.557 31.6464 29.994C31.6426 30.431 31.4673 30.849 31.1583 31.158C30.8493 31.4671 30.4313 31.6423 29.9943 31.6461C29.5573 31.6499 29.1363 31.4819 28.822 31.1783L20.0003 22.3567L11.1786 31.1783C10.8643 31.4819 10.4433 31.6499 10.0063 31.6461C9.5693 31.6423 9.15128 31.4671 8.84226 31.158C8.53325 30.849 8.35797 30.431 8.35417 29.994C8.35037 29.557 8.51837 29.136 8.82196 28.8217L17.6436 20L8.82196 11.1783C8.50951 10.8658 8.33398 10.442 8.33398 10C8.33398 9.55807 8.50951 9.13423 8.82196 8.82168V8.82168Z" fill={fill} />
        </svg>
    )
}
