import { ApiInstance } from "."
import { LANGUAGE } from "../utils/constants";

export const getListPosition = () => {
    const lang = localStorage.getItem('language') || LANGUAGE.VN;

    return ApiInstance.getInstance().get("/api/position", {
        params: {
            page: 1,
            limit: 1000,
            lang
        }
    })
}

export const getListNationality = () => {
    return ApiInstance.getInstance().get("/api/nationality", {
        params: {
            page: 1,
            limit: 1000,
        }
    })
}