type ICVerifyProps = {
    isValid: boolean;
}
export const ICVerify: React.FC<ICVerifyProps> = ({
    isValid
}) => {
    return isValid ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 6L9 17L4 12" stroke="#3DD990" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.293 5.29308C5.48053 5.10561 5.73484 5.00029 6 5.00029C6.26516 5.00029 6.51947 5.10561 6.707 5.29308L12 10.5861L17.293 5.29308C17.3852 5.19757 17.4956 5.12139 17.6176 5.06898C17.7396 5.01657 17.8708 4.98898 18.0036 4.98783C18.1364 4.98668 18.2681 5.01198 18.391 5.06226C18.5138 5.11254 18.6255 5.18679 18.7194 5.28069C18.8133 5.37458 18.8875 5.48623 18.9378 5.60913C18.9881 5.73202 19.0134 5.8637 19.0123 5.99648C19.0111 6.12926 18.9835 6.26048 18.9311 6.38249C18.8787 6.50449 18.8025 6.61483 18.707 6.70708L13.414 12.0001L18.707 17.2931C18.8892 17.4817 18.99 17.7343 18.9877 17.9965C18.9854 18.2587 18.8802 18.5095 18.6948 18.6949C18.5094 18.8803 18.2586 18.9855 17.9964 18.9878C17.7342 18.99 17.4816 18.8892 17.293 18.7071L12 13.4141L6.707 18.7071C6.5184 18.8892 6.2658 18.99 6.0036 18.9878C5.7414 18.9855 5.49059 18.8803 5.30518 18.6949C5.11977 18.5095 5.0146 18.2587 5.01233 17.9965C5.01005 17.7343 5.11084 17.4817 5.293 17.2931L10.586 12.0001L5.293 6.70708C5.10553 6.51955 5.00021 6.26525 5.00021 6.00008C5.00021 5.73492 5.10553 5.48061 5.293 5.29308Z" fill="#F55858" />
    </svg>
}