export const ICClapperboard: React.FC = () => {
    return <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.977 17.9059L14.8186 13.3041V10.8597L21.977 6.25798C22.4188 5.97389 23 6.29118 23 6.81653V16.6833C23 17.811 22.4188 18.1899 21.977 17.9059Z" fill="#D16161" />
        <path d="M21.977 17.2419L14.8186 12.6402V13.9681L21.977 18.5698C22.4188 18.8539 23 18.5366 23 18.0113V16.6833C23 17.2087 22.4188 17.5259 21.977 17.2419Z" fill="#AE3244" />
        <path d="M17.5458 6.32797C17.5458 5.59759 16.9482 5 16.2178 5H2.32797C2.17291 5 2.02395 5.02718 1.88531 5.07658L1.66398 19.1638L17.4692 18.9425C17.5187 18.8038 17.5458 18.6549 17.5458 18.4998V6.32797Z" fill="#FF8086" />
        <path d="M1.88531 18.2785V5.07658C1.3709 5.25993 1 5.75269 1 6.32797V18.4999C1 19.2302 1.59759 19.8278 2.32797 19.8278H16.2178C16.7932 19.8278 17.2859 19.4569 17.4692 18.9425L2.5493 18.9425C2.1841 18.9425 1.88531 18.6437 1.88531 18.2785Z" fill="#D16161" />
        <path d="M12.86 11.8554L7.11989 8.16539C7.07519 8.13666 7.02897 8.11435 6.98218 8.09753L6.53953 16.3099C6.53953 16.555 6.81074 16.7031 7.01693 16.5705L13.1621 12.462C13.1785 12.2326 13.0786 11.9959 12.86 11.8554Z" fill="#F5FCFF" />
        <path d="M6.98218 15.8672V8.09753C6.56631 7.948 6.09687 8.25179 6.09687 8.72393V16.1039C6.09687 16.6292 6.67804 16.9465 7.11989 16.6624L12.86 12.9725C13.048 12.8516 13.1479 12.6595 13.1621 12.462L7.45959 16.1279C7.25336 16.2604 6.98218 16.1123 6.98218 15.8672Z" fill="#DCE6EB" />
    </svg>

}