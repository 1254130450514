import React from 'react';
import useIframeContentHeight from '../hooks/useIframeContentHeight';

type IframeProps = {
    content: string,
    id?: string,
    onInferredClick?: (element: HTMLIFrameElement) => void,
    customStyle?: React.CSSProperties | unknown,
    customClass?: string,
}

export const Iframe: React.FC<IframeProps> = ({ content, id, customStyle, customClass, onInferredClick }) => {
    const [iframeRef, iframeHeight] = useIframeContentHeight();

    React.useEffect(() => {
        if (!iframeRef.current) {
            return;
        }
        let doc = iframeRef.current?.contentDocument;
        if (doc && content) {
            doc.open();
            doc.write(content);
            doc.close();
            iframeRef.current.style.width = '100%';
            // iframeRef.current.style.height = '100%';
            // iframeRef.current.style.height = `${iframeRef.current?.contentWindow?.document?.body?.scrollHeight}px`;
            // set font family
            doc.body.style.fontFamily = 'SVN-Poppins, sans-serif';
            doc.body.style.lineHeight = '24px';
            doc.body.style.color = '#110C22';
            doc.body.style.margin = '0';
            // set class to body
            if (customClass) {
                doc.body.className = customClass || '';
                doc.body.style.cursor = 'pointer';
            }

            if (customStyle) {
                Object.keys(customStyle).forEach((key: string) => {
                    // @ts-ignore
                    if (customStyle[key]) {
                        // @ts-ignore
                        doc && doc.body?.style?.setProperty(key.toString(), customStyle[key].toString());
                    }
                });
            }
        }
    }
    , [iframeRef, content, customClass]);

    React.useEffect(() => {
        const onBlur = () => {
            if (
                document.activeElement &&
                document.activeElement.nodeName.toLowerCase() === 'iframe' &&
                iframeRef.current &&
                iframeRef.current === document.activeElement
            ) {
                onInferredClick && onInferredClick(iframeRef.current);
            }
        };

        window.addEventListener('blur', onBlur);
        window.addEventListener('mousedown', onBlur);

        return () => {
            window.removeEventListener('blur', onBlur);
            window.removeEventListener('mousedown', onBlur);
        };
    }, []);

    React.useEffect(() => {
        if (!iframeRef.current) {
            return;
        }
        const doc = iframeRef.current?.contentDocument;
        if (doc) {
            doc.oncopy = (e: ClipboardEvent) => {
                e.preventDefault();
            };
        }

        // check if content iframe is clicked
        // iframeRef.current.contentDocument?.addEventListener('click', (e) => {
        //     if (doc) {
        //         const element = doc.elementFromPoint(e.clientX, e.clientY);
        //         // iframeRef.current && element?.classList?.contains('answer-iframe') && onInferredClick && onInferredClick(iframeRef.current);
        //         if (typeof element?.nodeName === 'string' && element?.nodeName.toLowerCase() === 'p') {
        //             iframeRef.current && onInferredClick && onInferredClick(iframeRef.current);
        //         }
        //     }
        // });
    }, [iframeRef]);

    return (
        <iframe title="name" key={id} 
            allow="clipboard-read; document.write"
            src="about:blank" scrolling="no" frameBorder="0" 
            height={iframeHeight} 
            ref={iframeRef} />
    );
};;
