import React from "react"
import { HRButton } from "../../components/button"
import { ICCheckV2, ICClose } from "../../icons/index"
import { HomeHeader } from "../home/header"
import { FormInstance } from 'antd/lib/form';
import { CompanyProfile } from "./company-profile"
import { getListNationality } from "../../api/utils"
import { useForm } from 'antd/lib/form/Form';
import { CompanyLogo } from "./company-logo"
import { useCompanyProfile } from "./hooks/useCompanyProfile";
import { TCompanyProfile, TCompanyProfileEntity, TCompanyProfileRequest } from "../../api/company-profile";
import i18next from "../../i18n";

type TCompanyPage = {
    companyProfileForm: FormInstance<any>,
    listNationality: any[],
    backgroundColor: string,
    textColor: string,
    setTextColor: React.Dispatch<React.SetStateAction<string>>,
    setBackgroundColor: React.Dispatch<React.SetStateAction<string>>,
    companyProfile: TCompanyProfile,
    setCompanyProfile: React.Dispatch<React.SetStateAction<TCompanyProfile>>,
    logoCompany: string | null,
    setLogoCompany: React.Dispatch<React.SetStateAction<string | null>>,
}
export const CompanyPageContext = React.createContext<TCompanyPage>({} as TCompanyPage);
export const useCompanyPageContext = () => React.useContext(CompanyPageContext);

export const CompanyPage: React.FC = () => {
    const [logoCompany, setLogoCompany] = React.useState<string | null>(null);
    const [backgroundColor, setBackgroundColor] = React.useState<string>('#7357FF');
    const [textColor, setTextColor] = React.useState<string>('#FFFFFF');
    const [listNationality, setListNationality] = React.useState<any[]>([]);
    const [companyProfile, setCompanyProfile] = React.useState<TCompanyProfile>({} as TCompanyProfile);
    const [companyProfileDataAPI, setCompanyProfileDataAPI] = React.useState<TCompanyProfileEntity>({} as TCompanyProfileEntity);
    const [companyProfileForm] = useForm();

    const { hasChangedValues, loadCompanyProfile, onSaveCompanyProfile } = useCompanyProfile(
        companyProfileForm,
        companyProfileDataAPI,
        textColor,
        backgroundColor,
        logoCompany,
    );

    React.useEffect(() => {
        getListNationality().then(({ data }) => {
            setListNationality(data);
            setTimeout(() => {
                const nationalityDefault = data?.find((item: any) => item?.isDefault === true);
                if (nationalityDefault) {
                    companyProfileForm.setFieldValue('country', nationalityDefault?._id);
                }
            }, 1000);
        });
        loadCompanyProfile(setCompanyProfile);
    }, []);

    React.useEffect(() => {
        if (!companyProfileForm) return;
        setCompanyProfileDataAPI(prev => ({
            address: companyProfile?.address,
            country: companyProfile?.country,
            email: companyProfile?.email,
            logo: companyProfile?.logo,
            name: companyProfile?.name,
            taxCode: companyProfile?.taxCode,
            color: {
                backgroundColor: companyProfile?.color?.backgroundColor,
                fontColor: companyProfile?.color?.fontColor,
            }
        }));
        companyProfileForm?.setFieldsValue({
            name: companyProfile?.name,
            address: companyProfile?.address,
            country: companyProfile?.country,
            email: companyProfile?.email,
            logo: companyProfile?.logo,
            taxCode: companyProfile?.taxCode,
        });
        setBackgroundColor(companyProfile?.color?.backgroundColor || '#7357FF');
        setTextColor(companyProfile?.color?.fontColor || '#FFFFFF');
        if (companyProfile?.logo) setLogoCompany(companyProfile?.logo);
    }, [companyProfile, companyProfileForm]);

    const onSave = React.useCallback(() => {
        companyProfileForm?.validateFields().then((values) => {
            const payload: TCompanyProfileRequest = {
                ...values,
                logo: logoCompany,
                color: {
                    backgroundColor: backgroundColor,
                    fontColor: textColor,
                }
            };
            onSaveCompanyProfile(payload, setCompanyProfileDataAPI);
        });
    }, [backgroundColor, companyProfileForm, onSaveCompanyProfile, logoCompany, textColor]);

    const onResetValues = React.useCallback(() => {
        companyProfileForm?.resetFields();
        companyProfileForm?.setFieldsValue({
            name: companyProfileDataAPI?.name,
            address: companyProfileDataAPI?.address,
            country: companyProfileDataAPI?.country,
            email: companyProfileDataAPI?.email,
            logo: companyProfileDataAPI?.logo,
            taxCode: companyProfileDataAPI?.taxCode,
        });
        setBackgroundColor(companyProfileDataAPI?.color?.backgroundColor || '#7357FF');
        setTextColor(companyProfileDataAPI?.color?.fontColor || '#FFFFFF');
        if (companyProfileDataAPI?.logo) setLogoCompany(companyProfileDataAPI?.logo);
    }, [companyProfileDataAPI, companyProfileForm]);

    return (
        <CompanyPageContext.Provider value={{
            companyProfileForm: companyProfileForm,
            listNationality: listNationality,
            backgroundColor,
            textColor,
            setTextColor,
            setBackgroundColor,
            companyProfile,
            setCompanyProfile,
            logoCompany,
            setLogoCompany,
        }}>
            <div className="px-2 lg:px-0 lg:pt-8 space-y-6 pb-16">
                <HomeHeader activedIndex={2} />
                <div className="text-left lg-container mx-auto lg:pt-4 space-y-4 lg:space-y-16">
                    <div className="flex justify-between items-start gap-[32px]">
                        <div className="flex flex-col gap-[8px] max-w-[554px]">
                            <h2 className="mb-0 text-heading-5-bold text-high-em ">
                                {i18next.t('companyProfile.brandEstablishment')}
                            </h2>
                            <p className="mb-0 text-med-em text-body">
                                {i18next.t('companyProfile.brandDescription')}
                            </p>
                        </div>
                        {hasChangedValues && <div className="hidden lg:flex flex-row justify-center items-start gap-[16px]">
                            <span>
                                <HRButton btnType="sub" btnSize="md" onClick={onResetValues}>
                                    <div className="flex gap-[8px] items-center justify-center">
                                        <ICClose fill="#4F4B5C" width={24} height={24} />
                                        <span className="text-body-bold text-[#4F4B5C]">{i18next.t('myProfile.unsave')}</span>
                                    </div>
                                </HRButton>
                            </span>
                            <span>
                                <HRButton btnType="primary" btnSize="md" onClick={onSave}>
                                    <div className="flex gap-[8px] items-center justify-center">
                                        <ICCheckV2 fill="white" width={24} height={24} />
                                        <span className="text-body-bold text-white">{i18next.t('myProfile.saveChange')}</span>
                                    </div>
                                </HRButton>
                            </span>
                        </div>}
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <CompanyProfile />
                        </div>
                        <div className="flex flex-col">
                            <CompanyLogo />
                        </div>
                    </div>
                    {/* Mobile */}
                    {hasChangedValues && <div className="flex lg:hidden flex-row justify-center items-start gap-[16px]">
                        <span>
                            <HRButton btnType="sub" btnSize="md" onClick={onResetValues}>
                                <div className="flex gap-[8px] items-center justify-center">
                                    <ICClose fill="#4F4B5C" width={24} height={24} />
                                    <span className="text-body-bold text-[#4F4B5C]">{i18next.t('companyProfile.discard')}</span>
                                </div>
                            </HRButton>
                        </span>
                        <span>
                            <HRButton btnType="primary" btnSize="md" onClick={onSave}>
                                <div className="flex gap-[8px] items-center justify-center">
                                    <ICCheckV2 fill="white" width={24} height={24} />
                                    <span className="text-body-bold text-white">{i18next.t('companyProfile.saveChange')}</span>
                                </div>
                            </HRButton>
                        </span>
                    </div>}
                </div>
            </div>
        </CompanyPageContext.Provider>
    )
}
