
import React, { forwardRef, useImperativeHandle } from "react";
import { Conversation, getConversations } from "../../api/gpt/generate";
import { ICDelete, ICMoreVertical } from "../../icons";
import { HRButton, IconButton } from "../../components/button";
import { deleteAllConversation, deleteConversation } from "../../api/gpt/delete";
import { groupByDay } from "./utils";
import { ICNewChat } from "../../icons/new-chat";
import { Menu, Modal, Pagination, Popover } from "antd";
import parse from 'html-react-parser'
import i18next from "../../i18n";
import _ from "lodash";
import { Message } from "./type";

const LIMIT = 15;
type ConversationsProps = {
    onLoad: (conversation: Conversation) => void;
    onCreateNewConversation: () => void;
    onCreate: () => void;
    currentConversationId?: string;
}

export const Conversations = forwardRef<any, ConversationsProps>(({
    onLoad,
    onCreate,
    currentConversationId,
    onCreateNewConversation
}, ref) => {
    const [page, setPage] = React.useState(1);
    const [totalDocuments, setTotalDocuments] = React.useState(0);
    const [conversations, setConversations] = React.useState<Conversation[]>([]);
    const [isOpenConfirmDeleteAllModal, setIsOpenConfirmDeleteAllModal] = React.useState(false);
    const isFetching = React.useRef(false);
    React.useEffect(() => {
        isFetching.current = true;
        getConversations(LIMIT, page).then((data) => {
            setConversations(prev => {
                const newConversations = [...prev];
                data.data.forEach((conversation) => {
                    if (newConversations.every((c) => c._id !== conversation._id)) newConversations.push(conversation);
                });
                return newConversations;
            });
            setTotalDocuments(data.metaData.totalDocuments);
        }).finally(() => {
            isFetching.current = false
        });
    }, [page]);

    const onDelete = async (converstation: Conversation, index: number) => {
        await deleteConversation(converstation._id);
        if (currentConversationId === converstation._id) {
            onCreateNewConversation();
        }
        const conversationLength = conversations.length;
        setConversations(prev => prev.filter((c) => c._id !== converstation._id));

        // Get the next conversation and push it to list conversations
        getConversations(1, conversationLength - 1).then((data) => {
            setConversations(prev => {
                const newConversations = [...prev];
                data.data.forEach((conversation) => {
                    if (newConversations.every((c) => c._id !== conversation._id)) newConversations.push(conversation);
                });
                return newConversations;
            });
            setTotalDocuments(data.metaData.totalDocuments);
        });
    }
    useImperativeHandle(ref, () => {
        return {
            addConversation: (conversation: Conversation) => {
                setConversations(prev => [conversation, ...prev]);
            },
            updateConversationMessage: (conversationId: string, newMessage: Conversation['messages']) => {
                setConversations(prev => {
                    const conversationIndex = _.findIndex(prev, { _id: conversationId });
                    if (conversationIndex === -1) return prev;
                    const newConversations = [...prev];
                    newConversations[conversationIndex].messages = [...newConversations[conversationIndex].messages, ...newMessage];
                    return newConversations;
                })
            }
        };
    }, []);
    const onDeleteAll = async () => {
        await deleteAllConversation();
        setConversations([]);
        closeConfirmDeleteAllModal();
        onCreateNewConversation();
    }

    const openConfirmDeleteAllModal = () => {
        setIsOpenConfirmDeleteAllModal(true);
    }

    const closeConfirmDeleteAllModal = () => {
        setIsOpenConfirmDeleteAllModal(false);
    }
    const scrollBarRef = React.useRef<HTMLDivElement>(null);
    const scrollToLoadMore = () => {
        if (!scrollBarRef.current || isFetching.current) return;
        const scrollBar = scrollBarRef.current;
        if (totalDocuments <= conversations.length) return;
        const THRESHOLD = 30;
        if (scrollBar.scrollTop + scrollBar.clientHeight + THRESHOLD >= scrollBar.scrollHeight) {
            setPage(prev => prev + 1);
        }
    }
    const conversationGroups = React.useMemo(() => groupByDay(conversations), [conversations]);
    return <div
        ref={scrollBarRef}
        className="overflow-y-auto px-4 py-2 flex flex-col gap-8 h-full"
        onScroll={scrollToLoadMore}
        style={{
            maxHeight: 'calc(100svh - 70px)'
        }}
    >
        <div className="min-h-fit">
            <HRButton btnType="sub" btnSize="sm" onClick={onCreate} >
                <div className="flex items-center space-x-2 justify-center">
                    <ICNewChat />
                    <span className="text-[14px] font-semibold">{i18next.t('assisstant.newChatButton.label')}</span>
                </div>
            </HRButton>
        </div>
        <div className="flex flex-col gap-3">
            {
                conversationGroups.map(group => (
                    <React.Fragment key={group.title}>
                        <div className="px-3">
                            <span className="text-[12px] font-bold">{group.title}</span>
                        </div>

                        <div className="flex flex-col">
                            {
                                group.items.map((item, index) => <ConversationItem
                                    key={item._id}
                                    conversation={item}
                                    onClick={() => onLoad(item)}
                                    onDelete={() => onDelete(item, index)}
                                    isActive={currentConversationId === item._id}
                                />)
                            }
                        </div>
                    </React.Fragment>
                ))
            }
        </div>

        {
            // Delete all btn
            Boolean(conversations.length) && <ConversationItem
                conversation={{
                    _id: '',
                    messages: [],
                    title: `${i18next.t('assisstant.deleteAllButton.label')}`,
                    createdAt: 0
                }}
                onClick={openConfirmDeleteAllModal}
                onDelete={() => { }}
                prefix={<ICDelete width={20} height={20} />}
                hasMore={false}
            />
        }

        <Modal
            open={isOpenConfirmDeleteAllModal}
            centered={true}
            title={i18next.t('assisstant.deleteAllModal.title')}
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[10px]">
                    <HRButton
                        btnSize="sm"
                        btnType="sub"
                        onClick={closeConfirmDeleteAllModal}
                    >
                        {i18next.t('assisstant.deleteAllModal.buttons.no')}
                    </HRButton>
                    <HRButton btnSize="sm" onClick={onDeleteAll}>
                        {i18next.t('assisstant.deleteAllModal.buttons.yes')}
                    </HRButton>
                </div>
            }
        >
            <span>{i18next.t('assisstant.deleteAllModal.description')}</span>
        </Modal>
    </div>
})
type ConversationItemProps = {
    conversation: Conversation;
    onClick: () => void;
    onDelete: () => void;
    isActive?: boolean;
    prefix?: React.ReactNode;
    hasMore?: boolean;
}
const ConversationItem = React.memo<ConversationItemProps>(({ conversation, onClick, onDelete, prefix, isActive = false, hasMore = true }) => {
    const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const onClose = React.useCallback(() => {
        setOpen(false);
    }, [])
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };
    const onConfirmDelete = async () => {
        await onDelete();
        closeConfirmDeleteModal();
    }
    const title = React.useMemo(() => {
        if (conversation.title) return conversation.title;
        let title = 'Untitled conversation';
        for (let i = 0; i < conversation.messages.length; i++) {
            const message = conversation.messages[i];
            if (message.role !== 'assistant') continue;
            try {
                const data = JSON.parse(message.content);
                if (data.title) {
                    title = data.title;
                    break;
                }
            } catch (e) {
                continue;
            }
        }
        return title;
    }, [conversation]);

    const textClass = `truncate text-[14px] select-none leading-5 text-[#364152] w-full ${isActive ? 'font-bold text-blue-600 bg-gray-100' : ''}`;

    const wrapClass = `group py-2 px-3 flex gap-2 rounded-xl cursor-pointer bg-white hover:bg-[#F6F8FB] items-center justify-between ${isActive ? 'bg-[#F6F8FB]' : ''}`;


    const openConfirmDeleteModal = () => {
        setIsOpenDeleteModal(true);
    }

    const closeConfirmDeleteModal = () => {
        setIsOpenDeleteModal(false);
    }

    return (
        <>
            <div
                onClick={onClick}
                className={wrapClass}
            >
                {prefix && <div className="min-w-fit">
                    {prefix}
                </div>}
                <p className={textClass}>{isActive} {title}</p>
                {hasMore && <Popover
                    open={open}
                    placement="bottomRight"
                    content={<div
                        className="px-3 py-2"
                    >
                        <div
                            onClick={e => {
                                openConfirmDeleteModal();
                                e.stopPropagation();
                            }}
                            className="flex space-x-2 items-center justify-between w-[100px] hover:bg-[#F6F8FB] cursor-pointer px-2 py-1"
                        >
                            <ICDelete width={16} height={16} />
                            <span>{i18next.t('assisstant.deleteButton.label')}</span>
                        </div>
                    </div>}
                    showArrow={false}
                    className="popover-more"
                    prefixCls="hr-popover ant-popover"
                    trigger="click"
                    onOpenChange={handleOpenChange}
                >
                    <IconButton
                        icon={<ICMoreVertical width={16} height={16} />}
                        btnType="tertiary"
                        btnSize="xs"

                        onClick={(event) => event.stopPropagation()} />
                </Popover>}
            </div>
            <Modal
                open={isOpenDeleteModal}
                centered={true}
                title={`${i18next.t('assisstant.deleteModal.title')}`}
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[10px]">
                        <HRButton
                            btnSize="sm"
                            btnType="sub"
                            onClick={closeConfirmDeleteModal}
                        >
                            {i18next.t('assisstant.deleteModal.buttons.no')}
                        </HRButton>
                        <HRButton btnSize="sm" onClick={onConfirmDelete}>
                            {i18next.t('assisstant.deleteModal.buttons.yes')}
                        </HRButton>
                    </div>
                }
            >
                <p>{parse(i18next.t('assisstant.deleteModal.description', { title: `<span className="font-bold">${title}</span>` }))}.</p>
            </Modal>
        </>
    )
});