import { IconProps } from "./type"

export const ICCredit: React.FC<IconProps> = ({
    fill = "#8D8A95",
    width = 24,
    height = 24
}) => {
    return (

        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" d="M22 6C22 5.46957 21.7893 4.96086 21.4142 4.58579C21.0391 4.21071 20.5304 4 20 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V6ZM20 8H4V6H20V8ZM4 11H20V18H4V11Z" fill={fill} />
        </svg>

    )
}
