interface IResizeImageOptions {
    maxWidth: number;
    maxHeight: number;
}
export const dataURItoBlob = (dataURI: string) => {
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
        atob(dataURI.split(',')[1]) :
        unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
};
export const resize = (image: any, settings: IResizeImageOptions) => {
    const canvas = document.createElement('canvas');
    let width = Math.min(image.width, settings.maxWidth);
    let height = Math.min(image.height, settings.maxHeight);
    canvas.width = width;
    canvas.height = height;
    // @ts-ignore
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    let dataUrl = canvas.toDataURL('image/jpeg');
    return dataURItoBlob(dataUrl);
};

export const resizeImage = (file: File, settings: IResizeImageOptions) => {
    const reader = new FileReader();
    const image = new Image();
    return new Promise((resolve, reject) => {
        if (!file.type.match(/image.*/)) {
            reject(new Error("Not an image"));
            reject(null);
        }
        reader.onload = (readerEvent: any) => {
            image.onload = () => resolve(resize(image, settings));
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
};

export const reduceImageSize = (file: File, settings: IResizeImageOptions) => {
    const reader = new FileReader();
    const image = new Image();
    if (!file.type.match(/image.*/)) {
        return file;
    }
    reader.onload = (readerEvent: any) => {
        image.onload = () => resize(image, settings);
        image.src = readerEvent.target.result;
    }
    reader.readAsDataURL(file);
    return file;

};
