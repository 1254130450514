import { IconProps } from "./type"

export const ICSuccess: React.FC<IconProps> = ({
    fill = "#0CD68A",
}) => {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M49.9998 95.8332C75.3129 95.8332 95.8332 75.3129 95.8332 49.9998C95.8332 40.0621 92.6704 30.8631 87.2966 23.3545L42.7201 67.931C42.6966 67.9545 42.6584 67.9545 42.6349 67.931L26.2202 51.5163C25.4388 50.7349 24.9998 49.6751 24.9998 48.57C24.9998 46.2688 26.8653 44.4033 29.1665 44.4033C30.2716 44.4033 31.3314 44.8423 32.1128 45.6237L42.6775 56.1885L81.8371 17.0288C73.592 9.06549 62.368 4.1665 49.9998 4.1665C24.6868 4.1665 4.1665 24.6868 4.1665 49.9998C4.1665 75.3129 24.6868 95.8332 49.9998 95.8332Z" fill={fill}/>
        </svg>        
    )
}
