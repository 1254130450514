import { FormInstance, useForm, useWatch } from "antd/lib/form/Form";
import React from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getTestLibraryById, getTestRatingLevel } from "../../api/library";
import { HRButton, IconButton } from "../../components/button";
import { ICEye, ICTick } from "../../icons";
import { ICBack } from "../../icons/back";
import { firstValueCommonData, isSuponsor } from "../../redux/selectors/commonData";
import { setLoading } from "../../redux/slices/appInfo";
import { ITestLibrary, TRatingLevel, TScoreLevels } from "../library/models";
import { CreateTestTabs, MobileCreateTestTabs } from "./tab";
import { AddQuestion } from "./tab/question/add";
import { EQuestionType, ESkillAreaType, formatQuestion, indexQuestion, TQuestion, TSkillArea } from "./tab/question/type";
import { useTranslation } from "react-i18next";
import { TCreateTestForm } from "./tab/model";
import { useCreateTestPage } from "./tab/hooks/useCreateTestPage";
import { usePrompt } from "../../hooks/usePromt";
import { useNotification } from '../../hooks/useNotification';
import classNames from "classnames"
import { DEFAULT_QUESTIONS_TIMING } from "./tab/question/contants";
import _ from "lodash";
import { useConfigQuestion } from "./tab/hooks/useConfigQuestion";
import { Modal, Tag } from "antd";
import { ETestStatus, StatusTestMappingColor, testStatusMapping } from "../my_test/type";
import i18next from "../../i18n";

type CreateTestHeaderProps = {
    onSave: () => void,
    id?: string,
}
const CreateTestHeader: React.FC<CreateTestHeaderProps> = ({
    onSave,
    id,
}) => {
    const { form, testDetails, mode, pageIsDirty } = useCreateTestPageContext()
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const titleFormValue = useWatch('title', form);
    const [showConfirm, setShowConfirm] = React.useState(false);

    const getMyTest = useCallback(() => {
        const search = location.state?.search ?? '';
        navigate({
            pathname: '/my-test',
            search,
        });
    }, [navigate, location]);

    const goPreviewTest = useCallback(() => {
        setShowConfirm(false);
        window.open(`/testing/${id}/preview?index=0`, '_blank');
    }, [id]);

    const onPreviewTesting = useCallback(() => {
        if (!pageIsDirty) {
            goPreviewTest();
        } else {
            setShowConfirm(true);
        }
    }, [pageIsDirty, goPreviewTest]);

    const renderHeader = React.useMemo(() => {
        if (mode === "create") {
            return titleFormValue || t("newTest");
        }
        return form.getFieldValue("title");
    }, [mode, form, t, titleFormValue]);

    const activePreviewQuestion = React.useMemo(() => {
        return (testDetails?.previewSection && testDetails?.previewSection?.length > 0) ||
            testDetails?.skillSections?.some((section) => section?.questions?.length > 0);
    }, [testDetails]);

    return <>
        <div className="lg:flex items-center justify-between gap-[16px]">
            <div className="flex space-x-2 items-center">
                <IconButton btnType="sub" btnSize="sm" icon={<ICBack />} onClick={getMyTest} />
                <span className="text-heading-5-semi-bold text-high-em line-clamp-2">{renderHeader}</span>
                {/* <span className="text-xs text-n-800 px-2 py-1 bg-n-150 rounded-[50px]">Nháp</span> */}
                {testDetails?.status && <Tag style={{
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '16px',
                    padding: '4px 8px',
                    color: '#FFFFFF',
                    backgroundColor: StatusTestMappingColor[testDetails?.status as ETestStatus],
                    borderColor: StatusTestMappingColor[testDetails?.status as ETestStatus]
                }}>
                    {testStatusMapping[testDetails?.status as ETestStatus]}
                </Tag>}
            </div>
            <div className="pt-4 lg:pt-0 flex space-x-3">
                <div className="hidden lg:block">
                    {activePreviewQuestion && <HRButton
                        btnType="sub"
                        btnSize="md"
                        onClick={onPreviewTesting}
                    >
                        <div className="grid grid-cols-[auto_auto] space-x-2 justify-center">
                            <ICEye />
                            <span>{i18next.t('createTest.preview')}</span>
                        </div>
                    </HRButton>}
                </div>
                <HRButton btnSize="md" onClick={onSave}>
                    <div className="flex space-x-2 justify-center">
                        <ICTick />
                        <span>{i18next.t('createTest.save')}</span>
                    </div>
                </HRButton>
            </div>
        </div>
        <Modal
            open={showConfirm}
            centered={true}
            closable={false}
            title={
                <div className="font-bold text-[18px] leading-[22px]">{i18next.t('createTest.previewTest')}</div>
            }
            footer={
                <div className="flex justify-end space-x-6">
                    <span>
                        <HRButton
                            btnSize="sm"
                            btnType="outline"
                            onClick={goPreviewTest}>
                            {i18next.t('createTest.continuePreview')}
                        </HRButton>
                    </span>
                    <span>
                        <HRButton
                            btnSize="sm"
                            btnType="primary"
                            onClick={() => setShowConfirm(false)}>
                            {i18next.t('createTest.gotIt')}
                        </HRButton>
                    </span>
                </div>
            }
        >
            <p className="font-medium text-[16px] leading-[24px]">{i18next.t('createTest.changeNotSaved')}</p>
        </Modal>
    </>
}
type TFormFilter = {
    title?: string
    isActive?: boolean
    questionType?: EQuestionType;
}
type TCreateTestPage = {
    showAddQuestion: boolean
    setShowAddQuestion: (
        show: boolean,
        indexSectionSelected?: number,
        indexQuestionSelected?: number
    ) => void;

    id?: string;

    // Question management
    sampleSection: TSkillArea
    setSampleSection: (section: TSkillArea) => void
    skillAreas: TSkillArea[]
    setSkillAreas: (sections: TSkillArea[]) => void
    onAddSkillArea: () => void
    onDeletedSkillArea: (index: number) => void
    selectedSection: TSkillArea | undefined
    selectedQuestion: TQuestion | undefined
    onCreatedQuestion: (question: TQuestion) => void
    // Tab management
    selectedTabIndex: number
    setSelectedTabIndex: (index: number) => void;

    // General management
    tags: string[]
    setTags: (tags: string[]) => void
    form: FormInstance<any>,

    // test details
    testDetails: ITestLibrary,
    initValuesForm: TCreateTestForm | undefined,

    // filter
    formFilter: TFormFilter
    setFormFilter: React.Dispatch<React.SetStateAction<TFormFilter>>,

    // Config question
    questionUsageForm: FormInstance<any>;
    questionUsage: number | number[] | undefined;
    setQuestionUsage: (value: number | number[] | undefined) => void;

    questionDuration: number | undefined;
    setQuestionDuration: (value: number | undefined) => void;

    pageIsDirty: boolean;
    mode: 'create' | 'edit';

    skillAreaTypeSelected: ESkillAreaType | undefined;
    setSkillAreaTypeSelected: React.Dispatch<React.SetStateAction<ESkillAreaType | undefined>>;

    ratingLevel: TRatingLevel[] | undefined;
    scoreLevelsQuestion: TScoreLevels[] | undefined;
    setScoreLevelsQuestion: React.Dispatch<React.SetStateAction<TScoreLevels[] | undefined>>;

}
const CreateTestPageContext = React.createContext<TCreateTestPage>({} as TCreateTestPage);
export const useCreateTestPageContext = () => React.useContext(CreateTestPageContext);

type CreateTestPageProps = {
    mode?: 'create' | 'edit'
}
const DEFAULT_SKILL_AREAS_SUPONSOR: TSkillArea[] = [
    {
        description: null,
        name: `${i18next.t('createTest.skillArea')} 1`,
        questions: [],
        type: ESkillAreaType.ATTEAM
    }
];

const DEFAULT_SKILL_AREAS: TSkillArea[] = [
    {
        description: null,
        name: `${i18next.t('createTest.skillArea')} 1` ,
        questions: [],
    }
];
export const CreateTestPage: React.FC<CreateTestPageProps> = ({
    mode = 'create'
}) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const [tags, setTags] = React.useState<string[]>([]);
    const [form] = useForm();
    const [questionUsageForm] = useForm();
    const [initValuesForm, setInitValuesForm] = React.useState<TCreateTestForm | undefined>();
    const { id = '' } = useParams();
    const { showError } = useNotification();
    const [isSaving, setIsSaving] = React.useState(false);
    const hasSuponsor = isSuponsor();
    const defaultSkillAreas = hasSuponsor ? DEFAULT_SKILL_AREAS_SUPONSOR : DEFAULT_SKILL_AREAS;

    const [selectedTabIndex, setSelectedTabIndex] = React.useState(1);
    const [showAddQuestion, setShowAddQuestion] = React.useState(false);
    const [selectedSectionIdx, setSelectedSectionIdx] = React.useState<number | undefined>();
    const [selectedQuestion, setSelectedQuestion] = React.useState<TQuestion | undefined>(undefined);
    const [sampleSection, setSampleSection] = React.useState<TSkillArea>({
        description: "",
        name: i18next.t('createTest.sampleQuestion'),
        questions: []
    });
    const [skillAreas, setSkillAreas] = React.useState<TSkillArea[]>(defaultSkillAreas)
    const [testDetails, setTestDetails] = React.useState<ITestLibrary>({} as ITestLibrary);
    const [ratingLevel, setRatingLevel] = React.useState<TRatingLevel[] | undefined>([]);
    const [scoreLevelsQuestion, setScoreLevelsQuestion] = React.useState<TScoreLevels[] | undefined>([]);

    // Config questions
    const [questionUsage, setQuestionUsage] = React.useState<number | number[] | undefined>(undefined);
    const [questionDuration, setQuestionDuration] = React.useState<number | undefined>(undefined);

    const [skillAreaTypeSelected, setSkillAreaTypeSelected] = React.useState<ESkillAreaType | undefined>(undefined);

    // check form
    const {
        hasChangedValues,
        insertTestLibrary,
        updateTest
    } = useCreateTestPage(form, initValuesForm, setTestDetails, setSelectedTabIndex);
    const {
        checkValidQuestionUsageForm,
        checkZeroValue
    } = useConfigQuestion(questionUsageForm);

    const onAddQuestion = (
        show: boolean,
        indexSectionSelected?: number,
        indexQuestionSelected?: number
    ) => {
        if (!show) {
            setSelectedSectionIdx(undefined);
            setSelectedQuestion(undefined);
        }
        setShowAddQuestion(show);
        if (show && indexSectionSelected !== undefined) {
            // Hard code that: 0 is sample section
            if (indexSectionSelected === 0) {
                setSelectedSectionIdx(indexSectionSelected);
                if (indexQuestionSelected !== undefined) {
                    setSelectedQuestion(sampleSection.questions[indexQuestionSelected]);
                }
            }
            else {
                setSelectedSectionIdx(indexSectionSelected);
                if (indexQuestionSelected !== undefined) {
                    setSelectedQuestion(skillAreas[indexSectionSelected - 1].questions[indexQuestionSelected]);
                }
            }
        }
    }
    const onAddSkillArea = () => {
        const names = skillAreas.map(s => s.name);
        let length = skillAreas.length + 1;
        let initialName = `${i18next.t('createTest.skillArea')} ${length}`;
        while (
            names.includes(initialName)
        ) {
            length++;
            initialName = `${i18next.t('createTest.skillArea')} ${length}`;
        }
        if (hasSuponsor) {
            setSkillAreas([...skillAreas, {
                description: null,
                name: initialName,
                questions: [],
                type: ESkillAreaType.ATTEAM
            }])
        } else {
            setSkillAreas([...skillAreas, {
                description: null,
                name: initialName,
                questions: [],
                type: ESkillAreaType.STANDARD
            }])
        }

    }
    const onCreatedQuestion = (question: TQuestion) => {
        if (selectedSectionIdx !== undefined) {
            if (selectedQuestion) {
                if (selectedSectionIdx === 0) {
                    setSampleSection(prev => {
                        return {
                            ...prev,
                            questions: prev.questions.map(q => q === selectedQuestion ? question : q)
                        }
                    })
                } else {
                    setSkillAreas(prev => {
                        const newSkillAreas = [...prev];
                        const selectedSection = newSkillAreas[selectedSectionIdx - 1];
                        newSkillAreas[selectedSectionIdx - 1] = {
                            ...selectedSection,
                            questions: selectedSection.questions.map(q => q === selectedQuestion ? question : q)
                        };
                        return newSkillAreas;
                    });
                }
            } else {
                if (selectedSectionIdx === 0) {
                    setSampleSection(prev => {
                        return {
                            ...prev,
                            questions: [...prev.questions, question]
                        }
                    })
                }
                else setSkillAreas(prev => {
                    const newSkillAreas = [...prev];
                    const selectedSection = newSkillAreas[selectedSectionIdx - 1];
                    newSkillAreas[selectedSectionIdx - 1] = {
                        ...selectedSection,
                        questions: [...selectedSection.questions, question]
                    };
                    return newSkillAreas;
                });
            }
        }
    }
    const onDeletedSkillArea = (index: number) => {
        skillAreas.splice(index - 1, 1);
        // Carefully with key is index in section
        setSkillAreas([...skillAreas]);
    }

    // init Form values
    const languageDefault = firstValueCommonData('language', i18n.language) || '';
    React.useEffect(() => {
        if (languageDefault && form) {
            form.setFieldsValue({
                language: languageDefault
            });
            const valuesForm = form.getFieldsValue(true);
            setInitValuesForm(valuesForm);
        }
    }, [languageDefault, form]);

    const onValidateSkillAreas = useCallback(() => {
        const isValid = skillAreas.every((skillArea) => {
            return skillArea.questions.some(q => q.isActive === true);
        });
        if (!isValid) {
            showError('Error', i18next.t('createTest.atLeastOneQuestion'));
            return false;
        }
        const inValidSkillAreas = skillAreas.map((d, index) => {
            const totalActive = d.questions?.filter(q => q.isActive === true).length;
            const isValid = Array.isArray(questionUsage) ? (questionUsage[index] > totalActive ? false : true) : true;
            return {
                ...d,
                isValid
            }
        }).some(d => d.isValid === false);
        if (inValidSkillAreas) {
            showError('Error', i18next.t('createTest.editAtLeastOneQuestion'));
            return false;
        }

        return true;
    }, [skillAreas, showError, questionUsage]);

    const onSaveForm = useCallback(async () => {
        try {
            await form.validateFields();
            const valuesForm = form.getFieldsValue(true);
            const payload = { ...valuesForm, skillsRequired: tags };

            setInitValuesForm(valuesForm);
            if (mode === "edit") {
                const isValid = onValidateSkillAreas();
                if (!isValid) return;

                let newQuestionUsage = questionUsage;
                if (Array.isArray(questionUsage)) {
                    questionUsageForm?.submit();
                    const newValues = questionUsageForm.getFieldsValue();
                    newQuestionUsage = Object.values(newValues);
                    setQuestionUsage(newQuestionUsage);
                    const isValidUsageForm = await checkValidQuestionUsageForm();
                    if (!isValidUsageForm) {
                        return;
                    }
                }
                if (Array.isArray(newQuestionUsage) && checkZeroValue(newQuestionUsage)) {
                    return;
                }
                const newPayload = {
                    ...payload,
                    previewSection: sampleSection.questions.map(formatQuestion),
                    skillSections: skillAreas.map(skillArea => ({
                        ...skillArea,
                        questions: skillArea.questions.map(formatQuestion)
                    })),
                    configuration: {
                        questionUsage: newQuestionUsage || null,
                        questionDuration: questionDuration || null,
                    },
                };
                setIsSaving(true);
                updateTest(id, newPayload);
                setInitialQuestion({
                    previewQuestion: sampleSection.questions,
                    skillAreas: skillAreas,
                    questionUsage: questionUsage,
                    questionDuration: questionDuration,
                });

            } else {
                insertTestLibrary(payload);
            }
        } catch (errors) {
            setIsSaving(false);
            console.warn("errors", errors);
        }
    }, [
        questionUsageForm,
        form,
        tags,
        mode,
        id,
        skillAreas,
        sampleSection,
        questionUsage,
        questionDuration,
        checkValidQuestionUsageForm,
        checkZeroValue,
        updateTest,
        insertTestLibrary,
        onValidateSkillAreas,
    ]);

    const [
        initialQuestion,
        setInitialQuestion,
    ] = React.useState<any>({
        previewQuestion: [],
        skillAreas: defaultSkillAreas,
        questionUsage: undefined,
        questionDuration: undefined,
    })
    React.useEffect(() => {
        if (mode !== "edit" || !id) return;
        try {
            dispatch(setLoading(true));
            getTestLibraryById(id).then((res) => {
                Object.keys(form.getFieldsValue()).forEach(key => {
                    form.setFieldsValue({
                        [key]: res.data[key]
                    })
                });
                setTestDetails(res.data);
                const newSampleQuestion = {
                    name: i18next.t('createTest.sampleQuestion'),
                    description: "",
                    questions: (res.data.previewSection || []).map(indexQuestion)
                };
                setSampleSection(newSampleQuestion);
                let newSkillAreas = [];
                if (res.data.skillSections.length > 0) {
                    newSkillAreas = res.data.skillSections.map((s: any) => ({
                        ...s,
                        questions: s.questions.map(indexQuestion)
                    }))
                    setSkillAreas(newSkillAreas);
                }
                setTags(res.data.skillsRequired);
                setInitValuesForm(form.getFieldsValue(true));

                loadScoreLevelsQuestion();

                setQuestionUsage(res.data?.configuration?.questionUsage || undefined);
                setQuestionDuration(res.data?.configuration?.questionDuration || DEFAULT_QUESTIONS_TIMING);
                setInitialQuestion({
                    previewQuestion: newSampleQuestion.questions,
                    skillAreas: newSkillAreas,
                    questionUsage: res.data?.configuration?.questionUsage || undefined,
                    questionDuration: res.data?.configuration?.questionDuration || DEFAULT_QUESTIONS_TIMING,
                })
            })
        } finally {
            dispatch(setLoading(false));
        }

    }, [mode, dispatch, form, id, ratingLevel]);

    const loadScoreLevelsQuestion = React.useCallback(() => {
        const ratingLevelSelected = ratingLevel?.find((item) => item?.id === form.getFieldValue('ratingLevel'));
        if (ratingLevelSelected) {
            const newScoreLevels = ratingLevelSelected?.scoreLevels.map((s: any) => ({
                ...s,
                value: s.score,
                label: `${s.symbol} - ${s.name}`
            }));
            setScoreLevelsQuestion(newScoreLevels);
        }
    }, [ratingLevel, form]);

    const loadRatingLevel = React.useCallback(async () => {
        try {
            dispatch(setLoading(true));
            const res = await getTestRatingLevel();
            if (res.data?.length > 0) {
                setRatingLevel(res.data.map((r: any) => ({
                    ...r,
                    value: r._id,
                    label: r.label,
                    scoreLevels: r.scoreLevels.map((s: any) => ({
                        ...s,
                        value: s.score,
                        label: `${s.symbol} - ${s.name}`
                    })).sort((a: any, b: any) => b?.score - a?.score)
                })).sort((a: any, b: any) => a?.label - b?.label));
                loadScoreLevelsQuestion();
            }
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch]);

    React.useEffect(() => {
        loadRatingLevel();
    }, []);

    // Filter
    const [formFilter, setFormFilter] = React.useState<TFormFilter>({});
    const pageIsDirty = React.useMemo(() => {
        const firstQuestion = {
            ...initialQuestion,
            previewQuestion: initialQuestion.previewQuestion.map(({ uuid, ...q }: any) => ({ ...q })),
            skillAreas: initialQuestion.skillAreas.map(({ questions, ...s }: any) => ({
                ...s,
                questions: questions.map(({ uuid, ...q }: any) => ({ ...q }))
            })),
        };
        const secondQuestion = {
            previewQuestion: sampleSection.questions.map(({ uuid, ...q }: any) => ({
                ...q,
            })),
            skillAreas: skillAreas.map(({ questions, ...s }: any) => ({
                ...s,
                questions: questions.map(({ uuid, ...q }: any) => ({
                    ...q,
                }))
            })),
            questionUsage,
            questionDuration,
        };

        return mode === 'edit' && !isSaving ? hasChangedValues || !_.isEqual(
            firstQuestion,
            secondQuestion
        ) : false
    }, [mode, hasChangedValues, initialQuestion, sampleSection.questions, skillAreas, questionUsage, questionDuration, isSaving]);

    usePrompt('Changes you made may not be saved.?', pageIsDirty);

    React.useEffect(() => {
        const handler = (e: any) => {
            e.preventDefault();
            if (!pageIsDirty) {
                return e.returnValue = undefined;
            }
            return e.returnValue = true;
        };

        if (!pageIsDirty) {
            window.removeEventListener("beforeunload", handler);
            return;
        }
        window.addEventListener("beforeunload", handler);
        return () => {
            window.removeEventListener("beforeunload", handler);
        }
    }, [pageIsDirty]);

    const selectedSection = React.useMemo(() => {
        if (selectedSectionIdx === undefined) {
            return undefined;
        }
        if (selectedSectionIdx === 0) {
            return sampleSection;
        }
        return skillAreas[selectedSectionIdx - 1];
    }, [selectedSectionIdx, skillAreas, sampleSection]);
    return <CreateTestPageContext.Provider value={{
        showAddQuestion,
        setShowAddQuestion: onAddQuestion,
        sampleSection,
        setSampleSection,
        skillAreas,
        setSkillAreas,
        onAddSkillArea,
        selectedSection,
        onCreatedQuestion,
        selectedQuestion,

        id,
        pageIsDirty,
        mode,

        selectedTabIndex,
        setSelectedTabIndex,
        onDeletedSkillArea,

        tags,
        setTags,
        form,
        testDetails,
        initValuesForm,
        formFilter,
        setFormFilter,

        questionUsageForm,
        questionUsage,
        setQuestionUsage,
        questionDuration,
        setQuestionDuration,

        skillAreaTypeSelected,
        setSkillAreaTypeSelected,

        ratingLevel,
        scoreLevelsQuestion,
        setScoreLevelsQuestion,
    }}>
        <div className="text-center">
            <div className="pt-8 space-y-6 min-h-screen">
                {
                    showAddQuestion && <AddQuestion />
                }
                <div className={classNames("pb-16 px-2 lg:px-0 lg:space-y-8 text-left lg-container mx-auto", showAddQuestion ? "hidden" : "")}>
                    <CreateTestHeader
                        id={id}
                        onSave={onSaveForm}
                    />
                    <div className="lg:mt-8 hidden lg:block">
                        <CreateTestTabs />
                    </div>
                    <div className="block:hidden lg:hidden">
                        <MobileCreateTestTabs
                            onSave={onSaveForm}
                        />
                    </div>
                </div>
            </div>
        </div>
    </CreateTestPageContext.Provider>
}
