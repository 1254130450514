import React from "react"
import styled from "styled-components"
import { Button, Form, Modal } from 'antd';
import { HRForm } from '../../../../../../components/form/form';
import { HRSelect } from "../../../../../../components/form/select";
import { useForm } from "antd/lib/form/Form";
import { HRButton } from '../../../../../../components/button';
import { useTranslation } from "react-i18next";
import { firstValueCommonData } from "../../../../../../redux/selectors/commonData";
import { ISearchCriteria } from "../../../../../library/models";
import i18next from "../../../../../../i18n";

const StatusGroup = styled.div`
    display: flex;
    .ant-btn {
        height: 41px;
        border: 0;
        width: 119px;
        padding: 0;
        background-color: #EEF0F6;
        color: #46485C;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
    }
    .ant-btn-group {
        width: 202px;
        border-radius: 50px;
        box-shadow: 0px 4px 16px rgba(98, 112, 140, 0.24);
        background-color: #EEF0F6;
    }
    .ant-btn-primary {
        background-color: #4D87FA;
        z-index: 2;
        color: white;
        border: 2.4px solid #FFFFFF;
        border-right-color: white !important;
        border-left-color: white !important;
    }
`;

const PLAN_TAG = [
    { label: i18next.t('createAssessment.free'), value: 'free' },
    { label: i18next.t('createAssessment.pay'), value: 'pay' }
];

const STATUS_TAG = [
    { label: i18next.t('createAssessment.new'), value: 'new' },
    { label: i18next.t('createAssessment.popular'), value: 'popular' },
];

type TFilterProps = {
    open: boolean;
    filterParams: ISearchCriteria;
    onReloadData: () => void;
    setOpen: (value: boolean) => void;
    onFilter: (value: any) => void;
}

export const FilterModal: React.FC<TFilterProps> = ({
    open,
    filterParams,
    onReloadData,
    setOpen,
    onFilter
}) => {
    const [formModal] = useForm();
    const { i18n } = useTranslation();
    const languageValueDefault = firstValueCommonData('language', i18n.language) || '';
    const languageNameDefault = firstValueCommonData('language', i18n.language, 'label') || '';
    const [status, setStatus] = React.useState<null | true>(null);
    const onToggle = React.useCallback((value: any) => {
        setStatus(value);
    }, [setStatus]);
    const [keepFilter, setKeepFilter] = React.useState<ISearchCriteria>();

    React.useEffect(() => {
        if (filterParams) {
            setKeepFilter(filterParams);
        }
    }, [filterParams]);

    React.useEffect(() => {
        if (keepFilter) {
            formModal.setFieldsValue(keepFilter);
        }
    }, [keepFilter, formModal]);

    const ratingList = [{
        label: i18next.t('createAssessment.all'),
        value: -999,
    },
    {
        label: i18next.t('createAssessment.starNumber', { t: 1 }),
        value: 1,
    },
    {
        label: i18next.t('createAssessment.starNumber', { t: 2 }),
        value: 2,
    },
    {
        label: i18next.t('createAssessment.starNumber', { t: 3 }),
        value: 3,
    },
    {
        label: i18next.t('createAssessment.starNumber', { t: 4 }),
        value: 4,
    },
    {
        label: i18next.t('createAssessment.starNumber', { t: 5 }),
        value: 5,
    }];

    const renderFaviored = React.useMemo(() => {
        return [
            {
                label: i18next.t('createAssessment.all'),
                value: null,
            },
            {
                label: i18next.t('createAssessment.favorite'),
                value: true,
            },
        ].map((item, index) => {
            return <Button key={index} onClick={() => onToggle(item.value)}
                className={item.value === status ? "ant-btn-primary" : ""}
                style={{
                    borderRadius: "50px",
                    fontSize: "14px",
                    lineHeight: "17px",
                }}>{item.label}</Button>

        })
    }, [status, onToggle]);

    const onSubmitted = React.useCallback(() => {
        const values = formModal.getFieldsValue();
        const newPayload = {...values, favorite: status, language: values?.language || languageValueDefault};
        onFilter(newPayload);
        setOpen(false);
    }, [formModal, onFilter, status, setOpen, languageValueDefault]);

    const onReset = React.useCallback(() => {
        formModal.resetFields();
        setStatus(null);
        // onReloadData();
    }, [formModal]);

    const onComeBack = React.useCallback(() => {
        formModal.setFieldsValue(keepFilter);
        setOpen(false);
    }, [formModal, keepFilter, setOpen]);

    const childForm = (
        <div className="grid gap-[24px] grid-cols-[1fr]">
            {/* <Form.Item name="roles">
                <HRSelect
                    name="position"
                    label="Chức vụ"
                    placeholder="Lựa chọn"
                    className="w-full"
                />
            </Form.Item> */}
            <Form.Item name="industry">
                <HRSelect
                    name="test_field"
                    label={i18next.t('createAssessment.industry') ?? ''}
                    placeholder={i18next.t('createAssessment.select') ?? ''}
                    className="w-full"
                />
            </Form.Item>
            <Form.Item name="language">
                <HRSelect
                    name="language"
                    label={i18next.t('createAssessment.language') ?? ''}
                    placeholder={languageNameDefault}
                    className="w-full"
                />
            </Form.Item>
            <Form.Item name="planTag">
                <HRSelect
                    options={PLAN_TAG}
                    label={i18next.t('createAssessment.planTag') ?? ''}
                    placeholder={i18next.t('createAssessment.select') ?? ''}
                    className="w-full"
                />
            </Form.Item>
            <Form.Item name="statusTag">
                <HRSelect
                    options={STATUS_TAG}
                    label={i18next.t('createAssessment.statusTag') ?? ''}
                    placeholder={i18next.t('createAssessment.select') ?? ''}
                    className="w-full"
                />
            </Form.Item>
            {/* <Form.Item name="rating">
                <HRSelect
                    options={ratingList}
                    label="Đánh giá"
                    placeholder="Lựa chọn"
                    className="w-full"
                />
            </Form.Item> */}
        </div>
    );

    return <Modal
        open={open}
        centered={true}
        onCancel={() => setOpen(false)}
        maskClosable={false}
        footer={null}
    >
        <div className="w-full">
            <div className="grid grid-cols-1 gap-[24px]">
                <div><h4 className="mb-0 text-heading-5-bold text-high-em">{i18next.t('createAssessment.filter')}</h4></div>
                {/* <div>
                    <StatusGroup>
                        <Button.Group>
                            {renderFaviored}
                        </Button.Group>
                    </StatusGroup>
                </div> */}
                <div>
                    <HRForm form={formModal} name="createAssessment" childNode={childForm} />
                </div>
                <div>
                    <span>
                        <HRButton btnType="tertiary" btnSize="sm" onClick={onReset} className="w-full" style={{
                            width: "100%",
                        }}>
                            <div className="flex gap-[16px] items-center justify-center text-info-500 text-body-medium">
                                <span>
                                    <img height={18} width={18} src="/images/reload.png" alt="reload" />
                                </span>
                                <span>{i18next.t('createAssessment.clearFilter')}</span>
                            </div>
                        </HRButton>
                    </span>
                </div>
                <div className="grid grid-cols-[97px_114px] gap-[16px] items-center justify-end">
                    <span>
                        <HRButton btnType="sub" onClick={onComeBack} className="w-full">{i18next.t('createAssessment.back')}</HRButton>
                    </span>
                    <span>
                        <HRButton btnType="primary" onClick={onSubmitted} className="w-full">{i18next.t('createAssessment.apply')}</HRButton>
                    </span>
                </div>
            </div>
        </div>
    </Modal>
}
