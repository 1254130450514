import { useState, useRef, useEffect } from "react";
import { Tag, TagProps, InputRef, Input, Tooltip } from "antd";
import { ICCloseFill } from "../../../icons";
import { HRTooltip } from "../../tooltip";

type HRTagComponentProps = TagProps & {
    tag: string,
    indexTags: number,
    isLongText: boolean,
    handleClose: (value: string) => void
    setEditInputIndex: (value: any) => void
    setEditInputValue: (value: string) => void
}

export const HRTagComponent: React.FC<HRTagComponentProps> = ({ tag, isLongText, indexTags, handleClose, setEditInputIndex, setEditInputValue, ...props }) => {
    return (
        <Tag {...props}
            className="edit-tag"
            key={tag}
            closable={true}
            closeIcon={<ICCloseFill />}
            onClose={() => handleClose(tag)}
            style={{
                border: '1px solid #0CD68A',
                borderRadius: '50px',
                height: '28px',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '15px',
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gap: '4px',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 12px',
                margin: '0'
            }}
        >
            <span
                onDoubleClick={e => {
                    setEditInputIndex(indexTags);
                    setEditInputValue(tag);
                    e.preventDefault();
                }}
            >
                {isLongText ? `${tag.slice(0, 40)}...` : tag}
            </span>
        </Tag>
    )
}

type HRTagItemsProps = TagProps & {
    tags?: string[],
    inputValue: string,
    setTags: (value: string[]) => void,
    setInputValue: (value: string) => void,
    onChange?: (value: string[]) => void
}

export const HRTagItems: React.FC<HRTagItemsProps> = ({ tags = [], inputValue, setTags, setInputValue, onChange, ...props }) => {
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const editInputRef = useRef<InputRef>(null);

    const handleClose = (removedTag: string) => {
        const newTags = tags.filter(tag => tag !== removedTag);
        setTags(newTags);
        if (onChange) {
            onChange(newTags);
        }
    };
    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditInputValue(e.target.value);
    };
    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags);
        if (onChange) {
            onChange(newTags);
        }
        setEditInputIndex(-1);
        setInputValue('');
    };

    useEffect(() => {
        if (editInputIndex !== -1) {
            editInputRef.current?.focus();
        }
    }, [editInputIndex]);

    return (
        <>
            {tags?.length > 0 && <div className="flex flex-wrap gap-[8px]">
                {tags.map((tag, index) => {
                    if (editInputIndex === index) {
                        return (
                            <Input
                                ref={editInputRef}
                                key={tag}
                                size="small"
                                className="tag-input"
                                value={editInputValue}
                                maxLength={50}
                                onChange={handleEditInputChange}
                                onBlur={handleEditInputConfirm}
                                onPressEnter={handleEditInputConfirm}
                                style={{
                                    width: '160px',
                                    border: '1px solid #6A6C7D',
                                    borderRadius: '50px',
                                    height: '28px',
                                    outline: 'none',
                                    padding: '6px 16px'
                                }}
                            />
                        );
                    }
                    const isLongText: boolean = tag.length > 40;
                    return isLongText ? (
                        <HRTooltip
                            content={tag}
                            key={tag}
                            childNode={(
                                <HRTagComponent tag={tag} 
                                    key={index}
                                    indexTags={index} 
                                    isLongText={isLongText}
                                    setEditInputIndex={setEditInputIndex}
                                    setEditInputValue={setEditInputValue}
                                    handleClose={handleClose} />
                            )} 
                        />
                    ) : (
                        <HRTagComponent tag={tag} 
                            key={index}
                            indexTags={index} 
                            isLongText={isLongText}
                            setEditInputIndex={setEditInputIndex}
                            setEditInputValue={setEditInputValue}
                            handleClose={handleClose} />
                    );
                })}
            </div>}
        </>
    )
}
