import { IconProps } from "./type"

export const ICEmail: React.FC<IconProps> = ({
    fill = "#D6D7DD",
    className = "svgFillColor"
}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4H20C20.5304 4 21.0391 4.21071 21.4142 4.58579C21.7893 4.96086 22 5.46957 22 6V18C22 18.5304 21.7893 19.0391 21.4142 19.4142C21.0391 19.7893 20.5304 20 20 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V6ZM5.519 6L12 11.671L18.481 6H5.52H5.519ZM20 7.329L12.659 13.753C12.4766 13.9128 12.2424 14.0009 12 14.0009C11.7576 14.0009 11.5234 13.9128 11.341 13.753L4 7.329V18H20V7.329Z" fill={fill} />
        </svg>
    )
}
