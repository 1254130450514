import { Modal, Popover, Row, Table, Tag } from "antd"
import { ColumnType, ColumnsType } from "antd/lib/table";
import React from "react";
import classNames from "classnames";
import Highlighter from "react-highlight-words";
import { HRPagination } from "../../components/pagination";
import { ICAdd, ICSort, ICMoreVertical, ICEdit, ICCopy, ICFolderMove, ICInfoBlack, ICDelete, ICNoneData } from "../../icons";
import { IPagination } from "../library/models";
import { EStatusAssessment, ISearchMyAssessment, StatusAssessmentMapping, StatusAssessmentMappingColor, TAssessmentData } from "./type";
import { SortOrder } from "antd/lib/table/interface";
import { useSortData } from ".";
import styled from 'styled-components';
import { modifyStatusAssessment, cloneAssessment, deleteAssessment } from '../../api/assessment';
import { useNotification } from "../../hooks/useNotification";
import { HRButton, IconButton } from "../../components/button";
import { formatLocaleDateString } from "../../utils/date";
import { useNavigation } from "../../hooks/useNavigate";
import { FilterStatus } from "./filterStatus";
import i18next from "../../i18n"
import { useMobile } from "../../hooks/useDimensions";

const ActionMenu = styled.div`
    &:hover {
        .sub-menu {
            display: block;
            left: -216px;
            top: 0;
            min-width: 206px;
            background-color: #fff;
            background-clip: padding-box;
            border-radius: 2px;
            box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
        }
    }
`;

const NextAction = styled.span`
    transform: rotate(90deg);
`;

const WrapperTable = styled.div`
    .ant-table-thead>tr>th {
        border: 0;
    }
    .ant-table-tbody>tr.ant-table-row>td {
        border-top: 1px solid transparent !important;
        padding-left: 16px !important;
        border-bottom: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row >td:first-child {
        border-left: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row >td:last-child {
        border-right: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row:hover>td:first-child, .ant-table-tbody>tr>td.ant-table-cell-row-hover:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 12px;
    }
    .ant-table-tbody>tr.ant-table-row:hover>td:last-child, .ant-table-tbody>tr>td.ant-table-cell-row-hover:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 12px;
    }
    .ant-table-tbody>tr>td {
        padding: 0 !important;
    }
`;

type PopoverContentProps = {
    onClose: () => void;
    onClone: () => void;
    onDelete: () => void;
    onMove: (value: EStatusAssessment) => void;
    id: string;
    status: EStatusAssessment | undefined;
    data: TAssessmentData;
}
const PopoverContent: React.FC<PopoverContentProps> = ({ id, status, data, onClose, onClone, onMove, onDelete }) => {
    const { navigate } = useNavigation();
    const onCloseWrapper = React.useCallback((event: any) => {
        if (event) {
            event.stopPropagation();
        }
        onClose();
    }, [onClose]);

    const moveWrapper = React.useCallback((event: any, value: EStatusAssessment) => {
        if (event) {
            event.stopPropagation();
        }
        onMove(value);
    }, [onMove]);

    const cloneWrapper = React.useCallback((event: any) => {
        if (event) {
            event.stopPropagation();
        }
        onClone();
    }, [onClone]);

    const deleteWrapper = React.useCallback((event: any) => {
        if (event) {
            event.stopPropagation();
        }
        onDelete();
    }, [onDelete]);

    const renderStatusAction = React.useMemo(() => {
        if (status === EStatusAssessment.ACTIVE) {
            return <>
                <span onClick={(event) => moveWrapper(event, EStatusAssessment.ARCHIVED)} className="flex space-x-3 items-center px-4 py-3 cursor-pointer hover:bg-n-200">
                    {i18next.t('myAssessments.archive')}
                </span>
            </>;
        }
        if (status === EStatusAssessment.DRAFT) {
            return <>
                <span onClick={(event) => moveWrapper(event, EStatusAssessment.ARCHIVED)} className="flex space-x-3 items-center px-4 py-3 cursor-pointer hover:bg-n-200">
                    {i18next.t('myAssessments.archive')}
                </span>
            </>;
        }
        if (status === EStatusAssessment.ARCHIVED) {
            return <>
                <span onClick={(event) => moveWrapper(event, EStatusAssessment.ACTIVE)} className="flex space-x-3 items-center px-4 py-3 cursor-pointer hover:bg-n-200">
                    {i18next.t('myAssessments.active')}
                </span>
                <span onClick={(event) => moveWrapper(event, EStatusAssessment.DRAFT)} className="flex space-x-3 items-center px-4 py-3 cursor-pointer hover:bg-n-200">
                    {i18next.t('myAssessments.draft')}
                </span>
            </>;
        }
    }, [status, moveWrapper]);

    const activeDeleteButton = React.useMemo(() => {
        if (data?.status === EStatusAssessment.DRAFT || data?.status === EStatusAssessment.ARCHIVED) {
            return true;
        }
        if (data?.status === EStatusAssessment.ACTIVE || data?.status === EStatusAssessment.LOCKED) {
            return false;
        }
        if (data?.totalCandidates === 0) {
            return true;
        }
        return false;
    }, [data]);

    const activeEditButton = React.useMemo(() => {
        if (status === EStatusAssessment.ACTIVE || data?.status === EStatusAssessment.ACTIVE || data?.status === EStatusAssessment.LOCKED) {
            return false;
        }
        if (data?.totalCandidates === 0 && (status !== EStatusAssessment.ARCHIVED || data?.status !== EStatusAssessment.ARCHIVED)) {
            return true;
        }
        return false;
    }, [data, status]);

    const onGoToAssessment = React.useCallback((event: any) => {
        navigate(`/assessment/${id}/details`);
        onCloseWrapper(event);
    }, [navigate, id, onCloseWrapper]);

    const onEditAssessment = React.useCallback((event: any) => {
        navigate(`/edit-assessment/${id}`);
        onCloseWrapper(event);
    }, [
        navigate,
        id,
        onCloseWrapper
    ]);

    return (
        <div className="flex flex-col min-w-[206px] py-[12px]">
            <div className="flex gap-[16px] items-center p-[16px] hover:bg-surface-med cursor-pointer text-body-medium text-high-em" onClick={onGoToAssessment}>
                <ICInfoBlack />
                <span>{i18next.t('myAssessments.detail')}</span>
            </div>
            {activeEditButton &&
                <div className="flex gap-[16px] items-center p-[16px] hover:bg-surface-med cursor-pointer text-body-medium text-high-em" onClick={onEditAssessment}>
                    <ICEdit />
                    <span>{i18next.t('myAssessments.edit')}</span>
                </div>
            }
            <div onClick={cloneWrapper} className="flex gap-[16px] items-center p-[16px] hover:bg-surface-med cursor-pointer text-body-medium text-high-em">
                <ICCopy />
                <span>{i18next.t('myAssessments.clone')}</span>
            </div>

            {data?.status === EStatusAssessment.ACTIVE && <div onClick={(event) => moveWrapper(event, EStatusAssessment.ARCHIVED)} className="flex gap-[16px] items-center p-[16px] hover:bg-surface-med cursor-pointer text-body-medium text-high-em">
                <ICFolderMove />
                <span>{i18next.t('myAssessments.moveTo', { t: StatusAssessmentMapping[EStatusAssessment.ARCHIVED]})}</span>
            </div>}

            {data?.status === EStatusAssessment.DRAFT && !data?.needToPay && <div onClick={(event) => moveWrapper(event, EStatusAssessment.ACTIVE)} className="flex gap-[16px] items-center p-[16px] hover:bg-surface-med cursor-pointer text-body-medium text-high-em">
                <ICFolderMove />
                <span>{i18next.t('myAssessments.moveTo', { t: StatusAssessmentMapping[EStatusAssessment.ACTIVE]})}</span>
            </div>}

            {data?.status === EStatusAssessment.ARCHIVED && <div onClick={(event) => moveWrapper(event, EStatusAssessment.ACTIVE)} className="flex gap-[16px] items-center p-[16px] hover:bg-surface-med cursor-pointer text-body-medium text-high-em">
                <ICFolderMove />
                <span>{i18next.t('myAssessments.moveTo', { t: StatusAssessmentMapping[EStatusAssessment.ACTIVE]})}</span>
            </div>}

            {/* <ActionMenu onClick={onCloseWrapper} className="relative flex space-x-3 items-center px-4 py-3 hover:text-sub-medium cursor-pointer justify-between">
                <span className="flex space-x-3">
                    <ICFolderMove />
                    <span>Di chuyển</span>
                </span>
                <NextAction>
                    <ICChevronUp />
                </NextAction>
                <span className="hidden absolute sub-menu">
                    {renderStatusAction}
                </span>
            </ActionMenu> */}

            {activeDeleteButton && <div onClick={deleteWrapper} className="flex gap-[16px] items-center p-[16px] hover:bg-surface-med cursor-pointer text-body-medium text-high-em">
                <ICDelete />
                <span>{i18next.t('myAssessments.delete')}</span>
            </div>}
        </div>
    )
}

const TitleRenderer: React.FC<{ title: string }> = ({ title }) => {
    const keyword = React.useContext(KeywordContext);

    return <Highlighter
        highlightClassName="text-highlight"
        searchWords={[`${keyword || ''}`]}
        autoEscape={true}
        className="text-body text-high-em"
        textToHighlight={title || ''}
    />
}
type SortableColumnProps = {
    title: string;
    order?: SortOrder;
    name: string;
}
const SortableColumn: React.FC<SortableColumnProps> = ({
    title,
    order,
    name
}) => {
    const { setSortData } = useSortData();
    return <div className="flex space-x-3 items-center select-none group" onClick={() => {
        setSortData({
            field: order === 'descend' ? 'createdAt' : name,
            order: order === 'descend' ? 'desc' : !order ? 'asc' : 'desc',
        });
    }}>
        <span className="text-body-bold text-high-em">{title as string}</span>
        <ICSort order={order} />
    </div>
}

type AssessmentPageListProps = {
    assessmentList: TAssessmentData[];
    setAssessmentList: (assessmentList: TAssessmentData[]) => void;
    status: EStatusAssessment | undefined;
    keyword?: string;
    pagination?: IPagination;
    onChangePaging: ({ page }: { page: number }) => void;
    reloadData: () => void;
    onChangeFilter: (value: any) => void;
    filterParams: ISearchMyAssessment;
    setStatus: (value: EStatusAssessment) => void;
}
const KeywordContext = React.createContext<string>('');

export const AssessmentPageList: React.FC<AssessmentPageListProps> = ({
    assessmentList,
    setAssessmentList,
    status,
    keyword,
    pagination,
    onChangePaging,
    reloadData,
    onChangeFilter,
    filterParams,
    setStatus,
}) => {
    const { navigate } = useNavigation();
    const { showSuccess, showError } = useNotification();
    const onChangePage = (page: number) => {
        onChangePaging({ page })
    };
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [assessmentId, setAssessmentId] = React.useState('');

    const onChangeData = React.useCallback((id: string, value: any) => {
        setAssessmentList(assessmentList.map(assessment => {
            if (assessment._id === id) {
                return {
                    ...assessment,
                    status: value
                }
            }
            return assessment;
        }));
    }, [setAssessmentList, assessmentList]);

    const onShowDeleteModal = React.useCallback((id: string) => {
        setAssessmentId(id);
        setShowDeleteModal(true);
    }, [setShowDeleteModal]);

    const onDeleteAssessment = React.useCallback(async () => {
        try {
            const assessmentSelected = assessmentList.find(d => d._id === assessmentId);
            await deleteAssessment(assessmentId);
            showSuccess(i18next.t('myAssessments.hasBeenDeleted', { t: assessmentSelected?.title }));
            setAssessmentList(assessmentList.filter(d => d._id !== assessmentId));
            reloadData();
        } catch (error) {
            showError('Error', i18next.t('myAssessments.errorOccurred'));
        }
    }, [assessmentId, reloadData, setAssessmentList, assessmentList, showSuccess, showError]);

    const columns: ColumnsType<TAssessmentData> = [
        {
            title: i18next.t('myAssessments.title'),
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            render: (title: string) => {
                return <div className="min-w-[200px]">
                    <TitleRenderer title={title} />
                </div>;
            },
        },
        {
            title: i18next.t('myAssessments.label'),
            dataIndex: 'status',
            key: 'status',
            width: '130px',
            sorter: true,
            render: (status: EStatusAssessment) => {
                if ((status as EStatusAssessment) === EStatusAssessment.LOCKED) {
                    return <Tag style={{
                        borderRadius: '4px',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        padding: '4px 8px',
                        color: '#4F4B5C',
                        backgroundColor: '#FFFFFF',
                        borderColor: '#ECECED'
                    }}>
                        {StatusAssessmentMapping[status]}
                    </Tag>
                }
                if ((status as EStatusAssessment) === EStatusAssessment.PAIDING) {
                    return <Tag style={{
                        borderRadius: '4px',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        padding: '4px 8px',
                        color: '#FFFFFF',
                        backgroundColor: StatusAssessmentMappingColor[EStatusAssessment.DRAFT],
                        borderColor: StatusAssessmentMappingColor[EStatusAssessment.DRAFT]
                    }}>
                        {StatusAssessmentMapping[EStatusAssessment.DRAFT]}
                    </Tag>
                }
                return <Tag style={{
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '16px',
                    padding: '4px 8px',
                    color: '#FFFFFF',
                    backgroundColor: StatusAssessmentMappingColor[status],
                    borderColor: StatusAssessmentMappingColor[status]
                }}>
                    {StatusAssessmentMapping[status]}
                </Tag>
            }
        },
        {
            title: i18next.t('myAssessments.totalCandidates'),
            dataIndex: 'totalCandidates',
            key: 'totalCandidates',
            width: '130px',
            sorter: true,
            render: (totalCandidates: number) => {
                return <div className="pr-2 text-body text-high-em min-w-[130px]">
                    <span>{totalCandidates ?? 0}</span>
                </div>
            }
        },
        {
            title: i18next.t('myAssessments.createdAt'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            width: '130px',
            render: (createdAt: number) => {
                return <div className="pr-2 text-body text-high-em min-w-[130px]">
                    <span>{formatLocaleDateString(createdAt)}</span>
                </div>
            }
        },
        {
            title: i18next.t('myAssessments.totalQuestions'),
            dataIndex: 'totalQuestions',
            key: 'totalQuestions',
            sorter: true,
            width: '130px',
            render: (totalQuestions: number) => {
                return <div className="pr-2 text-body text-high-em min-w-[130px]">
                    <span>{totalQuestions ?? 0}</span>
                </div>
            }
        },
        {
            title: '',
            dataIndex: '_id',
            key: '_id',
            width: '40px',
            render: (_id: string, data: TAssessmentData) => {
                if (data?.status === EStatusAssessment.PAIDING) {
                    return <div className="text-center">
                        <IconButton
                            icon={<ICMoreVertical />}
                            btnType="tertiary" />
                    </div>;
                }
                return <ItemActions id={_id} status={status} data={data} reloadData={reloadData} onShowDeleteModal={onShowDeleteModal} onChangeData={onChangeData} />
            }
        }
    ].map((col: ColumnType<TAssessmentData>) => {
        if (col.sorter) {
            const oldTitle = col.title;
            col.title = ({ sortColumns }) => {
                const sortedColumn = sortColumns?.find(({ column }) => column.key === col.key);
                return <SortableColumn
                    title={oldTitle as string}
                    order={sortedColumn?.order}
                    name={col.dataIndex as string}
                />
            }
        } else {
            col.title = <div className="text-body-bold text-high-em">{col.title as string}</div>;
        }
        col.showSorterTooltip = false;
        return col
    });

    const dataSource = React.useMemo(() => {
        if (status) {
            // paiding and draft used same place
            const customStatuses = status === EStatusAssessment.DRAFT ? [EStatusAssessment.DRAFT, EStatusAssessment.PAIDING] : [status];
            return assessmentList.filter(d => customStatuses.includes(d.status as EStatusAssessment)).map(assessment => ({
                ...assessment,
                key: assessment._id
            }));
        }
        return assessmentList;
    }, [assessmentList, status]);
    const isMobile = useMobile();
    return <WrapperTable className="grid grid-cols-1 shadow-e-03 text-g-400 overflow-hidden box-border rounded-[12px]">
        <FilterStatus
            status={status}
            filterParams={filterParams}
            setStatus={setStatus}
            onChangeFilter={onChangeFilter} />
        <KeywordContext.Provider value={keyword ?? ''}>
            <Table
                // Quick hack for force update table
                key={status}
                className="white-header max-w-full overflow-x-auto"
                columns={columns}
                dataSource={dataSource}
                rowClassName="cursor-pointer data-row"
                onRow={(record) => {
                    return {
                        onClick: () => {
                            navigate(`/assessment/${record._id}/details`, {
                                state: {
                                    search: window.location.search
                                }
                            });
                        }
                    }
                }}
                rowKey="_id"
                locale={{
                    emptyText: <EmptyTable keyword={keyword} />
                }}
                pagination={false}
            />
        </KeywordContext.Provider>
        {pagination && pagination.totalPages > 1 && <Row gutter={16}
            style={{
                margin: 0,
            }}
            className="lg:grid items-center justify-center lg:justify-end w-full bg-white p-[16px] border-t border-solid border-outline-med rounded-b-[12px]"
        >
            <HRPagination
                onChange={onChangePage}
                defaultCurrent={pagination?.page}
                defaultPageSize={pagination?.limit}
                total={pagination?.totalDocuments}
                currentPage={pagination?.page}
                showSizeChanger={false}
                size={isMobile ? "small" : "default"}
            />
        </Row>}
        <Modal
            centered
            onCancel={() => setShowDeleteModal(false)}
            open={showDeleteModal}
            title={
                <div className="font-bold text-[18px]">{i18next.t('myAssessments.removeFromList')}</div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={() => setShowDeleteModal(false)}>
                        {i18next.t('myAssessments.cancel')}
                    </HRButton>
                    <HRButton
                        btnType="danger"
                        btnSize="sm"
                        onClick={() => {
                            onDeleteAssessment();
                            setShowDeleteModal(false);
                        }}>
                        {i18next.t('myAssessments.delete')}
                    </HRButton>
                </div>
            }>
            <p>{i18next.t('myAssessments.deleteAssessmentWarning')}</p>
        </Modal>
    </WrapperTable>
}

type IEmptyTableProps = {
    keyword: string | undefined;
}

const EmptyTable: React.FC<IEmptyTableProps> = ({ keyword }) => {
    const { navigate } = useNavigation();
    const goCreateaAssessment = React.useCallback(() => {
        navigate('/create-assessment')
    }, [navigate])
    return (
        <div className="my-6 flex flex-col gap-[32px]">
            <div className="flex justify-center">
                <ICNoneData />
            </div>
            <div className="text-standard-bold text-high-em">
                {keyword ? i18next.t('myAssessments.noResult', { t: keyword }) : i18next.t('myAssessments.noAssessments')}
            </div>
            <div className="flex justify-center">
                <span>
                    <HRButton
                        btnType="secondary"
                        btnSize="sm"
                        style={{
                            borderRadius: '144px',
                        }}
                        onClick={goCreateaAssessment}>
                        <div className="flex space-x-2 items-center">
                            <ICAdd stroke="#7357FF" width={18} height={18} />
                            <span>{i18next.t('myAssessments.createNewAssessment')}</span>
                        </div>
                    </HRButton>
                </span>
            </div>
        </div>
    )
}

type ItemActionsProps = {
    id: string;
    data: TAssessmentData;
    onChangeData: (id: string, value: string) => void;
    status: EStatusAssessment | undefined;
    reloadData: () => void;
    onShowDeleteModal: (id: string) => void;
}
export const ItemActions: React.FC<ItemActionsProps> = ({
    id,
    onChangeData,
    status,
    reloadData,
    onShowDeleteModal,
    data
}) => {
    const { showSuccess, showError } = useNotification();
    const [open, setOpen] = React.useState(false);

    const onClose = React.useCallback(() => {
        setOpen(false);
    }, [])
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    const onCloneAssessment = React.useCallback(async () => {
        try {
            await cloneAssessment(id);
            showSuccess(i18next.t('myAssessments.assessmentTitleHasBeenUsed'));
            reloadData();
            onClose();
        } catch (error) {
            showError('Error', i18next.t('myAssessments.errorOccurred'));
        }
    }, [id, reloadData, showSuccess, showError, onClose]);

    const onShowConfirmDeleteModal = React.useCallback(() => {
        onShowDeleteModal(id);
        onClose();
    }, [id, onClose, onShowDeleteModal]);

    const onMoveAssessment = React.useCallback(async (value: any) => {
        try {
            await modifyStatusAssessment(id, {
                status: value
            });
            showSuccess(i18next.t('myAssessments.moveSuccess'));
            onChangeData(id, value);
            onClose();
        } catch (error) {
            showError('Error', i18next.t('myAssessments.errorOccurred'));
        }
    }, [id, showSuccess, showError, onClose, onChangeData]);

    return (
        <div className="text-center">
            <Popover
                open={open}
                placement="bottomRight"
                content={<PopoverContent id={id} status={status} data={data} onClose={onClose} onClone={onCloneAssessment} onDelete={onShowConfirmDeleteModal} onMove={onMoveAssessment} />}
                showArrow={false}
                className="popover-more"
                prefixCls="hr-popover ant-popover"
                trigger="click"
                onOpenChange={handleOpenChange}
            >
                <IconButton
                    icon={<ICMoreVertical />}
                    btnType="tertiary"
                    onClick={(event) => event.stopPropagation()} />
            </Popover>
        </div>
    )
}
