import React from "react";
import { HRCollapse } from "../../../../components/collapse";
import classNames from 'classnames'
import { useTestingPageContext } from "../..";
import { TQuestionTesting } from "../../type";
import _ from "lodash";
import styled from "styled-components";
import { EQuestionType } from "../../../create_test/tab/question/type";
import i18next from "../../../../i18n";

const Wrapper = styled.div`
    &:hover {
        .ant-collapse .ant-collapse-header,
        .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
            opacity: 1;
        }
    }
    .ant-collapse .ant-collapse-header,
    .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
        opacity: 0.2;
    }
`;

type QuestionListProps = {
    currentQuestionIndex: number;
    questionData: TQuestionTesting[];
    onNavigateQuestion: (index: number) => void;
    correctAnswer?: (number[] | number | undefined | string)[];
    sectionName?: string;
    questionViewed?: Set<string>;
}
export const QuestionList: React.FC<QuestionListProps> = ({
    currentQuestionIndex,
    questionData = [],
    onNavigateQuestion,
    correctAnswer,
    sectionName,
    questionViewed,
}) => {
    const { sectionTesting } = useTestingPageContext();
    
    const onSelectQuestion = React.useCallback((question: TQuestionTesting, index: number) => {
        onNavigateQuestion(index);
    }, [onNavigateQuestion]);
    
    const renderQuestion = React.useMemo(() => {
        const setBackground = (question: TQuestionTesting, index: number) => {
            if (question.type === EQuestionType.SINGLE_CHOICE || question.type === EQuestionType.ESSAY) {
                const isCorrect = Array.isArray(correctAnswer) && correctAnswer[index] === 0 ? true : correctAnswer && correctAnswer[index];
                if (isCorrect) {
                    return "bg-[#EEF0F6] border-[#EEF0F6]";
                }
            }
            if (question.type === EQuestionType.MULTIPLE_CHOICE) {
                if (correctAnswer && !_.isEmpty(correctAnswer[index])) {
                    return "bg-[#EEF0F6] border-[#EEF0F6]";
                }
            }
            return "bg-white";
        };
        const hasSelectedAnswer = (question: TQuestionTesting, index: number) => {
            if (question.type === EQuestionType.SINGLE_CHOICE || question.type === EQuestionType.ESSAY) {
                return Array.isArray(correctAnswer) && correctAnswer[index] === 0 ? true : correctAnswer && correctAnswer[index];
            }
            if (question.type === EQuestionType.MULTIPLE_CHOICE) {
                return correctAnswer && !_.isEmpty(correctAnswer[index]);
            }
            return false;
        };

        return (
            <div className="flex flex-col gap-[16px]">
                <div className="flex flex-wrap flex-row gap-[16px]">
                    {questionData.map((question: TQuestionTesting, index: number) => (
                        <div key={index} 
                            onClick={() => onSelectQuestion(question, index)}
                            style={{
                                borderColor: index === currentQuestionIndex ? "#7357FF" : "",
                            }}
                            className={
                                classNames(
                                    "flex items-center h-[34px] w-[34px] cursor-pointer text-center justify-center rounded-[4px] border-solid border-[1px] border-transparent p-[9px]",
                                    (index === 0 || questionViewed?.has(question._id)) && !hasSelectedAnswer(question, index) ? "border-[#E9A743]" : "",
                                    index === currentQuestionIndex ? "text-[#7357FF]" : "text-[#46485C]",
                                    setBackground(question, index)
                                )
                        }>
                            <div className="text-[14px] font-bold leading-[17px]">{index + 1}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }, [questionData, currentQuestionIndex, questionViewed, onSelectQuestion, correctAnswer]);

    const content = (
        <div className="grid grid-cols-1 gap-[32px] items-start">
            <div className="grid grid-cols-1 gap-[32px]">
                {renderQuestion}
            </div>
        </div>
    );
    const header = (
        <div className="flex flex-col gap-[4px]">
            <div className="text-[12px] font-medium leading-[15px] text-[#A6A8B4]">{i18next.t('testing.allQuestionsIn')}</div>
            <div className="text-[14px] font-bold text-[#46485C]">{sectionTesting?.title || sectionName}</div>
        </div>
    );

    return <Wrapper className="flex space-x-2 items-center">
        <HRCollapse title={header} bordered={false} childNode={content} className="w-full" />
    </Wrapper>
}
