import React from "react";
import { HRButton } from "../../../components/button";
import { useAssementData } from "./hooks/useCreateAssessment";
import { ICCheckV2, ICNext } from "../../../icons";
import { serializeAdditionalQuestion } from "./type";
import { useNavigation } from "../../../hooks/useNavigate";
import { useGeneralAssessment } from "./hooks/useAssessment";
import { useNotification } from "../../../hooks/useNotification";
import { CannotModifyModal } from "./header";
import i18next from "../../../i18n";

type TCreateDimensionFooterProps = {
    mode: 'create' | 'edit';
    id: string;
}
export const CreateDimensionFooter: React.FC<TCreateDimensionFooterProps> = ({
    mode,
    id
}) => {
    const {
        assessment,
        tests,
        additionalQuestions,
        step,
        setStep,
        generalForm,
        setAssessment,
        reviewQuestion,
        setIsPrompt,
    } = useAssementData();
    const { navigate } = useNavigation();
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const { showError } = useNotification();
    const disableCompleted = React.useMemo(() => {
        return additionalQuestions.length === 0 && tests.filter(d => typeof d._id === 'string').length === 0;
    }, [tests, additionalQuestions]);
    const hasPriceTest = React.useMemo(() => {
        return tests.filter(d => typeof d._id === 'string').some(d => d.price !== null);
    }, [tests]);
    const {
        saveGeneralInformation,
        updatedAssessment,
    } = useGeneralAssessment(generalForm, setStep, setAssessment);
    const doSubmitAssessment = React.useCallback(() => {
        const testsIds = tests.filter(d => d._id).map((test) => test._id);
        if (id) {
            const additionalIndex = (reviewQuestion ?? []).findIndex(d => d.type === 'additional_question') || 0;
            const payload = {
                ...assessment,
                tests: testsIds,
                additionalIndex: additionalIndex,
                additionalQuestions: additionalQuestions.map(serializeAdditionalQuestion)
            };
            setIsPrompt(false);
            updatedAssessment(id, payload).then((res: any) => {
                if (res.status === 200) {
                    const data = res.data;
                    if (data.needToPay && !data?.canSkipPayment) {
                        navigate(`/payment/${id}`);
                    } else {
                        navigate(`/assessment/${id}/details`);
                    }
                }
            }).catch((err) => console.warn(err)).finally(() => setIsPrompt(true));
        }

    }, [id, hasPriceTest, assessment, setIsPrompt, navigate, tests, additionalQuestions, reviewQuestion, updatedAssessment]);
    const onSubmit = React.useCallback(() => {
        if (hasPriceTest && assessment?.canSkipPayment === false) {
            setShowConfirmModal(true);

        } else {
            doSubmitAssessment();
        }
    }, [hasPriceTest, assessment, doSubmitAssessment]);
    const onNextStep = React.useCallback(() => {
        generalForm.validateFields().then((results: any) => {
            if (mode === 'create') {
                saveGeneralInformation(results);
            } else {
                setStep(prev => prev + 1);
            }
        }).catch((err) => {
            showError(i18next.t('createAssessment.requiredInfo'), i18next.t('createAssessment.fillRequired'));
        });
    }, [generalForm, mode, saveGeneralInformation, setStep]);

    return <div className="flex lg:hidden justify-center my-4 px-4">
        {
            step !== 3
                ? <HRButton
                    btnSize="md"
                    onClick={onNextStep}
                >
                    <div className="flex space-x-2 justify-center">
                        <span>{i18next.t('createAssessment.next')}</span>
                        <ICNext />
                    </div>
                </HRButton>
                : <HRButton
                    onClick={onSubmit}
                    btnSize="md"
                    disabled={disableCompleted}
                >
                    <div className="flex space-x-2 justify-center">
                        <span>{hasPriceTest && assessment?.canSkipPayment !== true ? i18next.t('createAssessment.completeAndCheckout') : i18next.t('createAssessment.complete')}</span>
                        <ICCheckV2 fill={disableCompleted ? '#B2B4BF' : 'white'} />
                    </div>
                </HRButton>
        }
        <CannotModifyModal
            isOpen={showConfirmModal}
            onCancel={() => setShowConfirmModal(false)}
            onConfirm={() => {
                doSubmitAssessment();
                setShowConfirmModal(false);
            }}
        />
    </div>
}