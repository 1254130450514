import { Collapse, CollapseProps } from 'antd'
import styled from 'styled-components';
import { ICChevronDown, ICChevronUp } from '../icons';
import classNames from 'classnames';
import { IconButton } from './button';

const { Panel } = Collapse;

const CollapseWrapper = styled.div`
    &.no-header {
        .ant-collapse {
            background-color: transparent;
            .ant-collapse-header {
                height: auto !important;
                background-color: transparent;
                flex-direction: column;
            }
            .ant-collapse-content {
                .ant-collapse-content-box {
                    padding: 32px !important;
                }
            }
        }
        .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
            background: transparent;
        }
    }
    &.camera-viewer {
        .ant-collapse-expand-icon {
            transform: rotate(180deg);
        }
    }
    &.no-border-radius {
        .ant-collapse {
            border-radius: 0;
            .ant-collapse-item:last-child>.ant-collapse-content {
                border-radius: 0;
            }
        }
    }
    .ant-collapse {
        border-radius: 12px;
        border: 0;
        box-shadow: 0px 6px 16px -6px rgba(17, 12, 34, 0.10);
        box-sizing: border-box;
        overflow: hidden;
        >.ant-collapse-item {
            border: 0;
        }
        .ant-collapse-item:last-child>.ant-collapse-header {
            border-radius: 12px 12px 0 0;
        }
        .ant-collapse-item:last-child>.ant-collapse-content {
            border-radius: 0 0 12px 12px;
        }
        .ant-collapse-header {
            flex-direction: row-reverse;
            padding: 16px !important;
            justify-content: center !important;
            align-items: center !important;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px !important;
            color: #110C22 !important;
            background: white;
        }
        .ant-collapse-content {
            border-top: 1px solid #E2E4EB;
            .ant-collapse-content-box {
                padding: 12px !important;
            }
        }
    }
    .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
        border: 0;
        background: white;
    }
`;

type HRCollapseProps = CollapseProps & {
    title: string | React.ReactElement | React.ReactNode;
    childNode: React.ReactElement | React.ReactNode;
    onChange?: (key: string | string[]) => void;
    bordered?: boolean;
    classess?: string;
}

export const HRCollapse: React.FC<HRCollapseProps> = ({
    title,
    childNode,
    bordered = true,
    onChange,
    classess
}) => {
    const customExpandIcon = (props: any) => {
        return <IconButton 
            btnType="tertiary"
            btnSize="sm"
            icon={props.isActive ? <ICChevronUp width={18} height={18} /> : <ICChevronDown width={18} height={18} />} />;
    };

    return <CollapseWrapper className={classNames("w-full", !title ? 'no-header': '', `${classess}`)}>
        <Collapse defaultActiveKey={['1']} onChange={onChange} bordered={bordered}
            expandIconPosition="end"
            expandIcon={(props) => customExpandIcon(props)}>
            <Panel header={title || ''} key="1">
                {childNode}
            </Panel>
        </Collapse>
    </CollapseWrapper>
}
