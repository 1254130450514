import React from "react";
import { useDispatch } from "react-redux";
import { useNotification } from "../../../hooks/useNotification";
import { setLoading } from "../../../redux/slices/appInfo";
import { previewTestById } from "../../../api/library";
import { ILibrary } from "../../library/models";

export const usePreviewTesting = () => {
    const dispatch = useDispatch();
    const { showError } = useNotification();

    const loadTestDetails = React.useCallback(async (id: string, setTestDetails?: React.Dispatch<React.SetStateAction<ILibrary>>) => {
        dispatch(setLoading(true));
        try {
            const result = await previewTestById(id);
            if (setTestDetails && result?.data) {
                setTestDetails((prev: any) => ({
                    ...prev,
                    ...result.data,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    return {
        loadTestDetails
    }
}
