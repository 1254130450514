import React, { useState, useMemo, useEffect, useCallback } from "react"
import debouce from 'lodash/debounce';
import { HRInput } from "../../components/form/input";
import { HRSelect } from "../../components/form/select";
import { ICSearch } from "../../icons";
import { ISearchCriteriaCandidate, TMyCandidateFilter } from "./type";
import i18next from "../../i18n";
type FilterProps = {
    onChange: (value: any) => void;
    filterParams: ISearchCriteriaCandidate;
    filterData?: TMyCandidateFilter;
}

export const Filter: React.FC<FilterProps> = ({
    onChange,
    filterParams,
    filterData,
}) => {
    const [title, setTitle] = useState<string | undefined>("");
    const handleChange = useCallback(($event: any) => {
        let value = $event.target.value;
        const MAX_LENGTH = 50;
        if (value.length > MAX_LENGTH) {
            return;
        }
        setTitle(value);
        const newParams = { ...filterParams, searchString: value, page: 1 };
        onSearchKeyword(newParams);
    }, [filterParams]);

    const onSearchKeyword = useMemo(() => {
        return debouce(onChange, 600);
    }, [onChange]);

    useEffect(() => {
        return () => {
            onSearchKeyword.cancel();
        }
    }, [onSearchKeyword]);

    useEffect(() => {
        if (filterParams) {
            setTitle(filterParams.searchString ?? '');
        }
    }, [filterParams]);
    const onChangeData = useCallback((values: any) => {
        onChange({ ...filterParams, ...values, page: 1 });
    }, [filterParams]);

    const onClearValue = React.useCallback(() => {
        setTitle(undefined);
        const newParams = { ...filterParams, searchString: undefined, page: 1 };
        onChange(newParams);
    }, [filterParams, onChange]);

    return (
        <div className="lg-container mx-auto flex justify-between">
            <div className="grid grid-cols-1 gap-[30px] w-full">
                <div className="grid grid-cols-1 gap-[16px] w-full">
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-[16px]">
                        <div className="pt-[24px]">
                            <HRInput
                                placeholder={i18next.t('myCandidate.search') ?? ''}
                                hasClearIcon={true}
                                onClearValue={onClearValue}
                                onChange={handleChange}
                                maxLength={50}
                                value={title}
                                prefix={<ICSearch />}
                            />
                        </div>
                        <HRSelect
                            label={i18next.t('myCandidate.assessment') ?? ''}
                            placeholder={i18next.t('myCandidate.select') ?? ''}
                            value={filterParams?.assessment ?? null}
                            className="w-full"
                            options={filterData?.assessments || []}
                            onChange={(value) => onChangeData({ assessment: value })}
                        />
                        <HRSelect
                            label={i18next.t('myCandidate.test') ?? ''}
                            value={filterParams?.test ?? null}
                            placeholder={i18next.t('myCandidate.select') ?? ''}
                            className="w-full"
                            options={filterData?.tests || []}
                            onChange={(value) => onChangeData({ test: value })}
                        />
                        <HRSelect
                            name="position"
                            label={i18next.t('myCandidate.position') ?? ''}
                            value={filterParams?.role ?? null}
                            placeholder={i18next.t('myCandidate.selectPosition') ?? ''}
                            className="w-full"
                            onChange={(value) => onChangeData({ role: value })}
                        />
                        
                    </div>
                </div>

            </div>

        </div>
    )
}
