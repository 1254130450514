import { ICClose } from "../../icons/close"
import classNames from 'classnames'

type AvatarModalProps = {
    imgSrc: string
    isOpen: boolean
    onClose: () => void
}
export const AvatarModal: React.FC<AvatarModalProps> = ({
    imgSrc,
    onClose,
    isOpen
}) => {
    return (
        <div
            onClick={onClose}
            className={classNames(isOpen ? "flex" : "hidden", "items-center justify-center fixed top-0 left-0 z-[1000] w-[100vw] h-[100vh] bg-[rgba(0,0,0,0.3)]")}
        >
            <div
                onClick={onClose}
                className="absolute top-0 right-[18px] flex items-center justify-center w-[104px] h-[104px] bg-[rgba(0,0,0,0.8)]">
                <ICClose fill="white"/>
            </div>
            <div className="bg-white rounded-[8px] w-[500px] h-[500px]" onClick={(e) => {
                e.stopPropagation()
            }}>
                <img src={imgSrc} alt="avatar" className="w-[500px] h-[500px]" />
            </div>
        </div>
    )
}