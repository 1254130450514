import { Checkbox, Radio } from "antd";
import React from "react";
import { EQuestionType, TChoiceQuestion } from "../../create_test/tab/question/type";
// import { Iframe } from "../../../components/iframe";
import { ContentState, Editor, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import classNames from "classnames";
import styled from "styled-components";

const Wrapper = styled.div`
    .ant-radio-wrapper {
        margin: 0;
        .ant-radio {
            top: .3em;
        }
    }
    .ant-radio-checked .ant-radio-inner:after {
        background-color: #0084FF;
    }
    .ant-radio-checked:after {
        border: 1px solid #0084FF;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: #0084FF !important;
    }
    .purple-checkbox .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #0084FF !important;
        border-color: #0084FF !important;
    }
    .purple-checkbox .ant-checkbox-inner {
        width: 24px;
        height: 24px;
        border-radius: 4px;
    }
`;

type TestingAnswerWrapperProps = {
    question: TChoiceQuestion;
    onChangeCorrectAnswer: (answer: number | undefined | number[]) => void;
    correctAnswer?: number | number[] | string;
}

// Use for single choice and multiple choice only
export const TestingAnswerWrapper: React.FC<TestingAnswerWrapperProps> = ({
    question,
    onChangeCorrectAnswer,
    correctAnswer,
}) => {
    const onChangeCorrectAnswerWrapper = (index: number, isChecked?: boolean) => {
        if (question.type === EQuestionType.SINGLE_CHOICE) {
            onChangeCorrectAnswer(index);
        } else {
            if (isChecked) {
                onChangeCorrectAnswer([...(correctAnswer as number[] ?? []), index]);
            } else {
                onChangeCorrectAnswer((correctAnswer as number[] ?? []).filter(item => item !== index));
            }
        }
    }

    return <div className='grid grid-cols-1 gap-[48px] answer-editor-view-only'>
        {
            question?.options?.map((answer, index) => {
                const isChecked = (index: number) => {
                    if (question.type === "single_choice") {
                        return correctAnswer === index;
                    } else {
                        if (!correctAnswer) {
                            return false;
                        }
                        if (Array.isArray(correctAnswer)) {
                            return correctAnswer.includes(index);
                        }
                        return ([correctAnswer] as number[] ?? [])?.includes(index);
                    }
                };
            
                return <AnswerEditor
                    key={answer?.id || index}
                    index={index}
                    answer={answer.content}
                    onChangeCorrectAnswer={onChangeCorrectAnswerWrapper}
                    isChecked={isChecked(index)}
                    questionType={question.type}
                />
            })
        }
    </div >
}

type TestingAnswerEditorProps = {
    questionType: EQuestionType;
    isChecked: boolean;
    onChangeCorrectAnswer: (index: number, isChecked?: boolean) => void;
    answer: string;
    index: number;
}
const AnswerEditor: React.FC<TestingAnswerEditorProps> = ({
    questionType,
    isChecked,
    onChangeCorrectAnswer,
    answer,
    index,
}) => {
    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(answer).contentBlocks))
    )

    React.useEffect(() => {
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(answer).contentBlocks)))
    }, [answer])

    const onEditorStateChange = (editorState: EditorState) => {
        // Do nothing
    }
    
    const onChangeAnswer = React.useCallback(() => {
        if (questionType === "single_choice") {
            onChangeCorrectAnswer(index);
        } else {
            onChangeCorrectAnswer(index, !isChecked);
        }
    }, [isChecked, index, onChangeCorrectAnswer, questionType]);

    return <Wrapper>
        <div 
            className={classNames([
                'flex items-center gap-[16px] border px-[16px] cursor-pointer',
                'shadow-e-01 border-outline-med rounded-[8px]',
                {
                    'text-info-bold bg-surface-info-accent-base border-outline-info-med': isChecked,
                }
            ])}
            onClick={onChangeAnswer}>
            {
                questionType === "single_choice" ? <Radio
                    checked={isChecked}
                    onChange={() => onChangeCorrectAnswer(index)}
                /> : <span>
                    <Checkbox
                        className="purple-checkbox"
                        checked={isChecked}
                        onChange={(e) => {
                            onChangeCorrectAnswer(index, e.target.checked);
                        }}
                    />
                </span>
            }
            <div className="relative">
                {/* <Iframe 
                    key={index}
                    id={`answer-${index}`}
                    content={answer as string || ''} 
                    onInferredClick={onChangeAnswer}
                    customClass="answer-iframe"
                    customStyle={{
                        width: '100%',
                        height: '56px',
                        overflow: 'hidden',
                        display: 'flex',
                        'align-items': 'center',
                    }} /> */}
                <Editor
                    stripPastedStyles={true}
                    readOnly={true}
                    editorState={editorState}
                    onChange={onEditorStateChange}
                />
            </div>
        </div>
    </Wrapper>
}
