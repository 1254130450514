import React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IPagination } from '../../../library/models';
import { ISearchMyAssessment, TSearchCandidateList } from '../../type';
import { setLoading } from '../../../../redux/slices/appInfo';
import { FilterCandidateList } from './filter';
import { CandidateTable } from './list';
import { useAssementDetailsData } from '..';
import { ECandidateAssessmenStatus } from '../../../testing/type';
import { getCandidateList } from '../../../../api/test-candidate';
import { useLocation } from 'react-router-dom';

export type TSortData = {
    field?: string;
    order?: 'asc' | 'desc';
}
type TSortDataContext = {
    sortData: TSortData;
    setSortData: (data: TSortData) => void;
}
const SortDataContext = React.createContext<TSortDataContext>({} as TSortDataContext);
export const useSortData = () => React.useContext(SortDataContext);

type CandidateListProps = {
    filterParams: any;
    setFilterParams: (data: any) => void;
    pagination: IPagination | undefined;
    setPagination: (data: IPagination) => void;
    selectedCandidateRowKeys: React.Key[];
    setSelectedCandidateRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
};

export const CandidateList: React.FC<CandidateListProps> = ({
    filterParams,
    setFilterParams,
    pagination,
    setPagination,
    selectedCandidateRowKeys,
    setSelectedCandidateRowKeys,
}) => {
    const location = useLocation();
    const ref = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        loadData(filterParams).then(() => {
            const stateHash = location.state?.hash;
            if (stateHash === "candidate-table") {
                location.state.hash = "";
                setTimeout(() => {
                    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 50);
            }
        })
    }, [])
    const dispatch = useDispatch();
    const { assessmentId, assessmentDetails, candidateList, setCandidateList, setCandidateFullList } = useAssementDetailsData();
    const [sortData, setSortData] = React.useState<TSortData>({ field: 'invitedAt', order: 'desc' });
    const [status, setStatus] = React.useState<ECandidateAssessmenStatus>(ECandidateAssessmenStatus.ALL);

    const hasCandidate = assessmentDetails?.totalCandidates && assessmentDetails?.totalCandidates > 0 ? true : false;

    const onChangeFilter = useCallback((values: any) => {
        const newParams = { ...filterParams, ...values };
        loadData(newParams);
    }, [filterParams]);

    const onChangeSort = useCallback((data: TSortData) => {
        setSortData(data);
        const formatSortData = `${data.field}::${data.order}`;
        const filterPayload = {
            orderBy: formatSortData,
            page: 1
        }
        if (onChangeFilter) onChangeFilter(filterPayload);
    }, [
        sortData,
        onChangeFilter
    ]);

    const loadData = async (value?: ISearchMyAssessment) => {
        const newParams = value || filterParams;
        const currentStatus = newParams.status || ECandidateAssessmenStatus.ALL;
        const payload: TSearchCandidateList = {
            ...newParams,
            status: currentStatus === ECandidateAssessmenStatus.ALL ? undefined : currentStatus,
        }
        setStatus(currentStatus || ECandidateAssessmenStatus.ALL);
        dispatch(setLoading(true));
        try {
            const results = await getCandidateList(assessmentId, payload);
            const { data } = results || {};
            const newData = data.data || [];
            setCandidateList(newData);
            setPagination(data.metaData);
            setFilterParams(payload);
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    };

    const reloadData = useCallback(() => {
        loadData(filterParams);
    }, [filterParams]);

    return (
        <div className="text-center" ref={ref}>
            <div className="flex flex-col">
                <SortDataContext.Provider value={{ sortData, setSortData: onChangeSort }}>
                {hasCandidate && <FilterCandidateList
                        filterParams={filterParams}
                        onChangeFilter={onChangeFilter} />}
                    <CandidateTable
                        candidateList={candidateList}
                        status={status}
                        setCandidateList={setCandidateList}
                        setCandidateFullList={setCandidateFullList}
                        reloadData={reloadData}
                        keyword={filterParams?.searchString}
                        pagination={pagination}
                        onChange={onChangeFilter}
                        filterParams={filterParams}
                        selectedCandidateRowKeys={selectedCandidateRowKeys}
                        setSelectedCandidateRowKeys={setSelectedCandidateRowKeys}
                        
                    />
                </SortDataContext.Provider>
            </div>
        </div>
    )
}
