import React from "react";
import { TAssessment } from "../create-assessment/type"
import { Switch } from "antd";
import i18next from "../../../i18n";

type AssessmentConfigurationProps = {
    assessmentDetails: TAssessment;
    onUpdate: (data: Record<string, any>) => void;
}
export const AssessmentConfiguration: React.FC<AssessmentConfigurationProps> = ({
    assessmentDetails,
    onUpdate
}) => {
    return <div className="min-h-[250px] flex flex-col w-full items-start justify-start rounded-[12px] bg-white shadow-e-03 z-[3] p-4 space-y-2">
        <span className="text-standard-bold text-g-800">{i18next.t('assessmentDetail.configuration')}</span>
        <div className="flex items-center justify-between space-x-2">
            <span className="text-body text-high-em">
                {i18next.t('assessmentDetail.forceTurnOnCamera')}
            </span>
            <div>
                <Switch
                    checked={assessmentDetails.forceTurnOnCamera}
                    style={{
                        width: '30px',
                    }}
                    onChange={() => onUpdate({
                        forceTurnOnCamera: !assessmentDetails.forceTurnOnCamera
                    })}
                />
            </div>
        </div>
    </div>
}