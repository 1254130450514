import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { IconProps } from './type';

const ICEyeSVG: React.FC = () => {
    return (
        <svg width="24" height="24" className="svgFillColor" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon / eye 1">
                <g id="Vector">
                    <path d="M15 12.8977C15 13.6934 14.6839 14.4564 14.1213 15.019C13.5587 15.5816 12.7957 15.8977 12 15.8977C11.2044 15.8977 10.4413 15.5816 9.87869 15.019C9.31609 14.4564 9.00002 13.6934 9.00002 12.8977C9.00002 12.1021 9.31609 11.339 9.87869 10.7764C10.4413 10.2138 11.2044 9.89771 12 9.89771C12.7957 9.89771 13.5587 10.2138 14.1213 10.7764C14.6839 11.339 15 12.1021 15 12.8977Z" fill="var(--icon-high-em)" />
                    <path d="M21.894 12.4507C19.736 8.13371 15.904 5.89771 12 5.89771C8.09702 5.89771 4.26402 8.13371 2.10602 12.4507C2.03666 12.5895 2.00055 12.7425 2.00055 12.8977C2.00055 13.0529 2.03666 13.2059 2.10602 13.3447C4.26402 17.6617 8.09601 19.8977 12 19.8977C15.903 19.8977 19.736 17.6617 21.894 13.3447C21.9634 13.2059 21.9995 13.0529 21.9995 12.8977C21.9995 12.7425 21.9634 12.5895 21.894 12.4507ZM12 17.8977C9.03102 17.8977 5.99802 16.2777 4.13002 12.8977C5.99802 9.5177 9.03001 7.89771 12 7.89771C14.969 7.89771 18.002 9.5177 19.87 12.8977C18.002 16.2777 14.969 17.8977 12 17.8977Z" fill="var(--icon-high-em)" />
                </g>
            </g>
        </svg>
    )
}

export const ICEye = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={ICEyeSVG} {...props} />
);

const EyeOffSVG: React.FC = () => {
    return (
        <svg width="24" height="24" className="svgFillColor" fill="var(--icon-high-em)" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.71 3.28994C4.61676 3.1967 4.50607 3.12274 4.38425 3.07228C4.26243 3.02182 4.13186 2.99585 4 2.99585C3.86814 2.99585 3.73758 3.02182 3.61575 3.07228C3.49393 3.12274 3.38324 3.1967 3.29 3.28994C3.1017 3.47825 2.99591 3.73364 2.99591 3.99994C2.99591 4.26624 3.1017 4.52164 3.29 4.70994L8.92 10.3399C8.56381 11.0026 8.43059 11.7623 8.54009 12.5066C8.64959 13.2509 8.99599 13.9401 9.52794 14.472C10.0599 15.004 10.7491 15.3503 11.4934 15.4599C12.2376 15.5694 12.9974 15.4361 13.66 15.0799L19.29 20.7099C19.383 20.8037 19.4936 20.8781 19.6154 20.9288C19.7373 20.9796 19.868 21.0057 20 21.0057C20.132 21.0057 20.2627 20.9796 20.3846 20.9288C20.5064 20.8781 20.617 20.8037 20.71 20.7099C20.8037 20.617 20.8781 20.5064 20.9289 20.3845C20.9797 20.2627 21.0058 20.132 21.0058 19.9999C21.0058 19.8679 20.9797 19.7372 20.9289 19.6154C20.8781 19.4935 20.8037 19.3829 20.71 19.2899L4.71 3.28994ZM12 13.4999C11.6022 13.4999 11.2206 13.3419 10.9393 13.0606C10.658 12.7793 10.5 12.3978 10.5 11.9999V11.9299L12.06 13.4899L12 13.4999Z" fill="var(--icon-high-em)" />
            <path d="M12.22 16.9999C7.92001 17.0999 5.10001 13.4099 4.22001 11.9999C4.84648 11.0006 5.59937 10.0863 6.46001 9.27987L5.00001 7.86987C3.87133 8.93334 2.90442 10.1563 2.13001 11.4999C2.04224 11.6519 1.99603 11.8243 1.99603 11.9999C1.99603 12.1754 2.04224 12.3479 2.13001 12.4999C2.76001 13.5899 6.13001 18.9999 12.02 18.9999H12.27C13.3776 18.967 14.4708 18.7403 15.5 18.3299L13.92 16.7499C13.3644 16.896 12.7942 16.9799 12.22 16.9999Z" fill="var(--icon-high-em)" />
            <path d="M21.87 11.4999C21.23 10.3899 17.7 4.81987 11.73 4.99987C10.6225 5.03273 9.52924 5.25949 8.5 5.66987L10.08 7.24987C10.6356 7.1037 11.2058 7.01985 11.78 6.99987C16.07 6.88987 18.89 10.5899 19.78 11.9999C19.1381 13.0022 18.3682 13.9165 17.49 14.7199L19 16.1299C20.1428 15.0692 21.1234 13.8461 21.91 12.4999C21.9918 12.3444 22.0311 12.1701 22.0241 11.9945C22.0171 11.819 21.9639 11.6484 21.87 11.4999Z" fill="var(--icon-high-em)" />
        </svg>
    )
}
export const ICEyeOff = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={EyeOffSVG} {...props} />
);

export const ICEyeTurnOn: React.FC<IconProps> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M11.2501 9C11.2501 9.59674 11.013 10.169 10.5911 10.591C10.1691 11.0129 9.59682 11.25 9.00009 11.25C8.40335 11.25 7.83105 11.0129 7.4091 10.591C6.98714 10.169 6.75009 9.59674 6.75009 9C6.75009 8.40326 6.98714 7.83097 7.4091 7.40901C7.83105 6.98705 8.40335 6.75 9.00009 6.75C9.59682 6.75 10.1691 6.98705 10.5911 7.40901C11.013 7.83097 11.2501 8.40326 11.2501 9Z" fill="var(--icon-high-em)"/>
            <path d="M16.4206 8.66475C14.8021 5.427 11.9281 3.75 9.00009 3.75C6.07284 3.75 3.19809 5.427 1.57959 8.66475C1.52757 8.76885 1.50049 8.88363 1.50049 9C1.50049 9.11637 1.52757 9.23115 1.57959 9.33525C3.19809 12.573 6.07209 14.25 9.00009 14.25C11.9273 14.25 14.8021 12.573 16.4206 9.33525C16.4726 9.23115 16.4997 9.11637 16.4997 9C16.4997 8.88363 16.4726 8.76885 16.4206 8.66475ZM9.00009 12.75C6.77334 12.75 4.49859 11.535 3.09759 9C4.49859 6.465 6.77259 5.25 9.00009 5.25C11.2268 5.25 13.5016 6.465 14.9026 9C13.5016 11.535 11.2268 12.75 9.00009 12.75Z" fill="var(--icon-high-em)"/>
        </svg>
    )
}

export const ICEyeTurnOff: React.FC<IconProps> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M3.53255 2.46764C3.46262 2.39771 3.3796 2.34224 3.28824 2.30439C3.19687 2.26655 3.09895 2.24707 3.00005 2.24707C2.90116 2.24707 2.80323 2.26655 2.71186 2.30439C2.6205 2.34224 2.53748 2.39771 2.46755 2.46764C2.32632 2.60887 2.24698 2.80041 2.24698 3.00014C2.24698 3.19987 2.32632 3.39141 2.46755 3.53264L6.69005 7.75514C6.4229 8.2521 6.32299 8.82192 6.40512 9.38013C6.48725 9.93833 6.74704 10.4552 7.146 10.8542C7.54497 11.2531 8.06186 11.5129 8.62007 11.5951C9.17827 11.6772 9.74809 11.5773 10.2451 11.3101L14.4676 15.5326C14.5373 15.6029 14.6202 15.6587 14.7116 15.6968C14.803 15.7349 14.901 15.7545 15.0001 15.7545C15.0991 15.7545 15.1971 15.7349 15.2885 15.6968C15.3799 15.6587 15.4628 15.6029 15.5326 15.5326C15.6028 15.4629 15.6586 15.38 15.6967 15.2886C15.7348 15.1972 15.7544 15.0991 15.7544 15.0001C15.7544 14.9011 15.7348 14.8031 15.6967 14.7117C15.6586 14.6203 15.6028 14.5374 15.5326 14.4676L3.53255 2.46764ZM9.00005 10.1251C8.70168 10.1251 8.41553 10.0066 8.20456 9.79563C7.99358 9.58466 7.87505 9.29851 7.87505 9.00014V8.94764L9.04505 10.1176L9.00005 10.1251Z" fill="var(--icon-high-em)"/>
            <path d="M9.16505 12.7501C5.94005 12.8251 3.82505 10.0576 3.16505 9.00014C3.6349 8.25066 4.19957 7.56499 4.84505 6.96014L3.75005 5.90264C2.90355 6.70024 2.17836 7.61747 1.59755 8.62514C1.53173 8.73915 1.49707 8.86849 1.49707 9.00014C1.49707 9.13179 1.53173 9.26112 1.59755 9.37514C2.07005 10.1926 4.59755 14.2501 9.01505 14.2501H9.20255C10.0332 14.2255 10.8531 14.0554 11.6251 13.7476L10.4401 12.5626C10.0233 12.6723 9.59569 12.7352 9.16505 12.7501Z" fill="var(--icon-high-em)"/>
            <path d="M16.4026 8.62514C15.9226 7.79264 13.2751 3.61514 8.79755 3.75014C7.96689 3.77478 7.14698 3.94485 6.37505 4.25264L7.56005 5.43764C7.97678 5.32801 8.40441 5.26512 8.83505 5.25014C12.0526 5.16764 14.1676 7.94264 14.8351 9.00014C14.3536 9.75186 13.7762 10.4376 13.1176 11.0401L14.2501 12.0976C15.1072 11.3021 15.8426 10.3848 16.4326 9.37514C16.4939 9.25851 16.5234 9.12778 16.5181 8.99612C16.5129 8.86445 16.473 8.7365 16.4026 8.62514Z" fill="var(--icon-high-em)"/>
        </svg>
    )
}
