import React from "react";
import { HRInput } from "../../components/form/input";
import { HRButton, IconButton } from "../../components/button";
import { ICDiscount } from "../../icons";
import { Modal } from "antd";
import { DiscountPayment } from "./discount";
import { useCandidateCreditPageContext } from ".";
import { setLoading } from "../../redux/slices/appInfo";
import { useDispatch } from "react-redux";
import { useNotification } from "../../hooks/useNotification";
import { formatMoney } from "../../utils";
import { TCandidateCreditRequest } from "./type";
import { getCandidateCreditPaymentUrl } from "../../api/payment";
import { ERROR_CODE } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import i18next from "../../i18n";

export const TotalPayment: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showError } = useNotification();
    const { discountCode, setDiscountCode, quantity, language, currentPaymentMethod, activeInvoice, candidatePayment, totalMoneyNotPaid, invoiceForm } = useCandidateCreditPageContext();
    const [showDiscountModal, setShowDiscountModal] = React.useState(false);

    const amountVAT = React.useMemo(() => {
        if (candidatePayment?.pricingOptions?.taxPercent) {
            return totalMoneyNotPaid * candidatePayment?.pricingOptions?.taxPercent / 100;
        }
        return 0;
    }, [candidatePayment, totalMoneyNotPaid]);

    const transactionFee = React.useMemo(() => {
        if (candidatePayment?.pricingOptions?.transactionFeePercent) {
            return totalMoneyNotPaid * candidatePayment?.pricingOptions?.transactionFeePercent / 100;
        }
        return 0;
    }, [candidatePayment, totalMoneyNotPaid]);

    const totalMoney = React.useMemo(() => {
        return totalMoneyNotPaid + amountVAT + transactionFee;
    }, [totalMoneyNotPaid, amountVAT, transactionFee]);

    const onSubmitPayment = React.useCallback(async () => {
        try {
            if (activeInvoice) {
                await invoiceForm?.validateFields();
            }
            dispatch(setLoading(true));
            const payload: TCandidateCreditRequest = {
                language,
                paymentMethod: currentPaymentMethod,
                hasInvoice: activeInvoice,
                unit: candidatePayment?.price?.unit as string,
                quantity: quantity as number,
            };
            if (activeInvoice) {
                const invoiceValues = invoiceForm?.getFieldsValue();
                payload.invoiceInfo = invoiceValues;
            }
            const result = await getCandidateCreditPaymentUrl(payload);
            if (result?.data?.url) {
                window.location.href = result.data.url;
            }
        }
        catch (error: any) {
            console.error(error);
            const errorCode = error?.response?.data?.errorCode || '';
            if(errorCode === ERROR_CODE.PAYMENT_NOT_SUPPORT) {
                showError("", i18next.t('payment.tryAnotherMethod'));
                return;
            }
            // if(errorCode === ERROR_CODE.PAYMENT_FAILED) {
            //     navigate(`/assessment/${id}/details`);
            //     return;
            // }
            const errorMessage = error?.response?.data?.errorMessage || error?.response?.statusText;
            errorMessage && showError("", errorMessage);
        } finally {
            dispatch(setLoading(false));
        }

    }, [dispatch, invoiceForm, activeInvoice, quantity, candidatePayment, currentPaymentMethod, language, showError]);

    return <div className="flex flex-col w-full gap-[16px]">
        <span className="text-title-bold text-high-em">{i18next.t('payment.pay')}</span>
        <div className="flex flex-col gap-[16px]" style={{
            boxShadow: "0px 6px 16px -6px rgba(17, 12, 34, 0.1)",
        }}>
            {/* <div className="grid grid-cols-[1fr_44px] gap-[16px]">
                <div className="flex flex-col w-full relative">
                    <HRInput placeholder="Nhập mã giảm giá" 
                        value={discountCode}
                        onChange={(e) => setDiscountCode(e.target.value)}
                        style={{
                            width: '100%',
                            paddingRight: '90px',
                            color: '#110C22',
                            fontSize: '14px',
                            lineHeight: '24px',
                            fontWeight: 700,
                        }} />
                    <span className="absolute right-[10px] top-[7px]">
                        <HRButton btnType="sub" btnSize="xs">
                            Áp dụng
                        </HRButton>
                    </span>
                </div>
                <IconButton btnType="outline" btnSize="md" icon={<ICDiscount />}
                    onClick={() => setShowDiscountModal(true)} />
            </div> */}
            <div className="bg-white rounded-[8px] p-[16px] flex flex-col gap-[16px]" style={{
                boxShadow: "0px 6px 16px -6px rgba(17, 12, 34, 0.1)",
                minHeight: '160px',
            }}>
                <div className="flex justify-between items-center">
                    <span className="text-body-medium text-high-em">{i18next.t('payment.provisional')}</span>
                    <span className="text-body-medium text-high-em">{formatMoney(totalMoneyNotPaid, candidatePayment?.price?.unit)}</span>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-body-medium text-med-em">{i18next.t('payment.tax')}</span>
                    <span className="text-body-medium text-med-em">{formatMoney(amountVAT, candidatePayment?.price?.unit)}</span>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-body-medium text-med-em">{i18next.t('payment.transactionFee')}</span>
                    <span className="text-body-medium text-med-em">{formatMoney(transactionFee, candidatePayment?.price?.unit)}</span>
                </div>
                <div className="flex w-full bg-[#ECECED] h-[1px]"></div>
                <div className="flex justify-between items-center">
                    <span className="text-body-medium text-high-em">{i18next.t('payment.total')}</span>
                    <span className="text-primary text-title-bold">{formatMoney(totalMoney, candidatePayment?.price?.unit)}</span>
                </div>

                <div className="flex flex-col gap-[16px]">
                    <HRButton btnType="danger"
                        btnSize="lg"
                        onClick={onSubmitPayment}>
                        {i18next.t('payment.pay')}
                    </HRButton>
                </div>
            </div>
        </div>
        <Modal
            centered
            onCancel={() => setShowDiscountModal(false)}
            open={showDiscountModal}
            title={null}
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="outline"
                        btnSize="sm"
                        onClick={() => setShowDiscountModal(false)}>
                        {i18next.t('payment.cancel')}
                    </HRButton>
                    <HRButton
                        onClick={() => {
                            setShowDiscountModal(false);
                        }}
                        btnSize="sm">
                        {i18next.t('payment.confirm')}
                    </HRButton>
                </div>
            }>
                <DiscountPayment />
        </Modal>
    </div>
}
