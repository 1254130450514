import { Card, Modal, Row, Switch, Table, Tag } from "antd";
import { HRButton } from "../../../components/button";
import { HRInput } from "../../../components/form/input";
import {
  ICCreateLink,
  ICCopy,
  ICSort,
  ICSendV2,
  ICNoneData,
  ICInviteEmail,
} from "../../../icons";
import { useCopyToClipboard } from "../../../hooks/useCopyToClipboard";
import React from "react";
import { ColumnType, ColumnsType } from "antd/lib/table";
import { formatLocaleDateString } from "../../../utils/date";
import { SortOrder } from "antd/lib/table/interface";
import { IPagination } from "../../library/models";
import { HRPagination } from "../../../components/pagination";
import { TSortData } from "./candidate-list";
import { getCVinAssessment } from "../../../api/assessment-cv";
import { useAssessment } from "../create-assessment/hooks/useAssessment";
import { EStatusAssessment, TCandidate, TCandidateRequest } from "../type";
import { useAssementDetailsData } from ".";
import { ConfigCVAssessment } from "./configCV";
import { useNotification } from "../../../hooks/useNotification";
import { TAssessment } from "../create-assessment/type";
import i18next from "../../../i18n";

type AssessmentCVProps = {
  assessmentId: string;
  isActive?: boolean;
  onToggleActive: (active: boolean) => void;

  isActiveReceiveEmail?: boolean;
  onToggleActiveReceiveEmail: (active: boolean) => void;

  onReloadCandiateList: () => void;
  setCandidateList: React.Dispatch<React.SetStateAction<TCandidate[]>>;
  assessmentDetails: TAssessment;
};
const getPublicAssessmentUrl = (id: string) =>
  window.location.origin + "/apply-resume/" + id;
export const AssessmentCV: React.FC<AssessmentCVProps> = ({
  isActive = false,
  assessmentId,
  onToggleActive,
  isActiveReceiveEmail,
  onToggleActiveReceiveEmail,
  onReloadCandiateList,
  setCandidateList,
  assessmentDetails,
}) => {
  const { showSuccess } = useNotification();
  const publicUrl = React.useMemo(
    () => getPublicAssessmentUrl(assessmentId),
    [assessmentId]
  );
  const { copy } = useCopyToClipboard();
  const handleCopy = React.useCallback(() => {
    copy(publicUrl);
    showSuccess(i18next.t("assessmentDetail.copiedSuccess"));
  }, [copy, publicUrl, showSuccess]);
  const handleActiveChanged = () => {
    onToggleActive(!isActive);
  };
  const handleActiveReceiveEmailChanged = () => {
    onToggleActiveReceiveEmail(!isActiveReceiveEmail);
  };
  const disabledActions = React.useMemo(() => {
    return (
      assessmentDetails?.status === EStatusAssessment.ARCHIVED ||
      assessmentDetails?.status === EStatusAssessment.LOCKED
    );
  }, [assessmentDetails?.status]);

  return (
    <div>
      {!disabledActions && (
        <div className="grid grid-cols-1 gap-[32px]">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-[24px]">
            <Card
              bodyStyle={{
                padding: "16px",
              }}
              style={{
                borderRadius: "8px",
                border: "1px solid #BEC0CA",
              }}
            >
              <div className="grid grid-cols-[56px_1fr_50px] gap-[16px] items-center">
                <ICCreateLink />
                <span className="flex flex-col gap-[4px]">
                  <span className="text-[#46485C] font-bold leading-[22px] text-[18px]">
                    {i18next.t("assessmentDetail.createLink")}
                  </span>
                  <span className="text-[#767888] text-[14px] font-normal leading-[17px]">
                    {i18next.t("assessmentDetail.publicCVLink")}
                  </span>
                </span>
                <Switch
                  checked={isActive}
                  style={{
                    width: "36px",
                  }}
                  onChange={handleActiveChanged}
                />
              </div>
            </Card>
            <Card
              bodyStyle={{
                padding: "16px",
              }}
              style={{
                borderRadius: "8px",
                border: "1px solid #BEC0CA",
              }}
            >
              <ConfigCVAssessment />
            </Card>
            <Card
              bodyStyle={{
                padding: "16px",
              }}
              style={{
                borderRadius: "8px",
                border: "1px solid #BEC0CA",
              }}
            >
              <div className="grid grid-cols-[56px_1fr_50px] gap-[16px] items-center">
                <ICInviteEmail />
                <span className="flex flex-col gap-[4px]">
                  <span className="text-[#46485C] font-bold leading-[22px] text-[18px]">
                    {i18next.t("assessmentDetail.notiEmail")}
                  </span>
                  <span className="text-[#767888] text-[14px] font-normal leading-[17px]">
                    {i18next.t("assessmentDetail.autoSendCVNoti")}
                  </span>
                </span>
                <Switch
                  checked={isActiveReceiveEmail}
                  style={{
                    width: "36px",
                  }}
                  onChange={handleActiveReceiveEmailChanged}
                />
              </div>
            </Card>
          </div>
          {isActive && (
            <div className="grid grid-cols-[1fr_auto] gap-[16px] items-center justify-end">
              <div className="grid grid-cols-1">
                <HRInput
                  value={publicUrl}
                  disabled={true}
                  style={{
                    color: "#3D6AC4",
                  }}
                />
              </div>
              <HRButton btnType="info" onClick={handleCopy} btnSize="md">
                <div className="flex space-x-2 items-center justify-center">
                  <ICCopy fill="white" width={18} height={18} />
                  <span>{i18next.t("assessmentDetail.copy")}</span>
                </div>
              </HRButton>
            </div>
          )}
        </div>
      )}
      <CVList
        assessmentId={assessmentId}
        onReloadCandiateList={onReloadCandiateList}
        setCandidateList={setCandidateList}
        disabledActions={disabledActions}
      />
    </div>
  );
};

type CVListProps = {
  assessmentId: string;
  disabledActions?: boolean;
  onReloadCandiateList: () => void;
  setCandidateList: React.Dispatch<React.SetStateAction<TCandidate[]>>;
};
export type TCVItem = {
  _id: string;
  assessment: string;
  name: string;
  email: string;
  phoneNumber: string;
  attachment: string;
  createdAt: string;
  hasInvited: boolean;
};
type TSortDataContext = {
  sortData: TSortData;
  setSortData: (data: TSortData) => void;
};
const SortDataContext = React.createContext<TSortDataContext>(
  {} as TSortDataContext
);
export const useSortData = () => React.useContext(SortDataContext);
const CVList: React.FC<CVListProps> = ({
  assessmentId,
  disabledActions,
  onReloadCandiateList,
  setCandidateList,
}) => {
  const { inviteCandidate } = useAssessment();
  const { setAssessmentDetails, cvs, setCvs } = useAssementDetailsData();

  const [showConfirmInvite, setShowConfirmInvite] =
    React.useState<boolean>(false);
  const [cvSelected, setCvSelected] = React.useState<TCVItem | null>(null);
  const [pagination, setPagination] = React.useState<IPagination>({
    limit: 10,
    page: 1,
    totalDocuments: 0,
    totalPages: 0,
  });
  const [sortData, setSortData] = React.useState<TSortData>({
    field: "createdAt",
    order: "desc",
  });
  const query = React.useMemo(() => {
    const formatSortData = `${sortData.field}::${sortData.order}`;
    return {
      orderBy: formatSortData,
      page: pagination.page,
    };
  }, [pagination.page, sortData]);

  const getCV = React.useCallback(() => {
    if (!assessmentId) return;
    getCVinAssessment(assessmentId, query).then((res) => {
      setCvs(res.data.data);
      setPagination(res.data.metaData);
    });
  }, [assessmentId, query]);

  React.useEffect(() => {
    getCV();
  }, []);

  const onChangePaging = React.useCallback(
    (page: number, pageSize?: number) => {
      setPagination((prev) => ({
        ...prev,
        page,
      }));
    },
    []
  );

  const reloadData = React.useCallback(() => {
    onReloadCandiateList();
    getCV();
  }, [onReloadCandiateList, getCV]);

  const onSubmitInviteEmail = React.useCallback(() => {
    setShowConfirmInvite(false);
    const payload: TCandidateRequest = {
      email: (cvSelected?.email || "").trim(),
      lastName: "",
      firstName: (cvSelected?.name || "").trim(),
    };
    inviteCandidate(
      assessmentId,
      payload,
      setCandidateList,
      setAssessmentDetails,
      reloadData
    );
  }, [
    assessmentId,
    cvSelected,
    inviteCandidate,
    reloadData,
    setAssessmentDetails,
    setCandidateList,
  ]);

  const columns: ColumnsType<TCVItem> = [
    {
      title: i18next.t("assessmentDetail.cvName"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (name: string) => {
        return <div className="max-w-[300px] truncate">{name}</div>;
      },
    },
    {
      title: i18next.t("assessmentDetail.cvEmail"),
      dataIndex: "email",
      key: "email",
      render: (email: string) => {
        return <div className="max-w-[200px] truncate">{email}</div>;
      },
    },
    {
      title: i18next.t("assessmentDetail.cvPhoneNumber"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: i18next.t("assessmentDetail.cvSendedAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (createdAt: string) => {
        return (
          <div className="pr-2">
            <span>{formatLocaleDateString(new Date(createdAt).valueOf())}</span>
          </div>
        );
      },
    },
    {
      title: i18next.t("assessmentDetail.cvAttachment"),
      dataIndex: "attachment",
      key: "attachment",
      width: "135px",
      render: (attachment: string) => {
        return (
          <div className="text-left pr-2 w-[147px]">
            {attachment ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_S3_URL}/${attachment}`}
                download={attachment.split("/").pop() || ""}
              >
                {i18next.t("assessmentDetail.download")}
              </a>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div className="text-center">
          {i18next.t("assessmentDetail.inviteTest")}
        </div>
      ),
      dataIndex: "hasInvited",
      key: "hasInvited",
      render: (hasInvited: boolean, record: any) => {
        return (
          <div className="text-center">
            {hasInvited ? (
              <Tag color="success">{i18next.t("assessmentDetail.invited")}</Tag>
            ) : (
              <HRButton
                onClick={() => {
                  setCvSelected(record);
                  setShowConfirmInvite(true);
                }}
                disabled={disabledActions}
                btnType="primary"
                btnSize="md"
              >
                <div className="flex space-x-2 items-center justify-center">
                  <ICSendV2
                    fill={disabledActions ? "var(--icon-disabled)" : "white"}
                    width={18}
                    height={18}
                  />
                </div>
              </HRButton>
            )}
          </div>
        );
      },
    },
  ].map((col: ColumnType<TCVItem>) => {
    if (col.sorter) {
      const oldTitle = col.title;
      col.title = ({ sortColumns }) => {
        const sortedColumn = sortColumns?.find(
          ({ column }) => column.key === col.key
        );
        const columnSort = col.dataIndex as string;
        return (
          <SortableColumn
            title={oldTitle as string}
            order={sortedColumn?.order}
            name={columnSort}
          />
        );
      };
    } else {
      col.title = (
        <div className="font-bold text-base">{col.title as string}</div>
      );
    }
    col.showSorterTooltip = false;
    return col;
  });
  return (
    <div className="my-4">
      <div className="text-left font-bold text-[20px] mb-4">
        {i18next.t("assessmentDetail.listCV")}
      </div>
      <SortDataContext.Provider
        value={{
          sortData,
          setSortData,
        }}
      >
        <Table
          className="white-header overflow-x-auto"
          columns={columns}
          dataSource={cvs}
          rowClassName="cursor-pointer data-row"
          rowKey="_id"
          locale={{
            emptyText: (
              <div className="my-6 flex flex-col gap-[32px]">
                <div className="flex justify-center">
                  <ICNoneData />
                </div>
                <div className="text-standard-bold text-high-em">
                  {i18next.t("assessmentDetail.emptyCV")}
                </div>
              </div>
            ),
          }}
          pagination={false}
        />
      </SortDataContext.Provider>
      {pagination && pagination.totalPages > 1 && (
        <Row
          gutter={16}
          className="mt-3 grid justify-center lg:items-center justify-end w-full"
        >
          <HRPagination
            onChange={onChangePaging}
            defaultCurrent={pagination?.page}
            defaultPageSize={pagination?.limit}
            total={pagination?.totalDocuments}
            currentPage={pagination?.page}
            showSizeChanger={false}
          />
        </Row>
      )}
      <Modal
        centered
        onCancel={() => setShowConfirmInvite(false)}
        open={showConfirmInvite}
        title={
          <div className="font-bold text-[18px]">
            {i18next.t("assessmentDetail.inviteCandidate")}
          </div>
        }
        footer={
          <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
            <HRButton
              btnType="sub"
              btnSize="sm"
              onClick={() => setShowConfirmInvite(false)}
            >
              {i18next.t("assessmentDetail.cancel")}
            </HRButton>
            <HRButton
              btnSize="sm"
              btnType="primary"
              onClick={onSubmitInviteEmail}
            >
              {i18next.t("assessmentDetail.confirm")}
            </HRButton>
          </div>
        }
      >
        <p className="mb-0">{i18next.t("assessmentDetail.cannotUndo")}</p>
        <p>{i18next.t("assessmentDetail.sureToInviteCandidate")}</p>
      </Modal>
    </div>
  );
};

type SortableColumnProps = {
  title: string;
  order?: SortOrder;
  name: string;
};
const SortableColumn: React.FC<SortableColumnProps> = ({
  title,
  order,
  name,
}) => {
  const { setSortData } = useSortData();
  return (
    <div
      className="flex space-x-3 items-center select-none min-w-[100px] group"
      onClick={() => {
        setSortData({
          field: order === "descend" ? "createdAt" : name,
          order: order === "descend" ? "desc" : !order ? "asc" : "desc",
        });
      }}
    >
      <span className="text-body-bold text-high-em">{title as string}</span>
      <ICSort order={order} />
    </div>
  );
};
