import React from "react";
import { HRButton } from "../../../components/button";
import { ICNext } from "../../../icons";
import { useAssementDetailsData } from ".";
import { retrievePaymentUrl } from "../../../api/payment";
import { setLoading } from "../../../redux/slices/appInfo";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EStatusAssessment } from "../type";
import i18next from "../../../i18n";

export const PurchaseAssessment: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { assessmentId, assessmentDetails, waitingOrderPayment } = useAssementDetailsData();
    const countTestingNeedPay = React.useMemo(() => {
        return assessmentDetails?.tests?.filter((item) => item?.price !== null)?.length;
    }, [assessmentDetails]);

    const isPaiding = assessmentDetails?.status === EStatusAssessment.PAIDING;
    const onPaymentClicked = React.useCallback(async () => {
        if (!waitingOrderPayment?._id) {
            navigate(`/payment/${assessmentId}`);
            return;
        };
        try {
            dispatch(setLoading(true));
            const { data } = await retrievePaymentUrl(waitingOrderPayment._id);
            dispatch(setLoading(false));
            if (data?.url) {
                window.open(data?.url, '_blank');
            }
        } catch (e) {
            dispatch(setLoading(false));
            console.error(e);
        }
    }, [dispatch, navigate, assessmentId, waitingOrderPayment]);

    return <div className="grid grid-cols-1 gap-[32px]">
        <div className="flex flex-row gap-[32px] items-center justify-start">
            <div className="hidden lg:grid grid-cols-1 gap-[16px]">
                {!isPaiding && <img src="/images/purchase.png" alt="logo" className="h-[200px] w-[200px]" />}
                {isPaiding && <img src="/images/purchase-order.jpg" alt="logo" />}
            </div>
            <div className="flex flex-col gap-[24px] items-start">
                <div className="flex flex-col gap-[8px]">
                    <h2 className="mb-0 text-high-em text-heading-6-bold">
                        {isPaiding ? i18next.t('assessmentDetail.testWaitingForPurchase') : i18next.t('assessmentDetail.beforeInviteCandidate')}
                    </h2>
                    {!isPaiding && <span className="flex flex-col text-high-em text-body">
                        <p className="mb-0">
                            {i18next.t('assessmentDetail.assessmentHasPaidTest', {t: countTestingNeedPay})}
                        </p>
                        <p className="mb-0">
                            {i18next.t('assessmentDetail.purchaseToInvite')}
                        </p>
                    </span>}
                    {isPaiding && <span className="flex flex-col text-high-em text-body">
                        <p className="mb-0">
                            {i18next.t('assessmentDetail.cannotEditWhilePurchase')}
                        </p>
                        <p className="mb-0">
                            {i18next.t('assessmentDetail.afterPurchaseSuccess')}
                        </p>
                    </span>}
                </div>
                <div className="flex flex-col min-w-[277px]">
                    <HRButton 
                        btnType="primary"
                        btnSize="lg"
                        onClick={onPaymentClicked}>
                        <div className="flex items-center justify-center gap-[10px]">
                            <span>{i18next.t('assessmentDetail.goToPurchase')}</span>
                            <ICNext />
                        </div>
                    </HRButton>
                </div>
            </div>
        </div>
    </div>
}
