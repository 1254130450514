import { useState } from 'react';
import { ICScreen, ICNotification, ICLogout, ICCredit, ICUserV2, ICNotificationV2, ICBrand } from "../../icons/index";
import classNames from 'classnames'
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../api/auth";
import { removeUserInfo } from "../../redux/actions";
import { setLoading } from "../../redux/slices/appInfo"

import { Modal } from 'antd';
import { useGeneralPageData } from '../general';
import { HRButton } from '../../components/button';
import i18next from '../../i18n';

const HEADER_TABS = [
    {
        icon: (isActive: boolean = false) => <ICUserV2 width={24} height={24} fill={isActive ? "#7357FF" : "#8D8A95"} />,
        title: i18next.t('home.myProfile'),
        path: "/profile",
    },
    {
        icon: (isActive: boolean = false) => <ICCredit fill={isActive ? "#7357FF" : "#8D8A95"} />,
        title: i18next.t('home.account'),
        path: "/account",
    },
    {
        icon: (isActive: boolean = false) => <ICBrand fill={isActive ? "#7357FF" : "#8D8A95"} />,
        title: i18next.t('home.brandEstablishment'),
        path: "/company",
    },
    {
        icon: (isActive: boolean = false) => <ICNotification fill={isActive ? "#7357FF" : "#8D8A95"} />,
        title: i18next.t('home.notification'),
        path: null,
    },
    {
        icon: (isActive: boolean = false) => <ICScreen fill={isActive ? "#7357FF" : "#8D8A95"} />,
        title: i18next.t('home.device'),
        path: "/devices",
    },
]
type HomeHeaderProps = {
    activedIndex?: number;
}
export const HomeHeader: React.FC<HomeHeaderProps> = ({
    activedIndex = 0
}) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { setShowComingSoonModal } = useGeneralPageData();
    const onLogoutUser = async () => {
        dispatch(setLoading(true));
        try {
            await logout();
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    }

    const onLogout = () => {
        setOpen(false);
        onLogoutUser();
        dispatch(removeUserInfo());
        navigate("/login");
    };
    const showConfirmModal = () => {
        setOpen(true);
    };
    const hideConfirmModal = () => {
        setOpen(false);
    };

    return (
        <div className="border-b-2 border-[#ECECED]">
            <div className="hidden lg:flex container mx-auto justify-between">
                <div className="flex space-x-6 flex-wrap">
                    {
                        HEADER_TABS.map((tab, index) => {
                            return (
                                <div key={index} className="flex items-center h-full">
                                    {tab?.path && <Link to={tab.path} key={index}>
                                        <div
                                            className={
                                                classNames(
                                                    "hover:text-primary flex space-x-2 items-center cursor-pointer pb-2 border-b-[3px] hover-icon hover:border-outline-primary text-standard-medium",
                                                    index === activedIndex ? "border-outline-primary text-primary" : "border-transparent text-med-em"
                                                )
                                            }
                                        >
                                            {tab.icon(index === activedIndex)}
                                            <div>{tab.title}</div>
                                        </div>
                                    </Link>}
                                    {!tab?.path && <div 
                                        className={
                                            classNames(
                                                "cursor-pointer",
                                                "hover:text-primary flex space-x-2 items-center cursor-pointer pb-2 border-b-[3px] hover-icon hover:border-outline-primary text-standard-medium",
                                                index === activedIndex ? "border-outline-primary text-primary" : "border-transparent text-med-em"
                                            )
                                        }>
                                        {tab.icon(index === activedIndex)}
                                        <span onClick={() => setShowComingSoonModal(true)}>
                                            {tab.title}
                                        </span>
                                    </div>}
                                </div>
                            );
                        })
                    }
                </div>
                <div>
                    <div className="flex cursor-pointer px-3 space-x-2 hover-icon text-med-em hover:text-primary" onClick={showConfirmModal}>
                        <ICLogout />
                        <span className="text-standard-medium">{i18next.t('home.logOut')}</span>
                    </div>
                    <Modal
                        title={i18next.t('home.notification')}
                        open={open}
                        centered={true}
                        onOk={onLogout}
                        onCancel={hideConfirmModal}
                        okText={i18next.t('home.yes')}
                        cancelText={i18next.t('home.no')}
                        footer={
                            <div className="grid grid-cols-[auto_auto] justify-end gap-[10px]">
                                <HRButton
                                    btnSize="sm"
                                    btnType="sub"
                                    onClick={hideConfirmModal}>
                                    {i18next.t('home.no')}
                                </HRButton>
                                <HRButton btnSize="sm" onClick={onLogout}>
                                    {i18next.t('home.yes')}
                                </HRButton>
                            </div>
                        }
                    >
                        <p>{i18next.t('home.sureToLogOut')}</p>
                    </Modal>
                </div>
            </div>
        </div>
    )
}
