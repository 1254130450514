import { Checkbox, CheckboxProps } from "antd"

type HRCheckBoxProps = CheckboxProps & {

}
export const HRCheckBox: React.FC<HRCheckBoxProps> = ({ style, ...props }) => {
    return (
        <Checkbox className="purple-checkbox" {...props} style={{
            fontSize: "12px",
            fontWeight: 500,
            ...style,
        }} />
    )
}
