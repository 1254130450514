import React from "react"
import { Typography } from 'antd';
import { ICClose, ICFavored, ICCheckboxList, ICBriefcase, ICClockTime, ICStarOutline, ICGlobeAlt, ICLevel, ICCheckCompleted, ICLogo } from "../../icons/index";
import { HRButton, IconButton } from "../../components/button"
import { ILibrary, TRatingLevel } from './models';
import { LibraryItemDetails } from './components/item_details';
import { useGeneralPageData } from "../general";
import { Iframe } from "../../components/iframe";
import i18next from "../../i18n";
import { useMobile } from "../../hooks/useDimensions";

const { Title } = Typography;

type LibraryDetailsProps = {
    data?: ILibrary;
    ratingLevel: TRatingLevel[] | undefined;
    setOpen: (value: boolean) => void
}
export const LibraryDetails: React.FC<LibraryDetailsProps> = ({
    data = {} as ILibrary,
    ratingLevel,
    setOpen
}) => {
    const [ratingLevelSelected, setRatingLevelSelected] = React.useState<string | undefined>('');
    const { setShowComingSoonModal } = useGeneralPageData();
    // const hasTestSuponsor = data?.isPublic === true;

    React.useEffect(() => {
        // hasTestSuponsor && 
        if (ratingLevel && ratingLevel?.length > 0) {
            const ratingLevelSelected = ratingLevel?.filter(item => item.id === data?.ratingLevel);
            if (ratingLevelSelected) {
                setRatingLevelSelected(ratingLevelSelected[0]?.label);
            }
        }
    }, []);

    const itemDetails = [
        {
            icon: <ICCheckboxList />,
            title: i18next.t('library.type'),
            name: 'test_type',
            value: data?.type
        },
        {
            icon: <ICClockTime />,
            title: i18next.t('library.duration'),
            value: i18next.t('library.time', {t: Array.isArray(data?.configuration?.questionDuration)
                    ? data?.configuration?.questionDuration.reduce((acc, cur) => acc + cur, 0)
                    : data?.configuration?.questionDuration ?? 0})
        },
        {
            icon: <ICGlobeAlt />,
            title: i18next.t('library.language'),
            name: 'language',
            value: data?.language
        },
        {
            icon: <ICLevel />,
            title: i18next.t('library.level'),
            // name: hasTestSuponsor ? undefined : 'hard_level',
            // value: hasTestSuponsor ? ratingLevelSelected : data?.level
            name: undefined,
            value: ratingLevelSelected
        },
        {
            icon: <ICStarOutline />,
            title: i18next.t('library.avgRate'),
            value: data?.avg_rate
        },
        {
            icon: <ICBriefcase fill="#7357FF" />,
            title: i18next.t('library.industry'),
            name: 'test_field',
            value: data?.industry
        }
    ];

    const avatar = React.useMemo(() => {
        return "/images/logo-company.jpg"
        // return data.createdBy ? getURL(data.createdBy.avatar) : "/images/logo-company.jpg"
    }, []);

    const renderSkillsRequired = React.useMemo(() => {
        if (data?.skillsRequired?.length) {
            return data?.skillsRequired.map((item, index) => {
                return <span key={index} className="grid grid-cols-[22px_auto] gap-[9px] items-center pl-[16px]">
                    <ICCheckCompleted />
                    <span>{item}</span>
                </span>
            });
        }
        return null;
    }, [data]);

    const onPreview = React.useCallback(() => {
        window.open(`/testing/${data.id}/preview`, '_blank');
    }, [data]);
    const isMobile = useMobile();
    return (
        <div className="relative grid grid-cols-[1fr] items-center gap-[32px]">
            <div className="grid grid-cols-[auto_auto] justify-between items-center w-full">
                <span className="grid grid-cols-[auto_auto] gap-[16px] justify-between w-full">
                    {data?.previewSection && data?.previewSection?.length > 0 && <HRButton
                        onClick={onPreview}
                        btnSize="md">
                        {i18next.t('library.trySample')}
                    </HRButton>}
                    <IconButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={() => setShowComingSoonModal(true)}
                        icon={<ICFavored />}
                    />
                </span>
                <span>
                    <HRButton btnSize="md" btnType="sub"
                        onClick={() => setOpen(false)}>
                        <div className="flex gap-[8px] items-center">
                            <ICClose width={18} height={18} />
                            <span>{i18next.t('library.close')}</span>
                        </div>
                    </HRButton>
                </span>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-[32px] flex-wrap">
                <div>
                    <div className="grid grid-cols-[1fr] gap-[16px] flex-wrap">
                        <Title level={3} className="" style={{
                            color: '##2E3046',
                            margin: 0,
                            padding: 0,
                            fontWeight: 700,
                            lineHeight: '29px'
                        }}>{data?.title}</Title>

                        <Title level={5} className="" style={{
                            margin: 0,
                            padding: 0,
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {data?.summary}
                        </Title>

                        <div className="grid grid-cols-2 lg:grid-cols-4 flex-wrap gap-y-[16px]">
                            {itemDetails?.map((item, index) => {
                                return <LibraryItemDetails
                                    key={`${index}-${item.value}`}
                                    roundedLeft={isMobile ? index % 2 === 0 : index % 4 === 0}
                                    roundedRight={(isMobile ? index % 2 === 1 : index % 4 === 3) || index === itemDetails.length - 1}
                                    name={item.name}
                                    value={item.value}
                                    icon={item.icon}
                                    title={item.title}
                                />
                            })}
                        </div>

                        <div className="grid grid-cols-1 flex-wrap gap-[16px] pt-[16px]">
                            <Title level={3} className="" style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '14px',
                                fontWeight: 700
                            }}>
                                {i18next.t('library.skillRequired')}
                            </Title>
                            {renderSkillsRequired}
                        </div>

                        <div className="grid grid-cols-1 flex-wrap gap-[16px] pt-[16px]">
                            <Title level={3} className="" style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '14px',
                                fontWeight: 700
                            }}>
                                {i18next.t('library.relevant')}
                            </Title>
                            <span>{data?.relevant}</span>
                        </div>

                    </div>

                </div>
                <div className="grid">
                    <div className="grid grid-cols-[1fr] gap-[16px] grid-rows-[auto_auto_1fr] flex-wrap">
                        <Title level={5} className="" style={{
                            color: '##2E3046',
                            margin: 0,
                            padding: 0,
                            fontWeight: 700,
                            lineHeight: '29px',
                            fontSize: '14px'
                        }}>{i18next.t('library.description')}</Title>
                        <div className="pr-[48px]">
                            <Iframe content={data?.description as string || ''} />
                        </div>

                        <div className="grid grid-cols-1 grid-rows-[auto_1fr] flex-wrap gap-[16px] pt-[16px]">
                            <Title level={3} className="" style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '14px',
                                fontWeight: 700
                            }}>
                                {i18next.t('library.introduceExpert')}
                            </Title>
                            <span className="grid grid-cols-[90px_auto] gap-[16px]">
                                <span className="h-[90px] w-[90px] rounded-[10px] border border-solid boder-[#ededed]">
                                    <ICLogo 
                                        width={90}
                                        height={90}
                                    />
                                </span>
                                <span className="grid grid-rows-[auto_1fr] gap-[8px]">
                                    <Title level={3} className="" style={{
                                        margin: 0,
                                        padding: 0,
                                        fontSize: '14px',
                                        fontWeight: 700
                                    }}>
                                        {/* {data?.createdBy?.name} */}
                                        {i18next.t('library.husa')}
                                    </Title>
                                    <span className="flex pr-[48px]">
                                        {i18next.t('library.aboutHusa')}
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
