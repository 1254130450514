
export const NotSupportedPage: React.FC = () => {
    return <div className="flex flex-row items-center p-8">
        <div className="flex flex-col gap-[40px]">
            <img src="/images/developing.jpg" alt="Not Supported" />
            <div className="flex flex-col gap-[16px]">
                <h4 className="text-[32px] font-bold leading-[40px] text-center text-high-em mb-0">Tính năng đang phát triển</h4>
                <p className="text-[#676472] text-[16px] font-normal leading-[24px] mb-0">
                    <p>Our mobile app is currently under development and refinement. Please use our desktop version for the best experience</p>
                </p>
            </div>
        </div>
    </div>
}