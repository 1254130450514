import { IconProps } from "./type"

export const ICStopWatch: React.FC<IconProps> = ({
    fill = "var(--text-primary)",
    width = 24,
    height = 24,
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path d="M12.5403 4.80756V3.7938H13.9973V1.86283H9.14059V3.7938H10.5976V4.80756C6.22655 5.2903 2.82684 8.95915 2.82684 13.4487C2.82684 18.2519 6.73651 22.138 11.569 22.138C16.4014 22.138 20.3111 18.2519 20.3111 13.4487C20.3111 8.98329 16.9114 5.2903 12.5403 4.80756ZM11.569 21.1725C7.29503 21.1725 3.79818 17.6968 3.79818 13.4487C3.79818 9.20052 7.29503 5.72477 11.569 5.72477C15.8429 5.72477 19.3397 9.20052 19.3397 13.4487C19.3397 17.6968 15.8429 21.1725 11.569 21.1725Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.14059 3.7938V1.86283H13.9973V3.7938H12.5403V4.80756C16.9114 5.2903 20.3111 8.98329 20.3111 13.4487C20.3111 18.2519 16.4014 22.138 11.569 22.138C6.73651 22.138 2.82684 18.2519 2.82684 13.4487C2.82684 8.95915 6.22655 5.2903 10.5976 4.80756V3.7938H9.14059ZM10.1712 4.2162H8.71415V1.44043H14.4238V4.2162H12.9668V4.43762C17.3685 5.10971 20.7375 8.89697 20.7375 13.4487C20.7375 18.4838 16.6383 22.5604 11.569 22.5604C6.49959 22.5604 2.40039 18.4838 2.40039 13.4487C2.40039 8.87281 5.7701 5.10832 10.1712 4.43757V4.2162ZM4.22463 13.4487C4.22463 17.4649 7.53195 20.7501 11.569 20.7501C15.606 20.7501 18.9133 17.4649 18.9133 13.4487C18.9133 9.43242 15.606 6.14717 11.569 6.14717C7.53195 6.14717 4.22463 9.43242 4.22463 13.4487ZM3.79818 13.4487C3.79818 17.6968 7.29503 21.1725 11.569 21.1725C15.8429 21.1725 19.3397 17.6968 19.3397 13.4487C19.3397 9.20052 15.8429 5.72477 11.569 5.72477C7.29503 5.72477 3.79818 9.20052 3.79818 13.4487Z" fill={fill} />
            <path d="M5.81195 13.2676C5.81195 16.5429 8.47559 19.1812 11.7822 19.1812V7.35403C8.47559 7.35403 5.81195 9.9924 5.81195 13.2676Z" fill={fill} />
            <path d="M18.5627 3.76363L17.326 4.98859C18.5627 5.70667 19.6075 6.74155 20.3324 7.96651L21.5691 6.74155C20.8015 5.53771 19.7567 4.52395 18.5627 3.76363Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.5334 3.49393L18.678 3.58597C19.8965 4.36191 20.9641 5.39727 21.7494 6.62877L21.8404 6.77151L20.288 8.30915L20.1485 8.07332C19.4419 6.87952 18.4234 5.8706 17.2181 5.17079L16.9801 5.03255L18.5334 3.49393ZM17.326 4.98859C17.3891 5.02527 17.4518 5.06277 17.5139 5.10108C18.6064 5.77457 19.5389 6.69822 20.2188 7.78032C20.2575 7.84188 20.2954 7.90394 20.3324 7.96651L21.5691 6.74155C20.8015 5.53771 19.7567 4.52395 18.5627 3.76363L17.326 4.98859Z" fill={fill} />
        </svg>
    )
}
