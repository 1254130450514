export const GET_STARTED_MESSAGE = `Let's create a test to assess the [Example: Multi Tasking] skill.
Here are the detailed requirements for the test:
- Objective: Assess the candidate's competency.
- Number of questions: 30 questions.
- Difficulty: High, focusing on complex situations for senior management candidates.
- Question type: Multiple choice.
- Time limit: 45 minutes.
`;

export const GET_STARTED_MESSAGE_VI = `Hãy tạo một bài kiểm tra nhằm đánh giá kỹ năng [Ví dụ: Multi Tasking]. 
Dưới đây là các yêu cầu chi tiết cho bài kiểm tra:
 - Mục tiêu: Đánh giá năng lực của ứng viên.
 - Số lượng câu hỏi: 30 câu.
 - Độ khó: Cao, tập trung vào các tình huống phức tạp dành cho ứng viên quản lý cấp cao.
 - Loại câu hỏi: Trắc nghiệm.
 - Thời gian làm bài: 45 phút.`;
