import { IconProps } from "./type"

export const ICUnccess: React.FC<IconProps> = () => {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8044_13495)">
            <path fillRule="evenodd" clipRule="evenodd" d="M100 50C100 77.615 77.615 100 50 100C22.385 100 0 77.615 0 50C0 22.385 22.385 0 50 0C77.615 0 100 22.385 100 50ZM39.3949 32.322C37.4423 30.3694 34.2765 30.3694 32.3238 32.322C30.3712 34.2746 30.3712 37.4405 32.3238 39.3931L42.9308 50L32.3238 60.607C30.3712 62.5596 30.3712 65.7254 32.3238 67.6781C34.2765 69.6307 37.4423 69.6307 39.3949 67.6781L50.0019 57.0711L60.6088 67.6781C62.5615 69.6307 65.7273 69.6307 67.6799 67.6781C69.6325 65.7254 69.6325 62.5596 67.6799 60.607L57.0729 50L67.6799 39.3931C69.6325 37.4405 69.6325 34.2746 67.6799 32.322C65.7273 30.3694 62.5615 30.3694 60.6088 32.322L50.0019 42.929L39.3949 32.322Z" fill="#F03D3D"/>
            </g>
            <defs>
            <clipPath id="clip0_8044_13495">
            <rect width="100" height="100" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}
